import React, { FC } from "react";
import { Flex } from "@familyzone/component-library";
import { Skeleton } from "@chakra-ui/react";

type PillProps = {
  title?: string;
  subtitle?: string;
  body?: string;
  onClick: () => void;
  isLoading?: boolean;
};
export const BasePill: FC<PillProps> = ({ title, subtitle, body, onClick, isLoading }) => (
  <Flex
    height="143px"
    py="sp16"
    px="sp24"
    background="neutrals.10"
    borderRadius="sm"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    onClick={onClick}
    _hover={{ cursor: "pointer" }}
  >
    <Skeleton isLoaded={!isLoading}>
      <Flex fontSize="xxl" fontWeight="medium" color="neutrals.200">
        {title}
      </Flex>
    </Skeleton>
    <Skeleton isLoaded={!isLoading}>
      <Flex fontSize="xl" fontWeight="medium" color="neutrals.100">
        {subtitle}
      </Flex>
    </Skeleton>
    <Flex fontSize="l">{body}</Flex>
  </Flex>
);
