import React from "react";
import $ from "jquery";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";
import SessionStore from "../../stores/SessionStore";
import LayoutConfig from "../../utils/LayoutConfig";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import ClasswizeFeatureSelector from "../../modules/ClasswizeFeatureSelector";
import TimePeriods from "../../criteria/TimePeriods";

import { get } from "shades";
import NuMultiSelect from "../../utils/NuMultiSelect";
import clipboardIcon from "../../../images/Icon_MUI_Copy.svg";
import ReactTooltip from "react-tooltip";

import ReportingOptions from "./ReportingOptions";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import MasqueradeClasswizeSignInAsTeacherModal from "./MasqueradeModal";

// Keep this in alphabetical order as the ordering here is used for the selector options order
const providersSelectionMapping = [
  { value: "api.linewizecp.azuread", displayText: "Azure" },
  { value: "clever", displayText: "Clever" },
  { value: "google", displayText: "Google Classes" },
  { value: "api.linewizecp.google", displayText: "Google Groups" },
  { value: "api.linewizecp.ldap", displayText: "LDAP" },
  { value: "oneroster", displayText: "OneRoster" },
  { value: "classlink", displayText: "ClassLink" },
  { value: "wonde", displayText: "Wonde" },
  { value: "powerschool", displayText: "PowerSchool" },
].sort((a, b) => a.displayText.localeCompare(b.displayText));

const default_classroom_features = [
  "lock",
  "open_tab",
  "close_tab",
  "focus",
  "bypass_code",
  "reward",
  "message_class",
  "message_student",
  "policies",
];

const classwizeLoginUrl = (tenantKey) => {
  return LayoutConfig.classwize_link() + "/login/" + tenantKey;
};

export default class Classwize extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      classwize: {
        classroom_groups: [],
        restricted_groups: [],
        classroom_restrict_global_teachers: false,
        classroom_allow_owner_access: false,
        restrict_classroom_creation_groups: [],
        classroom_exceptions: [],
        monitor_groups: [],
        classroom_groups_group_prefix: "",
        teacher_classrooms_enabled: false,
        teacher_classrooms_sync_enabled: false,
        classroom_features_enabled: undefined,
        classroom_time_restrictions: [],
        classroom_providers: [],
        tenant_key: false,
        classroom_teachers_allow_reporting_access: false,
        teachers_reporting_groups: [],
        teachers_reporting_type: "",
        create_blocking_rules_only: false,
        support_contact_name: "",
        support_contact_email: "",
        support_contact_phone: "",
        support_message: "Reach out to your in-school support person for assistance with Classwize.",
        classlink_enabled: false,
      },
      login_url: false,
      errors: null,
      time_restriction_delete: false,
      loaded: false,
      saving: false,
      changes: true,
      show_masquerade_modal: false,
      timePeriodCriteria: {},
      allTeacherGroups: [],
      isSupportAdmin: SessionStore.isSupportAdmin(),
    };

    this.providerTextLens = get("displayText");
    this.providerValueLens = get("value");
  }

  componentDidMount() {
    this.handle_load();
    SessionStore.listen(this.onStoreChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onStoreChange);
  }

  onStoreChange = () => {
    this.setState({
      isSupportAdmin: SessionStore.isSupportAdmin(),
    });
  };

  handle_load = () => {
    let self = this;
    let login_url = this.state.login_url;

    Api.get("/config/ajax/classwize", function (result) {
      if (!$.isEmptyObject(result["classwize"])) {
        self.state.classwize = result["classwize"];

        if (result["classwize"]["support_message"] === undefined || !result["classwize"]["support_message"].trim()) {
          self.setState({
            classwize: {
              ...result["classwize"],
              support_message: "Reach out to your in-school support person for assistance with Classwize.",
            },
          });
        }
      }

      if (self.state.classwize.tenant_key) {
        login_url = classwizeLoginUrl(self.state.classwize.tenant_key);
      } else {
        login_url = false;
      }

      if (self.state.classwize.classroom_features_enabled === undefined) {
        self.state.classwize.classroom_features_enabled = default_classroom_features;
      }

      if (self.state.classwize.restrict_classroom_creation_groups === undefined) {
        self.state.classwize.restrict_classroom_creation_groups = [];
      }

      if (self.state.classwize.restricted_groups === undefined) {
        self.state.classwize.restricted_groups = [];
      }

      if (self.state.classwize.classroom_providers === undefined) {
        self.state.classwize.classroom_providers = [];
      }

      if (self.state.classwize.monitor_groups === undefined) {
        self.state.classwize.monitor_groups = [];
      }

      if (!self.state.classwize.classroom_groups) {
        self.state.classwize.classroom_groups = [];
      }

      if (!self.state.classwize.teachers_reporting_groups) {
        self.state.classwize.teachers_reporting_groups = [];
      }

      if (!self.state.classwize.teachers_reporting_type) {
        self.state.classwize.teachers_reporting_type = "";
      }

      if (self.state.classwize.classroom_time_restrictions === undefined) {
        self.state.classwize.classroom_time_restrictions = [];
      } else {
        let conditions = [];
        self.state.classwize.classroom_time_restrictions.forEach(function (c) {
          conditions.push(c);
        });
        let currentTimePeriodCriteria = {};
        currentTimePeriodCriteria.conditions = conditions;
        self.setState({ timePeriodCriteria: currentTimePeriodCriteria });
      }

      self.setState({
        login_url: login_url,
        loaded: true,
      });
    });
  };

  extract_classroom_time_restrictions = () => {
    const classwize = this.state.classwize,
      time_restrictions = [];
    this.state.timePeriodCriteria.conditions.forEach(function (c) {
      time_restrictions.push(c);
    });
    classwize.classroom_time_restrictions = time_restrictions;
    this.setState({ classwize: classwize });
  };

  handle_Submit = (event) => {
    event.preventDefault();

    const _classwize = { ...this.state.classwize };
    // if blocking rules only is enabled, find and remove features that aren't compatible with this
    if (_classwize.create_blocking_rules_only) {
      _classwize.classroom_features_enabled = _classwize.classroom_features_enabled.filter((x) => x !== "bypass_code" && x !== "reward");
    }

    this.extract_classroom_time_restrictions();
    let self = this;
    self.setState({ saving: true, errors: null, changes: false });
    Api.post(
      "/config/ajax/classwize",
      _classwize,
      function (e) {
        self.handle_load();
        self.setState({ saving: false, changes: false });
      },
      function (error) {}
    );
  };

  handle_AddOrRemoveClassroomGroup = (groups) => {
    this.setState({ changes: true, classwize: { ...this.state.classwize, classroom_groups: groups.map((group) => group.name) } });
  };

  handle_AddOrRemoveReportingGroup = (groups) => {
    this.setState({ changes: true, classwize: { ...this.state.classwize, teachers_reporting_groups: groups.map((group) => group.name) } });
  };

  handle_AddOrRemoveRestrictedGroup = (groups) => {
    this.setState({ changes: true, classwize: { ...this.state.classwize, restricted_groups: groups.map((group) => group.name) } });
  };

  handle_AddOrRemoveRestrictClassroomCreationGroups = (groups) => {
    this.setState({
      changes: true,
      classwize: { ...this.state.classwize, restrict_classroom_creation_groups: groups.map((group) => group.name) },
    });
  };

  handle_AddOrRemoveExceptionGroups = (groups) => {
    this.setState({ changes: true, classwize: { ...this.state.classwize, classroom_exceptions: groups.map((group) => group.name) } });
  };

  handle_AddOrRemoveMonitorGroups = (groups) => {
    this.setState({ changes: true, classwize: { ...this.state.classwize, monitor_groups: groups.map((group) => group.name) } });
  };

  handle_AddClassroomFeaturesEnabled = (feature) => {
    this.state.classwize.classroom_features_enabled.push(feature);
    this.setState({ changes: true });
  };

  handle_RemoveClassroomFeaturesEnabled = (feature) => {
    const new_features = [];
    const classwize_with_new_features = this.state.classwize;

    for (const x of classwize_with_new_features.classroom_features_enabled) {
      if (x !== feature) {
        new_features.push(x);
      }
    }

    classwize_with_new_features.classroom_features_enabled = new_features;

    this.setState({
      classwize: classwize_with_new_features,
      changes: true,
    });
  };

  handle_timePeriodAdded = (timePeriod) => {
    this.state.classwize.classroom_time_restrictions.push(timePeriod);
    this.setState({ changes: true });
  };

  handle_timePeriodRemoved = (timePeriod) => {
    const new_classroom_time_restrictions = [];
    const classwize = this.state.classwize;

    for (const x of classwize.classroom_time_restrictions) {
      if (x !== timePeriod) {
        new_classroom_time_restrictions.push(x);
      }
    }

    if (new_classroom_time_restrictions.length < 1 && classwize.classroom_teachers_allow_reporting_access) {
      this.setState({ time_restriction_delete: true });
      return true;
    }
    classwize.classroom_time_restrictions = new_classroom_time_restrictions;

    this.setState({
      classwize: classwize,
      time_restriction_delete: false,
    });
    return false;
  };

  handle_ChangeMembershipRequired = (event) => {
    const classwize = this.state.classwize;
    classwize["classroom_restrict_global_teachers"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeOwnershipRequired = (event) => {
    const classwize = this.state.classwize;
    classwize["classroom_allow_owner_access"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeReportingRequired = (event) => {
    const classwize = this.state.classwize;
    classwize["classroom_teachers_allow_reporting_access"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeReportingType = (event) => {
    const classwize = this.state.classwize;
    classwize["teachers_reporting_type"] = event.value;
    this.setState({ classwize: classwize, changes: true });
  };

  check_enable_reporting_groups = (event) => {
    const classwize = this.state.classwize;
    return (
      classwize["classroom_teachers_allow_reporting_access"] &&
      (classwize["teachers_reporting_type"] === "allowList" || classwize["teachers_reporting_type"] === "blockList")
    );
  };

  handle_ChangeTeacherClassroomsEnabled = (event) => {
    const classwize = this.state.classwize;
    classwize["teacher_classrooms_enabled"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeExcludeGroupsRegex = (event) => {
    const classwize = this.state.classwize;
    classwize["classroom_groups_group_prefix"] = event.target.value;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeDisableAutomaticLogin = (event) => {
    const classwize = this.state.classwize;
    classwize["disable_automatic_login"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeDisableClasslinkLogin = (event) => {
    const classwize = this.state.classwize;
    classwize["classlink_enabled"] = event.target.checked;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeSupportContactName = (event) => {
    const classwize = this.state.classwize;
    classwize["support_contact_name"] = event.target.value;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeSupportContactEmail = (event) => {
    const classwize = this.state.classwize;
    classwize["support_contact_email"] = event.target.value;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeSupportContactPhone = (event) => {
    const classwize = this.state.classwize;
    classwize["support_contact_phone"] = event.target.value;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_ChangeSupportMessage = (event) => {
    const classwize = this.state.classwize;
    classwize["support_message"] = event.target.value;
    this.setState({ classwize: classwize, changes: true });
  };

  handle_addProviderFilter = (providerItem) => {
    const providerValue = this.providerValueLens(providerItem);
    this.state.classwize.classroom_providers.push(providerValue);

    const nextClasswizeState = { ...this.state.classwize };
    this.setState({ classwize: nextClasswizeState, changes: true });
  };

  handle_removeProviderFilter = (providerValue) => {
    const removalIndex = this.state.classwize.classroom_providers.findIndex((element) => element === providerValue);
    this.state.classwize.classroom_providers.splice(removalIndex, 1);

    const nextClasswizeState = { ...this.state.classwize };
    this.setState({ classwize: nextClasswizeState, changes: true });
  };

  handle_ChangeAllowTeacherClassroomsSync = (event) => {
    const nextClasswizeState = {
      ...this.state.classwize,
      teacher_classrooms_sync_enabled: event.target.checked,
    };
    this.setState({ classwize: nextClasswizeState, changes: true });
  };

  handle_ToggleCreateBlockingRulesOnly = (event) => {
    this.setState({ classwize: { ...this.state.classwize, create_blocking_rules_only: event.target.checked }, changes: true });
  };

  areClassroomTimeRestrictionsActive = () => {
    return this.state.classwize.classroom_time_restrictions && this.state.classwize.classroom_time_restrictions.length > 0;
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Classwize
            <DumbBusyIndicator loaded={this.state.loaded} />
            <div className="formgroup_title_rightlink_button">
              <MasqueradeClasswizeSignInAsTeacherModal teacherGroups={this.state.classwize?.classroom_groups} />
            </div>
            {this.state.login_url ? (
              <div className="formgroup_login_url">
                Classwize sign-in URL:
                <a href={this.state.login_url} target="_blank" rel="noreferrer">
                  {this.state.login_url}
                </a>
                <ReactTooltip event="click" place="bottom" eventOff="click" delayHide={1500} isCapture={true} />
                <button
                  data-tip="Copied to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(this.state.login_url);
                  }}
                  className="copy_to_clipboard_icon"
                >
                  <img alt="clipboard icon" src={clipboardIcon} />
                </button>
              </div>
            ) : null}
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Teacher Requirements</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Teachers</div>
                    <div className="formgroup-element-fields">
                      <MultiGroupSearchSelector
                        preselected={this.state.classwize?.classroom_groups?.map((group) => ({ name: group, value: group }))}
                        onChangeGroups={this.handle_AddOrRemoveClassroomGroup}
                        useLegacyId={true}
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Restricted</div>
                    <div className="formgroup-element-fields">
                      <MultiGroupSearchSelector
                        preselected={this.state.classwize?.restricted_groups?.map((group) => ({ name: group, value: group }))}
                        onChangeGroups={this.handle_AddOrRemoveRestrictedGroup}
                        useLegacyId={true}
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Membership</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.classroom_restrict_global_teachers}
                          data-cy="classwize-config-membership-checkbox"
                          onChange={this.handle_ChangeMembershipRequired}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Ownership</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.classroom_allow_owner_access}
                          data-cy="classwize-config-ownership-checkbox"
                          onChange={this.handle_ChangeOwnershipRequired}
                        />
                      </div>
                    </div>
                  </div>
                  <ReportingOptions
                    active={this.areClassroomTimeRestrictionsActive()}
                    secondaryOptions={this.check_enable_reporting_groups()}
                    reportingType={this.state.classwize.teachers_reporting_type}
                    checkboxStatus={this.state.classwize.classroom_teachers_allow_reporting_access}
                    handleStatusFunc={this.handle_ChangeReportingRequired}
                    handleReportingTypeFunc={this.handle_ChangeReportingType}
                    selected={this.state.classwize?.teachers_reporting_groups?.map((group) => ({ name: group, value: group }))}
                    onChangeReportingGroups={this.handle_AddOrRemoveReportingGroup}
                  />
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allow teacher to sync Google Classrooms</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.teacher_classrooms_sync_enabled}
                          onChange={this.handle_ChangeAllowTeacherClassroomsSync}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Monitoring Users</div>
                    <div className="formgroup-element-fields">
                      <MultiGroupSearchSelector
                        preselected={this.state.classwize?.monitor_groups?.map((group) => ({ name: group, value: group }))}
                        onChangeGroups={this.handle_AddOrRemoveMonitorGroups}
                        useLegacyId={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Classrooms</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allow Creation</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.teacher_classrooms_enabled}
                          onChange={this.handle_ChangeTeacherClassroomsEnabled}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.classwize?.teacher_classrooms_enabled && (
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">Restrict Classroom Creation</div>
                      <div className="formgroup-element-fields">
                        <MultiGroupSearchSelector
                          preselected={this.state.classwize?.restrict_classroom_creation_groups?.map((group) => ({
                            name: group,
                            value: group,
                          }))}
                          onChangeGroups={this.handle_AddOrRemoveRestrictClassroomCreationGroups}
                          useLegacyId={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Excluded from Classwize</div>
                    <div className="formgroup-element-fields">
                      <MultiGroupSearchSelector
                        preselected={this.state.classwize?.classroom_exceptions?.map((group) => ({ name: group, value: group }))}
                        onChangeGroups={this.handle_AddOrRemoveExceptionGroups}
                        useLegacyId={true}
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Class Filter</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          onChange={this.handle_ChangeExcludeGroupsRegex}
                          value={this.state.classwize.classroom_groups_group_prefix}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Select Providers</div>
                    <div className="formgroup-element-fields">
                      <NuMultiSelect
                        allItems={providersSelectionMapping}
                        selectedValues={this.state.classwize.classroom_providers}
                        displayTextLens={this.providerTextLens}
                        valueLens={this.providerValueLens}
                        onAdd={this.handle_addProviderFilter}
                        onRemove={this.handle_removeProviderFilter}
                        data-testid="classwize-provider-filter"
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Features</div>
                    <div className="formgroup-element-fields">
                      <ClasswizeFeatureSelector
                        selected={this.state.classwize.classroom_features_enabled}
                        addElement={this.handle_AddClassroomFeaturesEnabled}
                        removeElement={this.handle_RemoveClassroomFeaturesEnabled}
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allowed Time Periods</div>
                    <div className="formgroup-element-fields">
                      <TimePeriods
                        criteria={this.state.timePeriodCriteria}
                        renderSelectorOnly={true}
                        addElement={this.handle_timePeriodAdded}
                        removeElement={this.handle_timePeriodRemoved}
                        canRemoveElement={this.state.time_restriction_delete}
                      />
                      {this.state.time_restriction_delete && (
                        <div className="errorMessage">
                          This Time Period cannot be removed while Reporting groups are configured. Please remove all groups from Reporting
                          before trying this again.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title"> Sign in options</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Disable automatic sign in</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.disable_automatic_login}
                          onChange={this.handle_ChangeDisableAutomaticLogin}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allow ClassLink sign in</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input
                          type="checkbox"
                          checked={this.state.classwize.classlink_enabled}
                          onChange={this.handle_ChangeDisableClasslinkLogin}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">In-School Support Details for Teachers</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Contact Name</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          onChange={this.handle_ChangeSupportContactName}
                          value={this.state.classwize.support_contact_name}
                        />
                        {this.state.errors && this.state.errors.support_contact_name && (
                          <div className="errorMessage">{this.state.errors.support_contact_name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Contact Email</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          onChange={this.handle_ChangeSupportContactEmail}
                          value={this.state.classwize.support_contact_email}
                        />
                        {this.state.errors && this.state.errors.support_contact_email && (
                          <div className="errorMessage">{this.state.errors.support_contact_email}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Contact Phone</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          onChange={this.handle_ChangeSupportContactPhone}
                          value={this.state.classwize.support_contact_phone}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Support Message</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          maxLength="256"
                          onChange={this.handle_ChangeSupportMessage}
                          value={this.state.classwize.support_message}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.isSupportAdmin && (
                  <div className="formgroup">
                    <div className="formgroup_title">
                      <div className="formgroup_title_title">Advanced Configuration</div>
                    </div>
                    <div className="formgroup-content">
                      <div className="formgroup-element">
                        <div className="formgroup-element-title">Create Blocking Rules Only</div>
                        <div className="formgroup-element-fields">
                          <div className="mui-checkbox">
                            <input
                              type="checkbox"
                              checked={this.state.classwize.create_blocking_rules_only}
                              onChange={this.handle_ToggleCreateBlockingRulesOnly}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} saving={this.state.saving} changes={this.state.changes} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
