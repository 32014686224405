import { useLayoutEffect, useRef, useState } from "react";

export const useOverflowAmount = (ref, callback, direction = "horizontal") => {
  const [overflowAmount, setOverflowAmount] = useState();
  const observerRef = useRef();

  useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      const trigger = () => {
        const overflowAmount =
          direction === "horizontal" ? current.scrollWidth - current.clientWidth : current.scrollHeight - current.clientHeight;
        setOverflowAmount(overflowAmount);
        callback?.(overflowAmount);
      };
      if (!observerRef.current) {
        observerRef.current = new ResizeObserver(trigger);
        observerRef.current.observe(current);
      }
      trigger();
    }
  }, [ref, callback, direction]);

  useLayoutEffect(() => {
    const { current: observer } = observerRef;
    const { current } = ref;
    return () => {
      if (current) {
        observer?.unobserve(current);
      }
    };
  }, [observerRef, ref]);

  return overflowAmount;
};
