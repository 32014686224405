import React, { ReactElement, useEffect } from "react";
import SessionStore from "../stores/SessionStore";

const ForethoughtAI = (): ReactElement => {
  const removeForethoughtAIChatbot = () => {
    document.getElementById("forethought-chat")?.remove();
    document.getElementById("forethought-widget-embed-script")?.remove();
  };

  const sessionChanged = () => {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    if (!SessionStore.getSelectedDevice() || !SessionStore.isAuthenticated()) {
      removeForethoughtAIChatbot();
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_FORETHOUGHT_AI_API_KEY) {
      // The following script is available in the ForethoughtAI dashboard
      // ForethoughtAI app link: https://qoria.forethought.app/
      document.querySelector("#forethought-chat")?.remove();
      const script = document.createElement("script");
      script.setAttribute("src", "https://solve-widget.forethought.ai/embed.js");
      script.setAttribute("id", "forethought-widget-embed-script");
      script.setAttribute("data-api-key", process.env.REACT_APP_FORETHOUGHT_AI_API_KEY);
      document.head.append(script);
    }
    return () => {
      removeForethoughtAIChatbot();
    };
  }, []);

  useEffect(() => {
    SessionStore.listen(sessionChanged);
    return () => {
      SessionStore.unlisten(sessionChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="forethoughtAIChatbot"></div>;
};

export default ForethoughtAI;
