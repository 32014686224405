import React from "react";
import { Tag } from "@familyzone/component-library";

interface Props {
  groupName: string;
}

const GroupNamePill: React.FC<Props> = ({ groupName }) => {
  return <Tag isRemovable={false} tagLabel={groupName} tagLeftIcon="fa fa-user-circle" variant="dark" data-testid="groupNamePill" />;
};

export default GroupNamePill;
