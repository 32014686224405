export function validateContentHubUrl(url: string): boolean {
  if (url.length === 0) {
    return true;
  }

  //url must end in a valid character (a-z)
  const re = new RegExp("^(.*[a-z])$");
  if (!re.test(url)) {
    return false;
  }

  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
}
