import React from "react";
import { Link } from "react-router";

import Separator from "../../modules/Separator";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import SecurityGroupStore from "../../stores/SecurityGroupStore";
import SecurityGroupActions from "../../actions/SecurityGroupActions";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";

class AddSecurityGroup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: undefined,
      name: "",
      entries: undefined,
    };
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.group) {
      this.setState({
        id: nextprops.group.id,
        name: nextprops.group.name,
        entries: nextprops.group.entries,
      });
    } else {
      this.setState({
        id: undefined,
        name: "",
        entries: undefined,
      });
    }
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handle_Submit = () => {
    if (this.state.id) {
      this.props.handleUpdate({
        id: this.state.id,
        name: this.state.name,
        entries: this.state.entries,
      });
    } else {
      this.props.handleAdd(this.state.name);
    }
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Security Group" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="mui-textfield">
              <div className="formgroup-element">Name</div>
              <input type="text" value={this.state.name} onChange={this.handle_ChangeName} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div></div>;
  }
}

export default class SecurityGroups extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      securitygroups: [],
      edit__group: undefined,
      visible__add: false,
    };
  }

  componentDidMount() {
    SecurityGroupStore.listen(this.onChange);

    setTimeout(function () {
      SecurityGroupActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SecurityGroupStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      securitygroups: SecurityGroupStore.getSecurityGroups(),
    });
  };

  handler__clickEdit = (row) => {
    this.setState({
      edit__group: row,
      visible__add: true,
    });
  };

  handler__clickAdd = () => {
    this.setState({
      edit__group: undefined,
      visible__add: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Security Group" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (id) => {
    SecurityGroupActions.deleteSecurityGroup(id);
  };

  handle__Update = (object) => {
    Api.post("/config/device/ajax/securitygroups/" + object.id, object, function (result) {
      SecurityGroupActions.invalidateAndFetch();
    });
  };

  handle__Add = (name) => {
    SecurityGroupActions.addSecurityGroup(name);
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Name",
        data: function (row) {
          return <Link to={"/config/device/objects/securitygroups/" + row["id"]}>{row["name"]}</Link>;
        },
        search: FlexibleTable.search__single_string_field("name"),
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handler__clickEdit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row["id"])}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.securitygroups} loaded={true} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddSecurityGroup
          group={this.state.edit__group}
          visible={this.state.visible__add}
          handleAdd={this.handle__Add}
          handleUpdate={this.handle__Update}
          handleClose={this.handler__clickCloseAdd}
        ></AddSecurityGroup>

        <TableCentricPage
          icon="fa fa-object-group"
          title="Security Groups"
          buttons={this.render__buttons()}
          content={this.render__content()}
        />
      </TableLeftPanel>
    );
  }
}
