import React from "react";
import InfoBoxNew from "./InfoBoxNew";

export default class InfoBox extends React.Component {
  render() {
    return (
      <InfoBoxNew type={this.props.type} category={this.props.category} target={this.props.target} signatureId={this.props.signatureId} />
    );
  }
}
