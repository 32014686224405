import { datadogRum } from "@datadog/browser-rum";
import { parseEnvVarToStringArray } from "./envUtils";

export function initDatadogRum(): void {
  const enabledDomains = parseEnvVarToStringArray(process.env.REACT_APP_DD_RUM_ENABLED_ON_DOMAINS);
  if (enabledDomains.length === 0) return;

  if (enabledDomains.includes(window.location.hostname)) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DD_RUM_APP_ID as string,
      clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN as string,
      site: process.env.REACT_APP_DD_RUM_SITE,
      service: process.env.REACT_APP_DD_RUM_SERVICE_NAME,
      env: "production",
      sampleRate: 5,
      trackInteractions: true,
      version: process.env.REACT_APP_BUILD_VERSION,
    });
  }
}
