import React from "react";
import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import NatExceptionsModal from "./NatExceptionsModal";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rule: this.props.rule,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      rule: nextprops.rule,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (value) => {
    const rule = this.state.rule;
    rule.name = value;
    this.setState({ rule: rule });
  };

  handle_SourceCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.source_criteria = criteria;
    this.setState({ rule: rule });
  };

  handle_DestinationCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.destination_criteria = criteria;
    this.setState({ rule: rule });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.rule);
    this.handleHide();
  };

  render() {
    if (this.props.visible) {
      return (
        <NatExceptionsModal
          handleHide={this.handleHide}
          isOpen={this.props.visible}
          saveSelection={this.handle_Submit}
          selectedDestinationCriteria={this.state.rule.destination_criteria}
          selectedSourceCriteria={this.state.rule.source_criteria}
          updateParentSourceState={this.handle_SourceCriteriaChanged}
          updateParentDestinationState={this.handle_DestinationCriteriaChanged}
          updateParentNameState={this.handle_ChangeName}
          rule={this.state.rule}
        />
      );
    }

    return <div></div>;
  }
}

export default class NatExceptions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/wan/exceptions", function (result) {
      self.setState({
        rules: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    const self = this;
    Api.put("/edgewize/ajax/wan/exceptions", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Exception" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (row) => {
    const self = this;
    Api.delete("/edgewize/ajax/wan/exceptions", { id: row["id"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    const self = this;
    Api.post("/edgewize/ajax/wan/exceptions", rule, function (result) {
      self.handle_load();
    });
  };

  handle_toggleRule = (row) => {
    if (row["enabled"]) {
      row["enabled"] = false;
    } else {
      row["enabled"] = true;
    }

    const self = this;
    Api.post("/edgewize/ajax/wan/exceptions", row, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__add_permission: true,
    });
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Enabled",
        data: function (row) {
          return <input type="checkbox" checked={row["enabled"]} onChange={() => self.handle_toggleRule(row)} />;
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          return row["name"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("name"),
      },
      {
        title: "Source",
        data: function (row) {
          return <CriteriaPrinter criteria={row["source_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
        search: FlexibleTable.search__criteria_printer("source_criteria"),
      },
      {
        title: "Destination",
        data: function (row) {
          return <CriteriaPrinter criteria={row["destination_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
        search: FlexibleTable.search__criteria_printer("destination_criteria"),
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)}></EditButton>
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.rules} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddEditRule
          visible={this.state.visible__add_permission}
          handleSave={this.handle__SaveRule}
          handleClose={this.handler__clickCloseAdd}
          rule={this.state.editing__permission}
        ></AddEditRule>

        <TableCentricPage icon="fa fa-random" title="NAT Exceptions" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
