import { Box, Input, Text, useTranslation } from "@familyzone/component-library";
import React, { useState } from "react";
import { GroupUMS } from "../../../types/Groups";
import { searchGroups } from "../../../utils/api/Groups";
import { useDebounce } from "react-use";

export interface GroupSearchBoxProps {
  searchEnabled: boolean;
  onSearchStart: () => void;
  onSearchEnd: (groups: GroupUMS[]) => void;
  excludeArchivedGroups?: boolean;
}

const GroupSearchBox: React.FC<GroupSearchBoxProps> = ({ searchEnabled, onSearchStart, onSearchEnd, excludeArchivedGroups = true }) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [debouncedValue, setDebouncedValue] = React.useState("");
  const minimumSearchLength = 3;
  const canSearch = () => {
    return searchEnabled && !searching && searchInput.length >= minimumSearchLength;
  };

  // Debounce and search on debounced value change.
  React.useEffect(() => {
    if (canSearch()) void search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useDebounce(
    () => {
      setDebouncedValue(searchInput?.trim());
    },
    500,
    [searchInput]
  );

  const search = async () => {
    setSearching(true);
    onSearchStart();
    const result = await searchGroups(searchInput, excludeArchivedGroups);
    onSearchEnd(result);
    setSearching(false);
  };

  return (
    <Box width="536px">
      <Input placeholder={t("Search")} onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
      <Text color="neutrals.100" ml="4" mt="6">
        To search, please enter 3 or more characters.
      </Text>
    </Box>
  );
};

export default GroupSearchBox;
