import alt from "../alt";

class SessionActions {
  changeDevice(device) {
    return device;
  }

  deselectDevice() {
    return "nothing";
  }

  fetchAvailableDevices() {
    return "nothing";
  }

  fetchAccountDevicePermissions() {
    return "nothing";
  }

  fetchAccountDevicePermissionsUpdated(result) {
    return result;
  }

  fetchAvailableDevicesUpdated(result) {
    return result;
  }

  authenticate(creds) {
    return creds;
  }

  authenticateSuccess(data) {
    return data;
  }

  authenticateFailure(data) {
    return data;
  }

  logout() {
    return "logout";
  }

  invalidSessionError(exception) {
    return exception;
  }
}

export default alt.createActions(SessionActions);
