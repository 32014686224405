import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { DeviceOptions } from "../types/Device";
import Api from "../utils/Api";

export const DEVICE_OPTIONS_URL = "/managedevice/ajax/device";

export interface DeviceOptionsStore {
  options?: DeviceOptions;
  fetch: () => Promise<DeviceOptions>;
  save: (options: DeviceOptions) => Promise<DeviceOptions>;
  reset: () => void;
}

export const useDeviceOptionsStore = create<DeviceOptionsStore>((set, get) => ({
  options: undefined,

  fetch: async (): Promise<DeviceOptions> => {
    let options: DeviceOptions | undefined,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(DEVICE_OPTIONS_URL).then(
      (response: { data: DeviceOptions }) => (options = response.data),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!options || statusCode !== 200) {
      throw new ResponseError("Failed to fetch device options", statusCode);
    }

    set({ options });

    return options;
  },

  save: async (options: DeviceOptions): Promise<DeviceOptions> => {
    let saved: DeviceOptions | undefined,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.postAsync(DEVICE_OPTIONS_URL, options).then(
      () => (saved = { ...get().options, ...options }),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save device options", statusCode);
    }

    set({ options: saved });

    return saved;
  },

  reset: () => {
    set({ options: undefined });
  },
}));
