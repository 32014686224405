import React from "react";
import SleekModal from "../../../modules/SleekModal";

class SisDeleteDialog extends React.Component {
  state = {
    acceptPurgeData: false,
  };

  handleSubmit = () => {
    if (!this.state.acceptPurgeData) {
      // Show error
      console.log("data removal not accepted");
      return;
    }

    this.setState({ acceptPurgeData: false });

    this.props.handleDeleteConfirmed();
  };

  render() {
    return (
      <SleekModal
        show={this.props.show}
        className={this.props.modalClassName}
        title="Are you sure you wish to delete this SIS?"
        description={
          <p>
            This will remove the authentication and you will no longer be able to sync.
            <br />
            <br />
            Contact support if you wish to purge historic data.
          </p>
        }
        handleHide={this.props.handleHide}
        buttons={
          <button className="mui-btn mui-btn--raised modal-button" disabled={!this.state.acceptPurgeData} onClick={this.handleSubmit}>
            Yes, Delete
          </button>
        }
        centerButtons
      >
        <div className="sis-delete-dialog-content">
          <div className="mui-checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.acceptPurgeData}
                onChange={() => this.setState({ acceptPurgeData: !this.state.acceptPurgeData })}
              />
              I understand
            </label>
          </div>
        </div>
      </SleekModal>
    );
  }
}

export default SisDeleteDialog;
