import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { isArray } from "lodash";
import React from "react";
import { useDebounce } from "react-use";
import { FilteringSourceCriteria } from "../../modules/criteria/criteriaTypes";
import { UserUMS, UserOption } from "../../types/Users";
import { mapUserToOptions, search } from "./UserSearchHelper";

interface Props {
  criteria?: FilteringSourceCriteria;
  onChange?: (criteria: FilteringSourceCriteria) => void;
  preselected?: string[];
  onChangeUsers?: (users: string[]) => void;
  disabled?: boolean;
}

// This selector is used to select multiple users from a search, and it will return an array of usernames.
// This is in use in a number of places where saving to CMS is required.
// A new selector has been made to return the user ID and name, this is found in MultiUserSearchSelectorReturningIdAndName.tsx
export const MultiUserSearchSelector: React.FC<Props> = ({
  criteria,
  onChange: onChangeFilterSourceCriteria,
  preselected,
  onChangeUsers,
  disabled,
}) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = React.useState<UserOption[]>([]);
  const [searchResults, setSearchResults] = React.useState<UserUMS[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [debouncedSearch, setDebouncedSearch] = React.useState("");

  // If the preselected users are provided, set the selected users.
  React.useMemo(() => {
    if (preselected) {
      const selected: UserOption[] = preselected.map((username) => {
        return { value: username };
      });
      setSelectedUsers(selected);
    }
  }, [preselected]);

  const handleUserChange = (selection: UserOption[]) => {
    const userSet = new Set(selectedUsers);
    if (selection.length === 0) setSelectedUsers([]);

    if (selection.length < selectedUsers.length) {
      const removedUser = selectedUsers.find((user) => !selection.some((s) => s.id === user.id));
      if (removedUser) {
        userSet.delete(removedUser);
        setSelectedUsers(Array.from(userSet));
      }
    }

    if (selection.length > selectedUsers.length) {
      const addedUser = selection.find((user) => !selectedUsers.some((s) => s.id === user.id));
      if (addedUser) {
        userSet.add(addedUser);
        setSelectedUsers(Array.from(userSet));
      }
    }

    // Used by the policy modal to update the criteria.
    if (onChangeFilterSourceCriteria && criteria) {
      onChangeFilterSourceCriteria({ ...criteria, conditions: [...selection.map((o: Option) => String(o.value))] });
    }

    if (onChangeUsers && preselected) {
      const changeSelection = selection.map((o: Option) => String(o.value));
      onChangeUsers([...changeSelection]);
    }
  };

  React.useMemo(() => {
    if (criteria?.conditions && isArray(criteria.conditions)) {
      const selected: UserOption[] = criteria.conditions.map((user) => {
        return {
          value: user,
        };
      });
      setSelectedUsers(selected);
    }
  }, [criteria]);

  useDebounce(
    () => {
      setDebouncedSearch(searchTerm?.trim());
    },
    500,
    [searchTerm]
  );

  React.useEffect(() => {
    if (debouncedSearch) {
      void search(debouncedSearch).then((result) => setSearchResults(result));
    }
  }, [debouncedSearch]);

  const handleInput = (input: string) => {
    if (input.trim().length >= 3) setSearchTerm(input);
    else setSearchResults([]);
  };

  return (
    <>
      <MultiSearchSelector
        disabled={disabled}
        name="user-search-selector"
        placeholder={t("Search Users")}
        selected={selectedUsers}
        options={mapUserToOptions(searchResults)}
        onChange={(e) => handleUserChange(e)}
        onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
        filterOptions={false}
      />
      <Text color="neutrals.100" ml="4" mt="6">
        Please enter 3 or more characters to search.
      </Text>
    </>
  );
};
