import FingerprintStore from "../stores/FingerprintStore";
import FingerprintActions from "../actions/FingerprintActions";
import React from "react";
import Criteria from "./Criteria";
import SmartListSelector from "../modules/SmartListSelector";

export default class FingerprintCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      availableFingerprints: FingerprintStore.getFingerprints(),
    };

    /* {type: "fingerprint", negate: false, conditions: []} */
  }

  componentDidMount() {
    FingerprintStore.listen(this.onChange);

    setTimeout(() => {
      FingerprintActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    FingerprintStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableFingerprints: FingerprintStore.getFingerprints(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Change = (values) => {
    this.state.criteria.conditions = values;
    this.bubble_Changes(this.state.criteria);
  };

  get__value = (item) => {
    return item["id"];
  };

  get__label = (item) => {
    return item["name"];
  };

  handle_Add = (item) => {
    this.state.criteria.conditions.push(item);
    this.props.bubbleMethod(this.state.criteria);
  };

  handle_Remove = (item) => {
    let new_conditions = [];
    for (let x of this.state.criteria.conditions) {
      if (x !== item) {
        new_conditions.push(x);
      }
    }

    this.state.criteria.conditions = new_conditions;
    this.props.bubbleMethod(this.state.criteria);
  };

  render() {
    return (
      <Criteria label="Device Type" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <SmartListSelector
          get__value={this.get__value}
          get__label={this.get__label}
          selected={this.state.criteria.conditions}
          available={this.state.availableFingerprints}
          removeSelectedFromOptions
          addElement={this.handle_Add}
          removeElement={this.handle_Remove}
        />
      </Criteria>
    );
  }
}
