import alt from "../alt";

class SecurityGroupActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }

  invalidateAndFetch() {
    return "nothing";
  }

  deleteSecurityGroup(object_id) {
    return object_id;
  }

  addSecurityGroup(entity) {
    return entity;
  }
}

export default alt.createActions(SecurityGroupActions);
