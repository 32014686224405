import React, { useEffect, useState } from "react";
import {
  Flex,
  useTranslation,
  Modal,
  Table,
  Td,
  Tr,
  Tbody,
  TextInput,
  Text,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  Box,
  Thead,
  Th,
  IconButton,
  Icon,
  useToast,
  InlineNotification,
  Link,
} from "@familyzone/component-library";
import PropTypes from "prop-types";

import { FilteringRule, FilteringRuleHeader, SourceCriteriaError, TimePeriod, BaseCriteria, Signature, Keyword } from "./types";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import validateCriteria from "../../modules/criteria/validateCriteria";
import DirectivesSelector from "./DirectivesSelector";
import {
  FilteringSourceCriteria,
  Fingerprint,
  IpObject,
  MacObject,
  SecurityGroup,
  KeywordObject,
  WebsiteObject,
} from "../../modules/criteria/criteriaTypes";
import Api from "../../utils/Api";
import ObjectActions from "../../actions/ObjectActions";
import CreateObjectPool from "./CreateObjectPool";
import SignatureSelector from "./SignatureSelector";
import { isValidIPAddress } from "../../utils/Validation";

export const MAX_WEBSITE_CRITERIA = 8;
export const maxWebsiteText = "When a policy has more than 8 websites added, using an Object Pool is recommended.";
export const convertButtonText = "Create Object Pool";
interface PolicyModalProps {
  open: boolean;
  editing: boolean;
  loading: boolean;
  rule: FilteringRule | null;
  signatures: Signature[];
  keywords: Keyword[];
  keywordObjects: KeywordObject[];
  websiteObjects: WebsiteObject[];
  ipObjects: IpObject[];
  fingerprints: Fingerprint[];
  macObjects: MacObject[];
  timePeriods: TimePeriod[];
  securityGroups: SecurityGroup[];
  onClose: (rule: FilteringRule | null) => void;
  onSubmit: (rule: FilteringRule) => void;
}

// Action is the result of a filtering rule. The API expects a 1 or a 0 instead of boolean values.
type Action = 0 | 1;

const PolicyModal: React.FC<PolicyModalProps> = (
  {
    open = false,
    editing = false,
    loading = false,
    rule,
    signatures,
    keywords,
    keywordObjects,
    websiteObjects,
    ipObjects,
    fingerprints,
    macObjects,
    timePeriods,
    securityGroups,
    onClose = () => "",
    onSubmit = () => "",
  },
  context
) => {
  const { t } = useTranslation();

  const { errorToast } = useToast();

  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState<string>("");
  const [criteria, setCriteria] = useState<BaseCriteria[]>([]);

  const [sourceCriteria, setSourceCriteria] = useState<FilteringSourceCriteria[]>([]);
  const [sourceCriteriaErrors, setSourceCriteriaErrors] = useState<SourceCriteriaError[]>([]);
  const [customHeaders, setCustomHeaders] = useState<FilteringRuleHeader[]>([]);
  const [customHeaderName, setCustomHeaderName] = useState<string>("");
  const [customHeaderValue, setCustomHeaderValue] = useState<string>("");
  const [contentMods, setContentMods] = useState<string[]>([]);

  const [action, setAction] = useState<Action>(0);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [blockedPage, setBlockedPage] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [quarantine, setQuarantine] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const [objectPoolOpen, setObjectPoolOpen] = useState<boolean>(false);

  const headerText = editing ? t("Edit Policy") : t("Create Policy");
  const createPolicyText = t("Save Policy");
  const { successToast } = useToast();

  const handleChangeContentMods = (selected: string[]) => {
    setContentMods(selected);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeSignatures = (criteria: BaseCriteria[]) => {
    setCriteria(criteria);
  };

  const handleChangeSourceCriteria = (sourceCriteria: FilteringSourceCriteria[]) => {
    setSourceCriteria(sourceCriteria);
  };

  const handleToggleAllow = () => {
    setAction(1);
  };

  const handleToggleBlock = () => {
    setAction(0);
  };

  const handleChangeRedirect = () => {
    setRedirect(!redirect);
    setBlockedPage(false);
  };

  const handleChangeRedirectUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRedirectUrl(e.target.value);
  };

  const handleChangeBlockedPage = () => {
    setBlockedPage(!blockedPage);
    setRedirect(false);
  };

  const handleChangeAlert = () => {
    setAlert(!alert);
  };

  const handleChangeQuarantine = () => {
    setQuarantine(!quarantine);
  };

  const handleChangeLocked = () => {
    setLocked(!locked);
  };

  const handleDeleteHeader = (customHeader: FilteringRuleHeader) => {
    setCustomHeaders(customHeaders.filter((h) => h.name !== customHeader.name));
  };

  const handleAddHeader = () => {
    if (!customHeaderName || !customHeaderValue) return;
    if (customHeaders.some((h) => h.name === customHeaderName)) return;

    setCustomHeaders([
      ...customHeaders,
      {
        name: customHeaderName,
        value: customHeaderValue,
      },
    ]);

    setCustomHeaderName("");
    setCustomHeaderValue("");
  };

  const handleChangeHeaderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomHeaderName(e.target.value);
  };

  const handleChangeHeaderValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomHeaderValue(e.target.value);
  };

  const handleClickSubmit = () => {
    if (!rule) return;

    const criteriaValidationResult = validateCriteria(sourceCriteria);

    const [hasErrors, sourceCriterias] = criteriaValidationResult;

    if (hasErrors) {
      setSourceCriteriaErrors(
        sourceCriterias
          .map((sourceCriteria) => {
            if (sourceCriteria.errorMessage) {
              return {
                id: sourceCriteria.id,
                msg: sourceCriteria?.errorMessage,
              };
            }
            return null;
          })
          .filter((s): s is SourceCriteriaError => !!s)
      );
      return;
    }

    if (name.length < 4) {
      errorToast({
        title: t("Missing Name"),
        description: t("Policy name must have more than 3 characters."),
        isClosable: true,
        duration: 6000,
      });
      return;
    }

    if (rule?.content_mod === undefined) {
      if (criteria.length === 0) {
        errorToast({
          title: t("Missing Type"),
          description: t("At least one type must be selected."),
          isClosable: true,
          duration: 6000,
        });
        return;
      }
    } else {
      if (contentMods.length === 0) {
        errorToast({
          title: t("Invalid Content Mods"),
          description: t("At least one content mod must be selected."),
          isClosable: true,
          duration: 6000,
        });
        return;
      }
    }

    setSourceCriteriaErrors([]);

    const finalRule: FilteringRule = {
      action: action,
      criteria: criteria,
      enable_micro_lock: quarantine,
      enabled: rule.enabled,
      exclusive: rule.exclusive,
      expiry_timestamp: rule.expiry_timestamp,
      fireEvent: alert,
      id: rule.id,
      name: name,
      redirect: redirect,
      redirectUrl: redirectUrl,
      redirect_to_standard: blockedPage,
      source_criteria: sourceCriteria,
      sticky: locked,
      temp_rule: rule.temp_rule,
      top: rule.top,
      custom_headers: {
        upsert: action === 1 ? customHeaders : [],
      },
    };

    if (rule.content_mod !== undefined) {
      finalRule.content_mod = contentMods;
    }

    onSubmit(finalRule);
  };

  useEffect(() => {
    if (!rule) return;
    setName(rule.name);
    setAction(rule.action);
    setCriteria(
      rule.criteria.filter(
        (c, index, self) =>
          index === self.findIndex((t) => t.type === c.type && JSON.stringify(t.conditions) === JSON.stringify(c.conditions))
      )
    );
    setSourceCriteria(rule.source_criteria);
    setRedirect(rule.redirect);
    setRedirectUrl(rule.redirectUrl);
    setBlockedPage(rule.redirect_to_standard);
    setAlert(rule.fireEvent);
    setQuarantine(rule.enable_micro_lock);
    setLocked(rule.sticky);
    setCustomHeaders(rule?.custom_headers?.upsert || []);
    setCustomHeaderName("");
    setCustomHeaderValue("");
    if (rule.content_mod !== undefined) {
      setContentMods(rule.content_mod);
    }
  }, [rule, open]);

  const criteriaOptions: Partial<CriteriaTypes> = {
    "ipv4.range": "Network Range",
    "source.mac": "Mac Address",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    fingerprint: "Device Type",
    "ipv4.alias": "IP Address Object",
    "source.mac.pool": "Mac Address Object",
    timeperiod: "Time Periods",
    "application.http.contenttype.regex": "Content Type",
    "application.http.useragent.regex": "User Agent",
    "application.http.request.regex": "HTTP Request Path",
    "source.user": "User",
    group: "Group",
    "exclude.group": "Exclude Group",
    securitygroup: "Security Group",
  };

  const websiteCriteria: string[] = criteria
    .filter((c) => c.type === "application.http.hostname" && !Array.isArray(c.conditions) && "source_entries" in c.conditions)
    .map((c) =>
      c.type === "application.http.hostname" &&
      !Array.isArray(c.conditions) &&
      "source_entries" in c.conditions &&
      Array.isArray(c.conditions.source_entries)
        ? c.conditions.source_entries[0]
        : null
    )
    .filter((c): c is string => c !== null);

  const showWebsiteWarning = websiteCriteria.filter((website) => !isValidIPAddress(website)).length >= MAX_WEBSITE_CRITERIA;

  const handleOpenObjectList = () => {
    setObjectPoolOpen(true);
  };

  const handleCloseObjectList = () => {
    setObjectPoolOpen(false);
  };

  const handleOnClose = () => {
    if (disabled) return;
    onClose(rule);
  };

  const handleConvertObjectList = (ruleName: string, description: string, urls: string[]) =>
    void convertObjectList(ruleName, description, urls);

  const convertObjectList = async (ruleName: string, description: string, urls: string[]) => {
    try {
      if (!rule) return;

      setDisabled(true);
      const options = {
        name: ruleName,
        desc: description,
        type: 2,
      };

      const { result } = (await Api.putAsync("/config/device/ajax/aliases", options)) as { result: { id: string } };

      const namedEntries = urls.map((c, i) => ({
        id: i,
        click_action: "add",
        invalidValueMsg: null,
        name: c,
        desc: "",
        value: c,
      }));

      const object = {
        id: result.id,
        name: options.name,
        desc: options.desc,
        type: options.type,
        entries: [],
        named_entries: namedEntries,
      };

      await Api.postAsync("/config/device/ajax/aliases", object);

      const finalRule: FilteringRule = {
        action: action,
        criteria: [
          ...criteria.filter(
            (c) => !(c.type === "application.http.hostname" && !Array.isArray(c.conditions) && "source_entries" in c.conditions)
          ),
          {
            type: "application.http.hostname",
            conditions: [result.id],
          },
        ],
        enable_micro_lock: quarantine,
        enabled: rule.enabled,
        exclusive: rule.exclusive,
        expiry_timestamp: rule.expiry_timestamp,
        fireEvent: alert,
        id: rule.id,
        name: name,
        redirect: redirect,
        redirectUrl: redirectUrl,
        redirect_to_standard: blockedPage,
        source_criteria: sourceCriteria,
        sticky: locked,
        temp_rule: rule.temp_rule,
        top: rule.top,
        custom_headers: {
          upsert: action === 1 ? customHeaders : [],
        },
      };

      // add to finalrule.criteria where IP is true
      finalRule.criteria = [
        ...finalRule.criteria,
        ...criteria.filter(
          (c) =>
            c.type === "application.http.hostname" &&
            !Array.isArray(c.conditions) &&
            "source_entries" in c.conditions &&
            c.conditions.source_entries.length > 0 &&
            isValidIPAddress(c.conditions.source_entries[0])
        ),
      ];

      if (rule.content_mod !== undefined) {
        finalRule.content_mod = contentMods;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ObjectActions.invalidateAndFetch();
      onSubmit(finalRule);
      setObjectPoolOpen(false);
      successToast({
        title: t("Object Pool Created"),
        isClosable: true,
        duration: 6000,
        position: "bottom-right",
        children: <Link onClick={() => handleNavigateObjectLists(result.id)}>Click here to view Object Pool</Link>,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDisabled(false);
    }
  };

  const handleNavigateObjectLists = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    context.router.push("/config/device/objects/pools/" + id);
  };

  return (
    <Modal isOpen={open} onClose={handleOnClose} headerText={headerText} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box my="sp24" overflowY="auto" py="sp4" overflow={rule?.content_mod ? "visible" : "auto"}>
        <Table style={{ tableLayout: "fixed" }}>
          <Tbody>
            <Tr>
              <Td width="120px">
                <Text fontSize="medium" color="text.title">
                  {t("Name")}{" "}
                  <Text color="accent.red.400" display="inline">
                    *
                  </Text>
                </Text>
              </Td>
              <Td>
                <TextInput
                  name={name}
                  placeholder={t("Enter a policy name")}
                  value={name}
                  onChange={handleChangeName}
                  isDisabled={loading || disabled}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t("Type")}{" "}
                  <Text color="accent.red.400" display="inline">
                    *
                  </Text>
                </Text>
              </Td>
              <Td>
                {showWebsiteWarning ? (
                  <Flex pb="sp8">
                    <InlineNotification
                      status={"info"}
                      notificationDescription={
                        <Flex direction="column">
                          <Flex>
                            <Box>
                              <Text as="span" fontFamily="paragraphs" fontSize="14px">
                                {t(maxWebsiteText)}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex>
                            <Button size="sm" variant="primary" mt="sp8" disabled={loading || disabled} onClick={handleOpenObjectList}>
                              {t(convertButtonText)}
                            </Button>
                          </Flex>
                        </Flex>
                      }
                    />
                  </Flex>
                ) : (
                  <></>
                )}
                {rule?.content_mod === undefined ? (
                  <SignatureSelector
                    criteria={criteria}
                    signatures={signatures}
                    keywords={keywords}
                    keywordObjects={keywordObjects}
                    websiteObjects={websiteObjects}
                    disabled={loading || disabled}
                    onChange={handleChangeSignatures}
                  />
                ) : (
                  <DirectivesSelector disabled={loading || disabled} value={contentMods} onChange={handleChangeContentMods} />
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t("Criteria")}
                </Text>
              </Td>
              <Td>
                <CriteriaSelector
                  customOptions={criteriaOptions}
                  ipObjects={ipObjects}
                  fingerprints={fingerprints}
                  macObjects={macObjects}
                  timePeriods={timePeriods}
                  securityGroups={securityGroups}
                  disabled={loading || disabled}
                  selected={sourceCriteria}
                  errors={sourceCriteriaErrors}
                  onChange={handleChangeSourceCriteria}
                />
              </Td>
            </Tr>

            {!rule?.content_mod ? (
              <Tr>
                <Td>
                  <Text fontSize="medium" color="text.title">
                    {t("Action")}
                  </Text>
                </Td>
                <Td>
                  <Flex>
                    <RadioGroup>
                      <Radio name="allow" isChecked={action === 1} onChange={handleToggleAllow} isDisabled={loading || disabled}>
                        {t("Allow")}
                      </Radio>
                      <Radio name="block" isChecked={action === 0} onChange={handleToggleBlock} isDisabled={loading || disabled}>
                        {t("Block")}
                      </Radio>
                    </RadioGroup>
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <></>
            )}
            {action === 0 && !rule?.content_mod ? (
              <>
                <Tr>
                  <Td>
                    <Text fontSize="medium" color="text.title">
                      {t("Redirect")}
                    </Text>
                  </Td>
                  <Td>
                    <Flex>
                      <Checkbox name={"redirect"} isChecked={redirect} onChange={handleChangeRedirect} isDisabled={loading || disabled} />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="medium" color="text.title">
                      {t("Redirect Url")}
                    </Text>
                  </Td>
                  <Td>
                    <Flex>
                      <TextInput
                        name={"redirect-url"}
                        placeholder={t("Enter url")}
                        width="100%"
                        value={redirectUrl}
                        onChange={handleChangeRedirectUrl}
                        isDisabled={loading || disabled}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="medium" color="text.title">
                      {t("Blocked Page")}
                    </Text>
                  </Td>
                  <Td>
                    <Flex>
                      <Checkbox
                        name={"blocked-page"}
                        isChecked={blockedPage}
                        onChange={handleChangeBlockedPage}
                        isDisabled={loading || disabled}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="medium" color="text.title">
                      {t("Alert")}
                    </Text>
                  </Td>
                  <Td>
                    <Flex>
                      <Checkbox name={"alert"} isChecked={alert} onChange={handleChangeAlert} isDisabled={loading || disabled} />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="medium" color="text.title">
                      {t("Quarantine")}
                    </Text>
                  </Td>
                  <Td>
                    <Flex>
                      <Checkbox
                        name={"quarantine"}
                        isChecked={quarantine}
                        onChange={handleChangeQuarantine}
                        isDisabled={loading || disabled}
                      />
                    </Flex>
                  </Td>
                </Tr>
              </>
            ) : (
              <></>
            )}
            {!rule?.content_mod ? (
              <Tr>
                <Td>
                  <Text fontSize="medium" color="text.title">
                    {t("Locked")}
                  </Text>
                </Td>
                <Td>
                  <Flex>
                    <Checkbox name={"locked"} isChecked={locked} onChange={handleChangeLocked} isDisabled={loading || disabled} />
                  </Flex>
                </Td>
              </Tr>
            ) : (
              <></>
            )}
          </Tbody>
        </Table>
        {action === 1 && !rule?.content_mod ? (
          <Box my="sp16">
            <Text color="text.paragraph.light" mb="sp12">
              {t("Custom Headers")}
            </Text>
            <Flex mb="sp12">
              <Flex flexGrow={1} mr="sp8">
                <Box mr="sp4" flexGrow={1}>
                  <TextInput
                    type="text"
                    name="custom header name"
                    placeholder={t("Header Name")}
                    value={customHeaderName}
                    onChange={handleChangeHeaderName}
                  />
                </Box>
                <Box ml="sp4" flexGrow={1}>
                  <TextInput
                    type="text"
                    name="custom header value"
                    placeholder={t("Header Value")}
                    value={customHeaderValue}
                    onChange={handleChangeHeaderValue}
                  />
                </Box>
              </Flex>
              <IconButton
                size="default"
                variant="primary"
                icon={<Icon icon="fa-plus" variant="solid" />}
                aria-label="add header"
                disabled={!customHeaderName || !customHeaderValue || customHeaders.some((h) => h.name === customHeaderName)}
                onClick={handleAddHeader}
              />
            </Flex>
            {customHeaders.length !== 0 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th headerText={t("Name")} />
                    <Th headerText={t("Value")} />
                    <Th headerText="" />
                  </Tr>
                </Thead>
                <Tbody>
                  {customHeaders.map((customHeader, index) => (
                    <Tr key={`custom-header-${index}`}>
                      <Td>
                        <Text fontSize="sm" color="text.paragraph">
                          {customHeader.name}
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm" color="text.paragraph">
                          {customHeader.value}
                        </Text>
                      </Td>
                      <Td>
                        <Flex justifyContent="center" alignItems="center">
                          <IconButton
                            size="sm"
                            variant="secondary"
                            icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
                            aria-label="delete header"
                            onClick={() => handleDeleteHeader(customHeader)}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              // Spacer so dropdown looks right
              <Box pt="sp96" pb="sp2" />
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Flex>
        <Button
          variant="primary"
          onClick={handleClickSubmit}
          isLoading={loading || disabled}
          aria-label="save"
          data-testid="save-policy-button"
        >
          {createPolicyText}
        </Button>
      </Flex>
      <CreateObjectPool
        open={objectPoolOpen}
        existingUrls={websiteCriteria}
        savePool={handleConvertObjectList}
        onClose={handleCloseObjectList}
        policyName={name}
      />
    </Modal>
  );
};

export default PolicyModal;

PolicyModal.contextTypes = {
  router: PropTypes.object.isRequired,
};
