import React from "react";
import errorIcon from "../../images/error.svg";
import showIcon from "../../images/icon_show.svg";
import hideIcon from "../../images/icon_hide.svg";
import infoIcon from "../../images/info.svg";
import readyIcon from "../../images/ready.svg";

import { isValidPassword, MINIMUM_PASSWORD_LENGTH } from "../utils/Validation";

function ShowHideControl(props) {
  return (
    <div className="password-item show-hide-widget">
      <img
        src={props.passwordVisible ? hideIcon : showIcon}
        onClick={props.onShowHide}
        alt={props.passwordVisible ? "hide password" : "show password"}
      />
      <button id="show-hide-control" onClick={props.onShowHide} className="password-item show-hide-widget">
        {!!props.passwordVisible ? "Hide" : "Show"}
      </button>
    </div>
  );
}

const PASSWORD_TIPS_URL = "https://help.linewize.com/hc/articles/5766977328284";
const PASSWORD_ERROR_MESSAGE = "Something went wrong. Please try again.";

function MessageHint(props) {
  let icon;
  switch (props.status) {
    case "info":
      icon = infoIcon;
      break;
    case "ready":
      icon = readyIcon;
      break;
    case "error":
      icon = errorIcon;
      break;
    default:
      icon = errorIcon;
      console.error("No icon for status", props.status);
  }
  return (
    <div>
      <img src={icon} alt={`${props.status} icon`} /> <span className="{props.status}">{props.msg}</span>{" "}
      {props.showWhyUrl && (
        <a href={PASSWORD_TIPS_URL} alt="Tips for creating a strong password">
          Why?
        </a>
      )}
    </div>
  );
}

function PasswordChangerRow(props) {
  return (
    <React.Fragment>
      <div className="password-reset-row">
        <div className="password-item">
          <label>{props.labelText}</label>
        </div>
        {props.showHideIcon && <ShowHideControl onShowHide={props.onShowHide} passwordVisible={props.passwordVisible} />}
      </div>
      <div className="password-item password-reset-row">
        <input
          id={props.inputId}
          className="password-item-inputbox mui-textfield"
          onChange={props.onChangePassword}
          type={!!props.passwordVisible ? "text" : "password"}
          value={props.password}
          autoComplete="new-password"
        ></input>
      </div>
    </React.Fragment>
  );
}

class PasswordChanger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
      isPasswordValid: false,
      bothPasswordsEntered: false,
      passwordsMatch: false,
      passwordTimerId: null,
      status: "info",
      msg: "",
      showWhyUrl: this.props.showWhyUrl,
    };
  }
  passwordLengthInfo = `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long.`;

  componentDidMount = () => {
    this.setState({ msg: this.passwordLengthInfo });
  };

  onChangePasswordInternal = (e) => {
    this.props.onChangePassword(e);

    this.validatePassword(e);
  };

  validatePassword = (e) => {
    const password = e.target.value;
    const valid = isValidPassword(password);
    let bothPasswordsEntered = false;
    let passwordsMatch = false;

    if (this.props.showConfirmation) {
      bothPasswordsEntered = this.props.password1.length > 0 && this.props.password2.length > 0;
      // Props may not have updated yet so compare against the latest data.
      passwordsMatch = e.target.id === "password1" ? password === this.props.password2 : password === this.props.password1;
    }

    this.setState(
      {
        isPasswordValid: valid,
        bothPasswordsEntered: bothPasswordsEntered,
        passwordsMatch: passwordsMatch,
      },
      () => {
        if (valid && (!this.props.showConfirmation || bothPasswordsEntered === passwordsMatch)) {
          this.updateMessage();
        } else {
          this.startPasswordLengthTimer();
        }
      }
    );
  };

  startPasswordLengthTimer = () => {
    if (this.state.passwordTimerId !== null) {
      clearTimeout(this.state.passwordTimerId);
    }

    const passwordTimerDuration = 2000;
    const timerId = () =>
      setTimeout(() => {
        this.updateMessage();
        this.setState({ passwordTimerId: null });
      }, passwordTimerDuration);

    this.setState({ passwordTimerId: timerId() });
  };

  updateMessage = () => {
    const passwordLengthError = `Please enter at least ${MINIMUM_PASSWORD_LENGTH} characters.`;
    const passwordsDontMatchMessage = "Passwords don't match.";
    const multiplePasswordsValidMessage = "Passwords look good. All set to go.";
    const singlePasswordValidMessage = "Password looks good. All set to go.";

    let status;
    let msg;

    if (!this.state.isPasswordValid) {
      status = "error";
      msg = passwordLengthError;
    } else if (this.props.showConfirmation && !this.state.bothPasswordsEntered) {
      status = "info";
      msg = this.passwordLengthInfo;
    } else if (this.props.showConfirmation && !this.state.passwordsMatch) {
      status = "error";
      msg = passwordsDontMatchMessage;
    } else {
      status = "ready";
      msg = this.props.showConfirmation ? multiplePasswordsValidMessage : singlePasswordValidMessage;
    }

    const showUrl = this.props.showWhyUrl && status !== "ready";

    this.setState({
      status: status,
      msg: msg,
      showWhyUrl: showUrl,
    });
  };

  onShowHide = (event) => {
    event.preventDefault();
    this.setState((state) => {
      return { passwordVisible: !state.passwordVisible };
    });
  };

  render() {
    return (
      <div className="password-reset-container">
        <PasswordChangerRow
          inputId="password1"
          labelText={"Password"}
          onChangePassword={this.onChangePasswordInternal}
          onShowHide={this.onShowHide}
          passwordVisible={this.state.passwordVisible}
          password={this.props.password1}
          showHideIcon="true"
        />
        {this.props.showConfirmation && (
          <PasswordChangerRow
            inputId="password2"
            labelText={"Confirm Password"}
            onChangePassword={this.onChangePasswordInternal}
            onShowHide={this.onShowHide}
            passwordVisible={this.state.passwordVisible}
            password={this.props.password2}
          />
        )}
        {this.props.showMessageHint && <MessageHint status={this.state.status} msg={this.state.msg} showWhyUrl={this.state.showWhyUrl} />}
      </div>
    );
  }
}

export { PasswordChanger, MessageHint, PASSWORD_TIPS_URL, PASSWORD_ERROR_MESSAGE };
