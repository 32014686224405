import { Box, Card, Text } from "@familyzone/component-library";
import React from "react";
import { Link } from "react-router";
import Api from "../../../utils/Api";
import { ApiDates } from "../../../utils/DateTimeUtil";
import { getUserByUsername } from "../../../utils/api/Users";
import { uriSafeBase64Encode } from "../../../utils/Base64Util";

interface IFactsCardSet {
  user: string;
  dateFilters: ApiDates;
}

const cardErrorMessage = "Error retrieving data.";

const FactsCardSet = ({ user, dateFilters }: IFactsCardSet): JSX.Element => {
  const [totalSearches, setTotalSearches] = React.useState<number | string>("Loading...");
  const [deviceCount, setDeviceCount] = React.useState<number | string>("Loading...");
  const [activeConnections, setActiveConnections] = React.useState<number | string>("Loading...");
  const [videoViews, setVideoViews] = React.useState<number | string>("Loading...");
  const [groupCount, setGroupCount] = React.useState<number | string>("Loading...");
  const [userId, setUserId] = React.useState<string>("");

  React.useEffect(() => {
    prepareCardData(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dateFilters]);

  const totalSearchesApi = (user: string) => {
    Api.get_analytics(
      "/sufwize/ajax/search/users/hits?filter_user=" + user,
      (data: SearchesApiResponse) => {
        let total_searches = 0;
        for (const item of data["result"]) {
          total_searches += item["hits"];
        }
        setTotalSearches(total_searches);
      },
      (_: unknown) => {
        setTotalSearches(cardErrorMessage);
      },
      { dateOverride: dateFilters }
    );
  };

  const hostApi = (user: string) => {
    Api.get(
      "/edgewize/device/analytics/clients/hosts",
      (data: HostApiResponse) => {
        let device_count = 0;
        let active_connections = 0;
        for (const host of data.data) {
          if (host.user === user) {
            device_count += 1;
            active_connections += host.total_active_connections;
          }
        }
        setDeviceCount(device_count);
        setActiveConnections(active_connections);
      },
      (_: unknown) => {
        setDeviceCount(cardErrorMessage);
        setActiveConnections(cardErrorMessage);
      }
    );
  };

  const videoViewsApi = (user: string) => {
    Api.post_analytics(
      "/surfwize/ajax/users/videos/details",
      { user: user },
      (data: VideoViewsApiResponse) => {
        setVideoViews(data.result.length);
      },
      (_: unknown) => {
        setVideoViews(cardErrorMessage);
      }
    );
  };

  const groupCountApi = (user: string) => {
    getUserByUsername(user)
      .then((userDetails) => {
        setUserId(userDetails.id);
        setGroupCount(userDetails.groups.length);
      })
      .catch(() => {
        setGroupCount(cardErrorMessage);
      });
  };

  const prepareCardData = (user: string) => {
    totalSearchesApi(user);
    hostApi(user);
    videoViewsApi(user);
    groupCountApi(user);
  };

  const boxes = [
    { stateValue: deviceCount, text: "Authenticated Devices", link: "/surfwize/device/status/hosts/" + user },
    { stateValue: activeConnections, text: "Active Connections", link: "/surfwize/device/status/connections/" + user },
    { stateValue: groupCount, text: "Groups", link: "/config/device/userdb/users/id/" + userId },
    { stateValue: totalSearches, text: "Total Searches", link: "/cybersafety/search/" + uriSafeBase64Encode(user) },
    { stateValue: videoViews, text: "Video Views", link: "/cybersafety/video?filter_user=" + user },
  ].map((cardItem, index) => {
    if (cardItem.stateValue === cardErrorMessage) {
      return <></>;
    } else {
      return (
        <Box background="neutrals.0" marginBottom="sp16" key={index} borderRadius="sm">
          <Card heading={typeof cardItem.stateValue === "number" ? `${cardItem.stateValue} ${cardItem.text}` : cardItem.stateValue}>
            <Link to={cardItem.link}>
              <Text color="brand.500" fontSize="md">
                View all
              </Text>
            </Link>
          </Card>
        </Box>
      );
    }
  });

  return <Box>{boxes}</Box>;
};

export default FactsCardSet;

interface SearchesApiResponse {
  result: {
    hits: number;
    searchWord: string;
  }[];
}

interface HostApiResponse {
  data: {
    bypass_expiry_time: number;
    fz_poll_time: number;
    host: string;
    hostname: string;
    hw: string;
    interface: string;
    lastSeenTime: number;
    loginTime: number;
    micro_lock: number;
    total_active_connections: number;
    total_packet_count: number;
    total_transfer_count: number;
    vendor: string;
    version: number;
    user?: string; // Note that this user property has been added to enable TS to compile, as I did not see it in the response
    // and the API call has been copied out of the user dashboard component to this component unchanged.
  }[];
}

interface VideoViewsApiResponse {
  result: {
    date: string;
    ip: string;
    mac: string;
    domain: string;
    uri: string;
    videoid: string;
    video_provider: string;
    user: string;
  }[];
}
