import React from "react";
import { Text, TextInput, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";

export interface GenericSingleStringInputProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  readableName: string;
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const GenericSingleStringInput: React.FC<GenericSingleStringInputProps> = ({ criteria, disabled, readableName, onChange }) => {
  const { t } = useTranslation();

  if (Array.isArray(criteria.conditions)) return <Text>{t("Invalid Criteria")}</Text>;

  const handleChangeUserAgent = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...criteria, conditions: { expression: e.target.value } });
  };

  return (
    <TextInput
      placeholder={t(readableName)}
      isDisabled={disabled}
      value={String(criteria.conditions.expression)}
      onChange={handleChangeUserAgent}
    />
  );
};

export default GenericSingleStringInput;
