import { create } from "zustand";
import { UserFriendlyGroupNames } from "../types/Groups";
import { batchResolveGroupDescriptions } from "../utils/api/Groups";

export interface GroupDescriptionStore {
  groups: Map<string, string>;
  setGroups: (groups: Map<string, string>) => void;
  // Use getGroups rather than accessing groups directly.
  // It will return any information already in the store, and
  // automatically fetch any missing info from the SM API.
  getGroups: (groupnames: string[]) => Promise<UserFriendlyGroupNames[]>;
}

export const useGroupDescriptionStore = create<GroupDescriptionStore>((set, get) => ({
  groups: new Map(),
  setGroups: (groups) => set(() => ({ groups: groups })),
  getGroups: async (groupnames): Promise<UserFriendlyGroupNames[]> => {
    const result: UserFriendlyGroupNames[] = [];
    const toFetch: string[] = [];
    const currentGroups: Map<string, string> = get().groups;
    for (const g of groupnames) {
      const d = currentGroups.get(g);
      if (d === undefined || d === null) {
        toFetch.push(g);
      } else {
        result.push({
          name: g,
          description: d,
        });
      }
    }

    if (toFetch.length === 0) {
      return result;
    }
    const fetchedGroups = await batchResolveGroupDescriptions(toFetch);

    for (let i = 0; i < fetchedGroups.length; i++) {
      const g = fetchedGroups[i];
      if (g.description !== undefined && g.description !== "") {
        result.push(g);
        const d: string = g.description;
        useGroupDescriptionStore.setState((prev) => ({
          groups: new Map(prev.groups).set(g.name, d),
        }));
      }
    }

    return result;
  },
}));
