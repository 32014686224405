import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { FeatureFlags } from "../types/FeatureFlags";
import Api from "../utils/Api";

export const FEATURE_FLAGS_URL = "/config/feature-flags";

export interface FeatureFlagStore {
  flags?: FeatureFlags;
  getOrFetch: () => Promise<FeatureFlags>;
  fetch: () => Promise<FeatureFlags>;
  save: (flags: FeatureFlags) => Promise<FeatureFlags>;
  reset: () => void;
}

const synchronized: { fetchResult?: Promise<FeatureFlags> } = {};

export const useFeatureFlagStore = create<FeatureFlagStore>((set, get) => ({
  flags: undefined,

  /**
   * Gets feature flags from the store, or if the store is empty, then fetches
   * the flags from Config Management API first.
   *
   * NOTE: The feature flags are used in `AppRoot` to decide access to routes, conditionally
   * render menu items and content on some pages. To avoid fetching the flags two-three times
   * when UI loads, this method limits the number of in-flight requests to just 1 when invoked
   * multiple times in quick succession and returns the same promise to all callers.
   *
   * @returns promise that completes with feature flags.
   */
  getOrFetch: async (): Promise<FeatureFlags> => {
    let flags = get().flags;
    if (flags && Object.keys(flags).length > 0) {
      return flags;
    }

    if (synchronized.fetchResult) {
      return await synchronized.fetchResult;
    }

    synchronized.fetchResult = get().fetch();
    flags = await synchronized.fetchResult;
    synchronized.fetchResult = undefined;

    return flags;
  },

  fetch: async (): Promise<FeatureFlags> => {
    let flags,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(FEATURE_FLAGS_URL).then(
      (response: { data: FeatureFlags }) => (flags = response.data),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!flags || statusCode !== 200) {
      throw new ResponseError("Failed to fetch feature flags", statusCode);
    }

    set({ flags });

    return flags;
  },

  save: async (flags: FeatureFlags): Promise<FeatureFlags> => {
    let saved: FeatureFlags | undefined,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.postAsync(FEATURE_FLAGS_URL, flags).then(
      () => (saved = { ...get().flags, ...flags }),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save feature flags", statusCode);
    }

    set({ flags: saved });

    return saved;
  },

  reset: () => {
    set({ flags: undefined });
  },
}));
