import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import DeleteButton from "../../modules/DeleteButton";

class AddException extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      macAddress: "",
    };
  }

  handleHide = () => {
    this.setState({
      macAddress: "",
      invalidMacIpValueMsg: null,
    });
    this.props.handleClose();
  };

  handle_ChangeMacAddress = (event) => {
    const valueError = this.props.macIpValueValidator(event.target.value);
    this.setState({ macAddress: event.target.value, invalidMacIpValueMsg: valueError });
  };

  is_disabled = () => this.state.invalidMacIpValueMsg || !this.state.macAddress;

  handle_Submit = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!this.is_disabled()) {
      this.props.handleAdd({ macAddress: this.state.macAddress });
      this.handleHide();
    }
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit} disabled={this.is_disabled()}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Exception" handleHide={this.handleHide} actions={this.renderActions()} disable_overflow={true}>
          <form className="mui-form" onSubmit={this.handle_Submit}>
            <div className="formgroup-element-title">Mac Address</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.macAddress} onChange={this.handle_ChangeMacAddress} />
              <div className="errorMessage">{this.state.invalidMacIpValueMsg}</div>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class Exceptions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      exceptions: [],
      visible__add_exception: false,
      loaded: false,
    };

    this.handle_load();
  }

  handle_load = () => {
    const self = this;
    Api.get("/config/ajax/sessions/exceptions", function (result) {
      const exceptions = result["data"];
      self.setState({
        exceptions: exceptions,
        loaded: true,
      });
    });
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add_exceptions: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_exceptions: false,
    });
  };

  handle_Delete = (entity) => {
    const self = this;
    Api.delete("/config/ajax/sessions/exceptions", { macAddress: entity }, function () {
      self.handle_load();
    });
  };

  handle__AddException = (entity) => {
    const self = this;
    Api.put("/config/ajax/sessions/exceptions", entity, function () {
      self.handle_load();
    });
  };

  macIpValueValidator = (value) => {
    let errorMsg = null;

    if (this.state.exceptions.some((entry) => entry === value)) {
      errorMsg = "This Mac/Ip Address is already in use";
    }

    return errorMsg;
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Mac Address",
        data: function (row) {
          return row;
        },
        sortable: (rowA, rowB) => {
          return FlexibleTable.sort_caseinsensitive_strings(rowA, rowB);
        },
        search: FlexibleTable.search__single_string_field(),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <DeleteButton onClick={() => self.handle_Delete(row)}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.exceptions} loaded={this.state.loaded} />;
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddException
          visible={this.state.visible__add_exceptions}
          handleAdd={this.handle__AddException}
          macIpValueValidator={this.macIpValueValidator}
          handleClose={this.handler__clickCloseAdd}
        ></AddException>

        <TableCentricPage
          icon="fa fa-user"
          title="Associations Exceptions"
          buttons={this.render__buttons()}
          content={this.render__content()}
        />
      </TableLeftPanel>
    );
  }
}
