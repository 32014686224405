import { Button, Modal, TextInput, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { UserUMS } from "../../../types/Users";
import { validateUsername } from "./UsersNewHelpers";
import { createUser } from "../../../utils/api/Users";
import { useToast } from "@familyzone/component-library";
import PropTypes from "prop-types";
import { RouterContext } from "../../../utils/RouterContext";

interface AddUserProps {
  users: UserUMS[];
}

const AddUser: React.FC<AddUserProps> = ({ users }, context: RouterContext) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { errorToast } = useToast();

  useEffect(() => {
    const validation = validateUsername(username, users);
    setErrorMessage(validation);
    setSubmitDisabled(validation !== undefined);
  }, [username, users]);

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onClose = () => {
    setIsOpen(false);
    setUsername("");
  };

  const handleAddUser = (username: string) => {
    void createUser(username)
      .then((id: string) => {
        context.router.push(`/config/device/userdb/users/id/${id}`);
      })
      .catch(() => {
        errorToast({ title: "Error", description: "Failed to create user, please try again later." });
      });
  };

  const onSubmit = () => {
    handleAddUser(username);
    onClose();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setIsOpen(true);
        }}
        data-testid="add-user-button"
      >
        {t("Add User")}
      </Button>
      <Modal
        headerText={t("Add User")}
        size="sm"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        primaryCTALabel={t("Add")}
        primaryCTADisabled={submitDisabled}
        onPrimaryCTAClick={onSubmit}
      >
        <TextInput data-testid="add-user-input" value={username} onChange={onUsernameChange} errorMessage={errorMessage} />
      </Modal>
    </>
  );
};

export default AddUser;
AddUser.contextTypes = {
  router: PropTypes.object.isRequired,
};
