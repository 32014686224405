export class ClassroomCSVObject {
  constructor(file) {
    this.file = file;
    this.entries = {};
    this.unique_users = new Set();
    this.unique_classrooms = new Set();
    this.lineErrors = 0;
    this.nameErrors = 0;
    this.linesRead = 0;
    this.processed = false;
  }

  getFile() {
    return this.file;
  }

  addEntry(username, classroom, is_owner) {
    if (!this.entries[classroom]) {
      this.entries[classroom] = {};
    }
    this.entries[classroom][username] = is_owner;
    this.unique_users.add(username);
    this.unique_classrooms.add(classroom);
  }

  addLineError() {
    this.lineErrors++;
  }

  addNameError() {
    this.nameErrors++;
  }

  addLineRead() {
    this.linesRead++;
  }

  getUniqueEntries() {
    return {
      users: this.unique_users.size,
      classrooms: this.unique_classrooms.size,
    };
  }

  setProcessed() {
    this.processed = true;
  }
}
