/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from "react";
import moment from "moment";
import DeviceStatusStore from "../../stores/DeviceStatusStore";
import { Box, Button, Modal, Text } from "@familyzone/component-library";
import Api from "../../utils/Api";
import { InfoResponse } from "../../types/ApiResponses";

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const hasDeviceInformation = (data: unknown): data is InfoResponse =>
  typeof data === "object" && data !== null && "device_information" in data && !!data.device_information;

const DeviceFilteringModal: React.FC<Props> = ({ modalOpen = false, setModalOpen }) => {
  // eslint-disable-next-line
  const [status, setStatus] = React.useState<number>(DeviceStatusStore.getStackStatus());
  const [error, setError] = React.useState<string>();
  const filtered = () => status === 0 || status < moment().unix();

  const toggleFiltering = () => {
    if (filtered()) updateStackDisabledFlag(moment().unix() + 300);
    if (!filtered()) updateStackDisabledFlag(0);
    setModalOpen(false);
  };

  const updateStackDisabledFlag = (value: number) => {
    Api.post(
      "/managedevice/ajax/device/advanced",
      { key: "STACK_DISABLED_FLAG", value: value },
      function () {
        setStatus(value);
      },
      function (_err: unknown) {
        setError("Unable to change filtering. Please try again later or contact support if the problem persists.");
      }
    );
  };

  React.useEffect(() => {
    Api.get(
      "/info",
      function (result: unknown) {
        if (hasDeviceInformation(result)) {
          setStatus(result.device_information.stack_disabled_flag);
        }
      },
      function (_err: unknown) {
        setError("Cannot retrieve device information at this time. Please try again later or contact support if the problem persists.");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ModalBodyContent: React.FC = () => {
    if (error)
      return (
        <Box>
          <Text marginY="20">{error}</Text>
          <Button onClick={() => setModalOpen(false)} variant="secondary">
            Ok
          </Button>
        </Box>
      );
    if (filtered()) {
      return (
        <Box>
          <Text marginY="20">
            To help debug filtering issues, you can disable the Linewize filtering stack for 5 minutes. Please be warned, this will switch
            of all filtering, including authentication and adult content filtering for 5 minutes.
          </Text>
          <Button onClick={toggleFiltering} variant="danger">
            Disable Filtering
          </Button>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text marginY="20">Filtering is currently disabled. Click below to re-enable filtering.</Text>
          <Button onClick={toggleFiltering} variant="primary">
            Enable Filtering
          </Button>
        </Box>
      );
    }
  };

  return (
    <Modal headerText="Disable Filtering Temporarily" size="md" isOpen={modalOpen} onClose={() => setModalOpen(false)}>
      <ModalBodyContent />
    </Modal>
  );
};

export default DeviceFilteringModal;
