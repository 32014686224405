import {
  Box,
  Button,
  Text,
  useTranslation,
  Spinner,
  useToast,
  InlineNotification,
  Radio,
  RadioGroup,
  Option,
} from "@familyzone/component-library";
import React, { FunctionComponent, useEffect, useState } from "react";
import CardBasedPage from "../../templates/CardBasedPage";
import { SDL_API, CoursesConfigurationResponse, UpdateVisibilityPayload, ClusivityType } from "../../../utils/api/CoursesConfig";
import { MultiGroupSearchSelector } from "../../GroupSearch/MultiGroupSearchSelector";
import { MultiUserSearchSelectorReturningOptions } from "../../UserSearch/MultiUserSearchSelectorReturningOptions";
import { mapClusivityToRadioOption } from "./utils";

export enum ClusivityRadioOption {
  All = "All",
  Inclusive = "Inclusive",
  Exclusive = "Exclusive",
  None = "None",
}

const Courses: FunctionComponent = () => {
  const { t } = useTranslation();
  const title = t("Courses");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Courses"), isActive: true },
  ];

  const { successToast, errorToast } = useToast();

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [errorLoadingConfig, setErrorLoadingConfig] = useState<boolean>(false);
  const [savingConfig, setSavingConfig] = useState<boolean>(false);

  const [selectedRadioOption, setSelectedRadioOption] = useState<ClusivityRadioOption>(ClusivityRadioOption.All);
  const [selectedGroups, setSelectedGroups] = useState<Option[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);

  const handleChangeClusivity = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioOption(event.target.value as ClusivityRadioOption);
  };

  const handleChangeGroups = (groups: Option[]): void => {
    setSelectedGroups(groups);
  };

  const handleChangeUsers = (users: Option[]): void => {
    setSelectedUsers(users);
  };

  function displayConfigFailedToast(message: string): void {
    errorToast({
      title: t("Error saving Courses configuration"),
      description: message,
      position: "top",
      isClosable: true,
    });
  }

  function fetchCoursesConfig(): void {
    setLoadingConfig(true);
    SDL_API.getConfiguration()
      .then((response) => {
        if (!response.ok) {
          setLoadingConfig(false);
          setErrorLoadingConfig(true);
          return;
        }

        response
          .json()
          .then((responseData: CoursesConfigurationResponse) => {
            const clusivity = responseData.clusivity;
            setSelectedRadioOption(mapClusivityToRadioOption(clusivity));
            if (clusivity.users) setSelectedUsers(clusivity.users.map((user) => ({ value: user.id, label: user.username })));
            if (clusivity.groups) setSelectedGroups(clusivity.groups.map((group) => ({ value: group.id, label: group.name })));
            setLoadingConfig(false);
          })
          .catch(() => {
            setLoadingConfig(false);
            setErrorLoadingConfig(true);
          });
      })
      .catch(() => {
        setLoadingConfig(false);
        setErrorLoadingConfig(true);
      });
  }

  useEffect(() => {
    fetchCoursesConfig();
  }, []);

  function saveConfiguration(): void {
    setSavingConfig(true);

    // form correct payload based on configuration
    const visibilityPayload: UpdateVisibilityPayload = {
      clusivity: ClusivityType.All,
    };

    if (selectedRadioOption === ClusivityRadioOption.All) {
      visibilityPayload.clusivity = ClusivityType.All;
    }

    if (selectedRadioOption === ClusivityRadioOption.Inclusive) {
      visibilityPayload.clusivity = ClusivityType.Inclusive;
      visibilityPayload.users = selectedUsers.map((user) => user.value as string);
      visibilityPayload.groups = selectedGroups.map((group) => group.value as string);
    }

    if (selectedRadioOption === ClusivityRadioOption.Exclusive) {
      visibilityPayload.clusivity = ClusivityType.Exclusive;
      visibilityPayload.users = selectedUsers.map((user) => user.value as string);
      visibilityPayload.groups = selectedGroups.map((group) => group.value as string);
    }

    if (selectedRadioOption === ClusivityRadioOption.None) {
      visibilityPayload.clusivity = ClusivityType.Inclusive;
      visibilityPayload.users = [];
      visibilityPayload.groups = [];
    }

    SDL_API.updateVisibility(visibilityPayload)
      .then((response) => {
        if (!response.ok) {
          displayConfigFailedToast(t("Please contact support if the issue persists."));
          setSavingConfig(false);
          return;
        }
        successToast({
          title: t("Saved successfully"),
          description: t("Your Courses configuration has been saved successfully."),
          position: "top",
          isClosable: true,
        });
      })
      .catch(() => {
        displayConfigFailedToast(t("Please contact support if the issue persists."));
        setSavingConfig(false);
        return;
      })
      .finally(() => {
        setSavingConfig(false);
        return;
      });

    return;
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        {errorLoadingConfig && (
          <Box mb={10}>
            <InlineNotification
              notificationTitle={t("Error fetching Courses configuration")}
              notificationDescription={t("Please contact support if the issue persists.")}
              status="error"
            />
          </Box>
        )}

        <Box>
          <Box mb={20}>
            <Text fontWeight={"medium"} mb={3}>
              Courses tab visibility
            </Text>
            <Text color={"#42526E"} fontSize={"sm"}>
              Choose who can see the Courses tab in Classwize
            </Text>
          </Box>

          {loadingConfig ? (
            <Spinner />
          ) : (
            <>
              <RadioGroup name="clusivity" direction="column" value={selectedRadioOption}>
                {Object.values(ClusivityRadioOption).map((clusivityType) => {
                  let label = clusivityType;
                  if (clusivityType === ClusivityRadioOption.All) {
                    label = t("Show to all teachers");
                  } else if (clusivityType === ClusivityRadioOption.Inclusive) {
                    label = t("Only show to selected teachers and groups");
                  } else if (clusivityType === ClusivityRadioOption.Exclusive) {
                    label = t("Only hide from selected teachers and groups");
                  } else if (clusivityType === ClusivityRadioOption.None) {
                    label = t("Hide from all teachers");
                  }
                  return (
                    <Radio
                      key={clusivityType}
                      value={clusivityType}
                      onChange={handleChangeClusivity}
                      isChecked={selectedRadioOption === clusivityType}
                    >
                      {label}
                    </Radio>
                  );
                })}
              </RadioGroup>

              {!!selectedRadioOption &&
                (selectedRadioOption === ClusivityRadioOption.Inclusive || selectedRadioOption === ClusivityRadioOption.Exclusive) && (
                  <Box mt={20}>
                    <Text fontWeight={"medium"} mb={6} mt={20}>
                      {t(`Select Users`)}
                    </Text>
                    <MultiUserSearchSelectorReturningOptions preselected={selectedUsers} onChangeUsers={handleChangeUsers} />
                    <Text fontWeight={"medium"} mb={6} mt={20}>
                      {t(`Select Groups`)}
                    </Text>
                    <MultiGroupSearchSelector preselected={selectedGroups} onChangeGroups={handleChangeGroups} />
                  </Box>
                )}
            </>
          )}
        </Box>
      </Box>
      <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
        <Button variant="primary" disabled={savingConfig || loadingConfig || !selectedRadioOption} onClick={saveConfiguration}>
          {savingConfig ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default Courses;
