import React from "react";
import $ from "jquery";
import uuid from "uuid/v4";
import "../../css/ModalWindow.css";

export default class ModalWindow extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      element_id: uuid(),
    };
  }

  componentDidMount() {
    let modal = $("#" + this.state.element_id);
    modal.modal("show");
    modal.on("hidden.bs.modal", this.props.handleHide);
  }

  componentWillUnmount() {
    $("#" + this.state.element_id).modal("hide");
  }

  renderCustomClass = () => {
    return this.props.customClass ? this.props.customClass : "";
  };

  getBodyClass = () => {
    if (this.props.disable_overflow === true) {
      return "modal-body";
    }

    return "modal-body modal-body-overflow";
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 13 && this.props.defaultEnterAction) {
      event.stopPropagation();
      this.props.defaultEnterAction(event);
    }
  };

  render() {
    return (
      <div
        id={this.state.element_id}
        data-keyboard="false"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="categoryModalLabel"
        aria-hidden="true"
        onKeyUp={this.handleKeyUp}
      >
        <div className={"modal-dialog " + this.renderCustomClass()} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
              <h3 id="categoryModalLabel">{this.props.title}</h3>
              {this.props.navigation}
            </div>
            <div className={this.getBodyClass()}>{this.props.children}</div>
            <div className="modal-footer">{this.props.actions}</div>
          </div>
        </div>
      </div>
    );
  }
}
