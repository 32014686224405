import React from "react";

import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import RatelimiterQosModal from "./RatelimiterQosModal";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rule: this.props.rule,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      rule: nextprops.rule,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    const rule = this.state.rule;
    rule.name = event.target.value;
    this.setState({ rule: rule });
  };

  handle_SourceCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.source_criteria = criteria;
    this.setState({ rule: rule });
  };

  handle_DestinationCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.destination_criteria = criteria;
    this.setState({ rule: rule });
  };
  handle_ChangePerUser = (event) => {
    const rule = this.state.rule;
    rule.per_user = event.target.checked;
    this.setState({ rule: rule });
  };
  handle_ChangePerDevice = (event) => {
    const rule = this.state.rule;
    rule.per_device = event.target.checked;
    this.setState({ rule: rule });
  };

  handle_ChangeUpload = (event) => {
    const rule = this.state.rule;
    rule.upload = event.value;
    this.setState({ rule: rule });
  };
  handle_ChangeDownload = (event) => {
    const rule = this.state.rule;
    rule.download = event.value;
    this.setState({ rule: rule });
  };

  handle_changeState = (rule) => {
    this.setState({ rule: rule });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.rule);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };
  render() {
    if (this.props.visible) {
      return (
        <RatelimiterQosModal
          handleHide={this.handleHide}
          isOpen={this.props.visible}
          saveSelection={this.handle_Submit}
          selectedDestinationCriteria={this.state.rule.destination_criteria}
          selectedSourceCriteria={this.state.rule.source_criteria}
          updateParentSourceState={this.handle_SourceCriteriaChanged}
          updateParentPriorityState={this.handle_ChangeDcspValue}
          updateParentDestinationState={this.handle_DestinationCriteriaChanged}
          changeRuleState={this.handle_changeState}
          rule={this.state.rule}
        />
      );
    }

    return <div />;
  }
}

export default class RatelimiterQos extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/qos/limiter", function (result) {
      self.setState({
        rules: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    let self = this;
    Api.put("/edgewize/ajax/qos/limiter", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Rule" onClickHandler={this.handler__clickAdd} />;
  };

  handle_Delete = (row) => {
    let self = this;
    Api.delete("/edgewize/ajax/qos/limiter", { id: row["id"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    let self = this;
    Api.post("/edgewize/ajax/qos/limiter", rule, function (result) {
      self.handle_load();
    });
  };

  handle_toggleRule = (row) => {
    row["enabled"] = !row["enabled"];

    let self = this;
    Api.post("/edgewize/ajax/qos/limiter", row, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__add_permission: true,
    });
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Enabled",
        data: function (row) {
          return <input type="checkbox" checked={row["enabled"]} onChange={() => self.handle_toggleRule(row)} />;
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          return row["name"];
        },
        search: FlexibleTable.search__single_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Source",
        data: function (row) {
          return <CriteriaPrinter criteria={row["source_criteria"]} />;
        },
        onclick: undefined,
        search: FlexibleTable.search__criteria_printer("source_criteria"),
      },
      {
        title: "Destination",
        data: function (row) {
          return <CriteriaPrinter criteria={row["destination_criteria"]} />;
        },
        onclick: undefined,
        search: FlexibleTable.search__criteria_printer("destination_criteria"),
      },
      {
        title: "Upload Rate",
        data: function (row) {
          const find = valueDataMap.find((item) => item[0] === row["upload"]);
          if (find) {
            return find[1];
          }
        },
        onclick: undefined,
        search: function (row, search_expression) {
          if (search_expression === "") {
            return true;
          } else {
            const find = valueDataMap.find((item) => item[0] === row["upload"]);
            if (find) {
              return find[1].toLowerCase().includes(search_expression.toLowerCase());
            }
            return false;
          }
        },
      },
      {
        title: "Download Rate",
        data: function (row) {
          const find = valueDataMap.find((item) => item[0] === row["download"]);
          if (find) {
            return find[1];
          }
        },
        onclick: undefined,
        search: function (row, search_expression) {
          if (search_expression === "") {
            return true;
          } else {
            const find = valueDataMap.find((item) => item[0] === row["download"]);
            if (find) {
              return find[1].toLowerCase().includes(search_expression.toLowerCase());
            }
            return false;
          }
        },
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.rules} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddEditRule
          visible={this.state.visible__add_permission}
          handleSave={this.handle__SaveRule}
          handleClose={this.handler__clickCloseAdd}
          rule={this.state.editing__permission}
        ></AddEditRule>

        <TableCentricPage icon="fa fa-filter" title="Rate Limiting" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}

const valueDataMap = [
  [125000, "1Mbit"],
  [250000, "2Mbit"],
  [1250000, "10Mbit"],
  [1875000, "15Mbit"],
  [2560000, "20Mbit"],
  [3125600, "25Mbit"],
  [3756000, "30Mbit"],
  [4375600, "35Mbit"],
  [6250000, "50Mbit"],
  [12500000, "100Mbit"],
  [62500000, "500Mbit"],
  [125000000, "1Gbit"],
  [250000000, "2Gbit"],
];
