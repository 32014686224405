import React, { useEffect, useState, useCallback } from "react";
import { TextInput, Modal, ModalBody, useTranslation, useToast, InlineNotification, Box, Link } from "@familyzone/component-library";
import { Link as ReactLink } from "react-router";
import { isValidEmail } from "../../utils/Validation";
import { Guardian, Student } from "../../types/Community";
import { useGuardianStore } from "../../storez/GuardianStore";
import { ResponseError } from "../../types/Api";

interface EditParentModalProps {
  guardian: Guardian;
  show: boolean;
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
  handleFail?: (message: string) => void;
}

const EditParentModal: React.FC<EditParentModalProps> = ({ guardian, show, handleHide, handleSuccess }: EditParentModalProps) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [invalidFirstNameReason, setInvalidFirstNameReason] = useState<string | undefined>(undefined);
  const [invalidLastNameReason, setInvalidLastNameReason] = useState<string | undefined>(undefined);
  const [invalidEmailReason, setInvalidEmailReason] = useState<string | undefined>(undefined);

  const [connectedStudents, setConnectedStudents] = useState<Student[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setFirstName(guardian.firstName);
      setMiddleName(guardian.middleName ?? "");
      setLastName(guardian.lastName);
      setEmail(guardian.email);

      void useGuardianStore
        .getState()
        .fetchGuardianDetails(guardian.email)
        .then((details) => {
          setConnectedStudents(details.students?.filter((s) => s.claimed) ?? []);
        })
        .finally(() => setLoading(false));
    } else {
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setEmail("");
      setInvalidFirstNameReason(undefined);
      setInvalidLastNameReason(undefined);
      setInvalidEmailReason(undefined);
      setConnectedStudents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const changed = (): boolean => {
    return (
      firstName !== guardian.firstName ||
      middleName !== (guardian.middleName ?? "") ||
      lastName !== guardian.lastName ||
      email !== guardian.email
    );
  };

  const validInput: () => boolean = useCallback(() => {
    return (invalidFirstNameReason || invalidLastNameReason || invalidEmailReason) === undefined;
  }, [invalidFirstNameReason, invalidLastNameReason, invalidEmailReason]);

  function validateFirstName(firstName: string) {
    let firstname_error = undefined;
    if (firstName.length === 0) {
      firstname_error = "First name is required";
    }
    setInvalidFirstNameReason(firstname_error);
  }

  function validateEmail(email: string) {
    let email_error = undefined;
    if (email.length === 0) {
      email_error = "Email is required";
    } else if (!isValidEmail(email)) {
      email_error = "Please enter a valid email address";
    }
    setInvalidEmailReason(email_error);
  }

  function validateLastName(lastName: string) {
    let lastname_error = undefined;
    if (lastName.length === 0) {
      lastname_error = "Last name is required";
    }
    setInvalidLastNameReason(lastname_error);
  }

  function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
    validateFirstName(event.target.value);
  }

  function handleChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
    setMiddleName(event.target.value);
  }

  function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
    validateLastName(event.target.value);
  }

  function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  }

  function handleSubmit() {
    setLoading(true);

    void useGuardianStore
      .getState()
      .updateGuardian(guardian.email, { ...guardian, firstName, middleName, lastName, email })
      .then(
        (updated) => {
          handleSuccess(updated);
          successToast({
            title: t("Parent updated successfully"),
            description: t("Parent") + ` ${updated.firstName} ${updated.lastName} ` + t("details have been successfully updated"),
            isClosable: true,
          });
        },
        (err: ResponseError) => {
          if (err.status === 409) {
            setInvalidEmailReason("Entered email address is already in use by a different parent");
          } else {
            errorToast({
              title: t("Please try again"),
              description: t(err.message),
              isClosable: true,
            });
          }
        }
      )
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      headerText={t("Edit Parent Details")}
      isOpen={show}
      onClose={handleHide}
      size="sm"
      primaryCTALabel={t("Save")}
      primaryCTADisabled={loading || !changed() || !validInput()}
      onPrimaryCTAClick={handleSubmit}
      secondaryCTALabel={t("Cancel")}
      secondaryCTADisabled={loading}
      onSecondaryCTAClick={handleHide}
    >
      <ModalBody>
        {connectedStudents.length ? (
          <Box mb="sp16">
            <InlineNotification
              status="warning"
              notificationDescription={
                <Box color="text.title">
                  {t(
                    "This parent cannot be edited due to Qustodio connection of their other " +
                      (connectedStudents.length < 2 ? "student" : "students")
                  )}{" "}
                  {connectedStudents.map((s, i) => (
                    <>
                      <Link as={ReactLink} to={"/config/device/userdb/users/username/" + s.username} isExternal={true} fontWeight="medium">
                        {s.firstName} {s.lastName}
                      </Link>
                      {i < connectedStudents.length - 2 ? ", " : i < connectedStudents.length - 1 && " " + t("and") + " "}
                    </>
                  ))}
                </Box>
              }
            />
          </Box>
        ) : (
          <></>
        )}

        <form className="modal-form">
          <TextInput
            label="First Name"
            placeholder=""
            name="first-name"
            value={firstName}
            onChange={handleChangeFirstName}
            isDisabled={loading || !!connectedStudents.length}
            isRequired={true}
            errorMessage={invalidFirstNameReason}
          />
          <TextInput
            label="Middle Name"
            placeholder=""
            name="middle-name"
            value={middleName}
            onChange={handleChangeMiddleName}
            isDisabled={loading || !!connectedStudents.length}
          />
          <TextInput
            label="Last Name"
            placeholder=""
            name="last-name"
            value={lastName}
            onChange={handleChangeLastName}
            isDisabled={loading || !!connectedStudents.length}
            isRequired={true}
            errorMessage={invalidLastNameReason}
          />
          <TextInput
            label="Email Address"
            placeholder=""
            name="email"
            value={email}
            onChange={handleChangeEmail}
            isDisabled={loading || !!connectedStudents.length}
            isRequired={true}
            errorMessage={invalidEmailReason}
          />
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditParentModal;
