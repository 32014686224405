import React from "react";
import LineGraph from "../../modules/LineGraph";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";

export default class DiagnosticsMetrics extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      "system.cpu_usage": { data: [], loaded: false },
      "system.proc.meminfo.MemAvailable": { data: [], loaded: false },
      "firewall.conntracker.tcp.size": { data: [], loaded: false },
      "firewall.conntracker.udp.size": { data: [], loaded: false },
    };
    this.unsubFilterStore = null;
  }

  componentDidMount = () => {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    this.onChange();
  };

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onChange = () => {
    this.handle_load("system.cpu_usage");
    this.handle_load("system.proc.meminfo.MemAvailable");
    this.handle_load("firewall.conntracker.tcp.size");
    this.handle_load("firewall.conntracker.udp.size");
  };

  handle_load = (metric) => {
    Api.get_analytics(
      "/managedevice/ajax/device/metric/" + metric,
      (result) => {
        this.setState({
          [metric]: { data: result["data"], loaded: true },
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  render() {
    return (
      <LeftPanel>
        <div className="padded">
          <h2>Metrics</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">CPU Usage</div>
                </div>

                <LineGraph
                  data={this.state["system.cpu_usage"].data}
                  container="system_cpu_usage"
                  xAxisFormatter="formatUnixTimestamp"
                  dataLoaded={this.state["system.cpu_usage"].loaded}
                />
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Available Memory</div>
                </div>
                <LineGraph
                  data={this.state["system.proc.meminfo.MemAvailable"].data}
                  container="system_memory_available_graph"
                  xAxisFormatter="formatUnixTimestamp"
                  dataLoaded={this.state["system.proc.meminfo.MemAvailable"].loaded}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">TCP Connection Tracker Size</div>
                </div>

                <LineGraph
                  data={this.state["firewall.conntracker.tcp.size"].data}
                  container="tcp_graph"
                  xAxisFormatter="formatUnixTimestamp"
                  dataLoaded={this.state["firewall.conntracker.tcp.size"].loaded}
                />
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">UDP Connection Tracker Size</div>
                </div>
                <LineGraph
                  data={this.state["firewall.conntracker.udp.size"].data}
                  container="udp_graph"
                  xAxisFormatter="formatUnixTimestamp"
                  dataLoaded={this.state["firewall.conntracker.udp.size"].loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </LeftPanel>
    );
  }
}
