import { headers } from "./ApiHelpers";

export enum LicensedFeature {
  Classroom = "CLASSROOM_MANAGEMENT",
  Courses = "COURSES",
}

export interface LicensedFeaturesResonseShape {
  applianceId: string;
  features: LicensedFeature[];
}

export const CLASSROOM_API = {
  /**
   * Get the licensed features for a given appliance
   */
  getLicencedFeatures(): Promise<Response> {
    return fetch(`/teacher-portal/config/v1/appliances/${headers().Device}/classwize/licensed-features`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
    });
  },
  /**
   * Set the licensed features for a given appliance
   * @param features - Array of features to be licensed. Eg. `['CLASSROOM_MANAGEMENT', 'COURSES']`
   */
  updateLicencedFeatures(features: LicensedFeature[]): Promise<Response> {
    return fetch(`/teacher-portal/config/v1/appliances/${headers().Device}/classwize/licensed-features`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
      body: JSON.stringify({
        features: features,
      }),
    });
  },
};
