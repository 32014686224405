import React, { ReactChild } from "react";
import { Box } from "@familyzone/component-library";
import PageWithHeader from "./PageWithHeader";

export interface CardBasedPageProps {
  breadcrumbs?: {
    title: string;
    isActive: boolean;
  }[];
  title?: string;
  /**
   * Elements displayed right-hand side in the header section.
   */
  tools?: JSX.Element;
  /**
   * Action buttons displayed between header and the main content.
   */
  actions?: ReactChild | ReactChild[];
  /**
   * The main content.
   */
  children?: ReactChild | ReactChild[];
  topMargin?: string;
}

const CardBasedPage = (props: CardBasedPageProps): JSX.Element => {
  const { breadcrumbs, title, tools, actions, children = <></>, topMargin = "sp24" } = props;

  return (
    <PageWithHeader title={title} breadcrumbs={breadcrumbs} tools={tools}>
      <>
        {actions && (
          <Box mt="sp24" mx="sp24" textAlign="right">
            {actions}
          </Box>
        )}
        <Box mx="sp24" mt={topMargin} mb="sp24" bgColor="neutrals.0" borderRadius="6px">
          {children}
        </Box>
        {/*force bottom margin of the above box to display; otherwise, the*/}
        {/*spacing between content box and bottom window border gets collapsed */}
        <Box minHeight={1}></Box>
      </>
    </PageWithHeader>
  );
};

export default CardBasedPage;
