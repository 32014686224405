import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Button, Checkbox, Input, Modal, Select, useTranslation } from "@familyzone/component-library";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria, Fingerprint, IpObject, MacObject } from "../../modules/criteria/criteriaTypes";
import { getFingerprints, getObjects, getTimePeriods } from "../../pages/filtering/ApiHelpers";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";
import { Item } from "@familyzone/component-library/dist/components/Select";
import { TimePeriod } from "../../pages/filtering/types";

interface RateLimiterRule {
  name: string;
  per_device: boolean;
  per_user: boolean;
  download: number;
  upload: number;
  source_criteria?: FilteringSourceCriteria[];
  destination_criteria?: FilteringSourceCriteria[];
}

interface IRatelimiterQosModalProps {
  handleHide: () => void;
  isOpen: boolean;
  saveSelection: (rule: unknown) => void;
  selectedSourceCriteria: FilteringSourceCriteria[];
  selectedDestinationCriteria: FilteringSourceCriteria[];
  updateParentSourceState: (criteria: FilteringSourceCriteria[]) => void;
  updateParentDestinationState: (criteria: FilteringSourceCriteria[]) => void;
  changeRuleState: (rule: RateLimiterRule) => void;
  rule: RateLimiterRule;
}

const RatelimiterQosModal: React.FC<IRatelimiterQosModalProps> = ({
  handleHide,
  isOpen,
  saveSelection,
  selectedSourceCriteria,
  selectedDestinationCriteria,
  updateParentDestinationState,
  updateParentSourceState,
  changeRuleState,
  rule,
}) => {
  const { t } = useTranslation();
  const [sourceSelection, setSourceSelection] = React.useState<FilteringSourceCriteria[]>(selectedSourceCriteria);
  const [destinationSelection, setDestinationSelection] = React.useState<FilteringSourceCriteria[]>(selectedDestinationCriteria);
  const [ipObjects, setIpObjects] = React.useState<IpObject[]>([]);
  const [macObjects, setMacObjects] = React.useState<MacObject[]>([]);
  const [fingerprints, setFingerprints] = React.useState<Fingerprint[]>([]);
  const [timePeriods, setTimePeriods] = React.useState<TimePeriod[]>([]);
  const [loading, setLoading] = React.useState(true);

  const sourceOptions: Partial<CriteriaTypes> = {
    "source.user": "User",
    protocol: "Protocol",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "source.mac": "Mac Address",
    fingerprint: "Device Type",
    "source.mac.pool": "Mac Address Object",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
    timeperiod: "Time Periods",
    group: "Group",
  };

  const destinationOptions: Partial<CriteriaTypes> = {
    "application.http.contenttype.regex": "Content Type",
    "application.http.useragent.regex": "User Agent",
    "application.http.request.regex": "HTTP Request Path",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "application.http.hostname.regex": "Website",
    signature: "Signature",
    "application.http.hostname": "Website Objects",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
    geoip: "Country",
  };

  const handleChangeSource = (criteria: FilteringSourceCriteria[]) => {
    updateParentSourceState(criteria);
    setSourceSelection(criteria);
  };

  const handleChangeDestination = (criteria: FilteringSourceCriteria[]) => {
    updateParentDestinationState(criteria);
    setDestinationSelection(criteria);
  };

  const handleSave = () => {
    saveSelection(rule);
  };

  const setObjectsCallback = React.useCallback(async () => {
    const result = await getObjects();
    setFingerprints(await getFingerprints());
    setTimePeriods(await getTimePeriods());
    const ipObjects = result.filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0);
    const macObjects = result.filter((obj): obj is MacObject => obj.type === 4);
    setIpObjects(ipObjects);
    setMacObjects(macObjects);
  }, []);

  React.useEffect(() => {
    void setObjectsCallback();
    setLoading(false);
  }, [setObjectsCallback]);

  const setSelectedOption = (setValue: number): Item | undefined => {
    const foundObject = RateLimiterOptions.find((item) => item.value === setValue);
    if (!foundObject) return;
    else return { value: foundObject.text, text: foundObject.text };
  };

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={() => handleHide()}
      headerText={t("Edit Rule")}
      contentProps={{ style: { overflow: "scroll" } }}
    >
      <>
        {loading && <CardSpinner />}
        {!loading && (
          <Stack spacing="sp12" mt="sp8">
            <FormControl>
              <FormLabel>{t("Name")}</FormLabel>
              <Input value={rule.name} onChange={(e) => changeRuleState({ ...rule, name: e.target.value })} placeholder="Rule Name" />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Source")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                macObjects={macObjects}
                fingerprints={fingerprints}
                customOptions={sourceOptions}
                selected={sourceSelection}
                timePeriods={timePeriods}
                onChange={(criteria) => handleChangeSource(criteria)}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Destination")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                customOptions={destinationOptions}
                selected={destinationSelection}
                onChange={(criteria) => handleChangeDestination(criteria)}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Per User")}</FormLabel>
              <Checkbox isChecked={rule.per_user} onChange={() => changeRuleState({ ...rule, per_user: !rule.per_user })} />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Per Device")}</FormLabel>
              <Checkbox isChecked={rule.per_device} onChange={() => changeRuleState({ ...rule, per_device: !rule.per_device })} />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Upload Rate")}</FormLabel>
              <Select
                items={RateLimiterOptions.map((item) => ({ value: item.value.toString(), text: item.text }))}
                placeholder={"Select an upload rate"}
                selectedItem={setSelectedOption(rule.upload)}
                onChange={(item) => {
                  if (item) changeRuleState({ ...rule, upload: parseInt(item.value) });
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Download Rate")}</FormLabel>
              <Select
                items={RateLimiterOptions.map((item) => ({ value: item.value.toString(), text: item.text }))}
                placeholder={"Select a download rate"}
                selectedItem={setSelectedOption(rule.download)}
                onChange={(item) => {
                  if (item) changeRuleState({ ...rule, download: parseInt(item.value) });
                }}
              />
            </FormControl>
            <FormControl>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>
            </FormControl>
          </Stack>
        )}
      </>
    </Modal>
  );
};

export default RatelimiterQosModal;

const RateLimiterOptions = [
  { value: 125000, text: "1Mbit" },
  { value: 250000, text: "2Mbit" },
  { value: 1250000, text: "10Mbit" },
  { value: 1875000, text: "15Mbit" },
  { value: 2560000, text: "20Mbit" },
  { value: 3125600, text: "25Mbit" },
  { value: 3756000, text: "30Mbit" },
  { value: 4375600, text: "35Mbit" },
  { value: 6250000, text: "50Mbit" },
  { value: 12500000, text: "100Mbit" },
  { value: 62500000, text: "500Mbit" },
  { value: 125000000, text: "1Gbit" },
  { value: 250000000, text: "2Gbit" },
];
