import { GetGroups, GroupSingle, GroupUMS, SearchGroupsResponse, UserFriendlyGroupNames } from "../../types/Groups";
import { headers } from "./ApiHelpers";
import { PurgeType } from "./Helpers";

const baseUrl = "/api/groups";

export const createGroup = async (name: string): Promise<string> => {
  const raw = await fetch(`${baseUrl}`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ name }),
  });
  if (raw.status !== 201) throw new Error("Invalid response from server");

  const response: unknown = await raw.json();
  if (!(response !== null && typeof response === "object" && "id" in response && typeof response.id === "string")) {
    throw new Error("Invalid response from server");
  }
  return response.id;
};

export const getGroups = async (): Promise<GetGroups> => {
  const raw = await fetch(baseUrl, {
    headers: headers(),
  });
  const response: unknown = await raw.json();
  if (isGetGroups(response)) return response;
  else throw new Error("Invalid response from server");
};

export const getGroup = async (id: string): Promise<GroupSingle> => {
  const raw = await fetch(`${baseUrl}/${id}`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();
  if (isGroup(response) && isGroupSingle(response)) return response;
  else throw new Error("Invalid response from server");
};

export const searchGroups = async (query: string, archived = true): Promise<GroupUMS[]> => {
  const raw = await fetch(`${baseUrl}/search?query=${encodeURIComponent(query)}&excludeArchived=${String(archived)}`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (isGroupSearchResult(response) && isGroupObjects(response.groups)) {
    return response.groups;
  } else {
    throw new Error("Invalid response from server");
  }
};

export const purgeGroups = async (type: PurgeType): Promise<void> => {
  const raw = await fetch(`${baseUrl}/purge`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ groupType: type }),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};

export const deleteGroup = async (id: string): Promise<void> => {
  const raw = await fetch(`${baseUrl}`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ groups: [id] }),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};

export type UpdateGroup = {
  archived: boolean;
  newOwners: string[];
  newSubgroups: string[];
  oldOwners: string[];
  oldSubgroups: string[];
};
export const updateGroup = async (groupId: string, group: UpdateGroup): Promise<{ archived: boolean }> => {
  if (!groupId) throw new Error("Invalid group id");
  const raw = await fetch(`${baseUrl}/${groupId}`, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify(group),
  });
  if (raw.status !== 200) throw new Error("Invalid response from server");

  const response: unknown = await raw.json();
  if (!(response !== null && typeof response === "object" && "archived" in response && typeof response.archived === "boolean")) {
    throw new Error("Invalid response from server");
  }
  return { archived: response.archived };
};

export const batchResolveGroupDescriptions = async (groups: string[]): Promise<UserFriendlyGroupNames[]> => {
  if (groups.length === 0) return [];
  const raw = await fetch(`${baseUrl}/batch-resolve-descriptions`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ groups }),
  });
  if (raw.status !== 200) throw new Error("Invalid response from server");

  const response: unknown = await raw.json();
  if (responseHasGroupsArray(response) && isUserFriendlyGroupNames(response.groups)) return response.groups;
  else throw new Error("Invalid response from server");
};

const isGroupSearchResult = (response: unknown): response is SearchGroupsResponse => responseHasGroupsArray(response);

const isGroupObjects = (groups: unknown[]): groups is GroupUMS[] => {
  if (groups.length === 0) return true;
  return groups.some((group) => isGroup(group));
};

const isGroup = (group: unknown): group is GroupUMS => {
  return group !== null && typeof group === "object" && "id" in group && "archived" in group;
};

// Group single is an extension of GroupUMS with additional fields
const isGroupSingle = (group: GroupUMS): group is GroupSingle => {
  return "distinguishedName" in group && "users" in group;
};

const isGetGroups = (response: unknown): response is GetGroups => {
  if (!responseHasGroupsArray(response)) return false;
  if (response.groups.length === 0) return true;

  return response.groups.some(
    (group) =>
      group !== null && typeof group === "object" && "id" in group && "archived" in group && "name" in group && "sourceType" in group
  );
};

const isUserFriendlyGroupNames = (response: unknown): response is UserFriendlyGroupNames[] => {
  return (
    response !== null &&
    Array.isArray(response) &&
    response.every((group) => group !== null && typeof group === "object" && "name" in group)
  );
};

const responseHasGroupsArray = (response: unknown): response is { groups: unknown[] } => {
  return response !== null && typeof response === "object" && "groups" in response && Array.isArray(response.groups);
};
