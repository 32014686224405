import clsx from "clsx";
import React from "react";
import { useMount, useSetState } from "react-use";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";
import SessionStore from "../../stores/SessionStore";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

const MERGE_ATTRIBUTE = "email";
const AdvancedAuthenticationConfiguration = () => {
  const device = SessionStore.getSelectedDevice();
  const lockedOutKey = device ? device.concat("_MergeLockout") : null;

  const lockedOut =
    lockedOutKey && JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[lockedOutKey] > new Date().getTime();

  const [state, setState] = useSetState({
    enabled: false,
    changes: false,
    device: SessionStore.getSelectedDevice(),
    lockedOut: lockedOut,
  });

  useMount(() => {
    handleLoad();
  });

  const handleChangeEnabled = (event) => {
    setState({
      enabled: event.target.checked,
      changes: true,
    });
  };

  const handleChangeMergeKey = (event) => {
    setState({
      merge_key: event.value,
      changes: true,
    });
  };

  const handleChangeSource = (event) => {
    setState({
      source_provider: event.value,
      changes: true,
    });
  };

  const handleLoad = () => {
    Api.get("/config/ajax/authentication/mergedidentity", (result) => {
      const configuration = result["result"];

      setState({
        enabled: configuration["enabled"],
        source_provider: configuration["source_provider"],
        merge_key: configuration["merge_key"],
        loaded: true,
      });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const object = {
      enabled: state.enabled,
      source_provider: state.source_provider,
      merge_attribute: MERGE_ATTRIBUTE,
      merge_key: state.merge_key,
    };

    setState({ changes: false, saving: true });
    Api.post("/config/ajax/authentication/mergedidentity", object, () => {
      handleLoad();
      setState({ changes: false, saving: false });
    });
  };

  const handleTriggerMerge = (event) => {
    event.preventDefault();
    Api.get("/config/ajax/authentication/mergedidentity/merge", () => {});
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");

    manuallySetRunningSyncs[state.device ? state.device.concat("_MergeLockout") : null] = new Date().getTime() + 60000 * 5;

    localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
    setState({ lockedOut: true });
  };

  return (
    <LeftPanel>
      <LetterPanel>
        <LetterPanelHeader>
          Advanced Configuration
          <DumbBusyIndicator loaded={state.loaded} />
        </LetterPanelHeader>
        <LetterPanelBody>
          {state.loaded && (
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <h2>Unified Identity</h2>
                  <div className="dialog_subheading_desc">
                    Merge users to a single user account based on email. Group and classroom membership will be consolidated while archiving
                    the source accounts.
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={state.enabled} onChange={handleChangeEnabled} />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="mui-select">
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">Merge to</div>
                      <div className="formgroup-element-fields">
                        <SearchableSelect
                          value={state.source_provider}
                          onChange={handleChangeSource}
                          clearable={false}
                          valueDataMap={[
                            ["api.linewizecp.ldap", "LDAP"],
                            ["api.linewizecp.google", "Google"],
                            ["api.linewizecp.azuread", "Azure"],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {state.enabled && !state.source_provider && (
                    <div className="formgroup-content">To enable Unified Identity you must provide a "Merge to" source provider</div>
                  )}

                  <div className="mui-select">
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">Merge key</div>
                      <div className="formgroup-element-fields">
                        <SearchableSelect
                          value={state.merge_key}
                          onChange={handleChangeMergeKey}
                          clearable={false}
                          valueDataMap={[
                            ["email", "Email"],
                            ["email_strip_domain", "Email without domain"],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {state.enabled && !state.merge_key && (
                    <div className="formgroup-content">To enable Unified Identity you must provide a "Merge key"</div>
                  )}
                </div>
              </div>
            </form>
          )}
        </LetterPanelBody>
        <LetterPanelFooter className="mg-0">
          <LetterPanelSaveButton
            onClick={handleSubmit}
            changes={state.changes}
            saving={state.saving}
            // disable save if we are trying to enable unified identity AND we haven't provided a source provider or merge key
            // allow disabling unified identity even if source provider or merge key aren't provided
            disabled={!!state.enabled && (!state.source_provider || !state.merge_key)}
          />

          <button
            type="submit"
            style={{ marginRight: "8px" }}
            className={clsx(
              "letter-panel-footer-save-button",
              state.lockedOut ? "letter-panel-footer-save-saved" : "letter-panel-footer-save-changes"
            )}
            onClick={handleTriggerMerge}
            disabled={state.lockedOut}
            data-testid="trigger-merge-btn"
          >
            <span>Trigger Merge</span>
          </button>
        </LetterPanelFooter>
      </LetterPanel>
    </LeftPanel>
  );
};

export default AdvancedAuthenticationConfiguration;
