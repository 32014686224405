import React from "react";
import EmailSuccessIcon from "../../images/icons/success--email.svg";
import SleekModal from "./SleekModal";
import { Link } from "react-router";

const AssociationInExceptionModal = (props) => {
  const renderSuccessMessage = () => (
    <div>
      <img src={EmailSuccessIcon} alt="success icon" />
      <h3 className="modal-title--icon">Association already has been excepted</h3>
      <div>
        <p>
          Mac or IP address is already part of your list of associations exceptions. You can view the list{" "}
          <Link to="/config/device/userdb/exceptions">here</Link>.
        </p>
      </div>
    </div>
  );

  const renderModalButton = () => (
    <button className="button button-primary" onClick={props.handleHide}>
      Try Again
    </button>
  );

  if (props.visible) {
    return (
      <SleekModal
        description={renderSuccessMessage()}
        show={props.visible}
        handleHide={props.handleHide}
        buttons={renderModalButton()}
        centerButtons
      />
    );
  } else {
    return <div />;
  }
};

export default AssociationInExceptionModal;
