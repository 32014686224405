import React from "react";
import { Link } from "react-router";

export default class Criteria extends React.Component {
  render_OnRemove = () => {
    if (this.props.onRemove !== undefined) {
      return (
        <div className="criteria-remove">
          <Link onClick={() => this.props.onRemove()}>
            <i className="fa fa-times" aria-hidden="true" />
          </Link>
        </div>
      );
    } else {
      return <div />;
    }
  };

  render() {
    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">
          {this.render_OnRemove()}
          <div className="criteria-label">{this.props.label}</div>
        </div>
        <div className="formgroup-element-fields">
          <div className="">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
