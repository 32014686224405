import React from "react";
import PropTypes from "prop-types";
import Api from "../../../utils/Api";

export default class CleverConfigurationLink extends React.Component {
  componentDidMount() {
    let location = document.location.origin;
    if (location.indexOf("localhost") >= 0) {
      location = "http://localhost:3000/bounce";
    }
    const self = this;
    Api.post(
      "/config/ajax/authentication/clever/link/activate?code=" +
        this.props.location.query.code +
        "&callback=" +
        location +
        "/config/login/admin/clever/callback",
      {},
      function (res) {
        self.context.router.push("/config/device/authentication/clever");
      },
      function (error) {
        self.context.router.push("/config/device/authentication/clever");
      }
    );
  }

  render() {
    return <div />;
  }
}

CleverConfigurationLink.contextTypes = {
  router: PropTypes.object.isRequired,
};
