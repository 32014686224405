/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC, useEffect } from "react";
import Api from "../utils/Api";

import "../../css/AccessDeviceVerification.css";

interface Props {
  params: {
    device_id: string;
    requester_email: string;
    verify_code: string;
  };
}

const AccessDeviceVerification: FC<Props> = ({ params }): JSX.Element => {
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    void (async () => {
      await Api.getAsync(`/api/devices/${params.device_id}/verify?verify_code=${params.verify_code}`);
      setLoaded(true);
    })();
  });

  const render_text = () => {
    if (loaded) {
      return (
        <div className={"permission-status"}>
          <div className={"status-text"}>Permissions have been granted for the user: {params.requester_email}</div>
        </div>
      );
    } else {
      return (
        <div className={"permission-status"}>
          <i className="fa fa-refresh fa-spin fa-fw" />
          <div className="status-text">
            Granting {params.requester_email} permissions for {params.device_id}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="device-verification-body">
      <div className="device-verification-form">
        <form className="mui-form">{render_text()}</form>
      </div>
    </div>
  );
};

export default AccessDeviceVerification;
