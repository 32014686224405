import alt from "../alt";
import GlobalDatePickerVisibilityActions from "../actions/GlobalDatePickerVisibilityActions";

class GlobalDatePickerVisibilityStore {
  constructor() {
    this.globalDatePickerVisible = false;

    this.exportPublicMethods({
      isGlobalDatePickerVisible: this.isGlobalDatePickerVisible,
    });

    this.bindListeners({
      handleShowGlobalDatePicker: GlobalDatePickerVisibilityActions.SHOW_GLOBAL_DATE_PICKER,
      handleHideGlobalDatePicker: GlobalDatePickerVisibilityActions.HIDE_GLOBAL_DATE_PICKER,
    });
  }

  isGlobalDatePickerVisible = () => {
    return this.globalDatePickerVisible;
  };

  handleShowGlobalDatePicker = () => {
    this.globalDatePickerVisible = true;
  };

  handleHideGlobalDatePicker = () => {
    this.globalDatePickerVisible = false;
  };
}

export default alt.createStore(GlobalDatePickerVisibilityStore, "GlobalDatePickerVisibilityStore");
