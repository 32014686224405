import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  SearchBox,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  ThemeProvider,
  Tr,
  useTranslation,
} from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { searchStringOld as searchString } from "../../utils/Validation";
import { zIndices } from "../../utils/ZIndexUtil";
import { SortType } from "../templates/SortSearchTable";
import humanizeString from "humanize-string";

interface Data extends Record<string, unknown> {
  value: string;
  detail: string;
}

interface RawData {
  [value: string]: string;
}

interface Props {
  connection: unknown | undefined;
  visible: boolean;
  handleClose: () => void;
  humanize?: boolean;
}

export const ConnectionInfoModalNew: React.FC<Props> = ({ connection, visible, handleClose, humanize = true }) => {
  const { t } = useTranslation();

  const columns = [
    {
      headerText: t("Detail"),
      columnName: "detail",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Value"),
      columnName: "value",
      searchable: true,
    },
  ];

  const [data, setData] = useState<Data[]>([]);
  const [sortedData, setSortedData] = useState<Data[]>([]);
  const [hideEmpty, setHideEmpty] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState<SortType>("");
  const [searchValue, setSearchValue] = useState("");
  const [loaded, setLoaded] = useState(false);

  const handleSort = (columnName: string, sortDirection: "asc" | "desc" | "") => {
    setSortColumn(columnName);
    setSortDirection(sortDirection);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  const handleToggleHideEmpty = () => {
    setHideEmpty(!hideEmpty);
  };

  const componentDidMount = () => {
    if (connection) {
      const connectionDetails = Object.entries(connection as RawData).map(([k, v]) => ({
        detail: humanize ? humanizeString(k) : k,
        value: `${v}`,
      }));
      setData(connectionDetails);
      setLoaded(true);
    }
  };

  const componentDidUpdate = () => {
    let sortedData = data;
    if (sortDirection) {
      sortedData = orderBy(sortedData, sortColumn, sortDirection);
    }
    if (searchValue) {
      const searchableColumns = columns.filter((c) => c.searchable);
      sortedData = sortedData.filter((d: Record<string, unknown>) =>
        searchableColumns.some(({ columnName }) => searchString(searchValue, String(d[columnName])))
      );
    }
    if (hideEmpty) {
      sortedData = sortedData.filter(({ value }) => !!value);
    }
    setSortedData(sortedData);
  };

  useEffect(componentDidMount, [connection, humanize]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(componentDidUpdate, [data, sortColumn, sortDirection, searchValue, hideEmpty]);

  return (
    <ThemeProvider>
      <Modal isOpen={visible} size="md" onClose={handleClose} headerText={t("Connection Details")}>
        <ModalBody>
          <Flex direction="row" mb="sp16">
            <Flex direction="column">
              <SearchBox
                data-testid="searchBar"
                onClear={handleClearSearch}
                onChange={handleSearch}
                value={searchValue}
                placeholder={t("Search")}
                width="300px"
              />
            </Flex>
            <Flex grow={1} />
            <Flex direction="column" justify="center">
              <Checkbox isChecked={hideEmpty} onChange={handleToggleHideEmpty}>
                {t("Hide Empty Fields")}
              </Checkbox>
            </Flex>
          </Flex>
          <Flex direction="row">
            <Table>
              <Thead position="sticky" top="0" zIndex={zIndices.thead}>
                <Tr>
                  {columns.map(({ headerText, columnName, sortable }, index) => (
                    <Th
                      key={index}
                      headerText={headerText}
                      columnName={columnName}
                      sortDirection={sortable ? (sortColumn === columnName ? sortDirection : "") : undefined}
                      handleSort={sortable ? handleSort : undefined}
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody loaded={loaded} searched={!!searchValue}>
                {sortedData.map(({ detail, value }, index) => (
                  <Tr key={index}>
                    <Td overflowWrap="anywhere">
                      <Text fontSize="md">{detail}</Text>
                    </Td>
                    <Td overflowWrap="anywhere">
                      <Text fontSize="md">{value}</Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </ModalBody>
      </Modal>
    </ThemeProvider>
  );
};
