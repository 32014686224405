import { Item } from "../../../storez/MultiSelectStores";

export type UserJourneyData = {
  deviceid: string;
  user: string;
  string_datetime?: string;
  type: string;
  activity: string;
  signature_name: string[];
  signature_favicon_url: string | null;
  result: string;
  time?: string;
};

export function filteredData(data: UserJourneyData[] = [], categories: string[], types: string[], results: string[]): UserJourneyData[] {
  const typeFiltered = fieldFilteredData(data, "type", types);
  const resultFiltered = fieldFilteredData(typeFiltered, "result", results);
  const categoryFiltered = categoryFilteredData(resultFiltered, categories);
  return categoryFiltered;
}

function categoryFilteredData(data: UserJourneyData[] = [], categories: string[] = []): UserJourneyData[] {
  return data.filter(({ signature_name }) => {
    let included = false;
    signature_name.forEach((signature) => {
      if (categories.includes(signature)) {
        included = true;
        return;
      }
    });
    return included;
  });
}

type UJField = "deviceid" | "user" | "string_datetime" | "type" | "activity" | "signature_favicon_url" | "result" | "time";

function fieldFilteredData(data: UserJourneyData[] = [], field: UJField, values: string[] = []): UserJourneyData[] {
  if (!values || values.length === 0) return [];
  const lowerValues: string[] = [];
  values.forEach((v) => {
    if (v) {
      lowerValues.push(v.toLowerCase());
    }
  });
  return data.filter((item) => {
    const f = item[field];
    if (f && f !== null && f !== undefined) {
      return lowerValues.includes(f.toLowerCase());
    }
    return false;
  });
}

export function getCategories(data: UserJourneyData[] = []): Item[] {
  const categories: Item[] = [];
  const signatures: string[] = []; //We use this for .includes()
  data.forEach((item) => {
    if (item.signature_name !== null && item.signature_name.length > 0) {
      item.signature_name.forEach((signature) => {
        if (!signatures.includes(signature)) {
          signatures.push(signature);
          categories.push({ value: signature });
        }
      });
    }
  });
  return categories;
}

export const allTypes = ["Web", "Video", "Search"];
export const allResults = ["Allowed", "Blocked", "Flagged"];

export function formatFilterForExport(filter: string[], numberOfFilterOptions: number): string[] | undefined {
  if (filter.length > 0 && filter.length < numberOfFilterOptions) {
    return filter;
  }
  return undefined;
}
