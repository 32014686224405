import { Box, Button, Flex, Option, SingleSearchSelector, Text, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { GroupUMS } from "../../types/Groups";
import { searchGroups } from "../../utils/api/Groups";
import { mapGroupsToOptionsWithStableID, mapPrefillToOptionWithStableID } from "./GroupSearchHelper";

interface Props {
  id?: string;
  buttonText?: string;
  placeholderText: string;
  prefill?: GroupUMS | null;
  disabled?: boolean;
  showClearIcon?: boolean;
  showHelpText?: boolean;
  clearOnSelect?: boolean;
  handleSubmit?: (selected: Option | null) => void;
  handleChange?: (selected: Option | null) => void;
}

export const GroupSearchSelector: React.FC<Props> = ({
  id,
  buttonText,
  placeholderText,
  prefill,
  disabled,
  showClearIcon,
  showHelpText = true,
  clearOnSelect,
  handleSubmit,
  handleChange,
}) => {
  const { t } = useTranslation();

  const [groups, setGroups] = useState<GroupUMS[]>([]);
  const [selected, setSelected] = useState<Option | null>(mapPrefillToOptionWithStableID(prefill));

  const [query, setQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useDebounce(
    () => {
      setDebouncedValue(query?.trim());
    },
    500,
    [query]
  );

  useEffect(() => {
    if (debouncedValue && debouncedValue.length >= 3) {
      setGroups([]);
      void searchGroups(debouncedValue).then((result) => {
        setGroups(result);
        setShowDropdown(true);
      });
    } else {
      setGroups([]);
      setShowDropdown(false);
    }
  }, [debouncedValue]);

  const onChange = (option: Option | null, e: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    setSelected(option);

    if (e && "target" in e && "value" in e.target) {
      setQuery(e.target.value);
    }

    if (handleChange) {
      handleChange(option);
    }

    if (option && clearOnSelect) {
      setSelected(null);
      setGroups([]);
    }
  };

  return (
    <Box overflow="visible" position="relative">
      <SingleSearchSelector
        id={id}
        name="group-selector"
        placeholder={placeholderText}
        options={mapGroupsToOptionsWithStableID(groups)}
        disabled={disabled}
        selected={selected}
        onChange={onChange}
        showDropdownIcon={false}
        showDropdown={showDropdown}
        searchDelay={0}
        showClearIcon={showClearIcon}
        filterOptions={false}
      />
      {showHelpText && (
        <Text color="neutrals.200" mt="sp4" fontSize="sm">
          {t("Please enter 3 or more characters.")}
        </Text>
      )}
      {handleSubmit && buttonText && (
        <Flex mt="sp24">
          <Box>
            <Button variant="primary" disabled={!selected} onClick={() => handleSubmit(selected)}>
              {t(buttonText)}
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
};
