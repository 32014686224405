let LineReader = function (options) {
  if (!(this instanceof LineReader)) {
    return new LineReader(options);
  }

  let internals = (this._internals = {});
  let self = this;
  internals.reader = new FileReader();
  internals.chunkSize = options && options.chunkSize ? options.chunkSize : 1024;
  internals.events = {};
  internals.canRead = true;
  internals.reader.onload = function () {
    internals.chunk += this.result;

    if (/\r|\n/.test(internals.chunk)) {
      internals.chunk = internals.chunk.replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, "");
      internals.lines = internals.chunk.match(/[^\r\n]+/g);

      if (self._hasMoreData()) {
        internals.chunk = internals.chunk[internals.chunk.length - 1] === "\n" ? "" : internals.lines.pop();
      }
      self._step();
    } else {
      if (self._hasMoreData()) {
        return self.read();
      }
      if (internals.chunk.length) {
        return self._emit("line", [internals.chunk, self._emit.bind(self, "end")]);
      }
      self._emit("end");
    }
  };

  internals.reader.onerror = function () {
    self._emit("error", [this.error]);
  };
};

LineReader.prototype.on = function (eventName, cb) {
  this._internals.events[eventName] = cb;
};

LineReader.prototype.read = function (file) {
  let internals = this._internals;
  if (typeof file !== "undefined") {
    internals.file = file;
    internals.fileLength = file.size;
    internals.readPos = 0;
    internals.chunk = "";
    internals.lines = [];
  }
  let blob = internals.file.slice(internals.readPos, internals.readPos + internals.chunkSize);
  this._emit("progress", [(internals.readPos / internals.fileLength) * 100]);
  internals.readPos += internals.chunkSize;
  internals.reader.readAsText(blob, "UTF-8");
};

LineReader.prototype.abort = function () {
  this._internals.canRead = false;
};

LineReader.prototype._step = function () {
  let internals = this._internals;

  if (internals.lines.length === 0) {
    if (this._hasMoreData()) {
      return this.read();
    }
    return this._emit("end");
  }

  if (internals.canRead) {
    // this._emit('progress', [(internals.fileLength/internals.readPos)]);
    this._emit("line", [internals.lines.shift(), this._step.bind(this)]);
  } else {
    this._emit("end");
  }
};

LineReader.prototype._hasMoreData = function () {
  let internals = this._internals;
  return internals.readPos <= internals.fileLength;
};

LineReader.prototype._emit = function (event, args) {
  let boundEvents = this._internals.events;

  if (typeof boundEvents[event] === "function") {
    boundEvents[event].apply(this, args);
  }
};

export default LineReader;
