import dayjs from "dayjs";
import { create } from "zustand";
import { ApiDates } from "../utils/DateTimeUtil";

interface DateRangeFilterState {
  init: boolean;
  noise: boolean;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  relativeKey: string;
}

interface DateRangeFilterStore extends DateRangeFilterState {
  getStartDate: () => string;
  getEndDate: () => string;
  getStartTime: () => string;
  getEndTime: () => string;
  getApiDates: () => ApiDates;
  getIncludeNoise: () => boolean;
  getRelativeKey: () => string;

  getCookie: (cname: string) => string;
  setCookie: (cname: string, cvalue: string) => void;
  loadCookie: () => void;
  updateCookie: () => void;
  reset: () => void;

  isRelativePick: () => boolean;
  getCurrentRanges: () => Record<string, [dayjs.Dayjs, dayjs.Dayjs, string]>;
  updateDateFilter: (details: ApiDates) => void;
  updateNoiseFilter: (filter: boolean) => void;
}

export const useDateRangeFilterStore = create<DateRangeFilterStore>((set, get) => ({
  init: false,
  noise: false,
  startDate: dayjs().subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
  endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
  startTime: dayjs().subtract(6, "days").startOf("day").format("HH:mm:ss"),
  endTime: dayjs().endOf("day").format("HH:mm:ss"),
  relativeKey: "",

  getStartDate: () => {
    if (get().isRelativePick()) {
      const ranges = get().getCurrentRanges();
      get().startDate = ranges[get().relativeKey][0].format("YYYY-MM-DD");
      get().endDate = ranges[get().relativeKey][1].format("YYYY-MM-DD");
    }
    return get().startDate;
  },

  getEndDate: () => {
    return get().endDate;
  },

  getStartTime: () => {
    return get().startTime;
  },

  getEndTime: () => {
    return get().endTime;
  },

  getApiDates: () => {
    return {
      startDate: get().getStartDate(),
      endDate: get().endDate,
      startTime: get().startTime,
      endTime: get().endTime,
    };
  },

  getIncludeNoise: () => {
    return get().noise;
  },

  getRelativeKey: () => {
    return get().relativeKey;
  },

  getCookie: (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  setCookie: (cname: string, cvalue: string) => {
    document.cookie = cname + "=" + cvalue + "; path=/";
  },

  reset: () => {
    get().updateDateFilter({
      startDate: dayjs().subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
      endDate: dayjs().endOf("day").format("YYYY-MM-DD"),
      startTime: dayjs().subtract(6, "days").startOf("day").format("HH:mm:ss"),
      endTime: dayjs().endOf("day").format("HH:mm:ss"),
      relativeKey: "",
    });
  },

  loadCookie: () => {
    const cookie = get().getCookie("linewizeDateFilter");
    const parsedCookie = JSON.parse(cookie) as ApiDates;
    set({
      startDate: parsedCookie.startDate,
      endDate: parsedCookie.endDate,
      startTime: parsedCookie.startTime,
      endTime: parsedCookie.endTime,
      relativeKey: parsedCookie.relativeKey,
    });
  },

  updateCookie: () => {
    get().setCookie(
      "linewizeDateFilter",
      JSON.stringify({
        startDate: get().startDate,
        endDate: get().endDate,
        startTime: get().startTime,
        endTime: get().endTime,
        relativeKey: get().relativeKey,
      })
    );
  },

  isRelativePick: () => {
    return ["Today", "Yesterday", "Last 7 Days", "Last 30 Days"].indexOf(get().relativeKey) >= 0;
  },

  getCurrentRanges: () => {
    return {
      Today: [dayjs().startOf("day"), dayjs().endOf("day"), "today"],
      Yesterday: [dayjs().subtract(1, "days").startOf("day"), dayjs().subtract(1, "days").endOf("day"), "yesterday"],
      "Last 7 Days": [dayjs().subtract(6, "days").startOf("day"), dayjs().endOf("day"), "7days"],
      "Last 30 Days": [dayjs().subtract(29, "days").startOf("day"), dayjs().endOf("day"), "30days"],
    };
  },

  updateDateFilter: (details: ApiDates) => {
    set({
      startDate: details.startDate,
      endDate: details.endDate,
      startTime: details.startTime,
      endTime: details.endTime,
    });
    if (details.relativeKey) {
      set({ relativeKey: details.relativeKey });
    }
    get().updateCookie();
  },

  updateNoiseFilter: (filter: boolean) => {
    set({ noise: filter });
  },
}));

const initializeFilterStore = () => {
  if (useDateRangeFilterStore.getState().getCookie("linewizeDateFilter")) {
    useDateRangeFilterStore.getState().loadCookie();
  } else {
    useDateRangeFilterStore.getState().updateCookie();
  }
};

initializeFilterStore();
