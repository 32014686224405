import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { UserUMS } from "../../../types/Users";
import { deleteUser, getUsers, purgeUsers } from "../../../utils/api/Users";
import UsersNew from "./UsersNew";
import UserSearchBox from "../../UserSearch/UserSearchBox";
import { useToast } from "@familyzone/component-library";
import { PurgeType } from "../../../utils/api/Helpers";

const Users: React.FC = () => {
  const { successToast, errorToast } = useToast();

  const [users, setUsers] = useState<UserUMS[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const fetchUsers = async () => {
    setIsSearching(false);
    setLoaded(false);
    const users = await getUsers();
    setUsers(users);
    setLoaded(true);
  };

  useEffect(() => {
    void fetchUsers();
  }, []);

  const handleDelete = (id: string) => {
    deleteUser(id)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to delete the user at this time. Please try again later.",
        });
      });
  };

  const handlePurgeUsers = (type: PurgeType) => {
    purgeUsers(type)
      .then(() => {
        successToast({
          title: "Success!",
          description: "We've received the request and it has been sent for processing.",
        });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description: "We're not able to process the purge at this time. Please try again later.",
        });
      });
  };

  const withinTableChildren = (): JSX.Element => {
    return (
      <UserSearchBox
        searchEnabled={loaded}
        onSearchStart={() => {
          setLoaded(false);
          setIsSearching(true);
        }}
        onSearchEnd={(users: UserUMS[]) => {
          setUsers(users);
          setLoaded(true);
        }}
        excludeArchivedUsers={false}
      />
    );
  };

  return (
    <UsersNew
      users={users}
      loaded={loaded}
      onPurgeUsers={handlePurgeUsers}
      onDeleteUser={handleDelete}
      withinTableChildren={withinTableChildren()}
      isSearching={isSearching}
    />
  );
};

Users.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default Users;
