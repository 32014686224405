import { TableColumn } from "../types/table";

export const CSVGenerator = <FD>(columns: TableColumn[], formattedData: FD[]): string => {
  const exportableColumns = columns.filter((c) => c.exportable);

  let csv = "";
  exportableColumns.forEach(({ headerText }) => (csv += headerText + ","));
  csv = csv.slice(0, csv.length - 1) + "\n";

  formattedData.forEach((row) => {
    let rowData = "";

    exportableColumns.forEach((column) => {
      let cell: string;
      if (column.exportData) {
        cell = String(column.exportData(row));
      } else {
        cell = String(row[column.columnName as keyof FD]);
      }
      rowData += cell.replace(/,/g, "/") + ",";
    });
    csv += rowData.slice(0, rowData.length - 1) + "\n";
  });

  return csv;
};

export const CSVDownloader = (csv: string): void => {
  const link = document.createElement("a");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", "export.csv");
  link.click();
};
