interface IRow {
  auth_only: boolean;
  domain: string;
  enabled: boolean;
  exceptions: string[];
  hostname: string;
  id: string;
  mode: number;
  password: string;
  username: string;
}

interface IColumn {
  domain: string;
  hostname: string;
  username: string;
}

const getTypeText = (row: IRow): string => {
  if (row["mode"] === 2) {
    return "Domain Login Events";
  }
  if (row["mode"] === 1) {
    return "NPS RADIUS Events";
  }
  return "-";
};

const getExceptionsText = (row: IRow): string => {
  return (row.exceptions || []).join(",");
};

const getCellContent = (row: IRow, columnName: keyof IColumn): string => {
  return row[columnName] !== "" ? row[columnName] : "-";
};

export { IRow, getExceptionsText, getTypeText, getCellContent };
