import { Box, Input, Text, useTranslation } from "@familyzone/component-library";
import React, { useState } from "react";
import { Classroom } from "../../../types/Classrooms";
import { searchClassrooms } from "../../../utils/api/Classrooms";
import { useDebounce } from "react-use";

export interface ClassroomSearchBoxProps {
  searchEnabled: boolean;
  onSearchStart: () => void;
  onSearchEnd: (classrooms: Classroom[]) => void;
  excludeArchivedClassrooms?: boolean;
}

const ClassroomSearchBox: React.FC<ClassroomSearchBoxProps> = ({ searchEnabled, onSearchStart, onSearchEnd }) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [debouncedValue, setDebouncedValue] = React.useState("");
  const minimumSearchLength = 3;
  const canSearch = () => {
    return searchEnabled && !searching && searchInput.length >= minimumSearchLength;
  };

  // Debounce and search on debounced value change.
  React.useEffect(() => {
    if (canSearch()) void search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useDebounce(
    () => {
      setDebouncedValue(searchInput?.trim());
    },
    500,
    [searchInput]
  );

  const search = async () => {
    setSearching(true);
    onSearchStart();
    const result = await searchClassrooms(searchInput);
    onSearchEnd(result);
    setSearching(false);
  };

  return (
    <Box width="536px">
      <Input placeholder={t("Search")} onChange={(e) => setSearchInput(e.target.value)} value={searchInput} />
      <Text color="neutrals.100" ml="4" mt="6">
        To search, please enter 3 or more characters.
      </Text>
    </Box>
  );
};

export default ClassroomSearchBox;
