import React from "react";
import { Link } from "react-router";

export default class MoveButton extends React.Component {
  render() {
    return (
      <Link className="moveable">
        <i className="fa fa-arrows" aria-hidden="true"></i>
      </Link>
    );
  }
}
