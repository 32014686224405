import { create } from "zustand";
import { Device } from "../types/Device";

interface Account {
  id: string;
  username: string;
}

export interface SessionState {
  /**
   * Currently logged in user.
   */
  user?: Account;
  /**
   * Currently selected device.
   */
  device?: Device;
  /**
   * User access token.
   */
  token?: string;
  supportAdmin: boolean;
  getUser: () => Account;
  getDevice: () => Device;
  getToken: () => string;
  isSupportAdmin: () => boolean;
}

// Currently, the values are set by `SessionStore.js` to keep the two stores in sync,
// so for now, this merely serves as a strongly typed wrapper, but eventually, all
// session-related bits should be fully moved here
export const useSessionStore = create<SessionState>((set, get) => ({
  user: undefined,

  token: undefined,

  supportAdmin: false,

  device: undefined,

  getUser: (): Account => {
    const user = get().user;

    if (!user) {
      throw new Error("No user context");
    }

    return user;
  },

  getToken: (): string => {
    const token = get().token;

    if (!token) {
      throw new Error("No session token");
    }

    return token;
  },

  isSupportAdmin: (): boolean => {
    return get().supportAdmin;
  },

  getDevice: (): Device => {
    const device = get().device;

    if (!device) {
      throw new Error("No selected device");
    }

    return device;
  },

  reset: () => {
    set({
      user: undefined,
      token: undefined,
      supportAdmin: false,
      device: undefined,
    });
  },
}));
