import { Button, Flex, Modal, Text, useTranslation } from "@familyzone/component-library";
import React, { useState } from "react";
import { PurgeType } from "../../../utils/api/Helpers";

interface PurgeUsersProps {
  onPurgeUsers: (usersToPurge: PurgeType) => void;
}

const PurgeUsers: React.FC<PurgeUsersProps> = ({ onPurgeUsers }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [purgeType, setPurgeType] = useState(null as PurgeType);

  const onClose = () => {
    setIsOpen(false);
    setPurgeType(null);
  };

  const onConfirmationClose = () => {
    setIsConfirmationOpen(false);
    onClose();
  };

  const onPurge = (usersToPurge: PurgeType) => {
    setPurgeType(usersToPurge);
    setIsOpen(false);
    setIsConfirmationOpen(true);
  };

  const onConfirmation = () => {
    onPurgeUsers(purgeType);
    onConfirmationClose();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("Purge Users")}
      </Button>
      <Modal headerText={t("Purge Users")} variant="danger" size="md" isOpen={isOpen} onClose={onClose}>
        <Text mb="sp24" style={{ fontWeight: "bold" }}>
          {t("WARNING: Purge may stop School Manager, Classwize and Community features from working as expected.")}
        </Text>
        <Text mb="sp24">
          {t(
            "Purge deletes selected users from School Manager, removes them from groups and classrooms, and breaks any parent/child Community associations. You can only restore users by resyncing or reuploading the source data. You will also need to rebuild any Community parent/child associations, and ensure users are restored to the correct groups and classrooms."
          )}
        </Text>
        <Text mb="sp24">{t("Please select whether you wish to delete local, imported, or all users.")}</Text>
        <Flex>
          <Button variant="ghost" onClick={onClose} mr="sp8">
            {t("Cancel")}
          </Button>
          <Button
            mr="sp8"
            variant="danger"
            onClick={() => {
              onPurge("LOCAL");
            }}
          >
            {t("Purge Local Users")}
          </Button>
          <Button
            mr="sp8"
            variant="danger"
            onClick={() => {
              onPurge("IMPORTED");
            }}
          >
            {t("Purge Imported Users")}
          </Button>
          <Button
            mr="sp8"
            variant="danger"
            onClick={() => {
              onPurge("ALL");
            }}
          >
            {t("Purge All Users")}
          </Button>
        </Flex>
      </Modal>
      <Modal headerText={t("Are you sure?")} variant="danger" size="md" isOpen={isConfirmationOpen} onClose={onConfirmationClose}>
        <br></br>
        <Text mb="sp24" style={{ fontWeight: "bold" }}>
          {t("WARNING: YOU CANNOT UNDO THIS ACTION. Do you want to delete selected users from School Manager?")}
        </Text>
        <Flex>
          <Button variant="ghost" onClick={onConfirmationClose} mr="sp8">
            {t("Cancel")}
          </Button>
          <Button
            mr="sp8"
            variant="danger"
            onClick={() => {
              onConfirmation();
            }}
          >
            {t("Confirm")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default PurgeUsers;
