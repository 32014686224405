/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// These are all to do with SessionStore and third party code
import React from "react";
import { ReactElement, useEffect } from "react";
import SessionStore from "../stores/SessionStore";
import Api from "../utils/Api";

// Type hints to avoid boatload of Typescript errors/warnings
// See https://developer.zendesk.com/api-reference/widget-messaging/web/core for API contract.
type LoginCallback = (callback: (jwt: string) => void) => void;
type ZendeskWidget = (widget: string, command: string, callback?: LoginCallback) => void;

interface zendeskTokenApiResponse {
  token: string;
}

const Zendesk = (): ReactElement => {
  /**
   * Returns Zendesk widget or {@code undefined} if the widget has not been loaded yet.
   */
  const getZendeskWidget = (): ZendeskWidget | undefined => {
    // eslint-disable-next-line
    // @ts-ignore
    return window.zE as ZendeskWidget;
  };

  const displayZendeskWidget = () => {
    let zendeskPluginLoaded = Promise.resolve();

    // avoid reloading the library multiple times
    const zE = getZendeskWidget();
    if (!zE) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.async = true;
      script.src = "https://static.zdassets.com/ekr/snippet.js?key=89c520e8-9c7a-4b57-9e5b-46db5b4573a9";

      zendeskPluginLoaded = new Promise((resolve) => {
        script.onload = () => resolve(undefined);
      });

      const zd = document.getElementById("zendesk");
      zd?.appendChild(script);
    } else {
      zE("messenger", "show");
    }

    const username: string = SessionStore.getUsername();
    const userId: string = SessionStore.getUserId();
    Api.post("/zendesk/token", { email: username, id: userId }, (data: zendeskTokenApiResponse) => {
      // We have to run injected JS from the Zendesk widget.
      void zendeskPluginLoaded.then(() => {
        const zE = getZendeskWidget();
        if (!zE) {
          console.error("Unable to load Zendesk Widget: `zE` function not found");
          return;
        }

        zE("messenger", "loginUser", function (callback) {
          callback(data.token);
        });
      });
    });
  };

  const hideZendeskWidget = () => {
    const zE = getZendeskWidget();
    if (zE) {
      zE("messenger", "hide");
    }
  };

  useEffect(() => {
    displayZendeskWidget();

    return () => hideZendeskWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SessionStore.listen(sessionChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sessionChanged = () => {
    if (!SessionStore.getSelectedDevice() || !SessionStore.isAuthenticated()) {
      hideZendeskWidget();
    }
  };

  return <div id="zendesk"></div>;
};

export default Zendesk;
