import { Button } from "@chakra-ui/button";
import { Td, Tr, useTranslation, Text, Flex, Box, TableIconButton, Icon, useToast } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import ObjectActions from "../../../actions/ObjectActions";
import ObjectStore from "../../../stores/ObjectStore";
import { displayTypesArray, ObjectPool } from "../../../types/Objects";
import Api from "../../../utils/Api";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import TableBasedPage from "../../templates/TableBasedPage";
import DeleteObjectPoolsModal from "./DeleteObjectPoolsModal";
import NewAddObjectPools from "./AddObjectPools";
import PropTypes from "prop-types";
import { TableColumn } from "../../../types/table";

const ObjectPools: React.FC = (_, context) => {
  const { errorToast } = useToast();
  const isMounted = useIsMounted();
  const [objects, setObjects] = useState<ObjectPool[]>([]);
  const [loaded, setLoaded] = useState(true);
  const [openNewObject, setOpenNewObject] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingObject, setEditingObject] = useState<ObjectPool | null>(null);
  const [openDeleteObject, setOpenDeleteObject] = useState(false);

  const { t } = useTranslation();

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config/device/dashboard", isActive: false },
    { title: t("Objects"), url: "/config/device/objects/pools", isActive: false },
    { title: t("Pools"), isActive: true },
  ];

  /* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument */
  const onChange = () => {
    setLoaded(false);
    if (ObjectStore.getLoaded()) {
      setObjects(
        ObjectStore.getObjects().map((object: { type: string | number }) => ({
          ...object,
          displayType: displayTypesArray.find((displayType) => displayType[0] === object.type)?.[1],
        }))
      );
    }
    setLoaded(true);
  };

  useEffect(() => {
    ObjectStore.listen(onChange);
    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
    return () => {
      ObjectStore.unlisten(onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* eslint-enable */

  const columns: TableColumn[] = [
    {
      headerText: t("Name"),
      columnName: "name",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Description"),
      columnName: "desc",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Type"),
      columnName: "displayType",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
      alignment: "right",
    },
  ];

  const handleDelete = (id: string) => {
    if (!isMounted) return;
    setLoaded(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ObjectActions.deleteObject(id);
    setOpenDeleteObject(false);
    onChange();
    setLoaded(true);
  };

  const handleAddObject = async (object: ObjectPool) => {
    if (!isMounted) return;
    setLoaded(false);
    if (editing) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      await Api.post("/config/device/ajax/aliases", object)
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          ObjectActions.invalidateAndFetch();
        })
        .catch(() => {
          setLoaded(true);
          errorToast({ title: "Failed to update object pool", description: "Please try again later." });
        });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      await Api.put("/config/device/ajax/aliases", object).then((result: { result: ObjectPool }) => {
        if (result.result.id) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          context.router.push(`/config/device/objects/pools/${result.result.id}`);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ObjectActions.invalidateAndFetch();
      });
    }
    onChange();
    setOpenNewObject(false);
    setLoaded(true);
  };

  const handleOpenNewObject = () => {
    setOpenNewObject(true);
    setEditing(false);
  };

  const handleCloseAddObject = () => {
    setOpenNewObject(false);
    setEditing(false);
    setEditingObject(null);
  };

  const handleOpenEditObject = (object: ObjectPool) => {
    setOpenNewObject(true);
    setEditing(true);
    setEditingObject(object);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteObject(false);
    setEditing(false);
    setEditingObject(null);
  };

  const handleOpenDeleteConfirmation = (object: ObjectPool) => {
    setOpenDeleteObject(true);
    setEditing(true);
    setEditingObject(object);
  };

  const tableDataMap = (row: ObjectPool, index: number): JSX.Element => (
    <Tr key={index}>
      <Td>
        {row.id ? (
          <Link to={`/config/device/objects/pools/${encodeURIComponent(row.id)}`}>
            <Text color="#094C99" fontSize="md" minWidth="200px">
              {row?.name ? row.name : "___"}
            </Text>
          </Link>
        ) : (
          ""
        )}
      </Td>
      <Td>
        <Text fontSize="md">{row.desc}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{row.displayType}</Text>
      </Td>
      <Td>
        <Flex justifyContent="end">
          <Box mr="sp8">
            <TableIconButton
              onClick={() => handleOpenEditObject(row)}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              aria-label={"Edit association"}
              data-testId={row.id ? `${row.id}-edit` : "edit"}
            />
          </Box>
          <TableIconButton
            icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
            aria-label={"Delete association"}
            onClick={() => handleOpenDeleteConfirmation(row)}
            data-testId={row.id ? `${row.id}-delete` : "delete"}
          />
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <>
      <TableBasedPage
        title={t("Pools")}
        breadcrumbs={breadcrumbs}
        columns={columns}
        data={objects}
        tableDataMap={tableDataMap}
        loaded={loaded}
        showSearch={true}
        childrenInTableHeader={
          <Flex justifyContent="end">
            <Box>
              <Button onClick={handleOpenNewObject} variant="primary" aria-label="Add new object pool">
                {t("Add New Pool")}
              </Button>
            </Box>
          </Flex>
        }
      />
      <NewAddObjectPools
        open={openNewObject}
        editing={editing}
        objectPool={editingObject}
        onSave={(object: ObjectPool) => void handleAddObject(object)}
        loaded={loaded}
        onClose={handleCloseAddObject}
      />
      <DeleteObjectPoolsModal
        open={openDeleteObject}
        onClose={handleCloseDeleteConfirmation}
        id={editingObject?.id}
        type="objectpool"
        loaded={loaded}
        onDelete={handleDelete}
      />
    </>
  );
};
export default ObjectPools;

ObjectPools.contextTypes = {
  router: PropTypes.object.isRequired,
};
