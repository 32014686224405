import React, { useCallback, useEffect, useState } from "react";
import FlexibleTable from "../../../modules/FlexibleTable";
import FlexibleTableLabelWithImage from "../../../modules/FlexibleTableLabelWithImage";
import { SyncStatus } from "../SyncConfig/SyncStatus";
import Api from "../../../utils/Api";
import { humanizeDiffUnixAndNow } from "../../../utils/DateTimeUtil";
import ToggleSwitch from "../../../modules/ToggleSwitch";
import clsx from "clsx";
import EmptyColumnPlaceholder from "../../../modules/EmptyColumnPlaceholder";
import { Link } from "react-router";
import Separator from "../../../modules/Separator";
import TableCentricPageButton from "../../../modules/TableCentricPageButton";
import TableLeftPanel from "../../../utils/TableLeftPanel";
import TableCentricPage from "../../../modules/TableCentricPage";
import SleekModal from "../../../modules/SleekModal";
import SisProviderIconButton from "./SisProviderIconButton";
import SisDeleteDialog from "./SisDeleteDialog";
import SisResetDialog from "./SisResetDialog";
import SisWondeForm, { WondeFormOnHandle } from "./SisWondeForm";
import { OneRosterOnHandle, SisOneRosterForm } from "./SisOneRosterForm";
import { SisSchoolExcludeForm } from "./SisExcludeSchoolForm";
import { FetchSyncExecutionsResponse, getEndpoint, SyncExecution } from "../interfaces/GetSyncExecutions";
import moment from "moment";
import { PowerschoolOnHandle, SisPowerSchoolForm } from "./SisPowerSchoolForm";

interface RunningSyncsMap {
  [key: string]: number;
}

interface ProviderData {
  id?: string;
  type: string;
  enabled: boolean;
  integration_type: string;
  name: string;
  sync_parents?: boolean;
  sync_parent_types?: string[];
  sync_classrooms?: boolean;
  respect_enrolment_date?: boolean;
  respect_sessions?: boolean;
  excluded_schools_list?: string[];
  api_configuration: APIConfiguration;
}

export interface APIConfiguration {
  client_id?: string;
  client_secret?: string;
  endpoint_url?: string;
  token_url?: string;
  access_token?: string;
  classname_keys?: string;
  district_id?: string;
  domain?: string;
  email_key?: string;
  user_email_key?: string;
  user_match?: string;
  username_key?: string;
}

export interface SISProvider {
  api_configuration: APIConfiguration;
  enabled: boolean;
  id: string;
  integration_type: string;
  name: string;
  respect_enrolment_date: boolean;
  respect_sessions: boolean;
  sync_parents: boolean;
  sync_classrooms: boolean;
  type: SisVariant;
  status?: Status;
  excluded_schools_list?: string[];
  sync_executions?: SyncExecution[];
  sync_parent_types?: string[];
}

interface Status {
  end_time?: number;
  error?: string;
  start_time?: number;
  sync_id?: string;
  data_totality?: DataTotality;
  partial_errors?: string[][];
}

enum DataTotality {
  Full = "Full",
  Partial = "Partial",
}

export interface SISProvidersResponse {
  sis_providers: SISProvider[];
}

export enum SisVariant {
  Oneroster = "oneroster",
  Classlink = "classlink",
  Powerschool = "powerschool",
  Wonde = "wonde",
}

export const SisIntegration: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [showAddNew, setShowAddNew] = useState<boolean>(false);

  const [showForm, setShowForm] = useState<SisVariant | null>();

  const [showExcludeSchoolForm, setShowExcludeSchoolForm] = useState<boolean>(false);

  const [rowToEdit, setRowToEdit] = useState<SISProvider | null>(null);
  const [rowToViewLog, setRowToViewLog] = useState<SISProvider | null>(null);
  const [rowToDelete, setRowToDelete] = useState<SISProvider | null>(null);
  const [rowToReset, setRowToReset] = useState<SISProvider | null>(null);

  const [data, setData] = useState<SISProvider[]>([]);

  const fetchData = useCallback(() => {
    setLoaded(false);
    let data: SISProvider[] = [];

    Api.get("/surfwize/device/ajax/sis", async (result: SISProvidersResponse) => {
      data = result.sis_providers;
      // Create an array of promises for the API calls
      const promises = data.map((provider) => {
        return new Promise<void>((resolve) => {
          Api.get(
            getEndpoint(provider.type === "wonde" ? "WONDE" : "ONEROSTER", provider.id),
            (result: FetchSyncExecutionsResponse) => {
              provider.sync_executions = result.result.edges.map((edge) => edge.node);
              provider.status = {
                sync_id: provider.id,
              };

              // here we map our statuses to the existing 'Status' Object, this means we don't need to update all the code
              // todo: update all the ui to handle the new status structure instead of trying to transform it like this
              if (provider.sync_executions.length > 0 && provider.sync_executions[0].syncStatuses.length > 0) {
                // set error message
                provider.status.error =
                  provider.sync_executions[0].syncStatuses[0].status === "Failed" ||
                  provider.sync_executions[0].syncStatuses[0].status === "Partial"
                    ? provider.sync_executions[0].syncStatuses[0].message
                    : undefined;
                // set start time to the first status
                provider.status.start_time = moment(
                  provider.sync_executions[0].syncStatuses[provider.sync_executions[0].syncStatuses.length - 1].createdAt,
                  "YYYY-MM-DD HH:mm:ss.SSSSSS Z"
                ).unix();
                // set end time, consider it as ended when its latest status is an ended status
                provider.status.end_time =
                  provider.sync_executions[0].syncStatuses[0].status === "Completed" ||
                  provider.sync_executions[0].syncStatuses[0].status === "Failed" ||
                  provider.sync_executions[0].syncStatuses[0].status === "Partial"
                    ? moment(provider.sync_executions[0].syncStatuses[0].createdAt, "YYYY-MM-DD HH:mm:ss.SSSSSS Z").unix()
                    : undefined;
                // set data totality
                provider.status.data_totality =
                  provider.sync_executions[0].syncStatuses[0].status === "Partial" ? DataTotality.Partial : DataTotality.Full;
              }

              resolve();
            },
            () => {
              provider.status = undefined;
              resolve();
            }
          );
        });
      });

      await Promise.all(promises);

      setData(data);
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isSyncInProgress = useCallback((row: SISProvider): boolean => {
    if (!row.status || row.status.error) return false;
    return !!(row.status.start_time && !row.status.end_time);
  }, []);

  const isSyncStartable = useCallback((row: SISProvider): boolean => {
    const now = new Date();
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}") as RunningSyncsMap;
    const manualStatusExpiry = manuallySetRunningSyncs[row.id];
    if (manualStatusExpiry > now.getTime()) return true;

    if (!row.status || row.status.error) return false;

    // Check if start_time is over 15 minutes ago
    const fifteenMinutesAgo = now.getTime() - 15 * 60 * 1000; // 15 minutes in milliseconds
    const startTime = row.status.start_time ? new Date(row.status.start_time).getTime() : null;
    // startTime only has seconds, so * 1000.
    if (startTime && startTime * 1000 < fifteenMinutesAgo) {
      return false;
    }

    return !!(row.status.start_time && !row.status.end_time);
  }, []);

  const fetchDataWithSyncDelay = useCallback(() => {
    setLoaded(false);
    setTimeout(() => {
      fetchData();
    }, 1500);
  }, [fetchData]);

  const updateProvider = useCallback(
    (id: string, row: ProviderData) => {
      setLoaded(false);
      Api.put(`/surfwize/device/ajax/sis/${id}`, row, () => {
        fetchData();
      });
    },
    [fetchData]
  );

  const addProvider = useCallback(
    (data: ProviderData) => {
      setLoaded(false);
      Api.post("/surfwize/device/ajax/sis", data, () => {
        fetchData();
      });
    },
    [fetchData]
  );

  const handle_viewSyncLog = useCallback((row: SISProvider) => {
    setRowToViewLog(row);
  }, []);

  const handle_wondeFormSubmit = useCallback(
    (fields: WondeFormOnHandle, rowEdited: SISProvider | null) => {
      const apiData: ProviderData = {
        type: "wonde",
        enabled: rowEdited ? rowEdited.enabled : true,
        integration_type: fields.integration_type,
        name: fields.name,
        api_configuration: {
          domain: fields.domain,
          district_id: fields.district_id,
          access_token: fields.access_token,
          username_key: fields.username_key,
          email_key: fields.email_key,
          user_match: fields.user_match,
          user_email_key: fields.user_email_key,
          classname_keys: fields.classname_keys,
        },
      };

      if (rowEdited) {
        updateProvider(rowEdited.id, apiData);
      } else {
        addProvider(apiData);
      }
      setShowForm(null);
      setRowToEdit(null);
    },
    [addProvider, updateProvider]
  );

  const handle_updateExcludeSchools = useCallback(
    (schoolIDsToExclude: string[], rowEdited: SISProvider) => {
      const apiData: ProviderData = {
        ...rowEdited,
        excluded_schools_list: schoolIDsToExclude,
      };
      updateProvider(rowEdited.id, apiData);
      setShowExcludeSchoolForm(false);
      setRowToEdit(null);
    },
    [updateProvider]
  );

  const handle_oneRosterFormSubmit = useCallback(
    (fields: OneRosterOnHandle, rowEdited: SISProvider | null, isClasslink: boolean) => {
      const apiData: ProviderData = {
        type: isClasslink ? "classlink" : "oneroster",
        enabled: rowEdited ? rowEdited.enabled : true,
        sync_parents: fields.syncParentData,
        sync_parent_types: fields.syncParentTypes.map((type) => type.toLowerCase()),
        sync_classrooms: fields.syncClassroomData,
        respect_enrolment_date: fields.respectEnrolmentDate,
        respect_sessions: fields.respectSessions,
        integration_type: fields.integrationType,
        excluded_schools_list: rowEdited ? rowEdited.excluded_schools_list : [],
        name: fields.name,
        api_configuration: {
          endpoint_url: fields.endpointUrl,
          token_url: fields.tokenUrl,
          client_id: fields.clientId,
          client_secret: fields.clientSecret,
        },
      };

      if (rowEdited) {
        updateProvider(rowEdited.id, apiData);
      } else {
        addProvider(apiData);
      }

      setShowForm(null);
      setRowToEdit(null);
    },
    [addProvider, updateProvider]
  );

  const handle_powerschoolFormSubmit = useCallback(
    (fields: PowerschoolOnHandle, rowEdited: SISProvider | null) => {
      const apiData: ProviderData = {
        type: "powerschool",
        enabled: rowEdited ? rowEdited.enabled : true,
        sync_classrooms: true,
        respect_enrolment_date: fields.respectEnrolmentDate,
        respect_sessions: fields.respectSessions,
        integration_type: fields.integrationType,
        excluded_schools_list: rowEdited ? rowEdited.excluded_schools_list : [],
        name: fields.name,
        api_configuration: {
          endpoint_url: fields.endpointUrl,
          token_url: fields.tokenUrl,
          client_id: fields.clientId,
          client_secret: fields.clientSecret,
        },
      };

      if (rowEdited) {
        updateProvider(rowEdited.id, apiData);
      } else {
        addProvider(apiData);
      }
      setShowForm(null);
      setRowToEdit(null);
    },
    [addProvider, updateProvider]
  );

  const handle_delete_clicked = useCallback(
    (row: SISProvider) => {
      if (isSyncInProgress(row)) {
        return;
      }

      setRowToDelete(row);
    },
    [isSyncInProgress]
  );

  const handle_delete_confirmation = useCallback(() => {
    if (!rowToDelete) return;
    if (isSyncInProgress(rowToDelete)) {
      return;
    }

    const id = rowToDelete.id;
    Api.delete(`/surfwize/device/ajax/sis/${id}`, null, () => {
      fetchData();
    });
    setRowToDelete(null);
  }, [isSyncInProgress, fetchData, rowToDelete]);

  const handle_edit = useCallback(
    (row: SISProvider) => {
      if (isSyncStartable(row)) {
        return;
      }

      switch (row.type) {
        case "wonde":
          setShowForm(SisVariant.Wonde);
          setRowToEdit(row);
          break;
        case "oneroster":
          setShowForm(SisVariant.Oneroster);
          setRowToEdit(row);
          break;
        case "classlink":
          setShowForm(SisVariant.Classlink);
          setRowToEdit(row);
          break;
        case "powerschool":
          setShowForm(SisVariant.Powerschool);
          setRowToEdit(row);
          break;
        default:
          break;
      }
    },
    [isSyncStartable]
  );

  const handle_include_exclude_schools = useCallback(
    (row: SISProvider) => {
      if (isSyncStartable(row)) {
        return;
      }

      switch (row.type) {
        case "wonde":
          break;
        case "oneroster":
          setRowToEdit(row);
          setShowExcludeSchoolForm(true);
          break;
        case "classlink":
          setShowExcludeSchoolForm(true);
          setRowToEdit(row);
          break;
        case "powerschool":
          setShowExcludeSchoolForm(true);
          setRowToEdit(row);
          break;
        default:
          break;
      }
    },
    [isSyncStartable]
  );

  const handle_sync = useCallback(
    (row: SISProvider) => {
      if (!row.enabled || isSyncStartable(row)) {
        return;
      }

      const handleSuccess = () => {
        const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}") as RunningSyncsMap;
        const now = new Date();
        manuallySetRunningSyncs[row.id] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
        localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
        fetchDataWithSyncDelay();
      };

      if (row.type === "wonde") {
        Api.get("/config/ajax/wonde/sync/" + row.id, handleSuccess);
      } else {
        Api.get("/config/ajax/oneroster/sync/" + row.id, handleSuccess);
      }
    },
    [isSyncStartable, fetchDataWithSyncDelay]
  );

  const handle_reset_confirmation = useCallback(() => {
    if (rowToReset && rowToReset.type === "oneroster") {
      Api.get("/config/reset/oneroster/timestamp/" + rowToReset.id);
      fetchDataWithSyncDelay();
    }
    setRowToReset(null);
  }, [rowToReset, fetchDataWithSyncDelay]);

  const handle_enabledToggleChange = useCallback(
    (row: SISProvider) => {
      if (isSyncInProgress(row)) {
        return;
      }

      row.enabled = !row.enabled;
      updateProvider(row.id, row);
    },
    [updateProvider, isSyncInProgress]
  );

  const getIconByProvider = (variant: SisVariant): string | null => {
    switch (variant) {
      case SisVariant.Wonde:
        return "/static/images/icon_wonde.svg";
      case SisVariant.Oneroster:
        return "/static/images/icon_oneroster.svg";
      case SisVariant.Classlink:
        return "/static/images/icon_classlink.svg";
      case SisVariant.Powerschool:
        return "/static/images/icon_powerschool.png";
      default:
        return null;
    }
  };

  const renderViewLog = () => {
    if (!rowToViewLog) return null;

    return (
      <SyncStatus
        syncType={rowToViewLog.type === "wonde" ? "WONDE" : "ONEROSTER"}
        syncId={rowToViewLog.id}
        hideAndIgnoreSyncState={true}
        hideSyncButton={true}
      />
    );
  };

  const renderHeaderButtons = () => (
    <>
      <TableCentricPageButton
        title="Add New"
        onClickHandler={() => {
          setRowToEdit(null);
          setShowAddNew(true);
        }}
      />
      <TableCentricPageButton title="Refresh" onClickHandler={() => fetchData()} />
    </>
  );

  const emptyText = "---";
  const neverSyncedText = "Never synced";

  const syncDetailsText = useCallback(
    (row: SISProvider): string => {
      if (!row.status || isSyncInProgress(row) || !row.status.start_time) {
        return emptyText;
      }

      if (row.status.data_totality === DataTotality.Partial) {
        return "Partial sync";
      }

      return row.status.error ? "Error" : "Sync successful";
    },
    [isSyncInProgress]
  );

  const sortableSync = useCallback(
    (rowA: SISProvider, rowB: SISProvider): number => {
      //Never synced
      const statusA = rowA.status;
      const statusB = rowB.status;
      if (!statusA && !statusB) {
        return 0;
      } else if (!statusA) {
        return -1;
      } else if (!statusB) {
        return 1;
      }

      //In progress
      const inProgressA = isSyncInProgress(rowA);
      const inProgressB = isSyncInProgress(rowB);
      if (inProgressA && inProgressB) {
        return 0;
      } else if (inProgressA) {
        return 1;
      } else if (inProgressB) {
        return -1;
      }

      const timeA = rowA.status?.error ? rowA.status.start_time : rowA.status?.end_time;
      const timeB = rowB.status?.error ? rowB.status.start_time : rowB.status?.end_time;
      if (!timeA || !timeB) return 0;
      return timeA === timeB ? 0 : timeA < timeB ? -1 : 1;
    },
    [isSyncInProgress]
  );

  const sortableDetails = useCallback(
    (rowA: SISProvider, rowB: SISProvider): number => {
      return FlexibleTable.sort_caseinsensitive_strings(syncDetailsText(rowA), syncDetailsText(rowB));
    },
    [syncDetailsText]
  );

  const lastSyncText = useCallback(
    (row: SISProvider): string => {
      if (!row.status) {
        return neverSyncedText;
      }
      if (row.status.error) {
        return humanizeDiffUnixAndNow(row.status.start_time, "Last Sync Unavailable");
      }
      if (isSyncInProgress(row)) {
        return "In progress";
      }
      return humanizeDiffUnixAndNow(row.status.end_time, "Last Sync Unavailable");
    },
    [isSyncInProgress]
  );

  const syncStatusText = useCallback((row: SISProvider): string => {
    if (!row.status) {
      return emptyText;
    }
    if (row.status.error) {
      return "Failed";
    }
    if (row.status.start_time && row.status.end_time) {
      return "Synced";
    }
    return emptyText;
  }, []);

  const renderContent = useCallback(() => {
    const columns = [
      {
        title: "System",
        data: (row: SISProvider) => <FlexibleTableLabelWithImage label={row.name} imgUrl={getIconByProvider(row.type)} />,
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("name"),
        search: FlexibleTable.search__single_string_field("name"),
      },
      {
        title: "Enabled",
        data: (row: SISProvider) => <ToggleSwitch enabled={row.enabled} onChange={() => handle_enabledToggleChange(row)} />,
      },
      {
        title: "Last Sync",
        data: (row: SISProvider) => {
          const syncText = lastSyncText(row);
          const className = clsx("no-wrap", { "text-disabled": !row.enabled });
          if (!row.status || isSyncInProgress(row)) {
            return <span className={className}>{syncText}</span>;
          }
          return <span>{syncText}</span>;
        },
        search: FlexibleTable.search__with_function(lastSyncText),
        sortable: sortableSync,
      },
      {
        title: "Sync Status",
        data: (row: SISProvider) => {
          if (!row.status) {
            return <EmptyColumnPlaceholder />;
          }
          if (row.status.error && row.status.data_totality !== DataTotality.Partial) {
            return (
              <span className="no-wrap label-with-small-icon-left error text-uppercase">
                <i className="fa fa-exclamation-circle" />
                Failed
              </span>
            );
          }
          if (row.status.start_time && row.status.end_time) {
            const successClassName = clsx({ "text-disabled": !row.enabled });
            return <span className={successClassName}>Synced</span>;
          }

          return <EmptyColumnPlaceholder />;
        },
        sortable: sortableDetails,
        search: FlexibleTable.search__with_function(syncStatusText),
      },
      {
        title: "Sync Details",
        data: (row: SISProvider) => {
          if (!row.status || isSyncInProgress(row) || !row.status.start_time) {
            return <EmptyColumnPlaceholder />;
          }

          const textClassName = clsx(
            (row.status.error || row.status.data_totality === DataTotality.Partial) && "error",
            !row.enabled && "text-disabled"
          );
          return (
            <span className="sis-sync-details">
              <span className={textClassName}>{syncDetailsText(row)}</span>
              <Link onClick={() => handle_viewSyncLog(row)} to={""}>
                [view log]
              </Link>
            </span>
          );
        },
        sortable: sortableDetails,
        search: FlexibleTable.search__with_function(syncDetailsText),
      },
      {
        title: "Operations",
        data: (row: SISProvider) => {
          const rowOperationsClassName = clsx("row-operations", isSyncStartable(row) && "row-operations-disabled");
          const syncActionClassName = clsx(!row.enabled && "disabled");
          const syncReset = clsx(row.type !== SisVariant.Oneroster && "disabled");
          const deleteActionClassName = clsx(!isSyncInProgress(row) && "destructive");
          const resetSyncFunc = () => {
            if (row.type === "oneroster") {
              setRowToReset(row);
            }
          };
          return (
            <span className={rowOperationsClassName}>
              <Link to={""} className={syncActionClassName} onClick={() => handle_sync(row)}>
                Sync
              </Link>
              <Separator />
              <Link to={""} className={syncReset} onClick={resetSyncFunc}>
                Reset
              </Link>
              <Separator />
              <Link to={""} onClick={() => handle_edit(row)}>
                Edit
              </Link>
              {row.type !== SisVariant.Wonde && (
                <>
                  <Separator />
                  <Link to={""} onClick={() => handle_include_exclude_schools(row)}>
                    Exclude Schools
                  </Link>
                </>
              )}
              <Separator />
              <Link to={""} className={deleteActionClassName} onClick={() => handle_delete_clicked(row)}>
                Delete
              </Link>
            </span>
          );
        },
      },
    ];

    return <FlexibleTable columns={columns} data={data} movable={false} loaded={loaded} />;
  }, [
    handle_include_exclude_schools,
    lastSyncText,
    sortableSync,
    sortableDetails,
    syncStatusText,
    syncDetailsText,
    data,
    loaded,
    handle_enabledToggleChange,
    isSyncInProgress,
    isSyncStartable,
    handle_viewSyncLog,
    handle_sync,
    handle_edit,
    handle_delete_clicked,
  ]);

  return (
    <React.Fragment>
      <TableLeftPanel>
        <TableCentricPage title="School Information Systems" buttons={renderHeaderButtons()} content={renderContent()} />
      </TableLeftPanel>
      <SleekModal
        show={showAddNew}
        title="Add new SIS integration"
        description="Which SIS integration would you like to add?"
        handleHide={() => setShowAddNew(false)}
      >
        <div className="sis-add-new-provider-icons-container">
          <SisProviderIconButton
            label="OneRoster"
            imgSrc={getIconByProvider(SisVariant.Oneroster)}
            onClick={() => {
              setShowForm(SisVariant.Oneroster);
              setShowAddNew(false);
            }}
          />
          <SisProviderIconButton
            label="Wonde"
            imgSrc={getIconByProvider(SisVariant.Wonde)}
            onClick={() => {
              setShowForm(SisVariant.Wonde);
              setShowAddNew(false);
            }}
          />
          <SisProviderIconButton
            label="ClassLink"
            imgSrc={getIconByProvider(SisVariant.Classlink)}
            onClick={() => {
              setShowForm(SisVariant.Classlink);
              setShowAddNew(false);
            }}
          />
          <SisProviderIconButton
            label="PowerSchool"
            imgSrc={getIconByProvider(SisVariant.Powerschool)}
            onClick={() => {
              setShowForm(SisVariant.Powerschool);
              setShowAddNew(false);
            }}
          />
        </div>
      </SleekModal>
      <SleekModal show={!!rowToViewLog} handleHide={() => setRowToViewLog(null)}>
        {rowToViewLog && renderViewLog()}
      </SleekModal>
      <SisDeleteDialog
        modalClassName="sis-delete-modal"
        show={rowToDelete}
        handleHide={() => setRowToDelete(null)}
        handleDeleteConfirmed={handle_delete_confirmation}
      />
      <SisResetDialog
        modalClassName="sis-delete-modal"
        show={rowToReset}
        handleHide={() => setRowToReset(null)}
        handleResetConfirmed={handle_reset_confirmation}
      />
      <SisOneRosterForm
        show={showForm === SisVariant.Oneroster || showForm === SisVariant.Classlink}
        isClasslink={showForm === SisVariant.Classlink}
        rowToEdit={rowToEdit}
        handleHide={() => {
          setRowToEdit(null);
          setShowForm(null);
        }}
        handleSubmit={handle_oneRosterFormSubmit}
      />
      <SisPowerSchoolForm
        show={showForm === SisVariant.Powerschool}
        rowToEdit={rowToEdit}
        handleHide={() => {
          setRowToEdit(null);
          setShowForm(null);
        }}
        handleSubmit={handle_powerschoolFormSubmit}
      />
      <SisWondeForm
        show={showForm === SisVariant.Wonde}
        rowToEdit={rowToEdit}
        handleHide={() => {
          setRowToEdit(null);
          setShowForm(null);
        }}
        handleSubmit={handle_wondeFormSubmit}
      />
      {showExcludeSchoolForm && rowToEdit && (
        <SisSchoolExcludeForm
          show={showExcludeSchoolForm}
          rowToEdit={rowToEdit}
          handleHide={() => {
            setRowToEdit(null);
            setShowExcludeSchoolForm(false);
          }}
          handleSubmit={handle_updateExcludeSchools}
        />
      )}
    </React.Fragment>
  );
};
