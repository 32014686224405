import React from "react";

import LoginPageHeader from "../modules/LoginPageHeader";

export default class LoginRegisterAwaitingVerification extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      code: "",
      error: "",
    };
  }
  render() {
    return (
      <div className="loginpage-body">
        <LoginPageHeader />

        <div className="loginpage-loginform">
          <form className="mui-form">
            <legend>Account created</legend>
            <br />

            <div>Thanks for creating a Linewize account. Please check your emails to verify your account.</div>
            <br />
          </form>
        </div>
      </div>
    );
  }
}
