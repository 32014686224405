import { create } from "zustand";
import { ResponseError, jqXHR } from "../types/Api";
import Api from "../utils/Api";
import { AuditRecord, AuditScope, EntityType } from "../types/Audit";
import { AUDIT_MAX_PAGE_SIZE, AUDIT_SEARCH_DEFAULT_PAGE_SIZE } from "../constants/CommunityConstants";
import { useSessionStore } from "./SessionStore";

export const auditURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/audits`;
};

export interface SaveOptions {
  overrideVersionConflict: boolean;
}

export interface SearchOptions {
  query?: string;
  scopes?: AuditScope[];
  entityTypes?: EntityType[];
  cursor?: string;
  pageSize?: number;
}

export interface AuditListResponse {
  audits: AuditRecord[];
  hasMore: boolean;
}

export interface AuditStore {
  audits: AuditRecord[];
  hasMore: boolean;
  fetch: (options: SearchOptions) => Promise<AuditRecord[]>;
  reset: () => void;
}

export const useAuditStore = create<AuditStore>((set, get) => ({
  audits: [],
  hasMore: true,

  fetch: async (options: SearchOptions): Promise<AuditRecord[]> => {
    const pageSize = options.pageSize ? Math.min(options.pageSize, AUDIT_MAX_PAGE_SIZE) : AUDIT_SEARCH_DEFAULT_PAGE_SIZE;

    const params = new URLSearchParams({ limit: String(pageSize) });

    if (options.cursor) {
      params.append("cursor", options.cursor);
    }

    if (options.query) {
      params.append("q", options.query);
    }

    if (options.scopes && options.scopes.length > 0) {
      options.scopes.forEach((s) => params.append("scope", s));
    }

    if (options.entityTypes && options.entityTypes.length > 0) {
      options.entityTypes.forEach((t) => params.append("entityType", t));
    }

    let auditRecords;
    let hasMore = true;
    let statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(`${auditURL()}?${params.toString()}`).then(
      (response: AuditListResponse) => {
        hasMore = response.hasMore;

        if (options.cursor) {
          auditRecords = [...get().audits, ...response.audits];
        } else {
          auditRecords = response.audits;
        }
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!auditRecords || statusCode !== 200) {
      throw new ResponseError("Failed to fetch audit records", statusCode);
    }

    set({ audits: undefined });
    set({ audits: auditRecords, hasMore });

    return auditRecords;
  },

  reset: () => {
    set({ audits: undefined });
  },
}));
