import React from "react";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import Separator from "../../modules/Separator";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import ObjectPrinter from "../../criteria/ObjectPrinter";
import ObjectStore from "../../stores/ObjectStore";
import { ABTester } from "@familyzone/component-library";
import TimeoutsNew from "./TimeoutsNew";

import { timeoutValueText, timeoutTypeText } from "./TimeoutHelpers";

export default class Timeouts extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      custom_timeouts: [],
      loaded: false,
    };

    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/config/ajax/authentication/timeouts", function (result) {
      let custom_timeouts = result["custom_timeouts"];
      self.setState({
        custom_timeouts: custom_timeouts,
        loaded: true,
      });
    });
  };

  handler__clickAdd = () => {
    let self = this;
    Api.put("/config/ajax/authentication/timeouts", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickDelete = (id) => {
    console.log("handler__clickDelete called");
    console.log(id);
    let self = this;
    Api.delete("/config/ajax/authentication/timeouts", { id: id }, function (result) {
      self.handle_load();
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Timeout" onClickHandler={this.handler__clickAdd} />;
  };

  sortableTimeoutValue = (rowA, rowB) => {
    const timeoutSort = FlexibleTable.sortable__single_int_field("timeout")(rowA, rowB);
    if (timeoutSort !== 0) {
      return timeoutSort;
    }
    return FlexibleTable.sort_caseinsensitive_strings(timeoutValueText(rowA), timeoutValueText(rowB));
  };

  sortableNetworks = (rowA, rowB) => {
    const networksA = [];
    const networksB = [];

    for (let network of rowA["networks"]) {
      const resolved = ObjectStore.getObject(network);
      if (resolved) {
        networksA.push(resolved["name"]);
      } else {
        networksA.push(network);
      }
    }

    for (let network of rowB["networks"]) {
      const resolved = ObjectStore.getObject(network);
      if (resolved) {
        networksB.push(resolved["name"]);
      } else {
        networksB.push(network);
      }
    }

    const valueA = networksA.join(",");
    const valueB = networksB.join(",");

    return FlexibleTable.sort_caseinsensitive_strings(valueA, valueB);
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Authentication Provider",
        data: function (row) {
          return row["provider"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("provider"),
        search: FlexibleTable.search__single_string_field("provider"),
        onclick: undefined,
      },
      {
        title: "Groups",
        data: function (row) {
          return row["groups"];
        },
        sortable: FlexibleTable.sortable__caseinsensitive_string_array_field("groups"),
        search: FlexibleTable.search__single_string_field("groups"),
        onclick: undefined,
      },
      {
        title: "Networks",
        data: function (row) {
          return <ObjectPrinter values={row["networks"]}></ObjectPrinter>;
        },
        sortable: this.sortableNetworks,
        search: FlexibleTable.search__object_printer("networks"),
        onclick: undefined,
      },
      {
        title: "Timeout Type",
        data: timeoutTypeText,
        sortable: FlexibleTable.sortable__single_int_nested_field("type"),
        search: FlexibleTable.search__with_function(timeoutTypeText),
        onclick: undefined,
      },
      {
        title: "Timeout Value",
        data: timeoutValueText,
        sortable: this.sortableTimeoutValue,
        search: FlexibleTable.search__with_function(timeoutValueText),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton to={"/config/device/authentication/timeouts/" + row["id"]} />
              <Separator />
              <DeleteButton onClick={() => self.handler__clickDelete(row["id"])} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.custom_timeouts} loaded={this.state.loaded} />;
  };

  oldUi = () => {
    return (
      <TableLeftPanel>
        <TableCentricPage icon="fa fa-user" title="Timeouts" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  };

  render() {
    return (
      <ABTester
        optionB={this.oldUi()}
        optionA={<TimeoutsNew timeouts={this.state.custom_timeouts} loaded={this.state.loaded} />}
        isOptionB={true}
      />
    );
  }
}
