import React from "react";
export default class ChildrenCentricPage extends React.Component {
  render() {
    return (
      <div className="table-backdrop">
        <div className="maincontent-sub">
          <div className="main-content-sub-element">
            <div id="fz_table_header" className="tableheader tableheader-dark">
              <div className="tableheader-title">{this.props.title}</div>
              <div id="fz_table_header_buttons" className="table-header-buttons">
                {this.props.buttons}
              </div>
            </div>
          </div>

          {this.props.children}
        </div>
      </div>
    );
  }
}
