import React, { useState, useEffect, useCallback } from "react";
import { Box, useToast, useTranslation } from "@familyzone/component-library";

import { SafeSearchRule } from "../types";

import { createSafeSearchRule, getSafeSearchRules, modifySafeSearchRule, sortSafeSearchRules, deleteSafeSearchRule } from "../ApiHelpers";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import PageWithHeader from "../../../components/templates/PageWithHeader";
import SafeSearchTable from "../SafeSearchTable";
import SafeSearchLayout from "../SafeSearchLayout";
import SafeSearchModal from "../SafeSearchModal";

export const titleText = "Safe Search";
export const breadcrumbsText = ["Filtering", "Safe Search"];
export const addRuleErrorText = "There was an error creating the rule. Please refresh the page.";
export const modifyRuleErrorText = "There was an error saving the rule. Please refresh the page.";
export const sortRuleErrorText = "There was an error sorting the rules. Please refresh the page.";
export const deleteRuleErrorText = "There was an error deleting the rule. Please refresh the page.";

const SafeSearchPage: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { title: t(breadcrumbsText[0]), url: "/filtering", isActive: false },
    { title: t(breadcrumbsText[1]), isActive: true },
  ];

  const { errorToast } = useToast();
  const isMounted = useIsMounted();

  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);
  const [editingRule, setEditingRule] = useState<null | SafeSearchRule>(null);
  const [policyEdit, setPolicyEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [rules, setRules] = useState<SafeSearchRule[]>([]);

  const [search, setSearch] = useState("");

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleClosePolicyModal = () => {
    setPolicyModalOpen(false);
  };

  const handleClickCreateRule = useCallback(async () => {
    try {
      setLoading(true);
      // Add empty rule
      await createSafeSearchRule();
      if (!isMounted()) return;
      // Get total policies
      const filteringRules: SafeSearchRule[] = await getSafeSearchRules();
      if (!isMounted()) return;
      const newRule = filteringRules[filteringRules.length - 1];
      setRules(filteringRules);
      setEditingRule(newRule);

      setPolicyModalOpen(true);
      setPolicyEdit(false);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: t(addRuleErrorText),
      });
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted, errorToast, t]);

  const handleSaveRule = useCallback(
    async (rule: SafeSearchRule) => {
      try {
        setLoading(true);
        await modifySafeSearchRule(rule);
        if (!isMounted()) return;
        setRules(
          rules.map((r) => {
            if (r.id !== rule.id) return r;
            return rule;
          })
        );
        setPolicyModalOpen(false);
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: modifyRuleErrorText,
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, rules, errorToast]
  );

  const handleSortRules = useCallback(
    async (newRules: SafeSearchRule[]) => {
      try {
        setLoading(true);
        setRules(newRules);
        await sortSafeSearchRules(newRules);
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: t(sortRuleErrorText),
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, errorToast, t]
  );

  const handleDeleteRule = useCallback(
    async (rule: SafeSearchRule) => {
      try {
        if (loading) return;
        setLoading(true);
        await deleteSafeSearchRule(rule.id);
        if (!isMounted()) return;
        setRules(rules.filter((r) => r.id !== rule.id));
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: t(deleteRuleErrorText),
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, loading, rules, errorToast, t]
  );

  const handleEditRule = (rule: SafeSearchRule) => {
    if (loading) return;
    setEditingRule(rule);
    setPolicyModalOpen(true);
    setPolicyEdit(true);
  };

  const populateRules = useCallback(async () => {
    try {
      setLoading(true);
      // Remove any content mod rules from the ruleset before showing them
      const safeSearchRules: SafeSearchRule[] = await getSafeSearchRules();
      if (!isMounted()) return;
      setRules(safeSearchRules);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: "There was an error populating the policies",
      });
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted, errorToast]);

  useEffect(() => {
    populateRules().catch(() => "");
  }, [populateRules]);

  return (
    <PageWithHeader breadcrumbs={breadcrumbs} title={t(titleText)}>
      <Box width="100%" mt="sp16">
        <SafeSearchLayout
          loading={loading && rules.length === 0}
          disabled={loading && rules.length !== 0}
          onClickCreateRule={() => void handleClickCreateRule().catch(() => "")}
          onChangeSearch={handleChangeSearch}
          onClearSearch={handleClearSearch}
          search={search}
        >
          <SafeSearchTable
            disabled={loading && rules.length !== 0}
            loading={loading && rules.length === 0}
            rules={rules}
            onSortRules={(rules: SafeSearchRule[]) => void handleSortRules(rules)}
            onDeleteRule={(rule: SafeSearchRule) => void handleDeleteRule(rule)}
            onEditRule={(rule: SafeSearchRule) => void handleEditRule(rule)}
            search={search}
          />
        </SafeSearchLayout>
        <SafeSearchModal
          loading={loading}
          open={policyModalOpen}
          rule={editingRule}
          editing={policyEdit}
          onClose={handleClosePolicyModal}
          onSubmit={(rule: SafeSearchRule) => void handleSaveRule(rule)}
        />
      </Box>
    </PageWithHeader>
  );
};

export default SafeSearchPage;
