import { EfrontCourse } from "../../../utils/api/CoursesConfig";

export function sortCoursesAlphabeticallyByTitle(courses: EfrontCourse[]): EfrontCourse[] {
  return courses.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
}
