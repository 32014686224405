import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Icon, InlineNotification } from "@familyzone/component-library";
import moment from "moment/moment";
import { Divider } from "@chakra-ui/layout";
import {
  FetchSyncExecutionsError,
  FetchSyncExecutionsResponse,
  getEndpoint,
  SyncExecution,
  SyncStatus as SyncExecutionStatus,
} from "../interfaces/GetSyncExecutions";
import { Tooltip, Text, Link } from "@chakra-ui/react";
import Api from "../../../utils/Api";
import DumbBusyIndicator from "../../../modules/DumbBusyIndicator";
import SearchableSelect from "../../../modules/SearchableSelect";

interface OnChange {
  label: string;
  value: string;
}

interface SyncStatusProps {
  syncType: "GOOGLE" | "LDAPS" | "AZURE" | "CLEVER" | "ONEROSTER" | "WONDE";
  syncId?: string;
  handleRunSync?: () => void;
  syncLockoutTime?: Date;
  hideSyncButton?: boolean;
  hideAndIgnoreSyncState?: boolean;
}

export const formatString = "YYYY-MM-DD HH:mm:ss.SSSSSS Z";

export const SyncStatus: FC<SyncStatusProps> = ({
  syncType,
  syncId,
  handleRunSync,
  syncLockoutTime,
  hideSyncButton,
  hideAndIgnoreSyncState,
}) => {
  const [syncExecutions, setSyncExecutions] = useState<SyncExecution[]>([]);
  const [latestSyncExecution, setLatestSyncExecution] = useState<SyncExecution>();
  const [latestStatusOfLatestExecution, setLatestStatusOfLatestExecution] = useState<SyncExecutionStatus>();
  const [latestStatus, setLatestStatus] = useState<SyncExecutionStatus>();
  const [firstStatus, setFirstStatus] = useState<SyncExecutionStatus>();
  const [latestPartialError, setLatestPartialError] = useState<JSX.Element[]>([]);
  const [selected, setSelected] = useState<SyncExecution>();
  const [, setSyncExecutionsError] = useState<string>("");
  const [, setLoading] = useState<boolean>(true);

  const fetchStatuses = useCallback(() => {
    setLoading(true);
    setSyncExecutions([]);
    setSyncExecutionsError("");
    Api.get(
      getEndpoint(syncType, syncId),
      (data: FetchSyncExecutionsResponse) => {
        // flatten the result
        const results = data.result.edges.map((edge) => edge.node);

        setSyncExecutions(results);
        setSelected(results[0]);
        setLatestSyncExecution(results[0]);
        setLatestStatus(results[0]?.syncStatuses[0]);
        setLatestStatusOfLatestExecution(results[0]?.syncStatuses[0]);
        setFirstStatus(results[0]?.syncStatuses.find((se) => se.status === "Started"));
        setLoading(false);
      },
      (e: FetchSyncExecutionsError) => {
        setSyncExecutionsError(e.error);
        setLoading(false);
      }
    );
  }, [syncType, syncId]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    // here we parse the partial errors, need to make /n into new lines
    // this isn't the best way to do this, but it works for now and we have very minimal partial errors happening.
    if (selected?.syncStatuses[0]?.status === "Partial") {
      const messageLines = selected?.syncStatuses[0].message.split("\n").map((line, index) => {
        // this is a bit of a hack to tidy up the messages we get, likely will be tidied up by the backend at some point.
        if (line === "Error:" || line.length < 7) {
          return <></>;
        }
        if (line.includes("failed to fetch")) {
          return (
            <React.Fragment key={index}>
              <Divider borderColor="neutrals.40" />
              <Box display={"flex"} flexDirection={"column"} backgroundColor="#FBC9C9">
                <Text fontSize={16}>{line}</Text>
                <br />
              </Box>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      });
      setLatestPartialError(messageLines);
    } else {
      setLatestPartialError([]);
    }
  }, [selected]);

  const [cannotSyncReason, setCannotSyncReason] = useState<string>();
  const [canClick, setCanClick] = useState<boolean>(true);
  // here we set the reason why the sync button is disabled
  useEffect(() => {
    const storedValue = localStorage.getItem("syncOverride");
    if (storedValue) {
      setCannotSyncReason(undefined);
      return;
    }
    if (!handleRunSync) {
      setCannotSyncReason("Sync Configuration is not complete, ensure config and credentials are set correctly and saved.");
      return;
    } else if (
      moment(syncLockoutTime).isAfter(moment()) ||
      (latestSyncExecution?.syncStatuses[0]?.status === "Started" &&
        moment(latestSyncExecution?.syncStatuses[0]?.createdAt, formatString).isAfter(moment().subtract(15, "minute")))
    ) {
      // Lockout to allow JC to spin up a job and save a running status
      setCannotSyncReason("A sync was started within the last 15 minutes, please wait before attempting again.");
      return;
    } else {
      setCannotSyncReason(undefined);
    }
  }, [latestSyncExecution, handleRunSync, syncLockoutTime]);

  const handleRunSyncModified = useCallback(() => {
    if (handleRunSync && canClick) {
      setCanClick(false);
      handleRunSync();

      // we disable the button for 1 seconds, this is to prevent multiple clicks happening before we get a response.
      setTimeout(() => {
        setCanClick(true);
        fetchStatuses();
      }, 2000);
    }
  }, [canClick, handleRunSync, fetchStatuses]);

  const syncButton = useMemo(() => {
    return (
      <Box borderBottom={"solid 1px whitesmoke"} display={"flex"} flexDir={"column"} gap={"1rem"}>
        <Text fontSize={18} color={"black"}>
          Run Sync
        </Text>
        <Button
          disabled={!!cannotSyncReason || !canClick}
          onClick={handleRunSyncModified}
          leftIcon={<DumbBusyIndicator loaded={latestStatusOfLatestExecution?.status !== "Started"} />}
          variant={"primary"}
          data-testid="run-sync-button"
        >
          {latestStatusOfLatestExecution?.status === "Started" ? "Running Sync" : !handleRunSync ? "Unable to start" : "Run Sync"}
        </Button>
        {cannotSyncReason && (
          <InlineNotification status="warning" notificationTitle={"Unable to start sync"} notificationDescription={cannotSyncReason} />
        )}
        {!cannotSyncReason && latestStatusOfLatestExecution?.status === "Started" && (
          <InlineNotification
            status="warning"
            notificationTitle={"Sync is in progress"}
            notificationDescription={
              "A sync is currently in progress, please wait for it to finish. " +
              "\nIf you believe there has been an error, please try again or contact support."
            }
          />
        )}
      </Box>
    );
  }, [cannotSyncReason, canClick, handleRunSyncModified, latestStatusOfLatestExecution, handleRunSync]);

  const statsToRender: JSX.Element[] = useMemo(() => {
    const stats = [];
    let count = 0;

    if (!hideAndIgnoreSyncState) {
      stats.push(
        <ValueField
          key={`value-field-${count}`}
          variant={count % 2 === 0}
          title={"Sync Status:"}
          value={latestStatus?.status || "Never ran"}
        />
      );
      count++;
    }

    if (latestStatus?.status === "Started") {
      stats.push(
        <ValueField
          key={`value-field-${count}`}
          variant={count % 2 === 0}
          title={"Time Elapsed"}
          value={moment.duration(moment().diff(moment(latestStatus?.createdAt, formatString))).humanize()}
        />
      );
      count++;
    }

    if (firstStatus) {
      stats.push(
        <ValueField
          key={`value-field-${count}`}
          title={"Sync Started:"}
          value={`${moment(firstStatus?.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")}\n(${moment
            .duration(moment(firstStatus?.createdAt, formatString).diff(moment()))
            .humanize(true)})`}
          variant={count % 2 === 0}
          tooltipLabel={"Sync time is in your local time zone."}
        />
      );
      count++;
    }

    if (latestStatus && (latestStatus.status === "Completed" || hideAndIgnoreSyncState)) {
      stats.push(
        <ValueField
          key={`value-field-${count}`}
          variant={count % 2 === 0}
          title={"Sync Finished"}
          value={`${moment(latestStatus?.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")}\n(${moment
            .duration(moment(latestStatus.createdAt, formatString).diff(moment()))
            .humanize(true)})`}
          tooltipLabel={"Sync time is in your local time zone."}
        />
      );
      count++;
    }

    if (latestStatus && (latestStatus?.status === "Completed" || hideAndIgnoreSyncState) && latestStatus.metrics) {
      // Define the tooltips, titles, and values

      const fields = [
        {
          tooltipLabel: "How many students we matched between our systems and the sync data.",
          title: `Students Matched`,
          value: latestStatus.metrics.studentsMatched,
        },
        {
          tooltipLabel: "How many students we couldn't match between our systems and the sync data.",
          title: `Students Unmatched`,
          value: latestStatus.metrics.studentsUnmatched,
        },
        {
          tooltipLabel: "How many teachers we matched between our systems and the sync data.",
          title: `Teachers Matched`,
          value: latestStatus.metrics.teachersMatched,
        },
        {
          tooltipLabel: "How many teachers we couldn't match between our systems and the sync data.",
          title: `Teachers Unmatched`,
          value: latestStatus.metrics.teachersUnmatched,
        },
        {
          tooltipLabel: "How many users remained unchanged from this sync.",
          title: `Users Unchanged`,
          value: latestStatus.metrics.usersUnchanged,
        },
        { tooltipLabel: "How many new users were added from this sync.", title: `Users Added`, value: latestStatus.metrics.usersAdded },
        { tooltipLabel: "How many users were removed from this sync.", title: `Users Deleted`, value: latestStatus.metrics.usersDeleted },
        { tooltipLabel: "How many users were updated from this sync.", title: `Users Updated`, value: latestStatus.metrics.usersUpdated },
        {
          tooltipLabel: "How many groups remained unchanged from this sync.",
          title: `Groups Unchanged`,
          value: latestStatus.metrics.groupsUnchanged,
        },
        { tooltipLabel: "How many new groups were added from this sync.", title: `Groups Added`, value: latestStatus.metrics.groupsAdded },
        {
          tooltipLabel: "How many groups were removed from this sync.",
          title: `Groups Deleted`,
          value: latestStatus.metrics.groupsDeleted,
        },
        {
          tooltipLabel: "How many groups were updated from this sync.",
          title: `Groups Updated`,
          value: latestStatus.metrics.groupsUpdated,
        },
        {
          tooltipLabel: "How many classrooms remained unchanged from this sync.",
          title: `Classrooms Unchanged`,
          value: latestStatus.metrics.classroomsUnchanged,
        },
        {
          tooltipLabel: "How many new classrooms were added from this sync.",
          title: `Classrooms Added`,
          value: latestStatus.metrics.classroomsAdded,
        },
        {
          tooltipLabel: "How many classrooms were removed from this sync.",
          title: `Classrooms Deleted`,
          value: latestStatus.metrics.classroomsDeleted,
        },
        {
          tooltipLabel: "How many classrooms were updated from this sync.",
          title: `Classrooms Updated`,
          value: latestStatus.metrics.classroomsUpdated,
        },
        {
          tooltipLabel: "How many guardians remained unchanged from this sync.",
          title: `Guardians Unchanged`,
          value: latestStatus.metrics.guardiansUnchanged,
        },
        {
          tooltipLabel: "How many guardians were added from this sync.",
          title: `Guardians Added`,
          value: latestStatus.metrics.guardiansAdded,
        },
        {
          tooltipLabel: "How many guardians were removed from this sync.",
          title: `Guardians Deleted`,
          value: latestStatus.metrics.guardiansDeleted,
        },
        {
          tooltipLabel: "How many guardians were updated from this sync.",
          title: `Guardians Updated`,
          value: latestStatus.metrics.guardiansUpdated,
        },
        {
          tooltipLabel: "How many users were added to groups from this sync.",
          title: `Users Added to Groups`,
          value: latestStatus.metrics.userGroupsAdded,
        },
        {
          tooltipLabel: "How many users were removed from groups from this sync.",
          title: `Users Removed from Groups`,
          value: latestStatus.metrics.userGroupsDeleted,
        },
        {
          tooltipLabel: "How many users were added to classrooms from this sync.",
          title: `Users Added to Classrooms`,
          value: latestStatus.metrics.userClassroomsAdded,
        },
        {
          tooltipLabel: "How many users were removed from classrooms from this sync.",
          title: `Users Removed from Classrooms`,
          value: latestStatus.metrics.userClassroomsDeleted,
        },
        {
          tooltipLabel: "How many users were added to guardians from this sync.",
          title: `Users Added to Guardians`,
          value: latestStatus.metrics.userGuardiansAdded,
        },
        {
          tooltipLabel: "How many users were removed from guardians from this sync.",
          title: `Users Removed from Guardians`,
          value: latestStatus.metrics.userGuardiansDeleted,
        },
        {
          tooltipLabel: "How many groups were added as subgroups from this sync.",
          title: `Groups Added as Subgroups`,
          value: latestStatus.metrics.groupSubgroupsAdded,
        },
        {
          tooltipLabel: "How many groups were removed from subgroups from this sync.",
          title: `Groups Removed as Subgroups`,
          value: latestStatus.metrics.groupSubgroupsDeleted,
        },
        {
          tooltipLabel: "How many users were added as group owners from this sync.",
          title: `Users Added as Group Owners`,
          value: latestStatus.metrics.groupOwnersAdded,
        },
        {
          tooltipLabel: "How many users were removed as group owners from this sync.",
          title: `Users Removed as Group Owners`,
          value: latestStatus.metrics.groupOwnersDeleted,
        },
      ];

      fields.forEach((field) => {
        if (field.value !== null && typeof field.value !== "undefined") {
          stats.push(
            <ValueField
              key={`value-field-${count}`}
              variant={count % 2 === 0}
              tooltipLabel={field.tooltipLabel}
              title={field.title}
              value={field.value}
            />
          );
          count++;
        }
      });
    }

    return stats;
  }, [latestStatus, firstStatus, hideAndIgnoreSyncState]);

  return (
    <Box id="main-azure-config" background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"24px"} borderRadius={"6px"}>
      {!hideSyncButton && syncButton}
      <Box display={"flex"}>
        <Text fontSize={18} color={"black"}>
          Sync Status
        </Text>
        <Link onClick={fetchStatuses} marginLeft={"auto"}>
          <Box display={"flex"} marginLeft={"auto"} color={"#094C99"} alignItems={"center"} gap={"4px"} cursor={"pointer"}>
            <Icon aria-label={"refresh-icon"} icon="fa-arrows-rotate" />
            <Text>Refresh</Text>
          </Box>
        </Link>
      </Box>
      <SearchableSelect
        sx={{ width: "100%" }}
        value={selected?.id}
        loading={true}
        onChange={(e: OnChange) => {
          const newStatus = syncExecutions.find((sync) => sync.id === e.value);
          setSelected(newStatus);
          setLatestStatus(newStatus?.syncStatuses[0]);
          setFirstStatus(newStatus?.syncStatuses.find((se) => se.status === "Started"));
        }}
        valueDataMap={syncExecutions.map((sync) => [
          sync.id,
          `${
            sync.syncStatuses[0] ? moment.utc(sync.syncStatuses[0].createdAt, formatString).local().format("YYYY-MM-DD HH:mm:ss") : ""
          } - ${sync.syncStatuses[0] ? sync.syncStatuses[0].status : ""}`,
        ])}
      />
      {latestStatus?.status === "Failed" && (
        <InlineNotification
          status="error"
          notificationTitle={"Sync finished with errors"}
          notificationDescription={latestStatus?.message || "Unknown error, please try again or contact support."}
        />
      )}
      <Box border={"1px solid #EBECF0"} borderRadius={"6px"}>
        {statsToRender}
      </Box>
      {latestStatus?.status === "Partial" && (
        <Box
          backgroundColor={"#f0f0f0"}
          borderRadius={"6px"}
          padding={"1.75rem"}
          gap={"0.6rem"}
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          maxHeight={"200px"}
        >
          <Text fontSize={18}>Partially completed with errors:</Text>
          {latestPartialError}
        </Box>
      )}
    </Box>
  );
};

interface ValueFieldProps {
  value: string | number | undefined | null;
  title: string;
  variant?: boolean;
  tooltipLabel?: string;
}

const ValueField: React.FC<ValueFieldProps> = ({ value, title, tooltipLabel, variant = false }) => {
  if (value === null || value === undefined) return <></>;

  if (tooltipLabel === undefined) {
    return (
      <Box display={"flex"} backgroundColor={variant ? "#F4F5F7" : ""} alignContent={"center"} alignItems={"center"}>
        <Text flex={1} padding={"8px 0px 8px 8px"} fontWeight={"bold"} color={"#051839"}>
          {title}
        </Text>
        <Text flex={1} padding={"8px 0px 8px 8px"} fontWeight={400}>
          {value}
        </Text>
      </Box>
    );
  }

  return (
    <Tooltip variant="dark" label={tooltipLabel}>
      <Box display={"flex"} backgroundColor={variant ? "#F4F5F7" : ""} alignContent={"center"} alignItems={"center"}>
        <Text flex={1} padding={"8px 0px 8px 8px"} fontWeight={"bold"} color={"#051839"}>
          {title}
        </Text>
        <Text flex={1} padding={"8px 0px 8px 8px"} fontWeight={400}>
          {value}
        </Text>
      </Box>
    </Tooltip>
  );
};
