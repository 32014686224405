function AdultContentUrl(userId: string, dateFilter?: string): string {
  const url = "/surfwize/reporting/type/sphirewall.application.porn/user/" + userId + "?show_blocked=true&hits=true";
  if (dateFilter) {
    return url + "&" + dateFilter;
  }
  return url;
}

function OffensiveContentUrl(userId: string, dateFilter?: string): string {
  const url = "/surfwize/reporting/type/sphirewall.application.offensive/user/" + userId + "?show_blocked=true&hits=true";
  if (dateFilter) {
    return url + "&" + dateFilter;
  }
  return url;
}

function VPNUrl(userId: string, dateFilter?: string): string {
  const url = "/surfwize/reporting/type/sphirewall.application.blocklist.proxies/user/" + userId + "?show_blocked=true&hits=true";
  if (dateFilter) {
    return url + "&" + dateFilter;
  }
  return url;
}

function SuicideUrl(userId: string, dateFilter?: string): string {
  const url = "/cybersafety/search/user/" + userId + "/suicide";
  if (dateFilter) {
    return url + "?" + dateFilter;
  }
  return url;
}

function SearchUrl(userId: string, searchId: string, dateFilter?: string): string {
  const url = "/cybersafety/search/user/" + userId + "/" + searchId;
  if (dateFilter) {
    return url + "?" + dateFilter;
  }
  return url;
}

export { AdultContentUrl, OffensiveContentUrl, VPNUrl, SuicideUrl, SearchUrl };
