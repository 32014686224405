import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import QueryFilter from "../../modules/QueryFilter";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import FlexibleTable from "../../modules/FlexibleTable";
import AmountField from "../../modules/AmountField";
import SignatureStore from "../../stores/SignatureStore";
import SignatureActions from "../../actions/SignatureActions";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import Filterhelper from "../../utils/Filterhelper";

export default class ReportingWebsiteUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      loaded: false,
      filters: Filterhelper.operate(this),
    };

    if (props.location?.query && props.location.query.startDate && props.location.query.endDate)
      useDateRangeFilterStore.getState().updateDateFilter({
        startDate: props.location.query.startDate,
        endDate: props.location.query.endDate,
        startTime: props.location.query.startTime ? props.location.query.startTime : "00:00:00",
        endTime: props.location.query.endTime ? props.location.query.endTime : "23:59:59",
        relativeKey: "",
      });
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    SignatureStore.listen(this.onChange);

    setTimeout(SignatureActions.fetch, 0);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    this.handle_load();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.handle_load();
  };

  handle_load = () => {
    this.setState({
      data: [],
    });

    let self = this;
    Api.get_analytics(
      "/surfwize/analytics/website/" +
        self.props.params.website +
        "/user/" +
        this.props.params.user +
        "/by/day?" +
        Filterhelper.serialise(self.state.filters),
      function (result) {
        self.setState({
          data: result["data"],
          loaded: true,
        });
      },
      function (err) {
        console.log(err);
      },
      []
    );
  };

  prepare_columns = () => {
    let self = this;
    let columns = [
      {
        title: "Date",
        data: function (row) {
          return (
            <Link
              to={
                "/surfwize/reporting/users/" +
                self.props.params.user +
                "/timeline?date=" +
                row["day"] +
                "&filter_website=" +
                self.props.params.website
              }
            >
              {row["day"]}
            </Link>
          );
        },
        search: FlexibleTable.search__single_string_field("day"),
        sortable: FlexibleTable.sortable__single_string_field("day"),
        onclick: undefined,
      },
    ];
    if (this.state.filters.hits) {
      columns.push({
        title: "Hits",
        data: function (row) {
          return row["hits"];
        },
        sortable: FlexibleTable.sortable__single_numeric_field("hits"),
        onclick: undefined,
      });
    } else {
      columns.push(
        {
          title: "Upload",
          data: function (row) {
            return <AmountField amount={row["upload"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("upload"),
          onclick: undefined,
        },
        {
          title: "Download",
          data: function (row) {
            return <AmountField amount={row["download"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("download"),
          onclick: undefined,
        },
        {
          title: "Transfer",
          data: function (row) {
            return <AmountField amount={row["upload"] + row["download"]} />;
          },
          sortable: FlexibleTable.sortable__multi_int_field("upload", "download"),
          onclick: undefined,
        }
      );
    }
    return columns;
  };

  render__content = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <FlexibleTable
            columns={this.prepare_columns()}
            data={this.state.data}
            sort_column={0}
            sort_asc={true}
            loaded={this.state.loaded}
          />
        </div>
      </div>
    );
  };

  render_title = () => {
    return (
      <span>
        Website {this.props.params.website} Usage by {this.props.params.user}
      </span>
    );
  };

  render_TopRight = () => {
    return (
      <div>
        <QueryFilter filters={this.state.filters} />
      </div>
    );
  };

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-rocket" buttons={this.render_TopRight()} title={this.render_title()}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
