import React, { useCallback, useState } from "react";
import { Box, Modal, Password, useToast, useTranslation } from "@familyzone/component-library";
import Api from "../../../utils/Api";

interface SetPasswordModalProps {
  open: boolean;
  onClose: () => void;
  username: string;
}

const SetPasswordModal: React.FC<SetPasswordModalProps> = ({ open = false, onClose, username }: SetPasswordModalProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { successToast, errorToast } = useToast();
  const [saving, setSaving] = useState<boolean>(false);

  const titleText = t("Set Password");

  const handleClose = useCallback(() => {
    setPassword("");
    setConfirmPassword("");
    onClose();
  }, [onClose]);

  const submit = () => {
    setSaving(true);
    Api.put(
      `/config/ajax/users/${username}/legacyauthhash`,
      { password: password },
      () => {
        setSaving(false);
        successToast({
          title: t("Update successful"),
          description: t("The user's password has been updated"),
        });
        handleClose();
      },
      () => {
        setSaving(false);
        errorToast({
          title: t("Update failed"),
          description: t("The user's password could not be updated. Please try again in a few minutes."),
        });
      }
    );
  };

  const errorMessage = () => {
    if (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword) {
      return t("Passwords must match");
    }
    return "";
  };

  const submitLabel = () => {
    return saving ? "Saving" : "Save";
  };

  const isSubmitDisabled = () => {
    return saving || password === "" || confirmPassword === "" || password !== confirmPassword;
  };

  return (
    <Modal
      headerText={t(titleText)}
      size="md"
      isOpen={open}
      onClose={handleClose}
      primaryCTADisabled={isSubmitDisabled()}
      primaryCTALabel={submitLabel()}
      onPrimaryCTAClick={submit}
    >
      <Box my="sp16">
        <Password
          label="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Box>
      <Password
        label="Confirm password"
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
        errorMessage={errorMessage()}
      />
    </Modal>
  );
};

export default SetPasswordModal;
