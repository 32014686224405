import { Box, Flex, Tag, Td, Text, Tr } from "@familyzone/component-library";
import React, { FC, useEffect, useMemo, useState } from "react";
import StudentNamePill from "../../modules/StudentNamePill";
import { TableColumn } from "../../types/table";
import Api from "../../utils/Api";
import TableBasedPage from "../templates/TableBasedPage";
import CardSpinner from "./UserDashboard/CardSpinner";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import { Item } from "../../storez/MultiSelectStores";
import { uriSafeBase64Decode } from "../../utils/Base64Util";

export interface Props {
  params: {
    expression: string;
  };
}

interface DecodedProps {
  user: string;
  keywords?: Item[];
  categories?: Item[];
}

const IsDecodedProps = (props: unknown): props is DecodedProps => props !== null && typeof props === "object" && "user" in props;

export const TryDecodeProps = (expression: string): DecodedProps => {
  let decoded = "";
  let parsed: unknown;

  try {
    decoded = uriSafeBase64Decode(expression);
    parsed = JSON.parse(decoded);
  } catch (e) {
    if (e instanceof DOMException) {
      // Unable to decode expression, so we'll just pass it as a string of user
      parsed = { user: String(expression) };
    }

    if (e instanceof SyntaxError) {
      // Unable to JSON parse the decoded expression, so we'll just pass it as a string of user
      parsed = { user: String(decoded) };
    }
  }

  if (IsDecodedProps(parsed)) {
    return parsed;
  }

  // Invalid JSON format, so we'll just pass it as a string of user
  return { user: String(parsed) };
};

type UserSearchApiResponse = {
  result: {
    hits: number;
    searchWord: string;
  }[];
};

type tableData = {
  searchquery: string;
  hits: number;
};

const isUserSearchesResponse = (data: unknown): data is UserSearchApiResponse => {
  return data !== null && typeof data === "object" && "result" in data;
};

const ReportingSearchUsersNew: FC<Props> = (props) => {
  const filterStore = useDateRangeFilterStore();
  const { user, keywords, categories } = useMemo(() => TryDecodeProps(props.params.expression), [props.params.expression]);

  const [data, setData] = useState<tableData[]>();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);
  }, []);

  const columns: TableColumn[] = ["Search Query", "Hits"].map((item) => {
    return {
      headerText: item,
      columnName: item.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
      exportable: true,
    };
  });

  useEffect(() => {
    const userSearches = () => {
      setLoaded(false);
      const data = {
        ...(keywords && keywords.length > 0 && { keywords: keywords.map((item) => item.value) }),
        ...(categories && categories.length > 0 && { categories: categories.map((item) => item.value) }),
      };
      Api.post_analytics(
        `/sufwize/ajax/search/users/hits?filter_user=${user}&startDate=${filterStore.getStartDate()}&endDate=${filterStore.getEndDate()}&startTime=${filterStore.getStartTime()}&endTime=${filterStore.getEndTime()}`,
        data,
        (result: unknown) => {
          if (isUserSearchesResponse(result)) {
            const dataSet = result.result.map((item) => ({
              searchquery: item.searchWord,
              hits: item.hits,
            }));
            setData(dataSet);
          }
          setLoaded(true);
        },
        (_: unknown) => {
          setError(true);
        }
      );
    };

    userSearches();
  }, [user, filterStore, keywords, categories]);

  const tableRows = (tableData: tableData, index: number) => (
    <Tr key={index}>
      <Td>
        <Text>{tableData.searchquery}</Text>
      </Td>
      <Td>
        <Text>{tableData.hits}</Text>
      </Td>
    </Tr>
  );

  if (error)
    return (
      <Flex justifyContent="center" alignItems="center" flexGrow={1}>
        <Text>Sorry, something went wrong fetching data. Please try again soon.</Text>
      </Flex>
    );
  if (!loaded || !data) return <CardSpinner />;
  return (
    <TableBasedPage
      title="User Searches"
      children={
        <Box marginTop="24" marginLeft="24">
          <StudentNamePill username={user} />
          {keywords?.map((tag, i) => (
            <Box as="span" ml="sp4" key={i}>
              <Tag isRemovable={false} tagLabel={tag.text || tag.value} tagLeftIcon="fa fa-tag" variant="dark" data-testid="tagNamePill" />
            </Box>
          ))}
          {categories?.map((tag, i) => (
            <Box as="span" ml="sp4" key={i}>
              <Tag isRemovable={false} tagLabel={tag.text || tag.value} tagLeftIcon="fa fa-flag" variant="dark" data-testid="tagNamePill" />
            </Box>
          ))}
        </Box>
      }
      columns={columns}
      data={data}
      tableDataMap={tableRows}
      loaded={loaded}
    />
  );
};

export default ReportingSearchUsersNew;
