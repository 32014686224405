import { dom, library } from "@fortawesome/fontawesome-svg-core";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - This will fail to find corresponding type declarations unless compilerOptions.moduleResolution is set to "bundler" in tsconfig.json.
// But "bundler" was added in TypeScript 5 and we are still on TypeScript 4. TODO: Once updraded to TS5, set compilerOptions.moduleResolution to "bundler" and remove this comment.
import { all } from "@awesome.me/kit-b56161fd23/icons";

/**
 * Kicks off an initial replacement of all FA idiomatic text elements to SVGs, and configures a MutationObserver to replace any new elements added to the DOM.
 *
 * This function should be called once in the application's lifecycle.
 *
 * Example: <i class="fas fa-user" /> will be converted to <svg ... />
 */
export function replaceIdiomaticTextsWithSvgs(): void {
  // Add all icons to the library
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  library.add(all);

  // Replace all <i> elements with <svg> elements
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  dom.watch();
}
