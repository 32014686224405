import React, { FC } from "react";
import { ActiveConnectionsNew } from "./ActiveConnectionsNew";
import { LegacyActiveConnectionsProps } from "./ActiveConnectionsTypes";

export const ActiveConnections: FC<LegacyActiveConnectionsProps> = (props) => {
  const newUIProps = {
    user: props.location.query.user,
    mac: props.location.query.mac,
    ip: props.location.query.ip,
  };
  return <ActiveConnectionsNew {...newUIProps} />;
};
