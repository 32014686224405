import React from "react";
import Api from "../../utils/Api";
import ScrollingModal from "../../modules/ScrollingModal";

export default class RestartDevice extends React.Component {
  handleHide = () => {
    this.props.handleClose();
  };

  handle_Toggle = () => {
    const self = this;
    Api.get(
      "/managedevice/restart",
      function () {
        self.props.handleClose();
      },
      function () {
        self.props.handleClose();
      }
    );
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handleHide}>
        Close
      </button>
    );
  };

  render() {
    return (
      <ScrollingModal show={this.props.visible} title="Restart Appliance" handleHide={this.handleHide} buttons={this.renderActions()}>
        Manually trigger an appliance restart.
        <br />
        <br />
        <button className="mui-btn mui-btn--primary modal-button" onClick={this.handle_Toggle}>
          Restart Now
        </button>
      </ScrollingModal>
    );
  }
}
