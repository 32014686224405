import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Box, MultiHierarchySelector, useTranslation, Text, Icon, Image, useToast, Flex } from "@familyzone/component-library";
import {
  Keyword,
  Signature,
  AllHTTPOption,
  AllOptions,
  AllTrafficOption,
  BaseCriteria,
  HTTPDirectIpOption,
  KeywordObjectOption,
  KeywordOption,
  SignatureOption,
  UnclassifiedTrafficOption,
  WebsiteObjectOption,
  WebsiteOption,
} from "./types";
import { WebsiteObject, KeywordObject } from "../../modules/criteria/criteriaTypes";
import { queryDomainSignature } from "./ApiHelpers";
import { getWebsiteValue } from "./SignatureSelectorHelpers";

export const DefaultIcon: React.FC = ({ ...props }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.875 5.25C6.875 5.69922 6.83594 6.10938 6.79688 6.5H3.18359C3.14453 6.10938 3.10547 5.69922 3.10547 5.25C3.10547 4.82031 3.14453 4.41016 3.18359 4H6.79688C6.83594 4.41016 6.875 4.82031 6.875 5.25ZM9.82422 4C9.94141 4.41016 10 4.82031 10 5.25C10 5.69922 9.94141 6.10938 9.82422 6.5H7.42188C7.46094 6.10938 7.5 5.67969 7.5 5.25C7.5 4.82031 7.46094 4.41016 7.42188 4H9.82422ZM9.62891 3.375H7.34375C7.14844 2.14453 6.75781 1.08984 6.26953 0.425781C7.79297 0.835938 9.04297 1.92969 9.62891 3.375ZM6.71875 3.375H3.26172C3.37891 2.67188 3.57422 2.04688 3.78906 1.53906C4.00391 1.07031 4.21875 0.738281 4.45312 0.523438C4.66797 0.328125 4.84375 0.25 5 0.25C5.13672 0.25 5.3125 0.328125 5.52734 0.523438C5.76172 0.738281 5.97656 1.07031 6.19141 1.53906C6.40625 2.04688 6.60156 2.67188 6.71875 3.375ZM0.351562 3.375C0.9375 1.92969 2.1875 0.835938 3.71094 0.425781C3.22266 1.08984 2.83203 2.14453 2.63672 3.375H0.351562ZM2.55859 4C2.51953 4.41016 2.48047 4.82031 2.48047 5.25C2.48047 5.67969 2.51953 6.10938 2.55859 6.5H0.15625C0.0390625 6.10938 0 5.69922 0 5.25C0 4.82031 0.0390625 4.41016 0.15625 4H2.55859ZM3.78906 8.98047C3.57422 8.47266 3.37891 7.84766 3.26172 7.125H6.71875C6.60156 7.84766 6.40625 8.47266 6.19141 8.98047C5.97656 9.44922 5.76172 9.78125 5.52734 9.99609C5.3125 10.1914 5.13672 10.25 4.98047 10.25C4.84375 10.25 4.66797 10.1914 4.45312 9.99609C4.21875 9.78125 4.00391 9.44922 3.78906 8.98047ZM3.71094 10.0938C2.1875 9.68359 0.9375 8.58984 0.351562 7.125H2.63672C2.83203 8.375 3.22266 9.42969 3.71094 10.0938ZM6.26953 10.0938C6.75781 9.42969 7.14844 8.375 7.34375 7.125H9.62891C9.04297 8.58984 7.79297 9.68359 6.26953 10.0938Z"
        fill={"#42526E"}
      />
    </svg>
  );
};

const SearchIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
      />
    </svg>
  );
};

export const convertKeywordObject = (obj: KeywordObject, t: (str: string) => string): KeywordObjectOption => ({
  label: t(`Custom Search List - ${obj.name}`),
  icon: <SearchIcon />,
  value: obj.id,
  type: "search.keywords",
  conditions: { alias: obj.id },
});

export const convertWebsiteObject = (obj: WebsiteObject, t: (str: string) => string): WebsiteObjectOption => ({
  label: t(`Custom Website List - ${obj.name}`),
  icon: <DefaultIcon />,
  value: obj.id,
  type: "application.http.hostname",
  conditions: [obj.id],
});

export const convertKeyword = (keyword: Keyword, t: (str: string) => string): KeywordOption => ({
  label: t(`Searches for ${keyword.name}`),
  icon: <SearchIcon />,
  value: keyword.id,
  type: "search.keywords",
  conditions: { signature: keyword.id },
});

export const convertWebsite = (website: string): WebsiteOption => ({
  label: website,
  value: website,
  type: "application.http.hostname",
  conditions: { source_entries: [website] },
});

export const convertSignature = (signature: Signature, t: (str: string) => string): SignatureOption => ({
  category: signature.category,
  label: t(signature.name),
  icon: signature.favicon_url,
  value: signature.id,
  type: "signature",
  conditions: [signature.id],
});

export const convertVirtualSignature = (
  virtualSignature: BaseCriteria,
  t: (str: string) => string
): AllHTTPOption | HTTPDirectIpOption | AllTrafficOption | UnclassifiedTrafficOption => {
  switch (virtualSignature.type) {
    case "application.http":
      return {
        label: t("All HTTP/HTTPS Traffic"),
        icon: <DefaultIcon />,
        description: "Warning: This will apply to all internet traffic",
        type: "application.http",
        value: "application.http",
        conditions: {},
      };
    case "application.http.directip":
      return {
        label: t("HTTP Traffic via DirectIP"),
        icon: <DefaultIcon />,
        description: "All DirectIP HTTP traffic",
        type: "application.http.directip",
        value: "application.http.directip",
        conditions: {},
      };
    case "application":
      return {
        label: t("All Traffic"),
        icon: <DefaultIcon />,
        description: "Warning: This will apply to all internet traffic",
        type: "application",
        value: "application",
        conditions: {},
      };
    case "application.http.unclassified":
      return {
        label: t("Unclassified Traffic"),
        icon: <DefaultIcon />,
        description: "Warning: All unclassified HTTP/HTTPS Traffic",
        type: "application.http.unclassified",
        value: "application.http.unclassified",
        conditions: {},
      };
    default:
      return {
        label: t("Unknown Criteria"),
        icon: <DefaultIcon />,
        description: "Invalid Criteria",
        type: "application",
        value: "application",
        conditions: {},
      };
  }
};

export const virtualSignatures: ("application.http" | "application.http.directip" | "application" | "application.http.unclassified")[] = [
  "application.http",
  "application.http.directip",
  "application",
  "application.http.unclassified",
];

const convertOption = (option: AllOptions): BaseCriteria => {
  return {
    conditions: option.conditions,
    type: option.type,
  };
};

export const convertCriteria = ({
  criteria,
  keywordObjects,
  keywords,
  signatures,
  websiteObjects,
  t,
}: {
  t: (s: string) => string;
  criteria: BaseCriteria;
  keywordObjects: KeywordObject[];
  keywords: Keyword[];
  signatures: Signature[];
  websiteObjects: WebsiteObject[];
}): AllOptions | null => {
  switch (criteria.type) {
    case "search.keywords":
      if ("alias" in criteria.conditions && criteria.conditions.alias !== undefined) {
        const keywordObject = keywordObjects.find(
          (k) => !Array.isArray(criteria.conditions) && "alias" in criteria.conditions && k.id === criteria.conditions.alias
        );
        if (!keywordObject) return null;
        return convertKeywordObject(keywordObject, t);
      }
      if ("signature" in criteria.conditions && criteria.conditions.signature !== undefined) {
        const keyword = keywords.find(
          (k) => !Array.isArray(criteria.conditions) && "signature" in criteria.conditions && k.id === criteria.conditions.signature
        );
        if (!keyword) return null;
        return convertKeyword(keyword, t);
      }
      return null;
    case "signature":
      const signature = signatures.find((s) => Array.isArray(criteria.conditions) && s.id === criteria.conditions[0]);
      if (!signature) return null;
      return convertSignature(signature, t);
    case "application.http.hostname":
      if (Array.isArray(criteria.conditions) && criteria.conditions[0]) {
        const websiteObject = websiteObjects.find((w) => Array.isArray(criteria.conditions) && w.id === criteria.conditions[0]);
        if (!websiteObject) return null;
        return convertWebsiteObject(websiteObject, t);
      }
      if (
        !Array.isArray(criteria.conditions) &&
        criteria.conditions?.source_entries?.[0] &&
        typeof criteria.conditions.source_entries[0] === "string"
      ) {
        return {
          ...criteria,
          ...convertWebsite(criteria.conditions.source_entries[0]),
        };
      }
      return null;
    default:
      return convertVirtualSignature(criteria, t);
  }
};

export const maxSignaturesAllowed = 300;

export const placeholderText = "Search for one or more website/signature";
export const websiteText = "Website ";
export const maxSignaturesText = `You may only have a maximum of ${maxSignaturesAllowed} selections at once.`;
export const maxSignaturesSecondaryText = "Please use an object list if more selections are needed.";

export interface SignatureSelectorProps {
  signatures: Signature[];
  keywords: Keyword[];
  keywordObjects: KeywordObject[];
  websiteObjects: WebsiteObject[];
  criteria: BaseCriteria[];
  disabled?: boolean;
  onChange: (selected: BaseCriteria[]) => void;
  highlightedCriteria?: number[];
}

const SignatureSelector: React.FC<SignatureSelectorProps> = ({
  signatures,
  keywords,
  keywordObjects,
  websiteObjects,
  criteria,
  disabled,
  onChange,
  highlightedCriteria,
}) => {
  const sortedSignatures = signatures.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
  );

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [clearInput, setClearInput] = useState(false);
  const [suggestedSignature, setSuggestedSignature] = useState<Signature | null>(null);
  const { errorToast } = useToast();

  const websiteValue = useMemo(() => getWebsiteValue(inputValue), [inputValue]);

  const websiteExists = criteria.some(
    (c) => c.type === "application.http.hostname" && "source_entries" in c.conditions && c.conditions.source_entries[0] === websiteValue
  );

  const selected: AllOptions[] = useMemo(
    () =>
      criteria
        .map((c) =>
          convertCriteria({
            criteria: c,
            keywordObjects,
            keywords,
            signatures,
            websiteObjects,
            t,
          })
        )
        .filter((c): c is AllOptions => !!c)
        .map((c, i) => ({ ...c, color: highlightedCriteria?.includes(i) ? "accent.red.300" : undefined })),
    [criteria, keywords, keywordObjects, signatures, websiteObjects, t, highlightedCriteria]
  );

  const signaturesExceedsLimit = selected.length >= maxSignaturesAllowed;

  const options: AllOptions[] = useMemo(
    () =>
      [
        ...sortedSignatures
          .filter(
            (s) => !s.hidden && s.enabled && s.id !== "sphirewall.application.alwaysdeny" && s.id !== "sphirewall.application.alwaysallow"
          )
          .map((s) => convertSignature(s, t)),
        ...keywords.filter((k) => !k.deprecated).map((s) => convertKeyword(s, t)),
        ...keywordObjects.map((s) => convertKeywordObject(s, t)),
        ...websiteObjects.map((s) => convertWebsiteObject(s, t)),
        ...virtualSignatures.map((s) =>
          convertVirtualSignature(
            {
              conditions: {},
              type: s,
            },
            t
          )
        ),
      ].map((o) => (signaturesExceedsLimit ? { ...o, disabled: true } : o)),
    [sortedSignatures, keywords, keywordObjects, websiteObjects, signaturesExceedsLimit, t]
  );

  const suggestedSignatureExists = useMemo(
    () => !!suggestedSignature && selected.some((s) => s.value === suggestedSignature.id),
    [suggestedSignature, selected]
  );

  const handleAddSuggestedSignature = () => {
    if (!suggestedSignature) return;
    if (selected.some((s) => s.value === suggestedSignature.id)) return;
    const option = options.find((o) => o.value === suggestedSignature.id);
    if (!option) return;
    onChange([...selected.map(convertOption), convertOption(option)]);
    setClearInput(true);
  };

  const populateSignatureSuggestion = useCallback(async () => {
    try {
      if (!websiteValue) return;
      let website = websiteValue;
      website = website.replace("https://", "");
      const data = await queryDomainSignature(website.split("/")[0]);
      if ("signature" in data) {
        const foundSignature = signatures.find((s) => s.id === data.signature);
        if (!foundSignature || foundSignature.hidden || !foundSignature.enabled) {
          setSuggestedSignature(null);
          return;
        }
        // Found a signature to suggest
        setSuggestedSignature(foundSignature);
        return;
      }
      setSuggestedSignature(null);
    } catch (err) {
      console.error(err);
      errorToast({
        title: "Error loading data",
        description: "Encountered an error while loading Suggestion data",
      });
    }
  }, [websiteValue, signatures, errorToast]);

  useEffect(() => {
    populateSignatureSuggestion().catch(() => "");
  }, [populateSignatureSuggestion]);

  const handleChangeSearch = (search: string) => {
    setInputValue(search);
  };

  const handleChangeSelected = (selected: AllOptions[]) => {
    onChange(selected.map(convertOption));
  };

  const handleAddWebsite = () => {
    if (websiteExists) return;
    onChange([
      ...selected.map(convertOption),
      {
        conditions: { source_entries: [websiteValue] },
        type: "application.http.hostname",
      },
    ]);
    setClearInput(true);
  };

  useEffect(() => {
    if (!clearInput) return;
    setClearInput(false);
  }, [clearInput]);

  return (
    <MultiHierarchySelector
      options={options}
      selected={selected}
      onChange={handleChangeSelected}
      placeholder={t(placeholderText)}
      onSearch={handleChangeSearch}
      searchDelay={200}
      disableNoContentText
      disableCategoryOptions
      disabled={disabled}
      pageIncrement={10}
      pillWidth={"250px"}
      startingPageCount={15}
      onlyExactSiblingsInSearch
      clearInputOnSelect
      clearInput={clearInput}
      startContent={
        inputValue && !signaturesExceedsLimit ? (
          <Box padding="sp2" width="100%">
            <Box
              padding="sp8"
              borderRadius="4px"
              width="100%"
              cursor={!websiteExists ? "pointer" : undefined}
              display="flex"
              alignItems="center"
              backgroundColor={websiteExists ? "brand.200" : undefined}
              _hover={{
                backgroundColor: !websiteExists ? "brand.100" : undefined,
              }}
              onClick={handleAddWebsite}
            >
              <Box ml="sp12" mr="sp8" />
              <Box ml="sp2">
                <DefaultIcon />
              </Box>
              <Text ml="sp8">
                {t(websiteText)}
                <Text display="inline" fontWeight="bold">
                  {websiteValue}
                </Text>
              </Text>
            </Box>
            {suggestedSignature && (
              <Box
                padding="sp8"
                borderRadius="4px"
                width="100%"
                cursor={!suggestedSignatureExists ? "pointer" : undefined}
                display="flex"
                alignItems="center"
                backgroundColor={suggestedSignatureExists ? "brand.200" : undefined}
                _hover={{
                  backgroundColor: !suggestedSignatureExists ? "brand.100" : undefined,
                }}
                onClick={handleAddSuggestedSignature}
              >
                <Box ml="sp24" mr="sp8" display="flex">
                  <Icon icon="fa-arrow-turn-down-right" />
                </Box>
                <Box ml="sp2" width="16px">
                  {suggestedSignature.favicon_url ? <Image src={suggestedSignature.favicon_url} /> : <DefaultIcon />}
                </Box>
                <Text ml="sp8">{t(suggestedSignature.name)}</Text>
                <Text ml="sp8" display="inline" fontStyle="italic" color="neutrals.500">
                  {t("Contains this site")}
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {signaturesExceedsLimit && (
              <Flex padding="sp2" mb="sp8" mt="sp4" width="100%" justifyContent="center" direction="column">
                <Text textAlign="center" color="accent.red.400">
                  {t(maxSignaturesText)}
                </Text>
                <Text textAlign="center" color="accent.red.400">
                  {t(maxSignaturesSecondaryText)}
                </Text>
              </Flex>
            )}
          </>
        )
      }
    />
  );
};

export default SignatureSelector;
