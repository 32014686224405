import React from "react";

import "../../css/ToggleSwitch.css";

export default class ToggleSwitch extends React.Component {
  render() {
    return (
      <label className="switch">
        <input type="checkbox" className="switch-input" checked={this.props.enabled} onChange={this.onChange.bind(this)} />
        <span className="switch-panel" />
        <span className="switch-handle" />
      </label>
    );
  }

  onChange() {
    this.props.onChange();
  }
}
