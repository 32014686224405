import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Flex, Icon, InlineNotification, Modal, ModalBody, Tag, TextInput } from "@familyzone/component-library";
import Api from "../../utils/Api";
import { isValidEmail } from "../../utils/Validation";

export const EditPermissionNew: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [newRecipient, setNewRecipient] = useState("");
  const [invalidEmailReason, setInvalidEmailReason] = useState<undefined | string>(undefined);
  const [toBeAddedRecipients, setToBeAddedRecipients] = useState<string[]>([]);
  const [toBeRemovedRecipients, setToBeRemovedRecipients] = useState<string[]>([]);

  const handleOnOpen = () => {
    setNewRecipient("");
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const result = [...recipients, ...toBeAddedRecipients].filter((i) => !toBeRemovedRecipients.includes(i));
    Api.post("/managedevice/ajax/device/alerts/recipients", { recipients: result }, () => {
      handleOnClose();
    });
  };

  const handleLoad = () => {
    setToBeAddedRecipients([]);
    setToBeRemovedRecipients([]);
    Api.get("/managedevice/ajax/device/alerts/recipients", ({ data }: { data: string[] }) => {
      setRecipients(data.filter((i) => !!i));
    });
  };

  const handleAddRecipient = (email: string) => {
    const index = toBeRemovedRecipients.indexOf(email);
    if (index > -1) {
      toBeRemovedRecipients.splice(index, 1);
      setToBeRemovedRecipients([...toBeRemovedRecipients]);
    } else if (!recipients.includes(email) && !toBeAddedRecipients.includes(email)) setToBeAddedRecipients([...toBeAddedRecipients, email]);

    setNewRecipient("");
  };

  const handleRemoveRecipient = (email: string) => {
    const index = toBeAddedRecipients.indexOf(email);
    if (index > -1) {
      toBeAddedRecipients.splice(index, 1);
      setToBeAddedRecipients([...toBeAddedRecipients]);
    } else if (!toBeRemovedRecipients.includes(email)) setToBeRemovedRecipients([...toBeRemovedRecipients, email]);
  };

  const handleChangeRecipient = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmail(e.target.value);
    setNewRecipient(e.target.value);
  };

  const validateEmail = (email: string) => {
    let emailError = undefined;
    if (email.length === 0) emailError = "";
    // Closest possible email regex that exists, from https://emailregex.com/
    else if (!isValidEmail(email)) {
      emailError = "Invalid email";
    }
    setInvalidEmailReason(emailError);
  };

  useEffect(() => {
    if (open) {
      handleLoad();
    }
  }, [open]);

  return (
    <>
      <Button onClick={handleOnOpen} aria-label="Manage Alert Recipients" variant="primary">
        Manage Alert Recipients
      </Button>
      <Modal
        isOpen={open}
        size="md"
        onClose={handleOnClose}
        headerText="Manage Alert Recipients"
        primaryCTALabel="Save Details"
        onPrimaryCTAClick={handleSave}
        primaryCTADisabled={toBeAddedRecipients.length === 0 && toBeRemovedRecipients.length === 0}
      >
        <ModalBody>
          <Flex mb="sp4" flexDirection="row" alignItems="center">
            <Flex flexDirection="column" mr="sp12" width="100%" minHeight="81px">
              <TextInput
                placeholder="Enter recipient’s email"
                label="Add recipient"
                value={newRecipient}
                onChange={handleChangeRecipient}
                errorMessage={invalidEmailReason}
              />
            </Flex>
            <Flex flexDirection="column">
              <Button
                onClick={() => handleAddRecipient(newRecipient)}
                aria-label="Add Recipient"
                variant="primary"
                leftIcon={<Icon boxSize="12px" icon="fa fa-plus" />}
                disabled={!!invalidEmailReason || !newRecipient}
              >
                Add Recipient
              </Button>
            </Flex>
          </Flex>
          <Flex flexDirection="row" flexWrap="wrap">
            {recipients
              .filter((r) => !toBeRemovedRecipients.includes(r))
              .concat(toBeAddedRecipients)
              .map((r) => (
                <Box mr="sp8" mt="sp8" key={r}>
                  <Tag variant="dark" isRemovable tagLabel={r} onRemoveClick={() => handleRemoveRecipient(r)} />
                </Box>
              ))}
            {toBeAddedRecipients.map((r) => (
              <Flex mt="sp8" width="100%" key={`notif-${r}`}>
                <InlineNotification notificationTitle="Add recipients:" notificationDescription={r} status="success" />
              </Flex>
            ))}
            {toBeRemovedRecipients.map((r) => (
              <Flex mt="sp8" width="100%" key={`notif-${r}`}>
                <InlineNotification notificationTitle="Remove recipients:" notificationDescription={r} status="warning" />
              </Flex>
            ))}
          </Flex>
        </ModalBody>
      </Modal>
    </>
  );
};
