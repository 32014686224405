import { Stack } from "@chakra-ui/layout";
import { Modal, useTranslation, Text, Input, Button, Flex, useToast } from "@familyzone/component-library";
import PropTypes from "prop-types";
import React from "react";
import { createGroup } from "../../../utils/api/Groups";

const GroupsAddModal: React.FC = (_, context) => {
  const [groupName, setGroupName] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();
  const { errorToast } = useToast();

  const handleAdd = () => {
    if (groupName.trim() === "") return;
    void createGroup(groupName)
      .then((groupId) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        context.router.push("/config/device/userdb/groups/" + groupId);
      })
      .catch(() => {
        errorToast({ title: "Error", description: "Failed to create group, please try again later." });
      });
  };

  return (
    <>
      <Button variant="primary" onClick={() => setOpen(true)}>
        Add Group
      </Button>
      <Modal
        headerText={t("Add Group")}
        size="md"
        isOpen={open}
        onClose={() => setOpen(false)}
        contentProps={{ style: { overflow: "visible" } }}
      >
        <Stack spacing="sp12" width="100%" my="sp12">
          <Text>Group Name</Text>
          <Input data-testid="groupName-input" onChange={(e) => setGroupName(e.target.value)}></Input>
        </Stack>
        <Flex>
          <Button
            data-testid="addButton"
            disabled={!groupName || groupName.trim() === ""}
            maxWidth="100%"
            mt="sp12"
            variant="primary"
            onClick={handleAdd}
          >
            Add
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default GroupsAddModal;

GroupsAddModal.contextTypes = {
  router: PropTypes.object.isRequired,
};
