export interface TimePeriod {
  id?: string;
  name?: string;
}

export interface ObjectPool {
  desc: string;
  displayType?: string;
  entries?: string[];
  id: string | undefined;
  name: string;
  named_entries?: NamedEntry[];
  type: number;
}

export interface NamedEntry {
  click_action: string;
  desc: string;
  invalidValueMsg: string | null;
  name: string;
  value: string;
  id: string | number | undefined;
}

export const displayTypesArray = [
  [0, "IP Range List"],
  [1, "IP Subnet List"],
  [2, "Website List"],
  [4, "MAC Address List"],
  [6, "Search Keywords"],
  [7, "Domains List"],
];
