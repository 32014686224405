import { UserUMS } from "../../types/Users";
import { getUserByUsername } from "../../utils/api/Users";
import { fetchSubnetGroupsForSelectDropdownAsync, SubnetGroup } from "./CloudSafeHelper";

export interface PolicyBinding {
  enabled: boolean;
  id: string;
  objectLists: string[];
  user: string;
  objectListNames: string[];
}

export const isResponse = (response: unknown): response is { data: unknown } =>
  typeof response === "object" && response !== null && "data" in response;

export const isUnknownArray = (array: unknown): array is unknown[] => Array.isArray(array);

export const isPolicyBindings = (items: unknown[]): items is PolicyBinding[] => {
  if (items.length === 0) return true;
  return items.some((item) => isPolicyBinding(item));
};

const isPolicyBinding = (item: unknown): item is PolicyBinding =>
  typeof item === "object" &&
  item !== null &&
  "enabled" in item &&
  "id" in item &&
  "objectLists" in item &&
  "user" in item &&
  "objectListNames" in item;

export const tryGetUsersByUsername: (usernames: string[], onError: (e: unknown) => void) => Promise<UserUMS[]> = async (
  usernames,
  onError
) => {
  const result: UserUMS[] = [];
  for (const username of usernames) {
    try {
      const user = await getUserByUsername(username);
      if (user) result.push(user);
    } catch (err) {
      onError(err);
      break;
    }
  }
  return result;
};

export const tryFetchSubnetGroups: (onError: (e: unknown) => void) => Promise<SubnetGroup[]> = async (onError) => {
  let result: SubnetGroup[] = [];
  try {
    result = await fetchSubnetGroupsForSelectDropdownAsync();
  } catch (err) {
    onError(err);
  }
  return result;
};
