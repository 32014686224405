import { Box, InfiniteScroll, SearchBox, Text } from "@familyzone/component-library";
import moment from "moment";
import React from "react";
import Api from "../../../utils/Api";
import CardSpinner from "./CardSpinner";
import { eventStringFormatter, groupByTime, searchFilter } from "./EventTimelineCardHelpers";
import { ApiDates } from "../../../utils/DateTimeUtil";

interface IEventTimelineCard {
  user: string;
  dateFilters: ApiDates;
}

export type TimelineEvent = {
  day: string;
  deviceid: string;
  key: string;
  time: string;
  user: string;
  chrome_id?: string;
  idle_timeout?: number;
  ip?: string;
  macAddress?: string;
  "policy:name"?: string;
  provider?: string;
  red_flag?: string;
  red_flag_name?: string;
  referrer?: string;
  host?: string;
};

type EventApiResponse = {
  data: TimelineEvent[];
};

const EventTimelineCard = ({ user, dateFilters }: IEventTimelineCard): JSX.Element => {
  const [events, setEvents] = React.useState<TimelineEvent[]>([]);
  const [apiResponseEvents, setApiResponseEvents] = React.useState<TimelineEvent[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredEvents, setFilteredEvents] = React.useState<TimelineEvent[]>([]);
  const [errorState, setErrorState] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [shownRowCount, setShownRowCount] = React.useState(15);

  React.useEffect(() => {
    getEvents(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dateFilters]);

  React.useEffect(() => {
    if (events && searchTerm) {
      const filtered = searchFilter(events, searchTerm);
      setFilteredEvents(filtered);
    }
    if (!searchTerm) setApiResponseEvents(apiResponseEvents); // If search is cleared, then restore original API events.
  }, [apiResponseEvents, events, searchTerm]);

  const getEvents = (user: string) => {
    setLoading(true);
    Api.get_analytics(
      `/config/ajax/users/${user}/audit`,
      (response: EventApiResponse) => {
        const eventsToSet: TimelineEvent[] = [];
        const desired_events = {
          "event.userdb.auth.success": "Log in Login",
          "event.userdb.session.timeout": "",
          "event.firewall.webfilter.hit": "Blocked",
        };
        for (const event of response.data) {
          if (event.key in desired_events) {
            eventsToSet.push(event);
          }
        }
        setEvents(eventsToSet);
        setApiResponseEvents(eventsToSet);
        setLoading(false);
      },
      (_: unknown) => {
        setErrorState(true);
        setLoading(false);
      },
      { dateOverride: dateFilters }
    );
  };

  const renderEvents = (events: TimelineEvent[]) => {
    if (!events) {
      return (
        <Box paddingX="sp16" paddingY="sp24">
          <Text>There are no events for this user</Text>
        </Box>
      );
    }

    return (
      <InfiniteScroll
        fetchData={() => setShownRowCount(shownRowCount + 10)}
        hasMore={events.length >= shownRowCount}
        element="div"
        parentElemId="ComponentWrapper"
      >
        {groupByTime(events.slice(0, shownRowCount)).map((event) => (
          <Box key={event.key}>
            <ul>
              <li key={event.key}>
                <Text fontSize="md" color="text.title" mb="sp4" pb="md">
                  {moment(event.key, "YYYY-MM-DD HH:mm").fromNow()}
                </Text>
                {event.values.map((event, index) => (
                  <Box
                    key={index}
                    background={index % 2 === 0 ? "neutrals.20" : "neutrals.0"}
                    border="solid 0.5px"
                    borderColor="neutrals.30"
                    borderRadius="sm"
                    marginY="sp8"
                    paddingY="sp8"
                    paddingX="sp12"
                    data-testid="eventListItem"
                  >
                    <Text>{eventStringFormatter(event)}</Text>
                  </Box>
                ))}
              </li>
            </ul>
          </Box>
        ))}
      </InfiniteScroll>
    );
  };

  return (
    <Box background="neutrals.0" paddingX="sp16" paddingY="sp24" className="timeline-ui" borderRadius="sm">
      <Text fontFamily="heading" fontSize="xxl" color="text.title" mb="xl" pb="md">
        Event Timeline
      </Text>
      <Box marginY="sp12" width="100%">
        <SearchBox
          data-testid="search-filter"
          placeholder="Search"
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm("")}
        />
      </Box>
      {/* Is there an error? Show an error message. If not, check if loading. If loading show the spinner, otherwise show the events */}
      {errorState ? (
        <Text>There's a problem loading the Event Timeline right now. Please try again later.</Text>
      ) : loading ? (
        <CardSpinner />
      ) : searchTerm ? (
        renderEvents(filteredEvents)
      ) : (
        renderEvents(events)
      )}
    </Box>
  );
};

export default EventTimelineCard;
