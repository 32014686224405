import {
  Box,
  Flex,
  Icon,
  InlineNotification,
  TableIconButton,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import React from "react";
import { DeleteButtonNew } from "../../../modules/DeleteButtonNew";
import { GroupUMS } from "../../../types/Groups";
import { deleteGroup, getGroups } from "../../../utils/api/Groups";
import Link from "../../Link";
import TableBasedPage from "../../templates/TableBasedPage";
import GroupsAddModal from "./GroupsAddModal";
import GroupsPurgeModal from "./GroupsPurgeModal";
import ArchivedStatusPill from "../ArchivedStatusPill";
import GroupSearchBox from "./GroupsSearchBox";

const GroupsTable: React.FC = () => {
  const { t } = useTranslation();
  const [tableResults, setTableResults] = React.useState<{ groups: GroupUMS[] }>({ groups: [] });
  const [loading, setLoading] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true); // This is to determine if the user has searched or not.
  const { successToast, errorToast } = useToast();

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Users and Groups"), url: "/config/device/userdb", isActive: false },
    { title: t("Groups"), url: "/config/device/userdb/groups", isActive: true },
  ];

  const columns = [
    { headerText: t("Provider Name"), columnName: "name", sortable: true, searchable: true },
    { headerText: t("Provider Description"), columnName: "description", sortable: true, searchable: true },
    { headerText: t("Provider DN"), columnName: "distinguishedName", sortable: true, searchable: true },
    { headerText: t("Provider"), columnName: "sourceType", sortable: true, searchable: true },
    { headerText: t("Status"), columnName: "archived", sortable: true, searchable: false },
    { headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false },
  ];

  React.useMemo(() => {
    void (async () => {
      const groups = await getGroups();
      setTableResults(groups);
      setLoading(false);
    })();
  }, []);

  const handleDeleteGroup = async (id: string) => {
    try {
      await deleteGroup(id);
      setLoading(true);
      successToast({ title: "Success!", description: "We've received the request and it has been sent for processing." });
      const groups = await getGroups();
      setTableResults(groups);
      setLoading(false);
    } catch (error) {
      errorToast({ title: "Error", description: "We're not able to process the request at this time. Please try again later." });
    }
  };

  const operations = (id: string, isLocal: boolean): JSX.Element => {
    // Local groups can be edited and deleted. Synced groups can only be viewed.
    if (isLocal) {
      return (
        <Flex>
          <Box mr="sp8">
            <Link to={`/config/device/userdb/groups/${id}`}>
              <TableIconButton aria-label="Edit" icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />} />
            </Link>
          </Box>
          <Box mr="sp8">
            <DeleteButtonNew
              onClick={() =>
                void (async () => {
                  await handleDeleteGroup(id);
                })()
              }
              titleToDelete="Group"
              bodyToDelete="group"
              actionVerb="Archive"
            />
          </Box>
        </Flex>
      );
    } else {
      return (
        <Link to={`/config/device/userdb/groups/${id}`}>
          <TableIconButton aria-label="View" icon={<Icon icon="fa-eye" variant="solid" color="text.paragraph.light" />} />
        </Link>
      );
    }
  };

  const tableDataMap = (group: GroupUMS, index: number): JSX.Element => {
    return (
      <Tr key={index}>
        <Td data-testid="group-groupname">
          <Link to={`/config/device/userdb/groups/${group.id}`}>
            <Text fontSize={"sm"}>{group.name}</Text>
          </Link>
        </Td>
        <Td data-testid="group-description">
          <Text>{group.description}</Text>
        </Td>
        <Td data-testid="group-distinguishedName">
          <Text>{group.distinguishedName}</Text>
        </Td>
        <Td data-testid="group-sourceType">
          <Text>{group.sourceType}</Text>
        </Td>
        <Td data-testid="group-status">
          <ArchivedStatusPill archived={group.archived} />
        </Td>
        <Td>{operations(group.id, group.sourceType === "LOCAL")}</Td>
      </Tr>
    );
  };

  const aboveTableChildren = (): JSX.Element => {
    return (
      <Flex mt="sp24" mr="sp24" justifyContent="end">
        <GroupsAddModal />
        <Box ml="sp8" />
        <GroupsPurgeModal />
      </Flex>
    );
  };

  const helperNotification = (): JSX.Element => {
    return (
      <Box ml="sp12" minW="300px" maxH="sp12">
        <InlineNotification
          notificationDescription="Please use search to show different results."
          notificationTitle={`Showing you ${tableResults.groups.length} group(s)`}
          status="info"
        />
      </Box>
    );
  };

  const tableChildren = (): JSX.Element => {
    return (
      <Flex>
        <GroupSearchBox
          searchEnabled={!loading}
          onSearchStart={() => {
            setFirstLoad(false);
            setLoading(true);
          }}
          onSearchEnd={(groups: GroupUMS[]) => {
            setTableResults({ groups });
            setLoading(false);
          }}
        />
        {!(loading || !firstLoad) && helperNotification()}
      </Flex>
    );
  };

  return (
    <TableBasedPage
      title="Groups"
      loaded={!loading}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={tableResults.groups}
      tableDataMap={tableDataMap}
      children={aboveTableChildren()}
      childrenInTableHeader={tableChildren()}
      showSearch={false}
    />
  );
};

export default GroupsTable;
