import alt from "../alt";

class ObjectActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }

  invalidateAndFetch() {
    return "nothing";
  }

  deleteObject(object_id) {
    return object_id;
  }

  addObject(entity) {
    return entity;
  }

  saveObject(entity) {
    return entity;
  }
}

export default alt.createActions(ObjectActions);
