import { UserStoreUser } from "../../storez/UserStore";
import { GroupOwners } from "../../types/Groups";
import { UserUMS, UserOption } from "../../types/Users";
import { searchUsers } from "../../utils/api/Users";

export const search = async (searchTerm: string): Promise<UserUMS[]> => {
  return searchUsers(searchTerm);
};

export const formatUser = (user: { firstName: string; lastName: string; email?: string }): string => {
  const fullName = user.firstName + " " + user.lastName;
  const validFullname = fullName.trim() !== "";
  const displayName = validFullname ? fullName : "No name provided";
  if (!user.email) {
    return displayName;
  } else return displayName + " - " + user.email;
};

export const fullNameOrUsername = (user: UserStoreUser): string => {
  const name = getFullName(user);
  return name ? name : user.username;
};

export const getFullName = (user: UserStoreUser): string | undefined => {
  const fullName = ((user.firstName || "") + " " + (user.lastName || "")).trim();
  return fullName !== "" ? fullName : undefined;
};

export const fullNameAndUsername = (username: string, displayName?: string): string => {
  if (!displayName) {
    return username;
  }
  return displayName + " (" + username + ")";
};

// Show first name, last name + email
export const mapUserToOption: (user: UserUMS) => UserOption = (user: UserUMS) => {
  const displayName = formatUser(user);
  return { value: user.username, label: displayName, ...user };
};

export const mapUserToOptions: (users: UserUMS[]) => UserOption[] = (users: UserUMS[]) => {
  return users.map(mapUserToOption);
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapUserToOptionWithStableID` instead.
 */
export const mapUserToOptionWithID = (user: GroupOwners): UserOption => {
  return { value: user.id, label: formatUser(user), ...user };
};

export const mapUserToOptionWithStableID = (user: GroupOwners): UserOption => {
  return { value: user.stableId, label: formatUser(user), ...user };
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapUsersToOptionsWithStableID` instead.
 */
export const mapUsersToOptionsWithID = (users: GroupOwners[]): UserOption[] => {
  return users.map(mapUserToOptionWithID);
};

export const mapUsersToOptionsWithStableID = (users: GroupOwners[]): UserOption[] => {
  return users.map(mapUserToOptionWithStableID);
};

export const mapPrefillToOption = (prefill?: UserUMS | string): UserOption | null =>
  prefill
    ? typeof prefill === "string"
      ? {
          value: prefill,
          label: prefill,
        }
      : mapUserToOption(prefill)
    : null;
