import { headers } from "./ApiHelpers";
import { ClassroomPolicy, ImportSchedulesRequest, ImportSchedulesResponse } from "../../types/Classrooms";

const baseUrl = "/api/policies";

// Which set of data will be used depends on if this appliance is feature toggled to use CPS for policy data.
// If it is, then the data will be fetched from CPS using stableIDs as policy identifiers.
// If not, then the data will be fetched from CMS using groupNames as policy identifiers.
export const getPoliciesForClassrooms = async (groupNames: string[], policy_ids: string[]): Promise<Map<string, ClassroomPolicy>> => {
  const requestBody = {
    groupNames,
    policy_ids,
  };
  const result = await fetch(`${baseUrl}/fetch`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
  if (result.status !== 200) {
    throw new Error(`Invalid response from server, unexpected error code ${result.status}`);
  }
  const resultData: unknown = await result.json();
  if (!isClassroomPolicyMap(resultData)) {
    throw new Error("Invalid response from server");
  }
  return new Map<string, ClassroomPolicy>(Object.entries(resultData));
};

export const deletePolicyRule = async (policyId: string, ruleId: string): Promise<void> => {
  const raw = await fetch(`${baseUrl}/${policyId}/rules/${ruleId}`, {
    method: "DELETE",
    headers: headers(),
  });

  if (raw.status !== 204 && raw.status !== 404) {
    throw new Error(`Invalid response from server, unexpected error code ${raw.status}`);
  }
};

const isClassroomPolicyMap = (response: unknown): response is Map<string, ClassroomPolicy> => {
  if (response === null || typeof response !== "object") return false;
  for (const [key, value] of Object.entries(response)) {
    if (typeof key !== "string" || value === null || typeof value !== "object") return false;
    if (!("group" in value) || !("policy" in value)) return false;
  }
  return true;
};

export const importSchedules = async (schedules: ImportSchedulesRequest): Promise<ImportSchedulesResponse> => {
  const raw = await fetch(`/api/policies/schedules/import`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(schedules),
  });
  if (raw.status !== 200) {
    throw new Error(`Invalid response from server, unexpected error code ${raw.status}`);
  }
  const response: unknown = await raw.json();
  if (!isImportSchedulesResponse(response)) {
    throw new Error("Invalid response from server");
  }
  return response;
};

const isImportSchedulesResponse = (response: unknown): response is ImportSchedulesResponse => {
  if (response === null || typeof response !== "object") return false;
  if (!("importedClassroomsCount" in response) || !("importedPeriodsCount" in response) || !("errors" in response)) return false;

  // If no errors (null) or errors is an array, then it's valid
  return response.errors === null || Array.isArray(response.errors);
};
