import React from "react";
import { InputChartDataItem } from "../TopSixAppsPieChart";
import { getAmount } from "../../modules/AmountField";
export interface ReportingTopTypesDataResponse {
  data: ReportingTopTypesData[];
}

export interface ReportingTopTypesData {
  "categoryId.string"?: string;
  "subCategoryId.string"?: string;
  "tag.string"?: string;
  upload: number;
  download: number;
  tag?: string;
  categoryId?: string;
  domain?: string;
  website?: string;
  subCategoryId?: string;
  hits?: string | number;
  transfer?: string | number;
  day?: string;
}

export interface ReportingTopTypeUserDataResponse {
  data: ReportingTopTypeUserData[];
}

export interface ReportingTopTypeUserData {
  day: string;
  hits: number;
  download: number;
  upload: number;
  transfer: number;
}

export interface FormattedData {
  displayCategory: string;
  appOrWebsite: string;
  upload: number;
  download: number;
  total: number;
  displayUpload: string;
  displayDownload: string;
  displayTotal: string;
  raw: ReportingTopTypesData;
}

export const categorySplitter = (categoryId: string, subCategoryId: string): JSX.Element | string => {
  if (categoryId && subCategoryId) {
    return <span> {">"} </span>;
  } else return "";
};

export const displayCategory = (data: ReportingTopTypesData): string => {
  const subCategoryValid = data["subCategoryId.string"] && data["subCategoryId.string"] !== undefined;
  return `${data["categoryId.string"] ? data["categoryId.string"] : "-"}${
    subCategoryValid && data["subCategoryId.string"] ? ` > ${data["subCategoryId.string"]}` : ""
  }`;
};

export const getAppOrWebsiteLink = (data: ReportingTopTypesData, serialisedFilters: string, user?: string): string => {
  const tagEqualsCategoryId = data.tag === data.categoryId;
  const tagEqualsSubCategoryId = data.tag === data.subCategoryId;
  const domainOrWebsiteExists = data.domain || data.website;

  if (tagEqualsCategoryId || tagEqualsSubCategoryId) {
    if (domainOrWebsiteExists) {
      const domain = data.domain ? data.domain : data.website ? data.website : "";
      if (user) {
        return `/surfwize/reporting/website/${domain}/user/${user}?${serialisedFilters}`;
      }
      return `/surfwize/reporting/website/${domain}/users?${serialisedFilters}`;
    }
    return "";
  }

  if (data.tag !== undefined) {
    if (user) {
      return `/surfwize/reporting/type/${data.tag}/user/${user}?${serialisedFilters}`;
    }
    return `/surfwize/reporting/type/${data.tag}/users?${serialisedFilters}`;
  }
  return "";
};

export const getAppOrWebsite = (data: ReportingTopTypesData): string => {
  if (data.tag === data.categoryId || data.tag === data.subCategoryId) {
    return data.domain ?? data.website ?? "-";
  }
  if (data.tag) {
    return data["tag.string"] ?? "-";
  }
  return "-";
};

export function validateTrafficAndWebsiteTotalResponse(t: unknown): t is ReportingTopTypesDataResponse {
  const validatedReportingTopTypesDataResponse = t as ReportingTopTypesDataResponse;

  if (validatedReportingTopTypesDataResponse === undefined || validatedReportingTopTypesDataResponse.data === undefined) {
    return false;
  }

  if (validatedReportingTopTypesDataResponse.data.length >= 1) {
    return validateTrafficAndWebsiteTotal(validatedReportingTopTypesDataResponse.data[0]);
  }

  return true;
}

export function validateTrafficAndWebsiteTotal(t: unknown): t is ReportingTopTypesData {
  const validatedReportingTopTypesData = t as ReportingTopTypesData;

  return validatedReportingTopTypesData?.upload !== undefined && validatedReportingTopTypesData?.download !== undefined;
}

// See serialiseReportingFilters, need to ensure that these property names match the query param names sent to DQS
export interface ReportingFilter {
  filter_user?: string;
  blocked?: string;
  hits?: string;
  filter_group?: string;
  date?: string;
  filter_type?: string;
  filter_website?: string;
  filter_policyid?: string;
}

export const getReportingFilter = (params: URLSearchParams): ReportingFilter => {
  return {
    filter_user: params.get("filter_user") ?? undefined,
    blocked: params.get("blocked") ?? undefined,
    hits: params.get("hits") ?? undefined,
    filter_group: params.get("filter_group") ?? undefined,
    date: params.get("date") ?? undefined,
    filter_type: params.get("filter_type") ?? undefined,
    filter_website: params.get("filter_website") ?? undefined,
    filter_policyid: params.get("filter_policyid") ?? undefined,
  };
};

export const serialiseReportingFilters = (filter: ReportingFilter | undefined): string => {
  if (!filter) {
    return "";
  }

  return [...Object.entries(filter)]
    .filter(([_, v]) => v)
    .map(([k, v]) => `${k}=${String(v)}`)
    .join("&");
};

export const getTopReportingItems = (response: ReportingTopTypesDataResponse): InputChartDataItem[] => {
  const top_items = [];
  for (const item of response.data) {
    if (!item["tag.string"] || !item["tag"]) {
      continue;
    } else if (!item["tag.string"] && item["domain"]) {
      top_items.push({
        name: item["domain"],
        y: item["upload"] + item["download"],
      });
    } else if (item["tag"] === item["subCategoryId"] && item["domain"]) {
      top_items.push({
        name: item["domain"],
        y: item["upload"] + item["download"],
      });
    } else if (item["tag"] === item["categoryId"] && item["domain"]) {
      top_items.push({
        name: item["domain"],
        y: item["upload"] + item["download"],
      });
    } else {
      top_items.push({
        name: item["tag.string"],
        y: item["upload"] + item["download"],
      });
    }

    if (top_items.length === 6) {
      break;
    }
  }
  return top_items;
};

export const formatData = (unformatted: ReportingTopTypesData): FormattedData => {
  return {
    displayCategory: displayCategory(unformatted),
    appOrWebsite: getAppOrWebsite(unformatted),
    displayUpload: getAmount(unformatted.upload),
    displayDownload: getAmount(unformatted.download),
    displayTotal: getAmount(unformatted.upload + unformatted.download),
    upload: unformatted.upload,
    download: unformatted.download,
    total: unformatted.upload + unformatted.download,
    raw: unformatted,
  };
};
