import { ThemeProvider, Flex, Icon, Text, Box } from "@familyzone/component-library";
import React from "react";
import Link from "../../Link";
import { RedFlagPill, RedFlagSeverity } from "../RedFlagPill/RedFlagPill";
import { showWebIcon } from "./DashboardRedFlagItemHelpers";

export interface IDashboardRedFlagItemNew {
  name: string;
  nameLink: string;
  visibleFlags: DashboardRedFlag[];
  setRefs: (element: unknown) => void;
  hiddenFlagsCount: number;
  overflowLink: unknown;
  time: string;
}

export interface DashboardRedFlag {
  name: string;
  level: RedFlagSeverity;
  link: string;
  hits: number;
  type: string;
}

const DashboardRedFlagItemNew = ({
  name,
  nameLink,
  visibleFlags,
  setRefs,
  hiddenFlagsCount,
  overflowLink,
  time,
}: IDashboardRedFlagItemNew): JSX.Element => {
  return (
    <ThemeProvider>
      <Flex alignItems="center">
        <Box
          w="20%"
          maxWidth="150px"
          textOverflow="ellipsis"
          overflow="hidden"
          _hover={{
            textDecoration: "underline",
            cursor: nameLink ? "pointer" : "none",
          }}
        >
          <Link to={nameLink}>{name}</Link>
        </Box>
        <Flex ref={setRefs} width="80%">
          {visibleFlags.map(({ name, level, link, hits, type }, index) => {
            const tooltip = { hits: hits, time: time };
            return (
              <RedFlagPill
                key={index}
                tooltip={tooltip}
                text={name}
                link={link}
                severity={level}
                icon={showWebIcon(name, type) ? "webpage" : "search"}
              />
            );
          })}
          {hiddenFlagsCount > 0 && (
            <Link to={overflowLink}>
              <Flex
                border="1px solid"
                borderColor="neutrals.50"
                mt="sp8"
                py="6px"
                px="sp8"
                h="34px"
                alignItems="center"
                justifyContent="center"
                borderRadius="4px"
              >
                <Icon icon="fa-plus" color="neutrals.200" mr="sp4" />
                <Text fontWeight="medium" color="neutrals.900" textDecoration="none">
                  {hiddenFlagsCount}
                </Text>
              </Flex>
            </Link>
          )}
        </Flex>
      </Flex>
    </ThemeProvider>
  );
};
export default DashboardRedFlagItemNew;
