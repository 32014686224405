import { Option } from "@familyzone/component-library";
import ObjectStore from "../../../stores/ObjectStore";
import { WebsiteObject } from "../criteriaTypes";

export const getWebsiteObjects = (): Option[] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const websiteObjects: WebsiteObject[] = ObjectStore.getWebsiteObjects();
  return websiteObjects.map((websiteObject) => ({
    value: websiteObject.id,
    label: websiteObject.name,
  }));
};
