import React from "react";
import Api from "../../utils/Api";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import RightPanel from "../../utils/RightPanel";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import Filterhelper from "../../utils/Filterhelper";
import {
  Flex,
  Grid,
  GridItem,
  SearchBox,
  Table,
  TbodyInfiniteScroll,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  withTranslation,
} from "@familyzone/component-library";
import PropTypes from "prop-types";
import { orderBy } from "lodash";
import PageWithHeader from "../templates/PageWithHeader";
import { zIndices } from "../../utils/ZIndexUtil";

class NewYoutubePlayer extends React.Component {
  render = () => {
    let titleCss = { margin: "unset", marginBottom: "20px" };
    let url = "https://www.youtube.com/embed/" + this.props.videoid + "?rel=0&amp;showinfo=0";
    return (
      <div className="formgroup_title">
        <div className="formgroup_title_title" style={titleCss}>
          <Text fontSize="lg" fontFamily="heading" color="neutrals.900">
            {this.props.title}
          </Text>
        </div>
        <div className="formgroup">
          <div className="formgroup_content">
            <div className="row">
              <div className="col-md-12 video-sizing-container">
                <div className="video_player-wrapper">
                  <iframe
                    title="title"
                    className="video_player"
                    src={url}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="video_description">
                  <Text fontSize="md">{this.props.description}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

class VideoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      videoStats: { views: 0, title: "", description: "", users: [], user_count: 0 },
      videoDetails: [],
      filters: Filterhelper.operate(this),
      searchTerm: "",
      sortDirection: "desc",
      sortColumn: "views",
      shownRowCount: 30,
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
    this.handle_load();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onChange = () => {
    this.handle_load();
  };

  handleSearchChange = (evt) => {
    this.setState({
      searchTerm: evt.target.value,
    });
  };
  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
    });
  };
  handleSortChange = (sortColumn, sortDirection) => {
    this.setState({
      sortColumn,
      sortDirection,
    });
  };
  getData = () => {
    return this.state.videoDetails;
  };
  isLoaded = () => {
    return this.state.loaded;
  };
  showMore = () => {
    this.setState({
      shownRowCount: this.state.shownRowCount + 20,
    });
  };

  handle_load = () => {
    let self = this;
    let videoid = this.props.params.videoid;
    this.setState({
      videoStats: { views: 0, title: "", description: "", users: [], user_count: 0 },
      videoDetails: [],
      loaded: false,
    });

    let params = { videoid: videoid };
    if (this.state.filters.filter_user) {
      params["user"] = this.state.filters.filter_user;
    }

    Api.post_analytics(
      "/surfwize/ajax/users/videos/details",
      params,
      (result) => {
        let videoStats = {};
        this.setState({
          videoDetails: result["result"],
        });
        for (let event of result["result"]) {
          if (Object.keys(videoStats).length === 0) {
            videoStats = { users: [event["user"]], views: 1, user_count: 1, videoid: event["videoid"] };
          } else {
            videoStats["views"]++;
            if (videoStats["users"].indexOf(event["user"]) < 0) {
              videoStats["users"].push(event["user"]);
              videoStats["user_count"]++;
            }
          }
        }
        Api.post(
          "/surfwize/ajax/videos/youtube/list",
          { ids: videoid },
          (result) => {
            for (let video of result["result"]) {
              videoStats["title"] = video["snippet"]["title"];
              videoStats["description"] = video["snippet"]["description"];
            }
            self.setState({
              videoStats: videoStats,
              loaded: true,
            });
          },
          (err) => {
            console.log(err);
            self.setState({ loaded: true });
          }
        );
      },
      (err) => {
        console.log(err);
        self.setState({ loaded: true });
      }
    );
  };

  render() {
    const { t } = this.props;
    const { searchTerm, sortColumn, sortDirection } = this.state;
    const breadcrumbData = [
      { title: t("Cyber Safety"), url: "/cybersafety/dashboard", isActive: false },
      { title: t("Videos"), url: "/cybersafety/video", isActive: false },
      { title: t("Video Detail"), isActive: true },
    ];
    return (
      <PageWithHeader breadcrumbs={breadcrumbData} title={t("Video Detail")}>
        <Grid templateColumns="repeat(5, 1fr)" gap={0}>
          <GridItem colSpan={4} marginTop="sp24" marginStart="sp24" marginEnd="sp16" p="sp24" background="neutrals.0" borderRadius="sm">
            <Flex justifyContent="space-between" mb="sp16">
              <SearchBox
                placeholder="Search"
                value={this.state.searchTerm}
                onChange={this.handleSearchChange}
                onClear={this.handleSearchClear}
                width="400px"
              />
            </Flex>
            <Flex direction="row">
              <Table>
                <Thead position="sticky" top="0" zIndex={zIndices.thead}>
                  <Tr>
                    <Th
                      columnName="date"
                      handleSort={this.handleSortChange}
                      sortDirection={sortColumn === "date" && sortDirection}
                      headerText={t("Date / Time")}
                    />
                    <Th
                      columnName="user"
                      handleSort={this.handleSortChange}
                      sortDirection={sortColumn === "user" && sortDirection}
                      headerText={t("Username")}
                    />
                    <Th
                      columnName="ip"
                      handleSort={this.handleSortChange}
                      sortDirection={sortColumn === "ip" && sortDirection}
                      headerText={t("IP/Mac")}
                    />
                  </Tr>
                </Thead>
                <TbodyInfiniteScroll
                  fetchData={this.showMore}
                  hasMore={this.getData().length >= this.state.shownRowCount}
                  parentElemId="ComponentWrapper"
                  loaded={this.isLoaded()}
                  searched={!!this.state.searchTerm}
                >
                  {this.getData() &&
                    orderBy(this.getData(), sortColumn, sortDirection)
                      .filter((video) => !searchTerm || video.user.includes(searchTerm))
                      .filter((_r, i) => i <= this.state.shownRowCount)
                      .map((video, id) => (
                        <Tr key={video.date + id}>
                          <Td>
                            <Text fontSize="md">{video.date}</Text>
                          </Td>
                          <Td>
                            <Text fontSize="md">{video.user}</Text>
                          </Td>
                          <Td>
                            <Text fontSize="md">
                              {video.ip} {video.mac}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                </TbodyInfiniteScroll>
              </Table>
            </Flex>
          </GridItem>
          <GridItem colSpan={1} marginTop="sp24" marginEnd="sp24" background="neutrals.0" borderRadius="sm">
            <RightPanel visible={true}>
              <div className="padded">
                <NewYoutubePlayer
                  videoid={this.props.params.videoid}
                  title={this.state.videoStats.title + " - " + this.state.videoStats.views + " Views"}
                  description={this.state.videoStats.description}
                />
              </div>
            </RightPanel>
          </GridItem>
        </Grid>
      </PageWithHeader>
    );
  }
}

VideoDetails.contextTypes = {
  router: PropTypes.object.isRequired,
};
export default withTranslation()(VideoDetails);
