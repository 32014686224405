import React from "react";
import Highcharts from "highcharts";

require("highcharts/modules/wordcloud");

export default class HighchartsModul extends React.Component {
  constructor(props, context) {
    super(props, context);
    Highcharts.setOptions({
      global: {
        useUTC: false,
      },
    });
  }

  componentDidMount = () => {
    if (this.props.modules) {
      this.props.modules.forEach(function (module) {
        module(Highcharts);
      });
    }
    this.chart = new Highcharts[this.props.type || "Chart"](this.props.container, this.props.options);
  };

  componentWillUnmount = () => {
    this.chart && this.chart.destroy();
  };

  componentDidUpdate = () => {
    if (this.props.blockUpdates) {
      return;
    }
    this.chart.update(this.props.options, true, true);
  };

  render = () => {
    return <div id={this.props.container} />;
  };
}
