/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useEffect, useState } from "react";
import Api from "../../../utils/Api";
import SessionStore from "../../../stores/SessionStore";
import { Box, InlineNotification, Link, Text, useToast, useTranslation } from "@familyzone/component-library";
import { SyncStatus } from "./SyncStatus";
import { SyncCredentialStatus } from "./SyncCredentialStatus";
import PageWithHeader from "../../templates/PageWithHeader";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
} from "@chakra-ui/react";
import DumbBusyIndicator from "../../../modules/DumbBusyIndicator";
import { CheckBoxControl } from "./ConfigForm/CheckboxControl";
import { TextFieldControl } from "./ConfigForm/TextFieldControl";
import { CheckBoxWithInputControl } from "./ConfigForm/CheckboxWithInputControl";
import { AdvanceConfigurationConfirmModal } from "./ConfigForm/AdvanceConfigurationConfirmModal";
import GoogleLogo from "../../../../images/logo_google.svg";

export interface ConfigurationResponse {
  configuration: Configuration;
}

interface Configuration {
  googleenabled?: boolean;
  google_sync_classrooms?: boolean;
  google_strip_domain?: boolean;
  googledomain?: string[] | string; // this _should_ be an array but somewhere through the time it got set as a string
  google_allow_insecure_chrome?: boolean;
  google_classroom_extension_login?: boolean;
  google_sync_ous?: boolean;
  sync_by_id?: boolean;
  googleusername?: string;
  customer_google_oauth_client_id?: string;
  customer_google_oauth_client_secret?: string;
  customer_google_oauth_credentials?: string;
  googlecredentials?: string;
  google_domain_filter?: boolean;
  google_domain_filter_values?: string[];
  google_ignore_empty_classrooms?: boolean;
  google_ignore_empty_groups?: boolean;
  google_ou?: string;
}

type GoogleConfigInputs = {
  enabled: boolean;
  sync_ous: boolean;
  allow_insecure_chrome: boolean;
  classroom_extension_login: boolean;
  sync_classrooms: boolean;
  sync_by_id: boolean;
  admin_username: string;
  oauth_client_id?: string;
  oauth_client_secret?: string;
  ignore_empty_groups: boolean;
  ignore_empty_classrooms: boolean;

  strip_domain: boolean;
  strip_domain_values?: string; // these are commas seperated lists :puke:

  domain_filter: boolean;
  domain_filter_values?: string; // these are commas seperated lists :puke:

  ou_filter: boolean;
  ou_filter_value?: string;
};

export const GoogleConfiguration: React.FC = () => {
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const [customerToken, setCustomerTokenExists] = useState<boolean>(false);
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const _handleLoad = useCallback(async (): Promise<GoogleConfigInputs> => {
    return new Promise<GoogleConfigInputs>((resolve, reject) => {
      Api.get(
        "/config/ajax/authentication/google",
        (result: ConfigurationResponse) => {
          const configuration = result.configuration;
          setTokenExists(!!configuration.googlecredentials);
          setCustomerTokenExists(!!configuration.customer_google_oauth_credentials);

          resolve({
            enabled: !!configuration.googleenabled,
            strip_domain: !!configuration.google_strip_domain,
            strip_domain_values: configuration.googledomain
              ? Array.isArray(configuration.googledomain)
                ? configuration.googledomain.join(",")
                : configuration.googledomain
              : undefined,
            allow_insecure_chrome: !!configuration.google_allow_insecure_chrome,
            classroom_extension_login: !!configuration.google_classroom_extension_login,
            sync_classrooms: !!configuration.google_sync_classrooms,
            sync_ous: !!configuration.google_sync_ous,
            sync_by_id: !!configuration.sync_by_id,
            admin_username: configuration.googleusername || "",
            oauth_client_id: configuration.customer_google_oauth_client_id || "",
            oauth_client_secret: configuration.customer_google_oauth_client_secret || "",
            domain_filter: !!configuration.google_domain_filter,
            domain_filter_values: configuration.google_domain_filter_values?.join(","),
            ignore_empty_classrooms: !!configuration.google_ou || !!configuration.google_ignore_empty_classrooms,
            ignore_empty_groups: !!configuration.google_ou || !!configuration.google_ignore_empty_groups,
            ou_filter: !!configuration.google_ou,
            ou_filter_value: configuration.google_ou,
          });
        },
        () => {
          reject("failed to load config");
        }
      );
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isSubmitting, isLoading, dirtyFields },
  } = useForm<GoogleConfigInputs>({
    defaultValues: _handleLoad, // we use an async func here so the form "isLoading" state is true while doing initial load
  });

  const enabled = watch("enabled");
  const domainFilter = watch("domain_filter");
  const stripDomain = watch("strip_domain");
  const ouFilter = watch("ou_filter");
  const ignoreEmptyClassrooms = watch("ignore_empty_classrooms");
  const ignoreEmptyGroups = watch("ignore_empty_groups");
  const oauthClientID = watch("oauth_client_id");
  const oauthClientSecret = watch("oauth_client_secret");

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const device: string = SessionStore.getSelectedDevice() as string;

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("Google"), isActive: true },
  ];

  const handleLoad = useCallback(async (): Promise<void> => {
    reset(await _handleLoad());
  }, [reset, _handleLoad]);

  useEffect(() => {
    void handleLoad();
  }, [handleLoad]);

  useEffect(() => {
    if (ouFilter) {
      setValue("ignore_empty_groups", true, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
      setValue("ignore_empty_classrooms", true, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
    }
  }, [setValue, ouFilter]);

  const _submit = useCallback(
    (input: GoogleConfigInputs) => {
      return new Promise<void>((resolve, reject) => {
        const object = {
          googleenabled: input.enabled,
          google_strip_domain: input.strip_domain,
          google_allow_insecure_chrome: input.allow_insecure_chrome,
          google_classroom_extension_login: input.classroom_extension_login,
          google_sync_classrooms: input.sync_classrooms,
          google_sync_ous: input.sync_ous,
          sync_by_id: input.sync_by_id,
          googledomain: input.strip_domain_values ? input.strip_domain_values.split(",").map((v) => v.trim()) : [],
          googleusername: input.admin_username,
          customer_google_oauth_client_id: input.oauth_client_id,
          customer_google_oauth_client_secret: input.oauth_client_secret,
          google_domain_filter: input.domain_filter,
          google_domain_filter_values: input.domain_filter_values ? input.domain_filter_values.split(",").map((v) => v.trim()) : [],
          google_ou: input.ou_filter ? input.ou_filter_value : "",
          // ignore empty groups/classrooms need to be true if ou filter is true
          google_ignore_empty_groups: input.ou_filter ? true : input.ignore_empty_groups,
          google_ignore_empty_classrooms: input.ou_filter ? true : input.ignore_empty_classrooms,
        };
        console.log(object);
        Api.post(
          "/config/ajax/authentication/google",
          object,
          async () => {
            successToast({
              title: t("Configuration Saved"),
              description: t("Configuration settings for Google have been saved."),
            });
            await handleLoad();
            resolve();
          },
          () => {
            errorToast({
              title: t("Failed to save Configuration"),
              description: t("An unknown error occurred while trying to save configuration."),
            });
            reject("failed to load config");
          }
        );
      });
    },
    [handleLoad, successToast, errorToast, t]
  );

  const onSubmit: SubmitHandler<GoogleConfigInputs> = async (data) => {
    // If we are already showing the modal, submit it
    if (showConfirmModal) {
      setShowConfirmModal(false);
      return _submit(data);
    }
    // show confirmation model if an advance config was changed
    if (
      dirtyFields.strip_domain ||
      dirtyFields.strip_domain_values ||
      dirtyFields.ou_filter ||
      dirtyFields.ou_filter_value ||
      dirtyFields.ignore_empty_classrooms ||
      dirtyFields.ignore_empty_groups
    ) {
      setShowConfirmModal(true);
      return;
    }
    return _submit(data);
  };

  const handleSubmitConfirmationConfirm = () => {
    void handleSubmit(onSubmit)();
  };

  const handleRevoke = () => {
    Api.post("/config/ajax/authentication/google/revoke", {}, handleLoad, handleLoad);
  };

  const handleLink = () => {
    Api.post("/config/ajax/authentication/google/link", {}, (result: { url: Location | (string & Location) }) => {
      window.location = result.url;
    });
  };

  const handleCustomerRevoke = () => {
    Api.post("/config/ajax/authentication/google/customer/revoke", {}, handleLoad, handleLoad);
  };

  const handleCustomerLink = () => {
    Api.post("/config/ajax/authentication/google/customer/link", {}, (result: { url: Location | (string & Location) }) => {
      window.location = result.url;
    });
  };

  const handleSync = useCallback(() => {
    Api.post("/config/ajax/authentication/google/sync", {}, handleLoad);
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    manuallySetRunningSyncs[device.concat("_GoogleLockout")] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
    localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
  }, [device, handleLoad]);

  return (
    <PageWithHeader title={"Google"} breadcrumbs={breadcrumbs}>
      <Box display={"flex"} flex={1} padding={"24px"} gap={"16px"}>
        <Box flex={1} minWidth={"500px"}>
          <Box background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"1rem"} borderRadius={"6px"}>
            <Box padding={"20px 20px 0 20px"} display={"flex"} flexDir={"row"} gap={"4px"}>
              <Text fontSize={14}>
                {"Find out how to"}{" "}
                <Link isExternal href={"https://help.linewize.com/hc/en-gb/articles/5732845239580"}>
                  sync Google with School Manager
                </Link>
              </Text>
            </Box>
            {isLoading && (
              <Box display={"flex"} justifyContent={"center"} flex={1}>
                <DumbBusyIndicator loaded={!isLoading} />
              </Box>
            )}
            {!isLoading && (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <form onSubmit={handleSubmit(onSubmit)} data-testid={"google-config-form"}>
                <Box sx={{ display: "flex", flexDirection: "column", padding: "24px", gap: "16px", fontSize: "14px" }}>
                  <FormControl
                    isInvalid={!!errors.enabled}
                    sx={{
                      display: "flex",
                      minHeight: "40px",
                      gap: "16px",
                    }}
                  >
                    <FormLabel
                      htmlFor="enabled"
                      sx={{
                        width: "120px",
                        alignContent: "center",
                      }}
                    >
                      Enabled
                    </FormLabel>
                    <Checkbox isChecked={enabled} {...register("enabled", {})} />
                    <FormErrorMessage>{errors.enabled && errors.enabled.message}</FormErrorMessage>
                  </FormControl>
                  <Divider />
                  {enabled && (
                    <>
                      <CheckBoxControl
                        label={"Use FZ Auth API"}
                        register={register}
                        errors={errors}
                        propertyName={"allow_insecure_chrome"}
                        tooltip={"Turn on to use Linewize Cloud Authentication instead of Google OAuth."}
                      />
                      <CheckBoxControl
                        label={"Allow Chrome Extension Authentication"}
                        register={register}
                        errors={errors}
                        propertyName={"classroom_extension_login"}
                        tooltip={"Turn on to use Linewize Connect for Chrome to authenticate a user's device."}
                      />
                      <Divider />
                      <CheckBoxControl
                        label={"Sync Organization Units"}
                        register={register}
                        errors={errors}
                        propertyName={"sync_ous"}
                        tooltip={"Turn on to sync any existing organizational units in your Google Workspace."}
                      />
                      <CheckBoxControl
                        label={"Sync Classrooms"}
                        register={register}
                        errors={errors}
                        propertyName={"sync_classrooms"}
                        tooltip={"Turn on to let Google Classrooms, including assigned students and teachers, sync with Classwize."}
                      />
                      <CheckBoxControl
                        label={"Sync Groups By ID"}
                        register={register}
                        errors={errors}
                        propertyName={"sync_by_id"}
                        tooltip={
                          "Syncing by ID allows you to sync multiple groups with the same name, and strip domain doesn't apply on groups anymore. Changing this can invalidate existing filtering rules."
                        }
                      />
                      <CheckBoxWithInputControl
                        label={"Domain Filter"}
                        register={register}
                        errors={errors}
                        propertyName={"domain_filter"}
                        tooltip={"Accepts CSV list of domains. Only users and groups attached to these domains will be synced."}
                        inputPropertyName={"domain_filter_values"}
                        inputRegisterOptions={{
                          required: domainFilter ? "Domains required if Domain Filter is enabled." : false,
                          minLength: { value: 3, message: "Minimum length should be 3" },
                          pattern: {
                            value: /^(?!.*:\/\/)(([^,\s]+\s*,\s*)*[^,\s]+)$/,
                            message: "Please enter domains without protocols, separated by commas.",
                          },
                        }}
                        inputTestId={"domain_filter-input"}
                        enabled={domainFilter}
                      />
                      <TextFieldControl
                        label={"Administrator"}
                        register={register}
                        errors={errors}
                        propertyName={"admin_username"}
                        inputTestId={"admin_username-input"}
                        tooltip={"Enter the email address of your Google Workspace Super Administrator account."}
                        inputRegisterOptions={{ required: "A administrator account address is required." }}
                      />
                      <Box
                        sx={{
                          minHeight: "40px",
                          backgroundColor: "#F4F5F7",
                          alignContent: "center",
                          padding: "12px",
                        }}
                      >
                        <Text fontWeight={500}>OAuth</Text>
                      </Box>
                      <Text>
                        {
                          "Enter your OAuth credentials to increase Google Classroom sync speed. You must provide your OAuth credentials if you want to let teachers sync classes from Classwize."
                        }
                      </Text>
                      <TextFieldControl
                        inputTestId={"client_id-input"}
                        label={"Client ID"}
                        register={register}
                        errors={errors}
                        propertyName={"oauth_client_id"}
                      />
                      <TextFieldControl
                        inputTestId={"client_secret-input"}
                        label={"Client Secret"}
                        register={register}
                        errors={errors}
                        propertyName={"oauth_client_secret"}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {!customerToken && (
                          <Tooltip
                            hasArrow
                            isDisabled={!(!oauthClientID || !oauthClientSecret || isDirty)}
                            shouldWrapChildren
                            placement={"right"}
                            variant={"dark"}
                            label={"Please ensure Client ID and Secret are filled and saved before linking."}
                          >
                            <Button
                              disabled={!oauthClientID || !oauthClientSecret || isDirty}
                              onClick={handleCustomerLink}
                              variant={"outline"}
                              w={"300px"}
                              h={"40px"}
                              leftIcon={<img alt="Google Logo" src={GoogleLogo} />}
                            >
                              Setup OAuth with Google
                            </Button>
                          </Tooltip>
                        )}
                        {customerToken && (
                          <Button onClick={handleCustomerRevoke} variant={"primary"} backgroundColor={"#CC3746"} w={"300px"} h={"40px"}>
                            Revoke OAuth
                          </Button>
                        )}
                      </Box>

                      <Accordion
                        allowToggle
                        defaultIndex={stripDomain || ouFilter || ignoreEmptyClassrooms || ignoreEmptyGroups ? 0 : undefined}
                        sx={{
                          border: "1px solid #F4F5F7",
                          borderRadius: "6px",
                        }}
                      >
                        <AccordionItem>
                          <AccordionButton data-testid={"accordionBtn"}>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                minHeight: "40px",
                                backgroundColor: "#F4F5F7",
                                alignContent: "center",
                                alignItems: "center",
                                padding: "12px",
                                gap: "8px",
                              }}
                            >
                              <AccordionIcon />

                              <Text fontWeight={500}>Advanced Configuration</Text>
                            </Box>
                          </AccordionButton>
                          <AccordionPanel
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "16px",
                              fontSize: "14px",
                              gap: "16px",
                            }}
                          >
                            <Text>
                              Advanced sync settings are for complex deployments only. Consult with the Linewize support team before making
                              any changes.
                            </Text>
                            {stripDomain && (
                              <InlineNotification
                                status="warning"
                                notificationTitle={"User names need to be unique when using strip domains. "}
                                notificationDescription={
                                  "Strip Domain is used to modify usernames from the directory. Before enabling this feature ensure usernames without domains are unique. If usernames are not unique this will result in errors."
                                }
                              />
                            )}
                            <CheckBoxWithInputControl
                              label={"Strip Domain"}
                              register={register}
                              errors={errors}
                              propertyName={"strip_domain"}
                              tooltip={"Turn on to import users as username instead of username@example.com."}
                              inputPropertyName={"strip_domain_values"}
                              inputRegisterOptions={{
                                required: stripDomain ? "Domains are required if Strip Domain is enabled." : false,
                                minLength: { value: 3, message: "Minimum length should be 3" },
                                pattern: {
                                  value: /^(?!.*:\/\/)(([^,\s]+\s*,\s*)*[^,\s]+)$/,
                                  message: "Please enter domains without a protocol.",
                                },
                              }}
                              inputTestId={"strip_domain-input"}
                              enabled={stripDomain}
                            />
                            {ouFilter && (
                              <InlineNotification
                                status="warning"
                                notificationTitle={"Enabling Organization Unit filter forces ignore empty groups and classrooms to be on."}
                                notificationDescription={
                                  "While Organization Unit Filtering is enabled, both ignore empty classrooms and ignore empty groups will be enabled by default."
                                }
                              />
                            )}
                            <CheckBoxWithInputControl
                              label={"Organization Unit Filter"}
                              register={register}
                              errors={errors}
                              propertyName={"ou_filter"}
                              inputPropertyName={"ou_filter_value"}
                              inputRegisterOptions={{
                                required: ouFilter ? "OU required if Organization Unit Filter is enabled." : false,
                                minLength: { value: 3, message: "Minimum length should be 3" },
                              }}
                              inputTestId={"ou_filter-input"}
                              enabled={ouFilter}
                            />
                            <Divider />
                            {/*
                            the key values here are hack jobs, I couldn't figure out how to force a rerender after calling setValues
                            https://react-hook-form.com/docs/useform/setvalue says it should rerender when shouldDirty is true
                            so instead I force a remount via key :sob:
                            */}
                            <CheckBoxControl
                              key={`ignore-empty-groups-${ignoreEmptyGroups ? "true" : "false"}`}
                              disabled={ouFilter}
                              label={"Ignore Empty Groups"}
                              register={register}
                              errors={errors}
                              propertyName={"ignore_empty_groups"}
                            />
                            <CheckBoxControl
                              key={`ignore-empty-classrooms-${ignoreEmptyClassrooms ? "true" : "false"}`}
                              disabled={ouFilter}
                              label={"Ignore Empty Classrooms"}
                              register={register}
                              errors={errors}
                              propertyName={"ignore_empty_classrooms"}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    padding: "24px",
                    background: "#F5FCFF",
                    display: "flex",
                    gap: "24px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    data-testid={"submitBtn"}
                    mt={4}
                    disabled={!isDirty || isSubmitting}
                    colorScheme="teal"
                    type="submit"
                    variant={"primary"}
                  >
                    Submit
                  </Button>
                  <DumbBusyIndicator loaded={!isSubmitting} />
                </Box>
                <AdvanceConfigurationConfirmModal
                  isOpen={showConfirmModal}
                  onClose={() => setShowConfirmModal(false)}
                  onSubmit={handleSubmitConfirmationConfirm}
                />
              </form>
            )}
          </Box>
        </Box>
        {enabled && (
          <Box width={"400px"} gap={"16px"} display={"flex"} flexDir={"column"}>
            <SyncStatus
              syncType={"GOOGLE"}
              handleRunSync={tokenExists && !isDirty ? handleSync : undefined}
              syncLockoutTime={
                device
                  ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_GoogleLockout")]
                    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_GoogleLockout")]
                    : undefined
                  : undefined
              }
            />
            <SyncCredentialStatus isLinked={tokenExists} handleLink={handleLink} handleRevoke={handleRevoke} syncType={"GOOGLE"} />
          </Box>
        )}
      </Box>
    </PageWithHeader>
  );
};
