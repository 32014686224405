import React from "react";
import $ from "jquery";
import LeftPanel from "../../utils/LeftPanel";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import Api from "../../utils/Api";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SimpleLayer7CriteriaSelector from "../../modules/SimpleLayer7CriteriaSelector";
import DynamicInputList from "../../modules/DynamicInputList";
import { handleAddItem } from "./ConfigurationMitmHelper";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";

export default class Mitm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      enabled: false,
      key: "",
      cert: "",
      cert_password: "",

      inspected__domains: [],
      inspected__domains_exempt: [],
      inspected__signatures: [],
      inspected__signatures_exempt: [],
      inspected__all: false,

      enabled__groups: [],
      enabled__networks: [],
      enabled__networks_exempt: [],
      enabled__all: false,

      changes: true,
      saving: false,
    };
  }

  componentDidMount = () => {
    this.handle_load();
  };

  handle_ChangeEnabled = (event) => {
    this.setState({
      enabled: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeEverything = (event) => {
    this.setState({
      inspected__all: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeEnabledForAllDevices = (event) => {
    this.setState({
      enabled__all: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeKey = (event) => {
    this.setState({
      key: event.target.value,
      changes: true,
    });
  };

  handle_ChangeCert = (event) => {
    this.setState({
      cert: event.target.value,
      changes: true,
    });
  };

  handle_ChangeCertPassword = (event) => {
    this.setState({
      cert_password: event.target.value,
      changes: true,
    });
  };

  handle_AddIncludedNetwork = (network) => {
    const enabledNetworks = handleAddItem(network, this.state.enabled__networks);
    this.setState({ enabled__networks: enabledNetworks });
  };

  handle_RemoveIncludedNetwork = (group) => {
    const new_items = [];
    for (let x of this.state.enabled__networks) {
      if (x !== group) {
        new_items.push(x);
      }
    }

    this.setState({ enabled__networks: new_items });
  };

  handle_AddExcludedNetwork = (network) => {
    const enabled__networks_exempt = handleAddItem(network, this.state.enabled__networks_exempt);
    this.setState({ enabled__networks_exempt: enabled__networks_exempt });
  };

  handle_RemoveExcludedNetwork = (group) => {
    const new_items = [];
    for (let x of this.state.enabled__networks_exempt) {
      if (x !== group) {
        new_items.push(x);
      }
    }
    this.setState({ enabled__networks_exempt: new_items });
  };

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get(
      "/config/ajax/mitm/https",
      (result) => {
        let configuration = result["data"];
        if (!$.isEmptyObject(configuration)) {
          self.setState({
            loaded: true,
            enabled: configuration["enabled"],
            key: configuration["key"],
            cert: configuration["cert"],
            cert_password: configuration["cert_password"],
            everything: configuration["everything"],

            inspected__domains: configuration["inspected__domains"],
            inspected__domains_exempt: configuration["inspected__domains_exempt"],
            inspected__signatures: configuration["inspected__signatures"],
            inspected__signatures_exempt: configuration["inspected__signatures_exempt"],
            inspected__all: configuration["inspected__all"],

            enabled__groups: configuration["enabled__groups"],
            enabled__networks: configuration["enabled__networks"],
            enabled__networks_exempt: configuration["enabled__networks_exempt"],
            enabled__all: configuration["enabled__all"],
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handle_save = () => {
    let self = this;

    let object = {
      enabled: this.state.enabled,
      key: this.state.key,
      cert: this.state.cert,
      cert_password: this.state.cert_password,

      inspected__domains: this.state.inspected__domains,
      inspected__domains_exempt: this.state.inspected__domains_exempt,
      inspected__signatures: this.state.inspected__signatures,
      inspected__signatures_exempt: this.state.inspected__signatures_exempt,
      inspected__all: this.state.inspected__all,

      enabled__groups: this.state.enabled__groups,
      enabled__networks: this.state.enabled__networks,
      enabled__networks_exempt: this.state.enabled__networks_exempt,
      enabled__all: this.state.enabled__all,
    };

    self.setState({ saving: true, changes: false });
    Api.post(
      "/config/ajax/mitm/https",
      object,
      () => {
        self.handle_load();
        self.setState({ saving: false, changes: false });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handle_ModifyInspected = (obj) => {
    if (obj["signatures"]) {
      this.setState({ inspected__signatures: obj["signatures"] });
    }
    if (obj["websites"]) {
      this.setState({ inspected__domains: obj["websites"] });
    }
  };

  handle_ModifyExcluded = (obj) => {
    if (obj["signatures"]) {
      this.setState({ inspected__signatures_exempt: obj["signatures"] });
    }
    if (obj["websites"]) {
      this.setState({ inspected__domains_exempt: obj["websites"] });
    }
  };

  render_Inspected = () => {
    if (!this.state.inspected__all) {
      return (
        <div className="formgroup-element">
          <div className="formgroup-element-title">Inspected</div>
          <div className="formgroup-element-fields">
            <div className="mui-textfield">
              <SimpleLayer7CriteriaSelector
                placeholder={"Search for Website/Category"}
                selected_websites={this.state.inspected__domains}
                selected_signatures={this.state.inspected__signatures}
                handle_BubbleChanges={this.handle_ModifyInspected}
                id="inspected_signatures"
                hide_website_objects
              />
            </div>
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  render_Excluded = () => {
    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Excluded</div>
        <div className="formgroup-element-fields">
          <div className="mui-textfield">
            <SimpleLayer7CriteriaSelector
              placeholder={"Search for Website/Category"}
              selected_websites={this.state.inspected__domains_exempt}
              selected_signatures={this.state.inspected__signatures_exempt}
              handle_BubbleChanges={this.handle_ModifyExcluded}
              id="excluded_signatures"
              hide_website_objects
            />
          </div>
        </div>
      </div>
    );
  };

  render_IncludedNetworks = () => {
    if (this.state.enabled__all) {
      return <div></div>;
    }

    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Networks</div>
        <div className="formgroup-element-fields">
          <div className="mui-textfield">
            <DynamicInputList
              selected={this.state.enabled__networks}
              type="text"
              addElement={this.handle_AddIncludedNetwork}
              removeElement={this.handle_RemoveIncludedNetwork}
            ></DynamicInputList>
          </div>
        </div>
      </div>
    );
  };

  render_ExcludedNetworks = () => {
    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Excluded Networks</div>
        <div className="formgroup-element-fields">
          <div className="mui-textfield">
            <DynamicInputList
              selected={this.state.enabled__networks_exempt}
              type="text"
              addElement={this.handle_AddExcludedNetwork}
              removeElement={this.handle_RemoveExcludedNetwork}
            ></DynamicInputList>
          </div>
        </div>
      </div>
    );
  };

  handle_ChangeGroup = (groups) => {
    const newGroups = groups.map((group) => group.name);
    this.setState({ enabled__groups: newGroups });
  };

  render_IncludedGroups = () => {
    if (this.state.enabled__all) {
      return <div></div>;
    }

    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Groups</div>
        <div className="formgroup-element-fields">
          <div className="mui-textfield">
            <MultiGroupSearchSelector
              preselected={this.state.enabled__groups.map((group) => ({ name: group, value: group }))}
              onChangeGroups={this.handle_ChangeGroup}
              useLegacyId={true}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            HTTPS Inspection
            <DumbBusyIndicator loaded={this.state.loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.enabled} onChange={this.handle_ChangeEnabled} />
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <div className="formgroup_title">
                  <div className="formgroup_title_title">CA Configuration</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">CA Key</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <textarea value={this.state.key} onChange={this.handle_ChangeKey} placeholder="Key" />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">CA Certificate</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <textarea value={this.state.cert} onChange={this.handle_ChangeCert} placeholder="Certificate" />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">CA Password</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="password"
                          value={this.state.cert_password}
                          onChange={this.handle_ChangeCertPassword}
                          placeholder="CA password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="formgroup_title">
                <div className="formgroup_title_title">Inspected Applications and Websites</div>
              </div>
              <div className="formgroup-content">
                <div className="formgroup">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Inspect All</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.inspected__all} onChange={this.handle_ChangeEverything} />
                      </div>
                    </div>
                  </div>
                  {this.render_Inspected()}
                  {this.render_Excluded()}
                </div>
              </div>
              <br />
              <div className="formgroup_title">
                <div className="formgroup_title_title">Enabled Networks and Groups</div>
              </div>
              <div className="formgroup-content">
                <div className="formgroup-element">
                  <div className="formgroup-element-title">All Devices</div>
                  <div className="formgroup-element-fields">
                    <div className="mui-checkbox">
                      <input type="checkbox" checked={this.state.enabled__all} onChange={this.handle_ChangeEnabledForAllDevices} />
                    </div>
                  </div>
                </div>

                <div className="formgroup">
                  {this.render_IncludedNetworks()}
                  {this.render_ExcludedNetworks()}
                  {this.render_IncludedGroups()}
                </div>
              </div>

              <div className="formgroup_title">
                <div className="formgroup_title_title">Certificate Portal</div>
              </div>
              <div className="formgroup-content">
                To download certificates, users can be directed to <a href="http://certs.linewize.net">http://certs.linewize.net</a>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton changes={this.state.changes} saving={this.state.saving} onClick={this.handle_save} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
