import React, { useEffect, useMemo, useState } from "react";
import { Box, MultiHierarchySelector, useTranslation, Text } from "@familyzone/component-library";
import { getWebsiteValue } from "./SignatureSelectorHelpers";

export const maxSignaturesAllowed = 300;

export const placeholderText = "Enter a website";
export const websiteText = "Website ";

export interface WebsitePoolCreationProps {
  websiteObjects: string[];
  disabled?: boolean;
  onChange: (selected: options[]) => void;
}

interface options {
  label: string;
  value: string;
}

const WebsitePoolCreation: React.FC<WebsitePoolCreationProps> = ({ websiteObjects, disabled, onChange }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [clearInput, setClearInput] = useState(false);
  const selected: options[] = useMemo(() => websiteObjects.map((website) => ({ label: website, value: website })), [websiteObjects]);
  const websiteValue = useMemo(() => getWebsiteValue(inputValue), [inputValue]);
  const [options, setOptions] = useState<options[]>([{ label: "Start Typing to select a value", value: "youcannotselectthis" }]);

  const handleChangeSearch = (search: string) => {
    setInputValue(search);
  };

  const handleChangeSelected = (selected: options[]) => {
    onChange(selected.filter((option) => !option.value.includes("youcannotselectthis")));
  };

  const handleAddWebsite = () => {
    if (selected.find((site) => site.value === websiteValue)) return;
    onChange([...selected, { label: websiteValue, value: websiteValue }]);
    setClearInput(true);
  };

  const handleEnterWebsite = (e?: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    // is key pressed is enter
    if (e && e.key === "Enter") {
      if (websiteValue === ".com") return;
      if (selected.find((site) => site.value === websiteValue)) return;
      onChange([...selected, { label: websiteValue, value: websiteValue }]);
      setClearInput(true);
    }
  };

  useEffect(() => {
    if (!clearInput) return;
    setClearInput(false);
  }, [clearInput]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([{ label: "Start Typing to select a value", value: "youcannotselectthis" }]);
      return;
    }
    setOptions([]);
  }, [selected, inputValue]);

  return (
    <MultiHierarchySelector
      selected={selected}
      onChange={handleChangeSelected}
      placeholder={t(placeholderText)}
      onSearch={handleChangeSearch}
      searchDelay={200}
      disableNoContentText
      disableCategoryOptions
      disabled={disabled}
      clearInputOnSelect
      clearInput={clearInput}
      onKeyDown={handleEnterWebsite}
      options={options}
      startContent={
        inputValue && (
          <Box padding="sp2" width="100%">
            <Box
              padding="sp8"
              borderRadius="4px"
              width="100%"
              cursor={"pointer"}
              display="flex"
              alignItems="center"
              backgroundColor={"brand.200"}
              _hover={{
                backgroundColor: "brand.100",
              }}
              onClick={handleAddWebsite}
            >
              <Box ml="sp12" mr="sp8" />
              <Text ml="sp8">
                {t(websiteText)}
                <Text display="inline" fontWeight="bold">
                  {websiteValue}
                </Text>
              </Text>
            </Box>
          </Box>
        )
      }
    />
  );
};

export default WebsitePoolCreation;
