import { Box, Icon } from "@familyzone/component-library";
import React from "react";
import "../../css/ListSelector.css";

class GroupSelectedOption extends React.Component {
  handleRemove = () => {
    this.props.handleRemove(this.props.item);
  };

  render() {
    return (
      <div className="listselector-item">
        {this.props.item}
        <Box float="right">
          <Icon cursor="pointer" icon="fa-times" onClick={this.handleRemove} />
        </Box>
      </div>
    );
  }
}

export default class DynamicInputList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: "",
    };
  }

  handle_AddSelection = () => {
    this.props.addElement(this.state.value);
    this.setState({ value: "" });
  };

  handle_RemoveSelection = (item) => {
    this.props.removeElement(item);
  };

  render_SelectedOptions = () => {
    if (!this.props.selected) {
      return [];
    }

    let ret = [];
    for (let x of this.props.selected) {
      ret.push(<GroupSelectedOption key={x} item={x} handleRemove={this.handle_RemoveSelection} />);
    }

    return ret;
  };

  handle_ValueChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <div className="mui-textfield">
          <input
            placeholder="Enter value and press enter"
            type={this.props.type}
            value={this.state.value}
            onChange={this.handle_ValueChange}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                this.handle_AddSelection();
              }
            }}
          />
          <label>{this.props.label}</label>
        </div>
        <div>{this.render_SelectedOptions()}</div>
      </div>
    );
  }
}
