import React, { useEffect, useRef, useState } from "react";
import ComponentLoading from "./ComponentLoading";
import Select from "react-select";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { Flex, Text } from "@familyzone/component-library";

const WordCloud = (props) => {
  const [wordCloudLimit, setWordCloudLimit] = useState(props.limit ? props.limit : 30);
  const [graphLoaded, setGraphLoaded] = useState(false);
  const chartRef = useRef();

  useEffect(() => {
    if (props.dataLoaded) {
      chartRef.current = renderAMWordCloud(
        props.data,
        wordCloudLimit,
        props.onSeriesClick,
        () => {
          setGraphLoaded(true);
        },
        props.backgroundColor,
        props.wordColorLight,
        props.wordColorDark
      );

      return () => {
        chartRef.current && chartRef.current.dispose();
        chartRef.current = undefined;
      };
    }
  }, [props.dataLoaded, wordCloudLimit, props.data, props.onSeriesClick, props.backgroundColor, props.wordColorLight, props.wordColorDark]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = props.data;
    }
  }, [props.data]);

  const showNoData = props.dataLoaded && props.data.length === 0;
  const showGraph = props.dataLoaded && props.data.length > 0 && graphLoaded;
  const showLoading = !props.dataLoaded || (props.data.length > 0 && !graphLoaded);

  return (
    <>
      <WordCloudButton
        values={[30, 50, 100]}
        selectedValue={wordCloudLimit}
        onChange={(event) => setWordCloudLimit(event.value)}
        showLabel={props.showTermsLabel}
      />
      <div className="chart-container has-centered-elements">
        {showLoading && (
          <div className="is-centered">
            <ComponentLoading />
          </div>
        )}
        {showNoData && <div className="is-centered">No data to display</div>}
        <div id={props.container} className={`chart ${!showGraph ? "loading" : ""}`} />
      </div>
    </>
  );
};

export default WordCloud;

const maxLength = 6;
const indexScale = 3;
const minimumPercentage = 0.2;

const renderAMWordCloud = (
  seriesData,
  wordCloudLimit,
  onSeriesClick,
  readyFunction,
  backgroundColor = "#FFFFFF",
  wordColorLight = "#6BB3CC",
  wordColorDark = "#074E7D"
) => {
  am4core.useTheme(am4themes_kelly);
  am4core.useTheme(am4themes_animated);
  am4core.addLicense("CH262668106");

  const chart = am4core.create("amCharts-wordcloud_searches", am4plugins_wordCloud.WordCloud);
  chart.fontFamily = "Roboto";
  chart.background.fill = backgroundColor;
  const series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
  series.accuracy = 1;
  series.randomness = 0.5;
  series.rotationThreshold = 0.5;
  series.maxFontSize = 40;
  series.minFontSize = 14;

  let newSeriesData = [...seriesData];

  const numOfResults = newSeriesData.length;
  newSeriesData.forEach((entry, idx) => {
    // Original data is used for ReportingSearch.onSeriesClick
    entry.originalData = entry.name ?? "";

    // Reduce the number of words in each data entry (name)
    const words = entry.name.split(" ");
    if (words.length > maxLength) {
      entry.name = words.slice(0, maxLength).join(" ");
    }

    // Calculate weight - data-query-service wont handle this anymore
    let weight = numOfResults - idx * indexScale;
    if (weight < numOfResults * minimumPercentage) {
      weight = numOfResults * minimumPercentage;
    }
    weight = Math.round(weight);
    entry.weight = weight;
  });

  // Sort in descending order so that top hits are in front of the array
  newSeriesData.sort((a, b) => b.weight - a.weight);

  newSeriesData = newSeriesData.slice(0, wordCloudLimit);

  series.data = newSeriesData;

  series.dataFields.word = "name";
  series.dataFields.value = "weight";

  series.heatRules.push({
    target: series.labels.template,
    property: "fill",
    min: am4core.color(wordColorLight),
    max: am4core.color(wordColorDark),
    dataField: "value",
  });

  series.labels.template.tooltipText = "{originalData}: {hits}";
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = am4core.color("#353B40");

  const hoverState = series.labels.template.states.create("hover");
  hoverState.properties.fill = am4core.color("#2188D0");

  series.labels.template.events.on("hit", function (event) {
    // Pass the value of the word clicked through to the on click function
    onSeriesClick(event.target.dataItem.dataContext.originalData);
  });

  series.events.on("arrangeended", (event) => readyFunction());

  return chart;
};

const getValueDataMap = (values) => {
  let ret = [];
  for (let item of values) {
    ret.push({ value: item, label: item });
  }

  return ret;
};

const WordCloudButton = (props) => {
  return (
    <Flex alignItems="center">
      {props.showLabel && (
        <Text mr="sp8" fontFamily="paragraphs" fontWeight="500" color="text.title">
          Show terms
        </Text>
      )}
      <Select
        name="form-field-name"
        value={props.selectedValue}
        placeholder={props.selectedValue}
        onChange={(event) => props.onChange(event)}
        options={getValueDataMap(props.values)}
        clearable={false}
        searchable={false}
        style={{ minWidth: "95px" }} // Make the box a bit wider to match design. Numbers have inherently small width.
      />
    </Flex>
  );
};
