import React, { useMemo } from "react";
import { Flex, useTranslation, Thead, Tr, Th, DraggableTable, DraggableTbody, arrayMove, useToast } from "@familyzone/component-library";

import { FilteringRule } from "./types";

import PolicyTableRow from "./PolicyTableRow";
import NoContentDisplay from "./NoContentDisplay";

interface FilteringTableProps {
  loading?: boolean;
  disabled?: boolean;
  rules: FilteringRule[];
  onSortRules: (rules: FilteringRule[]) => void;
  onEditRule: (rule: FilteringRule) => void;
  onDeleteRule: (rule: FilteringRule) => void;
  onToggleRule: (rule: FilteringRule) => void;
}

const FilteringTable: React.FC<FilteringTableProps> = ({
  loading = false,
  disabled = false,
  rules = [],
  onSortRules = () => "",
  onEditRule = () => "",
  onDeleteRule = () => "",
  onToggleRule = () => "",
}) => {
  const { t } = useTranslation();
  const { warningToast } = useToast();

  const columns: string[] = ["", t("Enable"), t("Action"), t("Policy Name"), t("Operations")];

  const noContentTitle = t("Create your first policy");
  const noContentText = t("Create your first content filtering policy by clicking on the “Create Policy” button");

  const lockedWarningText = t("Locked filter policies have greater priority and must remain at the top");

  const sortedRules: FilteringRule[] = useMemo(() => {
    const sticky = rules.filter((r) => r.sticky);
    const nonSticky = rules.filter((r) => !r.sticky);
    return [...sticky, ...nonSticky];
  }, [rules]);

  if (rules.length === 0 && !loading) {
    return <NoContentDisplay title={noContentTitle} text={noContentText} />;
  }

  const handleMoveRule = (active: string | number, over: string | number) => {
    if (loading) return;
    if (active === over) return;

    const activeRule = sortedRules.find((r) => r.id === active);
    const overRule = sortedRules.find((r) => r.id === over);
    if (
      (overRule && overRule.sticky && activeRule && !activeRule.sticky) ||
      (overRule && !overRule.sticky && activeRule && activeRule.sticky)
    ) {
      warningToast({ title: lockedWarningText, position: "bottom", duration: 6000 });
      return;
    }

    const activeIndex = sortedRules.findIndex((r) => r.id === active);
    const overIndex = sortedRules.findIndex((r) => r.id === over);
    if (activeIndex === -1 || overIndex === -1) return;

    const newRules = arrayMove(sortedRules, activeIndex, overIndex);

    onSortRules(newRules);
  };

  return (
    <Flex mt="sp24">
      <DraggableTable onMove={handleMoveRule}>
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th
                key={`${index}-${column}`}
                headerText={column}
                columnName={column}
                alignment={index === columns.length - 1 ? "right" : "left"}
              />
            ))}
          </Tr>
        </Thead>
        <DraggableTbody items={sortedRules.map((r) => r.id)} loaded={!loading}>
          {sortedRules.map((rule) => (
            <PolicyTableRow
              draggable
              key={rule.id}
              rule={rule}
              id={rule.id}
              onToggleEnabled={() => onToggleRule(rule)}
              onEdit={() => onEditRule(rule)}
              onDelete={() => onDeleteRule(rule)}
              disabled={disabled}
            />
          ))}
        </DraggableTbody>
      </DraggableTable>
    </Flex>
  );
};

export default FilteringTable;
