import { Spacer } from "@chakra-ui/layout";
import { Box, Flex, Text } from "@familyzone/component-library";
import React from "react";
import { Link } from "react-router";
import AmountField from "../../../modules/AmountField";
import Api from "../../../utils/Api";
import CardSpinner from "./CardSpinner";
import { topTrafficTypesCardSorter } from "./TopTrafficTypesCardHelpers";
import { ApiDates } from "../../../utils/DateTimeUtil";

interface ITopTrafficTypesCard {
  user: string;
  dateFilters: ApiDates;
}

const TopTrafficTypesCard = ({ user, dateFilters }: ITopTrafficTypesCard): JSX.Element => {
  const [data, setData] = React.useState<TopTrafficTypesCardItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    prepareTrafficData(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dateFilters]);

  const prepareTrafficData = (user: string) => {
    setLoading(true);
    Api.get_analytics(
      "/surfwize/analytics/traffic/typesandwebsites?filter_user=" + user,
      (data: TopTrafficCardAnalyticsApiCallResponse) => {
        const sortedData = topTrafficTypesCardSorter(data);
        setData(sortedData);
        setLoading(false);
      },
      (err: unknown) => {
        // How do we want to handle API errors on the front-end?
        console.error("An error occurred fetching series data from the API. Error reported:", err);
        setLoading(false);
      },
      { dateOverride: dateFilters }
    );
  };

  return (
    <Box background="neutrals.0" paddingX="sp16" paddingY="sp24" marginBottom="sp16" borderRadius="sm">
      <Flex marginBottom="sp12">
        <Text fontSize="lg" color="neutrals.900">
          Top Traffic Types
        </Text>
        <Spacer />
        <Link to={"/surfwize/reporting/types?filter_user=" + user}>
          <Text color="brand.500" fontSize="sm">
            View all
          </Text>
        </Link>
      </Flex>
      {loading && <CardSpinner />}
      {!loading &&
        data.map((item, index) => (
          <Box key={item.name}>
            <Flex p="sp8" background={index % 2 === 0 ? "neutrals.20" : "neutrals.0"}>
              <Link to={itemLink(item) + user}>
                <Text color="brand.500" fontSize="md">
                  {item.name}
                </Text>
              </Link>
              <Spacer />
              <Text>
                <AmountField amount={item.upload + item.download} />
              </Text>
            </Flex>
          </Box>
        ))}
    </Box>
  );
};

export default TopTrafficTypesCard;

const itemLink = (item: TopTrafficTypesCardItem): string => {
  return item.signature ? `/surfwize/reporting/type/${item.tag}/user/` : `/surfwize/reporting/website/${item.website}/user/`;
};

export type TopTrafficTypesCardItem = {
  all_domains: string[];
  categoryId: string;
  "categoryId.string": string;
  day: string;
  domain: string;
  download: number;
  hits: number;
  name: string;
  signature: boolean;
  subCategoryId: string;
  "subCategoryId.string": string;
  tag: string;
  "tag.string": string;
  transfer: number;
  upload: number;
  website: string;
};

export type TopTrafficCardAnalyticsApiCallResponse = {
  data: {
    upload: number;
    download: number;
    hits: number;
    transfer: number;
    domain: string;
    website: string;
    categoryId: string;
    subCategoryId: string;
    tag: string;
    day: string;
    all_domains?: string[];
    "categoryId.string"?: string;
    "subCategoryId.string"?: string;
    "tag.string"?: string;
    name?: string;
    signature?: boolean;
  }[];
};
