import React from "react";
import { MultiSearchSelector, Option, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";

export interface GenericMultiStringSelectInputCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  namedValue: string;
  options: Option[];
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const GenericMultiStringSelectInputCriteria: React.FC<GenericMultiStringSelectInputCriteriaProps> = ({
  criteria,
  disabled,
  namedValue,
  options,
  onChange,
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);

  const handleChangeInput = (option: Option[]) => {
    const newOption = option.map((o) => o.value);
    setSelectedOptions(option);
    onChange({ ...criteria, conditions: newOption });
  };

  React.useMemo(() => {
    if (criteria?.conditions && Array.isArray(criteria.conditions)) {
      const selected: Option[] = criteria.conditions.map((item) => {
        return { value: item, label: options.find((o) => o.value === item)?.label };
      });
      setSelectedOptions(selected);
    }
  }, [criteria.conditions, options]);

  return (
    <MultiSearchSelector
      id={`${namedValue}-input-id`}
      disabled={disabled}
      placeholder={t("Make a selection or type to filter")}
      name={namedValue}
      options={options}
      selected={selectedOptions}
      onChange={handleChangeInput}
    />
  );
};

export default GenericMultiStringSelectInputCriteria;
