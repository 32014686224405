import { Box, Flex, Modal, useTranslation, Text, Button } from "@familyzone/component-library";
import React from "react";

interface DeleteObjectPoolsModalProps {
  open: boolean;
  id: string | undefined;
  onClose: () => void;
  loaded: boolean;
  type: string;
  onDelete: (id: string) => void;
}

const DeleteObjectPoolsModal: React.FC<DeleteObjectPoolsModalProps> = ({ open, onClose, id, loaded, type, onDelete }) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    if (!loaded) {
      return;
    }
    if (!id) {
      return;
    }
    onDelete(id);
  };

  return (
    <Modal
      isOpen={open}
      onClose={loaded ? onClose : () => ""}
      size="sm"
      headerText={type === "objectpool" ? t("Delete Object Pool Confirmation") : t("Delete Entry Confirmation")}
    >
      <Box>
        <Flex direction="column">
          <Flex pt="sp16">
            <Text>{t(`Are you sure you want to delete this ${type === "objectpool" ? "Object Pool" : "Entry"}?`)}</Text>
          </Flex>
          <Flex direction="row" justifyContent="space-between" pt="sp16">
            <Flex>
              <Button onClick={handleDelete} disabled={!loaded} variant="danger" aria-label="delete">
                {t("Delete")}
              </Button>
            </Flex>
            <Flex>
              <Button onClick={onClose} disabled={!loaded} aria-label="cancel">
                {t("Cancel")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};

export default DeleteObjectPoolsModal;
