import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MultiSearchSelector, Option, useTranslation } from "@familyzone/component-library";
import { getDirectives } from "./ApiHelpers";
import useIsMounted from "../../utils/hooks/useIsMounted";

interface DirectivesSelectorProps {
  value: string[];
  disabled?: boolean;
  onChange: (newValue: string[]) => void;
}

const DirectivesSelector: React.FC<DirectivesSelectorProps> = ({ value, disabled, onChange }) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const placeholderText = t("Select content modification type");

  const [directives, setDirectives] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleChangeDirectives = (selected: Option[]) => {
    onChange(selected.map((s) => `${s.value}`));
  };

  const populateDirectives = useCallback(async () => {
    try {
      setLoading(true);
      const newDirectives = await getDirectives();
      if (!isMounted()) return;
      setDirectives(newDirectives.map((d) => ({ value: d.id, label: d.name, icon: d.favicon_url })));
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted]);

  useEffect(() => {
    // The .catch is required because of eslint@typescript-eslint/no-floating-promises
    populateDirectives().catch(() => "");
  }, [populateDirectives]);

  const selectedDirectives: Option[] = useMemo(
    // o is Option is needed to tell TS that undefined types are filtered out by the filter function !!o
    () => value.map((v) => directives.find((d) => d.value === v)).filter((o): o is Option => !!o),
    [value, directives]
  );

  return (
    <MultiSearchSelector
      disabled={loading || disabled}
      name="content-modification-selector"
      placeholder={placeholderText}
      options={directives}
      selected={selectedDirectives}
      onChange={handleChangeDirectives}
    />
  );
};

export default DirectivesSelector;
