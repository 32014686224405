import React from "react";
import Link from "../Link";
import TableBasedPage from "../templates/TableBasedPage";
import { Image, Td, Text, Tr, useTranslation } from "@familyzone/component-library";

export interface IData {
  thumbnail: string[];
  title: string;
  user_count: number;
  videoid: string;
  views: number;
}

interface IVideoDashboardProps {
  data: IData[];
  loaded: boolean;
  filters: string;
}

const VideoDashboardNew = ({ data, loaded, filters }: IVideoDashboardProps): JSX.Element => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Cyber Safety"), url: "/cybersafety/dashboard", isActive: false },
    { title: t("Videos"), isActive: true },
  ];

  const columns = [];
  columns.push({
    headerText: t("Videos"),
    columnName: "videos",
    sortable: false,
    searchable: false,
    exportable: false,
  });
  columns.push({
    headerText: t("Title"),
    columnName: "title",
    sortable: true,
    searchable: true,
    exportable: true,
  });
  columns.push({
    headerText: t("Views"),
    columnName: "views",
    sortable: true,
    searchable: true,
    exportable: true,
  });
  columns.push({
    headerText: t("Users"),
    columnName: "user_count",
    sortable: true,
    searchable: true,
    exportable: true,
  });

  const dataMap = (row: IData, index: number) => {
    return (
      <Tr key={index}>
        <Td>
          <Image alt="thumbnail_image" src={row.thumbnail[0]} borderRadius="sm" />
        </Td>
        <Td>
          <Link to={`/cybersafety/video/${row.videoid}?${filters}`}>
            <Text color="brand.500" fontSize="md">
              {row.title}
            </Text>
          </Link>
        </Td>
        <Td>
          <Text fontSize="md">{row.views}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{row.user_count}</Text>
        </Td>
      </Tr>
    );
  };

  return (
    <TableBasedPage title={t("Videos")} breadcrumbs={breadcrumbs} columns={columns} data={data} tableDataMap={dataMap} loaded={loaded} />
  );
};

export default VideoDashboardNew;
