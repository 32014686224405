import React from "react";
import Api from "../../../utils/Api";
import SearchableSelect from "../../../modules/SearchableSelect";
import SessionStore from "../../../stores/SessionStore";

import { SyncStatus } from "./SyncStatus";
import { Box, Button, Link, Text } from "@familyzone/component-library";

export default class LdapConfiguration extends React.Component {
  constructor(props, context) {
    super(props, context);
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
    const device = SessionStore.getSelectedDevice();
    this.state = {
      enabled: false,
      sync_enabled: false,
      sync_ous: false,
      sync_by_dn: false,
      type: "",
      hostname: "",
      port: "",
      basedn: "",
      ldapusername: "",
      ldappassword: "",
      search_query: "(objectClass=*)",
      userownedgroupmembership: false,
      usernameattribute: "",
      managerattribute: "",
      emailattribute: "",
      userbase: "",
      usergroupattribute: "",
      userdnattribute: "",
      groupdnattribute: "",
      oudnattribute: "",
      ounameattribute: "",
      authenticateuserusingsupplieddn: false,
      groupbase: "",
      groupnameattribute: "",
      groupmembernameattribute: "",
      loaded: false,
      saving: false,
      changes: true,
      device: device,
      lockout: manuallySetRunningSyncs[device.concat("_LDAPLockout")],
    };

    this.handle_load();
  }

  notifyChanged = () => {
    this.setState({ changes: true });
  };

  handle_ChangeEnabled = (event) => {
    this.notifyChanged();
    this.setState({
      enabled: event.target.checked,
    });
  };

  handle_ChangeSync = (event) => {
    this.notifyChanged();
    this.setState({
      sync_enabled: event.target.checked,
    });
  };

  handle_ChangeSyncOUs = (event) => {
    this.setState({
      sync_ous: event.target.checked,
      changed: true,
    });
  };

  handle_ChangeSyncByDN = (event) => {
    this.setState({
      sync_by_dn: event.target.checked,
      changed: true,
    });
  };

  handle_ChangeAuthenticateUserUsingDn = (event) => {
    this.notifyChanged();
    this.setState({
      authenticateuserusingsupplieddn: event.target.checked,
    });
  };

  handle_ChangeMode = (event) => {
    this.notifyChanged();
    this.setState({
      type: event.value,
    });
  };

  handle_ChangeUserOwnedGroupMembership = (event) => {
    this.notifyChanged();
    this.setState({
      userownedgroupmembership: event.target.checked,
    });
  };

  handle_ChangeUserNameAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      usernameattribute: event.target.value,
    });
  };

  handle_ChangeManagerAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      managerattribute: event.target.value,
    });
  };

  handle_ChangeEmailAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      emailattribute: event.target.value,
    });
  };

  handle_ChangeUserBase = (event) => {
    this.notifyChanged();
    this.setState({
      userbase: event.target.value,
    });
  };

  handle_ChangeUserGroupAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      usergroupattribute: event.target.value,
    });
  };

  handle_ChangeUserDnAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      userdnattribute: event.target.value,
    });
  };

  handle_ChangeGroupDnAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      groupdnattribute: event.target.value,
    });
  };

  handle_ChangeOuDnAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      oudnattribute: event.target.value,
    });
  };

  handle_ChangeOuNameAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      ounameattribute: event.target.value,
    });
  };

  handle_ChangeGroupBase = (event) => {
    this.notifyChanged();
    this.setState({
      groupbase: event.target.value,
    });
  };

  handle_ChangeGroupNameAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      groupnameattribute: event.target.value,
    });
  };

  handle_ChangeGroupMemberNameAttribute = (event) => {
    this.notifyChanged();
    this.setState({
      groupmembernameattribute: event.target.value,
    });
  };

  handle_ChangeHostname = (event) => {
    this.notifyChanged();
    this.setState({
      hostname: event.target.value,
    });
  };

  handle_ChangePort = (event) => {
    this.notifyChanged();
    const port = parseInt(event.target.value);
    if (!isNaN(port)) {
      this.setState({
        port: parseInt(event.target.value),
      });
    } else {
      this.setState({ port: "" });
    }
  };
  handle_ChangeBaseDN = (event) => {
    this.notifyChanged();
    this.setState({
      basedn: event.target.value,
    });
  };
  handle_ChangeUsername = (event) => {
    this.notifyChanged();
    this.setState({
      ldapusername: event.target.value,
    });
  };
  handle_ChangePassword = (event) => {
    this.notifyChanged();
    this.setState({
      ldappassword: event.target.value,
    });
  };
  handle_ChangeSearchQuery = (event) => {
    let value = event.target.value ? event.target.value : "(objectClass=*)";
    this.setState({
      search_query: value,
      changed: true,
    });
  };

  handle_load = () => {
    let self = this;
    Api.get("/config/ajax/authentication/ldap", function (result) {
      let configuration = result["configuration"];
      self.setState({
        enabled: configuration["enabled"],
        sync_enabled: configuration["sync_enabled"],
        sync_ous: configuration["sync_ous"],
        sync_by_dn: configuration["sync_by_dn"],
        type: configuration["type"],
        hostname: configuration["hostname"],
        port: configuration["port"],
        basedn: configuration["basedn"],
        ldapusername: configuration["ldapusername"],
        ldappassword: configuration["ldappassword"],
        search_query: configuration["search_query"],
        userownedgroupmembership: configuration["userownedgroupmembership"],
        usernameattribute: configuration["usernameattribute"],
        managerattribute: configuration["managerattribute"],
        userbase: configuration["userbase"],
        usergroupattribute: configuration["usergroupattribute"],
        userdnattribute: configuration["userdnattribute"],
        groupdnattribute: configuration["groupdnattribute"],
        oudnattribute: configuration["oudnattribute"],
        ounameattribute: configuration["ounameattribute"],
        authenticateuserusingsupplieddn: configuration["authenticateuserusingsupplieddn"],
        groupbase: configuration["groupbase"],
        groupnameattribute: configuration["groupnameattribute"],
        groupmembernameattribute: configuration["groupmembernameattribute"],
        emailattribute: configuration["emailattribute"],
        status: result["status"],
        loaded: true,
      });
    });
  };

  handle_Submit = (event) => {
    event.preventDefault();

    let self = this;
    let object = {
      enabled: this.state.enabled,
      sync_enabled: this.state.sync_enabled,
      sync_ous: this.state.sync_ous,
      sync_by_dn: this.state.sync_by_dn,
      type: this.state.type,
      hostname: this.state.hostname,
      port: this.state.port,
      basedn: this.state.basedn,
      ldapusername: this.state.ldapusername,
      ldappassword: this.state.ldappassword,
      search_query: this.state.search_query,
      userownedgroupmembership: this.state.userownedgroupmembership,
      usernameattribute: this.state.usernameattribute,
      managerattribute: this.state.managerattribute,
      userbase: this.state.userbase,
      usergroupattribute: this.state.usergroupattribute,
      userdnattribute: this.state.userdnattribute,
      groupdnattribute: this.state.groupdnattribute,
      oudnattribute: this.state.oudnattribute,
      ounameattribute: this.state.ounameattribute,
      authenticateuserusingsupplieddn: this.state.authenticateuserusingsupplieddn,
      groupbase: this.state.groupbase,
      groupnameattribute: this.state.groupnameattribute,
      groupmembernameattribute: this.state.groupmembernameattribute,
      emailattribute: this.state.emailattribute,
    };

    self.setState({ changes: false, saving: true });

    Api.post(
      "/config/ajax/authentication/ldap",
      object,
      function (e) {
        self.handle_load();
        self.setState({ saving: false });
      },
      function (error) {}
    );
  };

  handle_Sync = () => {
    let self = this;
    if (self.state.status.ldap_sync_state !== "running") {
      self.state.status.ldap_sync_state = "sync_triggered";
      self.setState({ loaded: false });

      Api.post(
        "/config/ajax/authentication/ldap/sync",
        {},
        function (e) {
          self.handle_load();
          const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
          const now = new Date();
          manuallySetRunningSyncs[self.state.device.concat("_LDAPLockout")] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
          localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
        },
        function (error) {}
      );
    }
  };

  render__RequiredOptions = () => {
    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Enabled</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.enabled} onChange={this.handle_ChangeEnabled} data-cy="enabled" />
            </div>
          </div>
        </div>

        {this.render_GeneralOptions()}
      </div>
    );
  };

  render_GeneralOptions = () => {
    if (!this.state.enabled) {
      return <div />;
    }

    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Sync Enabled</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.sync_enabled} onChange={this.handle_ChangeSync} data-cy="syncEnabled" />
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Sync Organization Units</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.sync_ous} onChange={this.handle_ChangeSyncOUs} data-cy="syncOrgUnits" />
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Server Type</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="server-type-search">
                <SearchableSelect
                  value={this.state.type}
                  clearable={false}
                  className={"server-type-search"}
                  onChange={this.handle_ChangeMode}
                  valueDataMap={[
                    ["ad", "Active Directory"],
                    ["ol", "OpenLdap"],
                    ["ed", "Novell eDirectory"],
                    ["custom", "Manual"],
                  ]}
                  data-cy="serverType"
                ></SearchableSelect>
              </div>
            </div>
          </div>
        </div>

        <div className="formgroup-element">
          <div className="formgroup-element-title">Server Hostname</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="mui-textfield">
                <input type="text" value={this.state.hostname} onChange={this.handle_ChangeHostname} data-cy="serverHostname" />
              </div>
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Server Port</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="mui-textfield">
                <input type="text" value={this.state.port} onChange={this.handle_ChangePort} data-cy="serverPort" />
              </div>
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Base DN</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="mui-textfield">
                <input type="text" value={this.state.basedn} onChange={this.handle_ChangeBaseDN} data-cy="baseDN" />
              </div>
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Username</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="mui-textfield">
                <input type="text" value={this.state.ldapusername} onChange={this.handle_ChangeUsername} data-cy="username" />
              </div>
            </div>
          </div>
        </div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Password</div>
          <div className="formgroup-element-fields">
            <div className="formgroup-element-fields-inline">
              <div className="mui-textfield">
                <input type="text" value={this.state.ldappassword} onChange={this.handle_ChangePassword} data-cy="password" />
                {/* We use text instead of password as input-type because the ldap-password has been obfuscated by the back-end.
                            It looks like *****1q2w*/}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="formgroup-element-title">Search Query</div>
        <div className="mui-textfield">
          <input type="text" value={this.state.search_query} onChange={this.handle_ChangeSearchQuery} data-cy="searchQuery" />
        </div>
        <div className="formgroup-element">
          <em>
            <div>Syncing by DN allows you to sync multiple groups with the same name.</div>
            <div>Changing this can invalidate existing filtering rules.</div>
          </em>
          <div className="formgroup-element-title">Sync By DN</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.sync_by_dn} onChange={this.handle_ChangeSyncByDN} data-cy="syncByDN" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render__ManualOptions = () => {
    if (this.state.type === "custom") {
      return (
        <div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> User Owned Group Membership</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.userownedgroupmembership}
                  onChange={this.handle_ChangeUserOwnedGroupMembership}
                />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> Username Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.usernameattribute} onChange={this.handle_ChangeUserNameAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> Manager Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.managerattribute} onChange={this.handle_ChangeManagerAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Email Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.emailattribute} onChange={this.handle_ChangeEmailAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> User Base</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.userbase} onChange={this.handle_ChangeUserBase} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> User Group Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.usergroupattribute} onChange={this.handle_ChangeUserGroupAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> User DN Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.userdnattribute} onChange={this.handle_ChangeUserDnAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> Group DN Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.groupdnattribute} onChange={this.handle_ChangeGroupDnAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> OU DN Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.oudnattribute} onChange={this.handle_ChangeOuDnAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> OU Name Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.ounameattribute} onChange={this.handle_ChangeOuNameAttribute} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> Authenticate with User DN</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.authenticateuserusingsupplieddn}
                  onChange={this.handle_ChangeAuthenticateUserUsingDn}
                />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title"> Group Base</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.groupbase} onChange={this.handle_ChangeGroupBase} />
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Group Name Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.groupnameattribute} onChange={this.handle_ChangeGroupNameAttribute} />
                </div>
              </div>
            </div>
          </div>

          <div className="formgroup-element">
            <div className="formgroup-element-title">Group Member Name Attribute</div>
            <div className="formgroup-element-fields">
              <div className="formgroup-element-fields-inline">
                <div className="mui-textfield">
                  <input type="text" value={this.state.groupmembernameattribute} onChange={this.handle_ChangeGroupMemberNameAttribute} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  };

  render() {
    return (
      <Box display={"flex"} flex={1} padding={"24px"} gap={"16px"}>
        <Box flex={1}>
          <Box background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"1rem"} borderRadius={"6px"}>
            <Box borderBottom={"1px solid #e2e2e2"} padding={"20px"} display={"flex"} flexDir={"column"} gap={"1rem"}>
              <Text fontSize={"24px"}>LDAP</Text>
              <Link isExternal href={"https://help.linewize.com/hc/en-gb/articles/5732871324444"}>
                Configuring LDAP Integration Guide
              </Link>
            </Box>
            <Box>
              <form className="mui-form">
                <div className="formgroup">
                  <div className="formgroup-content">
                    {this.render__RequiredOptions()}
                    {this.render__ManualOptions()}
                  </div>
                </div>
              </form>
            </Box>
            <Box padding={"24px"} background={"#F5FCFF"}>
              <Button onClick={this.handle_Submit} disabled={!this.state.changes || this.state.saving} variant="primary">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        {this.state.enabled && (
          <Box width={"500px"} gap={"16px"} display={"flex"} flexDir={"column"}>
            <SyncStatus
              handleRunSync={this.handle_Sync}
              syncLockoutTime={
                JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[this.state.device.concat("_LDAPLockout")]
                  ? JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[this.state.device.concat("_LDAPLockout")]
                  : undefined
              }
              syncType={"LDAPS"}
            />
          </Box>
        )}
      </Box>
    );
  }
}
