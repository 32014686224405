import alt from "../alt";

export class GlobalDatePickerVisibilityActions {
  showGlobalDatePicker() {
    return true;
  }

  hideGlobalDatePicker() {
    return true;
  }
}

export default alt.createActions(GlobalDatePickerVisibilityActions);
