import React from "react";
import Criteria from "./Criteria";

export default class MacAddressCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = {};
    }

    this.state = {
      criteria: props.criteria,
    };
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = {};
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_MacAddressChange = (event) => {
    this.state.criteria.conditions.mac = event.target.value;
    this.props.bubbleMethod(this.state.criteria);
  };

  render_ValidationError = (errorMessage) => {
    return <div className="errorMessage">{errorMessage}</div>;
  };

  render() {
    return (
      <Criteria label="Mac Address" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-textfield">
          <input
            type="text"
            onChange={this.handle_MacAddressChange}
            value={this.state.criteria.conditions.mac || ""}
            placeholder="Mac Address"
          />
        </div>
        <div>{this.render_ValidationError(this.props.criteria.errorMessage || "")}</div>
      </Criteria>
    );
  }
}
