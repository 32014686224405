import React from "react";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import { Box, Icon, Tooltip, withTranslation } from "@familyzone/component-library";
import { zIndices } from "../../utils/ZIndexUtil";

class DropDownDeviceAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alerts: 0 };
  }

  componentDidMount() {
    DeviceStatusStore.listen(this.onChange);
  }

  componentWillUnmount() {
    DeviceStatusStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      alerts: DeviceStatusStore.getRecentAlerts(),
    });
  };

  render() {
    const { t } = this.props;
    return (
      <AuthorizationWrapper allowedRoles={this.props.allowedRoles}>
        {this.state.alerts > 0 && (
          <Tooltip label={`${this.state.alerts} ${t("Alarms")}`} placement="right" variant="dark" zIndex={zIndices.tooltip}>
            <Box
              onClick={this.props.onClick}
              _hover={{ cursor: this.props.onClick ? "pointer" : "default" }}
              height="fit-content"
              mr="sp8"
              lineHeight="0"
              borderRadius="sm"
              bg="accent.orange.100"
              p="sp12"
            >
              <Icon boxSize="16px" variant="solid" color="accent.orange.400" icon="fa-bell-exclamation" />
            </Box>
          </Tooltip>
        )}
      </AuthorizationWrapper>
    );
  }
}

export default withTranslation()(DropDownDeviceAlerts);
