import { Button, Flex, MultiSelect, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import "../../../css/ReportingWelfare.css";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import RiskSelectorActions from "../../actions/RiskSelectorActions";
import SignatureTreeActions from "../../actions/SignatureTreeActions";
import { CSVExportButton } from "../../modules/ExportReport";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import RiskSelectorStore from "../../stores/RiskSelectorStore";
import SignatureTreeStore from "../../stores/SignatureTreeStore";
import { useReportingWelfareStore } from "../../storez/MultiSelectStores";
import { useUserStore } from "../../storez/UserStore";
import { zIndices } from "../../utils/ZIndexUtil";
import Link from "../Link";
import TableBasedPage from "../templates/TableBasedPage";
import { fullNameOrUsername } from "../UserSearch/UserSearchHelper";
import RedFlags from "./RedFlags";

const ReportingWelfare = (props, context) => {
  const { t } = useTranslation();
  const FilterStore = useDateRangeFilterStore();
  const breadcrumbs = [
    { title: t("Cyber Safety"), url: "/cybersafety", isActive: false },
    { title: t("Red Flags"), url: "/cybersafety/wellbeing", isActive: true },
  ];

  const columns = [
    {
      headerText: t("Username"),
      columnName: "name",
      sortable: true,
      searchable: true,
      exportable: true,
      exportData: function (row) {
        return row["name"];
      },
    },
    {
      headerText: t("Full Name"),
      columnName: "full_name",
      sortable: true,
      searchable: true,
      exportable: true,
      exportData: function (row) {
        return row["full_name"];
      },
    },
    {
      headerText: t("Risk Indicators"),
      columnName: "riskIndicators",
      sortable: false,
      searchable: true,
      exportable: true,
      exportData: function (row) {
        return exportRiskyBusiness(row);
      },
    },
  ];

  const [data, setData] = useState([]);
  const [, setTotals] = useState({
    suicidal_search_term: 0,
    adult_content_hit: 0,
    offensive_hit: 0,
    vpn_hit: 0,
    devices: 0,
  });
  const [atRiskUsers, setAtRiskUsers] = useState([]);
  const [userRiskSelectorScore, setUserRiskSelectorScore] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [tags, setTags] = useState(useReportingWelfareStore.getState().tags || []);
  const [allOptions, setAllOptions] = useState([]);

  const labelMap = {
    suicidal_search_term: "Suicide",
    adult_content_hit: "Adult Content",
    offensive_hit: "Offensive Content",
    vpn_hit: "VPN",
    devices: "Devices",
  };

  const [getUsersAsMap] = useUserStore(useCallback((state) => [state.getUsersAsMap], []));

  const populateUserDetails = async (atRiskUsers) => {
    const usernames = atRiskUsers.filter((user) => !user.full_name).map((user) => user.name);

    if (usernames.length === 0) {
      return;
    }

    const usersMap = await getUsersAsMap(usernames);

    atRiskUsers.forEach((user) => {
      const storeUser = usersMap.get(user.name);
      if (storeUser) {
        user.full_name = fullNameOrUsername(storeUser);
      }
    });
  };

  const handleLoad = async () => {
    setLoaded(false);
    const allDataLoaded =
      RiskSelectorStore.getLoaded() && (SignatureTreeStore.getKeywords().length > 0 || SignatureTreeStore.getSignatures().length > 0);
    if (allDataLoaded) {
      const atRiskUsers = RiskSelectorStore.getAtRiskUsers();
      await populateUserDetails(atRiskUsers);
      setAllOptions(makeFilterOptions(atRiskUsers));
      setTotals(RiskSelectorStore.getTotals());
      setAtRiskUsers(atRiskUsers);
      setUserRiskSelectorScore(RiskSelectorStore.getUserRiskSelectorScore());
      setLoaded(true);
    }
  };

  useEffect(() => {
    useReportingWelfareStore.setState({ tags });
  }, [tags]);

  useEffect(() => {
    let filteredData = atRiskUsers;
    if (tags.length !== 0) {
      filteredData = atRiskUsers.filter(({ normals }) => {
        let included = false;
        for (const filter of tags) {
          const key = filter.value;
          if (normals[key] > 0) {
            return true;
          }
        }
        return included;
      });
    }
    setData(filteredData);
  }, [atRiskUsers, tags]);

  useEffect(() => {
    if (props.location?.query && props.location.query.startDate && props.location.query.endDate)
      FilterStore.updateDateFilter({
        startDate: props.location.query.startDate,
        endDate: props.location.query.endDate,
        startTime: props.location.query.startTime ? props.location.query.startTime : "00:00:00",
        endTime: props.location.query.endTime ? props.location.query.endTime : "23:59:59",
        relativeKey: "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.query]);

  useEffect(() => {
    const unsubFilterStore = useDateRangeFilterStore.subscribe(onFilterChange);
    RiskSelectorStore.listen(handleLoad);
    SignatureTreeStore.listen(handleLoad);

    setTimeout(() => {
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
      RiskSelectorActions.fetch();
      SignatureTreeActions.fetch();
    }, 0);

    return () => {
      unsubFilterStore();
      RiskSelectorStore.unlisten(handleLoad);
      SignatureTreeStore.unlisten(handleLoad);
      RiskSelectorActions.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChange = () => {
    setTimeout(() => {
      RiskSelectorActions.fetch();
    }, 0);
  };

  const makeFilterOptions = (atRiskUsers) => {
    const keywords = SignatureTreeStore.getKeywords();

    const options = {};

    for (const item of atRiskUsers) {
      for (const redFlagKey of Object.keys(item.normals)) {
        if (options[redFlagKey]) {
          continue;
        }

        if (item.normals[redFlagKey] === 0) {
          continue;
        }

        const keyword = keywords.find((x) => x.id === redFlagKey);
        if (keyword) {
          if (keyword.name !== "Adult Content") {
            options[redFlagKey] = keyword.name;
            continue;
          } else {
            options[redFlagKey] = "Adult Content Search";
            continue;
          }
        }

        if (labelMap[redFlagKey]) {
          options[redFlagKey] = labelMap[redFlagKey];
        }
      }
    }

    return Object.entries(options);
  };

  // NOTE: The local functions inside could likely be abstracted to a utility (or at least parts of the functionality)
  const exportRiskyBusiness = (row) => {
    // Find out what data we are going to be outputting - which risks does the user have
    const _riskySearches = riskySearches();
    const _riskyUrlHits = riskyUrlHits();
    const _riskyTampering = riskyTampering();

    let risksCsvValue = '"';

    let didOutputAnyGroup = false;
    if (_riskySearches.length > 0) outputGroupedValues(_riskySearches, "Search hits");
    if (_riskyUrlHits.length > 0) outputGroupedValues(_riskyUrlHits, "URL hits");
    if (_riskyTampering.length > 0) outputGroupedValues(_riskyTampering, "Tampering hits");

    if (didOutputAnyGroup) risksCsvValue = risksCsvValue.substring(0, risksCsvValue.length - 2);
    risksCsvValue += '"';

    return risksCsvValue;

    function outputGroupedValues(entries, identifier) {
      risksCsvValue += `[${identifier}: `;

      for (const entry of entries) {
        const key = Object.keys(entry)[0];
        risksCsvValue += `${key}=${entry[key]}, `;
      }

      risksCsvValue = risksCsvValue.substring(0, risksCsvValue.length - 2);
      risksCsvValue += "], ";

      didOutputAnyGroup = true;
    }

    function riskySearches() {
      const search_indicators = row.search_indicators;
      const _riskySearches = [];

      for (const property in search_indicators) {
        const value = search_indicators[property];

        if (value > 0) {
          const formattedProperty = property.replace("sphirewall.keyword.", "");
          _riskySearches.push({ [formattedProperty]: value });
        }
      }
      return _riskySearches;
    }

    function riskyUrlHits() {
      const targetProperties = ["adult_content_hit", "offensive_hit"];
      const _riskyUrlHits = [];

      for (const x of targetProperties) {
        if (row.hasOwnProperty(x) && row[x] > 0) {
          const formattedProperty = x.replace("_hit", "");
          _riskyUrlHits.push({ [formattedProperty]: row[x] });
        }
      }
      return _riskyUrlHits;
    }

    function riskyTampering() {
      const targetProperties = ["vpn_hit"];
      const _riskyTampering = [];

      for (const x of targetProperties) {
        if (row.hasOwnProperty(x) && row[x] > 0) {
          const formattedProperty = x.replace("_hit", "");
          _riskyTampering.push({ [formattedProperty]: row[x] });
        }
      }
      return _riskyTampering;
    }
  };

  const onClickResetFilters = () => {
    setTags([]);
  };

  const dataMap = (row, index) => {
    const url = `/surfwize/reporting/users/${row["name"]}`;
    return (
      <Tr key={index}>
        <Td>
          <Link fontSize="14px" to={url}>
            {row["name"]}
          </Link>
        </Td>
        <Td>
          {row.full_name ? (
            <Link fontSize="14px" to={url}>
              {row.full_name}
            </Link>
          ) : (
            <Text color="text.paragraph.light">{t("N/A")}</Text>
          )}
        </Td>
        <Td>
          <RedFlags
            indicatorClassname={"smallRiskStudentIndicator"}
            divClassName={"smallStudentRiskIndicators"}
            data={row}
            userRiskSelectorScore={userRiskSelectorScore}
          />
        </Td>
      </Tr>
    );
  };

  const getMultiSelectValues = () => {
    let ret = [];
    for (let tag of allOptions) {
      ret.push({
        value: tag[0],
        text: tag[1],
      });
    }
    return ret;
  };

  const searchGroups = () => {
    return (
      <Flex direction="row" ml="sp24" mt="sp24" zIndex={zIndices.multiSelect}>
        <MultiSelect
          showPills={true}
          label=""
          placeholder="Select filter tag"
          selectedItems={tags}
          items={getMultiSelectValues()}
          onChange={(newTags) => {
            setTags(() => newTags);
          }}
          canClearAll={false}
        />
        <Button variant="ghost" onClick={onClickResetFilters}>
          Reset Filters
        </Button>
      </Flex>
    );
  };

  return (
    <TableBasedPage
      title={t("Red Flags")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={data}
      tableDataMap={dataMap}
      loaded={loaded}
      children={searchGroups()}
      tableTopMargin={"sp24"}
      childrenInTableHeader={<CSVExportButton columns={columns} formattedData={data} />}
      searchInput={props.router.location.state?.default_filter ?? undefined}
    />
  );
};

export default ReportingWelfare;
ReportingWelfare.contextTypes = {
  router: PropTypes.object.isRequired,
};
