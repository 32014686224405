import { Text } from "@chakra-ui/layout";
import { Box, Flex } from "@familyzone/component-library";
import React from "react";
import NoContentIcon from "./NoContentIcon";

interface NoContentDisplayProps {
  title: string;
  text: string;
  children?: React.ReactNode;
}

const NoContentDisplay: React.FC<NoContentDisplayProps> = ({ title, text, children }) => {
  return (
    <Flex width="100%" justifyContent="center" alignItems="center" backgroundColor="neutrals.10" minHeight="280px" borderRadius={16}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth="290px">
        <Box mb="sp24">
          <NoContentIcon />
        </Box>
        <Text textAlign="center" fontSize="large" mb="sp8" color="neutrals.900">
          {title}
        </Text>
        <Text textAlign="center" color="neutrals.400">
          {text}
        </Text>
        {children}
      </Flex>
    </Flex>
  );
};

export default NoContentDisplay;
