import React, { useMemo } from "react";
import { Box, Flex, Icon, Text, Td, Toggle, TableIconButton, DraggableTr, Tr } from "@familyzone/component-library";

import { DeleteButtonNew } from "../../modules/DeleteButtonNew";

import { FilteringRule } from "./types";

import PolicyPill from "./PolicyPill";

interface PolicyTableRowProps {
  rule: FilteringRule;
  id: string;
  draggable?: boolean;
  disabled?: boolean;
  onToggleEnabled: (e: React.SyntheticEvent) => void;
  onEdit: (e: React.SyntheticEvent) => void;
  onDelete: () => void;
}

const PolicyTableRow: React.FC<PolicyTableRowProps> = ({
  rule,
  draggable = false,
  id = "",
  disabled = false,
  onToggleEnabled = () => "",
  onEdit = () => "",
  onDelete = () => "",
}) => {
  // If draggable, make the component a draggable TR
  const TrComponent = useMemo(() => (draggable ? DraggableTr : Tr), [draggable]);

  return (
    <TrComponent id={id} backgroundColor="neutrals.0">
      {rule?.content_mod === undefined ? (
        <Td width="48px">
          <Box p="sp4" display="flex" justifyContent="center">
            <svg width="4" height="13" viewBox="0 0 4 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 5.01587C1.15625 5.01587 0.5 5.70337 0.5 6.51587C0.5 7.35962 1.15625 8.01587 2 8.01587C2.8125 8.01587 3.5 7.35962 3.5 6.51587C3.5 5.70337 2.8125 5.01587 2 5.01587ZM0.5 1.76587C0.5 2.60962 1.15625 3.26587 2 3.26587C2.8125 3.26587 3.5 2.60962 3.5 1.76587C3.5 0.953369 2.8125 0.265869 2 0.265869C1.15625 0.265869 0.5 0.953369 0.5 1.76587ZM0.5 11.2659C0.5 12.1096 1.15625 12.7659 2 12.7659C2.8125 12.7659 3.5 12.1096 3.5 11.2659C3.5 10.4534 2.8125 9.76587 2 9.76587C1.15625 9.76587 0.5 10.4534 0.5 11.2659Z"
                fill="#6B778C"
              />
            </svg>
          </Box>
        </Td>
      ) : (
        <></>
      )}
      <Td>
        <Toggle
          data-testid={`${rule.id}-toggle`}
          disabled={disabled}
          isChecked={rule.enabled}
          onChange={onToggleEnabled}
          aria-label="Enabled"
        />
      </Td>
      {rule?.content_mod === undefined ? (
        <Td>
          {/* Workaround for bug with icon not showing if the icon classname is updated */}
          <Box display={rule.action === 0 ? "inline-block" : "none"}>
            <PolicyPill type="block" />
          </Box>
          <Box display={rule.action === 1 ? "inline-block" : "none"}>
            <PolicyPill type="allow" />
          </Box>
          {rule.sticky ? (
            <Box pl="sp4" display="inline-block">
              <PolicyPill type="lock" />
            </Box>
          ) : (
            <></>
          )}
        </Td>
      ) : (
        <></>
      )}
      <Td>
        <Box maxWidth="300px">
          <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
            {rule.name}
          </Text>
        </Box>
      </Td>
      <Td>
        <Flex justifyContent="end">
          <Box mr="sp8">
            <TableIconButton
              data-testid={`${rule.id}-edit`}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              aria-label="Edit"
              onClick={onEdit}
              disabled={disabled}
            />
          </Box>
          <DeleteButtonNew data-testid={`${rule.id}-delete`} disabled={disabled} onClick={onDelete} />
        </Flex>
      </Td>
    </TrComponent>
  );
};

export default PolicyTableRow;
