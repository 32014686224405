import { Flex, Icon, Modal, TableIconButton, useTranslation, Text, TextInput, Button, Box, useToast } from "@familyzone/component-library";
import React, { ChangeEvent, useState } from "react";
import Api from "../utils/Api";
import { isValidIPAddress } from "../utils/Validation";

interface CreateTemporarySessionProps {
  username: string;
}

const CreateTemporarySession = ({ username }: CreateTemporarySessionProps): JSX.Element => {
  const { t } = useTranslation();
  const { successToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [ip, setIp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const ipError = "Please enter a valid IP address.";
  const sessionCreationError = "Unable to create session. Please try again.";

  const onIpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newIp = e.target.value;
    setIp(newIp);
    setError(isValidIPAddress(newIp) ? "" : ipError);
  };

  const isButtonDisabled = () => {
    return loading || ip === "" || error === ipError;
  };

  const onCreateSession = () => {
    const onApiSuccess = () => {
      setIsOpen(false);
      setLoading(false);
      successToast({
        title: "Success",
        description: "Temporary session created",
        isClosable: true,
      });
    };
    const onApiFailure = () => {
      setError(sessionCreationError);
      setLoading(false);
    };
    const postBody = {
      username: username,
      ip_address: ip,
    };
    setLoading(true);
    Api.post("/config/ajax/admin-login-session", postBody, onApiSuccess, onApiFailure);
  };

  return (
    <>
      <TableIconButton
        onClick={() => {
          setIsOpen(true);
        }}
        icon={<Icon icon={`fa-key`} variant="solid" color="text.paragraph.light" />}
        aria-label="Create Temporary Session"
      />
      <Modal size="sm" isOpen={isOpen} onClose={() => setIsOpen(false)} headerText={t("Create Temporary Session")}>
        <Text mb="sp24">{t("Allow a user to access your network.")}</Text>
        <Flex mb="sp24">
          <Text mr="sp4" fontWeight="semibold">{`${t("Username")}:`}</Text>
          <Text>{username}</Text>
        </Flex>
        <Box mb="sp24">
          <TextInput
            data-testid="create-temp-session-ip-input"
            label="IP Address"
            placeholder="Enter a valid IP address"
            value={ip}
            isRequired
            errorMessage={error}
            onChange={onIpChange}
          />
        </Box>
        <Button
          variant="primary"
          data-testid="create-session-button"
          onClick={onCreateSession}
          isLoading={loading}
          disabled={isButtonDisabled()}
        >
          Create Session
        </Button>
      </Modal>
    </>
  );
};

export default CreateTemporarySession;
