import React from "react";
import ModalWindow from "../../modules/ModalWindow";
import FlexibleTable from "../../modules/FlexibleTable";

import "../../../css/ConnectionInfoModal.css";

export default class ConnectionInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      connection_detail: {},
      visible: false,
      hide_empty: false,
    };
    // this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(nextprops) {
    let self = this;
    if (nextprops["connection"]) {
      let data = [];
      for (let connectionDetail in nextprops["connection"]) {
        let connectionValue = nextprops["connection"][connectionDetail];
        if (self.shouldPrintObject(connectionValue)) {
          data.push({
            detail: connectionDetail,
            value: connectionValue + "",
          });
        }
      }
      self.setState({
        connection_detail: data,
      });
    }
    self.setState({
      visible: nextprops.visible,
    });
  }

  shouldPrintObject(connectionValue) {
    if (this.state.hide_empty) {
      if (Array.isArray(connectionValue) && connectionValue.length === 0) {
        return false;
      }
      return !!(connectionValue + "");
    }
    return true;
  }

  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handleHide}>
        Close
      </button>
    );
  };

  render_Details = () => {
    let self = this;
    let columns = [
      {
        title: "Detail",
        data: function (row) {
          return row["detail"];
        },
        search: FlexibleTable.search__single_string_field("detail"),
        sortable: FlexibleTable.sortable__single_string_field("detail"),
      },
      {
        title: "Value",
        data: function (row) {
          return row["value"];
        },
        search: FlexibleTable.search__single_string_field("value"),
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.connection_detail}
        loaded={true}
        hide_headers={false}
        className={"connectionInfo"}
        modalSearch={true}
        buttons={[<div onClick={self.toggleHideEmpty.bind(self)}>{self.state.hide_empty ? "Show" : "Hide"} Empty</div>]}
      />
    );
  };

  toggleHideEmpty() {
    let self = this;
    self.setState(
      {
        hide_empty: !this.state.hide_empty,
      },
      function () {
        self.componentWillReceiveProps(self.props);
      }
    );
  }

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow
          title={this.props.title ? this.props.title : "Connection Details"}
          handleHide={this.handleHide}
          actions={this.renderActions()}
        >
          {this.render_Details()}
        </ModalWindow>
      );
    }

    return <div />;
  }
}
