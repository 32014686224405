import { Box, Button, Icon, Modal, Radio, RadioGroup, TextArea, Text } from "@familyzone/component-library";
import React from "react";
import CardSpinner from "../components/reporting/UserDashboard/CardSpinner";
import { CSVDownloader, CSVGenerator } from "../helpers/CSVExport";
import SessionStore from "../stores/SessionStore";
import { TableColumn } from "../types/table";
import Api from "../utils/Api";
import { getUserByUsername } from "../utils/api/Users";

interface Props {
  columns: TableColumn[];
  formattedData: unknown[];
  disabled?: boolean;
  buttonText?: string;
}

interface ExportReportModalProps {
  headerText: string;
  userId: string;
  disabled?: boolean;
  dateTimeParams?: string;
  typeFilter?: string[];
  resultFilter?: string[];
  categoryFilter?: string[];
  sortColumn: string;
  isSortAscending: boolean;
}

export const CSVExportButton: React.FC<Props> = ({ buttonText, columns, formattedData, disabled = false }) => {
  return (
    <Button
      aria-label="Export Report"
      variant="primary"
      data-testid="downloadCSVButton"
      onClick={() => CSVDownloader(CSVGenerator(columns, formattedData))}
      leftIcon={<Icon boxSize="12px" icon="fa fa-file-download" />}
      disabled={disabled || formattedData.length === 0}
    >
      {buttonText || "Export Report"}
    </Button>
  );
};

export const ExportReportModal: React.FC<ExportReportModalProps> = ({
  headerText,
  userId,
  dateTimeParams,
  typeFilter,
  resultFilter,
  categoryFilter,
  sortColumn,
  isSortAscending,
  disabled = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("pdf");
  const [comment, setComment] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState<boolean>();
  const [userFullName, setUserFullName] = React.useState<string>("");

  /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
  const recipient_email: string = SessionStore.getUsername();
  const device_name: string = SessionStore.getSelectedDeviceName();
  const queryString = typeof dateTimeParams === "string" && dateTimeParams.length > 0 ? `?${dateTimeParams}` : "";
  /* eslint-enable */

  React.useEffect(() => {
    if (userId !== "") {
      void getUserByUsername(userId)
        .then((user) => setUserFullName(user.firstName + " " + user.lastName))
        .catch(() => setUserFullName(userId)); // If the network call fails, just use the userId
    }
  }, [userId]);

  const submit = () => {
    setSubmitting(true);
    Api.post_analytics(
      `/reports/student-journey/users/${userId}/types/${type}${queryString}`,
      {
        user_full_name: userFullName,
        recipient_emails: [recipient_email],
        device_name: device_name,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        comment: comment.trim(),
        type_filter: typeFilter,
        result_filter: resultFilter,
        category_filter: categoryFilter,
        sort_column: sortColumn,
        sort_ascending: isSortAscending,
      },
      (_success: unknown) => {
        setSuccess(true);
        setSubmitting(false);
      },
      (_error: unknown) => {
        setSuccess(false);
        setSubmitting(false);
      }
    );
  };

  const ExportReportForm = () => {
    return (
      <>
        <Box my="sp16">
          <RadioGroup direction="row" value={type} name="exportType" onChange={(value) => setType(value)}>
            <Radio value="pdf">PDF (.pdf)</Radio>
            <Radio value="csv">CSV (.csv)</Radio>
          </RadioGroup>
        </Box>
        <Box my="sp16">
          <TextArea
            rows={5}
            label="Comments (optional)"
            placeholder="Enter details about the report"
            onChange={(e) => setComment(e.target.value)}
          />
        </Box>
      </>
    );
  };

  const buttonText = (): string => {
    if (submitting) return "Submitting... please wait.";
    if (success !== undefined) return "Ok, got it";
    else return "Export";
  };

  const submitAction = (): void => {
    if (success !== undefined) handleCloseModal();
    else submit();
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSuccess(undefined);
    setComment("");
    setType("pdf");
  };

  const CompletedText: React.FC = () => {
    return (
      <Box mt="sp16">
        {success ? (
          <>
            <Text fontWeight="bold" fontSize="xl">
              The report will be sent to your email
            </Text>
            <Text fontWeight="bold" fontSize="xl">
              {recipient_email} when it is ready.
            </Text>
            <Text mt="sp12">Large data ranges may take some time to process.</Text>
          </>
        ) : (
          <>
            <Text fontWeight="bold" fontSize="xl">
              Unable to export data
            </Text>
            <Text mt="sp12">
              Try requesting a smaller data range or refreshing your page. If the problem persists contact Linewize Support.
            </Text>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setOpen(true)}
        disabled={disabled}
        leftIcon={<Icon boxSize="12px" icon="fa fa-file-download" />}
      >
        Export Report
      </Button>
      <Modal
        size="md"
        isOpen={open}
        onClose={handleCloseModal}
        headerText={success === undefined ? headerText : undefined}
        primaryCTALabel={buttonText()}
        onPrimaryCTAClick={submitAction}
        primaryCTADisabled={type === "" || submitting}
      >
        <>
          {success === undefined && !submitting && ExportReportForm()}
          {success === undefined && submitting && <CardSpinner margin="sp96" />}
          {success !== undefined && (
            <Box my="16">
              <Icon
                icon={success ? "fa-light fa-circle-check" : "fa-solid fa-triangle-exclamation"}
                color={success ? "accent.green.400" : "accent.red.400"}
                boxSize="48px"
              />
              <CompletedText />
            </Box>
          )}
        </>
      </Modal>
    </>
  );
};
