import { Classroom, UserCMS } from "../../../types/User";
import { Classroom as ClassroomUms } from "../../../types/Classrooms";
import { LinkedGuardian } from "../../../types/Community";
import { Group } from "../../../pages/filtering/types";
import { Option } from "@familyzone/component-library";
import { UserUMS } from "../../../types/Users";
import { isUserObject } from "../../../utils/api/Users";

export const getOptionsFromGroups = (groups: Group[]): Option[] => {
  const nonArchivedGroups = groups.filter((group: Group) => !group.archived);
  const groupOptions = nonArchivedGroups.map((value) => {
    const option: Option = {
      label: value.desc && value.desc !== "" && value.desc !== value.name ? `${value.desc} (${value.name})` : value.name,
      value: value.name,
    };
    return option;
  });
  return groupOptions;
};

export const getOptionsFromSelectedGroups = (selected: string[]): Option[] => {
  return selected.map((value) => {
    const option: Option = {
      value: value,
      name: value,
    };
    return option;
  });
};

//This is only used in the old Manage User page
export const getSelectedGroupsFromOptions = (selected: Option[]): string[] => {
  const groups: string[] = selected.map((group) => {
    return String(group.name); //Necessary because value can either be a number or string
  });
  return groups;
};

export const getMissingRequiredFieldsForLinking = (user: UserCMS | UserUMS): string[] => {
  const missingFields: string[] = [];
  if (!user.username || user.username.trim().length === 0) {
    missingFields.push("username");
  }
  if (isUserObject(user)) {
    if (!user.firstName || user.firstName.trim().length === 0) {
      missingFields.push("first name");
    }
    if (!user.lastName || user.lastName.trim().length === 0) {
      missingFields.push("last name");
    }
  } else {
    if (!user.fname || user.fname.trim().length === 0) {
      missingFields.push("first name");
    }
    if (!user.lname || user.lname.trim().length === 0) {
      missingFields.push("last name");
    }
  }
  return missingFields;
};

export const successToastTitle = "Parent Added Successfully";

export const successToastLinkText = (user: UserCMS | UserUMS, guardian: LinkedGuardian): string => {
  let text = `${guardian.firstName} ${guardian.lastName} has been successfully linked`;
  if (isUserObject(user)) {
    if (user.firstName && user.lastName) {
      text += ` to ${user.firstName ?? ""} ${user.lastName ?? ""}`;
    }
  } else if (user.fname && user.lname) {
    text += ` to ${user.fname ?? ""} ${user.lname ?? ""}`;
  }
  return text;
};

export const successToastAddText = (user: UserCMS | UserUMS, guardian: LinkedGuardian): string => {
  let text = `${guardian.firstName} ${guardian.lastName} has been successfully added as a parent`;
  if (isUserObject(user)) {
    if (user.firstName && user.lastName) {
      text += ` to ${user.firstName ?? ""} ${user.lastName ?? ""}`;
    }
  } else if (user.fname && user.lname) {
    text += ` to ${user.fname ?? ""} ${user.lname ?? ""}`;
  }
  return text;
};

export const isUserEditable = (user?: UserCMS | UserUMS): boolean => {
  // If there's no user, or the user is archived, or the user is not a local user, then the user is not editable
  if (!user || user.archived || (isUserObject(user) ? user.sourceType.toLowerCase() !== "local" : user.provider.toLowerCase() !== "local"))
    return false;

  return true;
};

export const getDisabledNotificationReason = (user: UserUMS): string => {
  if (!user) return "";
  if (user.archived && user.sourceType.toLowerCase() !== "local") return "Archived and synchronised users cannot be edited.";
  if (user.archived) return "Archived users cannot be edited.";
  if (user.sourceType.toLowerCase() !== "local") return "Synchronised users cannot be edited.";
  return "";
};

export const formatClassroom = (c: Classroom): ClassroomUms => {
  return {
    id: c.name,
    name: c.classroom_name,
    sourceType: c.provider_type,
    sourceId: c.name,
  };
};
