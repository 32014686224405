/**
 * EDU-2539
 * https://github.com/B-Communications/school-manager-ui/pull/862#discussion_r968039490
 * Some z-indices like menu modal etc. are managed by CL.
 * I don't think we should standardise z-indices cross-project since CL is imported by some other projects too.
 * If it's not broken im bit hesitant to fix/optimise it.
 */
export const zIndices = {
  thead: 10,
  datepicker: 11,
  multiSelect: 49,
  menu: 50,
  tooltip: 999999,
};
