import React from "react";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import DeleteButton from "../../modules/DeleteButton";
import { ABTester } from "@familyzone/component-library";
import "../../../css/Associations.css";
import AssociationInExceptionModal from "../../modules/AssociationInExceptionModal";
import AssociationTableRenderer from "./PurgeAssociationsTableRenderer";
import AssociationModal from "./AssociationModal";
import { ImportStatus } from "./AssociationsImportStatus";
import { ImportCSV } from "./AssociationsImportCSV";

class PurgeAssociations extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_Submit = (provider_group) => {
    this.props.handlePurge(provider_group);
    this.props.handleClose();
  };

  renderActions = () => {
    return [
      <button type="button" className="mui-btn mui-btn--raised" onClick={this.handleHide}>
        Cancel
      </button>,
      <button
        type="button"
        className="mui-btn mui-btn--raised"
        onClick={() => {
          this.handle_Submit("STALE");
        }}
      >
        Purge Stale Associations
      </button>,
      <button
        type="button"
        className="mui-btn mui-btn--raised"
        onClick={() => {
          this.handle_Submit("ALL");
        }}
      >
        Purge ALL Associations
      </button>,
    ];
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Purge Associations" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="mui-textfield">
              <p className="lead">
                WARNING! You are about to permanently delete associations. Please select whether you wish to delete stale/all associations.
                (Stale being those for users who are no longer active)
              </p>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

class Purging extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Purging Associations">
          <form className="mui-form">
            <div className="mui-textfield">
              <p className="lead">
                Purging {this.props.purge_type} associations... <i className="fa fa-refresh fa-spin fa-fw" />
              </p>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

class AddSession extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      username: "",
      macAddress: "",
      users: [],
    };
  }

  handleHide = () => {
    this.setState({
      username: "",
      macAddress: "",
      invalidMacIpValueMsg: null,
    });
    this.props.handleClose();
  };

  handle_ChangeUsername = (event) => {
    this.setState({ username: event.value });
  };

  handle_ChangeMacAddress = (event) => {
    const valueError = this.props.macIpValueValidator(event.target.value);
    this.setState({ macAddress: event.target.value, invalidMacIpValueMsg: valueError });
  };

  handle_Submit = (user, macIpAddress) => {
    this.props.handleAdd({ username: user, macAddress: macIpAddress });
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button
        type="submit"
        className="mui-btn mui-btn--raised"
        disabled={this.state.invalidMacIpValueMsg || !this.state.macAddress || !this.state.username}
        onClick={this.handle_Submit}
      >
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <AssociationModal
          handleSave={this.handle_Submit}
          onChangeMac={this.handle_ChangeMacAddress}
          macError={this.state.invalidMacIpValueMsg}
          onClose={this.handleHide}
        ></AssociationModal>
      );
    }

    return <div />;
  }
}

export default class PermanentAssociations extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sessions: [],
      visible__add_permission: false,
      visible__import_csv: false,
      loaded: false,
      visible_purge_associations: false,
      purging: false,
    };

    this.queue = [];
    this.workerRunning = false;
    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    Api.get("/config/ajax/sessions", function (result) {
      let sessions = result["data"];
      self.setState({
        sessions: sessions,
        loaded: true,
      });
    });
    this.workerRunning = false;
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add_permission: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  handler__clickCloseImport = () => {
    this.setState({
      visible__import_csv: false,
    });
  };

  handler__clickImport = () => {
    this.setState({
      visible__import_csv: true,
    });
  };

  handle_Delete = (entity) => {
    let self = this;
    Api.delete("/config/ajax/sessions", entity, function (result) {
      self.handle_load();
    });
  };

  handle_Purge = (group) => {
    let self = this;
    this.setState({ purging: true, purge_type: group });
    Api.delete("/config/ajax/sessions/purge", { group: group }, function (result) {
      self.handler__closePurgingWindow();
      self.handle_load();
    });
  };

  handle__AddSession = (session) => {
    let self = this;
    Api.put("/config/ajax/sessions", session, function () {
      self.handle_load();
    });
  };

  handler__clickPurge = () => {
    this.setState({
      visible_purge_associations: true,
    });
  };

  handler__clickClosePurge = () => {
    this.setState({
      visible_purge_associations: false,
    });
  };

  handler__closePurgingWindow = () => {
    this.setState({
      purging: false,
      visible_purge_associations: false,
    });
  };

  stopImport() {
    this.queue = [];
  }

  handle__AddSessionToQueue = (session) => {
    this.queue.push(session);
    if (!this.workerRunning) {
      this.popSessionFromQueue();
    }
  };

  popSessionFromQueue() {
    let self = this;
    this.workerRunning = true;
    if (this.queue.length === 0) {
      self.handle_load();
      this.workerRunning = false;
      return;
    }
    let session = this.queue.pop();
    Api.put(
      "/config/ajax/sessions",
      session,
      function () {
        self.handle_load();
        self.popSessionFromQueue();
      },
      function () {
        self.popSessionFromQueue();
        self.showAssociationInExceptionModal();
      }
    );
  }

  macIpValueValidator = (value) => {
    let errorMsg = null;

    if (this.state.sessions.some((entry) => entry.macAddress === value)) {
      errorMsg = "This Mac/Ip Address is already in use";
    }

    return errorMsg;
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Username",
        data: function (row) {
          return row["username"];
        },
        search: FlexibleTable.search__single_string_field("username"),
        sortable: FlexibleTable.sortable__single_string_field("username"),
        onclick: undefined,
      },
      {
        title: "Mac/IP Address",
        data: function (row) {
          return row["macAddress"];
        },
        search: FlexibleTable.search__single_string_field("macAddress"),
        sortable: FlexibleTable.sortable__single_string_field("macAddress"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <DeleteButton onClick={() => self.handle_Delete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.sessions} loaded={this.state.loaded} />;
  };

  render__buttons = () => {
    return (
      <div>
        <TableCentricPageButton title="Add" onClickHandler={this.handler__clickAdd} />
        <TableCentricPageButton title="Import CSV" onClickHandler={this.handler__clickImport} />
        <TableCentricPageButton title="Purge Associations" onClickHandler={this.handler__clickPurge} />
      </div>
    );
  };

  showAssociationInExceptionModal = () => {
    this.setState({ associationInExceptionModalVisible: true });
  };

  closeAssociationInExceptionModal = () => {
    this.setState({ associationInExceptionModalVisible: false });
  };

  render() {
    return (
      <ABTester
        optionB={
          <TableLeftPanel>
            <AssociationInExceptionModal
              visible={this.state.associationInExceptionModalVisible}
              handleHide={this.closeAssociationInExceptionModal}
            />
            <AddSession
              visible={this.state.visible__add_permission}
              handleAdd={this.handle__AddSessionToQueue}
              handleClose={this.handler__clickCloseAdd}
              macIpValueValidator={this.macIpValueValidator}
            ></AddSession>
            <ImportStatus count={this.queue.length} cancelJob={this.stopImport.bind(this)} />
            <ImportCSV
              visible={this.state.visible__import_csv}
              handleAdd={this.handle__AddSessionToQueue}
              handleClose={this.handler__clickCloseImport}
              macIpValueValidator={this.macIpValueValidator}
            />
            <TableCentricPage icon="fa fa-users" title="Associations" buttons={this.render__buttons()} content={this.render__content()} />
            <PurgeAssociations
              visible={this.state.visible_purge_associations}
              handlePurge={this.handle_Purge}
              handleClose={this.handler__closePurgingWindow}
            ></PurgeAssociations>
            <Purging
              purge_type={this.state.purge_type}
              visible={this.state.purging}
              handleClose={this.handler__closePurgingWindow}
            ></Purging>
          </TableLeftPanel>
        }
        optionA={<AssociationTableRenderer data={this.state.sessions} loaded={this.state.loaded} />}
        isOptionB={true}
      />
    );
  }
}
