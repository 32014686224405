import React, { useState, useEffect, useCallback } from "react";
import { Box, useToast, useTranslation } from "@familyzone/component-library";

import FilteringTable from "../FilteringTable";
import { FilteringRule, Keyword, Signature, TimePeriod } from "../types";
import PolicyModal from "../PolicyModal";
import PolicyLayout from "../PolicyLayout";

import {
  sortPolicies,
  modifyPolicy,
  deletePolicy,
  createPolicy,
  getPolicies,
  getObjects,
  getFingerprints,
  getTimePeriods,
  getSecurityGroups,
  getSignaturesAndKeywords,
} from "../ApiHelpers";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import PageWithHeader from "../../../components/templates/PageWithHeader";
import TestPolicyModal from "../../../components/modals/TestPolicyModal/TestPolicyModal";
import { Fingerprint, IpObject, KeywordObject, MacObject, WebsiteObject, SecurityGroup } from "../../../modules/criteria/criteriaTypes";
import SignatureSearchModal from "../../../components/modals/SignatureSearchModal";

const FilteringPage: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t("Content Filtering"), isActive: true },
  ];

  const title = t("Content Filtering");

  const { errorToast } = useToast();
  const isMounted = useIsMounted();

  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);
  const [testPolicyModalOpen, setTestPolicyModalOpen] = useState<boolean>(false);
  const [editingRule, setEditingRule] = useState<null | FilteringRule>(null);
  const [policyEdit, setPolicyEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [rules, setRules] = useState<FilteringRule[]>([]);
  const [searchOpen, setSearchOpen] = useState(false);

  const [signatures, setSignatures] = useState<Signature[]>([]);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [keywordObjects, setKeywordObjects] = useState<KeywordObject[]>([]);
  const [websiteObjects, setWebsiteObjects] = useState<WebsiteObject[]>([]);
  const [ipObjects, setIpObjects] = useState<IpObject[]>([]);
  const [fingerprints, setFingerprints] = useState<Fingerprint[]>([]);
  const [macObjects, setMacObjects] = useState<MacObject[]>([]);
  const [timePeriods, setTimePeriods] = useState<TimePeriod[]>([]);
  const [securityGroups, setSecurityGroups] = useState<SecurityGroup[]>([]);

  const handleToggleSearchOpen = () => {
    setSearchOpen(!searchOpen);
  };

  const populateObjects = useCallback(async () => {
    try {
      const objectPromise = getObjects();
      const fingerprintPromise = getFingerprints();
      const timePeriodPromise = getTimePeriods();
      const securityGroupPromise = getSecurityGroups();
      const signatureAndKeywordPromise = getSignaturesAndKeywords();

      setIpObjects((await objectPromise).filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0));
      setFingerprints(await fingerprintPromise);
      setMacObjects((await objectPromise).filter((obj): obj is MacObject => obj.type === 4));
      setTimePeriods(await timePeriodPromise);
      setSecurityGroups(await securityGroupPromise);
      setSignatures(
        (await signatureAndKeywordPromise).signatures
          .filter(
            (s) => s.enabled && !s.hidden && !["sphirewall.application.alwaysallow", "sphirewall.application.alwaysdeny"].includes(s.id)
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      );
      setKeywords((await signatureAndKeywordPromise).keywords);
      setKeywordObjects((await objectPromise).filter((obj): obj is KeywordObject => obj.type === 6));
      setWebsiteObjects((await objectPromise).filter((obj): obj is WebsiteObject => obj.type === 2));
    } catch (err) {
      console.error(err);
      errorToast({ title: "Failed to load. Please refresh the page", isClosable: true });
    }
  }, [errorToast]);

  useEffect(() => {
    void populateObjects();
  }, [populateObjects]);

  const handleClosePolicyModal = async (rule: FilteringRule | null) => {
    if (!rule) {
      setPolicyModalOpen(false);
      return;
    } else if (policyEdit === false) {
      await deletePolicy(rule.id);
      if (!isMounted()) return;
      setRules(rules.filter((r) => r.id !== rule.id));
    }
    setPolicyModalOpen(false);
  };

  const handleCloseTestPolicyModal = () => {
    setTestPolicyModalOpen(false);
  };

  const handleClickCreatePolicy = useCallback(async () => {
    try {
      setLoading(true);
      // Add empty rule
      await createPolicy();
      if (!isMounted()) return;
      // Get total policies
      const filteringRules: FilteringRule[] = await getPolicies(false);
      if (!isMounted()) return;
      const newRule = filteringRules[filteringRules.length - 1];
      setRules(filteringRules);
      setEditingRule(newRule);

      setPolicyModalOpen(true);
      setPolicyEdit(false);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: "There was an error creating the policy",
      });
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted, errorToast]);

  const handleClickTestPolicy = useCallback(() => {
    setTestPolicyModalOpen(true);
  }, []);

  const handleSaveRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        setLoading(true);
        await modifyPolicy(rule);
        if (!isMounted()) return;
        setRules(
          rules.map((r) => {
            if (r.id !== rule.id) return r;
            return rule;
          })
        );
        setPolicyModalOpen(false);
        void populateObjects();
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: "There was an error saving the policy",
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, rules, errorToast, populateObjects]
  );

  const handleSortRules = useCallback(
    async (newRules: FilteringRule[]) => {
      try {
        setLoading(true);
        setRules(newRules);
        await sortPolicies(newRules);
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: "There was an error sorting the policies",
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, errorToast]
  );

  const handleDeleteRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        if (loading) return;
        setLoading(true);
        await deletePolicy(rule.id);
        if (!isMounted()) return;
        setRules(rules.filter((r) => r.id !== rule.id));
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: "There was an error deleting the policy",
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, loading, rules, errorToast]
  );

  const handleEditRule = (rule: FilteringRule) => {
    if (loading) return;
    setSearchOpen(false);
    setEditingRule(rules.find((r) => r.id === rule.id) || rule);
    setPolicyModalOpen(true);
    setPolicyEdit(true);
  };

  const handleEditRuleFromModal = (ruleID: string) => {
    rules.forEach((r) => {
      if (r.id === ruleID) {
        handleEditRule(r);
      }
    });
  };

  const handleToggleRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        if (loading) return;
        const newRule = rule;
        newRule.enabled = !newRule.enabled;

        setLoading(true);
        await modifyPolicy(newRule);
        if (!isMounted()) return;
        setRules(
          rules.map((r) => {
            if (r.id !== rule.id) return r;
            return newRule;
          })
        );
      } catch (err) {
        if (!isMounted()) return;
        console.error(err);
        errorToast({
          title: "There was an error modifying the policy",
        });
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, loading, rules, errorToast]
  );

  const populateRules = useCallback(async () => {
    try {
      setLoading(true);
      // Remove any content mod rules from the ruleset before showing them
      const filteringRules: FilteringRule[] = await getPolicies(false);
      if (!isMounted()) return;
      setRules(filteringRules);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: "There was an error populating the policies",
      });
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted, errorToast]);

  useEffect(() => {
    populateRules().catch(() => "");
  }, [populateRules]);

  const handleRefresh = () => {
    populateRules().catch(() => "");
  };

  return (
    <PageWithHeader breadcrumbs={breadcrumbs} title={title}>
      <Box width="100%" mt="sp16">
        <PolicyLayout
          loading={loading && rules.length === 0}
          disabled={loading && rules.length !== 0}
          showTestPolicy
          showCategorySearch
          showObjectLists
          onClickCreatePolicy={() => void handleClickCreatePolicy().catch(() => "")}
          onClickTestPolicy={() => void handleClickTestPolicy()}
          onClickURLSearch={() => void handleToggleSearchOpen()}
        >
          <FilteringTable
            disabled={loading && rules.length !== 0}
            loading={loading && rules.length === 0}
            rules={rules}
            onSortRules={(rules: FilteringRule[]) => void handleSortRules(rules)}
            onToggleRule={(rule: FilteringRule) => void handleToggleRule(rule)}
            onDeleteRule={(rule: FilteringRule) => void handleDeleteRule(rule)}
            onEditRule={(rule: FilteringRule) => void handleEditRule(rule)}
          />
        </PolicyLayout>
        <PolicyModal
          loading={loading}
          open={policyModalOpen}
          rule={editingRule}
          editing={policyEdit}
          onClose={(rule: FilteringRule | null) => void handleClosePolicyModal(rule)}
          onSubmit={(rule: FilteringRule) => void handleSaveRule(rule)}
          signatures={signatures}
          keywords={keywords}
          keywordObjects={keywordObjects}
          websiteObjects={websiteObjects}
          ipObjects={ipObjects}
          macObjects={macObjects}
          securityGroups={securityGroups}
          fingerprints={fingerprints}
          timePeriods={timePeriods}
        />
        <TestPolicyModal
          signatures={signatures}
          keywords={keywords}
          keywordObjects={keywordObjects}
          websiteObjects={websiteObjects}
          open={testPolicyModalOpen}
          onClose={handleCloseTestPolicyModal}
          editRule={handleEditRuleFromModal}
          onRefresh={handleRefresh}
        />
        <SignatureSearchModal
          data-testid="search-modal"
          open={searchOpen}
          signatures={signatures}
          onClose={handleToggleSearchOpen}
          onSignatureClick={handleEditRule}
          onRefresh={() => void populateRules()}
        />
      </Box>
    </PageWithHeader>
  );
};

export default FilteringPage;
