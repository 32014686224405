import React from "react";
import PropTypes from "prop-types";
import SessionStore from "../../stores/SessionStore";
import DeviceStatusStore from "../../stores/DeviceStatusStore";
import DeviceStatusActions from "../../actions/DeviceStatusActions";
import DropDownDeviceAlerts from "./DeviceAlerts";
import StackStatus from "./StackStatus";
import DropDownDeviceVersion from "./DeviceVersion";
import { withTranslation } from "@familyzone/component-library";
import DeviceMenu from "./DeviceMenu";

class DeviceTab extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      device_status_menu_open: false,
      loaded_info: false,
      stack_status_change_active: false,
      tools_open: false,
      restart_open: false,
    };
  }

  componentDidMount() {
    SessionStore.listen(this.onChange);
    DeviceStatusStore.listen(this.onChange);
    setTimeout(() => {
      DeviceStatusActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
    DeviceStatusStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      loaded_info: DeviceStatusStore.getLoaded(),
    });
  };

  handle_OpenDeviceTools = () => {
    this.setState({
      tools_open: true,
    });
  };

  handle_ClickCloseTools = () => {
    this.setState({
      tools_open: false,
    });
  };

  handle_OpenRestart = () => {
    this.setState({
      restart_open: true,
    });
  };

  handle_ClickCloseRestart = () => {
    this.setState({
      restart_open: false,
    });
  };

  handle_ClickOpenUserMenu = () => {
    this.setState({
      device_status_menu_open: true,
    });
  };

  handle_ClickCloseUserMenu = () => {
    this.setState({
      device_status_menu_open: false,
    });
  };

  handle_OpenDeviceOptions = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice");
  };

  handle_OpenDeviceUpdate = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice/settings/updates");
  };

  handle_OpenDiagnostics = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice/settings/diagnostics/alarms");
  };

  handle_OpenConfiguration = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice/settings/audit");
  };

  handle_OpenAlarms = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice/settings/diagnostics/alarms");
  };

  handle_OpenDevicePermissions = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/managedevice/settings/permissions");
  };

  handle_OpenDevices = () => {
    this.handle_ClickCloseUserMenu();
    this.context.router.push("/devices");
  };

  handle_OpenStackStatusChange = () => {
    this.setState({ stack_status_change_active: true });
  };

  handleClose_OpenStackStatusChange = () => {
    this.setState({ stack_status_change_active: false });
  };

  render_DeviceInfo = () => {
    if (this.state.loaded_info) {
      return (
        <div className="device-update-container">
          <div className="device-update-info">
            <DropDownDeviceVersion allowedRoles={["owner"]} onClick={this.handle_OpenDeviceUpdate} />
            <StackStatus allowedRoles={["owner"]} onClick={this.handle_OpenStackStatusChange} />
            <DropDownDeviceAlerts allowedRoles={["owner"]} onClick={this.handle_OpenAlarms} />
          </div>
        </div>
      );
    }

    return <div />;
  };

  render() {
    if (!SessionStore.hasDevice()) {
      return <div />;
    }

    return (
      <DeviceMenu
        openDevicePermissions={this.handle_OpenDevicePermissions}
        openDeviceOptions={this.handle_OpenDeviceOptions}
        openDiagnostics={this.handle_OpenDiagnostics}
        openRestart={this.handle_OpenRestart}
        openDevices={this.handle_OpenDevices}
        openDeviceUpdate={this.handle_OpenDeviceUpdate}
        openAlarms={this.handle_OpenAlarms}
        deviceId={SessionStore.getSelectedDevice()}
        deviceName={SessionStore.getSelectedDeviceName()}
      />
    );
  }
}

DeviceTab.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(DeviceTab);
