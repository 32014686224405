import { Classroom, ClassroomWithUsers, GetClassroomResponse, UpdateClassroomBody, SearchClassroomsResponse } from "../../types/Classrooms";
import { headers } from "./ApiHelpers";
import { PurgeType } from "./Helpers";

const baseUrl = "/api/classrooms";

// It is expected that the CSV upload that calls this function has converted the CSV into JSON already, so we don't stringify it when
// POSTing to the batch create endpoint which expects JSON.
export const batchCreateClassrooms = async (classrooms: string): Promise<void> => {
  const raw = await fetch(`${baseUrl}/batch`, {
    method: "PUT",
    headers: headers(),
    body: classrooms,
  });
  if (raw.status !== 201) throw new Error("Invalid response from server");
};

export const deleteClassroom = async (id: string): Promise<void> => {
  const raw = await fetch(`${baseUrl}`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ classrooms: [id] }),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};

/**
 * Add Classroom
 * @param classroomName - Name of the new class
 */
export const createClassroom = async (classroomName: string): Promise<string> => {
  const raw = await fetch(baseUrl, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      name: classroomName,
    }),
  });
  const response: unknown = await raw.json();
  if (!(response !== null && typeof response === "object" && "id" in response && typeof response.id === "string")) {
    throw new Error("Invalid response from server");
  }
  return response.id;
};

export const searchClassrooms = async (query: string): Promise<Classroom[]> => {
  const raw = await fetch(`${baseUrl}/search?query=${encodeURIComponent(query)}`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (isClassroomSearchResult(response) && isClassroomObjects(response.classrooms)) {
    return response.classrooms;
  } else {
    throw new Error("Invalid response from server");
  }
};

export const purgeClassrooms = async (type: PurgeType): Promise<void> => {
  const raw = await fetch(`${baseUrl}/purge`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ type: type }),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};

const isClassroomSearchResult = (response: unknown): response is SearchClassroomsResponse => responseHasClassroomsArray(response);

const isClassroomObjects = (classrooms: unknown[]): classrooms is Classroom[] => {
  if (classrooms.length === 0) return true;
  return classrooms.some((classroom) => isClassroom(classroom));
};

const isClassroom = (classroom: unknown): classroom is Classroom => {
  return classroom !== null && typeof classroom === "object" && "id" in classroom && "name" in classroom && "sourceType" in classroom;
};

const responseHasClassroomsArray = (response: unknown): response is { classrooms: unknown[] } => {
  return response !== null && typeof response === "object" && "classrooms" in response && Array.isArray(response.classrooms);
};

export const getClassrooms = async (): Promise<Classroom[]> => {
  const raw = await fetch(baseUrl, {
    headers: headers(),
  });
  const response: unknown = await raw.json();
  if (isClassroomSearchResult(response)) return response.classrooms;
  throw new Error("Invalid response from server");
};

const isGetClassroomResponse = (response: unknown): response is GetClassroomResponse => {
  return response !== null && typeof response === "object" && "classroom" in response;
};

const isClassroomWithUsers = (object: unknown): object is ClassroomWithUsers => {
  return isClassroom(object) && "students" in object && "teachers" in object;
};

export const getClassroom = async (id: string): Promise<ClassroomWithUsers> => {
  const raw = await fetch(`${baseUrl}/${id}`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();
  if (isGetClassroomResponse(response) && isClassroomWithUsers(response.classroom)) return response.classroom;
  throw new Error("Invalid response from server");
};

export const updateClassroom = async (id: string, body: UpdateClassroomBody): Promise<void> => {
  const raw = await fetch(`${baseUrl}/${id}`, {
    headers: headers(),
    method: "PATCH",
    body: JSON.stringify(body),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};
