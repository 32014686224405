import React, { useCallback } from "react";
import { Box, Modal, Option, useTranslation } from "@familyzone/component-library";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";
import PropTypes from "prop-types";
import { RouterContext } from "../../utils/RouterContext";

interface FindUserModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
}

const FindUserModal: React.FC<FindUserModalProps> = ({ open = false, onClose = () => "" }, context: RouterContext) => {
  const titleText = "Find User";
  const placeholderText = "Enter username, email or name";
  const buttonText = "Go to their User Dashboard";
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClickSubmit = (selected: Option | null) => {
    if (!selected) return;
    handleClose();
    context.router.push(`/surfwize/reporting/users/${selected.value}`);
  };

  return (
    <Modal headerText={t(titleText)} size="md" isOpen={open} onClose={handleClose} contentProps={{ style: { overflow: "visible" } }}>
      <Box mt="sp24">
        <UserSearchSelector placeholderText={placeholderText} buttonText={buttonText} handleSubmit={handleClickSubmit} />
      </Box>
    </Modal>
  );
};

export default FindUserModal;
FindUserModal.contextTypes = {
  router: PropTypes.object.isRequired,
};
