import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import ModalWindow from "../../modules/ModalWindow";
import SearchableSelect from "../../modules/SearchableSelect";

class NetworkAddress extends React.Component {
  handle_Delete = () => {
    this.props.handle_RemoveNetwork(this.props.network, this.props.subnet, this.props.network_configuration);
  };

  render() {
    return (
      <div className="interfaces-ip-address">
        <span>
          {this.props.network}/{this.props.subnet}
        </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="interfaces-ip-address-remove" onClick={this.handle_Delete}>
          Remove
        </a>
      </div>
    );
  }
}

class AddNetworkAddressModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      network: "",
      subnet: "",
      visible: props.visible,
      network_configuration: props.network_configuration,
    };
  }

  handleHide = () => {
    this.setState({
      visible: false,
      network: "",
      subnet: "",
    });
    this.props.handleClose();
  };

  handle_ChangeNetwork = (event) => {
    this.setState({ network: event.target.value });
  };

  handle_Changesubnet = (event) => {
    this.setState({ subnet: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd({
      network: this.state.network,
      subnet: this.state.subnet,
      network_configuration: this.state.network_configuration,
    });

    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Network" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">Network</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.network} onChange={this.handle_ChangeNetwork} />
            </div>
            <div className="formgroup-element-title">Subnet Mask</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.subnet} onChange={this.handle_Changesubnet} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class GatewayToGatewayVpn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      configuration: {
        auto_start: false,
        auto_configure_firewall: false,
        auth_mode: 1,
        secret_key: "",
        local_id: "",
        local_device: "",
        remote_host: "",
        local_networks: [],
        remote_networks: [],
        remote_id: "",
      },
      status: {},
      devices: [],
      add_local_network_visible: false,
      add_remote_network_visible: false,
    };
  }

  componentDidMount() {
    this.handle_load();
    InterfaceStore.listen(this.onChange);
    InterfaceActions.fetch();
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    let configuration = this.state.configuration;

    this.setState({ devices: InterfaceStore.getInterfaces() });

    if ("local_shared_device" in configuration) {
      let local_shared_network_details = this.get_NetworkDetails(configuration["local_shared_device"]);
      configuration["local_networks"] = local_shared_network_details;
      this.setState({ configuration: configuration });
    }
  };

  handle_load = () => {
    let self = this;
    Api.get("/edgewize/ajax/gatewayvpns/" + this.props.params.vpn_id, function (result) {
      let configuration = self.state.configuration;
      result = result["data"];

      // Selectively load values from saved configuration
      for (let key in self.state.configuration) {
        if (key in result) {
          configuration[key] = result[key];
        }
      }

      // Handle Backward compatibility - local network
      if ("local_shared_device" in result && !("local_networks" in result)) {
        configuration["local_shared_device"] = result["local_shared_device"];
      }

      // Handle Backward compatibility - remote network
      if ("remote_network" in result && !("remote_networks" in result)) {
        configuration["remote_networks"].push({
          network: result["remote_network"],
          subnet: result["remote_subnet"],
        });
        configuration["remote_network"] = result["remote_network"];
        configuration["remote_subnet"] = result["remote_subnet"];
      }

      self.setState({ configuration: configuration });
    });
  };

  handle_save = (event) => {
    event.preventDefault();
    let self = this;
    self.setState({ saving: true });
    Api.post("/edgewize/ajax/gatewayvpns/" + this.props.params.vpn_id, this.state.configuration, function (result) {
      self.setState({ saving: false });
      self.handle_load();
      self.context.router.push("/edgewize/device/vpn");
    });
  };

  handle_Submit = (event) => {
    event.preventDefault();
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let i of this.state.devices) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  };

  get_NetworkDetails = (interface_name) => {
    let ret = [];
    for (let i of this.state.devices) {
      if (i.interface === interface_name) {
        for (let addr of i.ipv4) {
          ret.push({ network: addr.ip, subnet: addr.mask });
        }
      }
    }
    return ret;
  };

  handle_ChangeEnabled = (event) => {
    const configuration = this.state.configuration;
    configuration["auto_start"] = event.target.checked;
    this.setState({ configuration: configuration });
  };

  handle_ChangeAutoFirewall = (event) => {
    const configuration = this.state.configuration;
    configuration["auto_configure_firewall"] = event.target.checked;
    this.setState({ configuration: configuration });
  };

  handle_ChangeSecretKey = (event) => {
    const configuration = this.state.configuration;
    configuration["secret_key"] = event.target.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeLocalId = (event) => {
    const configuration = this.state.configuration;
    configuration["local_id"] = event.target.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeListeningInterface = (event) => {
    const configuration = this.state.configuration;
    configuration["local_device"] = event.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeRemoteHost = (event) => {
    const configuration = this.state.configuration;
    configuration["remote_host"] = event.target.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeRemoteNetwork = (event) => {
    const configuration = this.state.configuration;
    configuration["remote_network"] = event.target.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeRemoteSubnet = (event) => {
    const configuration = this.state.configuration;
    configuration["remote_subnet"] = event.target.value;
    this.setState({ configuration: configuration });
  };
  handle_ChangeRemoteID = (event) => {
    const configuration = this.state.configuration;
    configuration["remote_id"] = event.target.value;
    this.setState({ configuration: configuration });
  };

  handle_AddNetwork = (info) => {
    let configuration = this.state.configuration;
    if (info.network_configuration === "local") {
      configuration.local_networks.push({ network: info.network, subnet: info.subnet });
    } else {
      configuration.remote_networks.push({ network: info.network, subnet: info.subnet });
    }
    this.setState({ configuration: configuration });
  };

  handle_AddNetworkClose = () => {
    this.setState({
      add_local_network_visible: false,
      add_remote_network_visible: false,
    });
  };

  handle_AddNetworkOpen = (network_configuration) => {
    if (network_configuration === "local") {
      this.setState({ add_local_network_visible: true });
    } else {
      this.setState({ add_remote_network_visible: true });
    }
  };

  handle_RemoveNetwork = (network, subnet, network_configuration) => {
    let configured_networks = "";
    const configuration = this.state.configuration;

    if (network_configuration === "local") {
      configured_networks = configuration.local_networks;
    } else {
      configured_networks = configuration.remote_networks;
    }

    let ret = [];
    for (let existing_network of configured_networks) {
      if (existing_network.network === network && existing_network.subnet === subnet) {
        continue;
      }

      ret.push(existing_network);
    }

    if (network_configuration === "local") {
      configuration.local_networks = ret;
    } else {
      configuration.remote_networks = ret;
    }
    this.setState({ configuration: configuration });
  };

  render_Networks = (networks, network_configuration) => {
    let ret = [];
    for (let network of networks) {
      ret.push(
        <NetworkAddress
          network={network.network}
          subnet={network.subnet}
          handle_RemoveNetwork={this.handle_RemoveNetwork}
          network_configuration={network_configuration}
          key={network.network}
        />
      );
    }

    return ret;
  };

  render_NetworkConfiguration = (network_configuration) => {
    let network_label = "";
    let networks = "";

    if (network_configuration === "local") {
      network_label = <div className="formgroup-element-title">Local Network</div>;
      networks = this.state.configuration.local_networks;
    } else {
      network_label = <div className="formgroup-element-title">Remote Network</div>;
      networks = this.state.configuration.remote_networks;
    }
    return (
      <div className="mui-textfield">
        {network_label}
        {this.render_Networks(networks, network_configuration)}
        <Link onClick={() => this.handle_AddNetworkOpen(network_configuration)}>Add Network</Link>
      </div>
    );
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Manage Gateway VPN</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="mui-checkbox">
                    <label>
                      <input type="checkbox" checked={this.state.configuration.auto_start} onChange={this.handle_ChangeEnabled} />
                      Enabled
                    </label>
                  </div>

                  <div className="mui-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.configuration.auto_configure_firewall}
                        onChange={this.handle_ChangeAutoFirewall}
                      />
                      Auto Configure Firewall
                    </label>
                  </div>

                  <div className="formgroup-element-title">Secret Key</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.configuration.secret_key} onChange={this.handle_ChangeSecretKey} />
                  </div>

                  <div className="formgroup-element-title">Local ID</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.configuration.local_id} onChange={this.handle_ChangeLocalId} />
                  </div>

                  <div className="formgroup-element-title">Listening Interface</div>
                  <div className="mui-select">
                    <SearchableSelect
                      value={this.state.configuration.local_device}
                      onChange={this.handle_ChangeListeningInterface}
                      valueDataMap={this.get_SelectionMap()}
                    ></SearchableSelect>
                  </div>

                  <div className="formgroup-element-title">Remote IP</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.configuration.remote_host} onChange={this.handle_ChangeRemoteHost} />
                  </div>

                  <AddNetworkAddressModal
                    visible={this.state.add_local_network_visible}
                    handleClose={this.handle_AddNetworkClose}
                    handleAdd={this.handle_AddNetwork}
                    network_configuration="local"
                  ></AddNetworkAddressModal>
                  {this.render_NetworkConfiguration("local")}

                  <AddNetworkAddressModal
                    visible={this.state.add_remote_network_visible}
                    handleClose={this.handle_AddNetworkClose}
                    handleAdd={this.handle_AddNetwork}
                    network_configuration="remote"
                  ></AddNetworkAddressModal>
                  {this.render_NetworkConfiguration("remote")}

                  <div className="formgroup-element-title">Remote ID</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.configuration.remote_id} onChange={this.handle_ChangeRemoteID} />
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_save} saving={this.state.saving} changes={true} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}

GatewayToGatewayVpn.contextTypes = {
  router: PropTypes.object.isRequired,
};
