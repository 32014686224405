export const protocols = [
  [1, "ICMP"],
  [2, "IGMP"],
  [3, "GGP"],
  [6, "TCP"],
  [8, "EGP"],
  [9, "IGP"],
  [17, "UDP"],
  [88, "EIGRP"],
  [89, "OSPF"],
  [47, "GRE"],
  [50, "ESP"],
  [51, "AH"],
  [58, "ICMPv6"],
];
export const protocolOptions = protocols.map((protocol) => ({ text: protocol[1] as string, value: protocol[0] as string }));
