import React from "react";

export default class BetaTag extends React.Component {
  render() {
    return (
      <div
        className="beta-pill"
        title="This feature is in BETA. As a result, it may not function as expected. Please contact Linewize for assistance."
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src="/static/images/beta.svg" />
      </div>
    );
  }
}
