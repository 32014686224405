import React from "react";
import { Text, TextInput, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";
import { HStack } from "@chakra-ui/layout";
import { assignType } from "./helpers";

export interface GenericTwoStringInputCriteriaProps {
  criteria: FilteringSourceCriteria;
  onChange: (criteria: FilteringSourceCriteria) => void;
  rangeToUse: { [key: string]: string | number; readableName: string }[];
}

const GenericTwoStringInputCriteria: React.FC<GenericTwoStringInputCriteriaProps> = ({ criteria, rangeToUse, onChange }) => {
  const { t } = useTranslation();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = assignType(e.target.value);
    onChange({
      ...criteria,
      conditions: { ...criteria.conditions, [e.target.name]: value },
    });
  };

  const conditions = criteria.conditions;
  if (Array.isArray(conditions)) return <Text>{t("Invalid Criteria")}</Text>;

  return (
    <HStack spacing="sp4">
      {rangeToUse.map((item, i) => {
        return (
          <TextInput
            label={t(item.readableName)}
            placeholder={t(item.readableName)}
            name={Object.keys(item)[0]}
            value={conditions[Object.keys(item)[0]].toString()}
            onChange={handleChangeInput}
            type={typeof Object.keys(rangeToUse)[0] === "number" ? "number" : "string"}
            key={i}
          />
        );
      })}
    </HStack>
  );
};

export default GenericTwoStringInputCriteria;
