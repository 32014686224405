import { TFunction } from "i18next";

export interface ApplianceRoute {
  forceShowChildren: boolean;
  childItems: {
    to: string;
    name: string;
  }[];
}

export function getApplianceNavRoutes(t: TFunction): ApplianceRoute[] {
  return [
    {
      forceShowChildren: true,
      childItems: [
        {
          to: "/devices",
          name: t("Devices"),
        },
        {
          to: "/device/create",
          name: t("New Device"),
        },
        {
          to: "/account",
          name: t("Account"),
        },
      ],
    },
  ];
}
