import React, { FC, useState } from "react";
import { Box, Button, Text, InlineNotification } from "@familyzone/component-library";
import GoogleLogo from "../../../../images/logo_google.svg";
import { Button as ChakraButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";

interface SyncTokenStatusProps {
  handleRevoke: () => void;
  handleLink: () => void;
  isLinked: boolean;
  syncType: "GOOGLE" | "AZURE";
}

export const SyncCredentialStatus: FC<SyncTokenStatusProps> = ({ handleLink, syncType, isLinked, handleRevoke }) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  return (
    <Box background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"24px"} borderRadius={"6px"}>
      <Text fontSize={18} color={"black"}>
        Credentials
      </Text>
      <Box border={"1px solid #EBECF0"} borderRadius={"6px"} height={"41px"} backgroundColor={"#F4F5F7"} display={"flex"}>
        <Text flex={1} alignContent={"center"} paddingLeft={"12px"}>
          {syncType === "GOOGLE" ? "Google" : "Microsoft Entra ID"} Credentials
        </Text>
        <Text flex={1} alignContent={"center"} paddingLeft={"12px"}>
          {isLinked ? "Linked to Account" : "Not Linked"}
        </Text>
      </Box>
      {!isLinked && syncType === "GOOGLE" && (
        <Button onClick={handleLink} size="default" variant="outline" w="100%" leftIcon={<img alt="Google Logo" src={GoogleLogo} />}>
          Continue with Google
        </Button>
      )}
      {!isLinked && syncType === "AZURE" && (
        <Button onClick={handleLink} variant="primary">
          Link Credentials
        </Button>
      )}
      {isLinked && (
        <ChakraButton
          onClick={() => {
            setShowConfirmModal(true);
          }}
          variant={"primary"}
          backgroundColor={"#CC3746"}
        >
          Revoke
        </ChakraButton>
      )}
      {isLinked && (
        <InlineNotification status="warning" notificationDescription={"Revoking credentials will remove the ability to sync."} />
      )}
      <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={"500px"}>
          <ModalHeader display={"flex"} alignItems={"center"} gap={"1rem"}>
            <Box h={"16px"} w={"16px"}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
              </svg>
            </Box>
            Revoke Credentials
          </ModalHeader>
          <ModalBody display={"flex"} flexDirection={"column"} gap={"1rem"} overflow={"hidden"}>
            <Text>{"Revoking credentials will remove the ability to sync."}</Text>
            <Text>{"Confirm you want to make this change before proceeding."}</Text>
          </ModalBody>

          <ModalFooter
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ChakraButton
              onClick={() => {
                setShowConfirmModal(false);
                handleRevoke();
              }}
              backgroundColor={"#FFB240"}
            >
              Confirm
            </ChakraButton>
            <ChakraButton onClick={() => setShowConfirmModal(false)}>Cancel</ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
