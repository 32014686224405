import alt from "../alt";

class SignatureTreeActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }
}

export default alt.createActions(SignatureTreeActions);
