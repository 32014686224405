import React from "react";
import { groupLabelFormatter } from "../components/GroupSearch/GroupSearchHelper";
import { UserFriendlyGroupNames } from "../types/Groups";
import { batchResolveGroupDescriptions } from "../utils/api/Groups";

interface CriteriaPrinterGroupsProps {
  groups: string[];
}

const CriteriaPrinterGroups: React.FC<CriteriaPrinterGroupsProps> = ({ groups }) => {
  const [resolvedGroups, setResolvedGroups] = React.useState<UserFriendlyGroupNames[]>([]);
  const separator = ", ";
  React.useEffect(() => {
    void batchResolveGroupDescriptions(groups).then((resolvedGroups) => {
      setResolvedGroups(resolvedGroups);
    });
  }, [groups]);

  return (
    <>
      {resolvedGroups.map((group, index) => {
        const label = groupLabelFormatter(group.name, group.description, group.distinguishedName);
        if (index < resolvedGroups.length - 1) return <span key={group.name}>{label + separator}</span>;
        else return <span key={group.name}>{label}</span>;
      })}
    </>
  );
};

export default CriteriaPrinterGroups;
