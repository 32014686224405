import React, { useEffect, useState } from "react";
import { Button, Modal, TextInput, useToast, useTranslation } from "@familyzone/component-library";
import { createClassroom } from "../../../utils/api/Classrooms";
import PropTypes from "prop-types";

const AddClassroom: React.FC = (_, context) => {
  const [classroomName, setClassroomName] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const validateName = (name: string): boolean => {
    return name.trim().length === 0;
  };

  useEffect(() => {
    const validation = validateName(classroomName);
    setSubmitDisabled(validation);
  }, [classroomName]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setClassroomName(event.target.value);
  };

  const handleAddClassroom = (): void => {
    // Update UMS
    void createClassroom(classroomName.trim())
      .then((id: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        context.router.push("/config/device/classwize/classrooms/" + id);
      })
      .catch(() => {
        errorToast({ title: "Error", description: "Failed to create classroom, please try again later." });
      });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("Add Classroom")}
      </Button>
      <Modal
        headerText={t("Add Classroom")}
        size="sm"
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        primaryCTALabel={t("Add")}
        primaryCTADisabled={submitDisabled}
        onPrimaryCTAClick={handleAddClassroom}
      >
        <TextInput data-testid="add-classroom-input" value={classroomName} onChange={handleChangeName} placeholder="Enter classroom name" />
      </Modal>
    </>
  );
};

export default AddClassroom;

AddClassroom.contextTypes = {
  router: PropTypes.object.isRequired,
};
