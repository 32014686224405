import { FieldErrors, FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";
import { Input, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Box } from "@familyzone/component-library";
import { ConfigFormLabel } from "./ConfigFormLabel";
import React from "react";

type Props<T extends FieldValues> = {
  label: string;
  propertyName: keyof T;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  tooltip?: string;
  inputRegisterOptions?: RegisterOptions<T>;
  inputTestId?: string;
};

export const TextFieldControl = <T extends FieldValues>({
  label,
  propertyName,
  register,
  errors,
  tooltip,
  inputRegisterOptions,
  inputTestId,
}: Props<T>): JSX.Element => {
  return (
    <FormControl
      isInvalid={!!errors[propertyName]}
      sx={{
        display: "flex",
        minHeight: "40px",
        fontSize: "14px",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "120px",
        }}
      >
        <ConfigFormLabel label={label} tooltip={tooltip} />
      </Box>
      <Box flex={1} maxWidth={"350px"}>
        <Input
          data-testid={inputTestId}
          sx={{
            padding: "12px",
            alignContent: "center",
          }}
          {...register(propertyName as Path<T>, inputRegisterOptions)}
        />
        <FormErrorMessage>{errors[propertyName] && errors[propertyName]?.message}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};
