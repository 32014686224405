import get from "lodash.get";

/**
 * Gets the property value at path of object. If the resolved value is falsy (undefined, null) the defaultValue is used
 * in its place.
 *
 * @param object The object to query.
 * @param path The path of the property to get.
 * @param defaultValue The value returned if the resolved value is falsy.
 * @return Returns the resolved value.
 */
export function guardedGet(object, path, defaultValue) {
  return get(object, path, defaultValue) || defaultValue;
}
