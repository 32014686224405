import PeriodActions from "../actions/PeriodActions";
import PeriodStore from "../stores/PeriodStore";
import React from "react";
import Criteria from "./Criteria";
import { get } from "shades";
import NuMultiSelect from "../utils/NuMultiSelect";

export default class TimePeriods extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (!props.criteria.conditions) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      periods: PeriodStore.getPeriods(),
    };

    this.displayTextLens = get("name");
    this.valueLens = get("id");
  }

  componentDidMount() {
    PeriodStore.listen(this.onChange);

    setTimeout(() => {
      PeriodActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    PeriodStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      periods: PeriodStore.getPeriods(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (!nextprops.criteria.conditions) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Add = (item) => {
    const itemValue = this.valueLens(item);
    this.state.criteria.conditions.push(itemValue);

    this.props.bubbleMethod(this.state.criteria);
  };

  handle_Remove = (itemValue) => {
    const removalIndex = this.state.criteria.conditions.findIndex((element) => element === itemValue);
    this.state.criteria.conditions.splice(removalIndex, 1);

    this.props.bubbleMethod(this.state.criteria);
  };

  handle_selectorOnlyAdd = (item) => {
    const itemValue = this.valueLens(item);
    this.state.criteria.conditions.push(itemValue);

    this.props.addElement(itemValue);
  };

  handle_selectorOnlyRemove = (itemValue) => {
    if (!this.props.removeElement(itemValue)) {
      const removalIndex = this.state.criteria.conditions.findIndex((element) => element === itemValue);
      this.state.criteria.conditions.splice(removalIndex, 1);
    }
  };

  render() {
    if (this.props.renderSelectorOnly) {
      return (
        <NuMultiSelect
          allItems={this.state.periods}
          selectedValues={this.state.criteria.conditions}
          displayTextLens={this.displayTextLens}
          valueLens={this.valueLens}
          searchPlaceholder="Time Periods"
          selectedPlaceHolder="No Time Periods Selected"
          onAdd={this.handle_selectorOnlyAdd}
          onRemove={this.handle_selectorOnlyRemove}
        />
      );
    }

    return (
      <Criteria label="Periods" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <NuMultiSelect
          allItems={this.state.periods}
          selectedValues={this.state.criteria.conditions}
          displayTextLens={this.displayTextLens}
          valueLens={this.valueLens}
          searchPlaceholder="Time Periods"
          selectedPlaceHolder="No Time Periods Selected"
          onAdd={this.handle_Add}
          onRemove={this.handle_Remove}
        />
      </Criteria>
    );
  }
}
