import { headers } from "./ApiHelpers";

/**
 * Content Aware rule sensitivity.
 *
 * A decimal number between 0 and 1 if enabled.
 *
 * A sensitivity of -1 means that the rule is disabled.
 */
export type RuleSensitivity = number;

/**
 * The Content Aware config shape that comes from agent-config-service
 */
export interface ContentAwareConfig {
  defaultRules: {
    gore: RuleSensitivity;
    porn: RuleSensitivity;
    swimwear: RuleSensitivity;
  };
  /**
   * Enables blocking all images on a webpage, if a certain number of images on the page are found to be associated with a blocked category.
   */
  guiltByAssociationEnabled: boolean;
  /**
   * Array of domains that should be excluded from Content Aware filtering.
   */
  neverRunOn: string[];
}

export enum ContentAwareLicenseStatus {
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
}

/**
 * The Content Aware license shape that comes from agent-config-service
 */
export interface ContentAwareLicense {
  /**
   * The appliance ID.
   */
  name: string;
  /**
   * The license key for the third-party service.
   */
  key: string;
  /**
   * The license expiry date.
   */
  status: ContentAwareLicenseStatus;
}

/**
 * Get the Content Aware config for the current appliance.
 *
 * @returns The Content Aware config.
 * @throws {Error} If the request fails.
 */
export async function getContentAwareConfig(): Promise<ContentAwareConfig> {
  const response = await fetch(`/agent-config/v1/content-aware/configuration`, {
    method: "GET",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareConfig;
    case 401:
      throw new Error("Not authenticated or authorised to fetch Content Aware config");
    case 404:
      throw new Error("Content Aware config not found");
    default:
      throw new Error("Unknown error when trying to fetch Content Aware config");
  }
}

/**
 * Update the Content Aware config for the current appliance.
 *
 * @param config The new Content Aware config.
 * @returns {ContentAwareConfig} Updated config on success.
 * @throws {Error} If the request fails.
 */
export async function updateContentAwareConfig(config: ContentAwareConfig): Promise<ContentAwareConfig> {
  const response = await fetch(`/agent-config/v1/content-aware/configuration`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(config),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareConfig;
    case 400:
      throw new Error("Invalid Content Aware config");
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to update Content Aware config");
    case 404:
      throw new Error("No valid Content Aware license found for appliance");
    default:
      throw new Error("Unknown error when trying to update Content Aware config");
  }
}

/**
 * Get the Content Aware license for the current appliance.
 *
 * Requires Support Admin role.
 *
 * @returns The Content Aware license on success.
 * @throws {Error} If the request fails
 */
export async function getContentAwareLicense(): Promise<ContentAwareLicense> {
  const response = await fetch(`/agent-config/v1/content-aware/license`, {
    method: "GET",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareLicense;
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to fetch Content Aware license");
    case 404:
      throw new Error("License not found for appliance");
    default:
      throw new Error("Unknown error when trying to fetch Content Aware license for appliance");
  }
}

/**
 * Create new Content Aware license for the current appliance.
 *
 * Requires Support Admin role.
 *
 * @returns The new Content Aware license on success.
 * @throws {Error} If the request fails
 */
export async function createContentAwareLicense(): Promise<ContentAwareLicense> {
  const response = await fetch(`/agent-config/v1/content-aware/license`, {
    method: "POST",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareLicense;
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to fetch Content Aware license");
    case 409:
      throw new Error("Appliance already has a Content Aware license");
    default:
      throw new Error("Unknown error when trying to create Content Aware license for appliance");
  }
}

/**
 * Delete the Content Aware license for the current appliance.
 *
 * Requires Support Admin role.
 *
 * @returns {void} on success.
 * @throws {Error} If the request fails
 */
export async function deleteContentAwareLicense(): Promise<void> {
  const response = await fetch(`/agent-config/v1/content-aware/license`, {
    method: "DELETE",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return;
    case 400:
      throw new Error("No config");
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to delete Content Aware license");
    default:
      throw new Error("Unknown error when trying to delete Content Aware license for appliance");
  }
}

/**
 * Deactivate the Content Aware license for the current appliance.
 *
 * Requires Support Admin role.
 *
 * @returns {ContentAwareLicense} Deactivated license data on success.
 * @throws {Error} If the request fails
 */
export async function deactivateContentAwareLicense(): Promise<ContentAwareLicense> {
  const response = await fetch(`/agent-config/v1/content-aware/license/deactivate`, {
    method: "POST",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareLicense;
    case 400:
      throw new Error("No config");
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to deactivate Content Aware license");
    default:
      throw new Error("Unknown error when trying to deactivate Content Aware license for appliance");
  }
}

/**
 * Reactivate the Content Aware license (if it exists) for the current appliance.
 *
 * Requires Support Admin role.
 *
 * @returns {ContentAwareLicense} Reactivated license data on success.
 * @throws {Error} If the request fails
 */
export async function reactivateContentAwareLicense(): Promise<ContentAwareLicense> {
  const response = await fetch(`/agent-config/v1/content-aware/license/reactivate`, {
    method: "POST",
    headers: headers(),
  });

  switch (response.status) {
    case 200:
      return (await response.json()) as ContentAwareLicense;
    case 400:
      throw new Error("No config");
    case 401:
    case 403:
      throw new Error("Not authenticated or authorised to reactivate Content Aware license");
    default:
      throw new Error("Unknown error when trying to reactivate Content Aware license for appliance");
  }
}
