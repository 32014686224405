import { FetchAggregatesResponse } from "../../types/Aggregates";
import { headers } from "./ApiHelpers";

const baseUrl = "/api/count";

const isFetchAggregatesResponse = (response: unknown): response is FetchAggregatesResponse =>
  response !== null &&
  typeof response === "object" &&
  "aggregate" in response &&
  response.aggregate !== null &&
  typeof response.aggregate === "object" &&
  "groups" in response.aggregate &&
  "classrooms" in response.aggregate &&
  "users" in response.aggregate &&
  "modifiedDate" in response.aggregate;

export const fetchAggregates = async (): Promise<FetchAggregatesResponse> => {
  const raw = await fetch(baseUrl, { headers: headers() });
  const response: unknown = await raw.json();
  if (isFetchAggregatesResponse(response)) return response;
  throw new Error("Invalid response from server");
};
