import React from "react";
import { ImportFile } from "./AssociationsImportFile";
import { CSVObject } from "./AssociationsCSVObject";
import "../../../css/Associations.css";

export class ImportFileList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      users: [],
      updateCsvObjectCallback: this.props.updateCsvObjectCallback,
      deleteCsvObjectCallback: this.props.deleteCsvObjectCallback,
      files: this.props.files,
      csvObjects: new Set(),
    };
  }

  componentWillReceiveProps(newProps) {
    let updatedCsvSet = this.state.csvObjects;
    for (let file of newProps.files) {
      if (Array.from(this.state.csvObjects).filter((csvObject) => csvObject.getFile() === file).length === 0) {
        updatedCsvSet.add(new CSVObject(file));
      }
    }
    this.setState({ csvObjects: updatedCsvSet });
  }

  handle_deleteFile(csvObject) {
    let updatedCsvSet = this.state.csvObjects;
    updatedCsvSet.delete(csvObject);
    this.setState({ csvObjects: updatedCsvSet });
    this.state.deleteCsvObjectCallback(csvObject);
    this.state.updateCsvObjectCallback(this.state.csvObjects);
  }

  readerCallback() {
    return this.state.updateCsvObjectCallback(this.state.csvObjects);
  }

  renderFiles() {
    let ret = [];
    for (let csvObj of this.state.csvObjects) {
      let file = csvObj.getFile();
      ret.push(
        <ImportFile
          key={file.name + file.size + file.lastModified}
          csvObject={csvObj}
          availableUsers={this.state.users}
          readerCallback={this.readerCallback.bind(this)}
          deleteFile={this.handle_deleteFile.bind(this)}
          setImportDisabled={this.props.setImportDisabled}
        />
      );
    }
    return ret;
  }

  render() {
    return <div id="list">{this.renderFiles()}</div>;
  }
}
