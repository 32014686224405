import React from "react";
import PropTypes from "prop-types";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import ObjectStore from "../../stores/ObjectStore";
import ObjectActions from "../../actions/ObjectActions";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";
import { get } from "shades";
import NuMultiSelect from "../../utils/NuMultiSelect";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";

export default class Timeout extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      timeout: {
        type: 0,
        mon: false,
        tues: false,
        wed: false,
        thur: false,
        fri: false,
        sat: false,
        sun: false,
        groups: [],
        networks: [],
        provider: "",
      },
      networks: [],
      saving: false,
      changes: true,
      loaded: false,
    };

    this.networkDisplayTextLens = get("name");
    this.networkValueLens = get("id");
  }

  componentDidMount() {
    this.handle_load();
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    ObjectStore.unlisten(this.onChange);
  }

  notifyChanges = () => {
    this.setState({
      changes: true,
    });
  };

  onChange = () => {
    this.setState({
      networks: ObjectStore.getNetworkObjects(),
    });
  };

  handle_load = () => {
    let self = this;
    Api.get("/config/ajax/authentication/timeouts/" + this.props.params.timeout, function (result) {
      self.setState({
        timeout: result["data"],
        loaded: true,
      });
    });
  };

  handle_Submit = () => {
    let self = this;
    self.setState({ saving: true });
    Api.post(
      "/config/ajax/authentication/timeouts/" + this.props.params.timeout,
      this.state.timeout,
      function (e) {
        /* Since its saved, go back to the main timeouts page */
        self.setState({ saving: false, changes: false });
        self.context.router.push("/config/device/authentication/timeouts");
      },
      function (error) {}
    );
  };

  handle_ChangeType = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.type = event.value;
    this.setState({
      timeout: timeout,
    });
  };

  handle_ChangeTimeout = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.timeout = event.value;
    this.setState({
      timeout: timeout,
    });
  };

  handle_ChangeMonday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.mon = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeTuesday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.tues = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeWednesday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.wed = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeThursday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.thur = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeFriday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.fri = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeSaturday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.sat = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };
  handle_ChangeSunday = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.sun = event.target.checked;
    this.setState({
      timeout: timeout,
    });
  };

  handle_ChangeAuthProvider = (event) => {
    this.notifyChanges();
    const timeout = this.state.timeout;
    timeout.provider = event.value;
    this.setState({
      timeout: timeout,
    });
  };

  handle_ChangeGroup = (group) => {
    const timeout = this.state.timeout;
    timeout.groups = group.map((group) => group.name);
    this.setState({
      timeout: timeout,
    });
    this.notifyChanges();
  };

  handle_AddNetwork = (network) => {
    const networkValue = this.networkValueLens(network);
    this.state.timeout.networks.push(networkValue);

    this.setState({ timeout: this.state.timeout });
    this.notifyChanges();
  };

  handle_RemoveNetwork = (networkValue) => {
    const removalIndex = this.state.timeout.networks.findIndex((element) => element === networkValue);
    this.state.timeout.networks.splice(removalIndex, 1);

    this.setState({ timeout: this.state.timeout });
    this.notifyChanges();
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Timeout Configuration</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Timeout Type</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          clearable={false}
                          value={this.state.timeout.type}
                          onChange={this.handle_ChangeType}
                          valueDataMap={[
                            [0, "Idle User Timeout (in seconds)"],
                            [1, "Elapsed Timeout (in seconds)"],
                            [2, "Absolute Timeout (Day and 24Hour Time)"],
                          ]}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Timeout</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          clearable={false}
                          value={this.state.timeout.timeout}
                          onChange={this.handle_ChangeTimeout}
                          valueDataMap={[
                            [5 * 60, "5 Minutes"],
                            [10 * 60, "10 Minutes"],
                            [15 * 60, "15 Minutes"],
                            [30 * 60, "30 Minutes"],
                            [45 * 60, "45 Minutes"],
                            [1 * 60 * 60, "1 Hour"],
                            [2 * 60 * 60, "2 Hour"],
                            [3 * 60 * 60, "3 Hour"],
                            [4 * 60 * 60, "4 Hour"],
                            [5 * 60 * 60, "5 Hour"],
                            [6 * 60 * 60, "6 Hour"],
                            [24 * 60 * 60, "1 Day"],
                            [2 * 24 * 60 * 60, "2 Days"],
                            [7 * 24 * 60 * 60, "1 Week"],
                            [14 * 24 * 60 * 60, "2 Week"],
                          ]}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Day of Week</div>
                    <div className="formgroup-element-fields">
                      <div className="controls">
                        <label className="day_selector">
                          Mon: <input type="checkbox" onChange={this.handle_ChangeMonday} checked={this.state.timeout.mon} />
                        </label>
                        <label className="day_selector">
                          Tues: <input type="checkbox" onChange={this.handle_ChangeTuesday} checked={this.state.timeout.tues} />
                        </label>
                        <label className="day_selector">
                          Wed: <input type="checkbox" onChange={this.handle_ChangeWednesday} checked={this.state.timeout.wed} />
                        </label>
                        <label className="day_selector">
                          Thur: <input type="checkbox" onChange={this.handle_ChangeThursday} checked={this.state.timeout.thur} />
                        </label>
                        <label className="day_selector">
                          Fri: <input type="checkbox" onChange={this.handle_ChangeFriday} checked={this.state.timeout.fri} />
                        </label>
                        <label className="day_selector">
                          Sat: <input type="checkbox" onChange={this.handle_ChangeSaturday} checked={this.state.timeout.sat} />
                        </label>
                        <label className="day_selector">
                          Sun: <input type="checkbox" onChange={this.handle_ChangeSunday} checked={this.state.timeout.sun} />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Groups</div>
                    <div className="formgroup-element-fields">
                      {this.state.loaded && (
                        <MultiGroupSearchSelector
                          onChangeGroups={this.handle_ChangeGroup}
                          preselected={this.state.timeout.groups?.map((group) => ({ value: group, name: group }))}
                          useLegacyId={true}
                        ></MultiGroupSearchSelector>
                      )}
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Networks</div>
                    <div className="formgroup-element-fields">
                      <NuMultiSelect
                        allItems={this.state.networks}
                        selectedValues={this.state.timeout.networks}
                        displayTextLens={this.networkDisplayTextLens}
                        valueLens={this.networkValueLens}
                        searchPlaceholder="Network Objects"
                        selectedPlaceHolder="No Network Objects Selected"
                        onAdd={this.handle_AddNetwork}
                        onRemove={this.handle_RemoveNetwork}
                      />
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Login Method</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          clearable={false}
                          value={this.state.timeout.provider}
                          onChange={this.handle_ChangeAuthProvider}
                          valueDataMap={[
                            ["provider.persistedsession", "Permanent Association"],
                            ["provider.wmi.nps", "NPS"],
                            ["provider.wmi.dc", "DC"],
                            ["provider.radius", "RADIUS"],
                            ["api.linewizecp.password", "Captive Portal Password Login"],
                            ["api.linewizecp.guest", "Captive Portal Guest"],
                            ["api.linewizecp.chrome", "Chrome Extension"],
                            ["api.linewizecp.google", "Captive Portal Google"],
                            ["api.linewizecp.azuread", "Captive Portal AzureAD"],
                          ]}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} saving={this.state.saving} changes={this.state.changes} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}

Timeout.contextTypes = {
  router: PropTypes.object.isRequired,
};
