import { Box, Flex, Icon, Image, Spinner, Text } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import { getHeaderString, getSignatureData, InfoBoxProps, InfoBoxSignatureData } from "./InfoBoxHelpers";

const InfoBoxNew = ({ type, target, category, signatureId }: InfoBoxProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false);
  const [headerText, setHeaderText] = useState<string>("");
  const [signatureData, setSignatureData] = useState<InfoBoxSignatureData>({});

  const updateHeader = () => setHeaderText(target ? getHeaderString(type, category, target) : "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateHeader(), [type, target, category]);

  useEffect(() => {
    const update = async () => {
      if (!active) {
        return;
      }
      updateHeader();

      if (type === "signature" && signatureId) {
        const data = await getSignatureData(signatureId);
        setSignatureData(data);
      }
    };
    void update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Flex position="relative">
      <Flex>
        <Box
          cursor="pointer"
          onClick={() => {
            setActive(true);
          }}
        >
          <Icon mt="sp4" data-testid="infoBoxIcon" icon="fa-circle-question" ml="sp8" color="neutrals.100" />
        </Box>
      </Flex>
      {active && (
        <Box
          p="sp16"
          border="1px"
          borderColor="neutrals.50"
          borderRadius="6px"
          bgColor="neutrals.0"
          position="absolute"
          w="330px"
          zIndex="1"
          top="20px"
          left="6px"
        >
          <Flex justifyContent="space-between">
            <Flex>
              {signatureData && signatureData.favicon_url && (
                <Image
                  maxW="20px"
                  data-testid="infoBoxHeaderIcon"
                  m="0"
                  src={signatureData.favicon_url}
                  fallback={<Spinner data-testid="infoBoxHeaderIcon" />}
                  pr="sp4"
                />
              )}
              <Text fontWeight="medium" color="text.paragraph.title" fontSize="12px" data-testid="infoBoxHeader">
                {headerText}
              </Text>
            </Flex>
            <Box
              cursor="pointer"
              onClick={() => {
                setActive(false);
              }}
            >
              {signatureData.url && signatureData.url !== "None" && (
                <Link href={`http://${signatureData.url}`} target="_blank" rel="noopener noreferrer" to={""}>
                  <Icon icon="fa-sharp fa-light fa-arrow-up-right-from-square" variant="solid" mr="sp8" data-testid="infoBoxLink" />
                </Link>
              )}
              <Icon data-testid="infoBoxClose" icon="fa-xmark" ml="sp8" color="neutrals.100" />
            </Box>
          </Flex>
          <Flex pt="sp4">
            <Text fontSize="12px" data-testid="infoBoxDescription" color="text.paragraph.regular">
              {signatureData.description
                ? signatureData.description !== "None"
                  ? signatureData.description
                  : "No description available."
                : "No description available."}
            </Text>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default InfoBoxNew;
