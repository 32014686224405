import React from "react";
import ReactDOM from "react-dom";
import { initDatadogRum } from "./js/utils/datadogUtils";
import AppRoot from "./js/components/AppRoot";
import * as am4core from "@amcharts/amcharts4/core";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./js/utils/QueryClient";
import { replaceIdiomaticTextsWithSvgs } from "./js/utils/fontAwesomeUtils";

import "./css/style.css";
import "./css/bootstrap.min.css";
import "rc-tooltip/assets/bootstrap.css";
import "./scss/main.scss";

import $ from "jquery";

// Makes jQuery available in the global scope.
// This is generally discouraged in modern React development, it is better to import jQuery in the specific files that need it.
// But we need this because bootstrap.min.js expects it to be available in the global scope.
window.jQuery = window.$ = $;

require("./js/bootstrap.min.js");

am4core.addLicense("CH262668106");
am4core.options.autoDispose = true;

initDatadogRum();

replaceIdiomaticTextsWithSvgs();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AppRoot />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("surfwize-web")
);
