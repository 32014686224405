import React from "react";
import Criteria from "./Criteria";

export default class IPv4Criteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = {};
    }

    this.state = {
      criteria: props.criteria,
    };

    /*{type: "ipv4.address", negate: false, conditions: {ip: "0.0.0.0"}}}*/
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = {};
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_IpChange = (event) => {
    this.state.criteria.conditions.ip = event.target.value;
    this.bubble_Changes(this.state.criteria);
  };

  bubble_Changes = (criteria) => {
    /* Push changes up to the root */
    this.props.bubbleMethod(criteria);
  };

  render_ValidationError = (errorMessage) => {
    return <div className="errorMessage">{errorMessage}</div>;
  };

  render() {
    return (
      <Criteria label="IP" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-textfield">
          <input type="text" onChange={this.handle_IpChange} value={this.state.criteria.conditions["ip"]} placeholder="IP Address" />
        </div>
        <div>{this.render_ValidationError(this.props.criteria.errorMessage || "")}</div>
      </Criteria>
    );
  }
}
