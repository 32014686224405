import React from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

class BaseModal extends React.Component {
  handleClose = (e) => {
    e.stopPropagation();
    this.props.handleHide();
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const backdropClass = clsx("base-modal-backdrop", !this.props.show && "base-modal-hide");
    const buttonsClass = clsx("base-modal-buttons", this.props.centerButtons && "base-modal-buttons-centered");
    const baseClass = clsx("base-modal", this.props.noBasePadding && "no-padding");
    const bodyElement = document.body;

    return ReactDOM.createPortal(
      <div className={backdropClass} onMouseDown={this.handleClose}>
        <div onClick={this.stopPropagation} onMouseDown={this.stopPropagation} className={baseClass}>
          <div className="base-modal-close">
            <img
              alt="Close modal button"
              className="modal-close-icon"
              width="13"
              height="13"
              src="/static/images/icon_modal_close.svg"
              onClick={this.handleClose}
            />
          </div>
          <div className="base-modal-content">{this.props.children}</div>
          {this.props.buttons && <div className={buttonsClass}>{this.props.buttons}</div>}
        </div>
      </div>,
      bodyElement
    );
  }
}

export default BaseModal;
