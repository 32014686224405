import { Spacer } from "@chakra-ui/layout";
import { Box, Button, Flex, Grid, GridItem, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import StudentNamePill from "../../../modules/StudentNamePill";
import PageWithHeader from "../../templates/PageWithHeader";
import EventTimelineCard from "./EventTimelineCard";
import FactsCardSet from "./FactsCardSet";
import GraphCard from "./GraphCard";
import RedFlagsCard from "./RedFlagsCard";
import TopTrafficTypesCard from "./TopTrafficTypesCard";
import { useDateRangeFilterStore } from "../../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../../actions/GlobalDatePickerVisibilityActions";
import FindUserModal from "../../modals/FindUserModal";
import SvgIconSearch from "../../../svgr/SvgIconSearch";
import { ApiDates } from "../../../utils/DateTimeUtil";

interface IUserDashboardIndexProps {
  location?: {
    query?: ApiDates;
  };
  params: { user: string };
}

const UserDashboardIndex = (props: IUserDashboardIndexProps): JSX.Element => {
  const filterStore = useDateRangeFilterStore();
  const user = props.params.user;
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Users"), url: "/surfwize/reporting/users", isActive: false },
    { title: t("User Dashboard"), isActive: true },
  ];
  const title = "User Dashboard";
  const [showFindUser, setShowFindUser] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);
  }, []);

  const handleFindUserBtnClick = () => {
    setShowFindUser(true);
  };

  const handleHideFindUser = () => {
    setShowFindUser(false);
  };

  useEffect(() => {
    if (props.location?.query && props.location.query.startDate && props.location.query.endDate)
      filterStore.updateDateFilter({
        startDate: props.location.query.startDate,
        endDate: props.location.query.endDate,
        startTime: props.location.query.startTime ? props.location.query.startTime : "00:00:00",
        endTime: props.location.query.endTime ? props.location.query.endTime : "23:59:59",
        relativeKey: "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.query]);

  return (
    <PageWithHeader title={title} breadcrumbs={breadcrumbs}>
      <Box m="sp24">
        <Flex>
          <StudentNamePill username={user} />
          <Spacer />
          <Box p="4">
            <Button variant="primary" marginEnd="sp8" onClick={handleFindUserBtnClick}>
              <SvgIconSearch color="white" alt="open classwize modal" style={{ marginRight: "6px" }} />
              Find user
            </Button>
            <FindUserModal open={showFindUser} onClose={handleHideFindUser} />
            <Link to={"/surfwize/reporting/users/" + user + "/journey"}>
              <Button variant="primary" marginEnd="sp8">
                User Journey
              </Button>
            </Link>
            <Link
              to={
                "/surfwize/reporting/users/" +
                user +
                "/timeline?end_date=" +
                filterStore.getEndDate() +
                "&end_time=" +
                filterStore.getEndTime()
              }
            >
              <Button variant="primary">User Timeline</Button>
            </Link>
          </Box>
        </Flex>
        <Grid templateColumns="repeat(12, 1fr)" gap="sp16" marginTop="sp24">
          <GraphCard user={user} dateFilters={filterStore.getApiDates()} />
          <RedFlagsCard user={user} dateFilters={filterStore.getApiDates()} />
        </Grid>
        <Grid templateColumns="repeat(24, 1fr)" gap="sp16" marginTop="sp24">
          <GridItem colSpan={8} background="neutrals.0" borderRadius="sm" height="fit-content">
            <TopTrafficTypesCard user={user} dateFilters={filterStore.getApiDates()} />
          </GridItem>
          <GridItem colSpan={5} borderRadius="sm">
            <FactsCardSet user={user} dateFilters={filterStore.getApiDates()} />
          </GridItem>
          <GridItem colSpan={11} borderRadius="sm">
            <EventTimelineCard user={user} dateFilters={filterStore.getApiDates()} />
          </GridItem>
        </Grid>
      </Box>
    </PageWithHeader>
  );
};

export default UserDashboardIndex;
