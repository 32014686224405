import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";

export default class EditButton extends React.Component {
  onClick = () => {
    if (this.props.to !== undefined) {
      this.context.router.push(this.props.to);
    } else {
      this.props.onClick();
    }
  };

  render() {
    return (
      <Link onClick={this.onClick} data-testid="edit-button">
        {this.props.isEditing ? <i className="fa fa-refresh fa-spin fa-fw" /> : <i className="fa fa-pencil" />}
      </Link>
    );
  }
}

EditButton.contextTypes = {
  router: PropTypes.object.isRequired,
};
