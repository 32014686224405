import React from "react";
import SleekModal from "../../../modules/SleekModal";

class SisResetDialog extends React.Component {
  state = {
    acceptReset: false,
  };

  handleSubmit = () => {
    if (!this.state.acceptReset) {
      // Show error
      console.log("Reset not accepted");
      return;
    }

    this.setState({ acceptReset: false });

    this.props.handleResetConfirmed();
  };

  render() {
    return (
      <SleekModal
        show={this.props.show}
        className={this.props.modalClassName}
        title="Are you sure you wish to reset this SIS?"
        description={
          <p>
            This will reset the time of the last successful sync, allowing for a fresh sync using OneRoster.
            <br />
            <br />
            After the reset, the last sync column will display "Last Sync Unavailable". This is intentional.
            <br />
            <br />
            No data will be lost
          </p>
        }
        handleHide={this.props.handleHide}
        buttons={
          <button className="mui-btn mui-btn--raised modal-button" disabled={!this.state.acceptReset} onClick={this.handleSubmit}>
            Yes, Reset
          </button>
        }
        centerButtons
      >
        <div className="sis-delete-dialog-content">
          <div className="mui-checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.acceptReset}
                onChange={() => this.setState({ acceptReset: !this.state.acceptReset })}
              />
              I understand
            </label>
          </div>
        </div>
      </SleekModal>
    );
  }
}

export default SisResetDialog;
