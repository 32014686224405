import React, { ChangeEvent, useEffect, useState } from "react";
import Api from "../utils/Api";
import {
  useTranslation,
  Box,
  Text,
  Select,
  Button,
  TextInput,
  InlineNotification,
  Table,
  Tbody,
  Tr,
  Td,
} from "@familyzone/component-library";
import CardBasedPage from "./templates/CardBasedPage";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { branches } from "./DeviceCreationConst";
import { Link } from "react-router";
import SessionStore from "../stores/SessionStore";

const DeviceCreation = (): JSX.Element => {
  const { t } = useTranslation();

  type SupportAdminSessionStore = {
    isSupportAdmin: () => boolean;
  };

  type DeviceCreationState = {
    timezone: string;
    timezones: string[];
    branch: string;
    deviceid: string;
    devicekey: string;
    errorDeviceText: string;
    errorBranchText: string;
    errorTimezoneText: string;
    isSupportAdmin: boolean;
    submittedCreateDevice: boolean;
    completed: boolean;
  };

  const initialState = {
    timezone: "",
    timezones: [],
    branch: "",
    deviceid: "",
    devicekey: "",
    errorDeviceText: "",
    errorBranchText: "",
    errorTimezoneText: "",
    isSupportAdmin: (SessionStore as SupportAdminSessionStore).isSupportAdmin(),
    submittedCreateDevice: false,
    completed: false,
  } as DeviceCreationState;

  const [state, setState] = useState(initialState);

  type TimezoneResult = {
    result: string[];
  };

  useEffect(() => {
    void (async () => {
      const result = (await Api.getAsync("/managedevice/ajax/device/timezones")) as TimezoneResult;
      setState({ ...state, timezones: result.result });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type SelectItem = {
    value: string;
  };

  const timezones = (): Array<SelectItem> => {
    return state.timezones.map((subnetGroup) => {
      return { value: subnetGroup };
    });
  };

  const branchOptions = (): Array<SelectItem> => {
    return branches.map((s) => {
      return { value: s[1] };
    });
  };

  const branchCode = (value: string): string => {
    const found = branches.find((b) => b[1] === value);
    if (found == null) return "";
    else return found[0];
  };

  const breadcrumbs = [{ title: t("Devices"), url: "/devices", isActive: false }];

  const SectionHeading: React.FC<{ title: string }> = ({ title }) => {
    return (
      <Box borderRadius="sm" p="sp12" backgroundColor="neutrals.20">
        <Text fontFamily="Poppins" fontSize="sm" fontWeight="medium" color="text.paragraph.regular" role="heading">
          {title}
        </Text>
      </Box>
    );
  };

  const onChangeDeviceId = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, deviceid: event.target.value });
  };

  const formValid = () => {
    const emptyBranch = state.branch.trim().length === 0;
    const emptyTimezone = state.timezone.trim().length === 0;

    const formError = emptyBranch || emptyTimezone;
    if (formError) {
      setState({
        ...state,
        errorBranchText: emptyBranch ? t("Select a branch") : "",
        errorTimezoneText: emptyTimezone ? t("Select a timezone") : "",
      });
    }
    return !formError;
  };

  const clickAddNetworkButton = () => {
    if (formValid()) {
      const branch = state.branch.trim();
      const timezone = state.timezone.trim();
      const deviceid = state.deviceid.trim();

      Api.post(
        "/newdevice",
        {
          deviceid: deviceid,
          branch: branch,
          timezone: timezone,
        },
        (success: { device_key: string }) => {
          setState({
            ...state,
            devicekey: success.device_key,
            completed: true,
          });
        },
        (error: { responseJSON: { error: string } }) => {
          setState({ ...state, completed: false, errorDeviceText: error.responseJSON.error });
        }
      );
    }
  };

  const registerTheDevice = () => {
    if (state.submittedCreateDevice) return <Box></Box>;
    else
      return (
        <Button data-testid="submitForm" aria-label={t("Register the device")} onClick={clickAddNetworkButton} variant="primary">
          <i className="fa fa-plus" style={{ paddingRight: "10px" }} />
          {t("Register the device")}
        </Button>
      );
  };

  const registerLinkNewDevice = () => {
    return (
      <Box px="sp12" py="sp16">
        <FormControl mb="sp16">
          <FormLabel mr="sp16" htmlFor="pauseInternet.schoolHoursPeriodId-input">
            {t("Device ID")}
          </FormLabel>
          <TextInput
            data-testid="deviceId"
            type="text"
            placeholder={t("Device ID")}
            width={"200px"}
            onChange={onChangeDeviceId}
            errorMessage={state.errorDeviceText}
          />
        </FormControl>

        <FormControl mb="sp16">
          <FormLabel mr="sp16" htmlFor="pauseInternet.schoolHoursPeriodId-input">
            {t("Branch")}
          </FormLabel>
          <Select
            id="branch"
            data-testid="branch"
            items={branchOptions()}
            placeholder={t("Branch")}
            errorMessage={state.errorBranchText}
            onChange={(e: null | { value: string | number }) => {
              if (e != null) setState({ ...state, branch: branchCode(e.value.toString()) });
            }}
          />
        </FormControl>

        <FormControl mb="sp16">
          <FormLabel mr="sp16" htmlFor="pauseInternet.schoolHoursPeriodId-input">
            {t("Timezone")}
          </FormLabel>
          <Select
            id="timezone"
            data-testid="timezone"
            items={timezones()}
            placeholder={t("Timezone")}
            errorMessage={state.errorTimezoneText}
            onChange={(e: { value: string | number } | null) => {
              if (e != null) setState({ ...state, timezone: e.value.toString() });
            }}
          />
        </FormControl>

        {registerTheDevice()}
      </Box>
    );
  };

  const successfulDeviceRegistered = () => {
    return (
      <Box minHeight="200px" p="sp24">
        <InlineNotification
          isRemovable={false}
          notificationDescription="You must copy the values below into the device configuration, once completed, contact the support team to approve the subscription."
          notificationTitle="Congrats! Your device was registered"
          status="info"
        />

        <Table variant="simple" style={{ maxWidth: "450px", marginTop: "20px" }}>
          <Tbody>
            <Tr>
              <Td primaryText="Device ID" />
              <Td primaryText="Device Key" />
            </Tr>
            <Tr>
              <Td primaryText={state.deviceid} />
              <Td primaryText={state.devicekey} />
            </Tr>
          </Tbody>
        </Table>

        <Box style={{ marginTop: "20px" }}>
          <Link to="/devices">
            <Button aria-label={t("Continue")} variant="primary" float={"left"} marginBottom={"20px"}>
              {t("Continue")}
            </Button>
          </Link>
        </Box>
      </Box>
    );
  };

  const supportAdminRequired = () => {
    return (
      <Box minHeight="200px" p="sp24">
        <SectionHeading title={t("Insufficient permissions")} />
        <Box px="sp12" py="sp16">
          <img className="warning_title" src="/static/images/IconWarningTriangle.svg" alt="" />
          <Box>{t("The settings on this page are only for modification by Linewize staff members.")}</Box>
          <Box>{t("Contact your configuration team, or reach out to help@linewize.com")}</Box>
        </Box>
      </Box>
    );
  };

  const pageContents = () => {
    if (!state.isSupportAdmin) return supportAdminRequired();
    else if (state.completed) return successfulDeviceRegistered();
    else return registerLinkNewDevice();
  };

  const myPage = () => {
    return (
      <CardBasedPage title={t("Create New Device")} breadcrumbs={breadcrumbs}>
        {pageContents()}
      </CardBasedPage>
    );
  };

  return myPage();
};

export default DeviceCreation;
