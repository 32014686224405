import React, { useState } from "react";
import CriteriaPrinter from "../CriteriaPrinter";
import { FilteringSourceCriteria } from "./criteriaTypes";
import { useGroupDescriptionStore } from "../../storez/GroupDescriptionStore";
import { UserFriendlyGroupNames } from "../../types/Groups";
import { Text } from "@familyzone/component-library";
import { fetchDescriptionsFromStore } from "../../components/GroupSearch/GroupSearchHelper";

interface UserFriendlyGroupsCriteriaPrinterProps {
  criteria: FilteringSourceCriteria[];
}

export const UserFriendlyGroupsCriteriaPrinter: React.FC<UserFriendlyGroupsCriteriaPrinterProps> = ({ criteria }) => {
  const groupNames: string[] = [];
  const nonGroupCriteria: FilteringSourceCriteria[] = [];
  const [groupText, setGroupText] = useState<string>("");

  criteria.forEach((criterion) => {
    if (criterion.type === "group" || criterion.type === "exclude.group") {
      const conditions = criterion.conditions;
      if (isStringArray(conditions)) {
        groupNames.push(...conditions);
      }
    } else {
      nonGroupCriteria.push(criterion);
    }
  });

  const getGroups = useGroupDescriptionStore((state) => state.getGroups);
  void getGroupDescriptions(groupNames, getGroups).then((result) => {
    result === "" ? setGroupText("") : setGroupText(`${result}, `);
  });

  return (
    <Text>
      {groupText}
      {(groupNames.length === 0 || nonGroupCriteria.length > 0) && <CriteriaPrinter criteria={nonGroupCriteria} />}
    </Text>
  );
};

const getGroupDescriptions = async (
  groups: string[],
  getGroups: (groupnames: string[]) => Promise<UserFriendlyGroupNames[]>
): Promise<string> => {
  if (groups.length === 0) return "";

  const result = await fetchDescriptionsFromStore(groups, getGroups);
  return result.join(", ");
};

const isStringArray = (item: unknown): item is string[] => {
  if (!Array.isArray(item)) return false;
  for (const i of item) {
    if (typeof i !== "string") {
      return false;
    }
  }
  return true;
};
