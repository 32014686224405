import alt from "../alt";

class RiskSelectorActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(devices) {
    return devices;
  }

  reset() {
    return "nothing";
  }
}

export default alt.createActions(RiskSelectorActions);
