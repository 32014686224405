import React from "react";

export default class Header extends React.Component {
  render() {
    return (
      <div>
        <h2>{this.props.children}</h2>
      </div>
    );
  }
}
