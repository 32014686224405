import { orderBy } from "lodash";

export function sortTable<T>(data: T[], column: string, order: "asc" | "desc"): T[] {
  const toLowerCase = (d: T) => {
    const cell = d[column as keyof T];
    if (typeof cell === "string") return cell.toLowerCase();
    return cell;
  };

  return orderBy<T>(data, [toLowerCase], order);
}
