import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum Language {
  EN_UK = "en_uk",
  EN_XX = "en_XX",
}

export interface AccountSettings {
  language: Language;
}

export interface AccountStore {
  settings: AccountSettings;
  setLanguage: (language: Language) => void;
  reset: () => void;
}

// NOTE: This store persists changes to `localStorage`!
export const useAccountStore = create<AccountStore>()(
  persist(
    (set, get) => ({
      settings: { language: Language.EN_UK },

      setLanguage: (language: Language) => {
        set({ settings: { ...get().settings, language } });
      },

      reset: () => {
        set({ settings: { language: Language.EN_UK } });
      },
    }),
    { name: "accountStore" }
  )
);
