import React from "react";

import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";

import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import Separator from "../../modules/Separator";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import SearchableSelect from "../../modules/SearchableSelect";
import CriteriaSelector from "../../modules/criteria/CriteriaSelector";
import { getFingerprints, getObjects, getTimePeriods } from "../../pages/filtering/ApiHelpers";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rule: this.props.rule,
      fingerprints: this.props.fingerprint || [],
      ipObjects: this.props.ipObjects || [],
      macObjects: this.props.macObjects || [],
      timePeriods: this.props.timePeriods || [],
    };
  }

  componentDidMount() {
    InterfaceStore.listen(this.onChange);

    setTimeout(function () {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  componentWillReceiveProps(nextprops) {
    this.setState({
      rule: nextprops.rule,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeWanInterface = (event) => {
    const rule = this.state.rule;
    rule.uplink = event.value;
    this.setState({ rule: rule });
  };

  handle_SourceCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.source_criteria = criteria;
    this.setState({ rule: rule });
  };

  handle_DestinationCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.destination_criteria = criteria;
    this.setState({ rule: rule });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.rule);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let i of this.state.devices) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  };

  render() {
    const sourceCustomOptions = {
      group: "Group",
      "exclude.group": "Exclude Group",
      "source.user": "User",
      protocol: "Protocol",
      "ipv4.range": "Network Range",
      ipv4: "Network",
      "ipv4.address": "IP Address",
      "ipv4.alias": "IP Address Object",
      "source.mac": "Mac Address",
      fingerprint: "Device Type",
      "source.mac.pool": "Mac Address Object",
      "transport.port": "Port",
      "transport.portrange": "Port Range",
      device: "Interface",
      geoip: "Country",
      timeperiod: "Time Periods",
    };

    const destinationCustomOptions = {
      "source.user": "User",
      protocol: "Protocol",
      "ipv4.range": "Network Range",
      ipv4: "Network",
      "ipv4.address": "IP Address",
      "ipv4.alias": "IP Address Object",
      "source.mac": "Mac Address",
      fingerprint: "Device Type",
      signature: "Signature",
      "source.mac.pool": "Mac Address Object",
      "transport.port": "Port",
      "transport.portrange": "Port Range",
      device: "Interface",
      geoip: "Country",
      timeperiod: "Time Periods",
    };

    if (this.props.visible) {
      return (
        <ModalWindow title="Editing Rule" handleHide={this.handleHide} actions={this.renderActions()}>
          Sources
          <CriteriaSelector
            customOptions={sourceCustomOptions}
            ipObjects={this.props.ipObjects}
            macObjects={this.props.macObjects}
            fingerprints={this.props.fingerprints}
            timePeriods={this.props.timePeriods}
            selected={this.state.rule.source_criteria}
            onChange={this.handle_SourceCriteriaChanged}
          />
          Destination
          <CriteriaSelector
            customOptions={destinationCustomOptions}
            ipObjects={this.props.ipObjects}
            macObjects={this.props.macObjects}
            fingerprints={this.props.fingerprints}
            timePeriods={this.props.timePeriods}
            selected={this.state.rule.destination_criteria}
            onChange={this.handle_DestinationCriteriaChanged}
          />
          Wan Device
          <div className="mui-select">
            <SearchableSelect
              value={this.state.rule.uplink}
              onChange={this.handle_ChangeWanInterface}
              valueDataMap={this.get_SelectionMap()}
            ></SearchableSelect>
          </div>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class Wan extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      wan: {
        uplink_automatic_masquerading_enabled: false,
        uplink_load_balancing_enabled: false,
        rules: [],
      },
      visible__edit_rule: false,
      editing__permission: undefined,
      loaded: false,
      saving: false,
      changes: true,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = async () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/wan", function (result) {
      if (result["data"]) {
        self.setState({
          wan: result["data"],
          loaded: true,
        });
      }
    });

    const data = await Promise.all([getObjects(), getFingerprints(), getTimePeriods()]);
    this.setState({
      data: {
        ipObjects: data[0].filter((obj) => obj.type === 1 || obj.type === 0),
        fingerprints: data[1],
        timePeriods: data[2],
        macObjects: data[0].filter((obj) => obj.type === 4),
      },
    });
  };

  handle_Submit = () => {
    let self = this;
    self.setState({ saving: true, changes: false });
    Api.post(
      "/edgewize/ajax/wan",
      this.state.wan,
      function (e) {
        self.handle_load();
        self.setState({ saving: false, changes: false });
      },
      function (error) {}
    );
  };

  handle_AddUplinkRule = () => {
    let self = this;
    Api.put(
      "/edgewize/ajax/wan",
      {},
      function (e) {
        self.handle_load();
      },
      function (error) {}
    );
  };

  handle_ChangeMasqueradingEnabled = (event) => {
    const wan = this.state.wan;
    wan["uplink_automatic_masquerading_enabled"] = event.target.checked;
    this.setState({ wan: this.state.wan, changes: true });
  };

  handle_ChangeLbEnabled = (event) => {
    const wan = this.state.wan;
    wan["uplink_load_balancing_enabled"] = event.target.checked;
    this.setState({ wan: wan, changes: true });
  };

  /* Uplink rules */
  handle_UplinkRuleDelete = (rule) => {
    const wan = this.state.wan;
    let rules = [];
    for (let x of wan.rules) {
      if (x["id"] !== rule["id"]) {
        rules.push(x);
      }
    }

    wan.rules = rules;
    this.setState({ wan: wan, changes: true });

    this.handle_Submit();
  };

  handle_UplinkRuleSave = (rule) => {
    const wan = this.state.wan;
    let rules = [];
    for (let x of this.state.wan.rules) {
      if (x["id"] !== rule["id"]) {
        rules.push(x);
      } else {
        rules.push(rule);
      }
    }

    wan.rules = rules;
    this.setState({ wan: wan, changes: true });

    this.handle_Submit();
  };

  handle_UplinkRuleEdit = (rule) => {
    this.setState({
      visible__edit_rule: true,
      editing__permission: rule,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({ visible__edit_rule: false });
  };

  render_UplinkRules = () => {
    let self = this;
    let columns = [
      {
        title: "Sources",
        data: function (row) {
          return <CriteriaPrinter criteria={row["source_criteria"]} />;
        },
        onclick: undefined,
      },
      {
        title: "Destination",
        data: function (row) {
          return <CriteriaPrinter criteria={row["destination_criteria"]} />;
        },
        onclick: undefined,
      },
      {
        title: "WAN Configuration",
        data: function (row) {
          if (row["uplink"] === "" || row["uplink"] === undefined) {
            return "No Uplink Set";
          }

          return row["uplink"];
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_UplinkRuleEdit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_UplinkRuleDelete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.wan.rules}
        className="formgroup-table"
        hide_headers={false}
        loaded={this.state.loaded}
      />
    );
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Configuration
            <DumbBusyIndicator loaded={this.state.loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <div className="formgroup">
              <div className="formgroup-content">
                <div className="mui-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.wan.uplink_automatic_masquerading_enabled}
                      onChange={this.handle_ChangeMasqueradingEnabled}
                    />
                    Masquerading Enabled
                  </label>
                </div>
                <div className="mui-checkbox">
                  <label>
                    <input type="checkbox" checked={this.state.wan.uplink_load_balancing_enabled} onChange={this.handle_ChangeLbEnabled} />
                    Load Balancing Enabled
                  </label>
                </div>
              </div>
            </div>

            <div className="formgroup">
              <div className="formgroup_title">
                <div className="formgroup_title_title">Uplink Rules</div>
                <div className="formgroup_title_rightlink_button">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="heading_button" onClick={this.handle_AddUplinkRule}>
                    Add Uplink Rule
                  </a>
                </div>
              </div>
              <AddEditRule
                visible={this.state.visible__edit_rule}
                handleSave={this.handle_UplinkRuleSave}
                handleClose={this.handler__clickCloseAdd}
                rule={this.state.editing__permission}
                fingerprints={this.state.data?.fingerprints}
                timePeriods={this.state.data?.timePeriods}
                macObjects={this.state.data?.macObjects}
                ipObjects={this.state.data?.ipObjects}
              />
              {this.render_UplinkRules()}
            </div>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} saving={this.state.saving} changes={this.state.changes} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
