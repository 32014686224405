import React, { useState } from "react";
import Api from "../utils/Api";
import { isValidPassword } from "../utils/Validation";
import LoginWrapper from "./LoginWrapper";
import Link from "./Link";
import ReturnToSignIn from "./ReturnToSignIn";
import { Box, Button, Password, Text } from "@familyzone/component-library";

interface Props {
  params: {
    token: string;
  };
}

const LoginResetPasswordConfirm: React.FC<Props> = ({ params }) => {
  const [password, setPassword] = useState("");
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);

  const isPasswordValid = () => isValidPassword(password);

  const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setShowError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    setSaving(true);
    event.preventDefault();
    Api.unauthenticated_post(
      "/reset/confirm",
      {
        password: password,
        token: params.token,
      },
      () => {
        setSaving(false);
        window.location.href = "/login?passwordreset=true";
      },
      (e: unknown, rep: unknown) => {
        console.error("Error Saving Password:", e, rep);
        setShowError(true);
        setSaving(false);
      }
    );
  };

  const errorMessage = () => {
    if (showError) {
      return (
        <Text mt="sp4" color="error.plainText" fontSize="sm">
          This password reset link has expired.{" "}
          <Link fontSize="sm" fontFamily="links" fontWeight="500" to={"/login/resetpassword"}>
            Request a new link.
          </Link>
        </Text>
      );
    }
    return "";
  };

  const resetConfirm = () => (
    <LoginWrapper header="Reset Your Password">
      <Text fontFamily="paragraphs" fontWeight="500" mt="sp32" pt="sp16" color="text.title">
        New Password
      </Text>
      <Text fontFamily="paragraphs" fontSize="sm" color="text.paragraph.regular">
        Password must be at least 16 characters long.{" "}
        <Link fontSize="sm" fontFamily="links" fontWeight="500" to="https://help.linewize.com/hc/articles/5766977328284" target="_blank">
          Why?
        </Link>
      </Text>
      <Box mb="sp32">
        <Password value={password} onChange={handleChange} errorMessage={errorMessage() as string} />
      </Box>
      <Button
        data-testid="resetpasswordconfirmbutton"
        mt="sp8"
        mb="sp64"
        isLoading={saving}
        disabled={!isPasswordValid() || saving}
        onClick={handleSubmit}
        variant="primary"
        rightIcon={<i className="fa fa-arrow-right" aria-hidden="true"></i>}
      >
        Save Password
      </Button>
      <ReturnToSignIn />
    </LoginWrapper>
  );

  return resetConfirm();
};

export default LoginResetPasswordConfirm;
