import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Modal,
  ModalBody,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { Th as ChakraTh } from "@chakra-ui/table";
import SectionHeading from "../../header/components/SectionHeading";
import { useCommunitySafetyNetStore, CommunitySafetyNetStore } from "../../../storez/SafetyNetStore";
import { ResponseError } from "../../../types/Api";
import { SortType } from "../../templates/SortSearchTable";
import { SafetyNetPolicy } from "../../../types/SafetyNet";

interface SafetyNetModalProps {
  open: boolean;
  enablingDelegation?: boolean;
  onClose: () => void;
  onSave: () => void;
}

const CategoriesSection: React.FC<{
  loading: boolean;
  saving: boolean;
  policies: SafetyNetPolicy[];
  title: string;
  onChange: (policies: SafetyNetPolicy[]) => void;
}> = ({ loading, saving, policies, title, onChange }) => {
  const { t } = useTranslation();

  const [sortDirection, setSortDirection] = useState<SortType>("asc");

  const isAllChecked = policies.length > 0 && policies.every((p) => p.checked);

  const handleCheckAll = () => {
    onChange(
      policies.map((p) => {
        p.checked = !isAllChecked;
        return p;
      })
    );
  };

  const handleCheckOne = (policy: SafetyNetPolicy) => {
    policy.checked = !policy.checked;
    onChange([policy]);
  };

  const handleSortDirectionChange = (_: unknown, direction: SortType) => {
    setSortDirection(direction || "asc");
  };

  return (
    <>
      <SectionHeading title={t(title)} />
      <Table mt="sp16">
        <Thead>
          <Tr>
            <ChakraTh w="32px" pr="0">
              <Checkbox isChecked={isAllChecked} isDisabled={loading || saving || policies.length === 0} onChange={handleCheckAll}>
                {/* Label for assistive technologies (screen readers) / allow getByText to work */}
                <Box position="absolute" w="1px" h="1px" overflow="hidden" whiteSpace="nowrap">
                  {t(`Select All ${title}`)}
                </Box>
              </Checkbox>
            </ChakraTh>
            <Th
              w="160px"
              headerText={t("Category")}
              columnName="category"
              sortDirection={sortDirection}
              handleSort={handleSortDirectionChange}
            />
            <Th headerText={t("Description")} columnName="description" />
          </Tr>
        </Thead>
        <Tbody loaded={!loading}>
          {policies
            .sort((policy1, policy2) => {
              return sortDirection === "asc" ? policy1.name.localeCompare(policy2.name) : policy2.name.localeCompare(policy1.name);
            })
            .map((policy, i) => (
              <Tr key={policy.id || i}>
                <Td w="32px" pr="0">
                  <Checkbox isChecked={policy.checked} isDisabled={saving} onChange={() => handleCheckOne(policy)}>
                    {/* Label for assistive technologies (screen readers) / allow getByText to work */}
                    <Box position="absolute" w="1px" h="1px" overflow="hidden" whiteSpace="nowrap">
                      {policy.name}
                    </Box>
                  </Checkbox>
                </Td>
                <Td w="160px">
                  <Text whiteSpace="nowrap">{policy.name}</Text>
                </Td>
                <Td>
                  <Text>{policy.description ?? ""}</Text>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

const SafetyNetModal: React.FC<SafetyNetModalProps> = ({ open, enablingDelegation, onClose, onSave }) => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [recommended, setRecommended] = useState<SafetyNetPolicy[]>([]);
  const [additional, setAdditional] = useState<SafetyNetPolicy[]>([]);
  const [toAdd, setToAdd] = useState<SafetyNetPolicy[]>([]);
  const [toRemove, setToRemove] = useState<SafetyNetPolicy[]>([]);

  const [fetchSafetyNet, saveSafetyNetChanges] = useCommunitySafetyNetStore(
    useCallback((state: CommunitySafetyNetStore) => [state.fetch, state.save] as const, [])
  );

  useEffect(() => {
    if (open) {
      setLoading(true);
      setToAdd([]);
      setToRemove([]);

      fetchSafetyNet().then(
        (safetyNet) => {
          const autoSelectRecommended =
            enablingDelegation && safetyNet.recommended.every((p) => !p.checked) && safetyNet.additional.every((p) => !p.checked);

          if (autoSelectRecommended) {
            safetyNet.recommended.forEach((p) => (p.checked = true));
            setToAdd(safetyNet.recommended);
          }

          setRecommended(safetyNet.recommended);
          setAdditional(safetyNet.additional);
          setLoading(false);
        },
        (err: ResponseError) => {
          errorToast({
            title: t("Please try again"),
            description: t(err.message),
            isClosable: true,
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, fetchSafetyNet]);

  const addToSaveQueue = useCallback(
    (policies: SafetyNetPolicy[]) => {
      let addQueue = [...toAdd],
        removeQueue = [...toRemove];

      policies.forEach((policy) => {
        if (policy.checked) {
          // if policy needs to be saved and it's not in queue yet, add it
          if (!policy.id && !addQueue.some((item) => item === policy)) {
            addQueue.push(policy);
          }

          // policy that will be saved does not need to be deleted
          removeQueue = removeQueue.filter((item) => item.id !== policy.id);
        } else {
          // policy that will be deleted does not need to be saved
          addQueue = addQueue.filter((item) => item !== policy);

          // if policy needs to be deleted and it's not in queue yet, add it
          if (policy.id && !removeQueue.some((item) => item.id === policy.id)) {
            removeQueue.push(policy);
          }
        }
      });

      setToAdd(addQueue);
      setToRemove(removeQueue);
    },
    [toAdd, toRemove]
  );

  const handleSave = () => {
    let anySaved = false,
      anyChecked = false;

    recommended.concat(additional).forEach((policy) => {
      anySaved ||= !!policy.id;
      anyChecked ||= policy.checked;
    });

    // ask confirmation when
    // - all policies have been unselected
    // - auto-selected recommended have been unselected (only when turning on Delegation)
    // - persisted recommended have been unselected
    const shouldConfirm =
      !anyChecked ||
      (enablingDelegation && !anySaved && recommended.some((p) => !p.checked)) ||
      recommended.some((p) => p.id && !p.checked);

    if (shouldConfirm) {
      setShowConfirmModal(true);
    } else {
      doSave();
    }
  };

  const handleConfirmSave = () => {
    setShowConfirmModal(false);
    doSave();
  };

  const handleCancelSave = () => {
    setShowConfirmModal(false);
  };

  const doSave = useCallback(() => {
    setSaving(true);

    void saveSafetyNetChanges({ add: toAdd, remove: toRemove })
      .then(
        (safetyNet) => {
          setRecommended(safetyNet.recommended);
          setAdditional(safetyNet.additional);
          setToAdd([]);
          setToRemove([]);

          successToast({
            title: t("Update successful"),
            description: t("Safety Net policies have been successfully updated"),
            isClosable: true,
          });

          onSave();
        },
        (err: ResponseError) => {
          errorToast({
            title: t("Please try again"),
            description: t(err.message),
            isClosable: true,
          });
        }
      )
      .finally(() => {
        setSaving(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toAdd, toRemove]);

  const handleResetToRecommended = () => {
    const changed: SafetyNetPolicy[] = [];

    setRecommended(
      recommended.map((policy) => {
        if (!policy.checked) {
          changed.push(policy);
          policy.checked = true;
        }
        return policy;
      })
    );
    setAdditional(
      additional.map((policy) => {
        if (policy.checked) {
          changed.push(policy);
          policy.checked = false;
        }
        return policy;
      })
    );

    addToSaveQueue(changed);
  };

  const handleRecommendedChange = (policies: SafetyNetPolicy[]) => {
    addToSaveQueue(policies);
    setRecommended([...recommended]);
  };

  const handleAdditionalChange = (policies: SafetyNetPolicy[]) => {
    addToSaveQueue(policies);
    setAdditional([...additional]);
  };

  const anyChecked = recommended.some((p) => p.checked) || additional.some((p) => p.checked);
  const hasChanges = toAdd.length > 0 || toRemove.length > 0;

  return (
    <>
      <Modal
        isOpen={open}
        onClose={onClose}
        size="lg"
        headerText={t("School Safety Net")}
        primaryCTALabel={saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
        primaryCTADisabled={loading || saving || !hasChanges}
        onPrimaryCTAClick={handleSave}
        secondaryCTALabel={t("Reset to Recommended Policies")}
        secondaryCTADisabled={loading || saving}
        onSecondaryCTAClick={handleResetToRecommended}
      >
        <ModalBody>
          <Box mb="sp16">
            <Text color="text.title">
              {t("Configure policies that apply when parents are managing school devices.")}{" "}
              {t("These policies will apply before Qustodio rules.")}
            </Text>
          </Box>
          <Box mb="sp16" data-testid="recommended-categories-section">
            <CategoriesSection
              loading={loading}
              saving={saving}
              policies={recommended}
              title="Recommended Categories"
              onChange={handleRecommendedChange}
            />
          </Box>
          <Box data-testid="additional-categories-section">
            <CategoriesSection
              loading={loading}
              saving={saving}
              policies={additional}
              title="Additional Categories"
              onChange={handleAdditionalChange}
            />
          </Box>
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        variant="warning"
        isOpen={showConfirmModal}
        headerText={t("You've modified the Safety Net")}
        primaryCTALabel={t("Confirm")}
        onPrimaryCTAClick={handleConfirmSave}
        secondaryCTALabel={t("Cancel")}
        onSecondaryCTAClick={handleCancelSave}
        onClose={handleCancelSave}
      >
        <ModalBody>
          <Text color="text.title">
            {anyChecked
              ? t("You have disabled one or more recommended Safety Net policies.")
              : t("You have disabled all Safety Net policies.")}{" "}
            {t("Doing so means that when parents are managing school devices these policies may not apply.")}
          </Text>
          <Text mt="sp16" color="text.title">
            {t("Are you sure you want to proceed?")}
          </Text>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SafetyNetModal;
