export enum SafetyNetTag {
  RECOMMENDED = "Recommended Safety Net",
  CUSTOM = "Custom Safety Net",
}

export interface SafetyNetRule {
  id?: string;
  name: string;
  action: string;
  criteria: RuleCriteria[];
  version?: number;
  modifiedBy?: string;
  modifiedDate?: string;
}

export type SafetyNetPolicy = SafetyNetRule & {
  description: string;
  checked: boolean;
};

export enum RuleAction {
  BLOCK = "block",
}

export interface RuleCriteria {
  type: RuleCriteriaType;
  signature: string;
}

export enum RuleCriteriaType {
  SIGNATURE = "signature",
}

export interface Signature {
  id: string;
  name: string;
  description: string;
  tags: string[];
  enabled: boolean;
  hidden: boolean;
}
