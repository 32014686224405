import TableBasedPage from "../../templates/TableBasedPage";
import { Text, Td, Tr, useTranslation, ButtonTd, Checkbox } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { displayActiveAddress, displayStatus, displayType } from "./InterfacesHelpers";

export interface ipv4 {
  ip: string;
  mask: string;
}

export interface dhcp_lease {
  state?: string;
}

export interface interfacesData {
  interface: string;
  persisted: boolean;
  ipv4: ipv4[];
  bridge: boolean;
  vlan: boolean;
  lacp: boolean;
  dhcp: boolean;
  dhcp_lease?: dhcp_lease;
  ipv4_configured?: ipv4[];
  mac: string;
  state: boolean;
  has_link: boolean;
}

export interface interfacesTableData {
  interface: string;
  saved: boolean;
  type: string;
  activeAddress: string;
  macAddress: string;
  adminStatus: string;
  linkStatus: string;
}

interface InterfacesTableRenderProps {
  data: interfacesData[];
  loaded: boolean;
}

export const InterfacesNew = ({ data, loaded }: InterfacesTableRenderProps): JSX.Element => {
  const [formattedData, setFormattedData] = useState<interfacesTableData[]>([]);
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config/device/dashboard", isActive: false },
    { title: t("Networking"), url: "/config/device/network/devices", isActive: false },
    { title: t("Interfaces"), isActive: true },
  ];

  const items = ["Interface", "Saved", "Type", "Active Addresses", "Mac Address", "Admin Status", "Link Status"];

  const columns = [];
  items.forEach((item) => {
    columns.push({
      headerText: t(item),
      columnName: item.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
    });
  });
  columns.push({
    headerText: t("Operations"),
    columnName: "operations",
    sortable: false,
    searchable: false,
  });

  useEffect(() => {
    const result: interfacesTableData[] = [];
    data.map((row: interfacesData) =>
      result.push({
        interface: row.interface,
        saved: row.persisted,
        type: displayType(row.bridge, row.vlan, row.lacp),
        activeAddress: displayActiveAddress(row),
        macAddress: row.mac,
        adminStatus: displayStatus(row.state),
        linkStatus: displayStatus(row.has_link),
      })
    );
    setFormattedData(result);
  }, [data]);

  const dataMap = (
    { interface: intf, saved, type, activeAddress, macAddress, adminStatus, linkStatus }: interfacesTableData,
    index: number
  ) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{intf}</Text>
      </Td>
      <Td>
        <Checkbox data-testid="checkBox" isChecked={saved} />
      </Td>
      <Td>
        <Text fontSize="md">{type}</Text>
      </Td>
      <Td width="13%">
        <Text fontSize="md" noOfLines={1} textOverflow="hidden">
          {activeAddress}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">{macAddress}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{adminStatus}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{linkStatus}</Text>
      </Td>
      <Td>
        <ButtonTd buttonIconName="fa-pen" onClick={() => console.log("not implemented")} /> "TODO create button function"
        <ButtonTd buttonIconName="fa-trash-can" onClick={() => console.log("not implemented")} /> TODO create button function"
        <ButtonTd buttonIconName="fa-rotate" onClick={() => console.log("not implemented")} /> TODO create button function"
        <ButtonTd buttonIconName="fa-arrow-down" onClick={() => console.log("not implemented")} /> TODO create button function"
      </Td>
    </Tr>
  );

  return (
    <TableBasedPage
      title={t("Interfaces")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={formattedData}
      tableDataMap={dataMap}
      loaded={loaded}
      showSearch={true}
    />
  );
};

export default InterfacesNew;
