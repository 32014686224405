import React, { useCallback, useEffect, useRef, useState } from "react";
import PersonaDashboardStore from "../../../stores/PersonaDashboardStore";
import PersonaDashboardActions from "../../../actions/PersonaDashboardActions";
import InfoIconV2 from "../../../modules/InfoIconV2";
import { Link } from "react-router";
import { ToggleGroup } from "../../../modules/ToggleGroup";
import ComponentLoading from "../../../modules/ComponentLoading";
import NoData from "../../../modules/NoData";
import { useQuery } from "react-query";
import Api from "../../../utils/Api";
import PropTypes from "prop-types";
import icon_web from "../../../../images/Icon_Web.svg";
import dayjs from "dayjs";

const getDateOverrideLast7Days = () => {
  const start = dayjs().subtract(6, "days").startOf("day");
  const end = dayjs().endOf("day");
  return {
    startDate: start.format("YYYY-MM-DD"),
    endDate: end.format("YYYY-MM-DD"),
    startTime: start.format("HH:mm:ss"),
    endTime: end.format("HH:mm:ss"),
  };
};

const trendingVideosKeys = ["trending-videos", getDateOverrideLast7Days()];

const trendingSearchesKeys = ["trending-searches", getDateOverrideLast7Days()];

const trendingAppsAndWebsitesKeys = ["trending-appsandwebsites", getDateOverrideLast7Days()];

const sortByChangeDescending = (a, b) => b.change - a.change;

const findTitle = (row) => row["tag.string"];

const findLink = (row) => `/surfwize/reporting/type/${row["tag"]}/users`;

export const TrendingData = (_, context) => {
  const [selectedToggle, setSelectedToggle] = useState(() => {
    if (PersonaDashboardStore.getToggle("trendingData")) {
      return PersonaDashboardStore.getToggle("trendingData");
    } else {
      return "Apps";
    }
  });

  const [children, setChildren] = useState([]);

  const [showNoData, setShowNoData] = useState(false);

  const videosQuery = useQuery(
    trendingVideosKeys,
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/trending/videos", { dateOverride: getDateOverrideLast7Days() }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Videos",
      select: useCallback((data) => data.sort(sortByChangeDescending).slice(0, 6), []),
    }
  );

  const searchesQuery = useQuery(
    trendingSearchesKeys,
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/trending/searches", { dateOverride: getDateOverrideLast7Days() }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Searches",
      select: useCallback((data) => data.sort(sortByChangeDescending).slice(0, 6), []),
    }
  );

  const appsQuery = useQuery(
    trendingAppsAndWebsitesKeys,
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/trending/appsandwebsites", { dateOverride: getDateOverrideLast7Days() }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Apps",
      select: useCallback((data) => data.sort(sortByChangeDescending).slice(0, 6), []),
    }
  );

  const videoOnClick = (title) => {
    context.router.push({ pathname: "/cybersafety/video", state: { default_filter: title } });
  };

  const searchOnClick = (title) => {
    context.router.push("/cybersafety/search");
    const newTag = [{ value: title, label: title }];
    sessionStorage.setItem("tags", JSON.stringify(newTag));
  };

  const appOnClick = (title, link) => {
    context.router.push({ pathname: link, state: { default_filter: title } });
  };

  const showLoading = useCallback(
    () => videosQuery.isFetching || searchesQuery.isFetching || appsQuery.isFetching,
    [videosQuery.isFetching, searchesQuery.isFetching, appsQuery.isFetching]
  );

  useEffect(() => {
    let tempChildren;
    switch (selectedToggle) {
      case "Apps":
        tempChildren = appsQuery.data
          ? appsQuery.data.map((item, index) => {
              const title = findTitle(item);
              const link = findLink(item);

              const icon = item.favicon_url ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img className="trendingItemPill-icon" src={item.favicon_url} />
              ) : (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img className="trendingItemPill-icon trendingItemPill-icon-svg" src={icon_web} />
              );

              return <TrendingDataItem icon={icon} key={index} title={title} onclick={() => appOnClick(title, link)} />;
            })
          : [];
        break;

      case "Searches":
        tempChildren = searchesQuery.data
          ? searchesQuery.data.map(({ search_string }, index) => {
              return (
                <TrendingDataItem
                  icon={<i className="fa fa-solid fa-search" />}
                  title={search_string}
                  key={index}
                  onclick={() => searchOnClick(search_string)}
                />
              );
            })
          : [];
        break;

      default:
        tempChildren = videosQuery.data
          ? videosQuery.data.map(({ title, thumbnail }, index) => {
              const icon =
                thumbnail && thumbnail.length > 0 ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img className="trendingItemPill-icon" src={thumbnail[0]} />
                ) : (
                  <i className="fa fa-solid fa-play-circle" />
                );

              return <TrendingDataItem icon={icon} key={index} title={title} onclick={() => videoOnClick(title)} />;
            })
          : [];
        break;
    }

    setChildren(tempChildren);

    setShowNoData(!showLoading() && tempChildren.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToggle, showLoading, videosQuery.data, searchesQuery.data, appsQuery.data]);

  const updateToggle = (toggle) => {
    setSelectedToggle(toggle);
    PersonaDashboardActions.setToggle("trendingData", toggle);
  };

  const togglesRef = useRef();
  if (!togglesRef.current) {
    togglesRef.current = [
      {
        label: "Apps",
        onClick: () => updateToggle("Apps"),
      },
      {
        label: "Videos",
        onClick: () => updateToggle("Videos"),
      },
      {
        label: "Searches",
        onClick: () => updateToggle("Searches"),
      },
    ];

    const initiallySelectedToggle = togglesRef.current.find((element) => element.label === selectedToggle);
    initiallySelectedToggle.initiallySelected = true;
  }

  const viewAllLink = () => {
    switch (selectedToggle) {
      default:
      case "Videos":
        return "/cybersafety/video";
      case "Searches":
        sessionStorage.setItem("tags", JSON.stringify([]));
        return "/cybersafety/search";
      case "Apps":
        return "/surfwize/reporting/types";
    }
  };

  return (
    <div className="personaModule">
      <div className="personaModule-header">
        <h2>Trending</h2>
        <InfoIconV2 backgroundColor="grey" text="Popular videos, searches and apps for the last 7 days" />
        <Link to={viewAllLink()} className="personaModule-rightLink">
          View All
        </Link>
      </div>

      <div className="trendingData-buttonGroup">
        <ToggleGroup toggles={togglesRef.current} />
      </div>
      {showLoading() && (
        <div className="centered">
          <ComponentLoading />
        </div>
      )}
      {showNoData && <NoData />}
      {!showLoading() && !showNoData && <div>{children}</div>}
    </div>
  );
};

TrendingData.contextTypes = {
  router: PropTypes.object.isRequired,
};

const TrendingDataItem = ({ icon, title, onclick }) => {
  return (
    <div className="trendingItemPill" onClick={onclick}>
      {icon}
      <div className="trendingItemPill-text" title={title}>
        {title}
      </div>
    </div>
  );
};
