import React, { useEffect, useState } from "react";
import { ButtonTd, Flex, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import TableBasedPage from "../../templates/TableBasedPage";
import moment from "moment";

interface IStatusProps {
  ldap_sync_end_time: number;
  ldap_sync_errors: string;
  ldap_sync_group_count: number;
  ldap_sync_start_time: number;
  ldap_sync_state: string;
  ldap_sync_user_count: number;
}

export interface IRow {
  id: string;
  authenticateuserusingsupplieddn?: boolean;
  basedn: string;
  emailattribute?: string;
  enabled?: boolean;
  groupdnattribute?: string;
  groupnameattribute?: string;
  hostname: string;
  ldappassword?: string;
  ldapusername?: string;
  managerattribute?: string;
  oudnattribute?: string;
  ounameattribute?: string;
  port?: number;
  protocol?: string;
  search_query?: string;
  status?: IStatusProps;
  sync_by_dn?: boolean;
  sync_enabled?: boolean;
  sync_ous?: boolean;
  type?: string;
  userbase?: string;
  userdnattribute?: string;
  usergroupattribute?: string;
  usernameattribute?: string;
  userownedgroupmembership?: boolean;
}

interface TableDataFormatted extends IRow {
  syncStatus?: string;
}

interface ILdapServerProps {
  data: IRow[];
  loaded: boolean;
}

const LdapServersNew = ({ data, loaded }: ILdapServerProps): JSX.Element => {
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState<TableDataFormatted[]>([]);

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("LDAP Servers"), isActive: true },
  ];

  const columnHeaders = ["Server", "Base DN", "Status"];

  const columns = [];
  columnHeaders.forEach((item) => {
    columns.push({
      headerText: t(item),
      columnName: item.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
      exportable: true,
    });
  });

  columns.push({ headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false });

  const statusText = (status: IStatusProps | undefined) => {
    if (!status) {
      return "Never Synced";
    }
    if (status["ldap_sync_state"] === "error") {
      return `Failed to sync, reason was ${status["ldap_sync_errors"]}`;
    } else if (status["ldap_sync_state"] === "running") {
      return "Sync running now";
    } else if (status["ldap_sync_state"] === "finished") {
      return `Sync has run ${moment.unix(status["ldap_sync_end_time"]).fromNow()} and imported ${status["ldap_sync_user_count"]} users, ${
        status["ldap_sync_group_count"]
      } groups`;
    }

    return "";
  };

  useEffect(() => {
    const result: TableDataFormatted[] = [];
    data.map((row: IRow) =>
      result.push({
        id: row.id,
        hostname: row.hostname,
        basedn: row.basedn,
        status: row.status,
      })
    );
    setFormattedData(result);
  }, [data]);

  const dataMap = (row: IRow, index: number) => (
    <Tr key={index}>
      <Td data-testid="ldap-server-hostName">
        <Text fontSize="md">{row.hostname}</Text>
      </Td>
      <Td data-testid="ldap-server-baseDN">
        <Text fontSize="md">{row.basedn}</Text>
      </Td>
      <Td data-testid="ldap-server-status">
        <Text fontSize="md">{statusText(row.status)}</Text>
      </Td>
      <Td>
        <Flex>
          <ButtonTd buttonIconName="fa-pencil" onClick={() => 0} />
          <ButtonTd buttonIconName="fa-trash-can" onClick={() => 0} />
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <TableBasedPage
      title={t("LDAP Servers")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={formattedData}
      tableDataMap={dataMap}
      loaded={loaded}
    />
  );
};

export default LdapServersNew;
