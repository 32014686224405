type blockedObject = {
  [key: string]: unknown;
};

export const onFilter = (row: blockedObject, searchInput: string): boolean => {
  const searchTerm = searchInput.toLowerCase();
  const rowValues = Object.values(row);
  return rowValues.some((value) => {
    const stringifiedValue = typeof value === "string" ? value : String(value);
    return stringifiedValue.toLowerCase().includes(searchTerm);
  });
};
