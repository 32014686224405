import LoginWrapper from "./LoginWrapper";
import { Text } from "@familyzone/component-library";
import ReturnToSignIn from "./ReturnToSignIn";
import React from "react";

const LoginResetPasswordCheckEmail: React.FC = () => {
  return (
    <LoginWrapper header="Check Your Email">
      <Text fontFamily="paragraphs" color="text.title" mb="sp32" mt="sp16">
        If you have entered a valid School Manager account, you will receive a password recovery link in your email inbox.
      </Text>
      <ReturnToSignIn />
    </LoginWrapper>
  );
};

export default LoginResetPasswordCheckEmail;
