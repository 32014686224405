import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import moment from "moment";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import SessionStore from "../../stores/SessionStore";

class TakeSnapshot extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: "",
    };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Create Snapshot
      </button>
    );
  };

  change_Name = (event) => {
    this.setState({ name: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd(this.state.name);
    this.handleHide();
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Create Snapshot" handleHide={this.handleHide} actions={this.renderActions()}>
          <div className="mui-form">
            <div className="formgroup-element-title">Name</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.name} onChange={this.change_Name} />
            </div>
          </div>
        </ModalWindow>
      );
    }

    return <div></div>;
  }
}

export default class ManageDeviceSnapshots extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      snapshots: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    Api.get("/managedevice/ajax/device/snapshots", function (result) {
      self.setState({
        snapshots: result["data"],
        loaded: true,
      });
    });
  };

  handle__TakeSnapshot = (name) => {
    const self = this;
    Api.put("/managedevice/ajax/device/snapshots", { name: name }, function (result) {
      self.handle_load();
    });
  };

  download = (url) => {
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.setRequestHeader("Content-type", "application/zip");
    req.setRequestHeader("Device", SessionStore.getSelectedDevice());
    req.setRequestHeader("Token", SessionStore.getToken());
    req.setRequestHeader("UserId", SessionStore.getUserId());
    req.responseType = "blob";
    req.onload = function (event) {
      if (event.currentTarget.status === 200) {
        let filename = "";
        let disposition = req.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, "");
        }
        let blob = req.response;
        let link = document.createElement("a");
        link.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    };
    req.send();
  };

  handle_View = (snapshot) => {
    this.download("/managedevice/ajax/device/snapshot/" + snapshot.id);
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  handler__clickOpenAdd = () => {
    this.setState({
      visible__add_permission: true,
    });
  };

  handler__clickCloseEdit = () => {
    this.setState({
      visible__edit_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Take Snapshot" onClickHandler={this.handler__clickOpenAdd}></TableCentricPageButton>;
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Time",
        data: function (row) {
          return moment.unix(row["created"]).local().format();
        },
        sortable: FlexibleTable.sortable__single_numeric_field("created"),
        search: FlexibleTable.search__single_date_field("created", null),
        onclick: undefined,
      },
      {
        title: "Label/Name",
        data: function (row) {
          return row["description"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("description"),
        search: FlexibleTable.search__single_string_field("description"),
        onclick: undefined,
      },
      {
        title: "Account",
        data: function (row) {
          return row["account"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("account"),
        search: FlexibleTable.search__single_string_field("account"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => self.handle_View(row)}>Download</a>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.snapshots} loaded={this.state.loaded} sort_column={0} sort_asc={false} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <TakeSnapshot
          visible={this.state.visible__add_permission}
          handleAdd={this.handle__TakeSnapshot}
          handleClose={this.handler__clickCloseAdd}
        ></TakeSnapshot>

        <TableCentricPage icon="fa fa-cog" title="Snapshots" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
