import React from "react";
import Link from "../../Link";
import { Flex, Text } from "@familyzone/component-library";

export default class SubNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.unsubAccountStore = null;
  }

  render() {
    const { name, to } = this.props;
    const isActive = process.env.NODE_ENV === "development" ? window.location.hash === `#${to}` : window.location.pathname === to;
    const color = isActive ? "neutrals.0" : "neutrals.70";

    return (
      <Link to={to}>
        <Flex borderLeft="1px solid" borderLeftColor={isActive ? "accent.turquoise.300" : "neutrals.300"} ml="sp2" mr="sp8">
          <Flex
            flexGrow={1}
            alignItems="center"
            p="sp8"
            ml="sp4"
            bg={isActive ? "brand.500" : "transparent"}
            borderRightRadius="xs"
            flexShrink="1"
            mr="auto"
            _hover={{ bg: "neutrals.800" }}
          >
            <Text fontSize="md" color={color} mr="auto">
              {name}
            </Text>
          </Flex>
        </Flex>
      </Link>
    );
  }
}
