import { FieldErrors, FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";
import { Checkbox, FormControl } from "@chakra-ui/react";
import { Box } from "@familyzone/component-library";
import { ConfigFormLabel } from "./ConfigFormLabel";
import React from "react";

type Props<T extends FieldValues> = {
  label: string;
  propertyName: keyof T;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  tooltip?: string;
  disabled?: boolean;
  registerOptions?: RegisterOptions<T>;
};

export const CheckBoxControl = <T extends FieldValues>({
  label,
  propertyName,
  register,
  errors,
  tooltip,
  disabled,
  registerOptions,
}: Props<T>): JSX.Element => {
  return (
    <FormControl
      isInvalid={!!errors[propertyName]}
      sx={{
        display: "flex",
        minHeight: "20px",
        fontSize: "14px",
        alignItems: "center",
      }}
    >
      <Checkbox
        disabled={disabled ? disabled : false}
        sx={{
          padding: "0px 12px",
          alignContent: "center",
        }}
        {...register(propertyName as Path<T>, registerOptions || {})}
      />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <ConfigFormLabel label={label} tooltip={tooltip} />
      </Box>
    </FormControl>
  );
};
