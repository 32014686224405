import React from "react";
import { withTranslation } from "@familyzone/component-library";
import useFetchReportingTableData from "../../utils/hooks/useFetchReportingTableData";
import ReportingSearchableTable from "./ReportingSearchableTable";
import PageWithHeader from "../templates/PageWithHeader";

const ReportingTopHosts = (props) => {
  const { data, loaded } = useFetchReportingTableData("/surfwize/analytics/hosts");

  // Set the total sum
  data.forEach((d) => (d.total = d.upload + d.download));

  const { t } = props;
  const tableProps = {
    data,
    loaded,
    columns: [
      { name: "ip", header: "IP Address", sortable: true },
      { name: "mac", header: "MAC", sortable: true },
      { name: "upload", header: "Upload", isAmount: true, sortable: true },
      { name: "download", header: "Download", isAmount: true, sortable: true },
      { name: "total", header: "Total", isAmount: true, sortable: true },
    ],
  };

  return (
    <PageWithHeader
      breadcrumbs={[
        { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
        { title: t("Network"), url: "/surfwize/device/network", isActive: false },
        { title: t("Hosts"), url: "/surfwize/device/network/hosts", isActive: true },
      ]}
      title={t("Hosts")}
    >
      <ReportingSearchableTable isInfiniteScroll {...tableProps} />
    </PageWithHeader>
  );
};

export default withTranslation()(ReportingTopHosts);
