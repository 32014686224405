import React, { useCallback, useState, useEffect } from "react";
import $ from "jquery";
import SessionStore from "../stores/SessionStore";
import { useUserStore } from "../storez/UserStore";
import { useDeviceOptionsStore } from "../storez/DeviceOptionsStore";

const SnapEngage = () => {
  const [addingScript, setAddingScript] = useState(false);

  const [deviceOptions] = useDeviceOptionsStore(useCallback((state) => [state.options], []));

  useEffect(() => {
    SessionStore.listen(sessionChanged);
    setCookie("SnapABugNoProactiveChat", "no", 9999);
    addSnapEngage();
    return () => {
      removeSnapEngage();
      SessionStore.unlisten(sessionChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const addSnapEngage = () => {
    if ((typeof window.SnapEngage === "undefined" || $("#designstudio").length === 0) && !addingScript) {
      setAddingScript(true);
      let se = document.createElement("script");
      se.type = "text/javascript";
      se.async = true;
      se.src = "//storage.googleapis.com/code.snapengage.com/js/b2f839b0-377c-490d-acc3-3ece73ad009b.js";
      let done = false;
      se.onload = se.onreadystatechange = () => {
        if (!done) {
          done = true;
          let retry_count = 100;
          let loadInterval = setInterval(() => {
            try {
              if (retry_count <= 0) {
                clearInterval(loadInterval);
                setAddingScript(false);
              }
              window.SnapEngage.setCallback("Open", function (status) {
                configChanged();
              });
              clearInterval(loadInterval);
              setAddingScript(false);
            } catch (e) {
              retry_count--;
            }
          }, 100);
        }
      };
      let s = document.getElementById("snapEngage");
      s.parentNode.insertBefore(se, s);
      s.appendChild(se);
    } else {
      populateSnapEngageFields();
    }
  };

  const getUsers = useUserStore((state) => state.getUsers);

  const populateSnapEngageFields = async () => {
    if (typeof window.SnapEngage !== "undefined") {
      showButton();
      let username = SessionStore.getUsername();
      let fullName = "";
      try {
        const users = await getUsers([username]);
        if (users.length > 0 && users[0].firstName && users[0].lastName) {
          fullName = `${users[0].firstName} ${users[0].lastName}`;
        } else {
          fullName = username;
        }
      } catch (err) {
        fullName = username;
      }

      window.SnapEngage.setUserEmail(username, false);
      window.SnapEngage.setUserName(fullName);
      window.SnapEngage.setCustomField("Yourdeviceid", deviceOptions.deviceid);
      window.SnapEngage.setCustomField("Youruserid", username);
    }
  };

  const removeSnapEngage = () => {
    $("#designstudio").remove();
    $("#designstudio-minimize").remove();
    $("#designstudio-button").remove();
    $("#snapEngage script").remove();
    for (let styleObj of $("body style")) {
      if ($(styleObj).text().indexOf("designstudio") >= 0) {
        $(styleObj).remove();
      }
    }
  };

  const sessionChanged = () => {
    if (!SessionStore.getSelectedDevice()) {
      removeSnapEngage();
    }
  };

  const showButton = () => {
    $("#designstudio-button").show();
  };

  const hideButton = () => {
    $("#designstudio-button").hide();
    $("#designstudio").hide();
  };

  const configChanged = () => {
    if (SessionStore.getSelectedDevice() && deviceOptions.allow_support_school_manager) {
      populateSnapEngageFields();
    } else hideButton();
  };

  return <div id={"snapEngage"} />;
};

export default SnapEngage;
