import PersonaDashboardActions from "../actions/PersonaDashboardActions";
import alt from "../alt";

class PersonaDashboardStore {
  constructor() {
    this.rememberedToggles = {
      trendingData: undefined,
    };

    this.exportPublicMethods({
      getToggle: this.getToggle,
    });

    this.bindListeners({
      handleSetToggle: PersonaDashboardActions.SET_TOGGLE,
    });
  }

  handleSetToggle({ name, value }) {
    this.rememberedToggles[name] = value;
  }

  getToggle = (name) => {
    return this.rememberedToggles[name];
  };
}

export default alt.createStore(PersonaDashboardStore, "PersonaDashboardStore");
