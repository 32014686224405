import { create } from "zustand";
import { convertArrayToFeatureFlags } from "../components/config/ClassroomFeatureFlags/utils";
import { ClassroomFeatureFlagsResponseShape, getClassroomFeatureFlags } from "../utils/api/ClassroomFeatureFlags";

/**
 * Classroom feature flags are used in `AppRoot` to decide access to Classwize routes,
 * conditionally render menu items and content on some pages.
 */

export interface ClassroomFeatureFlags {
  screenShare: boolean;
}

export const defaultClassroomFlags: ClassroomFeatureFlags = {
  screenShare: false,
};

export interface ClassroomFeatureFlagStore {
  flags: ClassroomFeatureFlags;
  fetch: () => Promise<ClassroomFeatureFlags>;
  getFlags: () => ClassroomFeatureFlags;
  setFlags: (flags: ClassroomFeatureFlags) => void;
  resetFlags: () => void;
}

export const useClassroomFeatureFlagStore = create<ClassroomFeatureFlagStore>((set, get) => ({
  flags: defaultClassroomFlags,

  getFlags: (): ClassroomFeatureFlags => {
    const flags = get().flags;
    return flags;
  },

  setFlags: (flags: ClassroomFeatureFlags): void => {
    set({ flags });
  },

  fetch: async (): Promise<ClassroomFeatureFlags> => {
    const response = await getClassroomFeatureFlags();
    const json = (await response.json()) as ClassroomFeatureFlagsResponseShape;
    const flags = convertArrayToFeatureFlags(json.enabledFeatures) as unknown as ClassroomFeatureFlags;

    set({ flags });
    return flags;
  },

  resetFlags: (): void => {
    set({ flags: defaultClassroomFlags });
  },
}));
