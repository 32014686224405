import React from "react";
import Link from "../../Link";
import AuthorizationWrapper from "../../../modules/AuthorizationWrapper";
import { Flex, Icon, Text } from "@familyzone/component-library";
import SubNavItem from "./SubNavItem";

export default class NavItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.unsubAccountStore = null;
  }

  render() {
    const { name, childItems, to } = this.props;

    const isActive = process.env.NODE_ENV === "development" ? window.location.hash === `#${to}` : window.location.pathname === to;

    let isChildActive = false;
    if (childItems && childItems.length > 0) {
      isChildActive = process.env.NODE_ENV === "development" ? window.location.hash.includes(to) : window.location.pathname.startsWith(to);
    }

    const color = isActive || isChildActive ? "neutrals.0" : "neutrals.70";

    return (
      <>
        <Link to={to}>
          <Flex
            alignItems="center"
            p="sp8"
            ml="sp4"
            mr="sp8"
            my="sp2"
            bg={isActive ? "brand.500" : isChildActive ? "neutrals.700" : "transparent"}
            borderRadius="xs"
            flexShrink="1"
            _hover={{ bg: "neutrals.800" }}
          >
            <Icon
              color={isActive || isChildActive ? "accent.turquoise.300" : "neutrals.500"}
              icon="fa-circle"
              variant="solid"
              mr="sp8"
              boxSize="4px"
            />
            <Text fontSize="md" color={color} mr="auto">
              {name}
            </Text>
            {childItems && (isActive || isChildActive) && <Icon color={color} icon="fa-chevron-down" />}
            {childItems && !(isActive || isChildActive) && <Icon color={color} icon="fa-chevron-right" />}
          </Flex>
        </Link>
        {(isActive || isChildActive) && childItems && (
          <Flex ml="sp12" mt="sp2" flexDirection="column">
            {childItems
              .filter((childItem) => !childItem.hidden)
              .map((childItem, index) => (
                <AuthorizationWrapper allowedRoles={childItem.allowedRoles} key={index}>
                  <SubNavItem to={childItem.to} name={childItem.name} />
                </AuthorizationWrapper>
              ))}
          </Flex>
        )}
      </>
    );
  }
}
