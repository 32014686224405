import React from "react";
import { Tag } from "@familyzone/component-library";
import { useUserStore } from "../storez/UserStore";
import { fullNameAndUsername, getFullName } from "../components/UserSearch/UserSearchHelper";

interface Props {
  username: string;
}

const StudentNamePill: React.FC<Props> = ({ username }) => {
  const [displayName, setDisplayName] = React.useState(username);
  const getUser = useUserStore((state) => state.getUser);

  React.useMemo(() => {
    if (!getUser) return;
    void getUser(username)
      .then((user) => {
        if (!user) setDisplayName(username);
        else setDisplayName(fullNameAndUsername(user.username, getFullName(user)));
      })
      .catch(() => {
        setDisplayName(username);
      });
  }, [username, getUser]);

  return <Tag isRemovable={false} tagLabel={displayName} tagLeftIcon="fa fa-user-circle" variant="dark" data-testid="studentNamePill" />;
};

export default StudentNamePill;
