import { Box, Flex, Option, ThemeProvider } from "@familyzone/component-library";
import React, { ChangeEventHandler } from "react";
import ReactTooltip from "react-tooltip";
import NuSingleSelect from "../../utils/NuSingleSelect";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";

interface ReportingOptionsProps {
  active: boolean;
  secondaryOptions: boolean;
  reportingType: string;
  checkboxStatus: boolean;
  handleStatusFunc: ChangeEventHandler<HTMLInputElement>;
  handleReportingTypeFunc: unknown;
  selected?: Option[];
  onChangeReportingGroups?: (groups: Option[]) => void;
}

const ReportingOptions = ({
  active,
  secondaryOptions,
  reportingType,
  checkboxStatus,
  handleStatusFunc,
  handleReportingTypeFunc,
  selected,
  onChangeReportingGroups,
}: ReportingOptionsProps): JSX.Element => {
  const ReportingOptionsGroupSelector = (): JSX.Element => {
    return (
      <Box ml="40px" mt="10px" w="100%">
        <div className="mui-select" data-testid="timeRestrictionSelector">
          <MultiGroupSearchSelector preselected={selected} onChangeGroups={onChangeReportingGroups} useLegacyId={true} />
        </div>
      </Box>
    );
  };

  const ReportingStatusCheckbox = (): JSX.Element => {
    return (
      <div className="mui-checkbox">
        <input type="checkbox" checked={checkboxStatus} onChange={handleStatusFunc} />
      </div>
    );
  };

  const reportingOptionsInactive = () => {
    return (
      <div className="form-element-inactive">
        <span className="form-element-info-circle fa fa-info-circle" />
        <span className="form-element-inactive-text"> To activate this feature set an Allowed Time Period. </span>
      </div>
    );
  };

  const ReportingTypeSelector = (): JSX.Element => {
    return (
      <NuSingleSelect
        allItems={[
          {
            value: "",
            text: "All teachers with Classwize have access to reporting",
          },
          {
            text: "Allow only the below groups to access reporting",
            value: "allowList",
          },
          {
            text: "Block the below groups from accessing reporting",
            value: "blockList",
          },
        ]}
        displayTextLens={(item: { text: unknown }) => item.text}
        valueLens={(item: { value: unknown }) => item.value}
        onChange={handleReportingTypeFunc}
        selectedItem={reportingType}
        searchPlaceholder="Please select a reporting list"
      />
    );
  };

  const reportingOptionsActive = () => {
    return (
      <ThemeProvider>
        <Flex w="100%">
          <ReactTooltip id="reporting-tool-tip" place="bottom" effect="solid">
            Teachers in the groups added here can create reports in Classwize
          </ReactTooltip>
          <Box pt="sp8" w="14%" data-tip data-for="reporting-tool-tip">
            Reporting
          </Box>
          {active && (
            <Flex w="80%" data-testid="reportingTypeSelector">
              <Box w="5%">{ReportingStatusCheckbox()} </Box>
              <Box w="100%">{ReportingTypeSelector()}</Box>
            </Flex>
          )}
          {!active && <Flex w="80%">{reportingOptionsInactive()}</Flex>}
        </Flex>
        {secondaryOptions && (
          <Box ml="13%" w="76%">
            {" "}
            {ReportingOptionsGroupSelector()}{" "}
          </Box>
        )}
      </ThemeProvider>
    );
  };

  return reportingOptionsActive();
};

export default ReportingOptions;
