import { Stack } from "@chakra-ui/layout";
import { Modal, useTranslation, Text, Button, Flex, useToast } from "@familyzone/component-library";
import React from "react";
import { purgeGroups } from "../../../utils/api/Groups";
import { PurgeType } from "../../../utils/api/Helpers";

const GroupsPurgeModal: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const handleSubmit = (provider: PurgeType) => {
    setLoading(true);

    purgeGroups(provider)
      .then(() => {
        setOpen(false);
        successToast({ title: "Success!", description: "We've received the request and it has been sent for processing." });
      })
      .catch(() => {
        setOpen(true);
        errorToast({ title: "Error", description: "We're not able to process the purge at this time. Please try again later." });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button variant="primary" onClick={() => setOpen(true)}>
        Purge Groups
      </Button>
      <Modal
        headerText={t("Purge Groups")}
        size="md"
        isOpen={open}
        onClose={() => setOpen(false)}
        contentProps={{ style: { overflow: "visible" } }}
      >
        <Stack spacing="sp12" my="sp12">
          <Text>
            WARNING! You are about to permanently delete groups. Please select whether you wish to delete local/imported/all groups.
          </Text>
        </Stack>
        <Stack spacing="sp12" my="sp12">
          <Flex>
            <Button mr="4" variant="danger" onClick={() => handleSubmit("LOCAL")} isLoading={loading} disabled={loading}>
              Purge Local Groups
            </Button>
            <Button mr="4" variant="danger" onClick={() => handleSubmit("IMPORTED")} isLoading={loading} disabled={loading}>
              Purge Imported Groups
            </Button>
            <Button variant="danger" onClick={() => handleSubmit("ALL")} isLoading={loading} disabled={loading}>
              Purge All Groups
            </Button>
          </Flex>
          <Flex>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
};
export default GroupsPurgeModal;
