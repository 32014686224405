import { Box, Flex, Icon, TableIconButton, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import React, { useEffect } from "react";
import ObjectActions from "../../actions/ObjectActions";
import ObjectPrinter from "../../criteria/ObjectPrinter";
import ObjectStore from "../../stores/ObjectStore";
import TableBasedPage from "../templates/TableBasedPage";
import { Timeout, timeoutTypeText, timeoutValueText } from "./TimeoutHelpers";

export interface TimeoutsProps {
  timeouts: Timeout[];
  loaded: boolean;
}

const TimeoutsNew = ({ loaded, timeouts }: TimeoutsProps): JSX.Element => {
  const { t } = useTranslation();
  const title = t("Timeouts");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("Time Periods"), url: "/config/device/authentication/timeouts", isActive: true },
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    ObjectActions.fetch();
  }, []);

  const columns = [
    {
      headerText: t("Authentication Provider"),
      columnName: "provider",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Groups"),
      columnName: "groups",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Networks"),
      columnName: "networks",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Timeout Type"),
      columnName: "type",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Timeout Value"),
      columnName: "timeout",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
      sortable: false,
      searchable: false,
    },
  ];

  const dataMap = (timeout: Timeout, index: number) => {
    return (
      <Tr key={index}>
        <Td>
          <Text>{timeout.provider}</Text>
        </Td>
        <Td>
          <Text>{timeout.groups}</Text>
        </Td>
        <Td>
          <Text>{ObjectPrinter.getObjectsText(timeout.networks).join(" ")}</Text>
        </Td>
        <Td>
          <Text>{timeoutTypeText(timeout.type)}</Text>
        </Td>
        <Td>
          <Text>{timeoutValueText(timeout)}</Text>
        </Td>
        <Td>
          <Flex>
            <Box mr="sp8">
              <TableIconButton
                aria-label="Edit"
                onClick={() => {
                  window.location.href = `/config/device/authentication/timeouts/${timeout.id}`;
                }}
                icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              />
            </Box>
            <TableIconButton
              aria-label="Delete"
              disabled={true}
              onClick={() => {
                console.log("not implemented");
              }}
              icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
            />
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <TableBasedPage
      title={title}
      breadcrumbs={breadcrumbs}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      loaded={loaded && ObjectStore.getLoaded()}
      columns={columns}
      data={timeouts}
      tableDataMap={dataMap}
    />
  );
};

export default TimeoutsNew;
