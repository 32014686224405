import React, { useCallback, useEffect, useMemo, useState } from "react";

import ComponentLoading from "../../../modules/ComponentLoading";
import NoData from "../../../modules/NoData";
import { AdultContentUrl, OffensiveContentUrl, SearchUrl, SuicideUrl, VPNUrl } from "../../navigation/RedFlagUrls";
import dayjs from "dayjs";
import SignatureTreeStore from "../../../stores/SignatureTreeStore";
import SignatureTreeActions from "../../../actions/SignatureTreeActions";
import { Link } from "react-router";
import InfoIconV2 from "../../../modules/InfoIconV2";
import DashboardRedFlagItem from "./DashboardRedFlagItem";
import { useUserStore } from "../../../storez/UserStore";

const nonSearchFlags = ["adult_content_hit", "offensive_hit", "vpn_hit", "suicidal_search_term"];

const nonSearchFlagUrls = {
  adult_content_hit: AdultContentUrl,
  offensive_hit: OffensiveContentUrl,
  vpn_hit: VPNUrl,
  suicidal_search_term: SuicideUrl,
};

const flagText = {
  adult_content_hit: "Adult Content",
  offensive_hit: "Offensive Content",
  vpn_hit: "VPN",
  suicidal_search_term: "Suicidal Search",
};

const DashboardRedFlags = (props) => {
  const [signatureKeywords, setSignatureKeywords] = useState([]);
  const [formattedData, setFormattedData] = useState([]);

  // componentDidMount
  useEffect(() => {
    SignatureTreeStore.listen(updateKeywords);

    setTimeout(function () {
      SignatureTreeActions.fetch();
    }, 0);
  }, []);

  // componentWillUnmount
  useEffect(() => {
    return () => {
      SignatureTreeStore.unlisten(updateKeywords);
    };
  }, []);

  const updateKeywords = () => {
    const keywords = SignatureTreeStore.getKeywords();
    setSignatureKeywords(keywords);
  };

  const formatTime = (now, hitDatetime) => {
    const flagTime = dayjs(hitDatetime);
    let displayTime = flagTime.format("MMM DD YYYY, h:mm a");
    const diff = Math.abs(flagTime.diff(now, "minutes"));
    if (diff <= 1) {
      displayTime = "1 minute ago";
    } else if (diff < 60) {
      displayTime = `${diff} minutes ago`;
    } else if (diff < 1440) {
      const hours = Math.abs(flagTime.diff(now, "hours"));
      displayTime = `${hours} hours ago`;
    } else {
      const days = Math.abs(flagTime.diff(now, "days"));
      displayTime = `${days} days ago`;
    }
    return displayTime;
  };

  const getFlagList = useCallback((user, dateFilter) => {
    let flags = [];
    // Iterating through this because it combines search and non-search flags in one object
    Object.keys(user.normals).forEach((flagName) => {
      if (user.normals[flagName] > 0) {
        let link;
        let hits;
        let type;
        if (nonSearchFlags.includes(flagName)) {
          link = nonSearchFlagUrls[flagName](user.name, dateFilter);
          hits = user[flagName];
          type = "non-search";
        } else {
          link = SearchUrl(user.name, flagText[flagName], dateFilter);
          hits = user["search_indicators"][flagName];
          type = "search";
        }
        const normalisedScore = user["normals"][flagName];
        const severityLevel = normalisedScore > 5 ? "high" : normalisedScore > 2 ? "medium" : "low";

        flags.push({
          name: flagText[flagName],
          level: severityLevel,
          link: link,
          hits: hits,
          type: type,
        });
      }
    });
    return flags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);
  const [redFlagsLoading, setRedFlagsLoading] = useState(false);

  async function formatFlags(data) {
    if (!data || redFlagsLoading || signatureKeywords.length === 0) {
      return;
    }
    setRedFlagsLoading(true);

    signatureKeywords.forEach((keyword) => {
      flagText[keyword.id] = keyword.name;
    });

    const redFlagsCount = Math.min(6, data.length);
    const flagsToShow = data.slice(0, redFlagsCount);

    const usernames = flagsToShow.map((element) => element.name);
    const users = await getUsersAsMap(usernames);

    let results = [];
    const now = dayjs();
    const dateFilter =
      props.startDate && props.endDate
        ? `startDate=${props.startDate}&endDate=${props.endDate}&startTime=${props.startTime ? props.startTime : "00:00:00"}&endTime=${
            props.endTime ? props.endTime : "23:59:59"
          }`
        : "";

    flagsToShow.forEach((element) => {
      const user = users.get(element.name);
      const name = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : element.name;
      const result = {
        name: name,
        nameLink: `/surfwize/reporting/users/${element.name}?${dateFilter}`,
        time: formatTime(now, element.most_recent_hit_datetime),
        flags: getFlagList(element, dateFilter),
      };

      results.push(result);
    });

    setFormattedData(results);
    setRedFlagsLoading(false);
  }

  useMemo(() => {
    if (props && props.data && props.data.sorted_users_highest_score) {
      formatFlags(props.data.sorted_users_highest_score).catch((error) => {
        console.log(`Error while formatting red flags: ${error}`);
        setRedFlagsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, getFlagList, signatureKeywords]);

  const showLoading = () => {
    return props.isQueryLoading || signatureKeywords.length === 0 || redFlagsLoading;
  };

  const showNoData = () => {
    return !showLoading() && formattedData.length === 0;
  };

  const redFlagsContent = () => {
    if (showLoading()) {
      return (
        <div className="centered">
          <ComponentLoading />
        </div>
      );
    }
    if (showNoData()) {
      return <NoData />;
    }
    return (
      <div>
        {formattedData?.map(({ name, nameLink, time, flags }, index) => (
          <DashboardRedFlagItem time={time} name={name} nameLink={nameLink} flags={flags} key={index} />
        ))}
      </div>
    );
  };

  return (
    <div className="personaModule">
      <div className="personaModule-header">
        <h2>Red Flags</h2>
        <InfoIconV2 backgroundColor="grey" text="Risk indicators by user. Rollover for summary. Click for detail" />
        <Link to="/cybersafety/wellbeing" className="personaModule-rightLink">
          View All
        </Link>
      </div>
      {redFlagsContent()}
    </div>
  );
};

export default DashboardRedFlags;
