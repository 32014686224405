import { create } from "zustand";
import { ResponseError, jqXHR } from "../types/Api";
import { OutsideSchoolHoursReporting } from "../types/OutsideSchoolHoursReporting";
import Api from "../utils/Api";

export const reportingConfigURL = (): string => {
  return `/config/device/ajax/outside-school-reporting`;
};

export interface OutsideSchoolHoursReportingStore {
  config?: OutsideSchoolHoursReporting;
  fetch: () => Promise<OutsideSchoolHoursReporting>;
  save: (config: OutsideSchoolHoursReporting) => Promise<OutsideSchoolHoursReporting>;
  reset: () => void;
}

export const useOutsideSchoolHoursReportingStore = create<OutsideSchoolHoursReportingStore>((set) => ({
  config: undefined,

  fetch: async (): Promise<OutsideSchoolHoursReporting> => {
    let config,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsyncWithTimeout(reportingConfigURL(), 5000).then(
      (response: OutsideSchoolHoursReporting) => (config = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!config || statusCode !== 200) {
      throw new ResponseError("Failed to fetch outside school hours reporting configuration", statusCode);
    }

    set({ config: config });

    return config;
  },

  save: async (config: OutsideSchoolHoursReporting): Promise<OutsideSchoolHoursReporting> => {
    let saved: OutsideSchoolHoursReporting | undefined,
      statusCode = 200;

    await Api.putAsync(reportingConfigURL(), config).then(
      // CMS doesn't return us the updated config on PUT, so ignore response
      () => (saved = config),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save outside school hours reporting configuration", statusCode);
    }

    set({ config: saved });

    return saved;
  },

  reset: () => {
    set({ config: undefined });
  },
}));
