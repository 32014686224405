import { Box, Flex, Text, useTranslation } from "@familyzone/component-library";
import React from "react";
import { Verdict } from "../../../pages/filtering/types";
import { BLOCKED_TEXT } from "../StatusChip";

interface SafetyNetMatchProps {
  verdict: Verdict;
}

const PAUSE_TITLE_TEXT = "Community Pause Internet";
const PAUSE_DESCRIPTION_TEXT =
  "This student's internet is currently paused by their parent from the Qustodio App. This pause is active until";

const TestPolicyCommunityPauseInternet: React.FC<SafetyNetMatchProps> = ({ verdict }) => {
  const { t } = useTranslation();

  const pauseEnd = verdict.debug?.community_student_config?.pauseEnd;
  const formattedPauseEnd = pauseEnd !== undefined ? new Date(pauseEnd).toLocaleString() : "Unknown";

  return (
    <Box>
      <Text fontSize="md" fontWeight="medium" color="text.title" mb="sp8">
        {t(PAUSE_TITLE_TEXT)}
      </Text>
      <Text color="text.paragraph.regular">{`${t(PAUSE_DESCRIPTION_TEXT)} ${formattedPauseEnd}.`}</Text>
      <br />
      <Flex width="100%" justifyContent="center" alignItems="center" backgroundColor="neutrals.10" minHeight="100px">
        <Flex flexDirection="column" justifyContent="center" alignItems="center" maxWidth="290px" padding="sp8">
          <Box bgColor="accent.red.300" padding="sp8" paddingLeft="sp12" paddingRight="sp12" mb="sp8" borderRadius="4px">
            <Text textAlign="center" fontSize="large" color="white">
              {t(BLOCKED_TEXT)}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TestPolicyCommunityPauseInternet;
