import ReactGA from "react-ga4";

export function initGoogleAnalytics(applianceRegion: string): void {
  const measurementID = process.env.REACT_APP_GOOGLE_ANALYTICS;

  if (!measurementID) {
    console.error("Google Analytics measurement ID is not defined.");
    return;
  }

  if (applianceRegion === "beta-1" || applianceRegion === "sit") {
    return;
  }

  // Initialize Google Analytics only if region is not 'beta-1' or 'sit'
  ReactGA.initialize(measurementID);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
}
