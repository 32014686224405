import React from "react";
import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import { Session } from "./ActiveHostsHelpers";

interface Props {
  sessionToRemove: Session;
  handleOK: (session: Session) => void;
  handleOnClose: () => void;
}

export const DeleteSessionModal: React.FC<Props> = ({ sessionToRemove, handleOK, handleOnClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      headerText={t(`Remove Session`)}
      isOpen
      onClose={() => handleOnClose()}
      size="sm"
      variant="warning"
      primaryCTALabel={t("Remove Session")}
      onPrimaryCTAClick={() => handleOK(sessionToRemove)}
    >
      <ModalBody>
        <Text mb="sp8" fontFamily="heading">
          {t("Are you sure you want to remove the session for")} {sessionToRemove["user"]} ?
        </Text>
        <Text mb="sp8" fontFamily="paragraphs">
          {t("This action will remove or force the user to log out from the School Manager Network.")}
        </Text>
      </ModalBody>
    </Modal>
  );
};
