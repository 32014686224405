import React from "react";
import { ReactElement } from "react";
import LeftPanel from "../utils/LeftPanel";
import Header from "../utils/Header";

const AccessDenied = (): ReactElement => (
  <LeftPanel>
    <div className="padded">
      <Header>Access denied</Header>
      <div className="row">
        <div className="col-md-12">Please speak with your administrator if you think you should have access to this page.</div>
      </div>
    </div>
  </LeftPanel>
);

export default AccessDenied;
