import SignatureActions from "../actions/SignatureActions";
import SignatureStore from "../stores/SignatureStore";
import React from "react";
import Criteria from "./Criteria";
import SmartListSelector from "../modules/SmartListSelector";

export default class SignatureCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      availableSignatures: SignatureStore.getSignatures(),
    };

    /* {type: "fingerprint", negate: false, conditions: []} */
  }

  componentDidMount() {
    SignatureStore.listen(this.onChange);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableSignatures: SignatureStore.getSignatures(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Add = (item) => {
    this.state.criteria.conditions.push(item);
    this.props.bubbleMethod(this.state.criteria);
  };

  handle_Remove = (item) => {
    let new_conditions = [];
    for (let x of this.state.criteria.conditions) {
      if (x !== item) {
        new_conditions.push(x);
      }
    }

    this.state.criteria.conditions = new_conditions;
    this.props.bubbleMethod(this.state.criteria);
  };

  getSignatureValue = (item) => {
    return item["id"];
  };

  getSignatureName = (item) => {
    return item["name"];
  };

  render() {
    return (
      <Criteria label="Signature" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <SmartListSelector
          get__value={this.getSignatureValue}
          get__label={this.getSignatureName}
          selected={this.state.criteria.conditions}
          available={this.state.availableSignatures}
          removeSelectedFromOptions
          addElement={this.handle_Add}
          removeElement={this.handle_Remove}
        />
      </Criteria>
    );
  }
}
