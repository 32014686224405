import { Box, Flex, Text, useTranslation } from "@familyzone/component-library";
import React from "react";
import { policyCriteriaText } from "./TestPolicyModal";
import { Verdict } from "../../../pages/filtering/types";

interface PolicyCriteriaProps {
  verdict: Verdict;
  highlightedCriteria: number[];
  criteriaLabels: string[];
}

const PolicyCriteria: React.FC<PolicyCriteriaProps> = ({ verdict, highlightedCriteria, criteriaLabels }: PolicyCriteriaProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text color="text.paragraph.light" fontWeight="light" mb="sp8">
        {t(policyCriteriaText)}
      </Text>
      <Flex
        border="1px solid"
        borderColor="neutrals.30"
        padding="sp8"
        paddingBottom="6px"
        borderRadius="4px"
        direction="row"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {criteriaLabels.map((criteriaLabel, i) => (
          <Box
            borderRadius="4px"
            paddingTop="sp4"
            paddingBottom="sp4"
            paddingLeft="sp8"
            paddingRight="sp8"
            backgroundColor={
              highlightedCriteria.includes(i) ? (verdict.verdict === 1 ? "accent.green.100" : "accent.red.300") : "neutrals.300"
            }
            cursor="default"
            key={`criteria-${i}`}
            marginRight="sp4"
            marginBottom="sp4"
          >
            <Text fontSize="md" color={highlightedCriteria.includes(i) && verdict.verdict === 1 ? "neutrals.900" : "white"}>
              {t(criteriaLabel)}
            </Text>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default PolicyCriteria;
