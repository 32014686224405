import React, { useState } from "react";
import { Box, SearchBox, Table, Tbody, TbodyInfiniteScroll, Td, Text, Th, Thead, Tr, withTranslation } from "@familyzone/component-library";
import AmountField from "../../modules/AmountField";
import { orderBy } from "lodash";
import { Link } from "react-router";
import { zIndices } from "../../utils/ZIndexUtil";
import { onFilter } from "./ReportingSearchableTableHelper";

const ReportingSearchableTable = ({
  t,
  data,
  loaded,
  columns,
  tableButtonGroup,
  isInfiniteScroll,
  initialSortColumn,
  initialSortDirection,
}) => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn ? initialSortColumn : columns[0].name);
  const [sortDirection, setSortDirection] = useState(initialSortDirection ? initialSortDirection : "asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [shownRowCount, setShownRowCount] = useState(30);

  const onSort = (sortColumn, sortDirection) => {
    const sort = sortDirection === "asc" ? "desc" : "asc";

    setSortColumn(sortColumn);
    setSortDirection(sort);
  };

  const showMore = () => {
    setShownRowCount(shownRowCount + 20);
  };

  const showTableButtonGroup = () => {
    if (tableButtonGroup) {
      return tableButtonGroup;
    }
  };

  const renderEachRow = (r, i) => (
    <Tr key={i} p="12">
      {columns.map((c, i) =>
        c.isAmount ? (
          <Td key={i}>
            <Text fontFamily="paragraphs" fontSize="md">
              <AmountField amount={r[c.name]} />
            </Text>
          </Td>
        ) : c.isLink ? (
          <Td key={i}>
            <Link to={r.linkUrl}>
              <Text fontFamily="paragraphs" fontSize="md" color="brand.500">
                {`${r[c.name] || 0}`}
              </Text>
            </Link>
          </Td>
        ) : (
          <Td key={i}>
            <Text fontFamily="paragraphs" fontSize="md">
              {`${r[c.name] || 0}`}
            </Text>
          </Td>
        )
      )}
    </Tr>
  );

  return (
    <Box p="sp24" m="sp24" borderRadius="8px" bgColor="neutrals.0" className="reporting-table">
      <Box mb="24px" h="43px" display="flex" justifyContent="space-between" flex-direction="row">
        <Box display="flex" mb="24px" alignSelf="flex-start">
          <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onClear={() => setSearchTerm("")} w="100%" />
        </Box>
        {showTableButtonGroup()}
      </Box>
      <Table width="100%" mt="24px" loaded={loaded}>
        <Thead position="sticky" top="0" zIndex={zIndices.thead}>
          {columns.map((c) => (
            <Th
              key={c.name}
              headerText={t(c.header)}
              columnName={c.name}
              sortDirection={c.sortable ? (sortColumn === c.name ? sortDirection : "") : undefined}
              handleSort={() => {
                onSort(c.name, sortColumn === c.name && sortDirection);
              }}
            />
          ))}
        </Thead>
        <>
          {isInfiniteScroll && (
            <TbodyInfiniteScroll
              fetchData={showMore}
              hasMore={data.length >= shownRowCount}
              parentElemId="ComponentWrapper"
              loaded={loaded}
              searched={!!searchTerm}
            >
              {orderBy(data, sortColumn, sortDirection)
                .filter((r) => !searchTerm || onFilter(r, searchTerm))
                .filter((_r, i) => i <= shownRowCount)
                .map(renderEachRow)}
            </TbodyInfiniteScroll>
          )}
          {!isInfiniteScroll && (
            <Tbody loaded={loaded}>
              {orderBy(data, sortColumn, sortDirection)
                .filter((r) => !searchTerm || onFilter(r, searchTerm))
                .map(renderEachRow)}
            </Tbody>
          )}
        </>
      </Table>
    </Box>
  );
};

export default withTranslation()(ReportingSearchableTable);
