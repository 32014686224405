import React from "react";

import SignatureStore from "../stores/SignatureStore";
import SignatureActions from "../actions/SignatureActions";

export default class QueryFilter extends React.Component {
  componentDidMount() {
    SignatureStore.listen(this.resolveFilterTypeSignatureName);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);

    this.resolveFilterTypeSignatureName();
  }

  componentWillUnmount() {
    SignatureStore.unlisten(this.resolveFilterTypeSignatureName);
  }

  resolveFilterTypeSignatureName = () => {
    if (this.props.filters.filter_type) {
      let signature = SignatureStore.getSignature(this.props.filters.filter_type);
      if (signature) {
        this.setState({
          filter_type: signature.name,
        });
      }
    }
  };

  renderActionableBlocked() {
    let verdict;
    switch (this.props.filters.blocked) {
      case "true":
        verdict = "BLOCKED";
        break;
      case "false":
        verdict = "ALLOWED";
        break;
      default:
        verdict = "ALL";
        break;
    }
    return (
      <span className="tableheader-button" id="blocked_filter" onClick={this.props.onBlockedClicked}>
        <span>Verdict: {verdict}</span>
      </span>
    );
  }

  renderStaticBlocked() {
    if (this.props.filters.blocked) {
      return (
        <span className="tableheader-button" id="blocked_filter" style={{ cursor: "not-allowed" }}>
          <span>Blocked Requests</span>
        </span>
      );
    }
    return <span />;
  }

  renderPolicy() {
    if (this.props.filters.filter_policyid) {
      return (
        <span className="tableheader-button" id="blocked_filter" style={{ cursor: "not-allowed" }}>
          <span>Policy</span>
        </span>
      );
    }
    return <span />;
  }

  renderType() {
    if (this.props.filters.filter_type) {
      return (
        <span className="tableheader-button" id="blocked_filter" style={{ cursor: "not-allowed" }}>
          <span>{this.props.filters.filter_type}</span>
        </span>
      );
    }
    return <span />;
  }

  renderWebsite() {
    if (this.props.filters.filter_website) {
      return (
        <span className="tableheader-button" id="blocked_filter" style={{ cursor: "not-allowed" }}>
          <span>{this.props.filters.filter_website}</span>
        </span>
      );
    }
    return <span />;
  }

  render_filter = () => {
    let res = [];
    if (this.props.filters.filter_user) {
      res.push(
        <span className="tableheader-button" id="query_filter">
          <span>{"Filtered on " + this.props.filters.filter_user}</span>
        </span>
      );
    }

    return res;
  };

  render() {
    return (
      <div style={{ display: "inline-table" }}>
        {this.props.onBlockedClicked ? this.renderActionableBlocked() : this.renderStaticBlocked()}
        {!this.props.hideUser && this.render_filter()}
        {this.renderType()}
        {this.renderWebsite()}
        {this.renderPolicy()}
      </div>
    );
  }
}
