import React from "react";
import SearchableSelect from "../modules/SearchableSelect";
import differenceWith from "lodash.differencewith";

class GroupSelectedOption extends React.Component {
  handleRemove = () => {
    this.props.handleRemove(this.props.item);
  };

  render() {
    return (
      <div className="listselector-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.handleRemove}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </a>
        {this.props.label}
      </div>
    );
  }
}

export default class SmartListSelector extends React.Component {
  handle_AddSelection = (event) => {
    this.props.addElement(event.value);
  };

  handle_RemoveSelection = (item) => {
    this.props.removeElement(item);
  };

  render_SelectedOptions = () => {
    const ret = [];
    for (let x of this.props.selected) {
      ret.push(
        <GroupSelectedOption
          item={x}
          label={this.getLabelFromKey(x)}
          key={x}
          handleRemove={this.handle_RemoveSelection}
        ></GroupSelectedOption>
      );
    }

    return ret;
  };

  getLabelFromKey = (key) => {
    for (let available of this.props.available) {
      if (key === this.props.get__value(available)) {
        return this.props.get__label(available);
      }
    }

    return key;
  };

  get_SelectionMap() {
    let available = this.props.available;
    if (this.props.removeSelectedFromOptions) {
      available = Array.from(available);

      const comparator = (a, b) => this.props.get__value(a) === b;
      available = differenceWith(available, this.props.selected, comparator);
    }

    let ret = [];
    for (let option of available) {
      ret.push([this.props.get__value(option), this.props.get__label(option)]);
    }
    return ret;
  }

  render_Additional = () => {
    if (this.props.render_Additional) {
      return this.props.render_Additional();
    }
    return <div></div>;
  };

  render() {
    return (
      <div>
        <div>
          <SearchableSelect value={""} onChange={this.handle_AddSelection} valueDataMap={this.get_SelectionMap()}></SearchableSelect>
          <label>{this.props.label}</label>
        </div>
        {this.render_Additional()}

        <div className="selected-options scrollable">{this.render_SelectedOptions()}</div>
      </div>
    );
  }
}
