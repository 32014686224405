import React from "react";
import PropTypes from "prop-types";

import Header from "../utils/Header";
import SessionActions from "../actions/SessionActions";
import SessionStore from "../stores/SessionStore";
import Api from "../utils/Api";
import LeftPanel from "../utils/LeftPanel";
import DumbBusyIndicator from "../modules/DumbBusyIndicator";

export default class SelectDevice extends React.Component {
  componentDidMount() {
    SessionStore.listen(this.deviceChange);

    const deviceid = this.props.params.deviceid;
    Api.get(
      `/managedevice/ajax/device/${deviceid}`,
      (data) => {
        const device = data.result;
        const _device = { deviceid: device.deviceid, device: device }; // compatible with format SessionStore expects
        SessionActions.changeDevice(_device);
      },
      () => {
        this.context.router.push("/devices");
      }
    );
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.deviceChange);
  }

  deviceChange = () => {
    this.context.router.push("/surfwize");
  };

  render() {
    return (
      <LeftPanel>
        <div className="padded">
          <Header>
            <DumbBusyIndicator loaded={false} />
            Loading device from region redirect
          </Header>
        </div>
      </LeftPanel>
    );
  }
}

SelectDevice.contextTypes = {
  router: PropTypes.object.isRequired,
};
