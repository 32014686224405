import React from "react";
import PropTypes from "prop-types";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import { get } from "shades";
import NuMultiSelect from "../../utils/NuMultiSelect";

export default class MdnsEdit extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      configuration: {
        name: "",
        slaves: [],
      },
      devices: [],
      saving: false,
    };

    this.displayTextLens = get("interface");
    this.valueLens = get("interface");
  }

  componentDidMount() {
    this.handle_load();
    InterfaceStore.listen(this.onChange);
    InterfaceActions.fetch();
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  handle_load = () => {
    let self = this;
    Api.get("/edgewize/ajax/mdns/" + this.props.params.mdns_id, function (result) {
      self.setState({
        configuration: result["data"],
      });
    });
  };

  handle_save = (event) => {
    event.preventDefault();
    let self = this;
    self.setState({ saving: true });
    Api.post("/edgewize/ajax/mdns/" + this.props.params.mdns_id, this.state.configuration, function (result) {
      self.handle_load();
      self.context.router.push("/edgewize/device/mdns");
    });
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let i of this.state.devices) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  };

  handle_ChangeName = (event) => {
    const configuration = this.state.configuration;
    configuration["name"] = event.target.value;
    this.setState({ configuration: configuration });
  };

  addBridge = (item) => {
    const itemValue = this.valueLens(item);
    this.state.configuration.slaves.push(itemValue);

    this.setState({ configuration: this.state.configuration });
  };

  removeBridge = (itemValue) => {
    const removalIndex = this.state.configuration.slaves.findIndex((element) => element === itemValue);
    this.state.configuration.slaves.splice(removalIndex, 1);

    this.setState({ configuration: this.state.configuration });
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>MDNS Bridge</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element-title">Name</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.configuration.name} onChange={this.handle_ChangeName} />
                  </div>

                  <div className="formgroup-element-title">Bridge Slaves</div>
                  <div className="mui-textfield">
                    <NuMultiSelect
                      allItems={this.state.devices}
                      selectedValues={this.state.configuration.slaves}
                      displayTextLens={this.displayTextLens}
                      valueLens={this.valueLens}
                      searchPlaceholder="Bridge Slaves"
                      selectedPlaceHolder="No Bridge Slaves Selected"
                      onAdd={this.addBridge}
                      onRemove={this.removeBridge}
                    />
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_save} saving={this.state.saving} changes={true} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}

MdnsEdit.contextTypes = {
  router: PropTypes.object.isRequired,
};
