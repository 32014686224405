import { headers } from "./ApiHelpers";

export type EfrontCourse = {
  /**
   * Course ID from within eFront
   */
  id: number;
  /**
   * Name of the course
   */
  title: string;
  courseCode: string;
  /**
   * Description of the course
   */
  description: string;
  /**
   * If true, this course is currently assigned to the appliance. Only present if configured is true.
   */
  assigned?: boolean;
};

export type EfrontParentBranch = {
  /**
   * Branch ID from within eFront
   */
  id: number;
  /**
   * Branch name from within eFront
   */
  name: string;
  /**
   * If true, this branch is the parent branch for the appliance. Only present if configured is true.
   */
  parent?: boolean;
};

type EfrontLanguage = {
  /**
   * Language ID from within eFront
   */
  id: number;
  /**
   * Language name from within eFront
   */
  name: string;
  /**
   * If true, this language is currently assigned to the appliance. Only present if configured is true.
   */
  assigned?: boolean;
};

type EfrontTheme = {
  /**
   * Theme ID from within eFront
   */
  id: number;
  /**
   * Theme name from within eFront
   */
  name: string;
  /**
   * If true, this theme is currently assigned to the appliance. Only present if configured is true.
   */
  assigned?: boolean;
};

export enum ClusivityType {
  /**
   * Courses available to all teachers
   */
  All = "ALL",
  /**
   * Courses available to all except users and groups from this configuration
   */
  Exclusive = "EXCLUSIVE",
  /**
   * Courses only available to users and groups from this configuration
   */
  Inclusive = "INCLUSIVE",
}

export type ClusiveUsers = {
  /**
   * StableID of the user
   */
  id: string;
  username: string;
};

export type ClusiveGroups = {
  /**
   * StableID of the group
   */
  id: string;
  name: string;
};

export type ClusivityConfig = {
  type: ClusivityType;
  /**
   * The users and groups to include/exclude; required unless clusivity=ALL
   */
  users?: ClusiveUsers[];
  /**
   * The users and groups to include/exclude; required unless clusivity=ALL
   */
  groups?: ClusiveGroups[];
};

export type CoursesConfigurationResponse = {
  /**
   * If true, the appliance is configured for Courses
   */
  configured: boolean;
  /**
   * Branch ID from within eFront. Only present if configured is true.
   */
  branchId?: number;
  parentBranches: EfrontParentBranch[];
  languages: EfrontLanguage[];
  themes: EfrontTheme[];
  courses: EfrontCourse[];
  clusivity: ClusivityConfig;
};

export type CreateConfigPayload = {
  parentBranchId: number;
  themeId?: number;
  languageId?: number;
};

export type UpdateVisibilityPayload = {
  clusivity: ClusivityType;
  users?: string[];
  groups?: string[];
};

export type UpdateCoursesPayload = {
  /**
   * Array of courses to assign to the appliance
   */
  courses: {
    /**
     * Course ID from within eFront
     */
    id: number;
  }[];
};

export const SDL_API = {
  /**
   * Get the configuration for a given appliance
   */
  getConfiguration(): Promise<Response> {
    return fetch(`/sdl/config/appliances/${headers().Device}/configuration`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
    });
  },

  /**
   * Create a configuration (eFront branch) for a given appliance
   */
  createConfiguration(payload: CreateConfigPayload): Promise<Response> {
    return fetch(`/sdl/config/appliances/${headers().Device}/configuration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
      body: JSON.stringify(payload),
    });
  },

  /**
   * Modify the Courses tab visibility for a given appliance
   */
  updateVisibility(payload: UpdateVisibilityPayload): Promise<Response> {
    return fetch(`/sdl/config/appliances/${headers().Device}/configuration`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
      body: JSON.stringify(payload),
    });
  },

  /**
   * Update the Courses for a given appliance
   */
  updateCourses(payload: UpdateCoursesPayload): Promise<Response> {
    return fetch(`/sdl/config/appliances/${headers().Device}/courses`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
      body: JSON.stringify(payload),
    });
  },

  /**
   * Get the Courses (eFront) configuration for a given appliance
   */
  getCoursesConfig(): Promise<Response> {
    return fetch(`/sdl/config`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
    });
  },
  /**
   * Configure Courses (eFront) for a given appliance
   * @param eFrontBranchId - eFront branch ID
   * @param eFrontSubdomain - eFront subdomain
   * @param eFrontApiKey - eFront API key
   */
  createCoursesConfig(eFrontBranchId: number, eFrontSubdomain: string, eFrontApiKey: string): Promise<Response> {
    return fetch(`/sdl/config`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
      body: JSON.stringify({
        branchId: eFrontBranchId,
        subdomain: eFrontSubdomain,
        apiKey: eFrontApiKey,
      }),
    });
  },
  /**
   * Delete the Courses (eFront) configuration for a given appliance
   */
  deleteCoursesConfig(): Promise<Response> {
    return fetch(`/sdl/config`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: headers().Token,
        device: headers().Device,
      },
    });
  },
};
