import React from "react";
import InterfaceActions from "../../actions/InterfaceActions";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";

import InterfaceStore from "../../stores/InterfaceStore";
import Api from "../../utils/Api";

import LeftPanel from "../../utils/LeftPanel";
import RightPanel from "../../utils/RightPanel";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";

class ClientVpnStatus extends React.Component {
  render_ActiveStatus = () => {
    return (
      <div>
        <div className="right-panel-line">
          <span className="status_key">Status:</span> <span className="status_value">Running</span>
        </div>
        <div className="right-panel-line">
          <span className="status_key">Port:</span> <span className="status_value">1701</span>
        </div>
        <div className="right-panel-line">
          <span className="status_key">Active Clients:</span>{" "}
          <span className="status_value">{this.props.status["active_clients"].length}</span>
        </div>
      </div>
    );
  };

  render_InactiveStatus = () => {
    return (
      <div className="right-panel-line">
        <span className="status_key">Status:</span> <span className="status_value">Offline</span>
      </div>
    );
  };

  render() {
    if (this.props.status.active === true) {
      return this.render_ActiveStatus();
    }

    return this.render_InactiveStatus();
  }
}

export default class ClientVpn extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      configuration: {
        auth_mode: 1,
        auto_configure_firewall: true,
        auto_start: false,
        dns_ns1: "",
        dns_ns2: "",
        groups: [],
        local_device: "",
        provide_dns: true,
        secret_key: "",
        user_authentication_mode: 1,
        vpn_assign_ip: true,
        vpn_local_ip: "",
        vpn_remote_ip_range_end: "",
        vpn_remote_ip_range_start: "",
      },
      status: {},
      devices: [],
      loaded: false,

      saving: false,
      changes: true,
    };
  }

  componentDidMount() {
    this.handle_load();
    InterfaceStore.listen(this.onChange);

    setTimeout(function () {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/clientvpn", function (result) {
      if (result["data"]) {
        self.setState({
          configuration: result["data"],
          status: result["status"],
          loaded: true,
        });
      }
    });
  };

  handle_save = () => {
    let self = this;
    self.setState({
      changes: false,
      saving: true,
    });

    Api.post("/edgewize/ajax/clientvpn", this.state.configuration, function (result) {
      self.handle_load();
      self.setState({
        saving: false,
      });
    });
  };

  handle_Submit = (event) => {
    event.preventDefault();
  };

  handle_ChangeEnabled = (event) => {
    const configuration = this.state.configuration;
    configuration["auto_start"] = event.target.checked;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeAutoConfigureFirewall = (event) => {
    const configuration = this.state.configuration;
    configuration["auto_configure_firewall"] = event.target.checked;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeWanInterface = (event) => {
    const configuration = this.state.configuration;
    configuration["local_device"] = event.value;
    this.setState({ configuration: configuration, changes: true });
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let i of this.state.devices) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  };

  handle_ChangeAllocateAddresses = (event) => {
    const configuration = this.state.configuration;
    configuration["vpn_assign_ip"] = event.target.checked;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeVpnIpRangeStart = (event) => {
    const configuration = this.state.configuration;
    configuration["vpn_remote_ip_range_start"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };
  handle_ChangeVpnIpRangeEnd = (event) => {
    const configuration = this.state.configuration;
    configuration["vpn_remote_ip_range_end"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };
  handle_ChangeVpnLocalIp = (event) => {
    const configuration = this.state.configuration;
    configuration["vpn_local_ip"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangePrivateDns = (event) => {
    const configuration = this.state.configuration;
    configuration["provide_dns"] = event.target.checked;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeDnsNS1 = (event) => {
    const configuration = this.state.configuration;
    configuration["dns_ns1"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeDnsNS2 = (event) => {
    const configuration = this.state.configuration;
    configuration["dns_ns2"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeSecret = (event) => {
    const configuration = this.state.configuration;
    configuration["secret_key"] = event.target.value;
    this.setState({ configuration: configuration, changes: true });
  };

  handle_ChangeGroups = (groups) => {
    const configuration = this.state.configuration;
    configuration.groups = groups.map((group) => group.name);
    this.setState({
      configuration: configuration,
      changes: true,
    });
  };

  render() {
    return [
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Client VPN
            <DumbBusyIndicator loaded={this.state.loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.configuration.auto_start} onChange={this.handle_ChangeEnabled} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">WAN Interface</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          clearable={false}
                          value={this.state.configuration.local_device}
                          onChange={this.handle_ChangeWanInterface}
                          valueDataMap={this.get_SelectionMap()}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Local Address</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input
                          type="text"
                          value={this.state.configuration.vpn_local_ip}
                          onChange={this.handle_ChangeVpnLocalIp}
                          placeholder="IP Address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Dynamic Addressing</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allocate IP</div>
                    <div className="formgroup-element-fields">
                      <div className="formgroup-element-fields-inline">
                        <div className="mui-checkbox">
                          <input
                            type="checkbox"
                            checked={this.state.configuration.vpn_assign_ip}
                            onChange={this.handle_ChangeAllocateAddresses}
                          />
                        </div>
                        <div className="mui-textfield">
                          <input
                            type="text"
                            value={this.state.configuration.vpn_remote_ip_range_start}
                            onChange={this.handle_ChangeVpnIpRangeStart}
                            placeholder="Start IP"
                          />
                        </div>
                        <div className="mui-textfield">
                          <input
                            type="text"
                            value={this.state.configuration.vpn_remote_ip_range_end}
                            onChange={this.handle_ChangeVpnIpRangeEnd}
                            placeholder="End IP"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Provide DNS</div>
                    <div className="formgroup-element-fields">
                      <div className="formgroup-element-fields-inline">
                        <div className="mui-checkbox">
                          <input type="checkbox" checked={this.state.configuration.provide_dns} onChange={this.handle_ChangePrivateDns} />
                        </div>
                        <div className="mui-textfield">
                          <input
                            type="text"
                            value={this.state.configuration.dns_ns1}
                            onChange={this.handle_ChangeDnsNS1}
                            placeholder="Primary Server"
                          />
                        </div>
                        <div className="mui-textfield">
                          <input
                            type="text"
                            value={this.state.configuration.dns_ns2}
                            onChange={this.handle_ChangeDnsNS2}
                            placeholder="Secondary Server"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Authentication Options</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">VPN Secret Key</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.configuration.secret_key} onChange={this.handle_ChangeSecret} />
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Allowed Groups</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <MultiGroupSearchSelector
                          onChangeGroups={this.handle_ChangeGroups}
                          preselected={this.state.configuration?.groups?.map((group) => ({ value: group, name: group }))}
                          useLegacyId={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              onClick={this.handle_save}
              saving={this.state.saving}
              changes={this.state.changes}
            ></LetterPanelSaveButton>
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>,
      <RightPanel visible={this.state.auto_configure_firewall}>
        <div className="right-panel-heading">VPN Status</div>
        <ClientVpnStatus status={this.state.status}></ClientVpnStatus>
      </RightPanel>,
    ];
  }
}
