import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import React from "react";

const SchoolCalendarSetupModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      size="md"
      primaryCTALabel={t("Setup School Calendar")}
      onPrimaryCTAClick={onClose}
      headerText={t("Setup school hours and days in the Calendar")}
    >
      <ModalBody>
        <Text mb="sp8" fontFamily="paragraphs" color="text.paragraph.regular">
          {t("Use this school calendar page to define school hours and days.")}
        </Text>
      </ModalBody>
    </Modal>
  );
};

export default SchoolCalendarSetupModal;
