import React from "react";
import "../../css/SearchableSelect.css";
import Select from "react-virtualized-select";
import createFilterOptions from "react-select-fast-filter-options";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

export default class SearchableSelect extends React.Component {
  constructor(props, context) {
    super(props, context);
    let options = this.getOptions(this.props.valueDataMap);
    this.state = {
      valueDataMap: this.props.valueDataMap,
      filterOptions: createFilterOptions({ options }),
      options: options,
    };
  }

  render() {
    return (
      <div data-cy={this.props["data-cy"]}>
        <Select
          value={this.props.value}
          disabled={this.props.disabled}
          className={"searchable-select " + this.props.className}
          options={this.state.options}
          filterOptions={this.state.filterOptions}
          onChange={this.handle_OnChange}
          clearable={this.props.clearable}
          placeholder={this.props.placeholder}
          multi={this.props.multi}
          delimiter={this.props.delimiter}
          noResultsText={this.props.noResultsText}
          onInputChange={this.props.onInputChange}
          data-cy={this.props["data-cy"]}
        />
      </div>
    );
  }

  handle_OnChange = (event) => {
    let internalEvent = event;
    if (!internalEvent) {
      internalEvent = {
        label: "",
        value: "",
      };
    }

    this.props.onChange(internalEvent);
  };

  componentWillReceiveProps = (newProps) => {
    let valueDataMap = newProps.valueDataMap;
    if (this.state.options !== this.getOptions(valueDataMap)) {
      let options = this.getOptions(valueDataMap);
      this.setState({
        valueDataMap: valueDataMap,
        options: options,
        filterOptions: createFilterOptions({ options }),
      });
    }
  };

  getOptions(valueDataMap) {
    let ret = [];
    for (let keyValuePair of valueDataMap) {
      let key = keyValuePair[0];
      let keyValue = keyValuePair[1];
      let item = { value: key, label: keyValue };
      ret.push(item);
    }
    return ret;
  }
}
