import { useDebounce, useMeasure } from "react-use";
import { useOverflowAmount } from "../../../utils/hooks/useOverflowAmount";
import { usePrevious } from "@chakra-ui/hooks";
import React, { useRef, useState } from "react";
import DashboardRedFlagItemNew from "./DashboardRedFlagItemNew";

const DashboardRedFlagItem = ({ time, name, nameLink, flags }) => {
  const [setElement, { width: elementWidth }] = useMeasure();
  const previousElementWidth = usePrevious(elementWidth);
  const debounceTimeoutMs = 200;
  const [,] = useDebounce(handleWidthChange, debounceTimeoutMs, [previousElementWidth, elementWidth, flags]);

  const pillsContainerRef = useRef(null);
  const [visibleFlags, setVisibleFlags] = useState(flags);

  // These values are needed for the calculation of the overflow
  const marginLeftRight = 5;

  const [overflowDebounce, setOverflowDebounce] = useState(false);

  const overFlowCallback = (overflowAmount) => {
    if (!overflowDebounce && overflowAmount > 0) {
      let _overflowAmount = overflowAmount;
      const pillElements = pillsContainerRef.current.children;

      for (let i = pillElements.length - 1; i >= 0; i--) {
        const element = pillElements[i];
        _overflowAmount = _overflowAmount - element.offsetWidth - 2 * marginLeftRight;

        if (_overflowAmount <= -50) {
          setOverflowDebounce(true);
          setTimeout(() => {
            setOverflowDebounce(false);
          }, debounceTimeoutMs);
          // allow -50 for hidden flags link
          setVisibleFlags(visibleFlags.slice(0, i));
          break;
        }
      }
    }
  };

  useOverflowAmount(pillsContainerRef, overFlowCallback);

  function handleWidthChange() {
    // if container width increased, rerender with all flags so that the overflow logic will take them all into consideration with the increased container width
    if (previousElementWidth !== 0 && elementWidth !== 0 && previousElementWidth < elementWidth) {
      setVisibleFlags(flags);
    }
  }

  const setRefs = (element) => {
    pillsContainerRef.current = element;
    setElement(element);
  };

  const hiddenFlagsCount = flags.length - visibleFlags.length;

  const overflowLink = {
    pathname: "/cybersafety/wellbeing",
    state: { default_filter: name },
  };

  return (
    <DashboardRedFlagItemNew
      name={name}
      nameLink={nameLink}
      visibleFlags={visibleFlags}
      setRefs={setRefs}
      hiddenFlagsCount={hiddenFlagsCount}
      overflowLink={overflowLink}
      time={time}
    />
  );
};

export default DashboardRedFlagItem;
