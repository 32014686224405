import React from "react";
import $ from "jquery";

import SignatureStore from "../stores/SignatureStore";
import SignatureActions from "../actions/SignatureActions";
import SignatureTreeStore from "../stores/SignatureTreeStore";
import SignatureTreeActions from "../actions/SignatureTreeActions";
import ObjectStore from "../stores/ObjectStore";
import ObjectActions from "../actions/ObjectActions";
import { isValidWebsite } from "../utils/Validation";

import "../../css/Layer7CriteriaSelector.css";

class SelectedSignature extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      signature: props.signature,
      signatures: SignatureStore.getSignatures(),
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      signature: nextprops.signature,
    });
  }

  componentDidMount() {
    SignatureStore.listen(this.onChange);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      signatures: SignatureStore.getSignatures(),
    });
  };

  render_SignatureName = () => {
    for (let signature of this.state.signatures) {
      if (signature["id"] === this.state.signature) {
        return <span> {signature["name"]}</span>;
      }
    }

    return <span className="signature_item_conditions"> {this.state.signature}</span>;
  };

  render() {
    return (
      <div className="signature_item" data-cy="signature-item">
        <a
          className="signature_item_remove selected-item-remove"
          onClick={() => this.props.handle_Remove_Signature(this.props.signature)}
          data-cy="signature-item-remove"
        >
          <i className="fa fa-times" aria-hidden="true" />
        </a>
        Signature
        {this.render_SignatureName()}
      </div>
    );
  }
}

class SelectedObject extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      website_object: props.website_object,
      availableObjects: ObjectStore.getObjects(),
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      website_object: nextprops.website_object,
    });
  }

  componentDidMount() {
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    ObjectStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableObjects: ObjectStore.getObjects(),
    });
  };

  render_ObjectName = () => {
    for (let obj of this.state.availableObjects) {
      if (obj["id"] === this.state.website_object) {
        return <span> {obj["name"]}</span>;
      }
    }

    return <span className="signature_item_conditions"> {this.state.website_object}</span>;
  };

  render() {
    return (
      <div className="signature_item">
        <a
          className="signature_item_remove selected-item-remove"
          onClick={() => this.props.handle_Remove_Object(this.props.website_object)}
        >
          <i className="fa fa-times" aria-hidden="true" />
        </a>
        Manual Category
        {this.render_ObjectName()}
      </div>
    );
  }
}

export default class extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected_websites: props.selected_websites,
      selected_signatures: props.selected_signatures,
      selected_website_objects: props.selected_website_objects,
      search: "",
      website: "",
      websiteInvalid: false,
      dropdown_open: false,

      signatures: [],
      categories: [],
    };

    this.textInput = React.createRef();
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      selected_websites: nextprops.selected_websites,
      selected_signatures: nextprops.selected_signatures,
      selected_website_objects: nextprops.selected_website_objects,
    });
  }

  componentDidMount() {
    SignatureTreeStore.listen(this.onChange);
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      SignatureTreeActions.fetch();
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SignatureTreeStore.unlisten(this.onChange);
    ObjectStore.unlisten(this.onChange);
  }

  onChange = () => {
    let unprocessed_signatures = SignatureTreeStore.getSignatures();
    let original_signatures = [];

    for (let x = 0; x < unprocessed_signatures.length; x++) {
      let category = unprocessed_signatures[x];
      let resolved_category = {
        name: category["name"],
        id: category["id"],
        category: "All " + category["name"],
        searchable: category["name"],
      };
      original_signatures.push(resolved_category);

      for (let y = 0; y < category["sub_categories"].length; y++) {
        let sub = category["sub_categories"][y];
        let resolved_sub = {
          name: sub["name"],
          id: sub["id"],
          category: category["name"],
          searchable: category["name"] + " : " + sub["name"],
        };
        original_signatures.push(resolved_sub);

        for (let z = 0; z < sub["signatures"].length; z++) {
          let sig = sub["signatures"][z];
          original_signatures.push({
            name: sig["name"],
            id: sig["id"],
            category: category["name"] + ": " + sub["name"],
            searchable: category["name"] + " : " + sub["name"] + " : " + sig["name"],
            category_obj: resolved_category,
            sub_category_obj: resolved_sub,
          });
        }
      }
    }

    let unprocessed_websites = ObjectStore.getWebsiteObjects();
    let tmp_categories = [];

    if (!this.props.hide_website_objects) {
      for (let i = 0; i < unprocessed_websites.length; i++) {
        let category = unprocessed_websites[i];
        tmp_categories.push({ name: category["name"], id: category["id"] });
      }
    }

    this.setState({
      signatures: original_signatures,
      categories: tmp_categories,
    });
  };

  render_Selected = () => {
    let ret = [];
    for (let signatureIndex in this.state.selected_signatures) {
      let signature = this.state.selected_signatures[signatureIndex];
      ret.push(
        <SelectedSignature
          key={"sig_" + signatureIndex}
          handle_Remove_Signature={this.handle_Remove_Signature}
          signature={signature}
        ></SelectedSignature>
      );
    }

    for (let objectIndex in this.state.selected_website_objects) {
      let website_object = this.state.selected_website_objects[objectIndex];
      ret.push(
        <SelectedObject
          key={"obj_" + objectIndex}
          handle_Remove_Object={this.handle_Remove_Object}
          website_object={website_object}
        ></SelectedObject>
      );
    }

    for (let websiteIndex in this.state.selected_websites) {
      let website = this.state.selected_websites[websiteIndex];
      ret.push(
        <div key={"web_" + websiteIndex} className="signature_item">
          <a className="signature_item_remove selected-item-remove" onClick={() => this.handle_Remove_Website(website)}>
            <i className="fa fa-times" aria-hidden="true" />
          </a>
          Website
          <span className="signature_item_conditions"> {website}</span>
        </div>
      );
    }

    return ret;
  };

  handle_SearchChange = (event) => {
    /* Deal with the possibility that this entry will be a website ? */
    this.setState({
      search: event.target.value,
      website: event.target.value,
      dropdown_open: true,
    });

    $(document).on("click", this.handle_ExternalClick);
  };

  handle_CloseDropDown = () => {
    $(document).off("click", this.handle_ExternalClick);

    this.setState({
      search: "",
      website: "",
      websiteInvalid: false,
      dropdown_open: false,
    });

    return true;
  };

  handle_BubbleChanges = () => {
    let obj = {
      signatures: this.state.selected_signatures,
      objects: this.state.selected_website_objects,
      websites: this.state.selected_websites,
    };
    this.props.handle_BubbleChanges(obj);
  };

  handle_Add_Signature = (signature) => {
    this.state.selected_signatures.push(signature.id);
    this.handle_BubbleChanges();
    this.handle_CloseDropDown();
  };

  handle_Add_Object = (website_object) => {
    this.state.selected_website_objects.push(website_object.id);
    this.handle_BubbleChanges();
    this.handle_CloseDropDown();
  };

  handle_Add_Website = () => {
    if (this.state.selected_websites.includes(this.state.website)) {
      /* Don't add the same website twice */
      return;
    }

    if (isValidWebsite(this.state.website)) {
      this.state.selected_websites.push(this.state.website);
      this.setState({ websiteInvalid: false });
      this.handle_BubbleChanges();
      this.handle_CloseDropDown();
    } else {
      this.setState({ websiteInvalid: true });
      this.textInput.current.focus();
    }
  };

  handle_ExternalClick = (event) => {
    if (this.state.dropdown_open) {
      if ($("#" + this.props.id).find(event.target).length === 0) {
        this.handle_CloseDropDown();
      }
    }
  };

  handle_Remove_Signature = (signature) => {
    let selected_signatures = [];
    $.each(this.state.selected_signatures, function (i, elem) {
      if (elem !== signature) {
        selected_signatures.push(elem);
      }
    });

    this.state.selected_signatures = selected_signatures;
    this.handle_BubbleChanges();
  };

  handle_Remove_Object = (obj) => {
    let selected_website_objects = [];
    $.each(this.state.selected_website_objects, function (i, elem) {
      if (elem !== obj) {
        selected_website_objects.push(elem);
      }
    });

    this.state.selected_website_objects = selected_website_objects;
    this.handle_BubbleChanges();
  };

  handle_Remove_Website = (website) => {
    let new_criteria = [];
    $.each(this.state.selected_websites, function (i, elem) {
      if (elem !== website) {
        new_criteria.push(elem);
      }
    });

    this.state.selected_websites = new_criteria;
    this.handle_BubbleChanges();
  };

  check_Signature_Allowed = (signature) => {
    if (this.props.allowed_signatures) {
      if (this.props.allowed_signatures.includes(signature.id)) {
        return true;
      }
      return false;
    }
    return true;
  };

  render_AvailableOptionsMenu_Signatures = () => {
    let ret = [];
    for (let signatureIndex in this.state.signatures) {
      let signature = this.state.signatures[signatureIndex];

      /* Don't render selected signatures in menu */
      if (this.state.selected_signatures && this.state.selected_signatures.includes(signature.id)) {
        continue;
      }

      if (this.check_Signature_Allowed(signature)) {
        if (signature.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || this.state.search.length === 0) {
          ret.push(
            <div className="" key={signature.name + signatureIndex}>
              <div
                className="signature_drop_down_element signature_drop_down_element__clickable"
                onClick={() => this.handle_Add_Signature(signature)}
              >
                <div className="category__name">{signature.name}</div>
                <div className="category__category">{signature.category}</div>
              </div>
            </div>
          );
        }
      }
    }

    return ret;
  };

  render_AvailableOptionsMenu_Objects = () => {
    let ret = [];
    for (let categoryIndex in this.state.categories) {
      let category = this.state.categories[categoryIndex];

      /* Don't render selected objects in menu */
      if (this.state.selected_website_objects && this.state.selected_website_objects.includes(category.id)) {
        continue;
      }

      ret.push(
        <div className="" key={category.name + categoryIndex}>
          <div
            className="signature_drop_down_element signature_drop_down_element__clickable"
            onClick={() => this.handle_Add_Object(category)}
          >
            <div className="category__name">
              <span>{category.name}</span>
            </div>
            <div className="category__category">Manually created category</div>
          </div>
        </div>
      );
    }

    return ret;
  };

  render_AvailableOptionsMenu_Website = () => {
    if (this.state.website.length) {
      return (
        <div>
          <div
            id="website_input"
            className="signature_drop_down_element signature_drop_down_element__clickable"
            onClick={() => this.handle_Add_Website()}
          >
            <div className="category__name">
              Website <span>{this.state.website}</span>
            </div>
            <div className="category__category">Individual website/domain</div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  render_DropDownMenu = () => {
    if (this.state.dropdown_open) {
      return (
        <div className="signature_drop_down">
          {this.render_AvailableOptionsMenu_Website()}
          {this.render_AvailableOptionsMenu_Signatures()}
          {this.render_AvailableOptionsMenu_Objects()}
        </div>
      );
    }
    return <div />;
  };

  render_AvailableOptionsMenu = () => {
    return (
      <div tabIndex="0" className="burable" id={this.props.id}>
        <div>
          {this.state.websiteInvalid ? this.renderValidationError() : null}
          <div className="mui-textfield mui-textfield-no-marginbottom">
            <input
              placeholder={this.props.placeholder ? this.props.placeholder : ""}
              autoComplete="off"
              ref={this.textInput}
              type="text"
              value={this.state.search}
              onChange={this.handle_SearchChange}
              onFocus={this.handle_SearchChange}
            />
          </div>
        </div>

        {this.render_DropDownMenu()}
      </div>
    );
  };

  renderValidationError() {
    return <div className="errorMessage">The website URL you have entered is invalid</div>;
  }

  render() {
    return (
      <div>
        {this.render_Selected()}
        {this.render_AvailableOptionsMenu()}

        {this.props.children}
      </div>
    );
  }
}
