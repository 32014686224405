import { Box, Table, Tbody, Modal, Text, Td, Tr, useTranslation, TextInput, Button, Flex, useToast } from "@familyzone/component-library";
import React from "react";
import { useEffect, useState } from "react";
import { isValidIPAddress } from "../../utils/Validation";
import WebsitePoolCreation from "./WebsitePoolCreation";

interface CreateObjectPoolProps {
  existingUrls: string[];
  open: boolean;
  savePool: (name: string, description: string, urls: string[]) => void;
  onClose: () => void;
  policyName: string;
}

interface options {
  label: string;
  value: string;
}

const CreateObjectPool: React.FC<CreateObjectPoolProps> = ({ existingUrls, open, onClose, savePool, policyName }) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const headerText = t("Create Object Pool");
  const createPolicyText = t("Create Object Pool");

  const [name, setName] = useState(policyName);
  const [description, setDescription] = useState("");
  const [urls, setUrls] = useState<string[]>(existingUrls);
  const [loading, setLoading] = useState(false);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleChangeWebsiteObjects = (selected: options[]) => {
    if (selected.filter((option) => isValidIPAddress(option.value)).length > 0) {
      errorToast({ title: "IP addresses are not allowed", isClosable: true });
      return;
    }
    setUrls(selected.map((option) => option.value));
  };

  useEffect(() => {
    setLoading(true);
    setUrls(existingUrls.filter((url) => !isValidIPAddress(url)));
    setName(policyName + " - Website Object List");
    setLoading(false);
  }, [existingUrls, policyName]);

  const handleClickSubmit = () => {
    setLoading(true);
    if (name.length < 3) {
      errorToast({ title: "Name must be at least 3 characters", isClosable: true });
      setLoading(false);
      return;
    }
    if (urls.length < 1) {
      errorToast({ title: "Please add at least one URL", isClosable: true });
      setLoading(false);
      return;
    }
    savePool(name, description, urls);
  };

  return (
    <Modal
      isOpen={open}
      onClose={!loading ? onClose : () => ""}
      size="md"
      headerText={headerText}
      contentProps={{ style: { overflow: "visible" } }}
    >
      <Box my="sp24" overflowY="auto" py="sp4" overflow={"auto"}>
        <Table style={{ tableLayout: "fixed" }}>
          <Tbody>
            <Tr>
              <Td width="120px">
                <Text fontSize="medium" color="text.title">
                  {t("Name")}{" "}
                  <Text color="accent.red.400" display="inline">
                    *
                  </Text>
                </Text>
              </Td>
              <Td>
                <TextInput
                  name={name}
                  placeholder={t("Enter the name of the object pool")}
                  value={name}
                  onChange={handleChangeName}
                  isDisabled={loading}
                  aria-label={"Name"}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t("Description")}
                </Text>
              </Td>
              <Td>
                <TextInput
                  name={description}
                  placeholder={t("Enter a description")}
                  value={description}
                  onChange={handleChangeDescription}
                  isDisabled={loading}
                  aria-label={"Description"}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t("Value")}{" "}
                  <Text color="accent.red.400" display="inline">
                    *
                  </Text>
                </Text>
              </Td>
              <Td>
                <WebsitePoolCreation websiteObjects={urls} onChange={handleChangeWebsiteObjects} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Flex mt="sp16">
          <Button variant="primary" onClick={handleClickSubmit} isLoading={loading} aria-label="save">
            {createPolicyText}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default CreateObjectPool;
