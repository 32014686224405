import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import ModalWindow from "./ModalWindow";

class DeleteModal extends React.Component {
  handleHide = () => {
    this.props.handleClose();
  };

  handle_Delete = () => {
    this.props.onDelete();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--danger" onClick={this.handle_Delete}>
        Delete
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow
          title="Confirm Delete?"
          handleHide={this.handleHide}
          actions={this.renderActions()}
          defaultEnterAction={this.handle_Delete}
        >
          Are you sure you wish to delete this object
        </ModalWindow>
      );
    }

    return [];
  }
}

export default class DeleteButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      delete_confirm: false,
    };
  }

  onConfirm = () => {
    this.setState({
      delete_confirm: true,
    });
  };

  onDelete = () => {
    if (this.props.to !== undefined) {
      this.context.router.push(this.props.to);
    }
    this.setState({ delete_confirm: false });
    this.props.onClick();
  };

  onCancel = () => {
    this.setState({
      delete_confirm: false,
    });
  };

  render() {
    return (
      <Link onClick={this.onConfirm}>
        <div>
          <DeleteModal visible={this.state.delete_confirm} onDelete={this.onDelete} handleClose={this.onCancel} />
        </div>
        {this.props.isDeleting ? <i className="fa fa-refresh fa-spin fa-fw" /> : <i className="fa fa-trash" />}
      </Link>
    );
  }
}

DeleteButton.contextTypes = {
  router: PropTypes.object.isRequired,
};
