import React from "react";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import SessionStore from "../../stores/SessionStore";

import ConfigStore from "../../stores/ConfigStore";
import ConfigActions from "../../actions/ConfigActions";
import DeviceStatusActions from "../../actions/DeviceStatusActions";
import MiniLog from "./MiniLog";

import "../../../css/ManageDeviceUpdates.css";

import { branches } from "../DeviceCreationConst";

export default class ManageDeviceUpdates extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      update_allow_auto: false,
      update_branch: "",
      update_start_time: "",
      update_end_time: "",
      update_arch: "",
      current_version: "",
      newest_version: "",
      update_status: undefined,
      restart_status: 0,
      updateLog: "",
    };
    this.polling = false;
    this.checking_status = false;
    this.checkVersionTimeout = null;
    this.checkStatusTimeout = null;
  }

  componentDidMount() {
    let self = this;
    DeviceStatusStore.listen(this.onChange);
    SessionStore.listen(this.onChange);
    ConfigStore.listen(this.configChange);
    setTimeout(() => {
      ConfigActions.fetch();
    }, 0);
    // self.polling = true;
    self.checkStatus();
    self.checkVersion();
  }

  checkVersion = () => {
    this.checkVersionTimeout = null;
    let self = this;
    if (!self.checking_version) {
      self.checking_version = true;
      Api.get_with_timeout(
        "/managedevice/ajax/device/update/version",
        function (result) {
          self.setState({ current_version: result.version });
          self.checking_version = false;
          if (self.polling) {
            self.checkVersionTimeout = setTimeout(self.checkVersion, 1000);
          }
        },
        function (error) {
          self.checking_version = false;
          self.checkVersionTimeout = setTimeout(self.checkVersion, 5000);
        },
        10000
      );
    }
  };

  checkStatus = () => {
    this.checkStatusTimeout = null;
    let self = this;
    if (!self.checking_status) {
      self.checking_status = true;
      Api.get_with_timeout(
        "/managedevice/ajax/device/update/status",
        function (result) {
          self.checking_status = false;
          if (!self.restarting && self.state.restart_status === 1) {
            self.setState({ restart_status: 0 });
          }
          if (!self.updating && result.status !== self.state.update_status) {
            self.setState({ update_status: result.status });
            self.polling = false;
            self.checkVersion();
            DeviceStatusActions.fetch();
          }
          if (result.log.length !== self.state.updateLog.length) {
            self.setState({ updateLog: result.log });
          }
          if (self.polling || result.status === 1) {
            self.checkStatusTimeout = setTimeout(self.checkStatus, 1000);
          }
        },
        function (error) {
          self.checking_status = false;
          self.setState({ restart_status: 1 });
          self.checkStatusTimeout = setTimeout(self.checkStatus, 5000);
        },
        10000
      );
    }
  };

  componentWillUnmount() {
    this.polling = false;
    DeviceStatusStore.unlisten(this.onChange);
    SessionStore.unlisten(this.onChange);
    if (this.checkStatusTimeout) {
      clearTimeout(this.checkStatusTimeout);
    }
    if (this.checkVersionTimeout) {
      clearTimeout(this.checkVersionTimeout);
    }
  }

  onChange = () => {
    this.setState({
      devices: SessionStore.getAvailableDevices(),
      // current_version: DeviceStatusStore.getVersion() ? DeviceStatusStore.getVersion() : "",
      newest_version: DeviceStatusStore.getNewestVersion() ? DeviceStatusStore.getNewestVersion() : "",
    });
  };

  configChange = () => {
    let self = this;
    self.setState({
      update_allow_auto: ConfigStore.getItemInConfig("update_allow_auto"),
      update_branch: ConfigStore.getItemInConfig("update_branch"),
      update_start_time: ConfigStore.getItemInConfig("update_start_time"),
      update_end_time: ConfigStore.getItemInConfig("update_end_time"),
      update_arch: ConfigStore.getItemInConfig("update_arch"),
    });
  };

  handle_Submit = () => {
    let settings = {
      update_allow_auto: this.state.update_allow_auto,
      update_branch: this.state.update_branch,
      update_start_time: this.state.update_start_time,
      update_end_time: this.state.update_end_time,
      update_arch: this.state.update_arch,
    };
    let self = this;
    self.setState({
      saving: true,
      changes: false,
    });
    Api.post(
      "/managedevice/ajax/device",
      settings,
      function (e) {
        ConfigActions.fetch();
        self.setState({ saving: false });
      },
      function (error) {}
    );
  };

  handle_ChangeUpdateEnable = (event) => {
    this.setState({
      update_allow_auto: event.target.checked,
      changes: true,
    });
  };

  handle_ChangeUpdateBranch = (event) => {
    this.setState({
      update_branch: event.value,
      changes: true,
    });
  };

  handle_ChangeArch = (event) => {
    this.setState({
      update_arch: event.value,
      changes: true,
    });
  };

  handle_ChangeUpdateStartTime = (event) => {
    this.setState({
      update_start_time: event.value,
      changes: true,
    });
  };

  handle_ChangeUpdateEndTime = (event) => {
    this.setState({
      update_end_time: event.value,
      changes: true,
    });
  };

  handle_triggerUpdate = (event) => {
    const self = this;
    if (self.state.update_status !== 1) {
      self.setState({ update_status: 1 });
      self.polling = true;
      self.updating = true;
      Api.get(
        "/managedevice/ajax/device/update/start",
        function (e) {
          self.updating = false;
          self.checkStatus();
        },
        function (error) {}
      );
    }
  };

  handle_triggerRestart = (event) => {
    let self = this;
    if (self.state.restart_status !== 1) {
      self.setState({ restart_status: 1 });
      self.polling = true;
      self.restarting = true;
      Api.get(
        "/managedevice/ajax/device/update/restart",
        function (e) {
          self.restarting = false;
          self.checkStatus();
        },
        function (error) {}
      );
    }
  };

  render() {
    let self = this;
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Device Updates</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Automatic</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.update_allow_auto} onChange={this.handle_ChangeUpdateEnable} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Branch</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          value={this.state.update_branch}
                          onChange={this.handle_ChangeUpdateBranch}
                          clearable={false}
                          valueDataMap={branches}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Architecture</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-select">
                        <SearchableSelect
                          value={this.state.update_arch}
                          onChange={this.handle_ChangeArch}
                          clearable={false}
                          valueDataMap={[
                            ["386", "386"],
                            ["amd64", "amd64"],
                          ]}
                        ></SearchableSelect>
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Window</div>
                    <div className="formgroup-element-fields">
                      <div className="formgroup-element-fields-inline">
                        <div className="mui-select">
                          <SearchableSelect
                            value={this.state.update_start_time}
                            onChange={this.handle_ChangeUpdateStartTime}
                            clearable={false}
                            valueDataMap={[
                              ["19:00", "19:00"],
                              ["20:00", "20:00"],
                              ["21:00", "21:00"],
                              ["22:00", "22:00"],
                              ["23:00", "23:00"],
                              ["01:00", "01:00"],
                              ["02:00", "02:00"],
                              ["03:00", "03:00"],
                              ["04:00", "04:00"],
                              ["05:00", "05:00"],
                              ["06:00", "06:00"],
                              ["07:00", "07:00"],
                              ["08:00", "08:00"],
                              ["09:00", "09:00"],
                            ]}
                          ></SearchableSelect>
                        </div>
                        <div className="mui-select">
                          <SearchableSelect
                            value={this.state.update_end_time}
                            onChange={this.handle_ChangeUpdateEndTime}
                            clearable={false}
                            valueDataMap={[
                              ["19:00", "19:00"],
                              ["20:00", "20:00"],
                              ["21:00", "21:00"],
                              ["22:00", "22:00"],
                              ["23:00", "23:00"],
                              ["01:00", "01:00"],
                              ["02:00", "02:00"],
                              ["03:00", "03:00"],
                              ["04:00", "04:00"],
                              ["05:00", "05:00"],
                              ["06:00", "06:00"],
                              ["07:00", "07:00"],
                              ["08:00", "08:00"],
                              ["09:00", "09:00"],
                            ]}
                          ></SearchableSelect>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Active Version</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.current_version} disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Manual</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Latest Version</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.newest_version} disabled={true} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <button type="submit" className="mui-btn mui-btn--raised deviceUpdateButton" onClick={this.handle_triggerUpdate}>
                      {self.state.update_status === 1 ? (
                        <span>
                          Updating <i className="fa-solid fa-spinner-third fa-spin" aria-hidden="true" />
                        </span>
                      ) : (
                        <span>Update</span>
                      )}
                    </button>
                    {self.state.update_status === 2 || self.state.restart_status === 1 ? (
                      <button
                        type="submit"
                        className="mui-btn mui-btn--raised mui-btn--danger deviceRestartButton"
                        onClick={this.handle_triggerRestart}
                      >
                        {self.state.restart_status === 1 ? (
                          <span>
                            Restarting <i className="fa-solid fa-spinner-third fa-spin" aria-hidden="true" />
                          </span>
                        ) : (
                          <span>Restart</span>
                        )}
                      </button>
                    ) : (
                      <div />
                    )}
                  </div>
                  {self.state.update_status && self.state.update_status !== 0 ? (
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">Update Log</div>
                      <MiniLog className={"updateLog"} text={self.state.updateLog} />
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton saving={this.state.saving} changes={this.state.changes} onClick={this.handle_Submit} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
