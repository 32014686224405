import React from "react";

import { TimePeriod } from "../../pages/filtering/types";
import { FilteringSourceCriteria, IpObject, Fingerprint, MacObject, SecurityGroup } from "./criteriaTypes";

import { MultiGroupSearchSelector } from "../../components/GroupSearch/MultiGroupSearchSelector";
import IPv4RangeCriteria from "./inputs/IPv4RangeCriteria";
import IPv4Criteria from "./inputs/IPv4Criteria";
import IPv4AddressCriteria from "./inputs/IPv4AddressCriteria";
import IPv4AliasCriteria from "./inputs/IPv4AliasCriteria";
import MacCriteria from "./inputs/MacCriteria";
import FingerprintCriteria from "./inputs/FingerprintCriteria";
import MacPoolCriteria from "./inputs/MacPoolCriteria";
import PeriodCriteria from "./inputs/PeriodCriteria";
import HTTPPathCriteria from "./inputs/HTTPPathCriteria";
import ContentTypeCriteria from "./inputs/ContentTypeCriteria";
import UserAgentCriteria from "./inputs/UserAgentCriteria";
import SecurityGroupCriteria from "./inputs/SecurityGroupCriteria";
import { MultiUserSearchSelector } from "../../components/UserSearch/MultiUserSearchSelector";
import GenericMultiStringSelectInputCriteria from "./inputs/GenericMultiStringSelectInputCriteria";
import { portOptions } from "./inputs/portOptions";
import GenericTwoStringInputCriteria from "./inputs/GenericTwoStringInputCriteria";
import GenericSingleSelectCriteria from "./inputs/GenericSingleSelectCriteria";
import { countryOptions } from "./inputs/countryOptions";
import InterfaceStore from "../../stores/InterfaceStore";
import { NetworkInterface } from "../../stores/interface";
import { Item } from "@familyzone/component-library/dist/components/Select";
import GenericSingleStringInput from "./inputs/GenericSingleStringInputCriteria";
import { getWebsiteObjects } from "./inputs/websiteObjectOptions";
import SignatureCriteria from "../../criteria/SignatureCriteria";
import { protocolOptions } from "./inputs/protocolOptions";

export interface CriteriaItemProps {
  criteria: FilteringSourceCriteria;
  ipObjects?: IpObject[];
  fingerprints?: Fingerprint[];
  macObjects?: MacObject[];
  timePeriods?: TimePeriod[];
  securityGroups?: SecurityGroup[];
  disabled?: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
  onDelete?: () => void;
}

const CriteriaItem: React.FC<CriteriaItemProps> = ({
  criteria,
  ipObjects = [],
  fingerprints = [],
  macObjects = [],
  timePeriods = [],
  securityGroups = [],
  disabled = false,
  onChange = () => "",
  onDelete = () => "",
}) => {
  switch (criteria.type) {
    case "application.http.hostname":
      return (
        <GenericMultiStringSelectInputCriteria
          criteria={criteria}
          disabled={disabled}
          onChange={onChange}
          namedValue="websiteObjects"
          options={getWebsiteObjects()}
        />
      );
    case "application.http.hostname.regex":
      return <GenericSingleStringInput criteria={criteria} disabled={disabled} readableName={"Website"} onChange={onChange} />;
    case "group":
      return <MultiGroupSearchSelector criteria={criteria} disabled={disabled} onChange={onChange} useLegacyId={true} />;
    case "exclude.group":
      return <MultiGroupSearchSelector criteria={criteria} disabled={disabled} onChange={onChange} useLegacyId={true} />;
    case "source.user":
      return <MultiUserSearchSelector criteria={criteria} onChange={onChange} />;
    case "ipv4.range":
      return <IPv4RangeCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "ipv4":
      return <IPv4Criteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "ipv4.address":
      return <IPv4AddressCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "ipv4.alias":
      return <IPv4AliasCriteria criteria={criteria} disabled={disabled} onChange={onChange} ipObjects={ipObjects} />;
    case "source.mac":
      return <MacCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "fingerprint":
      return <FingerprintCriteria criteria={criteria} disabled={disabled} onChange={onChange} fingerprints={fingerprints} />;
    case "source.mac.pool":
      return <MacPoolCriteria criteria={criteria} disabled={disabled} onChange={onChange} macObjects={macObjects} />;
    case "timeperiod":
      return <PeriodCriteria criteria={criteria} disabled={disabled} onChange={onChange} timePeriods={timePeriods} />;
    case "application.http.request.regex":
      return <HTTPPathCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "application.http.contenttype.regex":
      return <ContentTypeCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "application.http.useragent.regex":
      return <UserAgentCriteria criteria={criteria} disabled={disabled} onChange={onChange} />;
    case "securitygroup":
      return <SecurityGroupCriteria criteria={criteria} disabled={disabled} onChange={onChange} securityGroups={securityGroups} />;
    case "transport.port":
      return (
        <GenericMultiStringSelectInputCriteria
          criteria={criteria}
          disabled={disabled}
          onChange={onChange}
          namedValue="port"
          options={portOptions()}
        />
      );
    case "transport.portrange":
      return (
        <GenericTwoStringInputCriteria
          criteria={criteria}
          onChange={onChange}
          rangeToUse={[
            { startPort: 0, readableName: "Start Port" },
            { endPort: 0, readableName: "End Port" },
          ]}
        />
      );
    case "geoip":
      return (
        <GenericSingleSelectCriteria
          criteria={criteria}
          options={countryOptions}
          namedValue="country"
          readableName="Country"
          onChange={onChange}
        />
      );
    case "device":
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const interfaces: NetworkInterface[] = InterfaceStore.getInterfaces(); // This could be replaced by an API call, eg: Api.get("/config/device/ajax/network/devices"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const interfaceItems: Item[] = interfaces.map((iface) => ({ text: iface.interface, value: iface.interface }));
      return (
        <GenericSingleSelectCriteria
          criteria={criteria}
          options={interfaceItems}
          namedValue="name"
          readableName="Interface"
          onChange={onChange}
        />
      );
    case "protocol":
      return (
        <GenericSingleSelectCriteria
          criteria={criteria}
          options={protocolOptions}
          namedValue="protocol"
          readableName="Protocol"
          onChange={onChange}
        />
      );
    case "signature":
      // This is the old criteria selection component.
      return <SignatureCriteria criteria={criteria} bubbleMethod={onChange} handle_RemoveCriteria={onDelete} />;
    default:
      return <></>;
  }
};

export default CriteriaItem;
