import React, { ReactChild } from "react";
import PageWithHeader from "./PageWithHeader";
import SortSearchTable, { SortSearchTableProps, SortType, TableData } from "./SortSearchTable";

export interface TableBasedPageProps<Data> extends SortSearchTableProps<Data> {
  title?: string | React.ReactNode;
  breadcrumbs?: {
    title: string;
    isActive: boolean;
  }[];
  children?: ReactChild;
  disableInfiniteScroll?: boolean;
  onSort?: (sortColumn: string, sortDirection: SortType) => void;
}

const TableBasedPage = <Data extends TableData<Data>>({
  title,
  breadcrumbs,
  children = <></>,
  loaded,
  columns,
  data,
  tableDataMap,
  showSearch = true,
  tableTopMargin = "sp24",
  childrenInTableHeader = <></>,
  overflowAutoToAllowDropdownSelectToShow = false,
  searchInput,
  onSort,
  defaultSort,
  disableInfiniteScroll = false,
}: TableBasedPageProps<Data>): JSX.Element => {
  return (
    <PageWithHeader title={title} breadcrumbs={breadcrumbs}>
      <>
        {children}
        <SortSearchTable
          showSearch={showSearch}
          tableTopMargin={tableTopMargin}
          childrenInTableHeader={childrenInTableHeader}
          overflowAutoToAllowDropdownSelectToShow={overflowAutoToAllowDropdownSelectToShow}
          loaded={loaded}
          columns={columns}
          data={data}
          tableDataMap={tableDataMap}
          searchInput={searchInput}
          onSort={onSort}
          defaultSort={defaultSort}
          disableInfiniteScroll={disableInfiniteScroll}
        />
      </>
    </PageWithHeader>
  );
};

export default TableBasedPage;
