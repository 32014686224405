import React from "react";
import "../../../css/Associations.css";

export class ImportStatus extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      cancelJob: this.props.cancelJob,
    };
  }

  render() {
    if (this.props.count > 0) {
      return (
        <div className={"importingJob"}>
          <div className={"importingJobContainer"}>
            <div className={"importingJobStatus"}>
              <div className={"importingCount"}>{this.props.count}</div>
              <div className={"importingText"}>Items remaining</div>
            </div>
            <div className={"importingCancel"}>
              <button className="mui-btn mui-btn--raised mui-btn--danger" onClick={this.state.cancelJob}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}
