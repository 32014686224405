import React, { FunctionComponent, useState } from "react";
import { Box, Checkbox, Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";

interface Props {
  heading: string;
  description: string;
  /** If a string is given, a confirmation checkbox will be rendered */
  confirmationCheckbox?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  CTALabel: string;
}

const ConfirmationModal: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const [confirmCheckbox, setConfirmCheckbox] = useState<boolean>(false);

  const handleConfirm = (): void => {
    setConfirmCheckbox(false);
    props.onConfirm();
  };

  const handleClose = (): void => {
    setConfirmCheckbox(false);
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.open}
      onClose={handleClose}
      variant="warning"
      size="sm"
      headerText={props.heading}
      primaryCTALabel={props.CTALabel}
      primaryCTADisabled={!!props.confirmationCheckbox && !confirmCheckbox}
      onPrimaryCTAClick={handleConfirm}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={handleClose}
    >
      <ModalBody>
        <Text mb="sp24"></Text>
        <Text mb="sp24">{props.description}</Text>
        <>
          {props.confirmationCheckbox !== "" && (
            <Box mb="sp24">
              <Checkbox
                isChecked={confirmCheckbox}
                data-testid="confirmation-checkbox"
                onChange={() => setConfirmCheckbox(!confirmCheckbox)}
              >
                {props.confirmationCheckbox}
              </Checkbox>
            </Box>
          )}
        </>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
