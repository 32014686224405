import { ClusivityRadioOption } from ".";
import { ClusivityConfig, ClusivityType, EfrontCourse } from "../../../utils/api/CoursesConfig";

export function sortCoursesAlphabeticallyByTitle(courses: EfrontCourse[]): EfrontCourse[] {
  return courses.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
}

export function mapClusivityToRadioOption(clusivity: ClusivityConfig): ClusivityRadioOption {
  const users = clusivity.users || [];
  const groups = clusivity.groups || [];

  const hasUsersOrGroups = users.length > 0 || groups.length > 0;

  if (clusivity.type === ClusivityType.All) {
    return ClusivityRadioOption.All;
  }

  if (clusivity.type === ClusivityType.Exclusive && hasUsersOrGroups) {
    return ClusivityRadioOption.Exclusive;
  }

  if (clusivity.type === ClusivityType.Exclusive && !hasUsersOrGroups) {
    return ClusivityRadioOption.All;
  }

  if (clusivity.type === ClusivityType.Inclusive && !hasUsersOrGroups) {
    return ClusivityRadioOption.None;
  }

  if (clusivity.type === ClusivityType.Inclusive && hasUsersOrGroups) {
    return ClusivityRadioOption.Inclusive;
  }

  // Fallback to showing for all teachers
  return ClusivityRadioOption.All;
}
