import React from "react";
import Criteria from "./Criteria";

export default class Port extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      editing_state: props.criteria.conditions.join(","),
    };

    /*{type: "transport.port", negate: false, conditions: [21,23]}}*/
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
      editing_state: nextprops.criteria.conditions.join(","),
    });
  }

  handle_PortChange = (event) => {
    this.setState({
      editing_state: event.target.value,
    });
  };

  bubble_Changes = (criteria) => {
    let string_ports = this.state.editing_state.split(",");
    let new_ports = [];
    for (let x of string_ports) {
      console.log("checking " + x);
      if (isNaN(x)) {
        continue;
      }

      if (x === "") {
        continue;
      }

      new_ports.push(parseInt(x));
    }

    this.state.criteria.conditions = new_ports;
    this.props.bubbleMethod(criteria);
  };

  render() {
    return (
      <Criteria label="Port" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-textfield">
          <input
            type="text"
            onChange={this.handle_PortChange}
            value={this.state.editing_state}
            onBlur={this.bubble_Changes}
            placeholder="Port"
          />
        </div>
      </Criteria>
    );
  }
}
