import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import FlexibleTable from "../../modules/FlexibleTable";
import AmountField from "../../modules/AmountField";
import SignatureStore from "../../stores/SignatureStore";
import SignatureActions from "../../actions/SignatureActions";
import QueryFilter from "../../modules/QueryFilter";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import Filterhelper from "../../utils/Filterhelper";
import { getUsersByUsername } from "../../utils/api/Users";
import { fullNameOrUsername } from "../UserSearch/UserSearchHelper";

export default class ReportingTopTypeUsers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      series: undefined,
      interval_multiplier: 24,
      table_data_loaded: false,
      filters: Filterhelper.operate(this),
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    SignatureStore.listen(this.onChange);

    setTimeout(SignatureActions.fetch, 0);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
  }

  componentWillUnmount() {
    this.unsubFilterStore();
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.handle_load();
  };

  handle_load = () => {
    this.setState({ data: [] });

    Api.get_analytics(
      "/surfwize/analytics/traffic/types/" + this.props.params.type + "/users/by/day?" + Filterhelper.serialise(this.state.filters),
      (result) => {
        this.prepare_table(result["data"]);
      },
      function (err) {
        console.log(err);
      }
    );
  };

  prepare_table = (data_set) => {
    const userMap = new Map();
    const usersToFetch = [];
    let user_data = {};
    let table_data = { data: [] };
    for (let idx in data_set) {
      let username = data_set[idx]["user"];
      // If the key of username is not in the userMap, add it to the map and the list of users to fetch.
      if (!userMap.has(username)) {
        userMap.set(username, undefined);
        usersToFetch.push(username);
      }
    }
    getUsersByUsername(usersToFetch).then((users) => {
      for (const user of users) {
        userMap.set(user.username, user);
      }
      for (let idx in data_set) {
        let username = data_set[idx]["user"];

        const user = userMap.get(username);
        // If the user has some kind of name, set it, otherwise use the username
        const userTextToShow = user ? fullNameOrUsername(user) : username;
        user_data[username] = {
          user: username,
          name: userTextToShow,
          hits: data_set[idx]["hits"],
          download: data_set[idx]["download"],
          upload: data_set[idx]["upload"],
          transfer: data_set[idx]["transfer"],
        };
      }
      for (let user in user_data) {
        table_data["data"].push(user_data[user]);
      }
      this.setState({
        data: table_data["data"],
        table_data_loaded: true,
      });
    });
  };

  prepare_columns = () => {
    let self = this;
    let columns = [
      {
        title: "User",
        data: function (row) {
          return (
            <span>
              <Link
                to={
                  "/surfwize/reporting/type/" +
                  self.props.params.type +
                  "/user/" +
                  row["user"] +
                  "?" +
                  Filterhelper.serialise(self.state.filters)
                }
              >
                {row["user"]}
              </Link>
            </span>
          );
        },
        search: FlexibleTable.search__single_string_field("user"),
        sortable: FlexibleTable.sortable__single_string_field("user"),
        onclick: undefined,
        export: function (row) {
          return row["user"];
        },
      },
      {
        title: "Name",
        data: function (row) {
          let name = row["name"] ? row["name"] : "-";
          return (
            <span>
              <Link
                to={
                  "/surfwize/reporting/type/" +
                  self.props.params.type +
                  "/user/" +
                  row["user"] +
                  "?" +
                  Filterhelper.serialise(self.state.filters)
                }
              >
                {name}
              </Link>
            </span>
          );
        },
        search: FlexibleTable.search__single_string_field("name"),
        sortable: FlexibleTable.sortable__single_string_field("name"),
        onclick: undefined,
      },
    ];

    if (self.state.filters.hits) {
      columns.push({
        title: "Hits",
        data: function (row) {
          return row["hits"];
        },
        sortable: FlexibleTable.sortable__single_numeric_field("hits"),
        onclick: undefined,
        export: function (row) {
          return row["hits"];
        },
      });
    } else {
      columns.push(
        {
          title: "Upload",
          data: function (row) {
            return <AmountField amount={row["upload"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("upload"),
          onclick: undefined,
          export: function (row) {
            return row["upload"];
          },
        },
        {
          title: "Download",
          data: function (row) {
            return <AmountField amount={row["download"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("download"),
          onclick: undefined,
          export: function (row) {
            return row["download"];
          },
        },
        {
          title: "Transfer",
          data: function (row) {
            return <AmountField amount={row["upload"] + row["download"]} />;
          },
          sortable: FlexibleTable.sortable__multi_int_field("upload", "download"),
          onclick: undefined,
          export: function (row) {
            return row["upload"] + row["download"];
          },
        }
      );
    }
    return columns;
  };

  render__content = () => {
    let self = this;
    return (
      <div>
        <div className="main-content-sub-element">
          <FlexibleTable
            columns={this.prepare_columns()}
            data={this.state.data}
            sort_column={self.state.filters.hits ? 2 : 4}
            sort_asc={false}
            loaded={this.state.table_data_loaded}
          />
        </div>
      </div>
    );
  };

  render_title = () => {
    let name = "";
    if (SignatureStore.getSignature(this.props.params.type)) {
      name = SignatureStore.getSignature(this.props.params.type)["name"];
    }
    return <span>{name} Users</span>;
  };

  render_TopRight = () => {
    return (
      <div>
        <QueryFilter filters={this.state.filters} />
      </div>
    );
  };

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-rocket" title={this.render_title()} buttons={this.render_TopRight()}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
