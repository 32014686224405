import alt from "../alt";

class CountryActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(devices) {
    return devices;
  }
}

export default alt.createActions(CountryActions);
