import { FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { Button, Icon, Input, Modal, Radio, RadioGroup, TableIconButton, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria, Fingerprint, IpObject, MacObject, SecurityGroup } from "../../modules/criteria/criteriaTypes";
import { getObjects, getFingerprints, getTimePeriods, getSecurityGroups } from "../../pages/filtering/ApiHelpers";
import { TimePeriod } from "../../pages/filtering/types";

type Rule = {
  action: number;
  comment: string;
  destination_criteria: FilteringSourceCriteria[];
  enabled: boolean;
  id: string;
  log: boolean;
  source_criteria: FilteringSourceCriteria[];
};

interface IFirewallRuleModalProps {
  handleSubmit: (rule?: Rule) => void;
  existingRule: Rule;
}

const FirewallRuleModal: React.FC<IFirewallRuleModalProps> = ({ handleSubmit, existingRule }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  const [fingerprints, setFingerprints] = React.useState<Fingerprint[]>([]);
  const [ipObjects, setIpObjects] = React.useState<IpObject[]>([]);
  const [macObjects, setMacObjects] = React.useState<MacObject[]>([]);
  const [timePeriods, setTimePeriods] = React.useState<TimePeriod[]>([]);
  const [securityGroups, setSecurityGroups] = React.useState<SecurityGroup[]>([]);
  const [rule, setRule] = React.useState<Rule>(existingRule);

  const { errorToast } = useToast();

  React.useCallback(async () => {
    try {
      const data = await Promise.all([getObjects(), getFingerprints(), getTimePeriods(), getSecurityGroups()]);
      setIpObjects(data[0].filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0));
      setFingerprints(data[1]);
      setMacObjects(data[0].filter((obj): obj is MacObject => obj.type === 4));
      setTimePeriods(data[2]);
      setSecurityGroups(data[3]);
    } catch (err) {
      errorToast({ title: "Failed to load. Please refresh the page", isClosable: true });
    }
  }, [errorToast]);

  const sourceOptions: Partial<CriteriaTypes> = {
    device: "Interface",
    geoip: "Country",
    fingerprint: "Device Type",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "ipv4.range": "Network Range",
    protocol: "Protocol",
    securitygroup: "Security Group",
    "source.user": "User",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    timeperiod: "Time Periods",
  };

  const destinationOptions: Partial<CriteriaTypes> = {
    device: "Interface",
    geoip: "Country",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "ipv4.range": "Network Range",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
  };

  return (
    <>
      <TableIconButton onClick={() => setIsOpen(!isOpen)} aria-label="Edit" icon={<Icon boxSize="12px" icon="fa fa-pencil" />} />
      <Modal
        onClose={() => setIsOpen(false)}
        size="md"
        isOpen={isOpen}
        headerText={t("Edit Rule")}
        contentProps={{ style: { overflowX: "hidden", overflowY: "scroll" } }}
      >
        <Stack spacing="sp12" mt="sp8">
          <FormControl>
            <FormLabel>Rule Name</FormLabel>
            <Input defaultValue={existingRule.comment} onChange={(e) => setRule({ ...rule, comment: e.target.value })} />
          </FormControl>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <CriteriaSelector
              customOptions={sourceOptions}
              selected={rule.source_criteria}
              errors={[]}
              fingerprints={fingerprints}
              ipObjects={ipObjects}
              macObjects={macObjects}
              timePeriods={timePeriods}
              securityGroups={securityGroups}
              onChange={(selected) => setRule({ ...rule, source_criteria: selected })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Destination</FormLabel>
            <CriteriaSelector
              customOptions={destinationOptions}
              selected={rule.destination_criteria}
              errors={[]}
              ipObjects={ipObjects}
              macObjects={macObjects}
              timePeriods={timePeriods}
              securityGroups={securityGroups}
              onChange={(selected) => setRule({ ...rule, destination_criteria: selected })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Action</FormLabel>
            <RadioGroup
              direction="row"
              onChange={(value) => setRule({ ...rule, action: Number(value) })}
              defaultValue={String(existingRule.action)}
            >
              <Radio value="0">DENY</Radio>
              <Radio value="1">ACCEPT</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Button variant="primary" onClick={() => handleSubmit(rule)}>
              Save Rule
            </Button>
          </FormControl>
        </Stack>
      </Modal>
    </>
  );
};

export default FirewallRuleModal;
