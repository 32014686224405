import React, { useEffect, useState } from "react";
import { timeInputAutoInsertColon, timeInputAutoInsertLeadingZero } from "../utils/DateTimeUtil";

const BACKSPACE_KEY_CODE = 8;

const TimeRangeSelector = (props) => {
  const [startTime, setStartTime] = useState(props.startTime);
  const [endTime, setEndTime] = useState(props.endTime);
  const [lastPressedKey, setLastPressedKey] = useState(BACKSPACE_KEY_CODE);

  useEffect(() => {
    setStartTime(props.startTime);
  }, [props.startTime]);
  useEffect(() => {
    setEndTime(props.endTime);
  }, [props.endTime]);

  const handleChangeStartTime = (event) => {
    const inputStr = cleanseInput(event.target.value);
    setStartTime(inputStr);
    props.updateCallback(inputStr, endTime);
  };

  const handleChangeEndTime = (event) => {
    const inputStr = cleanseInput(event.target.value);
    setEndTime(inputStr);
    props.updateCallback(startTime, inputStr);
  };

  const cleanseInput = (inputStr) => {
    inputStr = inputStr.trim();
    if (lastPressedKey !== BACKSPACE_KEY_CODE) {
      inputStr = timeInputAutoInsertColon(inputStr);
      inputStr = timeInputAutoInsertLeadingZero(inputStr);
    }
    return inputStr;
  };

  const handleInputKeyDown = (event) => {
    setLastPressedKey(event.keyCode);
  };

  return (
    <div className="formgroup-element-fields-inline">
      <div className="mui-textfield">
        <input type="text" value={startTime} onChange={handleChangeStartTime} onKeyDown={handleInputKeyDown} placeholder="08:00" />
      </div>
      <div className="inline-block">to</div>
      <div className="mui-textfield">
        <input type="text" value={endTime} onChange={handleChangeEndTime} onKeyDown={handleInputKeyDown} placeholder="18:00" />
      </div>
    </div>
  );
};

export default TimeRangeSelector;
