import React, { useEffect } from "react";
import ModalWindowDeleteNetwork from "./ModalWindowDeleteNetwork";
import { Select, TableButton, Box, Icon, Button, Flex, Td, Tr, useTranslation } from "@familyzone/component-library";
import TableBasedPage from "../templates/TableBasedPage";
import { useState } from "react";
import PropTypes from "prop-types";
import { useToast } from "@familyzone/component-library";
import styled from "@emotion/styled";
import {
  removeDuplicatesByObjectId,
  saveCurrentObjectListAsync,
  fetchSubnetGroupsForSelectDropdownAsync,
  newRow,
  fetchTableDataAsync,
  removeEmptyObjectIds,
  CloudSafeNetworkRowUI,
  SubnetGroup,
  SubnetGroupOptionUI,
} from "./CloudSafeHelper";

const CloudSafe = (): JSX.Element => {
  const { successToast } = useToast();
  const { t } = useTranslation();

  const groupNotAvailableText = t("(Group not available)");

  const breadcrumbData = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t("Cloud Safe Network"), url: "", isActive: true },
  ];

  const [tableRows, setTableRows] = useState(new Array<CloudSafeNetworkRowUI>());
  const [subnetGroupOptions, setSubnetGroupOptions] = useState<SubnetGroup[]>([]);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [rowSelectedForDelete, setRowSelectedForDelete] = useState<CloudSafeNetworkRowUI>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    void (async () => {
      const subnetGroups = await fetchSubnetGroupsForSelectDropdownAsync();
      setSubnetGroupOptions(subnetGroups);
      const rows = await fetchTableDataAsync(subnetGroups, setPageLoaded, groupNotAvailableText);
      setTableRows(rows);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataWithoutDuplicates = (data: Array<CloudSafeNetworkRowUI>) => {
    setTableRows(removeDuplicatesByObjectId(data));
  };

  const clickDeleteButton = (row: CloudSafeNetworkRowUI) => {
    setRowSelectedForDelete(row);
    setShowDeleteModal(true);
  };

  const cancelEditingBySetAllToNonEditState = () => {
    setDataWithoutDuplicates(
      tableRows.map((o) => {
        return { ...o, inEditState: false };
      })
    );
  };

  const oneItemIsInEditState = () => {
    return (
      null !=
      tableRows.find((obj: CloudSafeNetworkRowUI) => {
        if (obj.inEditState) return obj;
        else return null;
      })
    );
  };

  const successMessage = () => {
    successToast({
      title: t("Update successful"),
      description: t("Network entry has been successfully updated"),
      isClosable: true,
    });
  };

  const clickSaveButton = () => {
    cancelEditingBySetAllToNonEditState();
    void (async () => {
      const duplicatesRemoved = removeDuplicatesByObjectId(tableRows);
      const noEmpty = removeEmptyObjectIds(duplicatesRemoved);
      await saveCurrentObjectListAsync(noEmpty);
      const rows = await fetchTableDataAsync(subnetGroupOptions, setPageLoaded, groupNotAvailableText);

      setTableRows((previousRows: Array<CloudSafeNetworkRowUI>) => {
        return previousRows.map((previousRow: CloudSafeNetworkRowUI) => {
          const newRow = rows.find((row: CloudSafeNetworkRowUI) => {
            return row.objectId === previousRow.objectId;
          });
          return { ...previousRow, ...newRow, inEditState: previousRow.inEditState };
        });
      });

      successMessage();
    })();
  };

  const clickEditButton = (row: CloudSafeNetworkRowUI) => {
    if (oneItemIsInEditState()) return;

    setDataWithoutDuplicates(
      tableRows.map((obj: CloudSafeNetworkRowUI) => {
        return { ...obj, inEditState: obj === row };
      })
    );
  };

  const clickAddNetworkButton = () => {
    if (oneItemIsInEditState()) return;

    const newData = [{ ...newRow(t("")), inEditState: true }, ...tableRows];
    setDataWithoutDuplicates(newData);
  };

  const subnetGroupOptionsForUI = (): Array<SubnetGroupOptionUI> => {
    return subnetGroupOptions.map((subnetGroup) => {
      return { value: subnetGroup.text, objectId: subnetGroup.objectId };
    });
  };

  const closeDropdownSubnetGroup = (row: CloudSafeNetworkRowUI, newValue: SubnetGroupOptionUI | null) => {
    const newData = tableRows.map(function (obj: CloudSafeNetworkRowUI) {
      if (obj.id === row.id) {
        obj.objectId = newValue == null ? "" : newValue.objectId || "";
        obj.text = newValue == null ? "" : newValue.value.toString() || "";
      }
      return obj;
    });

    setTableRows(newData);
  };

  const clickCancelOnDeleteNetworkModalWindow = () => {
    setShowDeleteModal(false);
  };

  const clickDeleteOnDeleteNetworkModalWindow = () => {
    if (rowSelectedForDelete == null) return;

    setShowDeleteModal(false);
    const newDataWithDuplicates: Array<CloudSafeNetworkRowUI> = tableRows.filter((item) => {
      return item.objectId !== rowSelectedForDelete.objectId;
    });
    const newData = removeDuplicatesByObjectId(newDataWithDuplicates);
    setTableRows(newData);

    void (async () => {
      await saveCurrentObjectListAsync(newData);
      successMessage();
    })();
  };

  const columns = [
    {
      headerText: t("Subnet Group(s)"),
      columnName: "subnetGroups",
      sortable: false,
      searchable: false,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
      searchable: false,
      sortable: false,
    },
  ];

  const tableDataMap = (row: CloudSafeNetworkRowUI, index: number) => {
    const blueButtonStyle = `
  background-color: #0075DB;
  color: #ffffff;
  :hover {
    background-color: #0075DB;
    color: #ffffff;
  }
`;
    const BlueButton = styled(TableButton)(blueButtonStyle);

    return (
      <Tr key={index}>
        <Td>
          {row == null ? (
            <Box>{groupNotAvailableText}</Box>
          ) : !row.inEditState ? (
            <Box>{row.text}</Box>
          ) : (
            <Select items={subnetGroupOptionsForUI()} placeholder={row.text} onChange={(e) => closeDropdownSubnetGroup(row, e)} />
          )}
        </Td>

        <Td w="200px" justifyContent="right">
          <Flex>
            <Box>
              {row.inEditState ? (
                <BlueButton
                  onClick={() => clickSaveButton()}
                  leftIcon={<Icon icon="fa-save" variant="solid" color="white" data-testid="save-icon" />}
                  mr="sp8"
                >
                  {t("Save")}
                </BlueButton>
              ) : (
                <TableButton
                  onClick={() => clickEditButton(row)}
                  leftIcon={<Icon icon="fa-gear" variant="solid" color="text.paragraph.light" data-testid="edit-icon" />}
                  mr="sp8"
                >
                  {t("Edit")}
                </TableButton>
              )}
              <TableButton
                onClick={() => clickDeleteButton(row)}
                leftIcon={<Icon icon="fa-trash" variant="solid" color="text.paragraph.light" data-testid="delete-icon" />}
              >
                {t("Delete")}
              </TableButton>
            </Box>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <>
      {showDeleteModal ? (
        <ModalWindowDeleteNetwork handleOK={clickDeleteOnDeleteNetworkModalWindow} handleOnClose={clickCancelOnDeleteNetworkModalWindow} />
      ) : (
        <></>
      )}
      <TableBasedPage<CloudSafeNetworkRowUI>
        overflowAutoToAllowDropdownSelectToShow={true}
        title={t("Cloud Safe Network")}
        breadcrumbs={breadcrumbData}
        columns={columns}
        data={tableRows}
        tableDataMap={tableDataMap}
        loaded={pageLoaded}
        showSearch={false}
        childrenInTableHeader={
          <Flex direction="row">
            <Flex flex="1" />
            <Button aria-label={t("Add Network")} onClick={clickAddNetworkButton} variant="primary">
              <i className="fa fa-plus" style={{ paddingRight: "10px" }} />
              {t("Add Network")}
            </Button>
          </Flex>
        }
      />
    </>
  );
};

CloudSafe.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default CloudSafe;
