import React, { FunctionComponent } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Icon, Tooltip } from "@familyzone/component-library";

interface Props {
  title: string;
  tooltip?: string;
}

export const SectionHeading: FunctionComponent<Props> = (props) => {
  return (
    <Flex alignItems={"center"} data-testid="section-heading" borderRadius="sm" p="sp12" backgroundColor="neutrals.20">
      <Text fontFamily="Poppins" fontSize="sm" fontWeight="medium" color="text.paragraph.regular" role="heading">
        {props.title}
      </Text>
      {props.tooltip && (
        <Box ml={10}>
          <Tooltip label={props.tooltip} variant="dark">
            <Flex>
              <Icon
                bg="neutrals.100"
                borderRadius="round"
                p={"sp2"}
                color="neutrals.0"
                height={"12px"}
                width={"12px"}
                variant={"solid"}
                icon={"fa-question"}
                data-testid={"section-heading-help-icon"}
              />
            </Flex>
          </Tooltip>
        </Box>
      )}
    </Flex>
  );
};
