import React from "react";
import SignatureTreeStore from "../../stores/SignatureTreeStore";
import "../../../css/ReportingWelfare.css";
import PropTypes from "prop-types";
import { AdultContentUrl, OffensiveContentUrl, SearchUrl, SuicideUrl, VPNUrl } from "../navigation/RedFlagUrls";

export default class RedFlags extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      userRiskSelectorScore: {},
    };
  }

  findColorClassName = (user, indicatorKey) => {
    let score = this.props.userRiskSelectorScore[user][indicatorKey];
    if (score > 5) {
      return "riskStudentIndicator-high";
    } else if (score > 2) {
      return "riskStudentIndicator-medium";
    }
    return "riskStudentIndicator-low";
  };

  renderRiskyBusiness = (row) => {
    let ret = [];
    let normals = row["normals"];
    let keywords = SignatureTreeStore.getKeywords();
    for (let indicatorKey in normals) {
      if (indicatorKey === "adult_content_hit") {
        if (row[indicatorKey] > 0) {
          let colorClassname = this.findColorClassName(row["name"], indicatorKey);
          ret.push(
            <div
              key={indicatorKey}
              onClick={() => this.handle_OpenAdultContentRedFlag(row["name"])}
              className={"indicatorSelectable  " + this.props.indicatorClassname + " " + colorClassname}
            >
              <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img className={"keywordSearch_" + colorClassname} src={"/static/images/Icon_Web.svg"} />
                Adult Content
              </div>
            </div>
          );
        }
      }
      if (indicatorKey === "offensive_hit") {
        if (row[indicatorKey] > 0) {
          ret.push(
            <div
              key={indicatorKey}
              onClick={() => this.handle_OpenOffensiveContentRedFlag(row["name"])}
              className={"indicatorSelectable " + this.props.indicatorClassname + " " + this.findColorClassName(row["name"], indicatorKey)}
            >
              <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>Offensive Content</div>
            </div>
          );
        }
      }
      if (indicatorKey === "vpn_hit") {
        if (row[indicatorKey] > 0) {
          let colorClassname = this.findColorClassName(row["name"], indicatorKey);
          ret.push(
            <div
              key={indicatorKey}
              onClick={() => this.handle_OpenVPNRedFlag(row["name"])}
              className={"indicatorSelectable " + this.props.indicatorClassname + " " + colorClassname}
            >
              <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img className={"keywordSearch_" + colorClassname} src={"/static/images/Icon_Web.svg"} />
                VPN
              </div>
            </div>
          );
        }
      }
      if (indicatorKey === "suicidal_search_term") {
        if (row[indicatorKey] > 0) {
          ret.push(
            <div
              key={indicatorKey}
              onClick={() => this.handle_OpenSuicideRedFlag(row["name"])}
              className={"indicatorSelectable  " + this.props.indicatorClassname + " " + this.findColorClassName(row["name"], indicatorKey)}
            >
              <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>Suicidal Search</div>
            </div>
          );
        }
      }
      if (indicatorKey === "devices") {
        if (row[indicatorKey] && row[indicatorKey].length > 5) {
          let colorClassname = this.findColorClassName(row["name"], indicatorKey);
          ret.push(
            <div key={indicatorKey} className={"smallRiskStudentIndicator " + this.props.indicatorClassname + " " + colorClassname}>
              <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img className={"keywordSearch_" + colorClassname} src={"/static/images/Icon_SettingCog.svg"} />
                {row[indicatorKey].length} Devices
              </div>
            </div>
          );
        }
      }
      for (let keyword of keywords) {
        if (indicatorKey === keyword.id) {
          if (row["search_indicators"][indicatorKey] > 0) {
            let colorClassname = this.findColorClassName(row["name"], indicatorKey);
            ret.push(
              <div
                key={indicatorKey}
                onClick={() => this.handle_OpenSearchRedFlag(row["name"], keyword.name)}
                className={"indicatorSelectable " + this.props.indicatorClassname + " " + colorClassname}
              >
                <div className={"riskStudentIndicatorText newUiRiskIndicatorButton"}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img className={"keywordSearch_" + colorClassname} src={"/static/images/Icon_Search.svg"} />
                  {keyword.name}
                </div>
              </div>
            );
          }
        }
      }
    }
    return ret;
  };

  handle_OpenAdultContentRedFlag(userId) {
    this.context.router.push(AdultContentUrl(userId));
  }

  handle_OpenOffensiveContentRedFlag(userId) {
    this.context.router.push(OffensiveContentUrl(userId));
  }

  handle_OpenVPNRedFlag(userId) {
    this.context.router.push(VPNUrl(userId));
  }

  handle_OpenSuicideRedFlag(userId) {
    this.context.router.push(SuicideUrl(userId));
  }

  handle_OpenSearchRedFlag(userId, searchId) {
    this.context.router.push(SearchUrl(userId, searchId));
  }

  render() {
    return <div className={this.props.divClassName}>{this.renderRiskyBusiness(this.props.data)}</div>;
  }
}

RedFlags.contextTypes = {
  router: PropTypes.object.isRequired,
};
