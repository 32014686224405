import { Box, Button, Flex, Icon, TableIconButton, Td, Text, Tr, useToast, useTranslation } from "@familyzone/component-library";

import React, { useCallback, useEffect, useState } from "react";
import TableBasedPage from "../../../components/templates/TableBasedPage";
import { DeleteButtonNew } from "../../../modules/DeleteButtonNew";
import { TableColumn } from "../../../types/table";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import { createReport, deleteReport, editReport, EmailReport, getReports } from "../ApiHelpers";

import { AddEditEmailReportModal } from "../../../components/filtering/AddEditEmailReportModal";
import { useGroupDescriptionStore } from "../../../storez/GroupDescriptionStore";
import { fetchDescMapFromStore } from "../../../components/GroupSearch/GroupSearchHelper";

export type SearchableEmailReport = EmailReport & {
  typeSearch: string;
  filterSearch: string;
};

export const breadcrumbsText = ["Filtering", "Reports and Alerts"];
export const titleText = "Reports and Alerts";
export const columnsText = ["Recipient Email", "Filter", "Report Type", "Operation"];

export const deleteRuleErrorText = "There was an error deleting the Report. Please refresh the page.";
export const addRuleErrorText = "There was an error adding a Report. Please refresh the page.";
export const editRuleErrorText = "There was an error editing the Report. Please refresh the page";
export const addText = "Add Reporter";

export const emptyReport = {
  daily_filtering_alerts_digest: false,
  deviceid: "",
  disable_rate_limiting: false,
  email: "",
  filtering_alerts: false,
  groups: [],
  recipient_id: 987654321,
  red_flags: false,
  selected_red_flags: [],
  teacher_added_user: false,
  user_downvotes: false,
  username: "",
  weekly_report: false,
  weekly_welfare_report: false,
  typeSearch: "",
  filterSearch: "",
};

const ReportsAndAlerts: React.FC = () => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [rules, setRules] = useState<SearchableEmailReport[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingReport, setEditingReport] = useState<SearchableEmailReport>(emptyReport);

  const [groupDescriptions, setGroupDescriptions] = useState<Map<string, string>>(new Map());

  const { errorToast } = useToast();
  const isMounted = useIsMounted();
  const breadcrumbs = [
    { title: t(breadcrumbsText[0]), url: "/filtering", isActive: false },
    { title: t(breadcrumbsText[1]), isActive: true },
  ];

  const columns: TableColumn[] = [
    { headerText: t(columnsText[0]), columnName: "email", sortable: true, searchable: true },
    { headerText: t(columnsText[1]), columnName: "filterSearch", sortable: true, searchable: true },
    { headerText: t(columnsText[2]), columnName: "typeSearch", sortable: true, searchable: true },
    { headerText: t(columnsText[3]), columnName: "operations", sortable: false, searchable: false },
  ];

  const handleDeleteReport = async (row: EmailReport) => {
    try {
      row.state_deleting = true;
      setLoaded(false);
      if (!isMounted()) return;
      await deleteReport(row.recipient_id);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: t(deleteRuleErrorText),
      });
    } finally {
      await populateReportsAndAlerts();
      setLoaded(true);
    }
  };

  const handleEditReport = (row: SearchableEmailReport) => {
    setEditingReport(row);
    setModalOpen(true);
  };

  const handleEditReportSubmit = async (report: SearchableEmailReport) => {
    try {
      if (!report) return;
      setLoaded(false);
      if (!isMounted) return;
      await editReport(report);
      setModalOpen(false);
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: t(editRuleErrorText),
      });
    } finally {
      await populateReportsAndAlerts();
      setLoaded(true);
    }
  };

  const onClickCreateRule = async () => {
    try {
      setLoaded(false);
      if (!isMounted()) return;
      await createReport();
    } catch (err) {
      if (!isMounted()) return;
      console.error(err);
      errorToast({
        title: t(addRuleErrorText),
      });
    } finally {
      await populateReportsAndAlerts();
      setLoaded(true);
    }
  };

  const handleOnClose = () => {
    setModalOpen(false);
  };

  const populateReportsAndAlerts = useCallback(async () => {
    try {
      const reports = await getReports();
      setGroupDescriptions(await getGroupDescriptions(reports));
      setRules(
        reports.map((report) => ({
          ...report,
          typeSearch: `${report.weekly_report ? "Weekly Report" : ""} ${report.weekly_welfare_report ? "Weekly Welfare Report" : ""} ${
            report.daily_filtering_alerts_digest ? "Daily Alert Digest" : ""
          } ${report.filtering_alerts ? "Filtering Alerts" : ""} ${report.red_flags ? "Red Flags" : ""} ${
            report.teacher_added_user ? "Teacher Modified Class Users" : ""
          }`,
          filterSearch: `${report.groups.reduce((a, b) => a + " " + b, "")} ${report.username}`,
        }))
      );
      setLoaded(true);
    } catch (err) {
      errorToast({
        title: t("Error loading report configuration. Please try again later."),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void populateReportsAndAlerts();
  }, [populateReportsAndAlerts]);

  const getGroups = useGroupDescriptionStore((state) => state.getGroups);

  const getGroupDescriptions = (reports: EmailReport[]): Promise<Map<string, string>> => {
    return fetchDescMapFromStore(
      reports.flatMap((report: EmailReport) => report.groups),
      getGroups
    );
  };

  const dataMap = (report: SearchableEmailReport, index: number) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{report.email}</Text>
      </Td>
      <Td>
        <Flex>
          {
            <Box>
              {report.groups?.length > 0 ? (
                report.groups.map((group, index) => (
                  <Text key={index} fontSize="md">
                    {groupDescriptions.get(group) ?? group}
                  </Text>
                ))
              ) : (
                <Text key={index} fontSize="md">
                  {report.username}
                </Text>
              )}
            </Box>
          }
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="space-between" flexDirection="column">
          {report.filtering_alerts && (
            <Text
              fontSize="md"
              color={report.disable_rate_limiting ? "red" : ""}
              title={report.disable_rate_limiting ? "Rate Limiting Disabled" : ""}
            >
              Filtering Alerts
            </Text>
          )}
          {report.red_flags && (
            <Text
              fontSize="md"
              color={report.disable_rate_limiting ? "red" : ""}
              title={report.disable_rate_limiting ? "Rate Limiting Disabled" : ""}
            >
              Red Flags
            </Text>
          )}
          {report.teacher_added_user && (
            <Text
              fontSize="md"
              color={report.disable_rate_limiting ? "red" : ""}
              title={report.disable_rate_limiting ? "Rate Limiting Disabled" : ""}
            >
              Teacher Modified Class Users
            </Text>
          )}
          {report.weekly_report && <Text fontSize="md">Weekly Report</Text>}
          {report.weekly_welfare_report && <Text fontSize="md">Weekly Wellbeing Report</Text>}
          {report.daily_filtering_alerts_digest && <Text fontSize="md">Daily Alert Digest</Text>}
        </Flex>
      </Td>

      <Td>
        <Flex justifyContent="end">
          <Box mr="sp8">
            <TableIconButton
              data-testid={`${report.recipient_id}-edit`}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              aria-label="Edit"
              onClick={() => handleEditReport(report)}
              disabled={!loaded}
            />
          </Box>
          <DeleteButtonNew
            data-testid={`${report.recipient_id}-delete`}
            disabled={!loaded}
            titleToDelete="Reporter"
            onClick={() => void handleDeleteReport(report)}
          />
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <>
      <TableBasedPage
        breadcrumbs={breadcrumbs}
        title={titleText}
        columns={columns}
        data={rules}
        tableDataMap={dataMap}
        loaded={loaded}
        childrenInTableHeader={
          <Flex justifyContent="space-between" alignItems="center">
            <Button
              variant="primary"
              size="sm"
              leftIcon={<Icon icon="fa-plus" boxSize="18px" />}
              onClick={() => void onClickCreateRule()}
              disabled={!loaded}
            >
              {t(addText)}
            </Button>
          </Flex>
        }
      />
      <AddEditEmailReportModal
        visible={modalOpen}
        rule={editingReport}
        handleClose={() => handleOnClose()}
        handleSave={(report: SearchableEmailReport) => void handleEditReportSubmit(report)}
      />
    </>
  );
};

export default ReportsAndAlerts;
