import React from "react";
import $ from "jquery";
import Api from "../../utils/Api";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import PropTypes from "prop-types";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import Filterhelper from "../../utils/Filterhelper";
import { withTranslation } from "@familyzone/component-library";
import VideoDashboardNew from "./VideoDashboardNew";

class VideoDashboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      videos: [],
      filters: Filterhelper.operate(this),
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    setTimeout(() => {
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);

    this.handle_load();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onChange = () => {
    this.handle_load();
  };

  handle_load = () => {
    this.setState({
      loaded: false,
    });

    let params = {};
    if (this.state.filters.filter_user) {
      params["user"] = this.state.filters.filter_user;
    }

    Api.post_analytics(
      "/surfwize/ajax/videos",
      params,
      (result) => {
        const ret = [];
        $.each(result["result"], (i, elem) => {
          if (elem["title"] !== "") {
            ret.push(elem);
          }
        });

        this.setState({
          videos: ret,
          loaded: true,
        });
      },
      (err) => {
        console.log(err);
        this.setState({ loaded: true });
      }
    );
  };

  render = () => {
    return <VideoDashboardNew data={this.state.videos} loaded={this.state.loaded} filters={Filterhelper.serialise(this.state.filters)} />;
  };
}

VideoDashboard.contextTypes = {
  router: PropTypes.object.isRequired,
};
export default withTranslation()(VideoDashboard);
