import React from "react";
import { Link } from "react-router";

import PropTypes from "prop-types";
import ModalWindow from "../../modules/ModalWindow";
import ListSelector from "../../modules/ListSelector";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";
import DynamicInputList from "../../modules/DynamicInputList";
import NuMultiSelect from "../../utils/NuMultiSelect";
import { get } from "shades";

class IpAddress extends React.Component {
  handle_Delete = () => {
    this.props.handle_RemoveIp(this.props.ip, this.props.mask);
  };

  render() {
    return (
      <div className="interfaces-ip-address">
        <span>
          {this.props.ip}/{this.props.mask}
        </span>
        <Link className="interfaces-ip-address-remove" onClick={this.handle_Delete}>
          Remove
        </Link>
      </div>
    );
  }
}

class AddIpAddressModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ip: "",
      mask: "",
      visible: false,
    };
  }

  handleHide = () => {
    this.setState({
      visible: false,
      ip: "",
      mask: "",
    });
    this.props.handleClose();
  };

  handle_ChangeIp = (event) => {
    this.setState({ ip: event.target.value });
  };
  handle_ChangeMask = (event) => {
    this.setState({ mask: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd({
      ip: this.state.ip,
      mask: this.state.mask,
    });

    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add IP Address" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">IP Address</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.ip} onChange={this.handle_ChangeIp} />
            </div>
            <div className="formgroup-element-title">Subnet Mask</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.mask} onChange={this.handle_ChangeMask} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

class PasswordAsTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  render() {
    return (
      <input
        type={this.props.type}
        className="pw-text-security"
        ref={this.inputRef}
        value={this.props.value}
        onChange={(event) => {
          const style = getComputedStyle(this.inputRef.current);
          this.props.onChange(event, style.webkitTextSecurity);
        }}
      ></input>
    );
  }
}

export default class Interface extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      device: {
        int: "",
        name: "",
        ipv4_configured: [],
        bridgeDevices: [],
        lacpDevices: [],
      },
      devices: [],
      loaded: false,
      add_address_visible: false,
      saving: false,
      pppoe_password_input_type: "text",
    };

    this.bridgeDisplayTextLens = get("interface");
    this.bridgeValueLens = get("interface");
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    Api.get("/config/device/ajax/network/devices/" + this.props.params.interface, function (result) {
      let device = result["data"];
      self.setState({
        device: device,
        loaded: true,
      });
    });

    Api.get("/config/device/ajax/network/devices", function (result) {
      let devices = result["data"];
      self.setState({
        devices: devices,
      });
    });
  };

  handle_Submit = () => {
    let self = this;
    let device_configuration = {
      interface: this.state.device.interface,
      persisted: this.state.device.persisted,
      name: this.state.device.name,
      ipv4_configured: this.state.device.ipv4_configured,
      wan: this.state.device.wan,
      wan_uplink_primary: this.state.device.wan_uplink_primary,
      gateway: this.state.device.gateway,
      mac: this.state.device.mac,
      dhcp: this.state.device.dhcp,
      bridgeDevices: this.state.device.bridgeDevices,

      lacpDevices: this.state.device.lacpDevices,
      lacpMode: this.state.device.lacpMode,
      lacpLinkStatePollFrequency: this.state.device.lacpLinkStatePollFrequency,
      lacpLinkStateDownDelay: this.state.device.lacpLinkStateDownDelay,
      lacpLinkStateUpDelay: this.state.device.lacpLinkStateUpDelay,

      full_duplex: this.state.device.full_duplex,
      autoneg: this.state.device.autoneg,
      speed: this.state.device.speed,

      dhcpServerMode: this.state.device.dhcpServerMode,
      dhcpServerStart: this.state.device.dhcpServerStart,
      dhcpServerEnd: this.state.device.dhcpServerEnd,
      dhcpServerRelay: this.state.device.dhcpServerRelay,
      dhcpServerDnsMode: this.state.device.dhcpServerDnsMode,
      dhcpServerDnsServer: this.state.device.dhcpServerDnsServer,

      pppoe_enabled: this.state.device.pppoe_enabled,
      pppoe_username: this.state.device.pppoe_username,
      pppoe_password: this.state.device.pppoe_password,

      upstream_enabled: this.state.device.upstream_enabled,
      upstream_proxy_uri: this.state.device.upstream_proxy_uri,
      upstream_enabled_ports: this.state.device.upstream_enabled_ports,
      upstream_enabled_destination_exceptions: this.state.device.upstream_enabled_destination_exceptions,
      upstream_enabled_source_exceptions: this.state.device.upstream_enabled_source_exceptions,
    };

    self.setState({ saving: true });
    Api.post(
      "/config/device/ajax/network/devices/" + this.props.params.interface,
      device_configuration,
      function (e) {
        self.setState({ false: true });
        self.context.router.push("/config/device/network/devices");
      },
      function (error) {}
    );
  };

  handle_ChangePersisted = (event) => {
    let device = this.state.device;
    device.persisted = event.target.checked;
    this.setState({
      device: device,
    });
  };

  handle_ChangeName = (event) => {
    let device = this.state.device;
    device.name = event.target.value;
    this.setState({
      device: device,
    });
  };

  handle_ChangeWan = (event) => {
    let device = this.state.device;
    device.wan = event.target.checked;
    this.setState({
      device: device,
    });
  };

  handle_ChangeWanPrimary = (event) => {
    let device = this.state.device;
    device.wan_uplink_primary = event.target.checked;
    this.setState({
      device: device,
    });
  };

  handle_ChangeDhcp = (event) => {
    let device = this.state.device;
    device.dhcp = event.target.checked;
    this.setState({
      device: device,
    });
  };

  /* Ip Addressing */
  handle_ChangeAddIp = (event) => {
    this.setState({
      add__ip: event.target.value,
    });
  };

  handle_ChangeAddMask = (event) => {
    this.setState({
      add__mask: event.target.value,
    });
  };

  handle_ChangeGateway = (event) => {
    let device = this.state.device;
    device.gateway = event.target.value;
    this.setState({
      device: device,
    });
  };

  handle_ChangeMacAddress = (event) => {
    let device = this.state.device;
    device.mac = event.target.value;
    this.setState({
      device: device,
    });
  };

  handle_AddIp = (info) => {
    let device = this.state.device;
    device.ipv4_configured.push({ ip: info.ip, mask: info.mask });
    this.setState({
      device: device,
    });
  };

  handle_AddIpClose = () => {
    this.setState({ add_address_visible: false });
  };

  handle_AddIpOpen = () => {
    this.setState({ add_address_visible: true });
  };

  handle_RemoveIp = (ip, mask) => {
    let device = this.state.device;
    let ret = [];
    for (let existing_ip of device.ipv4_configured) {
      if (existing_ip.ip === ip && existing_ip.mask === mask) {
        continue;
      }

      ret.push(existing_ip);
    }

    device.ipv4_configured = ret;
    this.setState({
      device: device,
    });
  };

  render_IpAddresses = () => {
    let ret = [];
    for (let ip of this.state.device.ipv4_configured) {
      ret.push(<IpAddress ip={ip.ip} mask={ip.mask} handle_RemoveIp={this.handle_RemoveIp} />);
    }

    return ret;
  };

  render_ManualAddressingConfiguration = () => {
    if (this.state.device.dhcp === false) {
      return [
        <div className="formgroup-element">
          <div className="formgroup-element-title">IP Addresses</div>
          <div className="formgroup-element-fields">
            <div className="mui-textfield">
              {this.render_IpAddresses()}
              <Link onClick={this.handle_AddIpOpen}>Add Address</Link>
            </div>
          </div>
        </div>,
        <div className="formgroup-element">
          <div className="formgroup-element-title">Gateway</div>
          <div className="formgroup-element-fields">
            <div className="mui-textfield">
              <input type="text" value={this.state.device.gateway} onChange={this.handle_ChangeGateway} />
            </div>
          </div>
        </div>,
      ];
    }

    return <div />;
  };

  addBridge = (item) => {
    const itemValue = this.bridgeValueLens(item);
    const device = this.state.device;

    device.bridgeDevices.push(itemValue);
    this.setState({ device: device });
  };

  removeBridge = (itemValue) => {
    const device = this.state.device;

    const removalIndex = device.bridgeDevices.findIndex((element) => element === itemValue);
    device.bridgeDevices.splice(removalIndex, 1);

    this.setState({ device: device });
  };

  render_BridgeConfiguration = () => {
    if (this.state.device.bridge === true) {
      return (
        <div className="formgroup">
          <div className="formgroup_title">
            <div className="formgroup_title_title">Bridge Members</div>
          </div>
          <div className="formgroup-content">
            <NuMultiSelect
              allItems={this.state.devices}
              selectedValues={this.state.device.bridgeDevices}
              displayTextLens={this.bridgeDisplayTextLens}
              valueLens={this.bridgeValueLens}
              searchPlaceholder="Select..."
              selectedPlaceHolder="No Bridge Members Selected"
              onAdd={this.addBridge}
              onRemove={this.removeBridge}
            />
          </div>
        </div>
      );
    }

    return <div />;
  };

  addLacpDevice = (i) => {
    let device = this.state.device;
    device.lacpDevices.push(i);
    this.setState({ device: device });
  };

  removeLacpDevice = (i) => {
    let device = this.state.device;
    let ret = [];
    for (let x of device.lacpDevices) {
      if (x === i) {
        continue;
      }

      ret.push(x);
    }

    device.lacpDevices = ret;
    this.setState({ device: device });
  };

  handle_ChangeLacpPollFrequency = (event) => {
    let device = this.state.device;
    device.lacpLinkStatePollFrequency = parseInt(event.target.value);
    this.setState({ device: device });
  };
  handle_ChangeLacpDownDelay = (event) => {
    let device = this.state.device;
    device.lacpLinkStateDownDelay = parseInt(event.target.value);
    this.setState({ device: device });
  };

  handle_ChangeLacpUpDelay = (event) => {
    let device = this.state.device;
    device.lacpLinkStateUpDelay = parseInt(event.target.value);
    this.setState({ device: device });
  };

  handle_ChangeLacpMode = (event) => {
    let device = this.state.device;
    device.lacpMode = event.value;
    this.setState({ device: device });
  };

  render_LacpConfiguration = () => {
    let available = [];
    for (let i of this.state.devices) {
      available.push(i.interface);
    }

    if (this.state.device.lacp === true) {
      return [
        <div className="formgroup">
          <div className="formgroup_title">
            <div className="formgroup_title_title">Bond Settings</div>
          </div>
          <div className="formgroup-content">
            <div className="formgroup-element">
              <div className="formgroup-element-title">LACP Mode</div>
              <div className="formgroup-element-fields">
                <SearchableSelect
                  value={this.state.device.lacpMode}
                  onChange={this.handle_ChangeLacpMode}
                  valueDataMap={[
                    [0, "balance-rr"],
                    [1, "active-backup"],
                    [2, "balance-xor"],
                    [3, "broadcast"],
                    [4, "802.3ad"],
                    [5, "balance-tlb"],
                    [6, "balance-alb"],
                  ]}
                ></SearchableSelect>
              </div>
            </div>
            <div className="formgroup-element">
              <div className="formgroup-element-title">Poll Frequency</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input
                    type="number"
                    value={this.state.device.lacpLinkStatePollFrequency}
                    onChange={this.handle_ChangeLacpPollFrequency}
                  />
                </div>
              </div>
            </div>

            <div className="formgroup-element">
              <div className="formgroup-element-title">Down Delay</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="number" value={this.state.device.lacpLinkStateDownDelay} onChange={this.handle_ChangeLacpDownDelay} />
                </div>
              </div>
            </div>
            <div className="formgroup-element">
              <div className="formgroup-element-title">Up Delay</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="number" value={this.state.device.lacpLinkStateUpDelay} onChange={this.handle_ChangeLacpUpDelay} />
                </div>
              </div>
            </div>
          </div>
        </div>,
        <div className="formgroup">
          <div className="formgroup_title">
            <div className="formgroup_title_title">Bond Members</div>
          </div>
          <div className="formgroup-content">
            <ListSelector
              available={available}
              selected={this.state.device.lacpDevices}
              addElement={this.addLacpDevice}
              removeElement={this.removeLacpDevice}
              label="Bond Members"
            ></ListSelector>
          </div>
        </div>,
      ];
    }

    return <div />;
  };

  handle_ChangeAutoNeg = (event) => {
    let device = this.state.device;
    device.autoneg = event.target.checked;
    this.setState({ device: device });
  };

  handle_ChangeFullDuplex = (event) => {
    let device = this.state.device;
    device.full_duplex = event.target.checked;
    this.setState({ device: device });
  };

  handle_ChangeLinkSpeed = (event) => {
    let device = this.state.device;
    device.speed = parseInt(event.value);
    this.setState({ device: device });
  };

  render_PhysicalDuplexConfiguration = () => {
    if (this.state.device.autoneg === false) {
      return (
        <div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Full Duplex</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input type="checkbox" checked={this.state.device.full_duplex} onChange={this.handle_ChangeFullDuplex} />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Link Speed</div>
            <div className="formgroup-element-fields">
              <SearchableSelect
                value={this.state.device.speed}
                onChange={this.handle_ChangeLinkSpeed}
                valueDataMap={[
                  [10, "10BaseT"],
                  [100, "100BaseT"],
                  [1000, "1000BaseT"],
                  [10000, "10000BaseT"],
                ]}
              ></SearchableSelect>
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  };

  render_PhysicalConfiguration = () => {
    if (this.state.device.physical === true) {
      return (
        <div className="formgroup">
          <div className="formgroup_title">
            <div className="formgroup_title_title">Physical Settings</div>
          </div>
          <div className="formgroup-content">
            <div className="formgroup-element">
              <div className="formgroup-element-title">Autoneg</div>
              <div className="formgroup-element-fields">
                <div className="mui-checkbox">
                  <input type="checkbox" checked={this.state.device.autoneg} onChange={this.handle_ChangeAutoNeg} />
                </div>
              </div>
            </div>

            {this.render_PhysicalDuplexConfiguration()}

            <div className="formgroup-element">
              <div className="formgroup-element-title">Mac Address</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="text" value={this.state.device.mac} onChange={this.handle_ChangeMacAddress} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  };

  handle_ChangeDhcpServerMode = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerMode: parseInt(event.value),
    };
    this.setState({ device: device });
  };

  handle_ChangeDhcpServerRelay = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerRelay: event.target.value,
    };
    this.setState({ device: device });
  };

  handle_ChangeDhcpServerStart = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerStart: event.target.value,
    };
    this.setState({ device: device });
  };

  handle_ChangeDhcpServerEnd = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerEnd: event.target.value,
    };
    this.setState({ device: device });
  };

  handle_ChangeDhcpServerDnsMode = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerDnsMode: parseInt(event.value),
    };
    this.setState({ device: device });
  };

  handle_ChangeDhcpServerDnsServer = (event) => {
    const device = {
      ...this.state.device,
      dhcpServerDnsServer: event.target.value,
    };
    this.setState({ device: device });
  };

  handle_ChangePPPOEEnabled = (event) => {
    const device = {
      ...this.state.device,
      pppoe_enabled: event.target.checked,
    };
    this.setState({ device: device });
  };

  handle_ChangePPPOEUsername = (event) => {
    const device = {
      ...this.state.device,
      pppoe_username: event.target.value,
    };
    this.setState({ device: device });
  };

  handle_ChangePPPOEPassword = (event, text_security) => {
    if (!text_security || text_security !== "disc") {
      // Since -webkit-text-security is only supported by webkit based browser,
      // we need to control the input type to hide the password for Firefox
      const length = event.currentTarget.value.length;
      this.setState({ pppoe_password_input_type: length === 0 ? "text" : "password" });
    }

    const device = {
      ...this.state.device,
      pppoe_password: event.target.value,
    };
    this.setState({ device: device });
  };

  addPort = (port) => {
    if (port) {
      this.state.device.upstream_enabled_ports.push(port);
      this.setState({ device: this.state.device });
    }
  };

  removePort = (port) => {
    let ports = [];
    for (let x of this.state.device.upstream_enabled_ports) {
      if (x === port) {
        continue;
      }

      ports.push(x);
    }

    const device = {
      ...this.state.device,
      upstream_enabled_ports: ports,
    };
    this.setState({ device: device });
  };

  addUpstreamSourceException = (exception) => {
    if (exception.match("([0-9]+)\\.([0-9]+)\\.([0-9]+)\\.([0-9]+)")) {
      this.state.device.upstream_enabled_source_exceptions.push(exception);
      this.setState({ device: this.state.device });
    }
  };

  removeUpstreamSourceException = (exception) => {
    let exceptions = [];
    for (let x of this.state.device.upstream_enabled_source_exceptions) {
      if (x === exception) {
        continue;
      }

      exceptions.push(x);
    }

    const device = {
      ...this.state.device,
      upstream_enabled_source_exceptions: exceptions,
    };
    this.setState({ device: device });
  };

  addUpstreamDestinationException = (exception) => {
    if (exception.match("([0-9]+)\\.([0-9]+)\\.([0-9]+)\\.([0-9]+)")) {
      this.state.device.upstream_enabled_destination_exceptions.push(exception);
      this.setState({ device: this.state.device });
    }
  };

  removeUpstreamDestinationException = (exception) => {
    let exceptions = [];
    for (let x of this.state.device.upstream_enabled_destination_exceptions) {
      if (x === exception) {
        continue;
      }

      exceptions.push(x);
    }

    const device = {
      ...this.state.device,
      upstream_enabled_destination_exceptions: exceptions,
    };
    this.setState({ device: device });
  };

  handle_ChangeUpstreamEnabled = (event) => {
    const device = {
      ...this.state.device,
      upstream_enabled: event.target.checked,
    };
    this.setState({ device: device, changes: true });
  };

  handle_ChangeProxy = (event) => {
    const device = {
      ...this.state.device,
      upstream_proxy_uri: event.target.value,
    };
    this.setState({ device: device, changes: true });
  };

  render_DhcpServerConfiguration_Forward = () => {
    if (this.state.device.dhcpServerMode === 3) {
      return (
        <div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Forward</div>
            <div className="formgroup-element-fields">
              <div className="mui-textfield">
                <input type="text" value={this.state.device.dhcpServerRelay} onChange={this.handle_ChangeDhcpServerRelay} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  render_DhcpServerConfiguration_Respond = () => {
    if (this.state.device.dhcpServerMode === 2) {
      return (
        <div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Start Address</div>
            <div className="formgroup-element-fields">
              <div className="mui-textfield">
                <input type="text" value={this.state.device.dhcpServerStart} onChange={this.handle_ChangeDhcpServerStart} />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">End Address</div>
            <div className="formgroup-element-fields">
              <div className="mui-textfield">
                <input type="text" value={this.state.device.dhcpServerEnd} onChange={this.handle_ChangeDhcpServerEnd} />
              </div>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Dns Mode</div>
            <div className="formgroup-element-fields">
              <SearchableSelect
                value={this.state.device.dhcpServerDnsMode}
                onChange={this.handle_ChangeDhcpServerDnsMode}
                valueDataMap={[
                  [0, "Ignore"],
                  [1, "Forward"],
                  [2, "Use External"],
                ]}
              ></SearchableSelect>
            </div>
          </div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Dns Server</div>
            <div className="formgroup-element-fields">
              <div className="mui-textfield">
                <input type="text" value={this.state.device.dhcpServerDnsServer} onChange={this.handle_ChangeDhcpServerDnsServer} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  render_DhcpServerConfiguration = () => {
    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Mode</div>
          <div className="formgroup-element-fields">
            <div className="mui-textfield">
              <SearchableSelect
                value={this.state.device.dhcpServerMode}
                onChange={this.handle_ChangeDhcpServerMode}
                clearable={false}
                valueDataMap={[
                  [1, "Ignore"],
                  [2, "Respond"],
                  [3, "Forward"],
                ]}
              ></SearchableSelect>
            </div>
          </div>
        </div>

        {this.render_DhcpServerConfiguration_Forward()}
        {this.render_DhcpServerConfiguration_Respond()}
      </div>
    );
  };

  render() {
    return [
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Manage Interface {this.props.params.interface}</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Saved</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.persisted} onChange={this.handle_ChangePersisted} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Name</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.device.name} onChange={this.handle_ChangeName} />
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Wan Uplink</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.wan} onChange={this.handle_ChangeWan} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Wan Primary</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.wan_uplink_primary} onChange={this.handle_ChangeWanPrimary} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.render_BridgeConfiguration()}
              {this.render_LacpConfiguration()}
              {this.render_PhysicalConfiguration()}

              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Addressing</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">DHCP</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.dhcp} onChange={this.handle_ChangeDhcp} />
                      </div>
                    </div>
                  </div>
                  <AddIpAddressModal
                    visible={this.state.add_address_visible}
                    handleClose={this.handle_AddIpClose}
                    handleAdd={this.handle_AddIp}
                  ></AddIpAddressModal>
                  {this.render_ManualAddressingConfiguration()}
                </div>
              </div>

              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">PPPOE</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.pppoe_enabled} onChange={this.handle_ChangePPPOEEnabled} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Username</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.device.pppoe_username} onChange={this.handle_ChangePPPOEUsername} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Password</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <PasswordAsTextInput
                          type={this.state.pppoe_password_input_type}
                          value={this.state.device.pppoe_password}
                          onChange={this.handle_ChangePPPOEPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Upstream Proxy</div>
                </div>
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.device.upstream_enabled} onChange={this.handle_ChangeUpstreamEnabled} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Proxy URI</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.device.upstream_proxy_uri} onChange={this.handle_ChangeProxy} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Ports</div>
                    <div className="formgroup-element-fields">
                      <DynamicInputList
                        selected={this.state.device.upstream_enabled_ports}
                        type="number"
                        addElement={this.addPort}
                        removeElement={this.removePort}
                      ></DynamicInputList>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Exception Source IPs</div>
                    <div className="formgroup-element-fields">
                      <DynamicInputList
                        selected={this.state.device.upstream_enabled_source_exceptions}
                        type="text"
                        addElement={this.addUpstreamSourceException}
                        removeElement={this.removeUpstreamSourceException}
                      ></DynamicInputList>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Exception Destination IPs</div>
                    <div className="formgroup-element-fields">
                      <DynamicInputList
                        selected={this.state.device.upstream_enabled_destination_exceptions}
                        type="text"
                        addElement={this.addUpstreamDestinationException}
                        removeElement={this.removeUpstreamDestinationException}
                      ></DynamicInputList>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">DHCP Server Configuration</div>
                </div>
                <div className="formgroup-content">{this.render_DhcpServerConfiguration()}</div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton changes={true} onClick={this.handle_Submit} saving={this.state.saving} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>,
    ];
  }
}

Interface.contextTypes = {
  router: PropTypes.object.isRequired,
};
