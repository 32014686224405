import React from "react";
import LineReader from "../../../../utils/LineReader";
import { useUserStore } from "../../../../storez/UserStore";

const ImportClassroomFile = (props) => {
  const [loading, setLoading] = React.useState(0);
  const [csvObject, setCsvObject] = React.useState(props.csvObject);
  const [preprocessed, setPreprocessed] = React.useState([]);
  const readerCallback = props.readerCallback;
  const VALID_COLUMN_COUNT = 3;

  React.useEffect(() => {
    if (props.csvObject) {
      setCsvObject(props.csvObject);
    }
  }, [props.csvObject]);

  React.useEffect(() => {
    if (!csvObject.processed) {
      let lr = new LineReader();
      lr.on("line", readLine);
      lr.on("progress", updateProgress);
      lr.on("end", loadDone);
      lr.read(csvObject.getFile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProgress = (evt) => {
    if (evt < 100) {
      setLoading(evt);
    }
  };

  const readLine = (line, callback) => {
    csvObject.addLineRead();
    let entries = line.split(",");
    if (entries.length !== VALID_COLUMN_COUNT) {
      csvObject.addLineError();
      callback();
      return;
    }
    let username = entries[0].trim().replace(/['"]+/g, "");
    let classroom = entries[1].trim().replace(/['"]+/g, "");
    let is_owner = entries[2].trim().replace(/['"]+/g, "");

    if (username === "" || classroom === "" || is_owner === "") {
      csvObject.addLineError();
      callback();
      return;
    }

    const toAdd = { username, classroom, is_owner };
    const pp = preprocessed;
    pp.push(toAdd);
    setPreprocessed(pp);
    callback();
  };

  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);
  const filterByValidUsers = async () => {
    let usernames = new Set(preprocessed.map((entry) => entry.username));
    let validUsers = new Map();
    try {
      validUsers = await getUsersAsMap([...usernames]);
    } catch (error) {} // If this fails, all users will be treated as invalid
    preprocessed.forEach((entry) => {
      if (validUsers.has(entry.username)) {
        csvObject.addEntry(entry.username, entry.classroom, entry.is_owner);
      } else {
        csvObject.addNameError();
      }
    });
  };

  const loadDone = () => {
    filterByValidUsers().finally(() => {
      csvObject.setProcessed(true);
      setLoading(100);
      if (readerCallback) {
        readerCallback(csvObject);
      }
    });
  };

  const handle_deleteFile = () => {
    props.deleteFile(csvObject);
  };

  const render_issues = () => {
    let errorLog = [];

    if (csvObject.lineErrors > 0) {
      errorLog.push(
        <div key={"lineErrors"} className={"errorItem"}>
          {csvObject.lineErrors} Invalid Lines
        </div>
      );
    }
    if (csvObject.nameErrors > 0) {
      errorLog.push(
        <div key={"nameErrors"} className={"errorItem"}>
          {csvObject.nameErrors} Unknown Users
        </div>
      );
    }

    if (errorLog.length > 0) {
      return (
        <div className="importErrors">
          {csvObject.lineErrors + csvObject.nameErrors}
          <i className="fa fa-exclamation-triangle" aria-hidden="true">
            <div className={"errorTooltip"}>
              <i className={"fa fa-caret-left"} aria-hidden="true" />
              {errorLog}
            </div>
          </i>
        </div>
      );
    }
    return <div className="importErrors" />;
  };

  let file = csvObject.getFile();
  if (loading !== 100) {
    return (
      <div className="file">
        <div className={"fileName"}> {file.name} </div>
        <div className={"fileInfo"}>
          <div className={"loadingEntries"}>Loading ({loading.toFixed(0)}%)</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="file">
        <div className={"fileName"}> {file.name} </div>
        <div className={"fileInfo"}>
          <div className={"uniqueEntries"}>
            {csvObject.getUniqueEntries().users} <i className="fa fa-user" title={"Users"} aria-hidden="true" />
          </div>
          <div className={"uniqueEntries"}>
            {csvObject.getUniqueEntries().classrooms} <i className="fa fa-users" title={"Classrooms"} aria-hidden="true" />
          </div>
          {render_issues()}
          <div className={"deleteButton"}>
            <i className="fa fa-times" aria-hidden="true" onClick={handle_deleteFile} />
          </div>
        </div>
      </div>
    );
  }
};

export default ImportClassroomFile;
