import {
  Box,
  Button,
  Flex,
  Icon,
  InlineNotification,
  Input,
  Option,
  Spinner,
  TableIconButton,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import React, { useEffect, useMemo, useState } from "react";
import CardBasedPage from "../../templates/CardBasedPage";
import SignatureStore from "../../../stores/SignatureStore";
import SignatureActions from "../../../actions/SignatureActions";
import SortSearchTable from "../../templates/SortSearchTable";
import CardSpinner from "../../reporting/UserDashboard/CardSpinner";
import {
  ClassroomSchedule,
  ClassroomWithUsers,
  Policies,
  Policy,
  Schedule,
  SignatureNames,
  Signatures,
  UpdateClassroomBody,
  ClassroomPolicy,
  InnerPolicy,
} from "../../../types/Classrooms";
import formatSchedule from "./ClassroomFormatSchedule";
import { getClassroom, updateClassroom } from "../../../utils/api/Classrooms";
import { formatUser } from "../../UserSearch/UserSearchHelper";
import { MultiUserSearchSelectorReturningOptions } from "../../UserSearch/MultiUserSearchSelectorReturningOptions";
import { subtract } from "../DiffSetHelper";
import { getPoliciesForClassrooms, deletePolicyRule } from "../../../utils/api/Policies";

interface Props {
  params: {
    id: string;
  };
}

const Classroom: React.FC<Props> = ({ params }) => {
  const { t } = useTranslation();
  const [classroom, setClassroom] = useState<ClassroomWithUsers>();
  const [classroomPolicy, setClassroomPolicy] = useState<ClassroomPolicy>();
  const [loaded, setLoaded] = useState<boolean>();
  const [signatureLoaded, setSignatureLoaded] = useState<boolean>(false);
  const [policies, setPolicies] = useState<Policy[]>();
  const [saving, setSaving] = useState<boolean>();
  const [saveable, setSaveable] = useState<boolean>(false);
  const [selectedTeachers, setSelectedTeachers] = React.useState<Option[]>([]);
  const [selectedStudents, setSelectedStudents] = React.useState<Option[]>([]);

  const { successToast, errorToast } = useToast();

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "config/device/classwize/", isActive: false },
    { title: t("Classrooms"), url: "config/device/classwize/classrooms", isActive: false },
    { title: t("Manage Classroom"), isActive: true },
  ];

  const fetchClassroom = async () => {
    try {
      const classroom = await getClassroom(params.id);
      setClassroom(classroom);
      if (classroom.teachers) {
        setSelectedTeachers(
          classroom.teachers.map((u) => ({
            value: u.id,
            label: formatUser(u),
            username: u.username,
          }))
        );
      }
      if (classroom.students) {
        setSelectedStudents(
          classroom.students.map((u) => ({
            value: u.id,
            label: formatUser(u),
            username: u.username,
          }))
        );
      }
      setLoaded(true);
    } catch (e) {
      errorToast({ title: "Error", description: "Failed to load. Please refresh the page", isClosable: true });
    }
  };

  const readOnly = useMemo(() => {
    if (!classroom) return true;
    switch (classroom.sourceType.toLowerCase()) {
      case "local":
      case "csv":
      case "":
        return false;
      default:
        return true;
    }
  }, [classroom]);

  useEffect(() => {
    void fetchClassroom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const mapPolicy = (policy: InnerPolicy): Policies => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const signatures: Signatures = SignatureStore.getSignatures();
    const signature_names: SignatureNames = {};
    for (const { id, name } of signatures) {
      signature_names[id] = name;
    }

    const allowed = policy.allowed.map(({ id, tag, user }) => ({
      policy: `Allow ${signature_names[tag] || tag}`,
      type: "allowed",
      name: tag,
      user,
      id,
    }));

    const blocked = policy.blocked.map(({ id, tag, user }) => ({
      policy: `Block ${signature_names[tag] || tag}`,
      type: "blocked",
      name: tag,
      user,
      id,
    }));
    return allowed.concat(blocked);
  };

  /* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call */
  const onSignatureChange = () => {
    if (SignatureStore.getSignatures().length > 0) {
      setSignatureLoaded(true);
    }
  };

  useEffect(() => {
    onSignatureChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SignatureStore.listen(onSignatureChange);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);

    return () => {
      SignatureStore.unlisten(onSignatureChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroom]);
  /* eslint-enable */

  useEffect(() => {
    void updatePolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroom]);

  const updatePolicies = async () => {
    if (!classroom) return;
    try {
      const policyIDs: string[] = [];
      if (classroom.stableIDv1) {
        policyIDs.push(classroom.stableIDv1);
      }
      const classroomPolicies = await getPoliciesForClassrooms([classroom.name], policyIDs);
      // TODO: When the Policy migration is complete, only use stableIDs to fetch policies.
      if (classroomPolicies) {
        let classroomPolicy = classroomPolicies.get(classroom.name);
        if (!classroomPolicy && classroom.stableIDv1) {
          classroomPolicy = classroomPolicies.get(classroom.stableIDv1);
        }
        if (classroomPolicy) {
          setClassroomPolicy(classroomPolicy);
          setPolicies(mapPolicy(classroomPolicy.policy));
        }
      }
    } catch (e) {
      console.error(e);
      errorToast({
        title: "Error",
        description: "Failed to load policies. Please refresh the page",
        isClosable: true,
      });
    }
  };

  const onChangeTeachers = (users: Option[]) => {
    setSelectedTeachers(users);
    setSaveable(true);
  };

  const onChangeStudents = (users: Option[]) => {
    setSelectedStudents(users);
    setSaveable(true);
  };

  const tableDataMapSchedule = (schedule: Schedule, index: number) => {
    if (schedule) {
      return (
        <Tr key={index}>
          <Td>
            {schedule.mon.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.tue.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.wed.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.thur.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.fri.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.sat.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
          <Td>
            {schedule.sun.map((time) => (
              <Text>{time}</Text>
            ))}
          </Td>
        </Tr>
      );
    }
    return <></>;
  };
  const columnsSchedule = [
    {
      headerText: t("Monday"),
      columnName: "monday",
    },
    {
      headerText: t("Tuesday"),
      columnName: "tuesday",
    },
    {
      headerText: t("Wednesday"),
      columnName: "Wednesday",
    },
    {
      headerText: t("Thursday"),
      columnName: "thursday",
    },
    {
      headerText: t("Friday"),
      columnName: "friday",
    },
    {
      headerText: t("Saturday"),
      columnName: "saturday",
    },
    {
      headerText: t("Sunday"),
      columnName: "sunday",
    },
  ];

  const tableDataMap = (classroomRule: Policy, index: number) => {
    if (!classroomPolicy) return <></>;
    return (
      <Tr key={index}>
        <Td>
          <Text>{classroomRule.policy}</Text>
        </Td>
        <Td>
          <Text>{classroomRule.user ? classroomRule.user : "All Students"}</Text>
        </Td>
        <Td>
          <Flex>
            <Box mr="sp8">
              <TableIconButton
                disabled={readOnly}
                icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
                aria-label={"Delete Policy"}
                onClick={() => handleDeleteRuleFromPolicy(classroomRule.id)}
                data-testId={`deletePolicyBtn-${classroomRule.policy}`}
              />
            </Box>
          </Flex>
        </Td>
      </Tr>
    );
  };

  const handleDeleteRuleFromPolicy = (ruleId: string) => {
    if (!classroomPolicy) {
      throw new Error("Attempted to delete rule from nonexistent policy");
    }

    const classroomId = params.id;
    if (!classroom || !classroom.stableIDv1) {
      throw new Error("Attempting to delete rule from a nonexistent classroom or one without a stableID");
    }

    deletePolicyRule(classroom.stableIDv1, ruleId)
      .then(() => {
        setPolicies(policies?.filter((rule) => rule.id !== ruleId));
      })
      .catch((error) => {
        console.error("Error deleting rule " + ruleId + " from policy " + classroomId, error);
      });
  };

  const columns = [
    {
      headerText: t("Policy"),
      columnName: "policy",
      sortable: true,
    },
    {
      headerText: t("Students"),
      columnName: "students",
      sortable: true,
    },
    {
      headerText: t("Operations"),
      columnName: "Operations",
    },
  ];

  const classroomSchedule = useMemo((): ClassroomSchedule => formatSchedule(classroomPolicy?.schedule), [classroomPolicy]);

  const handleSave = async () => {
    if (!classroom) return;
    setSaving(true);

    const newStudents = Array.from(
      subtract(new Set(selectedStudents.map((u) => String(u.value))), new Set(classroom.students.map((u) => u.id.toString())))
    );

    const oldStudents = Array.from(
      subtract(new Set(classroom.students.map((u) => u.id.toString())), new Set(selectedStudents.map((u) => String(u.value))))
    );

    const newTeachers = Array.from(
      subtract(new Set(selectedTeachers.map((u) => String(u.value))), new Set(classroom.teachers.map((u) => u.id.toString())))
    );

    const oldTeachers = Array.from(
      subtract(new Set(classroom.teachers.map((u) => u.id.toString())), new Set(selectedTeachers.map((u) => String(u.value))))
    );

    const patchBody: UpdateClassroomBody = {
      oldStudents,
      oldTeachers,
      newStudents,
      newTeachers,
    };

    try {
      await updateClassroom(classroom.id, patchBody);
      successToast({
        title: "Success",
        description: "Classroom was saved successfully.",
        isClosable: true,
      });
    } catch (e) {
      errorToast({
        title: "Error",
        description: "Unable to save Classroom. Please try again.",
        isClosable: true,
      });
    } finally {
      setSaving(false);
      setSaveable(false);
      await fetchClassroom();
    }
  };

  if (!loaded) return <CardSpinner />;
  return (
    <CardBasedPage title={t("Manage Classroom")} breadcrumbs={breadcrumbs}>
      <Box p="sp16">
        <Box mb="sp24">
          {readOnly && (
            <Box pt="sp12">
              <InlineNotification
                status="warning"
                notificationTitle={t("Editing disabled")}
                notificationDescription={t("Archived or synchronised classrooms cannot be edited.")}
              />
            </Box>
          )}
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Classroom Details
          </Text>
          <Flex margin="2px">
            <Flex flexDir="column" justifyContent="space-evenly">
              <Text margin="14px">Name</Text>
              <Text margin="14px">Provider</Text>
            </Flex>
            <Flex flexDir="column" justifyContent="space-evenly" width="60%">
              <Input value={classroom?.name} margin="0px" data-testid="name-input" isDisabled />
              <Input value={classroom?.sourceType} margin="0px" data-testid="provider-input" isDisabled />
            </Flex>
          </Flex>
        </Box>

        <Box mb="sp24">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Teachers and Students
          </Text>
          <Flex margin="2px">
            <Flex flexDir="column" justifyContent="space-evenly">
              <Text margin="14px">Teachers</Text>
            </Flex>
            <Flex flexDir="column" justifyContent="space-evenly" width="60%" data-testid="teachers-selector">
              <MultiUserSearchSelectorReturningOptions
                disabled={readOnly}
                onChangeUsers={onChangeTeachers}
                preselected={selectedTeachers}
                useLegacyId={true}
              />
            </Flex>
          </Flex>
          <Flex margin="2px">
            <Flex flexDir="column" justifyContent="space-evenly">
              <Text margin="14px">Students</Text>
            </Flex>
            <Flex flexDir="column" justifyContent="space-evenly" width="60%" data-testid="students-selector">
              <MultiUserSearchSelectorReturningOptions
                disabled={readOnly}
                onChangeUsers={onChangeStudents}
                preselected={selectedStudents}
                useLegacyId={true}
              />
            </Flex>
          </Flex>
        </Box>
        <Box mb="sp24">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Schedule
          </Text>
          <Box marginLeft={"14px"} data-testid="schedule-table" width="64%">
            <SortSearchTable
              tableTopMargin="sp0"
              tableMargin="sp0"
              tablePadding="sp0"
              loaded={signatureLoaded}
              columns={columnsSchedule}
              showSearch={false}
              tableDataMap={tableDataMapSchedule}
              data={classroomSchedule}
            />
          </Box>
        </Box>
        <Box mb="sp24" width="64%">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Policies
          </Text>
          <Box marginLeft={"14px"} data-testid="policy-table">
            <SortSearchTable
              tableTopMargin="sp0"
              tableMargin="sp0"
              tablePadding="sp0"
              loaded={signatureLoaded}
              columns={columns}
              showSearch={false}
              tableDataMap={tableDataMap}
              data={policies ?? []}
            />
          </Box>
        </Box>
        {!readOnly && (
          <Box>
            <Button
              disabled={!saveable}
              onClick={() => void (async () => await handleSave())()}
              variant="primary"
              data-testid="save-classroom"
            >
              {saving ? <Spinner data-testid="loading-spinner" /> : <Text>Save</Text>}
            </Button>
          </Box>
        )}
      </Box>
    </CardBasedPage>
  );
};

export default Classroom;
