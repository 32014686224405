import { Group, User } from "./Community";

export enum CampaignType {
  ONE_OFF = "ONE_OFF",
  RECURRING = "RECURRING",
}

export interface Campaign {
  id: string;
  key: string;
  name: string;
  type: CampaignType;
  enabled: boolean;
  version: number;
}

export interface ExecutionCriteria {
  excludeUsers?: User[];
  excludeGroups?: Group[];
}

export interface CampaignConfig {
  applianceId: string;
  campaignKey: string;
  criteria: ExecutionCriteria;
  enabled: boolean;
  version: number;
}

export const CampaignConfigEquals = (c1?: CampaignConfig, c2?: CampaignConfig): boolean => {
  let equals = c1?.enabled === c2?.enabled && c1?.version === c2?.version;

  if (equals) {
    const arr1 = c1?.criteria.excludeUsers ?? [];
    const arr2 = c2?.criteria.excludeUsers ?? [];
    equals = arr1.length === arr2.length && arr1.every((o1) => arr2.some((o2) => o1.username === o2.username));
  }

  if (equals) {
    const arr1 = c1?.criteria.excludeGroups ?? [];
    const arr2 = c2?.criteria.excludeGroups ?? [];
    equals =
      arr1.length === arr2.length &&
      arr1.every((o1) => arr2.some((o2) => o1.sourceName === o2.sourceName && o1.sourceType === o2.sourceType));
  }

  return equals;
};

export const CampaignConfigPlaceholder: Readonly<CampaignConfig> = Object.freeze({
  applianceId: "",
  campaignKey: "",
  criteria: {},
  enabled: false,
  version: -1,
});
