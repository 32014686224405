import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { CommunityConfig } from "../types/Community";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";

export const communityConfigURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/community-config`;
};

export interface SaveOptions {
  overrideVersionConflict: boolean;
}

export interface CommunityConfigStore {
  config?: CommunityConfig;
  fetch: () => Promise<CommunityConfig>;
  save: (config: CommunityConfig, options?: SaveOptions) => Promise<CommunityConfig>;
  reset: () => void;
}

export const useCommunityConfigStore = create<CommunityConfigStore>((set) => ({
  config: undefined,

  fetch: async (): Promise<CommunityConfig> => {
    let config,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(communityConfigURL()).then(
      (response: CommunityConfig) => (config = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!config || statusCode !== 200) {
      throw new ResponseError("Failed to fetch Community configuration", statusCode);
    }

    set({ config });

    return config;
  },

  save: async (config: CommunityConfig, options?: SaveOptions): Promise<CommunityConfig> => {
    let saved: CommunityConfig | undefined,
      statusCode = 200;

    if (options && options.overrideVersionConflict) {
      // eslint-disable-next-line
      await Api.getAsync(communityConfigURL()).then(
        (response: CommunityConfig) => (config.version = response.version),
        (reason: jqXHR) => (statusCode = reason?.status)
      );

      if (statusCode !== 200) {
        throw new ResponseError("Failed to save Community configuration", statusCode);
      }
    }

    // eslint-disable-next-line
    await Api.putAsync(communityConfigURL(), config).then(
      (response: CommunityConfig) => (saved = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save Community configuration", statusCode);
    }

    // PUT doesn't return the flags, so we just copy them over
    // which is fine cause they cannot be modified via this API
    saved.featureFlags = config.featureFlags;

    set({ config: saved });

    return saved;
  },

  reset: () => {
    set({ config: undefined });
  },
}));
