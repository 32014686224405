import { QueryClient } from "react-query";

// This is a standalone module because AppRoot.js is a class component and hooks (`useQueryClient`)
// cannot be used in class components; therefore, we rely on the fact that ES modules are singletons.
// That said, we should get rid of React Query completely and use Zustand stores across the board.

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export default queryClient;
