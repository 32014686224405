import { GroupUMS, UserFriendlyGroupNames } from "../../types/Groups";
import { Option } from "@familyzone/component-library";

interface GroupToOption {
  /**
   * @deprecated
   * UUID. Changes after a sync. Use `stableId` instead.
   */
  id: string;
  stableId: string;
  name: string;
  description?: string;
  distinguishedName?: string;
  sourceName?: string;
  sourceType?: GroupUMS["sourceType"];
}

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapGroupToOptionWithStableID` instead.
 */
export const mapGroupToOptionWithID = (group: GroupToOption): Option => {
  let displayName = group.name;
  if (group.description || group.distinguishedName) {
    displayName = groupLabelFormatter(group.name, group.description, group.distinguishedName);
  }
  return {
    value: group.id,
    label: displayName,
    id: group.id,
    name: group.name,
    sourceName: group.sourceName,
    sourceType: group.sourceType,
  };
};

export const mapGroupToOptionWithStableID = (group: GroupToOption): Option => {
  let displayName = group.name;
  if (group.description || group.distinguishedName) {
    displayName = groupLabelFormatter(group.name, group.description, group.distinguishedName);
  }
  return {
    value: group.stableId,
    label: displayName,

    id: group.id,
    stableId: group.stableId,
    name: group.name,
    sourceName: group.sourceName,
    sourceType: group.sourceType,
  };
};

export const groupLabelFormatter = (name: string, description?: string, distinguishedName?: string): string => {
  if (description && distinguishedName) {
    return description + " / " + distinguishedName + " (" + name + ")";
  }
  if (description && !distinguishedName) {
    return description + " (" + name + ")";
  }
  if (!description && distinguishedName) {
    return distinguishedName + " (" + name + ")";
  }
  return name;
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapGroupToOptionNoLabelFallbackWithStableID` instead.
 */
export const mapGroupToOptionNoLabelFallbackWithID = (group: GroupToOption): Option => {
  const result: Option = { value: group.id, id: group.id, name: group.name };
  if (group.description) {
    result.label = groupLabelFormatter(group.name, group.description);
  }
  return result;
};

export const mapGroupToOptionNoLabelFallbackWithStableID = (group: GroupToOption): Option => {
  const result: Option = { value: group.stableId, id: group.stableId, name: group.name };
  if (group.description) {
    result.label = groupLabelFormatter(group.name, group.description);
  }
  return result;
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapGroupsToOptionsNoLabelFallbackWithStableID` instead.
 */
export const mapGroupsToOptionsNoLabelFallbackWithID = (groups: GroupToOption[]): Option[] => {
  return groups.map(mapGroupToOptionNoLabelFallbackWithID);
};

export const mapGroupsToOptionsNoLabelFallbackWithStableID = (groups: GroupToOption[]): Option[] => {
  return groups.map(mapGroupToOptionNoLabelFallbackWithStableID);
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapGroupsToOptionsWithStableID` instead.
 */
export const mapGroupsToOptionsWithID = (groups: GroupToOption[]): Option[] => {
  return groups.map(mapGroupToOptionWithID);
};

export const mapGroupsToOptionsWithStableID = (groups: GroupToOption[]): Option[] => {
  return groups.map(mapGroupToOptionWithStableID);
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapPrefillToOptionWithStableID` instead.
 */
export const mapPrefillToOptionWithID = (prefill?: GroupUMS | null): Option | null => {
  if (!prefill) return null;

  return mapGroupToOptionNoLabelFallbackWithID(prefill);
};

export const mapPrefillToOptionWithStableID = (prefill?: GroupUMS | null): Option | null => {
  if (!prefill) return null;

  return mapGroupToOptionNoLabelFallbackWithStableID(prefill);
};

export const convertStringArrayToOptions = (groups: string[]): Option[] => {
  return groups.map((group) => ({ value: group, name: group }));
};

/**
 * @deprecated
 * UUID. Changes after a sync. Use `mapPrefillsToOptionsWithStableID` instead.
 */
export const mapPrefillsToOptionsWithID = (prefills: GroupUMS[]): Option[] => {
  return prefills.map(mapPrefillToOptionWithID).filter((item): item is Option => !!item);
};

export const mapPrefillsToOptionsWithStableID = (prefills: GroupUMS[]): Option[] => {
  return prefills.map(mapPrefillToOptionWithStableID).filter((item): item is Option => !!item);
};

export const fetchDescriptionsFromStore = async (
  groupNames: string[],
  getGroups: (groupnames: string[]) => Promise<UserFriendlyGroupNames[]>
): Promise<string[]> => {
  const userFriendlyGroupNamesResult = await getGroups(groupNames);

  const result: string[] = groupNames.map((name) => {
    const ufGroup = userFriendlyGroupNamesResult.find((group) => group.name === name);
    if (ufGroup) return groupLabelFormatter(ufGroup.name, ufGroup.description, ufGroup.distinguishedName);
    return name;
  });

  return result;
};

export const fetchDescMapFromStore = async (
  groupNames: string[],
  getGroups: (groupnames: string[]) => Promise<UserFriendlyGroupNames[]>
): Promise<Map<string, string>> => {
  const result = new Map<string, string>();
  const userFriendlyGroupNamesResult = await getGroups(groupNames);

  groupNames.forEach((name) => {
    const ufGroup = userFriendlyGroupNamesResult.find((group) => group.name === name);
    const description = ufGroup ? groupLabelFormatter(ufGroup.name, ufGroup.description, ufGroup.distinguishedName) : name;
    result.set(name, description);
  });

  return result;
};

export const fetchDescriptionsFromStoreOptions = async (
  groups: Option[],
  getGroups: (groupnames: string[]) => Promise<UserFriendlyGroupNames[]>
): Promise<Option[]> => {
  if (groups.length === 0) return groups;

  // If a group already has a description, set it as the label
  groups.forEach((group) => {
    if (group.description !== undefined && group.description !== "") {
      group.label = String(group.description);
    }
  });

  // Get the descriptions for the groups that don't have labels and have a valid name.
  const groupNames: string[] = groups.filter((g) => !g.label && g.name).map((group: Option): string => String(group.name));
  if (groupNames.length > 0) {
    const userFriendlyGroupNamesResult = await getGroups(groupNames);
    // Create an array of options with the values and labels from the groups and descriptions.
    userFriendlyGroupNamesResult.forEach((groupResult) => {
      const groupToAddLabel = groups.find((group) => group.name === groupResult.name);
      if (groupToAddLabel)
        groupToAddLabel.label = groupLabelFormatter(groupResult.name, groupResult.description, groupResult.distinguishedName);
    });
  }

  return groups;
};
