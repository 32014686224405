import React from "react";
import { Box, Flex, useTranslation, Button, Icon, Spinner, SearchBox, Tooltip } from "@familyzone/component-library";

import PropTypes from "prop-types";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
export interface PolicyLayoutProps {
  disabled?: boolean;
  loading?: boolean;
  showSearchBox?: boolean;
  showCategorySearch?: boolean;
  showTestPolicy?: boolean;
  showObjectLists?: boolean;
  onClickCreatePolicy?: (e: React.SyntheticEvent) => void;
  onClickTestPolicy?: (e: React.SyntheticEvent) => void;
  onClickURLSearch?: (e: React.SyntheticEvent) => void;
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch?: (e: React.SyntheticEvent) => void;
  search?: string;
}

const PolicyLayout: React.FC<PolicyLayoutProps> = (
  {
    disabled = false,
    loading = false,
    showSearchBox = false,
    showCategorySearch = false,
    showTestPolicy = false,
    showObjectLists = false,
    onClickCreatePolicy = () => "",
    onClickTestPolicy = () => "",
    onClickURLSearch = () => "",
    onChangeSearch = () => "",
    onClearSearch = () => "",
    search = "",
    children,
  },
  context
) => {
  const { t } = useTranslation();

  const createPolicyText = t("Create Policy");
  const searchText = t("Category Search");
  const testPolicyText = t("Test Policy");
  const searchBoxText = t("Search by policy name");
  const objectLists = t("Object Lists");

  const handleCreatePolicy = (e: React.SyntheticEvent) => {
    onClickCreatePolicy(e);
  };

  const handleTestPolicy = (e: React.SyntheticEvent) => {
    onClickTestPolicy(e);
  };

  const handleNavigateObjectLists = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    context.router.push("/config/device/objects/pools");
  };

  return (
    <Box>
      <Flex flexDirection={"row-reverse"} alignItems="end" paddingEnd={"sp24"}>
        {showTestPolicy && !loading && (
          <Tooltip placement="bottom" label="Check website access for a specific user based on your active policy set." variant="dark">
            <Button
              marginStart="sp12"
              variant="outline"
              leftIcon={<Icon icon="fa-sliders" boxSize="12px" />}
              onClick={handleTestPolicy}
              disabled={disabled}
            >
              {testPolicyText}
            </Button>
          </Tooltip>
        )}
        {showCategorySearch && !loading && (
          <Button
            marginStart="sp12"
            variant="outline"
            leftIcon={<Icon icon="fa-search" boxSize="12px" />}
            onClick={onClickURLSearch}
            disabled={disabled}
          >
            {searchText}
          </Button>
        )}
        <AuthorizationWrapper allowedRoles={["owner", "surfwize_settings"]}>
          {showObjectLists && !loading && (
            <Button
              variant="outline"
              leftIcon={<Icon icon="fa-bar-chart" boxSize="12px" />}
              marginStart="sp12"
              onClick={handleNavigateObjectLists}
              disabled={disabled}
            >
              {objectLists}
            </Button>
          )}
        </AuthorizationWrapper>
      </Flex>

      <Flex width="100%" p="sp24">
        <Box backgroundColor="neutrals.0" borderRadius="6px" width="100%" pl="sp12" pr="sp12" pt="sp12">
          <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Box pl="sp12" pr="sp12" flexGrow={1} maxWidth={536}>
              {showSearchBox && (
                <SearchBox
                  name="searchbox"
                  placeholder={searchBoxText}
                  value={search}
                  onChange={onChangeSearch}
                  onClear={onClearSearch}
                  width="100%"
                />
              )}
            </Box>
            <Flex justifyContent="end" alignItems="center" flexWrap="wrap">
              {disabled && (
                <Box p="sp4">
                  <Spinner data-testid="loading-spinner" />
                </Box>
              )}
              {!loading && (
                <Box p="sp12">
                  <Button
                    variant="primary"
                    leftIcon={<Icon icon="fa-plus" boxSize="18px" />}
                    onClick={handleCreatePolicy}
                    disabled={disabled}
                  >
                    {createPolicyText}
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>
          <Box px="sp12" pb="sp12">
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default PolicyLayout;

PolicyLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};
