import { UserTransferSummaryData } from "./GraphCard";

export const dataSorter = (data: UserTransferSummaryData): number[][] => {
  const sdata = [];

  // The following loop is untouched from the original implementation.
  for (let i = 0; i < data["transfer"].length; i++) {
    const day_token = data["transfer"][i]["day"].split("-");
    const ts = new Date(parseInt(day_token[0]), parseInt(day_token[1]) - 1, parseInt(day_token[2])).getTime();
    const item = [ts, data["transfer"][i]["upload"] + data["transfer"][i]["download"]];
    sdata.push(item);
  }

  return sdata;
};
