import React from "react";
import { Box, Flex, Text, TextInput, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";

export interface IPv4RangeCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const IPv4RangeCriteria: React.FC<IPv4RangeCriteriaProps> = ({ criteria, disabled, onChange }) => {
  const { t } = useTranslation();

  if (Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  const handleChangeStartIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(criteria.conditions)) return;
    onChange({ ...criteria, conditions: { start_ip: e.target.value, end_ip: criteria.conditions.end_ip } });
  };

  const handleChangeEndIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(criteria.conditions)) return;
    onChange({ ...criteria, conditions: { start_ip: criteria.conditions.start_ip, end_ip: e.target.value } });
  };

  return (
    <Flex>
      <TextInput
        name="start ip"
        placeholder={t("Start IP")}
        isDisabled={disabled}
        value={String(criteria.conditions.start_ip)}
        onChange={handleChangeStartIp}
      />
      <Box mx="sp4" />
      <TextInput
        name="end ip"
        placeholder={t("End IP")}
        isDisabled={disabled}
        value={String(criteria.conditions.end_ip)}
        onChange={handleChangeEndIp}
      />
    </Flex>
  );
};

export default IPv4RangeCriteria;
