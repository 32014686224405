import React from "react";

import CriteriaEditor from "../../modules/CriteriaEditor";
import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rule: this.props.rule,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      rule: nextprops.rule,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    const rule = this.state.rule;
    rule.comment = event.target.value;
    this.setState({ rule: this.state.rule });
  };

  handle_SourceCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.source_criteria = criteria;
    this.setState({ rule: this.state.rule });
  };

  handle_DestinationCriteriaChanged = (criteria) => {
    const rule = this.state.rule;
    rule.destination_criteria = criteria;
    this.setState({ rule: this.state.rule });
  };

  handle_ChangeForwardingDestination = (event) => {
    const rule = this.state.rule;
    rule.forwardingDestination = event.target.value;
    this.setState({ rule: this.state.rule });
  };

  handle_ChangeForwardingDestinationPort = (event) => {
    const rule = this.state.rule;
    rule.forwardingDestinationPort = event.target.value;
    this.setState({ rule: this.state.rule });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.rule);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Editing Rule" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element">
              <div className="formgroup-element-title">Name</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="text" value={this.state.rule.comment} onChange={this.handle_ChangeName} />
                </div>
              </div>
            </div>

            <div className="formgroup-element-title">Allowed Sources</div>
            <div className="mui-textfield">
              <CriteriaEditor
                criteria={this.state.rule.source_criteria}
                handleCriteriaModified={this.handle_SourceCriteriaChanged}
                allowed={["protocol", "ipv4.range", "ipv4", "ipv4.address", "ipv4.alias", "device", "geoip", "timeperiod"]}
              ></CriteriaEditor>
            </div>

            <div className="formgroup-element-title">Destination</div>
            <div className="mui-textfield">
              <CriteriaEditor
                criteria={this.state.rule.destination_criteria}
                handleCriteriaModified={this.handle_DestinationCriteriaChanged}
                allowed={[
                  "ipv4.range",
                  "ipv4",
                  "ipv4.address",
                  "ipv4.alias",
                  "source.mac",
                  "transport.port",
                  "transport.portrange",
                  "device",
                ]}
              ></CriteriaEditor>
            </div>

            <div className="formgroup-element">
              <div className="formgroup-element-title">Lan Target IP</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="text" value={this.state.rule.forwardingDestination} onChange={this.handle_ChangeForwardingDestination} />
                </div>
              </div>
            </div>
            <div className="formgroup-element">
              <div className="formgroup-element-title">Lan Target Port</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input
                    type="text"
                    value={this.state.rule.forwardingDestinationPort}
                    onChange={this.handle_ChangeForwardingDestinationPort}
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div></div>;
  }
}

export default class WanPortForwarding extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/wan/portforwarding", function (result) {
      self.setState({
        rules: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    const self = this;
    Api.put("/edgewize/ajax/wan/portforwarding", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Rule" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (row) => {
    const self = this;
    Api.delete("/edgewize/ajax/wan/portforwarding", { id: row["id"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    const self = this;
    Api.post("/edgewize/ajax/wan/portforwarding", rule, function (result) {
      self.handle_load();
    });
  };

  handle_toggleRule = (row) => {
    if (row["enabled"]) {
      row["enabled"] = false;
    } else {
      row["enabled"] = true;
    }

    const self = this;
    Api.post("/edgewize/ajax/wan/portforwarding", row, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__add_permission: true,
    });
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Enabled",
        data: function (row) {
          return <input type="checkbox" checked={row["enabled"]} onChange={() => self.handle_toggleRule(row)} />;
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          return row["comment"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("comment"),
      },
      {
        title: "Allowed Sources",
        data: function (row) {
          return <CriteriaPrinter criteria={row["source_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Destination",
        data: function (row) {
          return <CriteriaPrinter criteria={row["destination_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Lan Target",
        data: function (row) {
          return row["forwardingDestination"] + ":" + row["forwardingDestinationPort"];
        },
        onclick: undefined,
        search: FlexibleTable.search__multiple(["forwardingDestination", "forwardingDestinationPort"]),
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)}></EditButton>
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.rules} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddEditRule
          visible={this.state.visible__add_permission}
          handleSave={this.handle__SaveRule}
          handleClose={this.handler__clickCloseAdd}
          rule={this.state.editing__permission}
        ></AddEditRule>

        <TableCentricPage icon="fa fa-random" title="Port Forwarding" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
