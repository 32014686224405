import React from "react";

export interface AmountFieldProps {
  amount: number;
}

const AmountField = ({ amount }: AmountFieldProps): JSX.Element => {
  return <span>{getAmount(amount)}</span>;
};

export const getAmount = (amount: number): string => {
  if (amount < 0) {
    return "0B";
  }
  if (amount < 1024) {
    return `${amount}B`;
  }
  if (amount < 1048576) {
    return `${Math.round(amount / 1024)}KB`;
  }
  if (amount < 1073741824) {
    return `${Math.round(amount / 1048576)}MB`;
  }
  return `${Math.round(amount / 1073741824)}GB`;
};

export default AmountField;
