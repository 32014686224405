/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
import { headers } from "./ApiHelpers";
import SessionStore from "../../stores/SessionStore";

export type VerifyAuthResponse = {
  token: string;
  userflowToken: string;
  userflowUserIdentity: string;
  userflowCompanyIdentity: string;
};

export async function verifyAuth(onSuccess?: (result: VerifyAuthResponse) => void, onError?: (result: Error) => void): Promise<void> {
  const response = await fetch("/api/verify-auth", {
    method: "PUT",
    headers: {
      ...headers(),
      // Verify if username matches in SMAPI
      User: SessionStore.getUsername(),
    },
  });

  if (!response.ok && onError) {
    onError(new Error(`Failed to verify auth: ${response.statusText}`));
    return;
  }

  const data = (await response.json()) as VerifyAuthResponse;
  if (onSuccess) {
    onSuccess(data);
  }
}
