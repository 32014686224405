import React from "react";
import uuid from "uuid/v4";

import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import Api from "../../utils/Api";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import FlexibleTable from "../../modules/FlexibleTable";
import CriteriaPrinter from "../../modules/CriteriaPrinter";
import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import Separator from "../../modules/Separator";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import WalledGardenSelectionModal from "./WalledGardenSelectionModal";

class ExceptionModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      criteria: undefined,
      criteria_id: undefined,
      new_exception: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.exception) {
      if (nextProps.exception !== this.props.exception) {
        this.setState({
          criteria: nextProps.exception.criteria,
          criteria_id: nextProps.exception.criteria_id,
          new_exception: nextProps.new_exception,
        });
      }
    }
  };

  render() {
    if (this.props.visible) {
      let self = this;

      if (!self.state.criteria) {
        self.state.criteria = [];
        self.state.criteria_id = uuid();
        self.state.new_exception = true;
      }

      return (
        <WalledGardenSelectionModal
          handleHide={this.handleHide}
          isOpen={this.props.visible}
          saveSelection={this.handle_Submit}
          selectedCriteria={self.state.criteria}
          updateParentState={(criteria) => this.handle_SourceCriteriaChanged(criteria)}
          isExclusion={true}
        />
      );
    }

    return <div />;
  }

  handle_SourceCriteriaChanged = (criteria) => {
    this.setState({ criteria: criteria });
  };

  handle_Submit = () => {
    let exceptions = {
      criteria: this.state.criteria,
      criteria_id: this.state.criteria_id,
    };
    this.props.handleSave(exceptions);
    this.handleHide();
    this.resetState();
  };

  handleHide = () => {
    this.props.handleClose();
  };

  resetState = () => {
    this.setState({
      criteria: undefined,
      criteria_id: undefined,
      new_exception: false,
    });
  };
}

class BlockRuleModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      criteria: undefined,
      criteria_id: undefined,
      new_rule: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.rule) {
      if (nextProps.rule !== this.props.rule) {
        this.setState({
          criteria: nextProps.rule.criteria,
          criteria_id: nextProps.rule.criteria_id,
          new_rule: nextProps.new_rule,
        });
      }
    }
  };

  render() {
    if (this.props.visible) {
      let self = this;

      if (!self.state.criteria) {
        self.state.criteria = [];
        self.state.criteria_id = uuid();
        self.state.new_rule = true;
      }

      return (
        <WalledGardenSelectionModal
          handleHide={this.handleHide}
          isOpen={this.props.visible}
          saveSelection={this.handle_Submit}
          selectedCriteria={self.state.criteria}
          updateParentState={(criteria) => this.handle_SourceCriteriaChanged(criteria)}
          isExclusion={false}
        />
      );
    }

    return <div />;
  }

  handle_SourceCriteriaChanged = (criteria) => {
    this.setState({ criteria: criteria });
  };

  handle_Submit = () => {
    let rule = {
      criteria: this.state.criteria,
      criteria_id: this.state.criteria_id,
    };
    this.props.handleSave(rule);
    this.handleHide();
    this.resetState();
  };

  handleHide = () => {
    this.props.handleClose();
  };

  resetState = () => {
    this.setState({
      criteria: undefined,
      criteria_id: undefined,
      new_rule: false,
    });
  };
}

export default class WalledGarden extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      enabled: false,
      loaded: false,
      saving: false,
      changes: true,
      visible__blockRuleModal: false,
      visible__exceptionModal: false,
      rules: [],
      exceptions: [],
      editing__blockRule: undefined,
      editing__exception: undefined,
      new_blockRule: false,
      new_exception: false,
      redirectUrl: "",
    };

    this.criteria_type_name_mapping = {
      group: "Group",
      "exclude.group": "Exclude Group",
      "source.user": "User",
      "ipv4.range": "Network Range",
      ipv4: "Network",
      "ipv4.address": "IP Address",
      "ipv4.alias": "IP Address Object",
      signature: "Application",
      "application.http.hostname.regex": "Website",
      "application.http.hostname": "Website Object",
    };

    this.handle_load();
  }

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Walled Garden
            <DumbBusyIndicator loaded={this.state.loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">{this.render__RequiredOptions()}</div>
              </div>
            </form>
            {this.render__contents()}
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} changes={this.state.changes} saving={this.state.saving} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }

  render__RequiredOptions = () => {
    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Enabled</div>
        <div className="formgroup-element-fields">
          <div className="mui-checkbox">
            <input type="checkbox" checked={this.state.enabled} onChange={this.handle_ChangeEnabled} />
          </div>
        </div>
      </div>
    );
  };

  render__contents = () => {
    if (this.state.enabled) {
      return (
        <div>
          <div className="formgroup">
            <div className="formgroup-content">
              <div className="formgroup-element-title">Redirect URL</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input type="text" value={this.state.redirectUrl} onChange={this.handle_ChangeRedirectUrl} />
                </div>
              </div>
            </div>

            <div className="formgroup_title">
              <div className="formgroup_title_title">Inclusions</div>
              <div className="formgroup_title_rightlink_button">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="heading_button" onClick={this.handle_AddBlockRule}>
                  Add Inclusion
                </a>
              </div>
            </div>

            <div className="formgroup-content formgroup-content-nopadding formgroup-content-fullwidth">
              <BlockRuleModal
                visible={this.state.visible__blockRuleModal}
                handleSave={this.handle__AddBlockRule}
                handleClose={this.handler__closeModalView}
                rule={this.state.editing__blockRule}
                new_rule={this.state.new_blockRule}
              ></BlockRuleModal>
              {this.render__blockruletable()}
            </div>
            <br />
            <br />
            <div className="formgroup_title">
              <div className="formgroup_title_title">Exceptions</div>
              <div className="formgroup_title_rightlink_button">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="heading_button" onClick={this.handle_AddException}>
                  Add Exception
                </a>
              </div>
            </div>

            <div className="formgroup-content formgroup-content-nopadding formgroup-content-fullwidth">
              <ExceptionModal
                visible={this.state.visible__exceptionModal}
                handleSave={this.handle__AddException}
                handleClose={this.handler__closeModalView}
                exception={this.state.editing__exception}
                new_exception={this.state.new_exception}
              ></ExceptionModal>
              {this.render__exceptiontable()}
            </div>
          </div>
        </div>
      );
    }
  };

  render__exceptiontable = () => {
    let self = this;
    let columns = [
      {
        title: "Type",
        data: function (row) {
          let criteria_array = row["criteria"];
          let ret = [];
          for (let criteria of criteria_array) {
            let separator = criteria_array.length >= 2 && ret.length < criteria_array.length - 1 ? ", " : "";
            ret.push(
              <span key={"c" + ret.length}>
                {self.criteria_type_name_mapping[criteria["type"]]}
                {separator}
              </span>
            );
          }
          return ret;
        },
        onclick: undefined,
      },
      {
        title: "Source Criteria",
        data: function (row) {
          let criteria_array = row["criteria"];
          return <CriteriaPrinter criteria={criteria_array}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_EditException(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_DeleteException(row)} />
            </div>
          );
        },
        class: "operationsColumn",
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.exceptions}
        className="formgroup-table"
        hide_headers={true}
        noDataMessage="No exceptions configured"
        loaded={this.state.loaded}
      />
    );
  };

  render__blockruletable = () => {
    let self = this;
    let columns = [
      {
        title: "Type",
        data: function (row) {
          let criteria_array = row["criteria"];
          let ret = [];
          for (let criteria of criteria_array) {
            let separator = criteria_array.length >= 2 && ret.length < criteria_array.length - 1 ? ", " : "";
            ret.push(
              <span key={"c" + ret.length}>
                {self.criteria_type_name_mapping[criteria["type"]]}
                {separator}
              </span>
            );
          }
          return ret;
        },
        onclick: undefined,
      },
      {
        title: "Source Criteria",
        data: function (row) {
          let criteria_array = row["criteria"];
          return <CriteriaPrinter criteria={criteria_array}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_EditBlockRule(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_DeleteBlockRule(row)} />
            </div>
          );
        },
        class: "operationsColumn",
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.rules}
        className="formgroup-table"
        hide_headers={true}
        noDataMessage="All network devices"
        loaded={this.state.loaded}
      />
    );
  };

  handle_load = () => {
    let self = this;
    Api.get("/config/ajax/mobilezone", function (result) {
      self.setState({
        enabled: result["enabled"],
        loaded: true,
      });
      if (result["rules"]) {
        self.setState({ rules: result["rules"] });
      }
      if (result["exceptions"]) {
        self.setState({ exceptions: result["exceptions"] });
      }
      if (result["redirectUrl"]) {
        self.setState({ redirectUrl: result["redirectUrl"] });
      }
    });
  };

  handle_ChangeEnabled = (event) => {
    this.notifyChanged();
    this.setState({ enabled: event.target.checked });
  };

  handle_ChangeRedirectUrl = (event) => {
    this.notifyChanged();
    this.setState({ redirectUrl: event.target.value });
  };

  notifyChanged = () => {
    this.setState({ changes: true });
  };

  handle_AddException = () => {
    this.notifyChanged();
    this.setState({
      visible__exceptionModal: true,
      editing__exception: undefined,
      new_exception: true,
    });
  };

  handle_EditException = (exception) => {
    this.notifyChanged();
    this.setState({
      visible__exceptionModal: true,
      editing__exception: exception,
      new_exception: false,
    });
  };

  handle_DeleteException = (exception) => {
    this.notifyChanged();

    let exceptions = [];
    for (let x of this.state.exceptions) {
      if (x["criteria_id"] !== exception["criteria_id"]) {
        exceptions.push(x);
      }
    }

    this.setState({ exceptions: exceptions });
  };

  handle_AddBlockRule = () => {
    this.notifyChanged();
    this.setState({
      visible__blockRuleModal: true,
      editing__blockRule: undefined,
      new_rule: true,
    });
  };

  handle_EditBlockRule = (rule) => {
    this.notifyChanged();
    this.setState({
      visible__blockRuleModal: true,
      editing__blockRule: rule,
      new_rule: false,
    });
  };

  handle_DeleteBlockRule = (rule) => {
    this.notifyChanged();

    let rules = [];
    for (let x of this.state.rules) {
      if (x["criteria_id"] !== rule["criteria_id"]) {
        rules.push(x);
      }
    }

    this.setState({ rules: rules });
  };

  handler__closeModalView = () => {
    this.setState({
      visible__blockRuleModal: false,
      visible__exceptionModal: false,
    });
  };

  handle__AddBlockRule = (rule) => {
    let rules = [];
    for (let x of this.state.rules) {
      if (x["criteria_id"] !== rule["criteria_id"]) {
        rules.push(x);
      } else {
        rules.push(rule);
      }
    }

    if (this.state.new_rule) {
      rules.push(rule);
    }

    this.setState({ rules: rules });
  };

  handle__AddException = (exception) => {
    let exceptions = [];
    for (let x of this.state.exceptions) {
      if (x["criteria_id"] !== exception["criteria_id"]) {
        exceptions.push(x);
      } else {
        exceptions.push(exception);
      }
    }

    if (this.state.new_exception) {
      exceptions.push(exception);
    }

    this.setState({ exceptions: exceptions });
  };

  handle_Submit = (event) => {
    event.preventDefault();

    let object = {
      enabled: this.state.enabled,
      rules: this.state.rules,
      exceptions: this.state.exceptions,
      redirectUrl: this.state.redirectUrl,
    };

    this.handle__SaveObject(object);
  };

  handle__SaveObject = (object) => {
    let self = this;

    self.setState({ changes: true, saving: true });

    Api.post(
      "/config/ajax/mobilezone",
      object,
      function (e) {
        self.handle_load();
        self.setState({ changes: false, saving: false, new_rule: false });
      },
      function (error) {
        console.log(error);
        self.setState({ saving: false });
      }
    );
  };
}
