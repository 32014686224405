import { Box, Flex, Tag, Td, Text, Tr, useTranslation, Button, Icon, Checkbox } from "@familyzone/component-library";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import TableBasedPage from "../../../../components/templates/TableBasedPage";
import { TableColumn } from "../../../../types/table";
import { getBypassCodes, updateBypassCode } from "../../ApiHelpers";
import { BypassCode } from "../../types";
import BypassCodeModal from "./BypassCodeModal";

export const titleText = "Bypass Codes Available";
export const addText = "Add Code";
export const columnsText = ["Code", "Created By", "Creation", "Expiry", "Status"];
export const searchText = "Search";
export const breadcrumbsText = ["Filtering", "Bypass Codes", "Available"];
export const expireText = "Expire Now";
export const expiredText = "Expired";
export const showExpiredText = "Show expired";

const BypassCodesAvailable: React.FC = () => {
  const { t } = useTranslation();

  const [bypassCodes, setBypassCodes] = useState<BypassCode[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showExpired, setShowExpired] = useState(true);

  const breadcrumbs = [
    { title: t(breadcrumbsText[0]), url: "/filtering", isActive: false },
    { title: t(breadcrumbsText[1]), url: "/filtering/bypass", isActive: false },
    { title: t(breadcrumbsText[2]), isActive: true },
  ];

  const columns: TableColumn[] = [
    {
      headerText: t(columnsText[0]),
      columnName: "code",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[1]),
      columnName: "created_by",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[2]),
      columnName: "creation_time",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t(columnsText[3]),
      columnName: "expiry_time",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t(columnsText[4]),
      columnName: "status",
      sortable: false,
      searchable: false,
      alignment: "center",
    },
  ];

  const populateBypassCodes = useCallback(async () => {
    try {
      const codes = await getBypassCodes();
      setBypassCodes(codes.length > 1 ? codes.sort((a, b) => (a.expiry_time > b.expiry_time ? -1 : 1)) : codes);
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    populateBypassCodes().catch(() => "");
  }, [populateBypassCodes]);

  const handleClickExpire = async (code: BypassCode) => {
    try {
      setLoaded(false);
      code.expiry_time = moment().unix();
      await updateBypassCode(code);
      setBypassCodes(bypassCodes.map((c) => (c.id !== code.id ? c : code)));
    } catch (err) {
      console.error(err);
    } finally {
      setLoaded(true);
    }
  };

  const onClickCreateCode = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUpdateBypassCodes = () => {
    populateBypassCodes().catch(() => "");
  };

  const handleChangeShowExpired = () => {
    setShowExpired(!showExpired);
  };

  const dataMap = (code: BypassCode, index: number) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{code.code}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{code.created_by}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{moment.unix(code.creation_time).format("YYYY/MM/DD HH:mm")}</Text>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Text fontSize="md" mr="sp8">
            {moment.unix(code.expiry_time).format("YYYY/MM/DD HH:mm")}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex alignItems="center" justifyContent="center">
          {moment.unix(code.expiry_time) < moment() ? (
            <Tag variant="subtle" tagLabel={t(expiredText)} />
          ) : (
            <Box onClick={() => void handleClickExpire(code)} cursor="pointer">
              <Tag variant="green" tagLabel={t(expireText)} />
            </Box>
          )}
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <>
      <TableBasedPage
        breadcrumbs={breadcrumbs}
        title={titleText}
        columns={columns}
        data={bypassCodes.filter((b) => (showExpired ? true : moment.unix(b.expiry_time) > moment()))}
        tableDataMap={dataMap}
        loaded={loaded}
        childrenInTableHeader={
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mr="sp16">
              <Checkbox isChecked={showExpired} isDisabled={!loaded} onChange={handleChangeShowExpired}>
                {t(showExpiredText)}
              </Checkbox>
            </Flex>
            <Button
              variant="primary"
              size="sm"
              leftIcon={<Icon icon="fa-plus" boxSize="18px" />}
              onClick={onClickCreateCode}
              disabled={!loaded}
            >
              {t(addText)}
            </Button>
          </Flex>
        }
      />
      <BypassCodeModal disabled={!loaded} open={modalOpen} onClose={handleCloseModal} onSubmit={handleUpdateBypassCodes} />
    </>
  );
};

export default BypassCodesAvailable;
