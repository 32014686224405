import { Accordion, Box, Spinner, Text, useTranslation } from "@familyzone/component-library";
import SignatureHierarchy, { SignatureTree } from "../SignatureHierarchy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { criteriaHierarchyText } from "./TestPolicyModal";
import { CommunitySafetyNetStore, useCommunitySafetyNetStore } from "../../../storez/SafetyNetStore";
import { Verdict } from "../../../pages/filtering/types";
import { RuleCriteria, SafetyNetPolicy } from "../../../types/SafetyNet";
import PolicyCriteria from "./PolicyCriteria";
import StatusChip from "../StatusChip";

interface SafetyNetMatchProps {
  verdict: Verdict;
  signatureTree: SignatureTree[];
}

const safetyNetText = "Existing School Safety Net Policy";
const safetyNetDescription =
  "The below policies have been created by the school admin as a base filtering policy for student devices under parental controls.";
const safetyNetRuleText = "School Safety Net Policy";
const safetyNetRuleTitleText = "for Parental Controls";

const criteriaMatches = (verdict: Verdict, rule: RuleCriteria) =>
  verdict.signatures?.category === rule.signature ||
  verdict.signatures?.signature === rule.signature ||
  verdict.signatures?.subCategory === rule.signature;

const TestPolicySafetyNetMatch: React.FC<SafetyNetMatchProps> = ({ verdict, signatureTree }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [enabledSafetyNetRules, setEnabledSafetyNetRules] = useState<SafetyNetPolicy[]>([]);
  const [getSafetyNetPolicies] = useCommunitySafetyNetStore(
    useCallback((state: CommunitySafetyNetStore) => [state.getActivePolicies] as const, [])
  );

  useEffect(() => {
    void getSafetyNetPolicies().then((policies) => {
      setEnabledSafetyNetRules(policies);
      setLoading(false);
    });
  }, [getSafetyNetPolicies]);

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [highlightedCriteria, matchedRule] = useMemo(() => {
    const highlightedCriteria = enabledSafetyNetRules.map((rule, i) => {
      return rule.criteria.some((c) => criteriaMatches(verdict, c)) ? i : -1;
    });
    const matchedRule = enabledSafetyNetRules.find((_, i) => {
      return highlightedCriteria[i] !== -1;
    });
    return [highlightedCriteria, matchedRule];
  }, [verdict, enabledSafetyNetRules]);

  const matchedRuleName = matchedRule?.name || t(safetyNetRuleText) || "Unknown";

  return (
    <Box>
      <Text fontSize="md" fontWeight="medium" color="text.title" mb="sp8">
        {t(safetyNetText)}
      </Text>
      <Text color="text.paragraph.regular">{t(safetyNetDescription)}</Text>
      <Accordion
        isOpen={dropdownOpen}
        title={`${matchedRuleName} ${t(safetyNetRuleTitleText)}`}
        titleContent={<StatusChip action={verdict.verdict} />}
        accordionPanelProps={{ padding: 0 }}
        accordionButtonProps={{
          onClick: handleToggleDropdown,
        }}
      >
        <Box padding="sp16">
          {loading ? (
            <Spinner size="md" />
          ) : (
            <>
              <PolicyCriteria
                verdict={verdict}
                criteriaLabels={enabledSafetyNetRules.map((c) => c.name)}
                highlightedCriteria={highlightedCriteria}
              />
              <Text color="text.paragraph.light" fontWeight="light" mb="sp8" mt="sp12">
                {t(criteriaHierarchyText)}
              </Text>
              <SignatureHierarchy signatureTree={signatureTree} />
            </>
          )}
        </Box>
      </Accordion>
    </Box>
  );
};

export default TestPolicySafetyNetMatch;
