import React from "react";
import moment from "moment";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import { Icon, Box, Tooltip, withTranslation } from "@familyzone/component-library";
import { zIndices } from "../../utils/ZIndexUtil";

class StackStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: undefined,
    };
  }

  componentDidMount() {
    DeviceStatusStore.listen(this.onChange);
  }

  componentWillUnmount() {
    DeviceStatusStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      status: DeviceStatusStore.getStackStatus(),
    });
  };

  render() {
    const { t } = this.props;

    return (
      <AuthorizationWrapper allowedRoles={this.props.allowedRoles}>
        <Tooltip
          label={this.state.status && this.state.status > 0 && this.state.status > moment().unix() ? t("Filtering off") : t("Filtering On")}
          placement="right"
          variant="dark"
          zIndex={zIndices.tooltip}
        >
          <Box
            borderWidth="1px"
            borderStyle="solid"
            borderColor={this.state.status && this.state.status > 0 && this.state.status > moment().unix() ? "neutrals.60" : "brand.400"}
            mr="sp8"
            lineHeight="0"
            borderRadius="sm"
            bg="neutrals.0"
            p="sp12"
            onClick={this.props.onClick}
            _hover={{ cursor: this.props.onClick ? "pointer" : "default" }}
          >
            <Icon
              boxSize="14px"
              variant="solid"
              color={this.state.status && this.state.status > 0 && this.state.status > moment().unix() ? "neutrals.200" : "brand.400"}
              icon="fa-shield-halved"
            />
          </Box>
        </Tooltip>
      </AuthorizationWrapper>
    );
  }
}

export default withTranslation()(StackStatus);
