import alt from "../alt";
import SignatureActions from "../actions/SignatureActions";
import Api from "../utils/Api";

class SignatureStore {
  constructor() {
    this.signatures = [];
    this.keywords = [];

    this.exportPublicMethods({
      getSignatures: this.getSignatures,
      getKeyword: this.getKeyword,
      getSignature: this.getSignature,
    });

    this.bindListeners({
      handleFetch: SignatureActions.FETCH,
      handleFetchUpdated: SignatureActions.FETCH_UPDATED,
    });
  }

  getSignatures = () => {
    return this.signatures;
  };

  getSignature = (key) => {
    for (let signature of this.signatures) {
      if (signature["id"] === key) {
        return signature;
      }
    }
  };

  getKeyword = (key) => {
    for (let keyword of this.keywords) {
      if (keyword["id"] === key) {
        return keyword;
      }
    }
  };

  handleFetchUpdated(result) {
    this.signatures = result["signatures"];
    this.keywords = result["keywords"];

    return true;
  }

  handleFetch = () => {
    if (this.signatures.length === 0) {
      Api.get(
        "/config/ajax/signatures",
        function (data) {
          setTimeout(() => {
            SignatureActions.fetchUpdated(data);
          }, 0);
        },
        function (err) {
          console.log("error fetching signatures");
        }
      );
    }

    return false;
  };
}

export default alt.createStore(SignatureStore, "SignatureStore");
