/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import "../../../../css/CleverConfiguration.css";
import Api from "../../../utils/Api";
import DumbBusyIndicator from "../../../modules/DumbBusyIndicator";
import NuMultiSelect from "../../../utils/NuMultiSelect";
import SessionStore from "../../../stores/SessionStore";
import { get } from "shades";
import { SyncStatus } from "./SyncStatus";
import { Box, Button, InlineNotification, Link, Radio, RadioGroup } from "@familyzone/component-library";
import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text } from "@chakra-ui/react";

interface Config {
  enabled: boolean;
  districtID: string;
  matchUsersByName: boolean;
  matchUsersByUsername: boolean;
  forceUniqueName: boolean;
  syncParents: boolean;
  syncClassroomData: boolean;
  syncParentTypes: string[];
}

interface SyncApiConfigRequest {
  enabled: boolean;
  districtID: string;
  matchUsersByName: boolean;
  matchUsersByUsername: boolean;
  forceUniqueName: boolean;
  syncParents: boolean;
  syncClassroomData: boolean;
  syncParentTypes: string[];
}

interface SyncApiConfigResponse {
  configuration: {
    id: string;
    config: Config;
  };
  clever_client_id: string;
}

const CleverConfiguration: React.FC = () => {
  const initialConfig: Config = {
    enabled: false,
    districtID: "",
    matchUsersByName: false,
    matchUsersByUsername: false,
    forceUniqueName: false,
    syncParents: false,
    syncClassroomData: true,
    syncParentTypes: [],
  };

  interface ParentType {
    value: string;
  }
  const [configID, setConfigID] = useState<string>("");
  const [config, setConfig] = useState<Config>(initialConfig);

  const [device] = useState(SessionStore.getSelectedDevice());

  const [loaded, setLoaded] = useState(false);
  const [cleverClientId, setCleverClientId] = useState("");
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    handleLoad();
  }, []);

  const handleLoad = () => {
    // So here we try and load a config from sync-api
    Api.get(
      "/config/ajax/authentication/clever/config",
      (result: SyncApiConfigResponse) => {
        setLoaded(true);
        if (result.clever_client_id) {
          setCleverClientId(result.clever_client_id);
        }
        if (result.configuration) {
          setError(undefined);
          setConfigID(result.configuration.id);
          setConfig((prev) => ({
            ...prev,
            districtID: result.configuration.config.districtID,
            enabled: result.configuration.config.enabled,
            forceUniqueName: result.configuration.config.forceUniqueName,
            matchUsersByUsername: result.configuration.config.matchUsersByUsername,
            matchUsersByName: result.configuration.config.matchUsersByName,
            syncParentTypes: result.configuration.config.syncParentTypes || [],
            syncParents: result.configuration.config.syncParents,
            syncClassroomData: result.configuration.config.syncClassroomData,
          }));
        }
      },
      () => {
        setLoaded(true);
        setError("Unable to load configuration.");
      }
    );
  };

  const handleChangeEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({ ...prevConfig, enabled: isChecked }));
    setChanged(true);
  };

  const handleChange_SyncParents = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({ ...prevConfig, syncParents: isChecked }));
    setChanged(true);
  };

  const handleAddSyncParentType = (typeToAdd: ParentType) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      syncParentTypes: [...prevConfig.syncParentTypes, typeToAdd.value],
    }));
    setChanged(true);
  };

  const handleRemoveSyncParentType = (typeToRemove: string) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      syncParentTypes: prevConfig.syncParentTypes.filter((item) => item !== typeToRemove),
    }));
    setChanged(true);
  };

  const handleChangeMatchByEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    // since email is the default, we need to uncheck the other two options
    setConfig((prevConfig) => ({
      ...prevConfig,
      matchUsersByName: !isChecked,
      matchUsersByUsername: !isChecked,
    }));
    setChanged(true);
  };

  const handleChangeMatchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({
      ...prevConfig,
      matchUsersByName: isChecked,
      matchUsersByUsername: !isChecked,
    }));
    setChanged(true);
  };

  const handleChangeMatchByUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({
      ...prevConfig,
      matchUsersByName: !isChecked,
      matchUsersByUsername: isChecked,
    }));
    setChanged(true);
  };

  const handleChangeForceUniqueName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({ ...prevConfig, forceUniqueName: isChecked }));
    setChanged(true);
  };

  const handleChangeSyncClassroomData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setConfig((prevConfig) => ({ ...prevConfig, syncClassroomData: isChecked }));
    setChanged(true);
  };

  const handleCleverLogin = () => {
    let location = document.location.origin;
    if (location.indexOf("localhost") >= 0) {
      location = "http://localhost:3000/bounce";
    }
    window.location.href =
      "https://clever.com/oauth/authorize" +
      "?response_type=code" +
      "&redirect_uri=" +
      location +
      "/config/login/admin/clever/callback" +
      "&client_id=" +
      cleverClientId;
  };

  const handleSubmit = useCallback(() => {
    setShowConfirmModal(false);
    if (config.enabled && config.districtID === "") {
      setError("Cannot enable without a District ID");
      return;
    }
    setSaving(true);

    const syncAPIConfig: SyncApiConfigRequest = {
      enabled: config.enabled,
      districtID: config.districtID,
      matchUsersByName: config.matchUsersByName,
      matchUsersByUsername: config.matchUsersByUsername,
      forceUniqueName: config.forceUniqueName,
      syncParents: config.syncParents,
      syncParentTypes: config.syncParentTypes,
      syncClassroomData: config.syncClassroomData,
    };
    // if config ID exists, update sync-api config
    if (configID) {
      Api.put(
        "/config/ajax/authentication/clever/config/" + configID,
        syncAPIConfig,
        () => {
          handleLoad();
          setChanged(false);
          setSaving(false);
          setError(undefined);
        },
        () => {
          setError("Failed to save configuration.");
        }
      );
      return;
    }
    // if it doesn't exist, create on sync-api config
    Api.post(
      "/config/ajax/authentication/clever/config",
      syncAPIConfig,
      () => {
        handleLoad();
        setChanged(false);
        setSaving(false);
        setError(undefined);
      },
      () => {
        setError("Failed to save configuration.");
      }
    );
  }, [configID, config]);

  const handleSubmitMaybe = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (config.enabled && !config.syncClassroomData && !config.syncParents) {
        setError("Please select either parent data, classroom data, or both to be synced.");
      } else if (config.enabled && config.syncParents && config.syncParentTypes.length === 0) {
        setError("Please select at least one relationship type to sync parent data");
      } else if (config.enabled && config.syncParents && config.syncParentTypes.length > 0) {
        setShowConfirmModal(true);
      } else {
        handleSubmit();
      }
    },
    [config, handleSubmit]
  );

  const handleSyncClasses = () => {
    Api.get("/config/ajax/clever/sync", () => {
      handleLoad();
      const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
      const now = new Date();
      manuallySetRunningSyncs[device.concat("_CleverLockout")] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
      localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
    });
  };

  return (
    <>
      <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={"500px"}>
          <ModalHeader>Confirm Syncing of Parent Data</ModalHeader>
          <ModalBody overflow={"hidden"}>
            <Text>
              {
                "Syncing Parent Data will let us know which parents and students are linked for our Community program. This will potentially allow data and control to be provided to the student’s parent(s) identified this way. Ensure you have only selected required roles for parent(s) who are allowed control of students and their data."
              }
            </Text>
          </ModalBody>

          <ModalFooter
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button className="mui-btn mui-btn--raised modal-button" onClick={() => setShowConfirmModal(false)}>
              Cancel
            </button>
            <button className="mui-btn mui-btn--raised modal-button" onClick={handleSubmit}>
              Confirm and Save
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={"flex"} flex={1} padding={"24px"} gap={"16px"}>
        <Box flex={1}>
          <Box background={"white"} display={"flex"} flexDir={"column"} gap={"1rem"} padding={"1rem"} borderRadius={"6px"}>
            <Box borderBottom={"1px solid #e2e2e2"} padding={"20px"} display={"flex"} flexDir={"column"} gap={"1rem"}>
              <Text data-testid="title" fontSize={"24px"}>
                Clever
              </Text>
              <Link isExternal href={"https://help.linewize.com/hc/en-gb/articles/5733356335772"}>
                Configuring Clever Integration Guide
              </Link>
            </Box>
            <Box>
              <form className="mui-form">
                <Box className="formgroup">
                  <Box className="formgroup-content">
                    <Box className="formgroup-element">
                      <DumbBusyIndicator loaded={loaded} />
                      {loaded ? (
                        [
                          <Box key={"clever-enabled-box"} className="formgroup-element-title">
                            Enabled
                          </Box>,
                          <Box key={"clever-enabled-checkbox"} className="formgroup-element-fields">
                            <Box className="mui-checkbox">
                              <input
                                data-testid="enabled-checkbox"
                                type="checkbox"
                                checked={config.enabled}
                                onChange={handleChangeEnabled}
                              />
                            </Box>
                          </Box>,
                        ]
                      ) : (
                        <Box />
                      )}
                    </Box>
                    {config.enabled && (
                      <>
                        <Box className="formgroup-element">
                          <Box className="formgroup-element-title" width={"100%"}>
                            <Box>Match Users On:</Box>
                            <Box className="dialog_subheading_desc">
                              By default we match Clever users only on email, you can select an additional field to match users on.
                            </Box>
                          </Box>
                          <RadioGroup direction={"column"}>
                            <Radio isChecked={!config.matchUsersByName && !config.matchUsersByUsername} onChange={handleChangeMatchByEmail}>
                              Email Only (Default)
                            </Radio>
                            <Radio isChecked={config.matchUsersByName} onChange={handleChangeMatchByName}>
                              First and Last Name or Email
                            </Radio>
                            <Radio isChecked={config.matchUsersByUsername} onChange={handleChangeMatchByUsername}>
                              Username or Email
                            </Radio>
                          </RadioGroup>
                        </Box>
                        <Box className="formgroup-element">
                          <Box className={"loginButton"} onClick={handleCleverLogin}>
                            <Box className={"loginCover"} />
                            <img alt="login" className="clever_login" src="/static/images/clever/clever-login.png" />
                          </Box>
                        </Box>
                        <Box className="formgroup-element">
                          <Box className="formgroup-element-title">District Id</Box>
                          <Box className="dialog_subheading_desc">To update the district Id follow the Clever log in above.</Box>
                          <Box className="formgroup-element-fields">
                            <Box className="formgroup-element-fields-inline">
                              <Box className="mui-textfield">
                                <input id={"district_id_input"} type="text" spellCheck={false} value={config.districtID} readOnly />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="formgroup-element">
                          <Box
                            className="formgroup-element-title"
                            title={"When this is ticked, syncs include classrooms and classroom relationships."}
                          >
                            Sync Classroom Data
                          </Box>
                          <Box
                            className="formgroup-element-fields"
                            title={"When this is ticked, syncs include classrooms and classroom relationships."}
                          >
                            <Box className="mui-checkbox">
                              <input
                                data-testid="sync-classroom-data"
                                type="checkbox"
                                checked={config.syncClassroomData}
                                onChange={handleChangeSyncClassroomData}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className="formgroup-element">
                          <Box
                            className="formgroup-element-title"
                            title={"Include Clever section id in name to keep sections with duplicate names"}
                          >
                            Force Unique Class Names
                          </Box>
                          <Box
                            className="formgroup-element-fields"
                            title={"Include Clever section id in name to keep sections with duplicate names"}
                          >
                            <Box className="mui-checkbox">
                              <input
                                data-testid="force-unique-names"
                                type="checkbox"
                                checked={config.forceUniqueName}
                                onChange={handleChangeForceUniqueName}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className="formgroup-element">
                          <Box className="formgroup-element-title">Sync Parent Data</Box>
                          <Box className="formgroup-element-fields">
                            <Box className="mui-checkbox">
                              <input
                                data-testid="sync-parent-data-checkbox"
                                type="checkbox"
                                checked={config.syncParents}
                                onChange={handleChange_SyncParents}
                              />
                            </Box>
                          </Box>
                        </Box>
                        {config.syncParents && (
                          <>
                            <Box className="formgroup-element">
                              <Box>Select relationship type</Box>
                              <Box className="dialog_subheading_desc">Please select at least one relationship type to sync parent data</Box>
                            </Box>
                            <Box className="formgroup-element">
                              <Box className="formgroup-element-fields">
                                <NuMultiSelect
                                  allItems={[{ value: "Parent/Guardian" }, { value: "Family" }, { value: "Emergency" }, { value: "Other" }]}
                                  selectedValues={config.syncParentTypes}
                                  displayTextLens={get("value")}
                                  valueLens={get("value")}
                                  onAdd={handleAddSyncParentType}
                                  onRemove={handleRemoveSyncParentType}
                                />
                              </Box>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </form>
            </Box>
            <Box
              padding={"24px"}
              background={"#F5FCFF"}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {error && <InlineNotification status={"error"} notificationDescription={error} />}
              <Button onClick={handleSubmitMaybe} disabled={!changed || saving} variant="primary">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        {config.enabled && (
          <Box width={"500px"} gap={"16px"} display={"flex"} flexDir={"column"}>
            <SyncStatus
              handleRunSync={config.districtID ? handleSyncClasses : undefined}
              syncLockoutTime={
                JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_CleverLockout")]
                  ? JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}")[device.concat("_CleverLockout")]
                  : undefined
              }
              syncType={"CLEVER"}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CleverConfiguration;
