import Api from "../utils/Api";

import alt from "../alt";
import SecurityGroupActions from "../actions/SecurityGroupActions";
import SessionActions from "../actions/SessionActions";

class SecurityGroupStore {
  constructor() {
    this.securityGroups = [];

    this.exportPublicMethods({
      getSecurityGroups: this.getSecurityGroups,
    });

    this.bindListeners({
      handleFetch: SecurityGroupActions.FETCH,
      handleFetchUpdated: SecurityGroupActions.FETCH_UPDATED,
      handleInvalidateAndFetch: SecurityGroupActions.INVALIDATE_AND_FETCH,

      handleAddSecurityGroup: SecurityGroupActions.ADD_SECURITY_GROUP,
      handleDeleteSecurityGroup: SecurityGroupActions.DELETE_SECURITY_GROUP,
      handleDeselectDevice: SessionActions.DESELECT_DEVICE,
      handleChangeDevice: SessionActions.CHANGE_DEVICE,
    });
  }

  getSecurityGroups = () => {
    return this.securityGroups;
  };

  handleDeselectDevice() {
    this.handleDestroy();
  }

  handleChangeDevice() {
    this.handleDestroy();
  }

  handleDestroy = () => {
    this.securityGroups = [];
  };

  handleFetchUpdated(result) {
    this.securityGroups = result;
  }

  handleFetch = () => {
    if (this.securityGroups.length === 0) {
      Api.get(
        "/config/device/ajax/securitygroups",
        function (data) {
          SecurityGroupActions.fetchUpdated(data["data"]);
        },
        function (err) {
          console.log("error fetching objects");
        }
      );
    }
  };

  handleInvalidateAndFetch = () => {
    this.securityGroups = [];
    this.handleFetch();
  };

  handleDeleteSecurityGroup = (object_id) => {
    Api.delete("/config/device/ajax/securitygroups", { id: object_id }, function () {
      SecurityGroupActions.invalidateAndFetch();
    });
  };

  handleAddSecurityGroup = (entity) => {
    Api.put("/config/device/ajax/securitygroups", { name: entity }, function () {
      SecurityGroupActions.invalidateAndFetch();
    });
  };
}

export default alt.createStore(SecurityGroupStore, "SecurityGroupStore");
