import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import Api from "../../utils/Api";
import animationJSON from "./feedback-animation.json";
import { Modal, Text, TextArea, Button, Flex, Box, useTranslation, Animation } from "@familyzone/component-library";

interface FeedbackModalProps {
  open?: boolean;
  onClose?: () => void;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ open = false, onClose = () => "" }: FeedbackModalProps) => {
  // Text
  const titleText = "Tell us about School Manager";
  const bodyText = "We would love to know more about your experience using School Manager. Tell us what you think!";
  const buttonText = "Send Feedback";
  const errorText = "An unexpected error has occured";

  const feedbackTitleText = "Feedback sent!";
  const feedbackBodyText = ["Thank you for your feedback.", "We'll make sure the right people see it!"];

  // Hooks
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<string>("");

  // Functions
  const handleClose = useCallback(() => {
    setText("");
    setError("");
    onClose();
  }, [onClose]);

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const onSubmitSuccess = () => {
    setComplete(true);
  };

  const onSubmitError = useCallback(() => {
    setError(errorText);
  }, [errorText]);

  const handleSubmit = useCallback(async () => {
    if (loading) return;

    let deviceIdValue = localStorage.getItem("DEVICEID");
    if (!deviceIdValue) {
      deviceIdValue = "Not found";
    }

    let usernameValue = localStorage.getItem("USERNAME");
    if (!usernameValue) {
      usernameValue = "Not found";
    }

    setLoading(true);

    await Api.post(
      "/api/feedback",
      {
        username: usernameValue,
        deviceId: deviceIdValue,
        message: text,
      },
      onSubmitSuccess,
      onSubmitError
    );

    setLoading(false);
  }, [text, onSubmitError, loading]);

  const handleClickSubmit = () => {
    handleSubmit()
      .then(() => "")
      .catch(() => "");
  };

  // When complete, close the dialog after 4 seconds
  useEffect(() => {
    if (!complete) return;
    if (!open) return;

    const timeout = setTimeout(() => {
      handleClose();
    }, 7000);

    return () => {
      clearTimeout(timeout);
    };
  }, [open, complete, handleClose]);

  // When the dialog opens reset complete to false
  useEffect(() => {
    if (open) {
      setComplete(false);
    }
  }, [open]);

  return (
    <Modal headerText={!complete ? t(titleText) : ""} size="md" isOpen={open} onClose={handleClose}>
      {!complete ? (
        <Box>
          <Text mt="sp12" mb="sp16">
            {t(bodyText)}
          </Text>
          <TextArea value={t(text)} onChange={handleChangeText} errorMessage={t(error)} rows={5} />
          <Flex mt="sp16">
            <Box>
              <Button variant="primary" disabled={!text} onClick={handleClickSubmit} isLoading={loading}>
                {t(buttonText)}
              </Button>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box mt="sp12">
          <Flex mt="sp12" mb="sp12" justifyContent="center" alignItems="center">
            <Box width="40%">
              <Animation animation={animationJSON} play size="100%" speed={1.2} />
            </Box>
          </Flex>
          <Box mt="sp12" mb="sp12">
            <Text textAlign="center" fontSize="x-large" fontWeight="medium">
              {t(feedbackTitleText)}
            </Text>
          </Box>
          {feedbackBodyText.map((text, index) => (
            <Text textAlign="center" key={`feedback-text-${index}`}>
              {t(text)}
              <br />
            </Text>
          ))}
        </Box>
      )}
    </Modal>
  );
};

export default FeedbackModal;
