import React from "react";
import { Flex, useTranslation, Thead, Tr, Th, Tbody, Table, Box } from "@familyzone/component-library";

import { FilteringRule } from "./types";

import PolicyTableRow from "./PolicyTableRow";
import NoContentDisplay from "./NoContentDisplay";

interface ModificationTableProps {
  loading?: boolean;
  disabled?: boolean;
  rules: FilteringRule[];
  searched: boolean;
  onEditRule: (rule: FilteringRule) => void;
  onDeleteRule: (rule: FilteringRule) => void;
  onToggleRule: (rule: FilteringRule) => void;
}

const ModificationTable: React.FC<ModificationTableProps> = ({
  loading = false,
  disabled = false,
  rules = [],
  searched = false,
  onEditRule = () => "",
  onDeleteRule = () => "",
  onToggleRule = () => "",
}) => {
  const { t } = useTranslation();

  const columns: string[] = [t("Enable"), t("Policy Name"), t("Operations")];

  const noContentTitle = t("Create your first policy");
  const noContentText = t("Create your first content modification policy by clicking on the “Create Policy” button");

  if (!searched && rules.length === 0 && !loading) {
    return <NoContentDisplay title={noContentTitle} text={noContentText} />;
  }

  return (
    <Box>
      <Flex mt="sp12" mb="sp12">
        <Table>
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th
                  key={`${index}-${column}`}
                  headerText={column}
                  columnName={column}
                  alignment={index === columns.length - 1 ? "right" : "left"}
                />
              ))}
            </Tr>
          </Thead>
          <Tbody loaded={!loading}>
            {rules.map((rule) => (
              <PolicyTableRow
                key={rule.id}
                rule={rule}
                id={rule.id}
                onToggleEnabled={() => onToggleRule(rule)}
                onEdit={() => onEditRule(rule)}
                onDelete={() => onDeleteRule(rule)}
                disabled={disabled}
              />
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
};

export default ModificationTable;
