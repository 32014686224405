import React, { useMemo, useCallback } from "react";
import { useTranslation, Box, Text, MultiSearchSelector, Option } from "@familyzone/component-library";

import { SourceCriteriaError, TimePeriod } from "../../pages/filtering/types";
import { FilteringSourceCriteria, IpObject, Fingerprint, MacObject, SecurityGroup } from "./criteriaTypes";

import { v4 as uuidV4 } from "uuid";

import CriteriaItem from "./CriteriaItem";
import { FormControl, FormLabel } from "@chakra-ui/form-control";

export interface CriteriaTypes {
  "application.http.contenttype.regex": "Content Type";
  "application.http.hostname": "Website Objects";
  "application.http.hostname.regex": "Website";
  "application.http.request.regex": "HTTP Request Path";
  "application.http.useragent.regex": "User Agent";
  device: "Interface";
  "exclude.group": "Exclude Group";
  fingerprint: "Device Type";
  geoip: "Country";
  group: "Group";
  ipv4: "Network";
  "ipv4.address": "IP Address";
  "ipv4.alias": "IP Address Object";
  "ipv4.range": "Network Range";
  protocol: "Protocol";
  securitygroup: "Security Group";
  signature: "Signature";
  "source.mac": "Mac Address";
  "source.mac.pool": "Mac Address Object";
  "source.user": "User";
  timeperiod: "Time Periods";
  "transport.port": "Port";
  "transport.portrange": "Port Range";
}

export const criteriaTypes: CriteriaTypes = {
  "application.http.contenttype.regex": "Content Type",
  "application.http.hostname": "Website Objects",
  "application.http.hostname.regex": "Website",
  "application.http.request.regex": "HTTP Request Path",
  "application.http.useragent.regex": "User Agent",
  device: "Interface",
  "exclude.group": "Exclude Group",
  fingerprint: "Device Type",
  geoip: "Country",
  group: "Group",
  ipv4: "Network",
  "ipv4.address": "IP Address",
  "ipv4.alias": "IP Address Object",
  "ipv4.range": "Network Range",
  protocol: "Protocol",
  securitygroup: "Security Group",
  signature: "Signature",
  "source.mac": "Mac Address",
  "source.mac.pool": "Mac Address Object",
  "source.user": "User",
  timeperiod: "Time Periods",
  "transport.port": "Port",
  "transport.portrange": "Port Range",
};

interface CriteriaOption {
  label: string;
  value: keyof CriteriaTypes;
}

export const criteriaDefaultConditions = {
  "application.http.contenttype.regex": { expression: "" },
  "application.http.hostname": [], // UUIDs of Website Objects
  "application.http.hostname.regex": { expression: "" },
  "application.http.request.regex": { expression: "" },
  "application.http.useragent.regex": { expression: "" },
  device: { name: "" },
  "exclude.group": [],
  fingerprint: [],
  geoip: { country: "" },
  group: [],
  ipv4: { ip: "", mask: "" },
  "ipv4.address": { ip: "" },
  "ipv4.alias": [],
  "ipv4.range": { end_ip: "", start_ip: "" },
  protocol: [],
  securitygroup: [],
  signature: [], // Array of signatures - eg, ["category.voipvideo", "sphirewall.applicaiton.classdojo.com"]
  "source.mac": { mac: "" },
  "source.mac.pool": [],
  "source.user": [],
  timeperiod: [],
  "transport.port": [],
  "transport.portrange": { startPort: 0, endPort: 0 },
};

export interface CriteriaSelectorProps {
  customOptions?: Partial<CriteriaTypes>; // If provided will reduce types available to only the criteria types provided
  disabled?: boolean;
  selected: FilteringSourceCriteria[];
  errors: SourceCriteriaError[];
  ipObjects?: IpObject[];
  fingerprints?: Fingerprint[];
  macObjects?: MacObject[];
  timePeriods?: TimePeriod[];
  securityGroups?: SecurityGroup[];
  onChange: (sourceCriteria: FilteringSourceCriteria[]) => void;
}

const CriteriaSelector: React.FC<CriteriaSelectorProps> = ({
  disabled = false,
  selected = [],
  errors = [],
  ipObjects,
  fingerprints,
  macObjects,
  timePeriods,
  securityGroups,
  customOptions,
  onChange = () => "",
}) => {
  const { t } = useTranslation();

  const criteriaTypesToUse = customOptions ? customOptions : criteriaTypes;

  const criteriaTypeOptions: Option[] = useMemo(
    () => Object.entries(criteriaTypesToUse).map(([key, value]) => ({ label: t(value), value: key })),
    [criteriaTypesToUse, t]
  );

  const selectedCriteriaTypeOptions = useMemo(
    () => selected.map((s) => criteriaTypeOptions.find((o) => o.value === s.type)).filter((s): s is CriteriaOption => !!s),
    [selected, criteriaTypeOptions]
  );

  const handleChangeCriteriaType = (options: Option[]) => {
    const newCriteriaArray = options
      .filter((o): o is CriteriaOption => o.label !== undefined)
      .map(
        (option: CriteriaOption) =>
          selected.find((o) => o.type === option.value) || {
            conditions: criteriaDefaultConditions[option.value],
            id: uuidV4(),
            name: criteriaTypes[option.value],
            type: option.value,
          }
      );

    onChange(newCriteriaArray);
  };

  const handleChangeCriteria = (criteria: FilteringSourceCriteria) => {
    onChange(
      selected.map((s) => {
        if (s.id !== criteria.id) return s;
        return criteria;
      })
    );
  };

  const handleDeleteCriteria = (criteria: FilteringSourceCriteria) => {
    onChange(selected.filter((s) => s.id !== criteria.id));
  };

  const getCriteriaError = useCallback((criteria: FilteringSourceCriteria) => errors.find((e) => e.id === criteria.id)?.msg, [errors]);

  return (
    <Box>
      <MultiSearchSelector
        name="criteria-selector"
        placeholder={t("Select Criteria")}
        onChange={handleChangeCriteriaType}
        selected={selectedCriteriaTypeOptions}
        options={criteriaTypeOptions}
        disabled={disabled}
      />
      <Box>
        {selected.map((criteria) => (
          <Box key={criteria.id} mt="sp12">
            <FormControl>
              <FormLabel>{criteria.name}</FormLabel>
              <CriteriaItem
                disabled={disabled}
                criteria={criteria}
                ipObjects={ipObjects}
                fingerprints={fingerprints}
                macObjects={macObjects}
                timePeriods={timePeriods}
                securityGroups={securityGroups}
                onChange={handleChangeCriteria}
                onDelete={() => handleDeleteCriteria(criteria)}
              />
              {getCriteriaError(criteria) && (
                <Text mt="sp4" color="error.title">
                  {getCriteriaError(criteria)}
                </Text>
              )}
            </FormControl>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CriteriaSelector;
