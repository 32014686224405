import React from "react";

import Api from "../../utils/Api";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import LineGraph from "../../modules/LineGraph";
import TableLeftPanel from "../../utils/TableLeftPanel";

import { Box } from "@familyzone/component-library";
import { InfoIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

export interface MetricConfig {
  data: { time: number; value: number }[];
}

export interface MetricConfigState {
  data: { time: number; value: number }[];
  loaded: boolean;
  loading: boolean;
}

const DiagnosticsInterfaceMetrics: React.FC = () => {
  const [interface_name, setInterfaceName] = React.useState("eth0");
  const [download_rate, setDownloadRate] = React.useState<MetricConfigState>({
    data: [{ time: 0, value: 0 }],
    loaded: false,
    loading: false,
  });
  const [upload_rate, setUploadRate] = React.useState<MetricConfigState>({
    data: [{ time: 0, value: 0 }],
    loaded: false,
    loading: false,
  });

  const clearMetricState = () => {
    setDownloadRate({ data: [{ time: 0, value: 0 }], loaded: false, loading: false });
    setUploadRate({ data: [{ time: 0, value: 0 }], loaded: false, loading: false });
  };

  const onDateChanged = () => {
    clearMetricState();
  };

  const loadDownloadMetricData = () => {
    const download_metric_name = "interface." + interface_name + ".download_rate";

    if (download_rate.loaded === false && download_rate.loading === false) {
      setDownloadRate({ ...download_rate, loading: true });

      Api.get_analytics(
        "/managedevice/ajax/device/metric/" + download_metric_name,
        (result: MetricConfig) => setDownloadRate({ data: result["data"], loaded: true, loading: false }),
        (err: Error) => console.log(err)
      );
    }
  };

  const loadUploadMetricData = () => {
    const upload_metric_name = "interface." + interface_name + ".upload_rate";

    if (upload_rate.loaded === false && upload_rate.loading === false) {
      setUploadRate({ ...upload_rate, loading: true });

      Api.get_analytics(
        "/managedevice/ajax/device/metric/" + upload_metric_name,
        (result: MetricConfig) => setUploadRate({ data: result["data"], loaded: true, loading: false }),
        (err: Error) => console.log(err)
      );
    }
  };

  React.useEffect(() => {
    loadDownloadMetricData();
    loadUploadMetricData();
    const unsubFilterStore = useDateRangeFilterStore.subscribe(onDateChanged);
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);

    return () => {
      unsubFilterStore();
    };
  });

  const showGraphs = () => {
    return (
      <Box className="row">
        <Box className="col-md-6">
          <Box className="formgroup">
            <Box className="formgroup_title">
              <Box className="formgroup_title_title">Download Rate</Box>
            </Box>
            <LineGraph
              data={download_rate.data}
              container="Download Rate"
              xAxisFormatter="formatUnixTimestamp"
              dataLoaded={download_rate.loaded}
            />
          </Box>
        </Box>
        <Box className="col-md-6">
          <Box className="formgroup">
            <Box className="formgroup_title">
              <Box className="formgroup_title_title">Upload Rate</Box>
            </Box>
            <LineGraph
              data={upload_rate.data}
              container="Upload Rate"
              xAxisFormatter="formatUnixTimestamp"
              dataLoaded={upload_rate.loaded}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const RenderInput = () => {
    return (
      <span
        className="table-search-box"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            clearMetricState();
            loadDownloadMetricData();
            loadUploadMetricData();
          }
        }}
      >
        <span className="fa fa-search" />
        <input
          data-testid="search-box-test-id"
          placeholder="Search"
          value={interface_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInterfaceName(e.target.value)}
          data-cy="flexTableSearch"
        />
      </span>
    );
  };

  const RenderHeader = () => {
    return (
      <Box className="tableheader tableheader-dark">
        <Box className="tableheader-title">
          Interface Metrics (Support Admin Only)
          <Tooltip
            variant="dark"
            hasArrow={true}
            maxWidth="336px"
            fontSize="md"
            label="Type interface name in the textbox and press Enter to load graph"
          >
            <InfoIcon boxSize={6} ml={7} />
          </Tooltip>
        </Box>
        {RenderInput()}
      </Box>
    );
  };

  return (
    <TableLeftPanel>
      <Box className="table-backdrop">
        <Box className="maincontent-sub">
          <Box className="main-content-sub-element">{RenderHeader()}</Box>
          <Box className="main-content-sub-element">{showGraphs()}</Box>
        </Box>
      </Box>
    </TableLeftPanel>
  );
};

export default DiagnosticsInterfaceMetrics;
