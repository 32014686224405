import { Flex, InlineNotification, Tag } from "@familyzone/component-library";
import React from "react";
import { Classroom } from "../../../types/Classrooms";
import Link from "../../Link";

interface UserClassroomsProps {
  loaded: boolean;
  classrooms: Classroom[];
}

export const UserClassrooms: React.FC<UserClassroomsProps> = ({ loaded, classrooms }) => {
  if (!loaded) {
    return <></>;
  }

  const classroomMap = ({ id, name }: Classroom): JSX.Element => {
    return (
      <Link key={id} data-testid={`link${id}`} to={`/config/device/classwize/classrooms/${encodeURIComponent(id)}`}>
        <Flex mr="sp4" mb="sp4">
          <Tag tagLabel={name} variant="brand" />
        </Flex>
      </Link>
    );
  };

  if (classrooms && classrooms.length > 0) {
    return (
      <Flex flexWrap="wrap" mb="sp24">
        {classrooms.map(classroomMap)}
      </Flex>
    );
  }
  return <InlineNotification status="info" notificationDescription="There are no classrooms for this user." />;
};
