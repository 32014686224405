import React, { FC, useCallback, useState, useEffect } from "react";
import { useTranslation } from "@familyzone/component-library";
import Api from "../../../utils/Api";
import { BasePill } from "./BasePill";
import { FetchSyncExecutionsResponse, getEndpoint } from "../interfaces/GetSyncExecutions";
import moment from "moment/moment";

type SyncPillProps = {
  syncType: "GOOGLE" | "LDAPS" | "AZURE" | "CLEVER"; // we don't allow wonde or oneroster because they can have many syncs
  redirect: () => void;
};

// We can't just update the casing because LDAP is all upper case
export const syncTypeToFriendly = (syncType: "GOOGLE" | "LDAPS" | "AZURE" | "CLEVER"): string => {
  switch (syncType) {
    case "GOOGLE":
      return "Google";
    case "LDAPS":
      return "LDAP";
    case "CLEVER":
      return "Clever";
    case "AZURE":
      return "Microsoft Entra ID";
  }
};

export const SyncPill: FC<SyncPillProps> = ({ syncType, redirect }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [syncErrors, setSyncErrors] = useState("");
  const [syncStartTime, setSyncStartTime] = useState<moment.Moment | null>(null);

  const fetchStatuses = useCallback(() => {
    setLoading(true);
    Api.get(getEndpoint(syncType, undefined), (data: FetchSyncExecutionsResponse) => {
      // flatten the result
      const results = data.result.edges.map((edge) => edge.node);
      // latest sync status
      if (results[0]?.syncStatuses[0].status === "Failed") {
        setSyncErrors(results[0]?.syncStatuses[0].message);
      }
      // first sync status is 'started' or 'pending'
      if (results[0]?.syncStatuses[results[0]?.syncStatuses.length - 1].status === "Started") {
        setSyncStartTime(moment(results[0]?.syncStatuses[results[0]?.syncStatuses.length - 1].createdAt));
      } else if (results[0]?.syncStatuses[results[0]?.syncStatuses.length - 1].status === "Pending") {
        // set time to the actual starting time
        setSyncStartTime(moment(results[0]?.syncStatuses[results[0]?.syncStatuses.length - 2].createdAt));
      }
      setLoading(false);
    });
  }, [syncType]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  if (syncErrors !== "") {
    return <BasePill isLoading={loading} title={t("Failed to sync")} body={t(`${syncTypeToFriendly(syncType)} Sync`)} onClick={redirect} />;
  } else if (syncStartTime) {
    return (
      <BasePill
        isLoading={loading}
        title={`${t("Synced")} ${syncStartTime.from(moment())}`}
        body={t(`${syncTypeToFriendly(syncType)} Sync`)}
        onClick={redirect}
      />
    );
  } else {
    return (
      <BasePill
        isLoading={loading}
        title={`${t("Unable to find most recent status")}`}
        body={t(`${syncTypeToFriendly(syncType)} Sync`)}
        onClick={redirect}
      />
    );
  }
};
