// Applies shake animation, and error class. Intended to be used with dom input elements
export function applyErrorStyling(...elements) {
  elements.forEach((el) => {
    el.classList.add("apply-shake");
    el.classList.add("error");
  });
}

// Removes error styling applied by the above function
export function removeErrorStyling(...elements) {
  elements.forEach((el) => {
    el.classList.remove("apply-shake");
    el.classList.remove("error");
  });
}
