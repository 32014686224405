import alt from "../alt";
import FingerprintActions from "../actions/FingerprintActions";
import Api from "../utils/Api";

class FingerprintStore {
  constructor() {
    this.fingerprints = [];

    this.exportPublicMethods({
      getFingerprints: this.getFingerprints,
      getFingerprint: this.getFingerprint,
    });

    this.bindListeners({
      handleFetch: FingerprintActions.FETCH,
      handleFetchUpdated: FingerprintActions.FETCH_UPDATED,
    });
  }

  getFingerprints = () => {
    return this.fingerprints;
  };

  getFingerprint = (key) => {
    for (let fingerprint of this.fingerprints) {
      if (fingerprint["id"] === key) {
        return fingerprint;
      }
    }
  };

  handleFetchUpdated(result) {
    this.fingerprints = result;
  }

  handleFetch = () => {
    if (this.fingerprints.length === 0) {
      Api.get(
        "/config/ajax/fingerprints",
        function (data) {
          FingerprintActions.fetchUpdated(data["fingerprints"]);
        },
        function (err) {
          console.log("error fetching fingerprints");
        }
      );
    }
  };
}

export default alt.createStore(FingerprintStore, "FingerprintStore");
