import { PolicyBinding, tryFetchSubnetGroups } from "./DnsFilteringHelper";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  MultiSearchSelector,
  Option,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { SubnetGroup } from "./CloudSafeHelper";
import { useMount } from "react-use";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";
import { UserUMS } from "../../types/Users";

export interface AddEditBindingModalProps {
  isOpen: boolean;
  policyBinding?: PolicyBinding;
  handleClose: () => void;
  handleSave: (data: PolicyBinding, callback?: (err?: string) => void) => void;
  user?: UserUMS;
}

export const AddEditBindingModal: React.FC<AddEditBindingModalProps> = ({
  handleClose,
  handleSave,
  isOpen,
  policyBinding: propsPolicyBinding,
  user,
}) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const initialBinding = {
    enabled: false,
    id: "",
    objectLists: [],
    user: "",
    objectListNames: [],
  };

  const [policyBinding, setPolicyBinding] = useState<PolicyBinding>(initialBinding);

  const [subnetGroupOptions, setSubnetGroupOptions] = useState<SubnetGroup[]>([]);

  useMount(() => {
    void (async () => {
      const subnetGroups = await tryFetchSubnetGroups(() => {
        errorToast({ title: "There was an error fetching subnets", isClosable: true });
      });
      setSubnetGroupOptions(subnetGroups);
    })();
  });

  useEffect(() => {
    if (propsPolicyBinding) setPolicyBinding(propsPolicyBinding);
    else setPolicyBinding(initialBinding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsPolicyBinding]);

  const handleSubmit = () => {
    if (!policyBinding.user || policyBinding.user.length === 0) {
      errorToast({
        title: t("A user must be selected for the policy binding"),
        isClosable: true,
      });
      return;
    }

    if (!policyBinding.objectLists || policyBinding.objectLists.length === 0) {
      errorToast({
        title: t("One or more subnet groups must be selected"),
        isClosable: true,
      });
      return;
    }

    handleSave(policyBinding, (err) => {
      if (!err) {
        handleClose();
        setPolicyBinding(initialBinding);
        return;
      }

      errorToast({
        title: err,
        isClosable: true,
      });
    });
  };

  const userSelected = (user: Option | null) => {
    if (user) {
      setPolicyBinding({ ...policyBinding, user: `${user.value}` });
    } else {
      setPolicyBinding({ ...policyBinding, user: "" });
    }
  };

  const ipObjectsChanged = (objectLists: Option[]) => {
    setPolicyBinding({
      ...policyBinding,
      objectLists: objectLists.map((o) => `${o.value}`),
    });
  };

  const renderActions = (
    <Button variant="primary" onClick={handleSubmit} aria-label="save">
      {t("Save Policy Binding")}
    </Button>
  );

  const renderSubnets = () =>
    subnetGroupOptions
      .filter((o) => !policyBinding.objectLists.includes(o.objectId))
      .map((o) => ({
        label: o.text,
        value: o.objectId,
      }));

  const renderSelectedSubnets = () =>
    subnetGroupOptions
      .filter((o) => policyBinding.objectLists.includes(o.objectId))
      .map((o) => ({
        label: o.text,
        value: o.objectId,
      }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      headerText={propsPolicyBinding === undefined ? "Add Policy Binding" : "Edit Policy Binding"}
      size="md"
      contentProps={{ style: { overflow: "visible" } }}
    >
      <Box my="sp24" py="sp4" overflow="visible">
        <Table style={{ tableLayout: "fixed" }}>
          <Tbody>
            <Tr>
              <Td width="120px">
                <Text fontSize="medium" color="text.title">
                  {t("User")}
                </Text>
              </Td>
              <Td>
                <UserSearchSelector
                  prefill={user ?? policyBinding?.user}
                  handleChange={userSelected}
                  placeholderText={t("Enter username, email or name")}
                />
              </Td>
            </Tr>
            <Tr>
              <Td width="120px">
                <Text fontSize="medium" color="text.title">
                  {t("Subnets")}
                </Text>
              </Td>
              <Td>
                <MultiSearchSelector
                  placeholder={t("Select IP Objects")}
                  name="ip object selector"
                  options={renderSubnets()}
                  selected={renderSelectedSubnets()}
                  onChange={ipObjectsChanged}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Flex>{renderActions}</Flex>
    </Modal>
  );
};
