import { Box, Flex, GridItem, Text } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import SignatureTreeStore from "../../../stores/SignatureTreeStore";
import Api from "../../../utils/Api";
import { RedFlagPill } from "../RedFlagPill/RedFlagPill";
import CardSpinner from "./CardSpinner";
import { FormatRedFlagDataForPills, RedFlagRawApiData, RedFlagRawData, SignatureKeyword } from "./RedFlagsCardHelper";
import { ApiDates } from "../../../utils/DateTimeUtil";
import SignatureTreeActions from "../../../actions/SignatureTreeActions";

export interface RedFlagsCardProps {
  user: string;
  dateFilters: ApiDates;
}

const RedFlagsCard = ({ user, dateFilters }: RedFlagsCardProps): JSX.Element => {
  const [riskSelectors, setRiskSelectors] = useState<RedFlagRawData>();
  const [keywords, setKeywords] = useState<SignatureKeyword[]>([]);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    const loadRiskSelectorsData = (user: string) => {
      Api.get_analytics(
        `/surfwize/device/ajax/analytic/user/${user}/riskselector`,
        (response: RedFlagRawApiData) => {
          setRiskSelectors(response.data);
        },
        (_: unknown) => {
          setErrorState(true);
        },
        { dateOverride: dateFilters }
      );
    };

    /* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    const onChangeSignatures = () => {
      setKeywords(SignatureTreeStore.getKeywords());
    };

    loadRiskSelectorsData(user);

    SignatureTreeStore.listen(onChangeSignatures);

    setTimeout(() => {
      SignatureTreeActions.fetch();
    }, 0);

    return () => {
      SignatureTreeStore.unlisten(onChangeSignatures);
    };
    /* eslint-enable */
  }, [dateFilters, user]);

  const renderPills = (riskSelectors: RedFlagRawData) => {
    const data = FormatRedFlagDataForPills(riskSelectors, keywords, user);
    return data.map((value) => <RedFlagPill key={value.text} severity={value.severity} text={value.text} link={value.link} />);
  };

  return (
    <GridItem colSpan={4} background="neutrals.0" borderRadius="sm">
      <Box borderRadius="md" background="neutrals.0" paddingX="sp16" paddingY="sp24">
        <Text fontFamily="heading" fontWeight="500" fontSize="16px" color="text.title">
          Red Flags
        </Text>
        <Flex display="flex" flexWrap="wrap">
          {errorState ? (
            <Text>There's a problem loading Red Flags for this user right now. Please try again later.</Text>
          ) : !riskSelectors || keywords.length === 0 ? (
            <CardSpinner />
          ) : (
            renderPills(riskSelectors)
          )}
        </Flex>
      </Box>
    </GridItem>
  );
};

export default RedFlagsCard;
