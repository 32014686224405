// Change value to be a number to reduce type-casting
export function formatToMB(value: string): string {
  const numValue = Number(value);
  const absoluteValue = Math.abs(numValue);

  if (absoluteValue >= 100000 && absoluteValue < 100000000) {
    return parseFloat(String(Math.round(numValue * 100) / 100000)).toFixed(2) + "K";
  } else if (absoluteValue >= 100000000) {
    return parseFloat(String(Math.round(numValue * 100) / 100000000)).toFixed(2) + "M";
  }
  return String(value);
}

// Reference: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  decimals = Number(decimals);
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return String(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + " " + sizes[i];
};

export const formatBitsPerSecond = (bytes: number): string => {
  if (bytes === 0) return "0 bps";

  const k = 1000;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // We are only using this method to temporarily match up
  // with the incorrect formula for Mbps on the classic dashboard.

  const mbps = bytes / 104857;

  switch (i) {
    case 0:
      return (bytes * 8).toFixed(0) + " bps";
    case 1:
      return (mbps * 1000).toFixed(2) + " Kbps";
    case 2:
      return mbps.toFixed(2) + " Mbps";
    case 3:
      return (mbps / 1000).toFixed(2) + " Gbps";
    default:
      return "0 bps";
  }
};

/**
 * Splits a string into lines with a maximum length
 * @param {string} input - the string to split
 * @param {int} lineWrapLength - maximum length of a line
 * @returns the array of split strings
 */

export function splitLinesWithWrap(input: string, lineWrapLength: number): string[] {
  if (!input || input.length <= 0) {
    return [];
  }
  const lines = input.split("\n");
  if (!lineWrapLength || lineWrapLength <= 0) {
    return lines;
  }

  const result: string[] = [];
  const regex = new RegExp(`.{1,${lineWrapLength}}`, "g");
  lines.forEach((line) => {
    //Split into segments of up to lineWrapLength characters
    const segments = line.match(regex);
    segments?.forEach((segment) => {
      result.push(segment);
    });
  });
  return result;
}
