import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { CalendarRule, CloneCalendarRule, RecurrenceExceptionType } from "../../../types/Calendar";
dayjs.extend(timezone);

export const containsForbiddenCharacters = (name: string): boolean => {
  return !/^[a-zA-Z0-9(),. _-]+$/.test(name);
};

export const RuleMatchesFilter = (rule: CalendarRule, { year, month }: { year: number; month?: number }): boolean => {
  if (!rule.startDate) {
    return false;
  }

  const startDayjs = dayjs.unix(rule.startDate).utc(),
    endDayjs = dayjs.unix(rule.endDate ?? rule.startDate).utc();

  if (startDayjs.year() > year || endDayjs.year() < year) {
    return false;
  }

  if (typeof month !== "number") {
    return true;
  }

  if (startDayjs.year() < endDayjs.year()) {
    // rule that spills over to the next year
    return (startDayjs.year() === year && startDayjs.month() <= month) || (endDayjs.year() === year && month <= endDayjs.month());
  }

  // rule contained within a single year
  return startDayjs.month() <= month && month <= endDayjs.month();
};

export const ConvertRuleForDisplay = <T extends CalendarRule>(rule: T, { displayYear }: { displayYear: number }): T[] => {
  if (!rule.startDate) {
    throw Error("Rule must have a start date");
  }

  const formatted = CloneCalendarRule(rule);
  const startDayjs = dayjs.unix(rule.startDate);
  const endDayjs = rule.endDate ? dayjs.unix(rule.endDate) : startDayjs;

  let result: T[] = [formatted];

  if (!rule.recurrence) {
    // one-off rule => do nothing
    return result;
  }

  // recurrence rule:
  if (startDayjs.year() > displayYear) {
    // rule dates should not be modified if it hasn't occurred yet
    return result;
  }

  if (startDayjs.year() === displayYear) {
    // the first occurrence of a recurring rule=> display as-is
  } else if (endDayjs.year() === startDayjs.year()) {
    // recurring rule contained within a single year => adjust dates per filter value
    const yearDiff = endDayjs.year() - startDayjs.year();
    formatted.startDate = startDayjs.year(displayYear).unix();
    formatted.endDate = endDayjs.year(displayYear + yearDiff).unix();
    result = [formatted];
  } else if (endDayjs.year() > startDayjs.year()) {
    // recurring rule that starts in year Y and ends in Y+1 => split the rule

    // occurrence that started in the previous year and spills over to the selected year
    const yearDiff = endDayjs.year() - startDayjs.year();
    formatted.startDate = startDayjs.year(displayYear - yearDiff).unix();
    formatted.endDate = endDayjs.year(displayYear).unix();

    // occurrence that starts in the selected year and spills over to the next
    const untilDayjs = rule.recurrence.until && dayjs.unix(rule.recurrence.until);
    if (!untilDayjs || displayYear <= untilDayjs.year()) {
      const formatted2 = CloneCalendarRule(rule);
      formatted2.startDate = startDayjs.year(displayYear).unix();
      formatted2.endDate = endDayjs.year(displayYear + yearDiff).unix();
      result = [formatted, formatted2];
    }
  }

  // Handle exceptions in recurrence rule if any
  if (rule.recurrence?.exceptions && Array.isArray(rule.recurrence?.exceptions)) {
    for (const exception of rule.recurrence?.exceptions) {
      if (exception.type && exception.type === RecurrenceExceptionType.SKIPPED) {
        // That means this occurrence could be skipped (not displayed)
        // if the recurrenceId match with the startDate of this occurrence
        result = result.filter((formatted) => formatted.startDate !== exception.recurrenceId);
      } else {
        console.log("New RecurrenceExceptionType found, not handled yet: ", exception.type);
      }
    }
  }

  return result;
};
