import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";

export interface Session {
  absolute_timeout: number;
  authentication_provider: string;
  idle_timeout: number;
  ip_address: string;
  is_idle: boolean;
  is_saved?: boolean;
  lastSeen: number;
  loginTime: number;
  mac_address: string;
  type: string;
  user: string;
}

export interface Host {
  bypass_expiry_time: number;
  fingerprint?: string;
  fingerprint_name?: string;
  fz_poll_time: number;
  host: string;
  hostcolumnName?: string;
  hostname: string;
  hw: string;
  interface: string;
  lastSeenTime: number;
  loginTime: number;
  micro_lock: number;
  session?: Session;
  total_active_connections: number;
  total_packet_count: number;
  total_transfer_count: number;
  type?: string;
  user?: string;
  vendor: string;
  version: number;
  formattedLoginTime?: string;
  formattedLoginMethod?: string;
}

type QueryResult = { data: Host[] };

export const isQueryResult = (data: unknown): data is QueryResult => typeof data === "object" && data !== null && "data" in data;

const isData = (data: unknown): data is Host => typeof data === "object" && data !== null && "host" in data;

dayjs.extend(relativeTime);

export const exportLoginTime = (row: unknown): string => {
  if (isData(row)) {
    if (row["session"]) {
      return dayjs.unix(row["session"]["loginTime"]).fromNow();
    }
  }
  return "-";
};

export const exportAuthenticationProvider = (row: unknown): string => {
  if (isData(row)) {
    if (row["session"]) {
      return row["session"]["authentication_provider"];
    }
  }
  return "-";
};

export const exportSaved = (row: unknown): string => {
  if (isData(row)) {
    if (row["session"] && row["session"]["is_saved"] != null) {
      return row["session"]["is_saved"].toString();
    }
  }
  return "-";
};
