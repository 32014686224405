import React, { useEffect } from "react";
import { Td, Tr, Text, useTranslation } from "@familyzone/component-library";
import Api from "../../utils/Api";
import TableBasedPage from "../templates/TableBasedPage";
import CardSpinner from "./UserDashboard/CardSpinner";

interface EventData {
  chrome_id: string;
  day: string;
  deviceid: string;
  key: string;
  time: string;
  user: string;
  details?: string;
}

interface EventDisplayData {
  time: string;
  event: string;
  details: string;
}

export type EventResponse = { data: EventData[] };

const isEventData = (response: unknown): response is EventResponse =>
  typeof response == "object" && response !== null && "data" in response;

const EventsNew: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbData = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Realtime"), url: "/surfwize/device/status/hosts", isActive: false },
    { title: t("Alerts and Events"), isActive: true },
  ];

  function makeDetails(row: EventData) {
    let newDetails = "";
    for (const key in row) {
      if (key === "day" || key === "time" || key === "key" || key === "deviceid") {
        continue;
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        newDetails += `${key} : ${row[key as keyof EventData]}, `;
      }
    }
    const eventRowCopy: EventDisplayData = {
      details: newDetails,
      event: row.key,
      time: row.time,
    };
    return eventRowCopy;
  }

  const columns = [
    {
      headerText: t("Date/Time"),
      columnName: "time",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Event Type"),
      columnName: "event",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Details"),
      columnName: "details",
      sortable: true,
      searchable: true,
    },
  ];

  const [data, setData] = React.useState<EventDisplayData[]>();
  const [loaded, setLoaded] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  const handleLoad = () => {
    Api.get(
      "/edgewize/device/events",
      (result: unknown) => {
        if (isEventData(result)) {
          const eventsArray: EventDisplayData[] = [];
          result.data.forEach((row: EventData) => {
            const tempEvent = makeDetails(row);
            eventsArray.push(tempEvent);
          });
          setData(eventsArray);
        }
      },
      (_: unknown) => {
        setErrorState(true);
      }
    );
    setLoaded(true);
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableRows = (data: EventDisplayData, index: number) => (
    <Tr key={index}>
      <Td>
        <Text>{data.time}</Text>
      </Td>
      <Td>
        <Text>{data.event}</Text>
      </Td>
      <Td>
        <Text>{data.details}</Text>
      </Td>
    </Tr>
  );

  if (errorState) return <Text>Sorry, something went wrong fetching data. Please try again soon.</Text>;
  if (!data) return <CardSpinner />;

  return (
    <TableBasedPage
      title={t("Alerts and Events")}
      breadcrumbs={breadcrumbData}
      columns={columns}
      data={data}
      tableDataMap={tableRows}
      loaded={loaded}
      showSearch={true}
    />
  );
};

export default EventsNew;
