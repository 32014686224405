import { AgentAuth } from "../../stores/AgentAuthStore";
import { headers } from "./ApiHelpers";

const baseUrl = "/config/ajax/authentication/agent";

export const getAgentAuth = async (): Promise<AgentAuth | undefined> => {
  const url = `${baseUrl}`;
  return getAgentAuthApiCall(url);
};

export const saveAgentAuth = async (agentAuth: AgentAuth): Promise<boolean> => {
  const url = `${baseUrl}`;
  return saveAgentAuthApiCall(url, agentAuth);
};

export const getAgentAuthApiCall = async (url: string): Promise<AgentAuth | undefined> => {
  const raw = await fetch(url, {
    headers: headers(),
  });
  const response: { data: unknown } = (await raw.json()) as { data: unknown };

  if (!isAgentAuthResponse(response.data)) {
    console.error("Empty object or Failed to get valid Agent Auth response from server");
    return {} as AgentAuth;
  }
  return response.data;
};

export const saveAgentAuthApiCall = async (url: string, agentAuth: AgentAuth): Promise<boolean> => {
  try {
    const raw = await fetch(url, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify(agentAuth),
    });
    const response: unknown = await raw.json();
    return !!response;
  } catch (e) {
    console.error("Failed to get valid response from server while saving Agent Auth", e);
    return false;
  }
};

export const isAgentAuthResponse = (response: unknown): response is AgentAuth =>
  typeof response === "object" && response !== null && "enabled" in response && "require_hash_key" in response && "key" in response;
