export interface FetchSyncExecutionsResponse {
  result: Result;
}

export interface FetchSyncExecutionsError {
  error: string;
}

interface Result {
  edges: Edge[];
}

interface Edge {
  node: SyncExecution;
}

export interface SyncExecution {
  id: string;
  applianceID: string;
  syncStatuses: SyncStatus[];
  parentExecutionID?: string;
  syncInstanceID?: string;
  childExecutions?: SyncExecution[];
  legacySyncID?: string;
}

export interface SyncStatus {
  id: string;
  status: "Started" | "Completed" | "Failed" | "Fetching Data" | "Data Fetched" | "Partial" | "Pending";
  level: string;
  message: string;
  executionID: string;
  createdAt: string;
  metrics?: Metrics;
  applianceID: string;
}

export interface Metrics {
  studentsMatched?: number;
  studentsUnmatched?: number;
  teachersMatched?: number;
  teachersUnmatched?: number;
  usersUnchanged?: number;
  usersAdded?: number;
  usersDeleted?: number;
  usersUpdated?: number;
  groupsUnchanged?: number;
  groupsAdded?: number;
  groupsDeleted?: number;
  groupsUpdated?: number;
  classroomsUnchanged?: number;
  classroomsAdded?: number;
  classroomsDeleted?: number;
  classroomsUpdated?: number;
  guardiansUnchanged?: number;
  guardiansAdded?: number;
  guardiansDeleted?: number;
  guardiansUpdated?: number;
  userGroupsAdded?: number;
  userGroupsDeleted?: number;
  userClassroomsAdded?: number;
  userClassroomsDeleted?: number;
  userGuardiansAdded?: number;
  userGuardiansDeleted?: number;
  groupSubgroupsAdded?: number;
  groupSubgroupsDeleted?: number;
  groupOwnersAdded?: number;
  groupOwnersDeleted?: number;
}

export const getEndpoint = (syncType: "GOOGLE" | "LDAPS" | "AZURE" | "CLEVER" | "WONDE" | "ONEROSTER", syncId?: string): string => {
  switch (syncType) {
    case "GOOGLE":
      return "/config/ajax/authentication/google/sync/status";
    case "LDAPS":
      return "/config/ajax/authentication/ldap/sync/status";
    case "CLEVER":
      return "/config/ajax/authentication/clever/sync/status";
    case "AZURE":
      return "/config/ajax/authentication/azure/sync/status";
    case "WONDE":
      return `/config/ajax/authentication/wonde/sync/${syncId || ""}/status`;
    case "ONEROSTER":
      return `/config/ajax/authentication/oneroster/sync/${syncId || ""}/status`;
  }
};
