import React from "react";

export default class GuestPrinter extends React.Component {
  componentDidMount() {
    window.print();
  }

  render() {
    return (
      <div className="container">
        <div className="row-fluid">
          <hr />
          <div className="row-fluid marketing">
            <div className="span12">
              <h4>
                Guest User Details for <i>{decodeURI(this.props.params.login)}</i>
              </h4>
            </div>
          </div>
          <div className="row-fluid marketing">
            <div className="span12">
              <table className="newlifetable">
                <tr>
                  <td style={{ width: "100px" }}>Login Name:</td>
                  <td>{decodeURI(this.props.params.login)}</td>
                </tr>
                <tr>
                  <td style={{ width: "100px" }}>Full Name:</td>
                  <td>{decodeURI(this.props.params.name)}</td>
                </tr>
                <tr>
                  <td style={{ width: "100px" }}>Account expiry:</td>
                  <td>{decodeURI(this.props.params.expiry)}</td>
                </tr>
              </table>
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}
