import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, useTranslation, InlineNotification, useToast } from "@familyzone/component-library";

import { FilteringRule, TimePeriod } from "../types";
import PolicyModal from "../PolicyModal";
import PolicyLayout from "../PolicyLayout";

import {
  modifyPolicy,
  deletePolicy,
  getPolicies,
  createPolicy,
  getObjects,
  getFingerprints,
  getTimePeriods,
  getSecurityGroups,
} from "../ApiHelpers";
import ModificationTable from "../ModificationTable";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import PageWithHeader from "../../../components/templates/PageWithHeader";
import { Fingerprint, IpObject, MacObject, SecurityGroup } from "../../../modules/criteria/criteriaTypes";

const ModificationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const isMounted = useIsMounted();

  const breadcrumbs = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t("Content Modification"), isActive: true },
  ];

  const title = t("Content Modification");

  const chromeAgentText = t("Content Modifications only work for devices using our Chrome extension");

  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [editingRule, setEditingRule] = useState<null | FilteringRule>(null);
  const [policyEdit, setPolicyEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState<FilteringRule[]>([]);

  const [ipObjects, setIpObjects] = useState<IpObject[]>([]);
  const [fingerprints, setFingerprints] = useState<Fingerprint[]>([]);
  const [macObjects, setMacObjects] = useState<MacObject[]>([]);
  const [timePeriods, setTimePeriods] = useState<TimePeriod[]>([]);
  const [securityGroups, setSecurityGroups] = useState<SecurityGroup[]>([]);

  const populateObjects = useCallback(async () => {
    try {
      const objectPromise = getObjects();
      const fingerprintPromise = getFingerprints();
      const timePeriodPromise = getTimePeriods();
      const securityGroupPromise = getSecurityGroups();

      setIpObjects((await objectPromise).filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0));
      setFingerprints(await fingerprintPromise);
      setMacObjects((await objectPromise).filter((obj): obj is MacObject => obj.type === 4));
      setTimePeriods(await timePeriodPromise);
      setSecurityGroups(await securityGroupPromise);
    } catch (err) {
      console.error(err);
      errorToast({ title: "Failed to load. Please refresh the page", isClosable: true });
    }
  }, [errorToast]);

  useEffect(() => {
    void populateObjects();
  }, [populateObjects]);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleClosePolicyModal = () => {
    setPolicyModalOpen(false);
  };

  const handleClickCreatePolicy = useCallback(async () => {
    try {
      setLoading(true);
      // Add empty rule
      await createPolicy([]);
      // Get total policies
      if (!isMounted()) return;
      const filteringRules: FilteringRule[] = await getPolicies(true);
      if (!isMounted()) return;
      const newRule = filteringRules[filteringRules.length - 1];
      setRules(filteringRules);
      setEditingRule(newRule);

      setPolicyModalOpen(true);
      setPolicyEdit(false);
    } catch (err) {
      console.error(err);
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
  }, [isMounted]);

  const handleSaveRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        setLoading(true);
        await modifyPolicy(rule);
        if (!isMounted()) return;
        setRules(
          rules.map((r) => {
            if (r.id !== rule.id) return r;
            return rule;
          })
        );
        setPolicyModalOpen(false);
        void populateObjects();
      } catch (err) {
        console.error(err);
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, populateObjects, rules]
  );

  const handleDeleteRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        if (loading) return;
        setLoading(true);
        await deletePolicy(rule.id);
        if (!isMounted()) return;
        setRules(rules.filter((r) => r.id !== rule.id));
      } catch (err) {
        console.error(err);
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, loading, rules]
  );

  const handleEditRule = (rule: FilteringRule) => {
    if (loading) return;
    setEditingRule(rule);
    setPolicyModalOpen(true);
    setPolicyEdit(true);
  };

  const handleToggleRule = useCallback(
    async (rule: FilteringRule) => {
      try {
        if (loading) return;
        const newRule = rule;
        newRule.enabled = !newRule.enabled;

        setLoading(true);
        await modifyPolicy(newRule);
        if (!isMounted()) return;
        setRules(
          rules.map((r) => {
            if (r.id !== rule.id) return r;
            return newRule;
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        if (!isMounted()) return;
        setLoading(false);
      }
    },
    [isMounted, loading, rules]
  );

  const populateRules = useCallback(async () => {
    try {
      setLoading(true);
      const filteringRules: FilteringRule[] = await getPolicies(true);
      setRules(filteringRules);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // The .catch is required because of eslint@typescript-eslint/no-floating-promises
    populateRules().catch(() => "");
  }, [populateRules]);

  const shownRules = useMemo(() => rules.filter((r) => r.name.toLowerCase().includes(search.toLowerCase())), [rules, search]);

  return (
    <PageWithHeader breadcrumbs={breadcrumbs} title={title}>
      <Box width="100%">
        <Box mt="sp24" mx="sp24">
          <InlineNotification status="warning" notificationDescription={chromeAgentText} />
        </Box>
        <PolicyLayout
          loading={loading && rules.length === 0}
          disabled={loading && rules.length !== 0}
          onClickCreatePolicy={() => void handleClickCreatePolicy()}
          showSearchBox
          onChangeSearch={handleChangeSearch}
          onClearSearch={handleClearSearch}
          search={search}
        >
          <ModificationTable
            disabled={loading && rules.length !== 0}
            loading={loading && rules.length === 0}
            rules={shownRules}
            searched={!!search}
            onToggleRule={(rule: FilteringRule) => void handleToggleRule(rule)}
            onDeleteRule={(rule: FilteringRule) => void handleDeleteRule(rule)}
            onEditRule={(rule: FilteringRule) => handleEditRule(rule)}
          />
        </PolicyLayout>
        <PolicyModal
          signatures={[]}
          keywords={[]}
          websiteObjects={[]}
          keywordObjects={[]}
          ipObjects={ipObjects}
          macObjects={macObjects}
          fingerprints={fingerprints}
          timePeriods={timePeriods}
          securityGroups={securityGroups}
          loading={loading}
          open={policyModalOpen}
          rule={editingRule}
          editing={policyEdit}
          onClose={handleClosePolicyModal}
          onSubmit={(rule: FilteringRule) => void handleSaveRule(rule)}
        />
      </Box>
    </PageWithHeader>
  );
};

export default ModificationsPage;
