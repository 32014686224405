import { Stack } from "@chakra-ui/layout";
import { Button, Flex, Modal, Text, useToast, useTranslation } from "@familyzone/component-library";
import React, { FC, useState } from "react";
import { purgeClassrooms } from "../../../utils/api/Classrooms";
import { PurgeType } from "../../../utils/api/Helpers";

const ClassroomsPurgeModal: FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const handleSubmit = (provider: PurgeType) => {
    setLoading(true);

    purgeClassrooms(provider)
      .then(() => {
        setOpen(false);
        successToast({ title: "Success!", description: "We've received the request and it has been sent for processing." });
      })
      .catch(() => {
        setOpen(true);
        errorToast({ title: "Error", description: "We're not able to process the purge at this time. Please try again later." });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onConfirmClose = () => {
    setOpen(false);
    setConfirmOpen(false);
  };

  const onPurgeAll = () => {
    setOpen(false);
    setConfirmOpen(true);
  };

  const onConfirmPurgeAll = () => {
    handleSubmit("ALL");
    onConfirmClose();
  };

  return (
    <>
      <Button variant="primary" onClick={() => setOpen(true)}>
        {t("Purge Classrooms")}
      </Button>
      <Modal
        headerText={t("Purge Classrooms")}
        size="md"
        isOpen={open}
        onClose={() => setOpen(false)}
        contentProps={{ style: { overflow: "visible" } }}
      >
        <Stack spacing="sp12" my="sp12">
          <Text>
            {t(
              "WARNING! You are about to permanently delete classrooms. Please select whether you wish to delete local/imported/all classrooms."
            )}
          </Text>
        </Stack>
        <Stack spacing="sp12" my="sp12">
          <Flex wrap="wrap">
            <Button mt="8" mr="8" variant="danger" onClick={() => handleSubmit("LOCAL")} isLoading={loading} disabled={loading}>
              {t("Purge Local Classrooms")}
            </Button>
            <Button mt="8" mr="8" variant="danger" onClick={() => handleSubmit("IMPORTED")} isLoading={loading} disabled={loading}>
              {t("Purge Imported Classrooms")}
            </Button>
            <Button mt="8" variant="danger" onClick={onPurgeAll} isLoading={loading} disabled={loading}>
              {t("Purge All Classrooms")}
            </Button>
          </Flex>
          <Flex>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Flex>
        </Stack>
      </Modal>
      <Modal headerText={t("Are you sure?")} variant="danger" size="md" isOpen={confirmOpen} onClose={onConfirmClose}>
        <br />
        <Text mb="sp24" style={{ fontWeight: "bold" }}>
          {t(
            "Warning: This action cannot be undone. All classes will be purged. This includes all Imported classes and all teacher created Local and Combined classes in Classwize. A resync will not recreate local and combined classes. Do you wish to proceed?"
          )}
        </Text>
        <Flex>
          <Button variant="ghost" onClick={onConfirmClose} mr="sp8">
            {t("Cancel")}
          </Button>
          <Button mr="sp8" variant="danger" onClick={onConfirmPurgeAll}>
            {t("Confirm")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
export default ClassroomsPurgeModal;
