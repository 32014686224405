import {
  Box,
  Flex,
  Icon,
  Link,
  SearchBox,
  Table,
  TableIconButton,
  TbodyInfiniteScroll,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  useTranslation,
  Modal,
  ModalBody,
} from "@familyzone/component-library";
import React, { useEffect, useState, useCallback, ChangeEvent, useRef } from "react";
import { AuditRecord, AuditScope, EntityType } from "../../../types/Audit";
import { zIndices } from "../../../utils/ZIndexUtil";
import { AuditStore, useAuditStore } from "../../../storez/AuditStore";
import { ResponseError } from "../../../types/Api";
import { toInteger } from "lodash";
import * as _ from "lodash";
import { formatScope, getAuditDiffHtml, titleize } from "./AuditUtils";
import { ScopeSelector, ScopeOption } from "./ScopeSelector";

type AuditDiffModal = {
  showModal: boolean;
  audit: AuditRecord | null;
};

const SCOPE_OPTIONS: ScopeOption[] = [
  {
    scope: AuditScope.Calendar,
    entityTypes: [EntityType.SchoolTime, EntityType.NonSchoolDay],
  },
  { scope: AuditScope.Campaign },
  {
    scope: AuditScope.Community,
    entityTypes: [EntityType.CommunityConfig, EntityType.CommunityFlags, EntityType.StudentConfig],
  },
  { scope: AuditScope.SafetyNet },
];

export const CommunityAudit: React.FC = () => {
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const pageSize = useRef<number>(0);
  const [loading, setLoading] = useState(true);
  const [resetting, setResetting] = useState(true);
  const [selectedScopes, setSelectedScopes] = useState<ScopeOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [cursor, setCursor] = useState<string>("");
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();

  const [auditData, hasMore, fetchAuditData] = useAuditStore(
    useCallback((state: AuditStore) => [state.audits, state.hasMore, state.fetch] as const, [])
  );

  const [auditDiffModal, setAuditDiffModal] = useState<AuditDiffModal>({
    showModal: false,
    audit: null,
  });

  const getLandingScopesFromURL = (): ScopeOption[] => {
    const queryString = window.location.search || window.location.hash.split("?")[1] || "";
    const urlParams = new URLSearchParams(queryString);

    const optionMap: Map<AuditScope, ScopeOption> = new Map();

    urlParams
      .getAll("entityType")
      .map((t) => t as EntityType)
      .forEach((t) => {
        const option = SCOPE_OPTIONS.find((o) => o.entityTypes?.includes(t));
        if (option) {
          const existing = optionMap.get(option.scope);
          if (existing) {
            existing.entityTypes?.push(t);
          } else {
            optionMap.set(option.scope, { scope: option.scope, entityTypes: [t] });
          }
        }
      });

    urlParams
      .getAll("scope")
      .map((s) => s as AuditScope)
      .forEach((s) => {
        const option = SCOPE_OPTIONS.find((o) => o.scope === s);
        if (option && !optionMap.has(s)) {
          optionMap.set(option.scope, { scope: option.scope });
        }
      });

    return Array.from(optionMap.values());
  };

  useEffect(() => {
    const landingScopes = getLandingScopesFromURL();
    setSelectedScopes(landingScopes);

    const pageHeight = window.innerHeight;
    const rowHeight = Math.max(rowRef?.current?.offsetHeight ?? 40, 40);
    pageSize.current = toInteger(pageHeight / rowHeight);

    const searchOptions = {
      scopes: landingScopes.filter((s) => !s.entityTypes).map((o) => o.scope),
      entityTypes: landingScopes.flatMap((o) => o.entityTypes ?? []),
      query: "",
      pageSize: pageSize.current,
    };

    fetchAuditData(searchOptions).then(
      (_auditData) => {
        setLoading(false);
        setResetting(false);
      },
      (err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAuditData]);

  const showErrorToast = (options: { title: string; description: string }): void => {
    errorToast({ ...options, isClosable: true });
  };

  const showFetchErrorToast = (): void => {
    showErrorToast({ title: t("Please try again"), description: t("Failed to load audit records") });
  };

  const formatRecordTimestamp = (record: AuditRecord) => {
    const d = new Date(record.timestamp);

    return (
      <>
        <Text fontSize="md" color="text.title">
          {d.toLocaleTimeString()}
        </Text>
        <Text fontSize="sm" color="text.paragraph.light">
          {d.toLocaleDateString()}
        </Text>
      </>
    );
  };

  const formatRecordScope = (record: AuditRecord) => {
    return (
      <>
        <Text fontSize="md" color="text.title">
          {formatScope(record.entityType)}
        </Text>
        <Text fontSize="sm" color="text.paragraph.light">
          {formatScope(record.scope)}
        </Text>
      </>
    );
  };

  const formatRecordActor = (record: AuditRecord) => {
    if (!record.actor) {
      return <Text color="text.paragraph.light">{t("N/A")}</Text>;
    }

    const [prefix, suffix] = record.actor.split("@", 2);

    // <wbr/> signals an acceptable breaking point when text needs to be wrapped (e.g., very long emails)
    return (
      <Link href={"mailto:" + record.actor}>
        {prefix}
        {suffix && (
          <>
            <wbr />@{suffix}
          </>
        )}
      </Link>
    );
  };

  const formatRecordSummary = (record: AuditRecord) => {
    if (!record.summary) {
      return <Text color="text.paragraph.light">{t("N/A")}</Text>;
    }
    return <Text>{record.summary}</Text>;
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
    setCursor("");
    setResetting(true);
    executeSearch({ query: e.target.value, scopes: selectedScopes, cursor: "" });
  };

  const handleScopeChange = (items: ScopeOption[]) => {
    setSelectedScopes(items);
    setCursor("");
    setResetting(true);
    if (!loading) {
      if (items.length > 0) {
        executeSearch({ query: searchTerm, scopes: items, cursor: "" });
      } else {
        executeSearch({ query: searchTerm, cursor: "" });
      }
    }
  };

  const handleSearchClear = (): void => {
    setSearchTerm("");
    setCursor("");
    setResetting(true);
    executeSearch({ query: "", scopes: selectedScopes, cursor: "" });
  };

  const handleLoadMore = (): void => {
    if (loading || !hasMore) {
      return;
    }

    setLoading(true);

    // get more records after this record ID
    const afterId = auditData[auditData.length - 1].id;

    setCursor(afterId);
    executeSearch({ query: searchTerm, scopes: selectedScopes, cursor: afterId });
  };

  const executeSearch = (options: { query: string; scopes?: ScopeOption[]; cursor?: string }): void => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const searchOptions = {
      scopes: options.scopes?.filter((o) => !o.entityTypes?.length).map((s) => s.scope),
      entityTypes: options.scopes?.flatMap((o) => o.entityTypes ?? []),
      query: options.query,
      cursor: options.cursor ?? cursor,
      pageSize: pageSize.current,
    };

    setSearchTimeout(
      setTimeout(() => {
        setLoading(true);
        fetchAuditData(searchOptions).then(() => {
          setLoading(false);
          setResetting(false);
        }, showFetchErrorToast);
      }, 1000)
    );
  };

  const renderAuditDiffModal = () => {
    let before = "";
    let after = "";

    if (auditDiffModal.audit) {
      [before, after] = getAuditDiffHtml(_.clone(auditDiffModal.audit.before), _.clone(auditDiffModal.audit.after));
    }

    return (
      <Modal
        size="lg"
        headerText={`Changes for ${titleize(auditDiffModal.audit?.scope ?? "")}`}
        isOpen={auditDiffModal.showModal}
        onClose={() => closeAuditDiffModal()}
      >
        <ModalBody>
          <Box>
            {auditDiffModal.audit?.summary && (
              <Box mb="sp8">
                <Text color="text.title" fontWeight="medium">
                  Summary:
                </Text>
                <Text>{auditDiffModal.audit.summary}</Text>
              </Box>
            )}
            <Flex>
              <Box w="50%">
                <Box>
                  <Text color="text.title" fontWeight="medium">
                    Before:
                  </Text>
                </Box>
                <Box p="sp8">
                  <pre dangerouslySetInnerHTML={{ __html: before }}></pre>
                </Box>
              </Box>
              <Box w="50%">
                <Box>
                  <Text color="text.title" fontWeight="medium">
                    After:
                  </Text>
                </Box>
                <Box p="sp8">
                  <pre dangerouslySetInnerHTML={{ __html: after }}></pre>
                </Box>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  const closeAuditDiffModal = () => {
    setAuditDiffModal({
      showModal: false,
      audit: null,
    });
  };

  const openAuditDiffModal = (record: AuditRecord) => {
    setAuditDiffModal({
      showModal: true,
      audit: record,
    });
  };

  return (
    <>
      <Box p="sp24">
        <Flex mb="sp16" gap="sp8" position="sticky" zIndex={zIndices.multiSelect} alignItems="center">
          <SearchBox
            isDisabled={loading}
            value={searchTerm}
            onChange={handleSearchChange}
            onClear={handleSearchClear}
            w="300px"
            p="11px"
            placeholder={t("Search")}
            aria-label={t("Audit Search")}
            data-lpignore={true}
          />

          <ScopeSelector options={SCOPE_OPTIONS} selectedOptions={selectedScopes} onChange={handleScopeChange} disabled={loading} />
        </Flex>

        <Table>
          <Thead position="sticky" top="0" zIndex={zIndices.thead}>
            <Tr>
              <Th columnName="time" headerText={t("Time")} />
              <Th columnName="scope" headerText={t("Scope")} />
              <Th columnName="user" headerText={t("User")} />
              <Th columnName="summary" headerText={t("Summary")} />
              <Th headerText="" />
            </Tr>
          </Thead>
          <TbodyInfiniteScroll
            parentElemId="ComponentWrapper"
            fetchData={() => handleLoadMore()}
            hasMore={hasMore || loading}
            loaded={!resetting || !loading}
            searched={false}
          >
            {auditData?.map((record: AuditRecord) => (
              <Tr key={record.id} fontSize="md" color="text.title">
                <Td whiteSpace="nowrap" maxWidth="156px">
                  {formatRecordTimestamp(record)}
                </Td>
                <Td whiteSpace="nowrap" maxWidth="156px">
                  {formatRecordScope(record)}
                </Td>
                <Td wordBreak="break-word" minWidth="236px" maxWidth="40%">
                  {formatRecordActor(record)}
                </Td>
                <Td>{formatRecordSummary(record)}</Td>
                <Td width="46px">
                  <Flex gap="sp8">
                    <Tooltip
                      variant="dark"
                      placement="left"
                      hasArrow={true}
                      maxWidth="320px"
                      label={t("Show Changes")}
                      zIndex={zIndices.tooltip}
                    >
                      <Box>
                        <TableIconButton
                          disabled={loading}
                          icon={<Icon icon="fa-file" variant="solid" color="text.paragraph.light" />}
                          onClick={() => openAuditDiffModal(record)}
                          aria-label="Show Changes"
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </TbodyInfiniteScroll>
        </Table>
      </Box>
      {renderAuditDiffModal()}
    </>
  );
};
