import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const timeoutTypeText = (type: number): string => {
  switch (type) {
    case 0:
      return "Idle Timeout";
    case 1:
      return "Elapsed Timeout";
    case 2:
      return "Absolute Timeout";
    default:
      return "";
  }
};

export interface Timeout {
  mon: boolean;
  tues: boolean;
  wed: boolean;
  thur: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;

  groups: string[];
  id: string;
  networks: unknown;
  provider: string;
  timeout: number;
  type: number;
}

export const timeoutValueText = (timeout: Timeout): string => {
  if (timeout.type === 2) {
    let ret = "";
    ret += `At ${timeout.timeout} on `;

    const days = [];
    if (timeout.mon) days.push("Monday");
    if (timeout.tues) days.push("Tuesday");
    if (timeout.wed) days.push("Wednesday");
    if (timeout.thur) days.push("Thursday");
    if (timeout.fri) days.push("Friday");
    if (timeout.sat) days.push("Saturday");
    if (timeout.sun) days.push("Sunday");
    ret += days.join(", ");

    return ret;
  } else {
    return dayjs.duration(timeout.timeout, "seconds").humanize();
  }
};
