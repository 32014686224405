import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import Api from "../../utils/Api";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  MultiSearchSelector,
  Option,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";
import { Keyword } from "../../pages/filtering/types";
import useIsMounted from "../../utils/hooks/useIsMounted";
import { isValidEmail } from "../../utils/Validation";
import { getSignaturesAndKeywords } from "../../pages/filtering/ApiHelpers";
import { SearchableEmailReport } from "../../pages/filtering/reports";
import { convertStringArrayToOptions } from "../GroupSearch/GroupSearchHelper";

export interface Props {
  visible: boolean;
  handleSave: (rule: SearchableEmailReport) => void;
  handleClose: () => void;
  rule: SearchableEmailReport;
}

export const AddEditEmailReportModal: FC<Props> = ({ handleClose, handleSave, rule: propsRule, visible }) => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState(propsRule);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [keywords, setKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    setRule(propsRule);
  }, [propsRule]);

  const populateKeywords = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getSignaturesAndKeywords();
      setKeywords(data.keywords.filter((k) => !k.deprecated));
    } catch (err) {
      errorToast({
        title: t("Failed to load. Please refresh the page"),
        isClosable: true,
      });
    } finally {
      if (!isMounted()) return;
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToast, isMounted]);

  useEffect(() => {
    populateKeywords().catch(() => "");
  }, [populateKeywords]);

  const keywordOptions: Option[] = useMemo(() => {
    return keywords.map((keyword) => ({
      label: `${t("Searches")} ${keyword.name}`,
      value: keyword.id,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  const selectedKeywords = useMemo(
    () => keywordOptions.filter((key) => rule?.selected_red_flags.includes(`${key.value}`)) || false,
    [keywordOptions, rule]
  );

  const handleSubmit = () => {
    const hasErrors = !validateEmail(rule.email);
    if (hasErrors) return;

    handleSave(rule);
    handleClose();
  };

  const handleSendTestMail = () => {
    const hasErrors = !validateEmail(rule.email);
    if (hasErrors) return;

    setSendingEmail(true);

    Api.post(
      "/surfwize/ajax/reports/test",
      rule,
      () => {
        setSendingEmail(false);
        successToast({
          title: `${t("A test email has been sent to")} ${rule.email}`,
          isClosable: true,
        });
      },
      () => {
        setSendingEmail(false);
        errorToast({
          title: t("Failed to send the test email. Please try again"),
          isClosable: true,
        });
      }
    );
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, email: event.target.value });
  };

  const handleSelectUser = (user: Option | null) => {
    setRule({ ...rule, username: `${user?.value || ""}` });
  };

  const handleSelectGroups = (groups: Option[]) => {
    setRule({ ...rule, groups: [...new Set(groups.map((group) => String(group.name)))] });
  };

  const handleChangeRisks = (risks: Option[]) =>
    setRule({
      ...rule,
      selected_red_flags: risks.map((o) => `${o.value}`),
    });

  const handleChangeWeeklyWelfareReport = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, weekly_welfare_report: event.target.checked });
  };

  const handleChangeWeeklyReport = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, weekly_report: event.target.checked });
  };

  const handleChangeFilteringAlerts = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, filtering_alerts: event.target.checked });
  };

  const handleChangeUserAddedAlerts = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, teacher_added_user: event.target.checked });
  };

  const handleChangeDailyAlertDigest = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, daily_filtering_alerts_digest: event.target.checked });
  };

  const handleChangeRedFlags = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, red_flags: event.target.checked });
  };

  const handleChangeDisableRateLimiting = (event: ChangeEvent<HTMLInputElement>) => {
    setRule({ ...rule, disable_rate_limiting: event.target.checked });
  };

  const validateEmail = (email: string) => {
    if (!email || email.trim().length === 0) {
      errorToast({
        title: t("Email is required"),
        isClosable: true,
      });
      return false;
    }

    if (!isValidEmail(email)) {
      errorToast({
        title: t("Email is invalid"),
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const title = propsRule?.email ? "Edit Report/Notification" : "Add Report/Notification";

  return (
    <Modal isOpen={visible} onClose={handleClose} headerText={t(title)} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box
        my="sp24"
        py="sp4"
        overflowY="visible"
        overflowX="auto"
        css={{
          msOverflowStyle: "none" /* Internet Explorer 10+ */,
          scrollbarWidth: "none" /* Firefox */,
          "&::-webkit-scrollbar": {
            display: "none" /* Safari and Chrome */,
          },
        }}
      >
        <Table style={{ tableLayout: "fixed" }}>
          <Tbody>
            <Tr>
              <Td w="30%">
                <Text fontSize="medium" color="text.title">
                  {t("Recipient Email")}
                </Text>
              </Td>
              <Td>
                <Input value={rule?.email} placeholder={t("Enter Email")} onChange={handleChangeEmail} />
              </Td>
            </Tr>
            <Tr>
              <Td w="30%">
                <Text fontSize="medium" color="text.title">
                  {t("Groups")}
                </Text>
              </Td>
              <Td>
                <MultiGroupSearchSelector
                  preselected={rule?.groups ? convertStringArrayToOptions(rule?.groups) : []}
                  onChangeGroups={handleSelectGroups}
                  useLegacyId={true}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w="30%">
                <Text fontSize="medium" color="text.title">
                  {t("User")}
                </Text>
              </Td>
              <Td>
                <UserSearchSelector
                  prefill={rule?.username ?? ""}
                  handleChange={handleSelectUser}
                  placeholderText={t("Enter username, email or name")}
                />
              </Td>
            </Tr>
            <Tr>
              <Td w="30%">
                <Text fontSize="medium" color="text.title">
                  {t("Realtime Reports")}
                </Text>
              </Td>
              <Td>
                <Flex mb="sp8">
                  <Checkbox isChecked={rule?.teacher_added_user} onChange={handleChangeUserAddedAlerts}>
                    {t("Teacher Modified Class Users")}
                  </Checkbox>
                </Flex>
                <Flex mb="sp8">
                  <Checkbox isChecked={rule?.filtering_alerts} onChange={handleChangeFilteringAlerts}>
                    {t("Filtering Alerts")}
                  </Checkbox>
                </Flex>
                <Flex>
                  <Checkbox isChecked={rule?.red_flags} onChange={handleChangeRedFlags}>
                    {t("Red Flags")}
                  </Checkbox>
                </Flex>
                <>
                  {rule?.red_flags && (
                    <Box mt="sp8">
                      <MultiSearchSelector
                        placeholder={t("Select Red Flags")}
                        options={keywordOptions}
                        selected={selectedKeywords}
                        onChange={handleChangeRisks}
                      />
                    </Box>
                  )}
                  {(rule?.filtering_alerts || rule?.red_flags || rule?.teacher_added_user) && (
                    <Flex mt="sp8">
                      <Checkbox isChecked={rule?.disable_rate_limiting} onChange={handleChangeDisableRateLimiting}>
                        <Text color="error.plainText">{t("Disable Rate Limiting")}</Text>
                      </Checkbox>
                    </Flex>
                  )}
                </>
              </Td>
            </Tr>
            <Tr>
              <Td w="30%">
                <Text fontSize="medium" color="text.title">
                  {t("Periodic Reports")}
                </Text>
              </Td>
              <Td>
                <Flex>
                  <Flex direction="column">
                    <Flex mb="sp8">
                      <Checkbox isChecked={rule?.weekly_report} onChange={handleChangeWeeklyReport}>
                        {t("Weekly Report")}
                      </Checkbox>
                    </Flex>
                    <Flex mb="sp8">
                      <Checkbox isChecked={rule?.weekly_welfare_report} onChange={handleChangeWeeklyWelfareReport}>
                        {t("Weekly Wellbeing Report")}
                      </Checkbox>
                    </Flex>
                    <Flex mb="sp8">
                      <Checkbox isChecked={rule?.daily_filtering_alerts_digest} onChange={handleChangeDailyAlertDigest}>
                        {t("Daily Alert Digest")}
                      </Checkbox>
                    </Flex>
                  </Flex>
                  <Flex direction="column" alignSelf="end" ml="auto">
                    <Button
                      variant="secondary"
                      mr="sp8"
                      onClick={handleSendTestMail}
                      isLoading={loading || sendingEmail}
                      disabled={!rule?.weekly_report && !rule?.weekly_welfare_report && !rule?.daily_filtering_alerts_digest}
                      aria-label="test"
                      data-testid="test-button"
                    >
                      {t("Test")}
                    </Button>
                  </Flex>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Flex>
        <Button variant="primary" onClick={handleSubmit} isLoading={loading} aria-label="save" data-testid="save-button">
          {t("Save")}
        </Button>
      </Flex>
    </Modal>
  );
};
