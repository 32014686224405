import React from "react";
import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria, IpObject } from "../criteriaTypes";

export interface IPv4AliasCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
  ipObjects: IpObject[];
}

const IPv4AliasCriteria: React.FC<IPv4AliasCriteriaProps> = ({ criteria, disabled, onChange, ipObjects }) => {
  const { t } = useTranslation();

  if (!Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  // Map the list of users to a selector-usable array of label/value pairs
  const ipOptions: Option[] = ipObjects.map((i) => ({ label: i.name, value: i.id }));

  // Take the criteria conditions and convert them to label/value pairs
  const selectedIpOptions: Option[] = criteria.conditions
    .map((id) => ipOptions.find((o) => o.value === id))
    .filter((b): b is Option => !!b);

  const handleChangeSourceIps = (options: Option[]) => {
    onChange({ ...criteria, conditions: [...options.map((o: Option) => ipObjects.find((u) => u.id === o.value)?.id || "")] });
  };

  return (
    <MultiSearchSelector
      disabled={disabled}
      placeholder={t("Select IP Objects")}
      name="ip object selector"
      options={ipOptions}
      selected={selectedIpOptions}
      onChange={handleChangeSourceIps}
    />
  );
};

export default IPv4AliasCriteria;
