import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { CampaignConfig } from "../types/Campaign";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";

export const campaignConfigURL = (campaignKey: string): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/campaigns/${campaignKey}/config`;
};

interface SaveOptions {
  overrideVersionConflict: boolean;
}

export interface CampaignConfigStore {
  config?: CampaignConfig;
  fetch: (campaignKey: string) => Promise<CampaignConfig>;
  save: (config: CampaignConfig, options?: SaveOptions) => Promise<CampaignConfig>;
  reset: () => void;
}

export const useCampaignConfigStore = create<CampaignConfigStore>((set) => ({
  config: undefined,

  fetch: async (campaignKey: string): Promise<CampaignConfig> => {
    let config,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(campaignConfigURL(campaignKey)).then(
      (response: CampaignConfig) => (config = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200 && statusCode !== 404) {
      throw new ResponseError("Failed to fetch campaign configuration", statusCode);
    }

    if (!config) {
      config = {
        applianceId: useSessionStore.getState().getDevice().id,
        campaignKey: campaignKey,
        criteria: {},
        enabled: false,
        version: 0,
      } as CampaignConfig;
    }

    set({ config });

    return config;
  },

  save: async (config: CampaignConfig, options?: SaveOptions): Promise<CampaignConfig> => {
    let saved,
      statusCode = 200;

    if (options && options.overrideVersionConflict) {
      // eslint-disable-next-line
      await Api.getAsync(campaignConfigURL(config.campaignKey)).then(
        (response: CampaignConfig) => (config.version = response.version),
        (reason: jqXHR) => (statusCode = reason?.status)
      );

      if (statusCode !== 200) {
        throw new ResponseError("Failed to save campaign configuration", statusCode);
      }
    }

    if (config.version > 0) {
      // eslint-disable-next-line
      await Api.putAsync(campaignConfigURL(config.campaignKey), config).then(
        (response: CampaignConfig) => (saved = response),
        (reason: jqXHR) => (statusCode = reason?.status)
      );
    } else {
      // eslint-disable-next-line
      await Api.postAsync(campaignConfigURL(config.campaignKey), config).then(
        () => {
          saved = config;
          saved.version = 1;
        },
        (reason: jqXHR) => {
          statusCode = reason?.status;

          // jQuery.ajax treats non-json 2xx responses as errors :/
          if (statusCode === 201) {
            saved = config;
            saved.version = 1;
          }
        }
      );
    }

    if (!saved || (statusCode !== 200 && statusCode !== 201)) {
      throw new ResponseError("Failed to save campaign configuration", statusCode);
    }

    set({ config: saved });

    return saved;
  },

  reset: () => {
    set({ config: undefined });
  },
}));
