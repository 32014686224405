import { create } from "zustand";
import { getUsersByUsername } from "../utils/api/Users";

if (!process.env.REACT_APP_UMS_MAX_QUERY_SIZE) {
  throw new Error("REACT_APP_UMS_MAX_QUERY_SIZE env var must be defined");
}

export const UMS_MAX_QUERY_SIZE = parseInt(process.env.REACT_APP_UMS_MAX_QUERY_SIZE);

export type UserStoreUser = {
  username: string;
  id: string;
  firstName?: string;
  lastName?: string;
};

export interface UserStore {
  users: Map<string, UserStoreUser>;
  setUsers: (users: Map<string, UserStoreUser>) => void;
  // Use getUsers rather than accessing users directly.
  // It will return any information already in the store, and
  // automatically fetch any missing info from the SM API.
  getUsers: (usernames: string[]) => Promise<UserStoreUser[]>;
  // GetUsersAsMap is a wrapper around getUsers that returns the results mapped by username instead of as an array.
  getUsersAsMap: (usernames: string[]) => Promise<Map<string, UserStoreUser>>;
  getUser: (username: string) => Promise<UserStoreUser | undefined>;
  reset: () => void;
}

export const useUserStore = create<UserStore>((set, get) => ({
  users: new Map(),
  setUsers: (users) => set(() => ({ users: users })),
  getUsers: async (usernames): Promise<UserStoreUser[]> => {
    const usersMap = get().users;

    const { found, missing } = findUsersInMap(usernames, usersMap);
    if (missing.length === 0) {
      return found;
    }

    const promises = [];

    for (let from = 0; from < missing.length; from += UMS_MAX_QUERY_SIZE) {
      const to = Math.min(from + UMS_MAX_QUERY_SIZE, missing.length);
      promises.push(getUsersByUsername(missing.slice(from, to)));
    }

    try {
      const results = await Promise.all(promises);

      results.forEach((users) => {
        found.push(...users);
        users.forEach((user) => usersMap.set(user.username, user));
      });

      set({ users: usersMap });
    } catch (error) {
      console.error(error);
      return found;
    }

    return found;
  },
  getUsersAsMap: async (usernames): Promise<Map<string, UserStoreUser>> => {
    const users = await get().getUsers(usernames);
    const usersMap = new Map();
    users.forEach((user) => usersMap.set(user.username, user));
    return usersMap;
  },
  getUser: async (username): Promise<UserStoreUser | undefined> => {
    const users = await get().getUsers([username]);
    return users.length === 1 ? users[0] : undefined;
  },

  reset: () => {
    set({ users: new Map() });
  },
}));

type findUsersInMapResult = {
  found: UserStoreUser[];
  missing: string[];
};

// Returns the users found in the existing map, and an array of usernames that weren't found
const findUsersInMap = (usernames: string[], users: Map<string, UserStoreUser>): findUsersInMapResult => {
  const result: findUsersInMapResult = {
    found: [],
    missing: [],
  };

  usernames.forEach((username) => {
    const user = users.get(username);
    if (user) {
      result.found.push(user);
    } else {
      result.missing.push(username);
    }
  });

  return result;
};
