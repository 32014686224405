import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import FlexibleTable from "../../modules/FlexibleTable";
import SignatureStore from "../../stores/SignatureStore";
import SignatureActions from "../../actions/SignatureActions";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";

export default class ReportingViolationUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      policy: {},
      loaded: false,
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    SignatureStore.listen(this.onChange);

    setTimeout(function () {
      SignatureActions.fetch();
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);

    this.handle_load();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.handle_load();
  };

  handle_load = () => {
    let self = this;
    Api.get_analytics(
      "/surfwize/analytics/filtering/user/" + self.props.params.user + "/overview?filter_policy=" + self.props.params.policyId,
      function (result) {
        self.setState({
          data: result["data"],
        });
        Api.get_analytics("/surfwize/device/ajax/policy/" + self.props.params.policyId, function (result) {
          self.setState({
            policy: result["policy"],
            loaded: true,
          });
        });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  policy_name = () => {
    return this.state.policy.name ? this.state.policy.name : "";
  };

  prepare_columns = () => {
    let self = this;
    let columns = [
      {
        title: "Date",
        data: function (row) {
          return (
            <Link
              to={
                "/surfwize/reporting/users/" +
                self.props.params.user +
                "/timeline?date=" +
                row["day"] +
                "&filter_policyid=" +
                self.props.params.policyId
              }
            >
              {row["day"]}
            </Link>
          );
        },
        search: FlexibleTable.search__single_string_field("day"),
        sortable: FlexibleTable.sortable__single_string_field("day"),
        onclick: undefined,
      },
      {
        title: "Hits",
        data: function (row) {
          return row["hits"];
        },
        sortable: FlexibleTable.sortable__single_numeric_field("hits"),
        onclick: undefined,
      },
    ];

    return columns;
  };

  render__content = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <div className="row-fluid">
            <div className="span12">
              <FlexibleTable
                columns={this.prepare_columns()}
                data={this.state.data}
                sort_column={0}
                sort_asc={true}
                loaded={this.state.loaded}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-rocket" title={this.policy_name() + " Policy Violations by " + this.props.params.user}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
