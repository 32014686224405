import React, { ReactChild } from "react";
import PageWithHeader from "./PageWithHeader";
import SortSearchTable, { SortSearchTableProps, TableData } from "./SortSearchTable";
import { Box, Flex } from "@familyzone/component-library";

export interface TableWithChartsPageProps<Data> extends SortSearchTableProps<Data> {
  title?: string;
  breadcrumbs?: {
    title: string;
    isActive: boolean;
  }[];
  children?: ReactChild;
  charts?: ReactChild;
}

const TableWithChartsPage = <Data extends TableData<Data>>({
  title,
  breadcrumbs,
  loaded,
  columns,
  data,
  tableDataMap,
  showSearch = true,
  children = <></>,
  tableTopMargin = "sp24",
  childrenInTableHeader = <></>,
  overflowAutoToAllowDropdownSelectToShow = false,
  charts = <></>,
  defaultSort,
}: TableWithChartsPageProps<Data>): JSX.Element => {
  return (
    <PageWithHeader title={title} breadcrumbs={breadcrumbs}>
      <>
        {children}
        <Flex direction="row">
          <Box width="80%">
            <SortSearchTable
              showSearch={showSearch}
              tableTopMargin={tableTopMargin}
              childrenInTableHeader={childrenInTableHeader}
              overflowAutoToAllowDropdownSelectToShow={overflowAutoToAllowDropdownSelectToShow}
              loaded={loaded}
              columns={columns}
              data={data}
              tableDataMap={tableDataMap}
              defaultSort={defaultSort}
            />
          </Box>
          <Flex flexBasis="20%" flexDir="column">
            <Box m="sp24" mt={tableTopMargin} ml="sp2" p="sp24" background="neutrals.0" borderRadius="6px">
              {charts}
            </Box>
          </Flex>
        </Flex>
      </>
    </PageWithHeader>
  );
};

export default TableWithChartsPage;
