import { Box, Flex, Text } from "@familyzone/component-library";
import React, { useState } from "react";
import Link from "../../Link";
import { RedFlagIconType, RedFlagPillIcon } from "./RedFlagPillIcon";
import { RedFlagPillTooltip, RedFlagPillTooltipProps } from "./RedFlagPillTooltip";

export type RedFlagSeverity = "low" | "medium" | "high";

export interface RedFlagPillProps {
  severity: RedFlagSeverity;
  icon?: RedFlagIconType;
  text: string;
  link?: string;
  testid?: string;
  tooltip?: RedFlagPillTooltipProps;
}

export const innerColors: Record<RedFlagSeverity, string> = {
  low: "text.paragraph.regular",
  medium: "text.title",
  high: "neutrals.0",
};

const borderColors: Record<RedFlagSeverity, string> = {
  low: "accent.red.300",
  medium: "accent.red.200",
  high: "accent.red.400",
};

const backgroundColors: Record<RedFlagSeverity, string> = {
  low: "neutrals.0",
  medium: "accent.red.200",
  high: "accent.red.400",
};

const pillBody = (severity: RedFlagSeverity, text: string, icon?: RedFlagIconType) => {
  return (
    <Flex>
      {icon && (
        <Flex mt="sp2" mr="sp4">
          <RedFlagPillIcon type={icon} severity={severity} />
        </Flex>
      )}
      <Text fontWeight="medium" color={innerColors[severity]}>
        {text}
      </Text>
    </Flex>
  );
};

export const RedFlagPill = ({ severity, icon, text, link, testid, tooltip }: RedFlagPillProps): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);
  const cursor = link ? "pointer" : "default";
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      mt="sp8"
      mr="sp8"
      whiteSpace="nowrap"
      cursor={cursor}
      data-testid={testid}
      borderRadius="4px"
      py="6px"
      px="sp8"
      border="1px"
      h="34px"
      borderColor={borderColors[severity]}
      bgColor={backgroundColors[severity]}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && tooltip && (
        <Flex position="relative" justifyContent="center">
          <Box position="absolute" p="10px" bgColor="#3e3e3c" color="neutrals.0" borderRadius="4px" bottom="100%" mb="20px">
            <RedFlagPillTooltip hits={tooltip.hits} time={tooltip.time} />
          </Box>
        </Flex>
      )}
      {link ? (
        <Link whiteSpace="nowrap" to={link}>
          {pillBody(severity, text, icon)}
        </Link>
      ) : (
        pillBody(severity, text, icon)
      )}
    </Flex>
  );
};
