import { Box, Button, ButtonTd, Flex, Icon, Tag, Td, Text, Tooltip, Tr, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import Api from "../../../utils/Api";
import { formatData, getConnectionsUri } from "./ActiveConnectionsHelpers";
import TableBasedPage from "../../templates/TableBasedPage";
import { CSVExportButton } from "../../../modules/ExportReport";
import { ConnectionInfoModalNew } from "../../debug/ConnectionInfoModalNew";
import {
  ActiveConnectionsApiResponse,
  ActiveConnectionsFormattedData as FormattedData,
  ActiveConnectionsProps,
} from "./ActiveConnectionsTypes";
import Link from "../../../components/Link";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const ActiveConnectionsNew = (props: ActiveConnectionsProps): JSX.Element => {
  const { t } = useTranslation();

  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Realtime"), url: "/surfwize/device/status/hosts", isActive: false },
    { title: t("Connections"), isActive: true },
  ];

  const columns = [
    {
      headerText: t("Time"),
      columnName: "time",
      sortable: true,
    },
    {
      headerText: t("Verdict"),
      columnName: "allowed",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Source"),
      columnName: "source",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Destination"),
      columnName: "destination",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Protocol"),
      columnName: "protocol",
      sortable: true,
      exportable: true,
    },
    {
      headerText: t("User"),
      columnName: "user",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Application"),
      columnName: "applicationTagName",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("State"),
      columnName: "currentState",
      sortable: true,
    },
  ];

  const [data, setData] = useState<FormattedData[]>([]);
  const [startTime, setStartTime] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailConnection, setDetailConnection] = useState<unknown>();

  interface wrappedApiResponse {
    data: ActiveConnectionsApiResponse[];
  }

  const isConnectionsApiResponse = (response: unknown): response is wrappedApiResponse =>
    typeof response === "object" && response !== null && "data" in response;

  const isActiveConnectionsObject = (data: wrappedApiResponse["data"]): data is ActiveConnectionsApiResponse[] => {
    return (
      (Array.isArray(data) && data.length === 0) || data.some((item) => typeof item === "object" && "time" in item && "protocol" in item)
    );
  };

  useEffect(() => {
    Api.get(getConnectionsUri(props, startTime), (result: unknown) => {
      if (isConnectionsApiResponse(result) && isActiveConnectionsObject(result.data)) {
        const newData = formatData(result.data);
        setData(newData);
      }
      setLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startTime <= 0) {
      return;
    }
    const interval = setInterval(() => {
      Api.get(getConnectionsUri(props, startTime), (result: unknown) => {
        if (isConnectionsApiResponse(result) && isActiveConnectionsObject(result.data)) {
          const newData = formatData(result.data);
          data.unshift(...newData);
          setData([...data]);
          setStartTime(dayjs().unix());
        }
      });
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props, startTime]);

  const handleStartLiveTrace = () => {
    setStartTime(dayjs().unix());
  };

  const handleStopLiveTrace = () => {
    setStartTime(0);
  };

  const handleOpenDetails = (connection: ActiveConnectionsApiResponse) => {
    setDetailConnection(connection);
    setDetailVisible(true);
  };

  const handleClose = () => {
    setDetailVisible(false);
  };

  const aboveTableChildren = (): JSX.Element => {
    const userDashboardLink = `/surfwize/reporting/users/${props.user ?? ""}`;
    const showTag = props.user || props.mac || props.ip;
    const label = props.user ?? props.mac ?? props.ip ?? "";
    const icon = props.user ? "fa-user-circle" : props.mac ? "fa-desktop" : "fa-globe";
    return (
      <Box m="sp12" p="sp12" mb="0" pb="0">
        <Flex direction="row" alignItems="center">
          {showTag && (
            <Box mr="sp8">
              <Tag tagLabel={label} variant="dark" tagLeftIcon={`fa ${icon}`} />
            </Box>
          )}
          <Flex flex="1" />
          {props.user && (
            <Link to={userDashboardLink}>
              <Button size="sm" aria-label={t("Go to their User Dashboard")} variant="outline">
                {t("Go to their User Dashboard")}
              </Button>
            </Link>
          )}
        </Flex>
      </Box>
    );
  };

  const tableHeaderChildren = (): JSX.Element => {
    return (
      <>
        <ConnectionInfoModalNew connection={detailConnection} visible={detailVisible} handleClose={handleClose} />
        {startTime === 0 && (
          <Tooltip label={t("View live data, updated every 5 seconds")} placement="top" variant="dark">
            <Button
              aria-label={t("Start Live Trace")}
              onClick={handleStartLiveTrace}
              variant="secondary"
              leftIcon={<Icon boxSize="12px" icon="fa fa-play" />}
              mr="sp16"
            >
              {t("Start Live Trace")}
            </Button>
          </Tooltip>
        )}
        {startTime !== 0 && (
          <Button
            aria-label={t("Stop Live Trace")}
            onClick={handleStopLiveTrace}
            variant="secondary"
            leftIcon={<Icon boxSize="12px" icon="fa fa-stop" />}
            mr="sp16"
          >
            {t("Stop Live Trace")}
          </Button>
        )}
        <CSVExportButton columns={columns} formattedData={data} />
      </>
    );
  };

  const tableDataMap = (data: FormattedData, index: number): JSX.Element => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{dayjs.unix(data.time).fromNow()}</Text>
      </Td>
      <Td>
        <Text fontSize="md">
          {data.allowed && <Tag tagLabel={t("Allowed")} size="compact" variant="subtle" />}
          {!data.allowed && <Tag tagLabel={t("Blocked")} size="compact" variant="red" />}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">{data.source}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{data.destination}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{data.protocol}</Text>
      </Td>
      <Td>
        <Link to={`/surfwize/reporting/users/${data.user}`}>
          <Text color="brand.500" fontSize="md">
            {data.user}
          </Text>
        </Link>
      </Td>
      <Td>
        <Text fontSize="md">{data.applicationTagName}</Text>
      </Td>
      <Td>
        <Text fontSize="md" textTransform="capitalize">
          {`${t(data.currentState)}`.toLowerCase()}
        </Text>
      </Td>
      <Flex data-testid="connectionDetailsButton">
        <ButtonTd
          buttonIconName="fa fa-arrow-right"
          onClick={() => {
            handleOpenDetails(data.rawData);
          }}
        />
      </Flex>
    </Tr>
  );

  return (
    <TableBasedPage
      title={t("Connections")}
      breadcrumbs={breadcrumbs}
      loaded={loaded}
      columns={columns}
      data={data}
      tableDataMap={tableDataMap}
      children={aboveTableChildren()}
      childrenInTableHeader={tableHeaderChildren()}
    />
  );
};
