import CountryStore from "../stores/CountryStore";
import CountryActions from "../actions/CountryActions";
import React from "react";
import Criteria from "./Criteria";
import SearchableSelect from "../modules/SearchableSelect";

export default class Country extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = { country: "" };
    }

    this.state = {
      criteria: props.criteria,
      countries: CountryStore.getCountries(),
    };

    /* {type: "geoip", negate: false, conditions: {country: ""}} */
  }

  componentDidMount() {
    CountryStore.listen(this.onChange);

    setTimeout(() => {
      CountryActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    CountryStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      countries: CountryStore.getCountries(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = { country: "" };
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_ChangeCountry = (event) => {
    this.state.criteria.conditions.country = event.value;
    this.props.bubbleMethod(this.state.criteria);
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let x of this.state.countries) {
      ret.push([x, x]);
    }

    return ret;
  };

  render() {
    return (
      <Criteria label="Country" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-select">
          <SearchableSelect
            clearable={false}
            value={this.state.criteria.conditions.country}
            onChange={this.handle_ChangeCountry}
            valueDataMap={this.get_SelectionMap()}
          ></SearchableSelect>
        </div>
      </Criteria>
    );
  }
}
