import React from "react";
import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria, Fingerprint } from "../criteriaTypes";

export interface FingerprintCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
  fingerprints: Fingerprint[];
}

const FingerprintCriteria: React.FC<FingerprintCriteriaProps> = ({ criteria, disabled, onChange, fingerprints }) => {
  const { t } = useTranslation();

  if (!Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  // Map the list of users to a selector-usable array of label/value pairs
  const deviceOptions: Option[] = fingerprints.map((i) => ({ label: i.name, value: i.id }));

  // Take the criteria conditions and convert them to label/value pairs
  const selectedDeviceOptions: Option[] = criteria.conditions
    .map((id) => deviceOptions.find((o) => o.value === id))
    .filter((b): b is Option => !!b);

  const handleChangeSourceDevices = (options: Option[]) => {
    onChange({ ...criteria, conditions: [...options.map((o: Option) => fingerprints.find((u) => u.id === o.value)?.id || "")] });
  };

  return (
    <MultiSearchSelector
      disabled={disabled}
      placeholder={t("Select Device Type")}
      name="device selector"
      options={deviceOptions}
      selected={selectedDeviceOptions}
      onChange={handleChangeSourceDevices}
    />
  );
};

export default FingerprintCriteria;
