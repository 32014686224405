import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Box, Button, useTranslation, useToast, InlineNotification, Checkbox } from "@familyzone/component-library";
import CardBasedPage from "../../../templates/CardBasedPage";
import { SectionHeading } from "../../../shared/SectionHeading";
import { useFilterLicensesStore } from "../../../../storez/FilterLicensesStore";
import {
  ContentAwareLicenseStatus,
  createContentAwareLicense,
  deactivateContentAwareLicense,
  deleteContentAwareLicense,
  getContentAwareLicense,
  reactivateContentAwareLicense,
} from "../../../../utils/api/ContentAware";
import ConfirmationModal from "../../../shared/ConfirmationModal";

const FilterLicensesPage: FunctionComponent = () => {
  const filterLicensesStore = useFilterLicensesStore();

  const { t } = useTranslation();
  const title = t("Filtering Licenses");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Licenses"), url: "/config/licenses", isActive: false },
    { title: t("Filter"), isActive: true },
  ];

  const { successToast, errorToast, infoToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const [contentAwareCheckboxIsTicked, setContentAwareCheckboxIsTicked] = useState<boolean>(false);

  const [contentAwareLicenseKey, setContentAwareLicenseKey] = useState<string>("");
  const [contentAwareLicenseStatus, setContentAwareLicenseStatus] = useState<ContentAwareLicenseStatus>(
    ContentAwareLicenseStatus.Suspended
  );

  const [confirmDisableModalOpen, setConfirmDisableModalOpen] = useState<boolean>(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);

  function handleToggleContentAwareEnabled(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.checked) {
      setConfirmDisableModalOpen(true);
      return;
    } else {
      setContentAwareCheckboxIsTicked(true);
    }
  }

  const fetchFilterLicenses = useCallback(() => {
    setLoading(true);

    getContentAwareLicense()
      .then((license) => {
        setContentAwareCheckboxIsTicked(license.status === ContentAwareLicenseStatus.Active);
        setContentAwareLicenseStatus(license.status);
        setContentAwareLicenseKey(license.key);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
          // No license is a valid response, don't show an error toast for this.
          if (errorMessage === "License not found for appliance") return;
        }
        errorToast({
          title: t("Couldn't fetch Content Aware license"),
          description: "Please raise a dev esco if the issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [errorToast, t]);

  useEffect(() => {
    fetchFilterLicenses();
  }, [fetchFilterLicenses]);

  function handleDeleteLicense(): void {
    deleteContentAwareLicense()
      .then(() => {
        setContentAwareLicenseKey("");
        setContentAwareLicenseStatus(ContentAwareLicenseStatus.Suspended);
        setContentAwareCheckboxIsTicked(false);

        successToast({
          title: t("License key deleted"),
          description: t("Successfully deleted third-party license key for Content Aware functionality for this appliance."),
          position: "bottom-right",
        });

        // Update state so the navbar shows/hides the menu item
        filterLicensesStore.setContentAwareEnabled(false);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't delete license key"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDeactivateLicense(): void {
    deactivateContentAwareLicense()
      .then((license) => {
        setContentAwareLicenseKey(license.key);
        setContentAwareLicenseStatus(license.status);

        successToast({
          title: t("License deactivated"),
          description: t(
            "Successfully deactivated this appliance's Content Aware license, while maintaing their third-party license key for it."
          ),
          position: "bottom-right",
        });

        // Update state so the navbar shows/hides the menu item
        filterLicensesStore.setContentAwareEnabled(license.status === ContentAwareLicenseStatus.Active);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't deactivate license"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleReactivateLicense(): void {
    reactivateContentAwareLicense()
      .then((license) => {
        setContentAwareLicenseKey(license.key);
        setContentAwareLicenseStatus(license.status);

        successToast({
          title: t("License reactivated"),
          description: t("Successfully reactivated this appliance's Content Aware license."),
          position: "bottom-right",
        });

        // Update state so the navbar shows/hides the menu item
        filterLicensesStore.setContentAwareEnabled(license.status === ContentAwareLicenseStatus.Active);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't deactivate license"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleLicenseAlreadySuspended(): void {
    infoToast({
      title: t("License already suspended"),
      description: t("This appliance already has a suspended Content Aware license."),
      position: "bottom-right",
    });
    setSaving(false);
  }

  function handleGenerateLicense(): void {
    createContentAwareLicense()
      .then((license) => {
        setContentAwareLicenseKey(license.key);
        setContentAwareLicenseStatus(license.status);

        successToast({
          title: t("Key Successfully Generated"),
          description: t("You can now configure the Content Aware settings in the Filtering tab in School Manager."),
          position: "bottom-right",
        });

        // Update state so the navbar shows/hides the menu item
        filterLicensesStore.setContentAwareEnabled(license.status === ContentAwareLicenseStatus.Active);
      })
      .catch((err) => {
        let errorMessage;
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        errorToast({
          title: t("Couldn't generate license key"),
          description: errorMessage || "Unexpected issue, please raise a dev esco if this issue persists.",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleLicenseAlreadyActive(): void {
    infoToast({
      title: t("License already active"),
      description: t("This appliance already has an active Content Aware license."),
      position: "bottom-right",
    });
    setSaving(false);
  }

  function handleNoLicenseToDeactivate(): void {
    infoToast({
      title: t("No license to deactivate"),
      description: t("This appliance doesn't have a Content Aware license to deactivate."),
      position: "bottom-right",
    });
    setSaving(false);
  }

  function handleClickSaveButton(): void {
    setSaving(true);

    const licenseExists = contentAwareLicenseKey.length > 0;
    const licenseActive = contentAwareLicenseStatus === ContentAwareLicenseStatus.Active;

    if (contentAwareCheckboxIsTicked) {
      if (!licenseExists) {
        handleGenerateLicense();
        return;
      }
      if (licenseActive) {
        handleLicenseAlreadyActive();
        return;
      }
      handleReactivateLicense();
      return;
    } else {
      if (!licenseExists) {
        handleNoLicenseToDeactivate();
        return;
      }
      if (licenseActive) {
        handleDeactivateLicense();
        return;
      }
      handleLicenseAlreadySuspended();
      return;
    }
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        <InlineNotification notificationDescription={t("This page is only displayed to Support Admins.")} status="info" />

        <Box my={10}></Box>

        <SectionHeading
          title={t("Activate Content Aware License")}
          tooltip={t(
            "Activating this license will allow this school to use Content Aware Filtering. Only activate this if the school is paying for this product."
          )}
        />

        <Box p={10}>
          <Text mb={20}>In order to use Content Aware config filter please tick the box below to activate the license.</Text>
          <Checkbox isChecked={contentAwareCheckboxIsTicked} isDisabled={loading || saving} onChange={handleToggleContentAwareEnabled}>
            {t("Activate Content Aware License")}
          </Checkbox>
          {contentAwareLicenseKey && (
            <Box p={10}>
              <Text my={10}>Activation Key</Text>
              <Flex alignItems={"center"}>
                <Text borderBottom={1} borderColor={"neutrals.40"} borderStyle={"solid"} color={"neutrals.300"} height={30} px={10} mr={10}>
                  {contentAwareLicenseKey}
                </Text>
                <Button variant="primary" ml={10} onClick={() => setConfirmDeleteModalOpen(true)} disabled={saving || loading}>
                  Remove key
                </Button>
              </Flex>
            </Box>
          )}
          <ConfirmationModal
            open={confirmDisableModalOpen}
            onClose={() => setConfirmDisableModalOpen(false)}
            onConfirm={() => {
              setContentAwareCheckboxIsTicked(false);
              setConfirmDisableModalOpen(false);
            }}
            heading="Warning"
            description="Deselecting this check box will disable Content Aware configuration settings."
            confirmationCheckbox="I understand that Content Aware will be disabled."
            CTALabel="Disable Content Aware"
          />
          <ConfirmationModal
            open={confirmDeleteModalOpen}
            onClose={() => setConfirmDeleteModalOpen(false)}
            onConfirm={() => {
              handleDeleteLicense();
              setConfirmDeleteModalOpen(false);
            }}
            heading="Warning"
            description="Removing the license key will result in a deactivation of
Content aware and its configuration settings."
            confirmationCheckbox="I understand that the license key will be removed."
            CTALabel="Remove License"
          />
        </Box>
      </Box>
      <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
        <Button variant="primary" disabled={saving || loading} onClick={handleClickSaveButton} data-testid="save-filter-licenses-button">
          {saving ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default FilterLicensesPage;
