import { ipv4, interfacesData } from "./InterfacesNew";

export const displayStatus = (status: boolean): string => {
  return status ? "Up" : "Down";
};

export const displayType = (bridge: boolean, vlan: boolean, lacp: boolean): string => {
  if (bridge) return "Bridge";
  if (vlan) return "Vlan";
  if (lacp) return "Bonded Link";
  return "Physical Link";
};

const joinActiveAddress = (ipv4: ipv4[]): string => {
  const ipAddresses: string[] = [];
  ipv4.forEach((item) => {
    ipAddresses.push(item.ip + "/" + item.mask);
  });
  return ipAddresses.join();
};

export const displayActiveAddress = (row: interfacesData): string => {
  if (row.dhcp) {
    if (row.dhcp_lease && row.dhcp_lease.state === "DHCP_TRANSACTION_FINISHED") {
      return joinActiveAddress(row.ipv4);
    }
    return "";
  }
  if (row.interface === "lo") {
    return joinActiveAddress(row.ipv4);
  }
  if (row.ipv4_configured) {
    return joinActiveAddress(row.ipv4_configured);
  }
  return "";
};
