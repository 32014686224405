import React, { ChangeEvent, ReactElement } from "react";

interface TableSearchWithButtonProps {
  searchBoxLabel: string;
  searchExpression: string;
  searchChangeHandler: (arg: ChangeEvent<HTMLInputElement>) => void;
  apiSearchHandler: () => string;
  handleKeyDown: () => string;
}

export const TableSearchWithButton = (props: TableSearchWithButtonProps): ReactElement => {
  return (
    <span className="table-search-box-with-button">
      <input
        placeholder={props.searchBoxLabel}
        value={props.searchExpression}
        onChange={props.searchChangeHandler}
        data-cy="flexTableSearch"
        onKeyDown={props.handleKeyDown}
      />
      <button
        disabled={props.searchExpression.length > 1 ? false : true}
        className={`search-button ${props.searchExpression.length > 1 ? "active" : ""}`}
        onClick={props.apiSearchHandler}
        data-testid="searchBtn"
      >
        <i className="fa fa-search" aria-hidden="true" />
      </button>
    </span>
  );
};
