import React from "react";
import { cy } from "./CypressUtil";

export default class NuSingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: this.findSelectedItem(this.props.selectedItem),
      filteredItems: [],
      filter: "",
      openSelection: false,
      focus: false,
    };
  }

  findSelectedItem = (selectedValue) => {
    let selectedItem = undefined;
    if (selectedValue !== undefined) {
      for (const item of this.props.allItems) {
        if (this.props.valueLens(item) === selectedValue) {
          selectedItem = item;
        }
      }
    }
    return selectedItem;
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        openSelection: false,
        filter: "",
        filteredItems: [],
      });
    }
  };

  handleSelect = (item) => {
    if (item.ok !== undefined) {
      if (item.ok === true) {
        this.setState({
          openSelection: false,
          filter: "",
          filteredItems: [],
          selectedItem: item,
        });

        this.props.onChange(item, this.state.filter);
      }
    } else {
      this.setState({
        openSelection: false,
        filter: "",
        filteredItems: [],
        selectedItem: item,
      });

      this.props.onChange(item, this.state.filter);
    }
  };

  handleChange = (event) => {
    this.setState({
      filter: event.target.value,
      openSelection: true,
      filteredItems: this.prepareFilteredItems(event.target.value),
    });
  };

  handleFocus = () => {
    this.setState({
      filter: "",
      openSelection: true,
      filteredItems: this.prepareFilteredItems(""),
    });
  };

  handleClick = () => {
    this.setState({
      filter: "",
      openSelection: !this.state.openSelection,
      filteredItems: this.prepareFilteredItems(""),
    });
  };

  handleEsc = (event) => {
    if (event.keyCode === 27 || event.keyCode === 9) {
      this.setState({
        openSelection: false,
        filter: "",
        filteredItems: [],
      });
      this.refs.NuSelectInput.blur();
    }
  };

  findValue = () => {
    if (this.state.filter) {
      return this.state.filter;
    }
    if (this.state.selectedItem && !this.state.openSelection) {
      return this.props.displayTextLens(this.state.selectedItem);
    }
    return "";
  };

  prepareFilteredItems = (filter) => {
    const filteredItems = [];
    for (const item of this.props.allItems) {
      if (item.dynamic === true) {
        const transformed_value = item.transform_filter(filter);
        if (item.should_show(transformed_value)) {
          filteredItems.push({
            text: item.render_text(transformed_value),
            value: "website",
            ok: item.is_ok(transformed_value),
          });
        }
      } else {
        if (filter.length <= 2 && this.props.displayTextLens(item).toLowerCase().startsWith(filter.toLowerCase())) {
          filteredItems.push(item);
        } else if (filter.length > 2 && this.props.displayTextLens(item).toLowerCase().includes(filter.toLowerCase())) {
          filteredItems.push(item);
        } else if (filter.length === 0) {
          filteredItems.push(item);
        }
      }
    }

    return filteredItems.slice(0, 100);
  };

  getDropdownWidth = () => {
    if (this.wrapperRef === undefined) {
      return 0;
    }

    return this.wrapperRef.getBoundingClientRect().width;
  };

  renderItems = () => {
    const preparedItems = [];
    for (const item of this.state.filteredItems) {
      preparedItems.push(
        <div
          key={this.props.valueLens(item)}
          className="nuselect_search_select_item"
          onClick={() => this.handleSelect(item)}
          data-testid="nu-select-choice"
        >
          {this.props.displayTextLens(item)}
        </div>
      );
    }
    if (this.state.filteredItems.length > 99) {
      preparedItems.push(
        <div key="truncated_items_info" className="nuselect_search_select_item_truncate_info">
          Too many values to show them all. Please use search to narrow down results.
        </div>
      );
    }
    return preparedItems;
  };

  renderSelection = () => {
    if (this.state.openSelection) {
      return (
        <div className="nuselect_search_selection" style={{ width: "" + this.getDropdownWidth() + "px" }}>
          <div className="nuselect_search_select_list" data-cy={cy(this.props["data-cy"], "choices")}>
            {this.renderItems()}
          </div>
        </div>
      );
    }
  };

  labelStateClassName = () => {
    let className = "nuselect_select_label";
    if (!this.state.openSelection && this.state.selectedItem) {
      className += " show";
    }
    return className;
  };

  searchFieldStateClassName = () => {
    let className = "nuselect_search_field";
    if (this.props.invalid) {
      className += " invalid";
    }
    return className;
  };

  render() {
    return (
      <div>
        {this.props.title ? <div className="nuselect_selected_title">{this.props.title}</div> : null}
        <div ref={this.setWrapperRef} className={this.state.openSelection ? "nuselect_search_open nuselect_search" : "nuselect_search"}>
          {this.props.selectLabel ? <label className={this.labelStateClassName()}>{this.props.selectLabel}</label> : null}
          <div className={this.searchFieldStateClassName()}>
            <div className="nuselect_search_field_input">
              <input
                data-cy={cy(this.props["data-cy"], "search")}
                ref="NuSelectInput"
                type="text"
                value={this.findValue()}
                onChange={this.handleChange}
                placeholder={this.props.searchPlaceholder}
                onFocus={this.handleFocus}
                onKeyDown={this.handleEsc}
                onBlur={this.handleBlur}
              />
            </div>
            <i className="fa fa-angle-down nuselect_search_field_search_icon_zone" onClick={this.handleClick} />
          </div>
          {this.renderSelection()}
        </div>
      </div>
    );
  }
}
