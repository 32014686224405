import React, { useEffect, useState } from "react";
import { Box, DateTimePicker, Flex, SearchBox, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import Api from "../../utils/Api";
import { Link } from "react-router";
import { AnalyticsUsers, AnalyticsUsersResponse } from "../../types/ApiResponses";
import TableBasedPage from "../templates/TableBasedPage";
import { TableColumn } from "../../types/table";
import { formatBytes } from "../../utils/StringUtil";
import CardSpinner from "./UserDashboard/CardSpinner";
import { CSVExportButton } from "../../modules/ExportReport";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import dayjs from "dayjs";
import { dayjsDateToApiDate, shouldUseUSDateFormat } from "../../utils/DateTimeUtil";
import { zIndices } from "../../utils/ZIndexUtil";

const isUserData = (response: unknown): response is AnalyticsUsersResponse =>
  typeof response === "object" && response !== null && "data" in response;

const ReportingTopUsers: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbData = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Active Users"), isActive: true },
  ];

  const columns: TableColumn[] = [];
  columns.push({ headerText: t("Username"), columnName: "user", sortable: true, searchable: true, exportable: true });
  ["Name", "Upload", "Download", "Transfer"].forEach((column) => {
    columns.push({
      headerText: t(column),
      columnName: column.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
      exportable: true,
    });
  });
  columns.push({ headerText: t("Operations"), columnName: "operations" });

  const [data, setData] = React.useState<AnalyticsUsers[]>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [errorState, setErrorState] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().endOf("day"));
  const [useUSDateFormat, setUseUSDateFormat] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-implied-eval, @typescript-eslint/no-unsafe-argument
    setTimeout(GlobalDatePickerVisibilityActions.hideGlobalDatePicker, 0);
    handleLoad(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoad = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    Api.get_analytics(
      "/surfwize/analytics/users",
      (result: unknown) => {
        if (isUserData(result)) setData(result.data);
      },
      (_: unknown) => {
        setErrorState(true);
      },
      {
        dateOverride: dayjsDateToApiDate(start, end),
      }
    );
    setLoaded(true);
  };

  const searchWithQuery = (user_search_string: string) => {
    setLoaded(false);
    const order_by_direction = "ASC";
    const order_by_field = "user";

    Api.get_analyticsAsync(
      "/surfwize/analytics/users?user_search_string=" +
        user_search_string +
        "&order_by_direction=" +
        order_by_direction +
        "&order_by_field=" +
        order_by_field
    )
      .then((result) => {
        if (isUserData(result)) {
          setData(result.data);
          setLoaded(true);
        } else setErrorState(true);
      })
      .catch((_) => setErrorState(true));
  };

  const searchBox = () => {
    const canSearch = () => {
      return loaded && searchInput.length > 1;
    };

    return (
      <Box
        onKeyDown={(e) => {
          if (e.key === "Enter" && canSearch()) {
            searchWithQuery(searchInput);
          }
        }}
      >
        <SearchBox
          variant="clickSearch"
          placeholder={t("Search")}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          value={searchInput}
          onSearchClick={() => searchWithQuery(searchInput)}
          onClear={() => {
            searchWithQuery("");
            setSearchInput("");
          }}
          searchButtonDisabled={!canSearch()}
        />
      </Box>
    );
  };

  useEffect(() => {
    const checkDateFormat = async () => {
      setUseUSDateFormat(await shouldUseUSDateFormat());
    };
    void checkDateFormat();
  }, []);

  const tableHeader = (data: AnalyticsUsers[]) => {
    return (
      <>
        <Box width="536px" pr="sp8">
          {searchBox()}
        </Box>
        <Box zIndex={zIndices.datepicker}>
          <DateTimePicker
            initialStart={startDate}
            initialEnd={endDate}
            useUSDateFormat={useUSDateFormat}
            onDateChange={(start, end) => {
              const sd = dayjs(start);
              const ed = dayjs(end);
              setStartDate(sd);
              setEndDate(ed);
              handleLoad(sd, ed);
            }}
            direction="right"
            maxSpan={{ value: 12, unit: "months" }}
          />
        </Box>
        <Flex flex="1" />
        <Box>{<CSVExportButton columns={columns} formattedData={data} />}</Box>
      </>
    );
  };

  const tableRows = (tableData: AnalyticsUsers, index: number) => (
    <Tr key={index}>
      <Td>
        <Link to={`/surfwize/reporting/users/${tableData.user}`}>
          <Text color="brand.500" fontSize="md">
            {tableData.user}
          </Text>
        </Link>
      </Td>
      <Td>
        <Text fontSize="md">{tableData.name}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{formatBytes(tableData.upload)}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{formatBytes(tableData.download)}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{formatBytes(tableData.transfer)}</Text>
      </Td>
      <Td>
        <Link to={`/surfwize/device/status/connections?user=${tableData.user}`}>
          <Text color="brand.500" fontSize="md">
            Realtime Trace
          </Text>
        </Link>
      </Td>
    </Tr>
  );

  if (errorState) return <Text>Sorry, something went wrong fetching data. Please try again soon.</Text>;
  if (!data) return <CardSpinner />;
  return (
    <TableBasedPage
      title={t("Active Users")}
      breadcrumbs={breadcrumbData}
      columns={columns}
      data={data}
      tableDataMap={tableRows}
      childrenInTableHeader={tableHeader(data)}
      loaded={loaded}
      showSearch={false}
    />
  );
};

export default ReportingTopUsers;
