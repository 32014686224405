import { Box, Modal, Table, Td, Tr, useTranslation, Text, TextInput, Flex, Button } from "@familyzone/component-library";
import React from "react";
import { useEffect, useState } from "react";
import { NamedEntry } from "../../../types/Objects";

interface AddNewObjectEntryProps {
  open: boolean;
  existingEntry: NamedEntry | null;
  onSave: (entry: NamedEntry) => void;
  loaded: boolean;
  onClose: () => void;
}

const AddNewObjectEntry: React.FC<AddNewObjectEntryProps> = ({
  open,
  existingEntry,
  onSave = () => "",
  loaded,
  onClose = () => "",
}: AddNewObjectEntryProps) => {
  const { t } = useTranslation();
  const [entryName, setEntryName] = useState("");
  const [entryDescription, setEntryDescription] = useState("");
  const [entryValue, setEntryValue] = useState("");

  const headerText = existingEntry?.click_action === "edit" ? t("Edit Entry") : t("Create new Entry");
  const createEntryText = t("Save Entry");

  useEffect(() => {
    if (existingEntry?.click_action === "edit") {
      setEntryName(existingEntry.name);
      setEntryDescription(existingEntry.desc);
      setEntryValue(existingEntry.value);
    } else {
      setEntryName("");
      setEntryDescription("");
      setEntryValue("");
    }
  }, [existingEntry, open]);

  const handleEntryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntryName(e.target.value);
  };

  const handleEntryDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntryDescription(e.target.value);
  };

  const handleEntryValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntryValue(e.target.value);
  };

  const handleClickSubmit = () => {
    if (!loaded) {
      return;
    }

    if (existingEntry?.click_action === "edit") {
      onSave({
        ...existingEntry,
        name: entryName,
        desc: entryDescription,
        value: entryValue,
      });
      return;
    } else {
      onSave({
        name: entryName,
        desc: entryDescription,
        value: entryValue,
        click_action: "add",
        invalidValueMsg: null,
        id: "",
      });
    }
  };

  const handleonClose = () => {
    setEntryName("");
    setEntryDescription("");
    setEntryValue("");
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={handleonClose} headerText={headerText} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box my="sp24" overflowY="auto" py="sp4" overflow={"visible"}>
        <Table>
          <Tr>
            <Td>
              <Text fontSize="medium" color="text.title">
                Name:
              </Text>
            </Td>
            <Td>
              <TextInput
                aria-label="name"
                placeholder={t("Enter a Name")}
                value={entryName}
                onChange={handleEntryNameChange}
                isDisabled={!loaded}
              />
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="medium" color="text.title">
                Description:
              </Text>
            </Td>
            <Td>
              <TextInput
                aria-label="description"
                placeholder={t("Enter a Description")}
                value={entryDescription}
                onChange={handleEntryDescriptionChange}
                isDisabled={!loaded}
              />
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="medium" color="text.title">
                Entry:
              </Text>
            </Td>
            <Td>
              <TextInput
                aria-label="value"
                placeholder={t("Enter a Value")}
                value={entryValue}
                onChange={handleEntryValueChange}
                isDisabled={!loaded}
              />
            </Td>
          </Tr>
        </Table>
      </Box>
      <Flex>
        <Button
          variant="primary"
          onClick={handleClickSubmit}
          isLoading={!loaded}
          disabled={entryValue.length < 2}
          aria-label="save"
          data-testid="save-policy-button"
        >
          {createEntryText}
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddNewObjectEntry;
