import React, { FC, useState, useEffect, useCallback } from "react";
import TableBasedPage from "../../components/templates/TableBasedPage";
import { Text, Td, Tr, useTranslation, Flex, Icon, TableButton, Link } from "@familyzone/component-library";
import { Link as ReactLink } from "react-router";
import Api from "../../utils/Api";
import { Keyword } from "../../pages/filtering/types";
import { getSignaturesAndKeywords } from "../../pages/filtering/ApiHelpers";
import { UserProfileLinkWithIcon } from "../../utils/UserProfileLinkWithIcon";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { TableColumn } from "../../types/table";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import { ApiDates, getDateWindowRange } from "../../utils/DateTimeUtil";

export const titleText = "Search History";
export const columnTexts = ["Time", "Search Expression", "Categories", "Search Engine", "View in Context"];
export const breadcrumbsTexts = ["CyberSafety", "Search"];
export const viewText = "View";

type SearchHistory = {
  categories: string[];
  date: string;
  domain: string;
  ip: string;
  query: string;
  uri: string;
};

type TableSearchHistory = SearchHistory & {
  categoryText: string;
};

interface Props {
  location: {
    query: ApiDates;
  };
  params: {
    search: string;
    user: string;
  };
}

const ReportingSearchUserAudit: FC<Props> = (props) => {
  const { t } = useTranslation();
  const filterStore = useDateRangeFilterStore();

  const [entries, setEntries] = useState<TableSearchHistory[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [keywords, setKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    if (props.location.query.startDate && props.location.query.endDate) {
      filterStore.updateDateFilter({
        startDate: props.location.query.startDate,
        endDate: props.location.query.endDate,
        startTime: props.location.query.startTime ? props.location.query.startTime : "00:00:00",
        endTime: props.location.query.endTime ? props.location.query.endTime : "23:59:59",
        relativeKey: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.query]);

  const getKeywordName = useCallback(
    (id: string): string => {
      const keyword = keywords.find((keyword) => keyword.id === id);
      return t(keyword ? keyword.name : "");
    },
    [keywords, t]
  );

  const populateKeywords = useCallback(async () => {
    try {
      const res = await getSignaturesAndKeywords();
      setKeywords(res.keywords);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    void populateKeywords();
  }, [populateKeywords]);

  const populateEntries = useCallback(() => {
    if (keywords.length === 0) {
      return;
    }

    setLoaded(false);
    Api.post_analytics(
      "/sufwize/ajax/search/user",
      { user: props.params.user },
      (result: { result: SearchHistory[] }) => {
        setEntries(
          result["result"].map((entry) => ({
            ...entry,
            categoryText: getKeywordName(entry.categories[0]),
          }))
        );
        setLoaded(true);
      },
      (err: unknown) => {
        console.log(err);
        setLoaded(true);
      }
    );
  }, [getKeywordName, props.params.user, keywords]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
    const unsubFilterStore = useDateRangeFilterStore.subscribe(populateEntries);
    populateEntries();

    return () => {
      unsubFilterStore();
    };
  }, [populateEntries]);

  const breadcrumbs = [
    { title: t(breadcrumbsTexts[0]), url: "/cybersafety/dashboard", isActive: false },
    { title: t(breadcrumbsTexts[1]), url: "/cybersafety/dashboard", isActive: false },
    { title: props.params.user, isActive: true },
  ];

  const columns: TableColumn[] = [
    {
      headerText: t(columnTexts[0]),
      columnName: "date",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t(columnTexts[1]),
      columnName: "query",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnTexts[2]),
      columnName: "categoryText",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnTexts[3]),
      columnName: "domain",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnTexts[4]),
      columnName: "",
      sortable: false,
      searchable: false,
      alignment: "center",
    },
  ];

  const dataMap = (row: TableSearchHistory, index: number) => {
    const [startDate, endDate] = getDateWindowRange(row.date, 30);
    const href = `/surfwize/reporting/users/${props.params.user}/journey?highlight=${encodeURIComponent(
      row.query
    )}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    return (
      <Tr key={index}>
        <Td>
          <Text fontSize="md">{row.date}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{row.query}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{row.categoryText}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{row.domain}</Text>
        </Td>
        <Td>
          <Flex justifyContent="center">
            <Link as={ReactLink} to={href} className="view-in-context-link">
              <TableButton
                leftIcon={<Icon icon="fa-eye" variant="solid" color="text.paragraph.light" data-testid="edit-icon" />}
                mr="sp8"
                data-testid="edit-icon-button"
              >
                {t(viewText)}
              </TableButton>
            </Link>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <TableBasedPage
      title={
        <Flex direction="row" alignItems="center">
          <Text fontFamily="heading" fontSize="xxl" color="text.title" mb="xl" pb="md" role="heading">
            {t(titleText)}
          </Text>
          <Text fontFamily="heading" fontSize="xxl" color="text.title" mb="xl" pb="md" role="heading">
            <UserProfileLinkWithIcon username={props.params.user} />
          </Text>
        </Flex>
      }
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={entries}
      tableDataMap={dataMap}
      loaded={loaded}
      searchInput={props.params.search}
    />
  );
};

export default ReportingSearchUserAudit;
