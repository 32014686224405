import React from "react";

class FlexibleTableLabelWithImage extends React.PureComponent {
  render() {
    return (
      <span className="no-wrap">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width="24" height="24" src={this.props.imgUrl} />
        <span className="label">{this.props.label}</span>
      </span>
    );
  }
}

export default FlexibleTableLabelWithImage;
