import alt from "../alt";

class ApiActions {
  incrementRequestCounter(request_id) {
    return request_id;
  }

  decrementRequestCounter(request_id) {
    return request_id;
  }

  resetRequestCounter() {
    return "nothing";
  }
}

export default alt.createActions(ApiActions);
