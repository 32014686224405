import React from "react";
import { useDateRangeFilterStore } from "../storez/DateRangeFilterStore";
import { ABTester, Checkbox, withTranslation } from "@familyzone/component-library";

class NoiseFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noise: useDateRangeFilterStore.getState().getIncludeNoise(),
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onChange = () => {
    this.setState({
      noise: useDateRangeFilterStore.getState().getIncludeNoise(),
    });
  };

  handle_ChangeNoise = (event) => {
    useDateRangeFilterStore.getState().updateNoiseFilter(event.target.checked);
  };

  render() {
    const { t } = this.props;
    return (
      <span>
        {this.props.children}
        {!this.props.hideNoiseFilter && (
          <ABTester
            optionB={
              <span id="noise_filter">
                <input
                  type="checkbox"
                  id="noise_filter_checkbox"
                  name="noise_filter_checkbox"
                  checked={this.state.noise}
                  onChange={this.handle_ChangeNoise}
                  hidden={true}
                />
                <span hidden>Noise</span>
              </span>
            }
            optionA={
              <Checkbox isChecked={this.state.noise} onChange={this.handle_ChangeNoise}>
                {t("Noise")}
              </Checkbox>
            }
            isOptionB={true}
          />
        )}
      </span>
    );
  }
}

export default withTranslation()(NoiseFilter);
