//! DEPRECATED: Use TableCentricPageButton instead

import styled from "styled-components";

const SmallTableButton = styled.button`
  &:hover {
    text-decoration: underline;
  }

  background-color: #666;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  border-color: #333;
  order: 3;
`;
export default SmallTableButton;
