export default class Filterhelper {
  static operate(component) {
    return this.consolidateFilters(component.props.params, component.props.location);
  }

  static consolidateFilters(params, location) {
    let filter_user = undefined;
    let blocked = undefined;
    let hits = undefined;
    let filter_group = undefined;
    let date = undefined;
    let filter_type = undefined;
    let filter_website = undefined;
    let filter_policyid = undefined;

    if (params) {
      if (params.user) {
        filter_user = params.user;
      }
    }

    if (location) {
      if (location.query.filter_user) {
        filter_user = location.query.filter_user;
      }

      if (location.query.blocked) {
        blocked = location.query.blocked;
      }

      if (location.query.hits) {
        hits = location.query.hits;
      }

      if (location.query.filter_group) {
        filter_group = location.query.filter_group;
      }

      if (location.query.date) {
        date = location.query.date;
      }

      if (location.query.filter_type) {
        filter_type = location.query.filter_type;
      }

      if (location.query.filter_website) {
        filter_website = location.query.filter_website;
      }
      if (location.query.filter_policyid) {
        filter_policyid = location.query.filter_policyid;
      }
    }
    return {
      filter_user: filter_user,
      blocked: blocked,
      hits: hits,
      filter_group: filter_group,
      date: date,
      filter_type: filter_type,
      filter_website: filter_website,
      filter_policyid: filter_policyid,
    };
  }

  static serialise(filters) {
    let ret = "";
    if (filters.filter_user) {
      ret += "&filter_user=" + filters.filter_user;
    }
    if (filters.filter_group) {
      ret += "&filter_group=" + filters.filter_group;
    }

    if (filters.blocked && (filters.blocked === "true" || filters.blocked === "false")) {
      ret += "&blocked=" + filters.blocked;
    }

    if (filters.hits && filters.hits === "true") {
      ret += "&hits=" + filters.hits;
    }

    if (filters.filter_website) {
      ret += "&filter_website=" + filters.filter_website;
    }

    if (filters.filter_type) {
      ret += "&filter_type=" + filters.filter_type;
    }

    if (filters.filter_policyid) {
      ret += "&filter_policyid=" + filters.filter_policyid;
    }

    return ret;
  }
}
