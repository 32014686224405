import { Flex, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { Box, Button, Checkbox, Input, Text, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria } from "../../modules/criteria/criteriaTypes";
import Api from "../../utils/Api";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

interface RadiusConfig {
  enabled: boolean;
  exclude: FilteringSourceCriteria[];
  proxies: string[];
  proxy_enabled: boolean;
  secret: string;
}

const RadiusConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [radiusConfig, setRadiusConfig] = React.useState<RadiusConfig>({
    enabled: false,
    exclude: [],
    proxies: [],
    proxy_enabled: false,
    secret: "",
  });

  React.useEffect(() => {
    setLoading(true);
    Api.get("/config/ajax/authentication/radius", function (response: { result: RadiusConfig }) {
      if (response.result.proxies === undefined) response.result.proxies = [];
      setRadiusConfig(response.result);
    });
    setLoading(false);
  }, []);

  const handleSave = () => {
    setSaving(true);
    Api.post(
      "/config/ajax/authentication/radius",
      radiusConfig,
      function () {
        successToast({ title: t("Success"), description: t("RADIUS configuration saved") });
        setSaving(false);
      },
      function () {
        errorToast({ title: t("Error"), description: t("Error saving RADIUS configuration") });
        setSaving(false);
      }
    );
  };

  const excludeOptions: Partial<CriteriaTypes> = {
    "source.user": "User",
    group: "Group",
  };

  if (loading) return <CardSpinner />;

  return (
    <Box m="sp24" width="100%">
      <Box backgroundColor="white">
        <Flex backgroundColor="white" padding="sp12" direction="column">
          <Stack spacing="sp12" width="80%">
            <Text fontSize="xl">{t("RADIUS")}</Text>
            <FormControl>
              <Checkbox
                isChecked={radiusConfig.enabled}
                onChange={() => setRadiusConfig({ ...radiusConfig, enabled: !radiusConfig.enabled })}
              >
                {t("Enabled")}
              </Checkbox>
            </FormControl>
            {radiusConfig.enabled && (
              <>
                <FormControl>
                  <FormLabel>{t("Shared Secret")}</FormLabel>
                  <Input
                    value={radiusConfig.secret}
                    onChange={(e) => setRadiusConfig({ ...radiusConfig, secret: e.target.value })}
                    placeholder="Secret"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Forward")}</FormLabel>
                  <Input
                    value={radiusConfig.proxies.join()}
                    onChange={(e) => setRadiusConfig({ ...radiusConfig, proxies: e.target.value.split(",") })}
                    placeholder="Forwarding Destination"
                  />
                  <Box my="sp8" />
                  <Checkbox
                    isChecked={radiusConfig.proxy_enabled}
                    onChange={() => setRadiusConfig({ ...radiusConfig, proxy_enabled: !radiusConfig.proxy_enabled })}
                  >
                    {t("Enable Forward")}
                  </Checkbox>
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Exclude")}</FormLabel>
                  <CriteriaSelector
                    customOptions={excludeOptions}
                    selected={radiusConfig.exclude}
                    errors={[]}
                    onChange={(selected) => setRadiusConfig({ ...radiusConfig, exclude: selected })}
                  />
                </FormControl>
              </>
            )}
          </Stack>
        </Flex>
        <Button maxWidth="auto" ml="sp12" mb="sp24" disabled={saving} isLoading={saving} variant="primary" onClick={() => handleSave()}>
          {saving ? t("Saving...") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default RadiusConfiguration;
