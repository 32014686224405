import alt from "../alt";

class CaptivePortalActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }

  deletePortal(portal) {
    return portal;
  }

  addPortal() {
    return "nothing";
  }

  savePortal(portal) {
    return portal;
  }
}

export default alt.createActions(CaptivePortalActions);
