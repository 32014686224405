import alt from "../alt";

class FingerprintActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }
}

export default alt.createActions(FingerprintActions);
