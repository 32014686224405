import { Flex, MultiSelect } from "@familyzone/component-library";
import React, { useEffect, useMemo, useState } from "react";
import { zIndices } from "../../../utils/ZIndexUtil";
import { filteredData, getCategories, UserJourneyData } from "./UserJourneyHelper";
import { Item, useUserJourneyFiltersStore } from "../../../storez/MultiSelectStores";

/**
 * This component takes in
 * 1. The unfiltered data,
 * 2. A callback to call when filtered data changes
 * 3. A callback to call when filter values change.
 *
 * This means that the User Journey page doesn't have to care about how the data is filtered or maintain state related to it.
 * All it gets is the filtered results.
 */
export interface IUserJourneyFiltersProps {
  disabled?: boolean;

  data: UserJourneyData[];

  onDataFiltered(filteredData: UserJourneyData[]): void;

  onFiltersChanged(typesSelected: string[], resultsSelected: string[], categoriesSelected: string[]): void;
}

const UserJourneyFilters = ({ data, disabled = false, onDataFiltered, onFiltersChanged }: IUserJourneyFiltersProps): JSX.Element => {
  const categories = useMemo(() => {
    return getCategories(data);
  }, [data]);

  const [categoriesSelected, setCategoriesSelected] = useState<Item[]>(useUserJourneyFiltersStore.getState().categoriesSelected || []);

  const types = [{ value: "Search" }, { value: "Video" }, { value: "Web" }];
  const [typesSelected, setTypesSelected] = useState<Item[]>(useUserJourneyFiltersStore.getState().typesSelected || []);

  const results = [{ value: "Allowed" }, { value: "Blocked" }, { value: "Flagged" }];
  const [resultsSelected, setResultsSelected] = useState<Item[]>(useUserJourneyFiltersStore.getState().resultsSelected || []);

  useEffect(() => {
    useUserJourneyFiltersStore.setState({ categoriesSelected });
  }, [categoriesSelected]);

  useEffect(() => {
    useUserJourneyFiltersStore.setState({ typesSelected });
  }, [typesSelected]);

  useEffect(() => {
    useUserJourneyFiltersStore.setState({ resultsSelected });
  }, [resultsSelected]);

  const itemsToStrings = (items: Item[]): string[] => {
    return items.map((value: Item) => {
      return value.value.toString();
    });
  };

  const filterData = (): UserJourneyData[] => {
    const categoryItems: string[] = itemsToStrings(categoriesSelected.length === 0 ? categories : categoriesSelected);
    const typeItems: string[] = itemsToStrings(typesSelected.length === 0 ? types : typesSelected);
    const resultItems: string[] = itemsToStrings(resultsSelected.length === 0 ? results : resultsSelected);
    const result = filteredData(data, categoryItems, typeItems, resultItems);
    return result;
  };

  const filtered: UserJourneyData[] = useMemo(() => {
    return filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typesSelected, categoriesSelected, resultsSelected, categories]);

  useEffect(() => {
    onDataFiltered(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered]);

  const onCategoriesChange = (items: Item[]) => {
    setCategoriesSelected(items);
    onFiltersChanged(itemsToStrings(typesSelected), itemsToStrings(resultsSelected), itemsToStrings(items));
  };

  const onTypesChange = (items: Item[]) => {
    setTypesSelected(items);
    onFiltersChanged(itemsToStrings(items), itemsToStrings(resultsSelected), itemsToStrings(categoriesSelected));
  };

  const onResultsChange = (items: Item[]) => {
    setResultsSelected(items);
    onFiltersChanged(itemsToStrings(typesSelected), itemsToStrings(items), itemsToStrings(categoriesSelected));
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <Flex m="sp24" mb="0" zIndex={zIndices.multiSelect}>
      <MultiSelect
        onChange={onTypesChange}
        items={types}
        selectedItems={typesSelected}
        label="Type:"
        placeholder="Filter by: Type"
        disabled={disabled}
      />

      <MultiSelect
        onChange={onCategoriesChange}
        items={categories}
        selectedItems={categoriesSelected}
        canClearAll={true}
        label="Category:"
        placeholder="Filter by: Category"
        disabled={disabled}
      />
      <MultiSelect
        onChange={onResultsChange}
        items={results}
        selectedItems={resultsSelected}
        label="Verdict:"
        placeholder="Filter by: Verdict"
        disabled={disabled}
      />
    </Flex>
  );
};

export default UserJourneyFilters;
