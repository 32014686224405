import InterfaceStore from "../stores/InterfaceStore";
import InterfaceActions from "../actions/InterfaceActions";
import React from "react";
import Criteria from "./Criteria";
import SearchableSelect from "../modules/SearchableSelect";

export default class Interface extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = { name: "" };
    }

    this.state = {
      criteria: props.criteria,
      devices: InterfaceStore.getInterfaces(),
    };

    /* {type: "device", negate: false, conditions: {"name": ""}} */
  }

  componentDidMount() {
    InterfaceStore.listen(this.onChange);

    setTimeout(() => {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = { name: "" };
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_ChangeInterface = (event) => {
    this.state.criteria.conditions.name = event.value;
    this.props.bubbleMethod(this.state.criteria);
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let x of this.state.devices) {
      ret.push([x.interface, x.interface]);
    }

    return ret;
  };

  render() {
    return (
      <Criteria label="Interface" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-select">
          <SearchableSelect
            clearable={false}
            value={this.state.criteria.conditions.name}
            onChange={this.handle_ChangeInterface}
            valueDataMap={this.get_SelectionMap()}
          ></SearchableSelect>
        </div>
      </Criteria>
    );
  }
}
