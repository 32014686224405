import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

interface PieChartItems {
  valueField: string;
  categoryField: string;
  chartColorList: am4core.Color[];
}

export const createPieChart = (pieChartItems: PieChartItems): am4charts.PieChart => {
  const chart = am4core.create("personaTopSixAppsChart", am4charts.PieChart);
  chart.numberFormatter.numberFormat = "#.0";

  const pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = pieChartItems.valueField;
  pieSeries.dataFields.category = pieChartItems.categoryField;
  pieSeries.slices.template.dummyData = "formatTransfer";
  pieSeries.slices.template.tooltipText = "{category}: {value.percent}% ({formatTransfer})";

  chart.innerRadius = am4core.percent(60);
  pieSeries.slices.template.stroke = am4core.color("#ffffff");
  pieSeries.slices.template.strokeWidth = 1;
  pieSeries.slices.template.strokeOpacity = 1;

  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  if (pieSeries.tooltip) {
    pieSeries.tooltip.zIndex = Infinity;
  }

  const colorSet = new am4core.ColorSet();
  colorSet.list = pieChartItems.chartColorList;
  pieSeries.colors = colorSet;
  return chart;
};
