import React from "react";
import NuSingleSelect from "./NuSingleSelect";
import intersectionWith from "lodash.intersectionwith";
import { cy } from "./CypressUtil";

const NuSelectItem = ({ dataCy, item, handleRemove, displayTextLens }) => {
  const displayLabel = displayTextLens(item);
  const handleOnClick = () => handleRemove(item);

  return (
    <div className="listselector-item">
      <span
        className="signature_item_remove selected-item-remove"
        data-cy={"list-selector-item-remove_" + cy(displayLabel)}
        onClick={handleOnClick}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </span>
      {displayLabel}
    </div>
  );
};

export default class NuMultiSelect extends NuSingleSelect {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: [],
      filter: "",
      openSelection: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleAdd = (item) => {
    this.setState({
      openSelection: false,
      filter: "",
      filteredItems: [],
    });
    this.props.onAdd(item);
  };

  handleRemove = (item) => {
    const itemValue = this.props.valueLens(item);
    this.setState({
      openSelection: false,
      filter: "",
      filteredItems: [],
    });
    this.props.onRemove(itemValue);
  };

  handleFilterChange = (event) => {
    this.setState({
      filter: event.target.value,
      openSelection: true,
      filteredItems: this.prepareFilteredItems(event.target.value),
    });
  };

  prepareFilteredItems = (filter) => {
    const filteredItems = [];

    for (const item of this.props.allItems) {
      if (item.dynamic === true) {
        const transformed_value = item.transform_filter(filter);
        if (item.should_show(transformed_value)) {
          filteredItems.push({
            text: item.render_text(transformed_value),
            value: transformed_value,
            ok: item.is_ok(transformed_value),
          });
        }
      } else {
        if (filter.length <= 2 && this.props.displayTextLens(item).toLowerCase().startsWith(filter.toLowerCase())) {
          filteredItems.push(item);
        } else if (filter.length > 2 && this.props.displayTextLens(item).toLowerCase().includes(filter.toLowerCase())) {
          filteredItems.push(item);
        } else if (filter.length === 0) {
          filteredItems.push(item);
        }
      }
    }

    for (const selectedItem of this.props.selectedValues) {
      for (let i = 0; i < filteredItems.length; i++) {
        if (selectedItem === this.props.valueLens(filteredItems[i])) {
          filteredItems.splice(i, 1);
          break;
        }
      }
    }
    return filteredItems.slice(0, 100);
  };

  renderItems = () => {
    const preparedItems = [];
    for (const item of this.state.filteredItems) {
      preparedItems.push(
        <div
          key={this.props.valueLens(item)}
          className="nuselect_search_select_item"
          onClick={() => this.handleAdd(item)}
          data-testid="nu-select-choice"
        >
          {this.props.displayTextLens(item)}
        </div>
      );
    }
    if (this.state.filteredItems.length > 99) {
      preparedItems.push(
        <div key="truncated_items_info" className="nuselect_search_select_item_truncate_info">
          Too many values to show them all. Please use search to narrow down results.
        </div>
      );
    }
    return preparedItems;
  };

  renderSelectedItems = () => {
    const comparator = (a, b) => this.props.valueLens(a) === b;
    const selectedItems = intersectionWith(this.props.allItems, this.props.selectedValues, comparator);

    const renderItems = selectedItems.map((selectedItem) => (
      <NuSelectItem
        key={this.props.valueLens(selectedItem)}
        item={selectedItem}
        displayTextLens={this.props.displayTextLens}
        valueLens={this.props.valueLens}
        handleRemove={this.handleRemove}
        dataCy={this.props["data-cy"]}
      />
    ));

    return renderItems;
  };

  render() {
    return (
      <div>
        <div ref={this.setWrapperRef} className={this.state.openSelection ? "nuselect_search_open nuselect_search" : "nuselect_search"}>
          <div
            className={this.searchFieldStateClassName()}
            ref={(input) => {
              this.inputWrapperField = input;
            }}
          >
            <div className="nuselect_search_field_input">
              <input
                ref="NuSelectInput"
                type="text"
                value={this.state.filter}
                onChange={this.handleFilterChange}
                placeholder={this.props.searchPlaceholder}
                onFocus={this.handleFocus}
                onKeyDown={this.handleEsc}
                data-cy={this.props["data-cy"] && cy(this.props["data-cy"], "search")}
                data-testid={this.props["data-testid"]}
              />
            </div>
          </div>
          {this.renderSelection()}
        </div>
        <div className="nuselect_selected" data-cy={this.props["data-cy"] && cy(this.props["data-cy"], "selected")}>
          {this.renderSelectedItems()}
        </div>
      </div>
    );
  }
}
