import { Box } from "@familyzone/component-library";
import Link from "./Link";

const ReturnToSignIn = () => (
  <Box>
    <i className="fa fa-chevron-left" aria-hidden="true"></i>
    <Link to="/login" fontFamily="links" fontSize="14px" fontWeight="500" pl="sp4">
      Return to Sign in
    </Link>
  </Box>
);

export default ReturnToSignIn;
