import React from "react";
import Criteria from "./Criteria";

export default class PortRange extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = {
        startPort: 0,
        endPort: 6550,
      };
    }

    this.state = {
      criteria: props.criteria,
    };

    /* {type: "transport.portrange", negate: false, conditions: {startPort: 0, endPort: 65333}} */
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = {
        startPort: 0,
        endPort: 6550,
      };
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_ChangeStartPort = (event) => {
    if (isNaN(event.target.value)) {
      return;
    }

    this.state.criteria.conditions.startPort = parseInt(event.target.value);
    this.props.bubbleMethod(this.state.criteria);
  };

  handle_ChangeEndPort = (event) => {
    if (isNaN(event.target.value)) {
      return;
    }

    this.state.criteria.conditions.endPort = parseInt(event.target.value);
    this.props.bubbleMethod(this.state.criteria);
  };

  render() {
    return (
      <Criteria label="Port Range" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="formgroup-element-fields-inline">
          <div className="mui-textfield">
            <input
              type="text"
              value={this.state.criteria.conditions.startPort}
              onChange={this.handle_ChangeStartPort}
              placeholder="Start Port"
            />
          </div>
          <span>-</span>
          <div className="mui-textfield">
            <input type="text" value={this.state.criteria.conditions.endPort} onChange={this.handle_ChangeEndPort} placeholder="End Port" />
          </div>
        </div>
      </Criteria>
    );
  }
}
