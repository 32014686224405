import Api from "../../utils/Api";
import { v4 as uuid } from "uuid";

export type SubnetGroup = {
  text: string;
  objectId: string;
};

export type CloudSafeNetworkRowUI = {
  id: string;
  inEditState: boolean;
  text: string;
  objectId: string;
};

type CloudSafeData = {
  data: { result: { object_list: string[] } };
};

export type SubnetGroupOptionUI = {
  objectId?: string;
  value: string;
};

const typeSafeUuid = (): string => {
  return uuid();
};

export const newRow = (rowText: string): CloudSafeNetworkRowUI => {
  return {
    id: typeSafeUuid(),
    inEditState: false,
    objectId: "",
    text: rowText,
  } as CloudSafeNetworkRowUI;
};

export const fetchTableDataAsync = async (
  subnetGroupOptions: Array<SubnetGroup>,
  setLoaded: (loaded: boolean) => void,
  nonExistentRowDefaultText: string
): Promise<Array<CloudSafeNetworkRowUI>> => {
  const subnetGroupGetResult: CloudSafeData = (await Api.getAsync("/surfwize/cloudsafe/subnetgroup")) as CloudSafeData;
  setLoaded(true);
  const objectIds: Array<string> = subnetGroupGetResult.data.result.object_list;

  return objectIds.map((objectId: string) => {
    const found: SubnetGroup[] = subnetGroupOptions.filter((s) => {
      return s.objectId === objectId;
    });
    if (found.length > 0)
      return {
        id: typeSafeUuid(),
        inEditState: false,
        objectId: found[0].objectId,
        text: found[0].text,
      };
    else return newRow(nonExistentRowDefaultText);
  });
};

export const saveCurrentObjectListAsync = async (data: Array<CloudSafeNetworkRowUI>): Promise<void> => {
  const objectIdsToSave = data.map((d) => {
    return d.objectId;
  });
  await Api.postAsync("/surfwize/cloudsafe/subnetgroup", { object_list: objectIdsToSave });
};

export const fetchSubnetGroupsForSelectDropdownAsync = async (): Promise<SubnetGroup[]> => {
  interface SubnetGroupOptionData {
    data: Array<{
      id: string;
      name: string;
      type: number;
    }>;
  }
  const subnetGroupOptionData = (await Api.getAsync("/surfwize/cloudsafe/options")) as SubnetGroupOptionData;
  return subnetGroupOptionData.data
    .filter((optionData) => optionData.type === 0 || optionData.type === 1)
    .map((subnetGroup) => {
      return { objectId: subnetGroup.id, text: subnetGroup.name };
    });
};

export const removeDuplicatesByObjectId = (cloudSafeNetworkRows: Array<CloudSafeNetworkRowUI>): Array<CloudSafeNetworkRowUI> => {
  return cloudSafeNetworkRows.filter((item, index) => {
    return cloudSafeNetworkRows.findIndex((obj) => obj.objectId === item.objectId) === index;
  });
};

export const removeEmptyObjectIds = (array: Array<CloudSafeNetworkRowUI>): Array<CloudSafeNetworkRowUI> => {
  return array.filter((obj) => {
    return obj.objectId.trim() !== "";
  });
};
