import React, { Component, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import TableSearchBox from "./TableSearchBox";
import PortalHoc from "./PortalHoc";
import SmallTableButton from "./SmallTableButton";
import get from "lodash.get";
import { guardedGet } from "../utils/GuardedGet";
import moment from "moment";
import uuid from "uuid/v4";

import { searchStringOld as searchString } from "../utils/Validation";
import CriteriaPrinter from "./CriteriaPrinter";
import ObjectPrinter from "../criteria/ObjectPrinter";
import { TableSearchWithButton } from "./TableSearchWithButton";

export default class FlexibleTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      searchable: this.is_Searchable(),
      className: "maxtable " + this.props.className,
      search_expression: this.props.default_filter ? this.props.default_filter : "",
      sorting: this.isSortable(),
      sort_column: this.props.sort_column ? this.props.sort_column : this.getDefaultSort(),
      sort_asc: this.props.hasOwnProperty("sort_asc") ? this.props.sort_asc : true,
      noDataMessage: this.props.hasOwnProperty("noDataMessage") ? this.props.noDataMessage : "No Data To Display",
      num_display_rows: this.props.num_display_rows ? this.props.num_display_rows : 100,
      searchButton: this.props.searchButton,
      search_query: "",
    };
    this.mounted = false;
  }

  componentDidMount() {
    const columns = this.props.columns;
    if (columns && columns.length > 0) {
      this.setState({ columnPercentageWidth: this.calculateColumnWidths() });
    }
  }

  calculateColumnWidths = () => {
    const columns = this.props.columns;

    const percentageWidthPerColumn = 100 / columns.length;

    return percentageWidthPerColumn;
  };

  static search__multiple(keys) {
    return function (row, search_expression) {
      if (search_expression === "") {
        return true;
      } else {
        for (let key of keys) {
          if (row[key] && row[key] != null) {
            if (Number.isInteger(row[key])) {
              if (row[key].toString().toLowerCase().includes(search_expression.toLowerCase())) {
                return true;
              }
            } else {
              if (row[key].toLowerCase().includes(search_expression.toLowerCase())) {
                return true;
              }
            }
          }
        }

        return false;
      }
    };
  }

  static search__single_date_field(field_key, format = "YYYY-MM-DD HH:mm:ss") {
    return function (row, search_expression) {
      if (!row[field_key] || !search_expression) return true;
      const day = moment.unix(row[field_key]);
      const searchableTime = day.format(format);
      return searchableTime.includes(search_expression.trim());
    };
  }

  static search__single_string_field(field_key) {
    if (!field_key) {
      return (row, search_expression) => searchString(search_expression, row);
    }

    return function (row, search_expression) {
      return searchString(search_expression, row[field_key]);
    };
  }

  static search__object_printer(field_key) {
    return function (row, search_expression) {
      return searchString(search_expression, ObjectPrinter.getObjectsText(row[field_key]));
    };
  }

  /**
   * Search rows rendered by CriteriaPrinter
   * @param {string} field_key
   * @returns {function(string, string): boolean} if search matches
   */
  static search__criteria_printer(field_key) {
    return function (row, search_expression) {
      return searchString(search_expression, CriteriaPrinter.getSearchText(row[field_key]));
    };
  }

  /**
   * Perform an operation on row, then search the result
   * @param {function(row): string} execute_on_row The operation to perform on row before searching
   * @returns {function(string, string): boolean} if search matches
   */
  static search__with_function(execute_on_row) {
    return function (row, search_expression) {
      if (search_expression === "") {
        return true;
      } else {
        const exp = search_expression.trim().toLowerCase();
        return execute_on_row(row).trim().toLowerCase().includes(exp);
      }
    };
  }

  static sortable__single_string_nested_field(field_path) {
    return (a, b) => {
      const stringA = guardedGet(a, field_path, "");
      const stringB = guardedGet(b, field_path, "");

      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    };
  }

  static sortable__single_caseinsensitive_string_field(...field_path) {
    return (a, b) => {
      const stringA = guardedGet(a, field_path, "");
      const stringB = guardedGet(b, field_path, "");

      return FlexibleTable.sort_caseinsensitive_strings(stringA, stringB);
    };
  }

  static sort_caseinsensitive_strings(a, b) {
    const stringA = a.toLowerCase();
    const stringB = b.toLowerCase();

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }

    return 0;
  }

  static sortable__single_string_field(field_key_a, field_key_b) {
    // Sometimes we need different field names for different rows. For example, when different rows
    // are of different 'provider_type', and therefore the field we need to sort on has a different
    // name.
    if (!field_key_b) {
      field_key_b = field_key_a;
    }
    return function (row_a, row_b) {
      let nameA = row_a[field_key_a];
      let nameB = row_b[field_key_b];

      if (!nameA) {
        nameA = "";
      }

      if (!nameB) {
        nameB = "";
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    };
  }

  static sortable__single_numeric_field(key) {
    return (a, b) => {
      const valueA = guardedGet(a, key, Number.MIN_SAFE_INTEGER);
      const valueB = guardedGet(b, key, Number.MIN_SAFE_INTEGER);
      return valueA < valueB ? -1 : 1;
    };
  }

  static sortable__single_int_field(field_path) {
    return (a, b) => {
      const valueA = guardedGet(a, field_path, 0);
      const valueB = guardedGet(b, field_path, 0);

      if (typeof valueA !== "number" && typeof valueB !== "number") {
        return 0;
      } else if (typeof valueA !== "number") {
        return -1;
      } else if (typeof valueB !== "number") {
        return 1;
      }

      return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
    };
  }

  static sortable__single_int_nested_field(field_path) {
    return (a, b) => {
      if (!a || !b) return 0;
      const valueA = guardedGet(a, field_path, 0);
      const valueB = guardedGet(b, field_path, 0);

      if (typeof valueA !== "number" || typeof valueB !== "number") return 0;

      return valueA < valueB ? -1 : 1;
    };
  }

  static sortable__single_ip_range_field(field_key) {
    return (row_a, row_b) => FlexibleTable.sort_ip_with_separator(row_a[field_key], row_b[field_key], "-");
  }

  static sortable__single_ip_subnet_field(field_key) {
    return (row_a, row_b) => FlexibleTable.sort_ip_with_separator(row_a[field_key], row_b[field_key], "/");
  }

  static sort_ip_with_separator(a, b, separator) {
    const aSplit = a.split(separator);
    const bSplit = b.split(separator);

    let valueA = FlexibleTable.sort_ip(aSplit[0]);
    let valueB = FlexibleTable.sort_ip(bSplit[0]);

    if (valueA !== valueB) {
      return valueA < valueB ? -1 : 1;
    }

    if (aSplit.length > 1 && bSplit.length > 1) {
      valueA = FlexibleTable.sort_ip(aSplit[1]);
      valueB = FlexibleTable.sort_ip(bSplit[1]);
      return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
    } else if (aSplit.length > 1) {
      return -1;
    } else if (bSplit.length > 1) {
      return 1;
    }
    return 0;
  }

  static sortable__single_ip_field(field_key) {
    return this.sortable(function (row) {
      const ip = row[field_key];
      return FlexibleTable.sort_ip(ip);
    });
  }

  static sort_ip(ip) {
    const pattern = /^(\d){1,3}\.(\d){1,3}\.(\d){1,3}\.(\d){1,3}$/;
    if (!pattern.test(ip)) return Number.POSITIVE_INFINITY;

    const parts = ip.split(".").map(Number);
    const x = parts[0] * 1000 * 1000 * 1000 + parts[1] * 1000 * 1000 + parts[2] * 1000 + parts[3];
    return x;
  }

  static sort_bools(a, b) {
    const valueA = typeof a === "boolean" ? (a ? 1 : 0) : Number.MIN_SAFE_INTEGER;

    const valueB = typeof b === "boolean" ? (b ? 1 : 0) : Number.MIN_SAFE_INTEGER;
    return valueA < valueB ? -1 : 1;
  }

  static sortable__single_bool_field(field_key) {
    return (row_a, row_b) => FlexibleTable.sort_bools(row_a[field_key], row_b[field_key]);
  }

  static sortable__single_bool_field_path(...keys) {
    return (row_a, row_b) => FlexibleTable.sort_bools(get(row_a, keys), get(row_b, keys));
  }

  static sortable__multi_int_field(field_key1, field_key2) {
    return function (row_a, row_b) {
      let valueA = row_a[field_key1] + row_a[field_key2];
      let valueB = row_b[field_key1] + row_b[field_key2];

      if (!valueA) {
        valueA = Number.MIN_SAFE_INTEGER;
      }

      if (!valueB) {
        valueB = Number.MIN_SAFE_INTEGER;
      }

      return valueA < valueB ? -1 : 1;
    };
  }

  static sortable__multi_string_field(field_key1, field_key2) {
    return function (row_a, row_b) {
      const valueA = (row_a[field_key1] || "") + (row_a[field_key2] || "");
      const valueB = (row_b[field_key1] || "") + (row_b[field_key2] || "");
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  static sortable__multi_caseinsensitive_string_field(field_key1, field_key2) {
    return function (row_a, row_b) {
      const valueA = guardedGet(row_a, field_key1, "") + guardedGet(row_a, field_key2, "");
      const valueB = guardedGet(row_b, field_key1, "") + guardedGet(row_b, field_key2, "");
      return FlexibleTable.sort_caseinsensitive_strings(valueA, valueB);
    };
  }

  static sortable__caseinsensitive_string_array_field(field_key) {
    return function (row_a, row_b) {
      const valueA = guardedGet(row_a, field_key, []).join(",");
      const valueB = guardedGet(row_b, field_key, []).join(",");
      return FlexibleTable.sort_caseinsensitive_strings(valueA, valueB);
    };
  }

  static sortable(f) {
    return function (row_a, row_b) {
      const valueA = f(row_a);
      const valueB = f(row_b);

      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    };
  }

  is_Searchable = () => {
    for (let column_desc of this.props.columns) {
      if (column_desc.search) {
        return true;
      }
    }

    return false;
  };

  isSortable = () => {
    for (let column_desc of this.props.columns) {
      if (column_desc.sortable) {
        return true;
      }
    }

    return false;
  };

  getDefaultSort = () => {
    for (let i = 0; i < this.props.columns.length; i++) {
      if (this.props.columns[i].sortable) {
        return i;
      }
    }
  };

  getSortMethod = () => {
    return this.props.columns[this.state.sort_column].sortable;
  };

  handle_ChangeSort = (clicked_column) => {
    if (clicked_column === this.state.sort_column) {
      this.setState({ sort_asc: !this.state.sort_asc });
    } else {
      this.setState({
        sort_asc: this.state.sort_asc,
        sort_column: clicked_column,
      });
    }
  };

  is_exportable = () => {
    for (let column_desc of this.props.columns) {
      if (column_desc.export) {
        return true;
      }
    }
    return false;
  };

  handle_export = () => {
    let csv = "";
    let self = this;
    this.props.columns.forEach(function (column) {
      if (column.export) {
        csv += column.title + ",";
      }
    });
    csv = csv.slice(0, csv.length - 1) + "\n";

    this.props.data.forEach(function (row) {
      let row_data = "";
      self.props.columns.forEach(function (column) {
        if (column.export) {
          row_data += column.export(row) + ",";
        }
      });
      csv += row_data.slice(0, row_data.length - 1) + "\n";
    });

    let link = document.createElement("a");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    link.click();
  };

  render_TableHeadingColumn = (column_id) => {
    let column = this.props.columns[column_id];
    if (column.sortable) {
      try {
        if (!column.style) {
          column.style = {};
        }
        column.style["cursor"] = "pointer";
      } catch (e) {}
    }
    if (column_id === this.state.sort_column) {
      if (this.state.sort_asc) {
        return (
          <th className="StickyTableHeader" style={column.style} key={uuid()} onClick={() => this.handle_ChangeSort(column_id)}>
            {column.title}
            <i className="fa fa-sort-asc sort_icon" aria-hidden="true" />
          </th>
        );
      } else {
        return (
          <th className="StickyTableHeader" style={column.style} key={uuid()} onClick={() => this.handle_ChangeSort(column_id)}>
            {column.title}
            <i className="fa fa-sort-desc sort_icon" aria-hidden="true" />
          </th>
        );
      }
    } else if (column.sortable) {
      return (
        <th className="StickyTableHeader" style={column.style} key={uuid()} onClick={() => this.handle_ChangeSort(column_id)}>
          {column.title}
        </th>
      );
    } else {
      return (
        <th className="StickyTableHeader" style={column.style} key={uuid()}>
          {column.title}
        </th>
      );
    }
  };

  render__TableHeadings = () => {
    if (this.props.hide_headers) {
      return null;
    }

    let elements = [];
    for (let i = 0; i < this.props.columns.length; i++) {
      elements.push(this.render_TableHeadingColumn(i));
    }

    return elements;
  };

  render__TableData = () => {
    let self = this;
    let rows = this.props.data;
    if (!rows) {
      return;
    }

    if (this.isSortable()) {
      rows = rows.sort(function (row_a, row_b) {
        if (self.state.sort_asc) {
          return self.getSortMethod()(row_a, row_b);
        }
        return self.getSortMethod()(row_b, row_a);
      });
    }

    if (this.is_Searchable() === true) {
      rows = rows.filter(function (row) {
        if (self.state.search_expression === undefined || self.state.search_expression === "") {
          return true;
        }

        let and_conditions = self.state.search_expression.split("&&");
        for (let condition of and_conditions) {
          condition = condition.trim();

          function condition_met(condition, row, columns) {
            for (let column_desc of columns) {
              if (column_desc.search && column_desc.search(row, condition)) {
                return true;
              }
            }
            return false;
          }

          if (!condition_met(condition, row, self.props.columns)) {
            return false;
          }
        }

        return true;
      });
    }

    let elements = [];
    let display_rows = rows.slice(0, this.state.num_display_rows);
    for (let i = 0; i < display_rows.length; i++) {
      const row = display_rows[i];

      let rendered_columns = [];
      for (let column_desc of this.props.columns) {
        if (column_desc.onclick !== undefined) {
          rendered_columns.push(
            <StyledTd
              className={column_desc.class}
              key={uuid()}
              columnPercentageWidth={this.state.columnPercentageWidth}
              onClick={() => column_desc.onclick(row)}
            >
              {column_desc.data(row)}
            </StyledTd>
          );
        } else {
          rendered_columns.push(
            <StyledTd className={column_desc.class} key={uuid()} columnPercentageWidth={this.state.columnPercentageWidth}>
              {column_desc.data(row)}
            </StyledTd>
          );
        }
      }

      let key = uuid();
      if (this.props.movable && this.props.movable_id) {
        key = this.props.movable_id(row);
      }

      elements.push(
        <FlexibleTableRow
          key={key}
          disableDragging={!this.props.movable}
          draggableId={key}
          index={i}
          render={(provided, snapshot) => (
            <StyledTableRow
              key={key}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
              data-cy="tableRow"
            >
              {rendered_columns}
            </StyledTableRow>
          )}
        />
      );
    }

    if (rows.length > rows.slice(0, this.state.num_display_rows).length) {
      this.mounted = false;
      elements.push(
        <FlexibleTableRow
          key={uuid()}
          disableDragging
          draggableId={"load-more-drag-disabled"}
          index={elements.length}
          render={(provided) => (
            <StyledTableRow
              key="load_more"
              id="load_more"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              data-cy="tableRow"
            >
              <td
                style={{ background: "whitesmoke", textAlign: "center" }}
                colSpan={this.props.columns.length}
                onClick={this.handle_loadMore}
              >
                <div className="button buttom-primary" onClick={this.handle_loadMore}>
                  Show More Results
                </div>
              </td>
            </StyledTableRow>
          )}
        />
      );
    }

    return elements;
  };

  render_loading = () => {
    let loadingComponent = (
      <div>
        Loading <i className="fa fa-refresh fa-spin fa-fw" />
      </div>
    );
    if (this.props.loadingComponent) {
      loadingComponent = this.props.loadingComponent;
    }

    return (
      <FlexibleTableRow
        disableDragging
        draggableId={"loading-component-drag-disabled"}
        index={1}
        render={(provided) => (
          <StyledTableRow {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <td className="nodata" colSpan={this.props.columns.length}>
              {loadingComponent}
            </td>
          </StyledTableRow>
        )}
      />
    );
  };

  render_no_data_message = () => {
    return (
      <FlexibleTableRow
        disableDragging
        draggableId={"no-data-component-drag-disabled"}
        index={1}
        render={(provided) => (
          <StyledTableRow {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <td className="nodata" colSpan={this.props.columns.length}>
              {this.state.noDataMessage}
            </td>
          </StyledTableRow>
        )}
      ></FlexibleTableRow>
    );
  };

  handle_loadMore = () => {
    this.setState({
      num_display_rows: this.state.num_display_rows + 20,
    });
  };

  handle_SearchChange = (event) => {
    this.setState({
      search_expression: event.target.value,
    });
  };

  handle_SearchWithQuery = () => {
    this.props.searchWithQuery(this.props.searchProp, this.state.sort_asc, this.state.sort_column);
  };

  handle_keyDown = (event) => {
    if ((event.key === "Enter") & (this.props.searchProp.length > 1)) {
      this.props.searchWithQuery(this.props.searchProp, this.state.sort_asc, this.state.sort_column);
    }
  };

  render_Search = () => {
    if (this.is_Searchable()) {
      if (this.props.modalSearch) {
        return (
          <TableSearchBox
            searchExpression={this.state.search_expression}
            searchChangeHandler={this.handle_SearchChange}
            placeholder="Search"
          />
        );
      }
      if (this.props.searchButton) {
        return (
          <PortalHoc elementId="fz_table_header">
            <TableSearchWithButton
              searchExpression={this.props.searchProp}
              searchChangeHandler={this.props.handle_Change}
              apiSearchHandler={this.handle_SearchWithQuery}
              searchBoxLabel={this.props.searchBoxLabel}
              handleKeyDown={this.handle_keyDown}
            />
            <div className="table-search-wrapper">
              <div onClick={this.props.handle_Reset} className={"table-search-reset"}>
                <div className={"label"}>{"Reset Search"}</div>
                <i className="fa fa-refresh" />
              </div>
            </div>
          </PortalHoc>
        );
      }
      return (
        <PortalHoc elementId="fz_table_header">
          <TableSearchBox
            searchExpression={this.state.search_expression}
            searchChangeHandler={this.handle_SearchChange}
            placeholder="Search"
          />
        </PortalHoc>
      );
    }

    return <div />;
  };

  render_buttons = () => {
    if (this.props.buttons) {
      let ret = [];
      for (let button of this.props.buttons) {
        ret.push(
          <span key={"customButton" + ret.length} className="clickable tableheader-button">
            {button}
          </span>
        );
      }
      return (
        <div className={"customButtons"} style={{ float: "right" }}>
          {ret}
        </div>
      );
    }
  };
  render_export = () => {
    if (this.is_exportable()) {
      return (
        <PortalHoc elementId="fz_table_header_buttons">
          <SmallTableButton onClick={this.handle_export}>EXPORT</SmallTableButton>
        </PortalHoc>
      );
    }
  };

  componentDidUpdate() {
    this.mounted = true;
  }

  render_ShowHeading = () => {
    if (this.state.searchable) {
      return (
        <div className="maxtable-tableheader">
          {this.render_Search()}
          {this.render_buttons()}
          {this.render_export()}
        </div>
      );
    }

    return <div />;
  };

  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    // Dropped in the same place -> nothing to do
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Update the rows state so the drag drop is persisted
    const currentRowData = Array.from(this.props.data);
    const movedRow = currentRowData.splice(source.index, 1)[0];
    currentRowData.splice(destination.index, 0, movedRow);

    this.props.onMove(currentRowData);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={this.state.className + "-container"}>
          {this.render_ShowHeading()}
          <div className={this.state.className + "-table"}>
            <table className={this.state.className} style={{ display: "table", tableLayout: "auto" }}>
              <thead>
                <tr className="StickyTableHeader">{this.render__TableHeadings()}</tr>
              </thead>
              <Droppable droppableId="flexible_table_body">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {!this.props.loaded ? (
                      this.render_loading()
                    ) : this.props.data.length === 0 ? (
                      this.render_no_data_message()
                    ) : (
                      <Fragment>
                        {this.render__TableData()}
                        {provided.placeholder}
                      </Fragment>
                    )}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

class FlexibleTableRow extends Component {
  render() {
    return (
      <Draggable
        key={this.props.draggableId}
        draggableId={this.props.draggableId}
        index={this.props.index}
        isDragDisabled={this.props.disableDragging}
      >
        {(provided, snapshot) => this.props.render(provided, snapshot)}
      </Draggable>
    );
  }
}

//#region styled-components
const StyledTableRow = styled.tr`
  ${(props) =>
    props.isDragging
      ? `
        display: table;
        background-color: azure;
    `
      : ""}
`;

const StyledTd = styled.td`
  cursor: pointer;
  width: ${(props) => (props.columnPercentageWidth ? `${props.columnPercentageWidth}%` : "auto")};
`;
//#endregion
