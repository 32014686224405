import React from "react";
import ModalWindow from "../../modules/ModalWindow";
import moment from "moment";
import { ABTester, Box, Flex, Modal, Text } from "@familyzone/component-library";

const HostDetailsModal = (props) => {
  const handleHide = () => {
    props.handleClose();
  };

  const renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={handleHide}>
        Close
      </button>
    );
  };

  const renderSessionDetails = () => {
    if (props.host.session) {
      return (
        <div>
          <div className="host_details">
            <div className="host_details_key">User:</div>
            <div className="host_details_value">{props.host.user}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Session Type:</div>
            <div className="host_details_value">{props.host.session.type}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Idle Timeout:</div>
            <div className="host_details_value">{props.host.session.idle_timeout}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Absolute Timeout:</div>
            <div className="host_details_value">
              {props.host.session.absolute_timeout > 0 ? moment.unix(props.host.session.absolute_timeout).format() : "Not Set"}
            </div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Authentication Provider:</div>
            <div className="host_details_value">{props.host.session.authentication_provider}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Idle:</div>
            <div className="host_details_value">{props.host.session.is_idle ? "true" : "false"}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">First Seen:</div>
            <div className="host_details_value">{moment.unix(props.host.session.loginTime).fromNow()}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Last Seen:</div>
            <div className="host_details_value">{moment.unix(props.host.session.lastSeen).fromNow()}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Mobilezone Checkin:</div>
            <div className="host_details_value">
              {props.host.fz_poll_time > 0 ? moment.unix(props.host.fz_poll_time).fromNow() : "Never"}
            </div>
          </div>
          <div className="host_details">
            <div className="host_details_key">BypassCode Expiry:</div>
            <div className="host_details_value">
              {props.host.bypass_expiry_time > 0 ? moment.unix(props.host.bypass_expiry_time).fromNow() : "No Bypass"}
            </div>
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  const renderDetails = () => {
    if (props.visible) {
      return (
        <ModalWindow title="Host Details" handleHide={handleHide} actions={renderActions()}>
          <div className="host_details">
            <div className="host_details_key">Ip Address:</div>
            <div className="host_details_value">{props.host.host}</div>
          </div>

          <div className="host_details">
            <div className="host_details_key">Mac Address:</div>
            <div className="host_details_value">{props.host.hw}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Active Connections:</div>
            <div className="host_details_value">{props.host.total_active_connections}</div>
          </div>
          <div className="host_details">
            <div className="host_details_key">Device Type:</div>
            <div className="host_details_value">{props.host.type}</div>
          </div>

          {renderSessionDetails()}
        </ModalWindow>
      );
    }

    return <div></div>;
  };

  return (
    <ABTester
      isOptionB={true}
      optionB={renderDetails()}
      optionA={
        <Modal headerText="Host Details" isOpen={props.visible} onClose={handleHide} size="md">
          <Box color="neutrals.900">
            <Flex mt="sp12" flexDir="row">
              <Text fontWeight="bold" mr="sp8">
                IP address:
              </Text>
              <Text>{props.host.host}</Text>
            </Flex>
            <Flex mt="sp8" flexDir="row">
              <Text fontWeight="bold" mr="sp8">
                Mac Address:
              </Text>
              <Text>{props.host.hw}</Text>
            </Flex>
            <Flex mt="sp8" flexDir="row">
              <Text fontWeight="bold" mr="sp8">
                Active Connections:
              </Text>
              <Text>{props.host.total_active_connections}</Text>
            </Flex>
            <Flex mt="sp8" flexDir="row">
              <Text fontWeight="bold" mr="sp8">
                Device Type:
              </Text>
              <Text>{props.host.type}</Text>
            </Flex>
            {props.host.session && (
              <Box>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    User:
                  </Text>
                  <Text>{props.host.user}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Session Type:
                  </Text>
                  <Text>{props.host.session.type}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Idle Timeout:
                  </Text>
                  <Text>{props.host.session.idle_timeout}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Absolute Timeout:
                  </Text>
                  <Text>
                    {props.host.session.absolute_timeout > 0 ? moment.unix(props.host.session.absolute_timeout).format() : "Not Set"}
                  </Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Authentication Provider:
                  </Text>
                  <Text>{props.host.session.authentication_provider}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Idle:
                  </Text>
                  <Text>{props.host.session.is_idle ? "true" : "false"}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    First Seen:
                  </Text>
                  <Text>{moment.unix(props.host.session.loginTime).fromNow()}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Last Seen:
                  </Text>
                  <Text>{moment.unix(props.host.session.lastSeen).fromNow()}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    Mobilezone Checkin:
                  </Text>
                  <Text>{props.host.fz_poll_time > 0 ? moment.unix(props.host.fz_poll_time).fromNow() : "Never"}</Text>
                </Flex>
                <Flex mt="sp8" flexDir="row">
                  <Text fontWeight="bold" mr="sp8">
                    BypassCode Expiry:
                  </Text>
                  <Text>{props.host.bypass_expiry_time > 0 ? moment.unix(props.host.bypass_expiry_time).fromNow() : "No Bypass"}</Text>
                </Flex>
              </Box>
            )}
          </Box>
        </Modal>
      }
    />
  );
};

export default HostDetailsModal;
