import React from "react";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import { Box, Icon, Tooltip, withTranslation } from "@familyzone/component-library";
import { zIndices } from "../../utils/ZIndexUtil";

class DeviceVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { version: "", updateAvailable: false };
  }

  componentDidMount() {
    DeviceStatusStore.listen(this.onChange);
  }

  componentWillUnmount() {
    DeviceStatusStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      version: DeviceStatusStore.getVersion(),
      updateAvailable: DeviceStatusStore.getUpdateAvailable(),
    });
  };

  render() {
    const { t } = this.props;
    return (
      <AuthorizationWrapper allowedRoles={this.props.allowedRoles}>
        {this.state.updateAvailable && (
          <Tooltip label={t("Update Available")} placement="right" variant="dark" zIndex={zIndices.tooltip}>
            <Box
              onClick={this.props.onClick}
              _hover={{ cursor: this.props.onClick ? "pointer" : "default" }}
              height="fit-content"
              mr="sp8"
              lineHeight="0"
              borderRadius="sm"
              bg="accent.turquoise.100"
              p="sp12"
            >
              <Icon boxSize="16px" variant="solid" color="accent.turquoise.400" icon="fa-download" />
            </Box>
          </Tooltip>
        )}
      </AuthorizationWrapper>
    );
  }
}

export default withTranslation()(DeviceVersion);
