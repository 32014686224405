import { isNotEmpty, isValidMacAddress, isValidIPAddress, areUniqueArrays } from "../../utils/Validation";
import { FilteringSourceCriteria } from "./criteriaTypes";

interface CriteriaValidationModel {
  [key: FilteringSourceCriteria["type"]]: (
    sourceCriteria: FilteringSourceCriteria,
    totalSourceCriteria: FilteringSourceCriteria[]
  ) => null | string;
}

const criteriaValidationModel: CriteriaValidationModel = {
  "source.mac": (sourceCriteria) => {
    const value =
      !Array.isArray(sourceCriteria.conditions) && typeof sourceCriteria.conditions.mac === "string" ? sourceCriteria.conditions.mac : "";

    if (!isNotEmpty(value)) {
      return "Please enter a MAC Address";
    }

    if (!isValidMacAddress(value)) {
      return "Invalid MAC Address. Please try again.";
    }
    return null;
  },
  group: (sourceCriteria, totalSourceCriteria) => {
    const excludeConditions = totalSourceCriteria.find((c) => c.type === "exclude.group")?.conditions || [];

    if (!Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    if (!Array.isArray(excludeConditions)) return "Invalid exclude conditions";

    if (sourceCriteria.conditions.length === 0) return "Please include a group";

    if (!areUniqueArrays([sourceCriteria.conditions, excludeConditions])) {
      let alertStr = "A group cannot be both included and excluded. Please review configuration for:";
      const commonGroups = sourceCriteria.conditions.filter(
        (c) => Array.isArray(excludeConditions) && excludeConditions.find((s) => c === s)
      );
      commonGroups.forEach((group_name) => (alertStr += `\n\u2022 ${group_name}`));

      return alertStr;
    }
    return null;
  },
  securitygroup: (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a security group" : null;
  },
  "ipv4.alias": (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include an ip alias" : null;
  },
  "exclude.group": (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a group" : null;
  },
  "source.user": (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a user" : null;
  },
  "source.mac.pool": (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a mac pool" : null;
  },
  fingerprint: (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a device" : null;
  },
  timeperiod: (sourceCriteria) => {
    return !Array.isArray(sourceCriteria.conditions) || !isNotEmpty(sourceCriteria.conditions) ? "Please include a time period" : null;
  },
  "application.http.request.regex": (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    return !isNotEmpty(sourceCriteria.conditions.expression) ? "Please include an expression" : null;
  },
  "application.http.useragent.regex": (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    return !isNotEmpty(sourceCriteria.conditions.expression) ? "Please include an expression" : null;
  },
  "application.http.contenttype.regex": (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    return !isNotEmpty(sourceCriteria.conditions.expression) ? "Please include an expression" : null;
  },
  "ipv4.range": (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    if (!("start_ip" in sourceCriteria.conditions)) return "Please provide a start IP";
    if (!("end_ip" in sourceCriteria.conditions)) return "Please provide an end IP";
    if (!isNotEmpty(sourceCriteria.conditions.end_ip) || !isNotEmpty(sourceCriteria.conditions.start_ip)) {
      return "Please provide IP range";
    }
    if (!isValidIPAddress(sourceCriteria.conditions.end_ip) || !isValidIPAddress(sourceCriteria.conditions.start_ip)) {
      return "Please enter valid IP range";
    }
    return null;
  },
  ipv4: (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    if (!("ip" in sourceCriteria.conditions)) return "Please provide IP";
    if (!isNotEmpty(sourceCriteria.conditions.ip)) {
      return "Please provide IP";
    }
    if (!isValidIPAddress(sourceCriteria.conditions.ip)) {
      return "Please enter valid IP address";
    }
    return null;
  },
  "ipv4.address": (sourceCriteria) => {
    if (Array.isArray(sourceCriteria.conditions)) return "Invalid conditions";
    if (!("ip" in sourceCriteria.conditions)) return "Please provide IP";
    if (!isNotEmpty(sourceCriteria.conditions.ip)) {
      return "Please provide IP";
    }
    if (!isValidIPAddress(sourceCriteria.conditions.ip)) {
      return "Please enter valid IP address";
    }
    return null;
  },
};

export interface ErroredCriteria extends FilteringSourceCriteria {
  errorMessage?: string;
}

const validateCriteria = (sourceCriteria: FilteringSourceCriteria[]): [boolean, ErroredCriteria[]] => {
  const validationResult: (ErroredCriteria | null)[] = sourceCriteria.map((criteria) => {
    if (!(criteria.type in criteriaValidationModel)) return null;
    const errorMessage: string | null = criteriaValidationModel[criteria.type](criteria, sourceCriteria);
    if (!errorMessage) return null;
    return {
      ...criteria,
      errorMessage,
    };
  });

  const erroredCriteria = validationResult.filter((r): r is ErroredCriteria => !!r);

  return [erroredCriteria.length !== 0, erroredCriteria];
};

export default validateCriteria;
