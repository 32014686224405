import React from "react";
import { Link } from "react-router";
import { getUserByUsername } from "./api/Users";

interface UserProfileLinkWithIconProps {
  username: string;
}

export function UserProfileLinkWithIcon({ username }: UserProfileLinkWithIconProps): JSX.Element {
  const [displayName, setDisplayName] = React.useState<string>(username);

  React.useMemo(() => {
    void (async () => {
      const user = await getUserByUsername(username);
      setDisplayName(user.firstName.length || user.lastName.length ? `${user.firstName} ${user.lastName}` : user.username);
    })();
  }, [username]);

  return (
    <span className="titlelink">
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className="titlelink__icon" src={"/static/images/icon_user_profile.svg"} />
      <Link to={"/surfwize/reporting/users/" + username} data-cy="user_fullname">
        {displayName}
      </Link>
    </span>
  );
}
