import { Grid, useTranslation } from "@familyzone/component-library";
import React, { FC, useState } from "react";
import PageWithHeader from "../../templates/PageWithHeader";
import dayjs from "dayjs";
import { useMount } from "react-use";
import relativeTime from "dayjs/plugin/relativeTime";
import { AggregatePills } from "./AggregatePills";
import { SyncPill } from "./SyncPill";
import Api from "../../../utils/Api";
import {
  AzureSyncConfigResponse,
  GoogleSyncConfigResponse,
  LdapSyncConfigResponse,
  Ldap2SyncConfigResponse,
} from "../../../types/Aggregates";
import PropTypes from "prop-types";

dayjs.extend(relativeTime);

const ConfigurationDashboard: FC = (_, context) => {
  const { t } = useTranslation();

  const [azureEnabled, setAzureEnabled] = useState<boolean>(false);
  const [googleEnabled, setGoogleEnabled] = useState<boolean>(false);
  const [ldapEnabled, setLDAPEnabled] = useState<boolean>(false);
  const [ldap2Enabled, setLDAP2Enabled] = useState<boolean>(false);

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config/device/dashboard", isActive: false },
    { title: t("Dashboard"), isActive: true },
  ];
  const title = t("Dashboard");

  useMount(() => {
    Api.get("/config/ajax/authentication/azure", (result: AzureSyncConfigResponse) => {
      setAzureEnabled(result.configuration.azuread_enabled);
    });
    Api.get("/config/ajax/authentication/google", (result: GoogleSyncConfigResponse) => {
      setGoogleEnabled(result.configuration.googleenabled && result.configuration.google_sync_users_and_groups);
    });
    Api.get("/config/ajax/authentication/ldap", (result: LdapSyncConfigResponse) => {
      setLDAPEnabled(result.configuration.enabled && result.configuration.sync_enabled);
    });
    Api.get("/surfwize/device/ajax/ldap2", (result: Ldap2SyncConfigResponse) => {
      setLDAP2Enabled(result.ldap ? result.ldap.some((ldap) => ldap.enabled) : false);
    });
  });

  // seems we're stuck on react-router v3
  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call*/

  return (
    <PageWithHeader title={title} breadcrumbs={breadcrumbs}>
      <Grid py="sp24" px="sp24" templateColumns="repeat(3, 1fr)" gap="sp24">
        <AggregatePills />
        {googleEnabled && (
          <SyncPill
            syncType={"GOOGLE"}
            redirect={() => {
              context.router.push("/config/device/authentication/google");
            }}
          />
        )}
        {(ldapEnabled || ldap2Enabled) && (
          <SyncPill
            syncType={"LDAPS"}
            redirect={() => {
              if (ldapEnabled) context.router.push("/config/device/authentication/ldap");
              if (ldap2Enabled) context.router.push("/config/device/authentication/servers");
            }}
          />
        )}
        {azureEnabled && (
          <SyncPill
            syncType={"AZURE"}
            redirect={() => {
              context.router.push("/config/device/authentication/azure");
            }}
          />
        )}
      </Grid>
    </PageWithHeader>
  );
};

ConfigurationDashboard.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ConfigurationDashboard;
