import { Stack } from "@chakra-ui/layout";
import { Box, Button, Flex, Text, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import Api from "../../utils/Api";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

const CyberSafety: React.FC = () => {
  const [groups, setGroups] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const { successToast } = useToast();

  React.useEffect(() => {
    Api.get("/cybersafety/ajax/groups", (result: { data: string[] }) => {
      setLoading(false);
      setGroups(result.data);
    });
  }, []);

  const handleSave = () => {
    setLoading(true);
    Api.post("/cybersafety/ajax/groups", groups, () => {
      successToast({
        title: "Success",
        description: "Safety Groups saved successfully.",
        isClosable: true,
      });
    });
    setLoading(false);
  };

  if (groups.length === 0 && loading) return <CardSpinner />;

  return (
    <Box m="sp24" width="100%">
      <Box backgroundColor="white">
        <Flex backgroundColor="white" padding="sp12" direction="column">
          <Stack spacing="sp12" width="80%">
            <Text fontSize="xl">{t("Cyber Safety")}</Text>
            <Text fontSize="md">{t("Select groups")}</Text>
            <MultiGroupSearchSelector
              preselected={groups.map((group) => ({ value: group, name: group }))}
              onChangeGroups={(groups) => {
                setGroups(groups.map((group) => String(group.name)));
              }}
              useLegacyId={true}
            />
          </Stack>
        </Flex>
        <Button maxWidth="auto" ml="sp12" mb="sp24" isLoading={loading} variant="primary" onClick={handleSave}>
          {loading ? t("Saving...") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CyberSafety;
