import React from "react";
import clsx from "clsx";

export default class LetterPanelSaveButton extends React.Component {
  buttonText = () => {
    if (this.props.saving) {
      return "Saving";
    }
    if (!this.props.changes) {
      return "Saved";
    }
    return "Save";
  };

  render_SaveIndicator = () => {
    const saveButtonStyle = clsx(
      "letter-panel-footer-save-button",
      this.props.saving || !this.props.disabled ? "letter-panel-footer-save-changes" : "",
      !this.props.changes || this.props.disabled ? "letter-panel-footer-save-saved" : ""
    );
    return (
      <button
        type="submit"
        className={saveButtonStyle}
        disabled={this.props.saving || this.props.disabled}
        onClick={this.props.onClick}
        data-testid="submitBtn"
      >
        {this.props.saving && (
          <span>
            <i className="fa fa-refresh fa-spin" aria-hidden="true" /> Saving
          </span>
        )}
        {!this.props.saving && <span>{this.buttonText()}</span>}
      </button>
    );
  };

  render() {
    return this.render_SaveIndicator();
  }
}
