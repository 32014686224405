import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import DeviceStatusStore from "../../stores/DeviceStatusStore";
import { EditPermissionNew } from "./EditPermission";

export default class DiagnosticsAlarms extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      alarms: [],
      recent_alarms: [],
      visible__add_group: false,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    Api.get("/managedevice/ajax/device/alerts", function (result) {
      self.setState({
        alarms: result["data"],
        recent_alarms: DeviceStatusStore.getRecentAlerts(),
        loaded: true,
      });
    });
  };

  componentDidMount() {
    DeviceStatusStore.resetAlerts();
    this.handle_load();
  }

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  handler__clickOpenAdd = () => {
    this.setState({
      visible__add_permission: true,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Manage Alerting" onClickHandler={this.handler__clickOpenAdd} />;
  };

  handle__SaveRule = (permission) => {
    const self = this;
    Api.post("/managedevice/ajax/device/permissions", permission, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__edit_permission: true,
    });
  };

  render__content = () => {
    let columns = [
      {
        title: "Time",
        data: function (row) {
          return row["time"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("time"),
        search: FlexibleTable.search__single_string_field("time"),
        onclick: undefined,
      },
      {
        title: "Type",
        data: function (row) {
          return row["key"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("key"),
        search: FlexibleTable.search__single_string_field("key"),
        onclick: undefined,
      },
      {
        title: "Message",
        data: function (row) {
          return row["message"];
        },
        search: FlexibleTable.search__single_string_field("message"),
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.alarms}
        loaded={this.state.loaded}
        highlight_data={this.state.recent_alarms}
        sort_asc={false}
      />
    );
  };

  render() {
    return (
      // TODO: Only Manage Alert Recipients button has been refreshed
      <TableLeftPanel>
        <TableCentricPage icon="fa fa-bell" title="Alarms" buttons={<EditPermissionNew />} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
