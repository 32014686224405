import React from "react";
import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";
import MoveButton from "../../modules/MoveButton";
import DeleteButton from "../../modules/DeleteButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import RightPanel from "../../utils/RightPanel";
import FirewallRuleModal from "./FirewallRuleModal";

class FirewallRules extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    let self = this;
    this.setState({ loaded: false });

    Api.get("/edgewize/ajax/firewall/filtering", function (result) {
      self.setState({
        rules: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    let self = this;
    Api.put("/edgewize/ajax/firewall/filtering", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Rule" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (row) => {
    let self = this;
    Api.delete("/edgewize/ajax/firewall/filtering", { id: row["id"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    let self = this;
    Api.post("/edgewize/ajax/firewall/filtering", rule, function (result) {
      self.handle_load();
    });
  };

  handle_toggleRule = (row) => {
    if (row["enabled"]) {
      row["enabled"] = false;
    } else {
      row["enabled"] = true;
    }

    let self = this;
    Api.post("/edgewize/ajax/firewall/filtering", row, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__add_permission: true,
    });
  };

  handle_Move = (reorderedRules) => {
    const reorderedRulesIds = reorderedRules.map((rule) => rule.id);
    const compatIds = reorderedRulesIds.join(",");
    Api.post("/edgewize/device/firewall/filtering/reorder", { order: compatIds }, function () {});

    // Set the new rules
    this.setState({ rules: reorderedRules });
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Enabled",
        data: function (row) {
          return <input type="checkbox" checked={row["enabled"]} onChange={() => self.handle_toggleRule(row)} />;
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          return row["comment"];
        },
        onclick: undefined,
      },
      {
        title: "Source",
        data: function (row) {
          return <CriteriaPrinter criteria={row["source_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Destination",
        data: function (row) {
          return <CriteriaPrinter criteria={row["destination_criteria"]}></CriteriaPrinter>;
        },
        onclick: undefined,
      },
      {
        title: "Action",
        data: function (row) {
          if (row["action"] === 0) {
            return <span>DROP</span>;
          }

          return <span>ACCEPT</span>;
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <FirewallRuleModal existingRule={row} handleSubmit={(rule) => self.handle__SaveRule(rule)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)}></DeleteButton>
              <Separator />
              <MoveButton></MoveButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.rules}
        movable={true}
        movable_id={this.get__movable_id}
        onMove={this.handle_Move}
        loaded={this.state.loaded}
      />
    );
  };

  get__movable_id = (row) => {
    return row["id"];
  };

  render() {
    return <TableCentricPage icon="fa fa-shield" title="Firewall" buttons={this.render__buttons()} content={this.render__content()} />;
  }
}

class InboundRules extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      opts: {
        auto: true,
        wan_allow_icmp: true,
        wan_allow_ssh: true,
        wan_allow_web: true,
      },
    };
  }

  handle_load = () => {
    let self = this;
    Api.get("/edgewize/ajax/firewall/automatic", function (result) {
      self.setState({
        opts: result["data"],
      });
    });
  };

  handle_save = () => {
    let self = this;
    Api.post("/edgewize/ajax/firewall/automatic", this.state.opts, function (result) {
      self.handle_load();
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handle_ChangeAuto = (event) => {
    const opts = this.state.opts;
    opts["auto"] = event.target.checked;
    this.setState({ opts: opts });
  };

  handle_ChangeAllowIcmp = (event) => {
    const opts = this.state.opts;
    opts["wan_allow_icmp"] = event.target.checked;
    this.setState({ opts: opts });
  };

  handle_ChangeAllowSsh = (event) => {
    const opts = this.state.opts;
    opts["wan_allow_ssh"] = event.target.checked;
    this.setState({ opts: opts });
  };

  handle_ChangeAllowWeb = (event) => {
    const opts = this.state.opts;
    opts["wan_allow_web"] = event.target.checked;
    this.setState({ opts: opts });
  };

  render() {
    return (
      <form className="mui-form">
        <div className="mui-checkbox">
          <label>
            <input type="checkbox" checked={this.state.opts.auto} onChange={this.handle_ChangeAuto} />
            Automatically Block Inbound Traffic
          </label>
        </div>
        <div className="mui-checkbox">
          <label>
            <input type="checkbox" checked={this.state.opts.wan_allow_icmp} onChange={this.handle_ChangeAllowIcmp} />
            Allow ICMP
          </label>
        </div>
        <div className="mui-checkbox">
          <label>
            <input type="checkbox" checked={this.state.opts.wan_allow_ssh} onChange={this.handle_ChangeAllowSsh} />
            Allow SSH
          </label>
        </div>
        <div className="mui-checkbox">
          <label>
            <input type="checkbox" checked={this.state.opts.wan_allow_web} onChange={this.handle_ChangeAllowWeb} />
            Allow Web Admin
          </label>
        </div>
        <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_save}>
          Update
        </button>
      </form>
    );
  }
}

export default class Firewall extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return [
      <TableLeftPanel>
        <FirewallRules></FirewallRules>
      </TableLeftPanel>,

      <RightPanel>
        <div className="right-panel-heading">Configuration</div>
        <div className="padded">
          <InboundRules></InboundRules>
        </div>
      </RightPanel>,
    ];
  }
}
