import React, { useCallback, useContext, useEffect, useState } from "react";
import Api from "../../utils/Api";
import SignatureStore from "../../stores/SignatureStore";
import { useUserStore } from "../../storez/UserStore";
import SignatureActions from "../../actions/SignatureActions";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import "../../../css/ReportingBlocked.css";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { Box, Button, Flex, Text } from "@familyzone/component-library";
import { ButtonGroup } from "@chakra-ui/button";
import { PieChart } from "../../components/PieChart";

import { useMount, useSetState, useUnmount } from "react-use";

import { ReactRouterCompatContext } from "../ContextProvider";

import ReportingSearchableTable from "./ReportingSearchableTable";
import PageWithHeader from "../templates/PageWithHeader";
import { fullNameOrUsername } from "../UserSearch/UserSearchHelper";

export const UseContextProvider = () => useContext(ReactRouterCompatContext);

export const indexOptions = {
  users: "users",
  apps: "apps",
  policies: "policies",
};

export function findTitles(apiTrafficResult) {
  let data = [];
  let data_map = {};
  for (let appRow of apiTrafficResult) {
    let label = appRow["website"];
    if (appRow["tag"] !== appRow["categoryId"] && appRow["tag"] !== appRow["subCategoryId"]) {
      let stored_signature = SignatureStore.getSignature(appRow["tag"]);
      if (stored_signature) {
        label = stored_signature["name"];
      }
    }
    if (!label && appRow["tag.string"]) {
      label = appRow["tag.string"];
    }
    appRow.type_name = label;
    appRow.type = appRow["tag"];
    if (!data_map[appRow["type_name"]]) {
      data_map[appRow["type_name"]] = appRow;
    } else {
      data_map[appRow["type_name"]]["hits"] += appRow["hits"];
    }
  }
  data = Object.values(data_map);
  return data;
}

export function BlockedTable({ user_filtering, tab_update, date_changed }) {
  const [state, setState] = useSetState({
    app_filtering: [],
    policy_filtering: [],
    formatted_users: [],
    selected_index: UseContextProvider()?.router?.location.query.startingIndex || indexOptions.users,
    app_loaded: false,
    policy_loaded: false,
    users_loaded: false,
    isFetching: false,
  });

  const data_loaded = !state.isFetching && (state.app_loaded || state.policy_loaded || state.users_loaded);

  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);

  const load_policies = useCallback(
    (backgroundRefresh) => {
      setState({ isFetching: true });
      Api.get_analytics(
        "/surfwize/analytics/filtering/policies",
        function (result) {
          const policy_filtering = result["data"];
          if (policy_filtering) {
            for (let policy of policy_filtering) {
              policy["linkUrl"] = "/cybersafety/blocks/violations/" + policy["policy"] + "?&blocked=true&hits=true";
            }
          }
          if (backgroundRefresh) {
            setState({ policy_filtering: policy_filtering, policy_loaded: true, isFetching: false });
          } else {
            setState({
              policy_filtering: policy_filtering,
              selected_index: indexOptions.policies,
              policy_loaded: true,
              isFetching: false,
            });
          }
          tab_update(state.selected_index);
        },
        function (err) {
          console.log(err);
        }
      );
    },
    [setState, state.selected_index, tab_update]
  );

  const load_apps = useCallback(
    (backgroundRefresh) => {
      setState({ isFetching: true });
      Api.get_analytics(
        "/surfwize/analytics/traffic/typesandwebsites?blocked=true",
        function (result) {
          let data = findTitles(result.data);
          const types_websites = data;
          if (types_websites) {
            for (let app of types_websites) {
              if (app["type_name"] === app["domain"]) {
                app["linkUrl"] = "/surfwize/reporting/website/" + app["domain"] + "/users?&blocked=true&hits=true";
              } else {
                app["linkUrl"] = "/surfwize/reporting/type/" + app["type"] + "/users?&blocked=true&hits=true";
              }
            }
          }
          if (backgroundRefresh) {
            setState({ app_filtering: types_websites, app_loaded: true, isFetching: false });
          } else {
            setState({
              app_filtering: types_websites,
              selected_index: indexOptions.apps,
              app_loaded: true,
              isFetching: false,
            });
          }
          tab_update(state.selected_index);
        },
        function (err) {
          console.log(err);
        }
      );
    },
    [setState, state.selected_index, tab_update]
  );

  const loadFormattedUsers = useCallback(() => {
    if (user_filtering && user_filtering.length > 0) {
      setState({ isFetching: true });
      const userNames = user_filtering.map((userRow) => userRow.user);
      getUsersAsMap(userNames).then((users) => {
        let formattedUsers = [];
        for (let userRow of user_filtering) {
          const user = users.get(userRow["user"]);
          userRow["full_name"] = user ? fullNameOrUsername(user) : userRow["user"];
          userRow["linkUrl"] = "/surfwize/reporting/users/" + userRow["user"] + "/journey?verdict=Blocked";
          formattedUsers.push(userRow);
        }
        setState({
          formatted_users: formattedUsers,
          selected_index: indexOptions.users,
          users_loaded: true,
          isFetching: false,
        });
      });
    }
  }, [getUsersAsMap, setState, user_filtering]);

  useUnmount(() => {
    SignatureStore.unlisten(onChange);
  });

  const onChange = () => {
    setState({
      selected_index: state.selected_index,
    });
    if (state.selected_index === "users" && !state.users_loaded) {
      loadFormattedUsers();
    }
    if (state.selected_index === "apps" && !state.app_loaded) {
      load_apps(true);
    }
    if (state.selected_index === "policies" && !state.policy_loaded) {
      load_policies(true);
    }
  };

  useEffect(() => {
    SignatureStore.listen(onChange);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);

    return () => {
      SignatureStore.unlisten(onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_filtering]);

  const handle_show_users = () => {
    if (state.selected_index === indexOptions.users) {
      return;
    }

    if (state.users_loaded) {
      setState({ selected_index: indexOptions.users });
      return;
    }

    loadFormattedUsers();
  };

  const handle_load_apps = () => {
    if (state.selected_index === indexOptions.apps) {
      return;
    }
    if (state.app_loaded) {
      setState({ selected_index: indexOptions.apps });
      return;
    }
    load_apps();
  };

  const handle_load_policies = () => {
    if (state.selected_index === indexOptions.policies) {
      return;
    }

    if (state.policy_loaded) {
      setState({ selected_index: indexOptions.policies });
      return;
    }

    load_policies();
  };

  // new UI
  const newUI_get_data_columns = (index) => {
    switch (index) {
      case "users":
        return newUserDataColumns;
      case "apps":
        return newAppDataColumns;
      case "policies":
        return newPolicyDataColumns;
      default:
        return [];
    }
  };

  const get_data = (index) => {
    switch (index) {
      case "users":
        return state.formatted_users;
      case "apps":
        return state.app_filtering;
      case "policies":
        return state.policy_filtering;
      default:
        return [];
    }
  };
  // new UI
  const newUserDataColumns = [
    { name: "full_name", header: "Username", isLink: true },
    { name: "hits", header: "Hits" },
  ];
  // new UI
  const newAppDataColumns = [
    { name: "type_name", header: "Application", isLink: true },
    { name: "hits", header: "Hits" },
  ];
  // new UI
  const newPolicyDataColumns = [
    { name: "policy_name", header: "Policy", isLink: true },
    { name: "hits", header: "Hits" },
  ];

  const searchFilter = (row, searchTerm) => {
    return !searchTerm || row.username.includes(searchTerm);
  };
  // new UI
  const selectedButtonStyle = {
    _focus: { outline: "none", boxShadow: "none" },
    backgroundColor: "#42526E",
    color: "#FFFFFF",
  };
  // new UI
  const defaultButtonStyle = {
    outline: "0",
    border: "1px",
    borderColor: "#C1C7D0",
    backgroundColor: "#FFFFFF",
  };
  // new UI
  const buttonGroup = () => {
    return (
      <ButtonGroup isAttached>
        <Button
          sx={state.selected_index === indexOptions.users ? selectedButtonStyle : defaultButtonStyle}
          borderTopRightRadius="0px"
          borderBottomRightRadius="0px"
          onClick={handle_show_users}
        >
          Users
        </Button>
        <Button
          sx={state.selected_index === indexOptions.apps ? selectedButtonStyle : defaultButtonStyle}
          borderRadius="0px"
          onClick={handle_load_apps}
        >
          Apps
        </Button>
        <Button
          sx={state.selected_index === indexOptions.policies ? selectedButtonStyle : defaultButtonStyle}
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          onClick={handle_load_policies}
        >
          Policies
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <ReportingSearchableTable
      isInfiniteScroll
      data={get_data(state.selected_index)}
      loaded={data_loaded}
      columns={newUI_get_data_columns(state.selected_index)}
      searchFilter={searchFilter()}
      tableButtonGroup={buttonGroup()}
      initialSortColumn={newUI_get_data_columns(state.selected_index)[1].name}
      initialSortDirection={"desc"}
    />
  );
}

function BlockedApps({ data }) {
  return (
    <Box height="100%">
      <PieChart
        dataLoaded={data !== undefined}
        data={data}
        divname="amChart-pie_top_filtered_applications"
        name="App"
        points={"hits"}
        legend={true}
        chartName="apps"
        onClick={function (tag) {
          UseContextProvider().router.push("/surfwize/reporting/type/" + tag.tag + "/users?blocked=true");
        }}
      />
    </Box>
  );
}

function BlockedUsers({ data }) {
  const [state, setState] = useSetState({
    data: undefined,
  });
  const [topItems, setTopItems] = useState(null);

  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);

  const onChange = useCallback(() => {
    setState({
      data: state.data,
    });
  }, [setState, state.data]);

  useEffect(() => {
    onChange();
  }, [onChange]);

  useEffect(() => {
    async function fetchTopItems() {
      let tempTopItems = [];
      if (data) {
        const userNames = data.map((userRow) => userRow.user);
        const users = await getUsersAsMap(userNames);
        for (let item of data) {
          const user = users.get(item["user"]);
          tempTopItems.push({
            name: user ? fullNameOrUsername(user) : item["user"],
            y: item["hits"],
            user: item["user"],
          });
          if (tempTopItems.length === 6) {
            break;
          }
        }
      }

      // Update the state with the fetched data
      setTopItems(tempTopItems);
    }

    fetchTopItems();
  }, [data, getUsersAsMap]);

  return (
    <Box height="100%">
      <PieChart
        dataLoaded={topItems !== undefined}
        data={topItems}
        divname="amChart-pie_top_filtered_users"
        name="App"
        points={"hits"}
        legend={true}
        showPercentage={false}
        chartName="users"
        onClick={function (user) {
          UseContextProvider().router.push("/surfwize/reporting/types?blocked=true&filter_user=" + user.user);
        }}
      />
    </Box>
  );
}

export default function ReportingBlocked(props) {
  const [state, setState] = useSetState({
    user_data: undefined,
    application_data: undefined,
    types_websites: undefined,
    policy_filtering: undefined,
    user_data_loaded: false,
    current_tab: "users",
    date_changed: false,
  });

  useMount(() => {
    const unsubFilterStore = useDateRangeFilterStore.subscribe(handleDateChange);
    SignatureStore.listen(handleLoad);

    setTimeout(() => {
      SignatureActions.fetch();
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
      handleLoad();
    });

    return () => {
      unsubFilterStore();
      SignatureStore.unlisten(handleLoad);
    };
  });

  const handleDateChange = () => {
    setState({ date_changed: true });
    handleLoad();
  };

  const handleLoad = () => {
    // Loading should only occur when the Filter has changed to a blocked state:
    let application_data = [];
    const application_graph_data = [];
    let user_data = [];

    /* Save the datepicker state in state */

    const applicationsPromise = new Promise(function (resolve, reject) {
      Api.get_analytics(
        "/surfwize/device/ajax/webfiltering/applications?blocked=true",
        function (result) {
          application_data = result["data"];
          if (application_data) {
            for (let item of application_data) {
              let name = "";
              let signature = SignatureStore.getSignature(item["type"]);
              if (signature) {
                name = signature["name"];
              }
              application_graph_data.push({
                name: name,
                y: item["hits"],
                tag: item["type"],
              });

              if (application_graph_data.length === 6) {
                break;
              }
            }
            resolve(application_data, application_graph_data);
          }
        },
        function (err) {
          reject();
          console.log(err);
        }
      );
    });

    const userPromise = new Promise(function (resolve, reject) {
      Api.get_analytics(
        "/surfwize/analytics/filtering/users?blocked=true",
        function (result) {
          user_data = result["data"];
          resolve(user_data);
        },
        function (err) {
          reject();
          console.log(err);
        }
      );
    });

    Promise.all([userPromise, applicationsPromise]).then(function (values) {
      setState({
        application_data: values[1],
        application_graph_data: application_graph_data,
        user_data: user_data,
        user_data_loaded: true,
      });
    });
  };

  const tabUpdate = () => {
    setState({
      date_changed: false,
    });
  };

  return (
    <PageWithHeader
      breadcrumbs={[
        { title: "Cyber Safety", url: "/cybersafety", isActive: false },
        { title: "Blocked", url: "/cybersafety/blocks", isActive: true },
      ]}
      title="Blocked"
    >
      <Flex height="80%">
        <Box width="65%">
          <BlockedTable user_filtering={state.user_data ? state.user_data : []} tab_update={tabUpdate} date_changed={state.date_changed} />
        </Box>

        <Flex flexBasis="35%" flexDir="column">
          <Box height="40%" flexGrow={1} p="sp24" m="sp24" borderRadius="md" bgColor="neutrals.0">
            <Text color="#051839" fontSize="16px" fontWeight="700" lineHeight="20.45px">
              Top Filtered Applications
            </Text>
            <BlockedApps data={state.application_graph_data ? state.application_graph_data : undefined} />
          </Box>

          <Box height="40%" flexGrow={1} p="sp24" m="sp24" borderRadius="md" bgColor="neutrals.0">
            <Text color="#051839" fontSize="16px" fontWeight="700" lineHeight="20.45px">
              Top Filtered Users
            </Text>
            <BlockedUsers data={state.user_data ? state.user_data.slice(0, 6) : undefined} />
          </Box>
        </Flex>
      </Flex>
    </PageWithHeader>
  );
}
