import React from "react";

import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";

class AdvancedConfigItem extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      value: this.props.value,
    };
  }

  changeValue = (event) => {
    let val = event.target.type === "checkbox" ? event.target.checked : event.target.value;

    this.setState({
      value: val,
    });
    this.props.update(this.props.label, this.props.value.constructor(val));
  };

  render() {
    return (
      <div>
        <div className={typeof this.props.value === "boolean" ? "mui-checkbox" : "mui-textfield"}>
          <div className="formgroup-element">{this.props.label}</div>
          {typeof this.props.value === "boolean" ? (
            <input type="checkbox" onChange={this.changeValue} checked={this.state.value} />
          ) : typeof this.props.value === "number" ? (
            <input type="number" onChange={this.changeValue} value={this.state.value} required />
          ) : (
            <input type="text" onChange={this.changeValue} value={this.state.value} />
          )}
        </div>
      </div>
    );
  }
}

export default class DiagnosticsAdvancedConfiguration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      config: [],
      changes: false,
      saving: false,
    };
  }

  componentDidMount = () => {
    this.handle_load();
  };

  handle_load = () => {
    const self = this;
    Api.get(
      "/managedevice/ajax/device/advanced",
      (data) => {
        self.setState({ config: data["data"] });
      },
      (err) => console.log(err)
    );
  };

  handle_Submit = (event) => {
    let form = document.querySelector("form.mui-form");
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    let self = this;
    event.preventDefault();
    this.setState({ changes: false, saving: true });

    Api.post(
      "/managedevice/ajax/device/advanced/complete",
      this.state.config,
      (result) => {
        self.setState({ changes: false, saving: false });
      },
      (err) => console.log(err)
    );
  };

  handle_update = (key, value) => {
    let config = this.state.config;
    for (let item of config) {
      if (item["key"] === key) {
        item["value"] = value;
        break;
      }
    }
    this.setState({ config: config, changes: true });
  };

  renderConfig = () => {
    let configElements = [];
    for (let conf of this.state.config) {
      configElements.push(<AdvancedConfigItem key={conf["key"]} label={conf["key"]} value={conf["value"]} update={this.handle_update} />);
    }
    return configElements;
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Advanced Configuration</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="mui--text-danger">
                    These variables are intended to be modified only by Family Zone staff members. Please talk with the Support Team before
                    making changes.
                  </div>
                  <br></br>
                  {this.renderConfig()}
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              changes={this.state.changes}
              saving={this.state.saving}
              onClick={this.handle_Submit}
            ></LetterPanelSaveButton>
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
