import { CloseButton } from "@chakra-ui/react";
import { Flex, Icon, Spinner, Text } from "@familyzone/component-library";
import { motion } from "framer-motion";
import { LayoutProps } from "@chakra-ui/styled-system";
import React from "react";

export type StatusLabelProps = {
  variant: "error" | "info" | "success";
  minWidth?: LayoutProps["minWidth"];
  onHide?: () => void;
};

export const StatusLabel: React.FC<StatusLabelProps> = ({ children, variant, minWidth = "580px", onHide }) => {
  return (
    <motion.div key={"status-label-" + variant} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Flex
        data-testid="status-label"
        p="sp12"
        minWidth={minWidth}
        width="fit-content"
        borderRadius="sm"
        backgroundColor={variant + ".background"}
      >
        <Flex pt="sp4">
          {variant === "info" && <Icon icon="fa-info-circle" color="info.icon" variant="solid" boxSize="12px" />}
          {variant === "success" && <Icon icon="fa-check-circle" color="success.icon" variant="solid" boxSize="12px" />}
          {variant === "error" && <Icon icon="fa-warning" color="error.icon" variant="solid" boxSize="12px" />}
        </Flex>
        <Text flexGrow={1} color={variant === "error" ? "error.title" : "text.title"} fontSize="md" mx="sp12">
          {children}
        </Text>
        <Flex pt="sp4">
          {variant === "info" && <Spinner />}
          {variant !== "info" && onHide && <CloseButton size="md" color="neutrals.600" height="fit-content" onClick={onHide} />}
        </Flex>
      </Flex>
    </motion.div>
  );
};
