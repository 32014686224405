import React from "react";

class SisProviderIconButton extends React.PureComponent {
  render() {
    return (
      <button className="sis-provider-icon-button" onClick={this.props.onClick}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width="70" height="70" src={this.props.imgSrc} />
        <span>{this.props.label}</span>
      </button>
    );
  }
}

export default SisProviderIconButton;
