import alt from "../alt";

class InterfaceActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(devices) {
    return devices;
  }
}

export default alt.createActions(InterfaceActions);
