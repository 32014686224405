import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import ZoomingColumnGraph from "../../modules/ZoomingColumnGraph";
import FlexibleTable from "../../modules/FlexibleTable";
import AmountField from "../../modules/AmountField";
import SignatureStore from "../../stores/SignatureStore";
import SignatureActions from "../../actions/SignatureActions";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";

export default class ReportingDestinationPortUsers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      series: [],
      interval_multiplier: 24,
      loaded: false,
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.handle_load);
    SignatureStore.listen(this.handle_load);

    setTimeout(SignatureActions.fetch, 0);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    this.handle_load();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
    SignatureStore.unlisten(this.handle_load);
  }

  handle_load = () => {
    this.setState({
      data: [],
    });

    let self = this;
    Api.get_analytics(
      "/surfwize/analytics/destPort/" + this.props.params.destPort + "/users",
      function (result) {
        self.prepare_table(result["data"]);
        self.prepare_series(result["data"], result["period"]);
        self.setState({ loaded: true });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  prepare_table = (data_set) => {
    let user_data = {};
    let table_data = { data: [] };
    for (let idx in data_set) {
      let user = data_set[idx]["user"];
      if (!(user in user_data)) {
        user_data[user] = {
          user: user,
          download: data_set[idx]["download"],
          upload: data_set[idx]["upload"],
          transfer: data_set[idx]["transfer"],
        };
      } else {
        user_data[user]["download"] += data_set[idx]["download"];
        user_data[user]["upload"] += data_set[idx]["upload"];
        user_data[user]["transfer"] += data_set[idx]["transfer"];
      }
    }
    for (let user in user_data) {
      table_data["data"].push(user_data[user]);
    }
    this.setState({
      data: table_data["data"],
    });
  };

  prepare_series = (data_set, period) => {
    let series = [];
    let day_set = {};
    let date_entity = period === "PT1H" ? "day_time" : "day";
    for (let idx in data_set) {
      if (!(data_set[idx][date_entity] in day_set)) {
        day_set[data_set[idx][date_entity]] = data_set[idx]["transfer"];
      } else {
        day_set[data_set[idx][date_entity]] += data_set[idx]["transfer"];
      }
    }
    for (let day in day_set) {
      let info = [day];
      let ts;
      if (period === "PT1H") {
        ts = new Date(day).getTime();
      } else {
        let day_token = day.split("-");
        ts = new Date(parseInt(day_token[0]), parseInt(day_token[1]) - 1, parseInt(day_token[2])).getTime();
      }
      let day_data = {
        name: day,
        data: [
          {
            x: ts,
            y: day_set[day],
            url: undefined,
            info: info,
          },
        ],
      };
      series.push(day_data);
    }
    this.setState({
      series: series,
      interval_multiplier: period === "PT1H" ? 1 : 24,
    });
  };

  prepare_columns = () => {
    let self = this;
    let columns = [
      {
        title: "User",
        data: function (row) {
          return (
            <span>
              <Link to={"/surfwize/reporting/type/" + self.props.params.type + "/user/" + row["user"]}>{row["user"]}</Link>
            </span>
          );
        },
        search: FlexibleTable.search__single_string_field("user"),
        sortable: FlexibleTable.sortable__single_string_field("user"),
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          let name = row["name"] ? row["name"] : "-";
          return (
            <span>
              <Link to={"/surfwize/reporting/type/" + self.props.params.type + "/user/" + row["user"]}>{name}</Link>
            </span>
          );
        },
        search: FlexibleTable.search__single_string_field("name"),
        sortable: FlexibleTable.sortable__single_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Upload",
        data: function (row) {
          return <AmountField amount={row["upload"]} />;
        },
        sortable: FlexibleTable.sortable__single_numeric_field("upload"),
        onclick: undefined,
      },
      {
        title: "Download",
        data: function (row) {
          return <AmountField amount={row["download"]} />;
        },
        sortable: FlexibleTable.sortable__single_numeric_field("download"),
        onclick: undefined,
      },
      {
        title: "Transfer",
        data: function (row) {
          return <AmountField amount={row["upload"] + row["download"]} />;
        },
        sortable: FlexibleTable.sortable__multi_int_field("upload", "download"),
        onclick: undefined,
      },
    ];

    return columns;
  };

  render__content = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <div className="row-fluid">
            <div className="span12">
              <ZoomingColumnGraph data={this.state.series} interval_multiplier={this.state.interval_multiplier} />
            </div>
          </div>
        </div>
        <div className="main-content-sub-element">
          <div className="row-fluid">
            <div className="span12">
              <FlexibleTable
                columns={this.prepare_columns()}
                data={this.state.data}
                sort_column={4}
                sort_asc={false}
                loaded={this.state.loaded}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render_title = () => {
    return <span>Destination Port {this.props.params.destPort} Users</span>;
  };

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-rocket" title={this.render_title()}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
