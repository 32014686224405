import { getUsersByUsername } from "../../utils/api/Users";
import React from "react";
import LineReader from "../../utils/LineReader";
import { Box, InlineNotification } from "@familyzone/component-library";
import "../../../css/Associations.css";

export class ImportFile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: this.props.id,
      loading: 0,
      readerCallback: this.props.readerCallback,
      availableUsers: this.props.availableUsers,
      duplicationError: new Map(),
      nameArray: [],
      loadedCSV: false,
      invalidNames: "",
      macErrors: [],
    };
    this.csvObject = this.props.csvObject;
    this.userNameArray = [];
    this.macErrorMessage = [];
  }

  componentWillReceiveProps(newProps) {
    if (newProps.csvObject) {
      this.csvObject = this.props.csvObject;
    }
  }

  validateNames = async () => {
    if (!this.csvObject.lineErrors && !this.csvObject.macErrors) {
      try {
        const response = await getUsersByUsername(this.state.nameArray);
        const csvUsers = this.state.nameArray;
        const filteredUsers = csvUsers.filter((user) => !response.some((responseUser) => user === responseUser.username));
        if (filteredUsers.length > 0) {
          this.csvObject.addNameError();
          const invalidUsersString = filteredUsers.toString();
          const formattedInvalidUsers = invalidUsersString.replaceAll(",", ", ");
          this.setState({ invalidNames: formattedInvalidUsers });
        }
      } catch (err) {
        this.csvObject.addNameError();
        this.setState({ invalidNames: ["There were no valid usernames"] });
      }
    }
  };

  componentDidMount() {
    if (!this.csvObject.processed) {
      let lr = new LineReader();
      lr.on("line", this.readLine.bind(this));
      lr.on("progress", this.updateProgress.bind(this));
      lr.on("end", this.loadDone.bind(this));
      lr.read(this.csvObject.getFile());
    }
  }

  errorHandler(evt) {
    switch (evt.target.error.code) {
      case evt.target.error.NOT_FOUND_ERR:
        alert("File Not Found!");
        break;
      case evt.target.error.NOT_READABLE_ERR:
        alert("File is not readable");
        break;
      case evt.target.error.ABORT_ERR:
        break; // noop
      default:
        alert("An error occurred reading this file.");
    }
  }

  updateProgress(evt) {
    if (evt < 100) {
      this.setState({
        loading: evt,
      });
    }
  }

  readLine(line, callback) {
    this.csvObject.addLineRead();
    let entries = line.split(",");
    if (entries.length !== 2) {
      this.csvObject.addLineError();
      callback();
      return;
    }
    let name = entries[0].trim().replace(/['"]+/g, "");
    let macAddress = entries[1].trim().replace(/['"]+/g, "");
    /*
          Valid Mac Addresses:
              00-11-43-4A-B8-62
              0011.434A.B862
              0011434AB862
              01:23:45:67:89:ab
           */
    if (!/^[a-fA-F0-9:-]{17}|[a-fA-F0-9]{12}|[a-fA-F0-9.]{14}|([0-9,.]+)$/.test(macAddress)) {
      this.macErrorMessage.push("Bad Mac Address: " + macAddress + " [" + line + "]");
      this.csvObject.addMacError();
    } else {
      this.csvObject.addEntry(name, macAddress);
      this.userNameArray.push(name);
      const currentUsernames = this.userNameArray;
      this.setState({ nameArray: currentUsernames });
    }
    callback();
  }

  loadDone = async () => {
    await this.validateNames();
    this.csvObject.setProcessed(true);
    this.setState({ loading: 100 });

    if (this.macErrorMessage.length > 0) {
      this.setState({ macErrors: this.macErrorMessage });
    }
    if (!this.csvObject.macErrors && !this.csvObject.nameErrors && !this.csvObject.lineErrors) {
      this.props.setImportDisabled();
    }
    if (this.state.readerCallback) {
      this.setState({ duplicationError: this.state.readerCallback(this.csvObject) });
    }
  };

  handle_deleteFile() {
    this.props.deleteFile(this.csvObject);
  }

  render_issues() {
    let errorLog = [];
    let fileName = this.csvObject.getFile().name;
    if (this.state.duplicationError[fileName] && this.state.duplicationError[fileName].length > 0) {
      this.state.duplicationError[fileName].forEach((error) => {
        errorLog.push(<div key={fileName + " " + error}>{error}</div>);
      });
    }
    if (this.csvObject.lineErrors > 0) {
      errorLog.push(
        <div key={"lineErrors"}>{this.csvObject.lineErrors} Invalid Line(s). Each line requires a username and an address.</div>
      );
    }
    if (this.csvObject.macErrors > 0) {
      this.state.macErrors.forEach((error) => {
        errorLog.push(<div key={"macErrors"}>{error}</div>);
      });
    }
    if (this.csvObject.nameErrors > 0) {
      errorLog.push(<div></div>);
    }
    if (errorLog.length > 0) {
      return (
        <Box whiteSpace={"initial"}>
          <InlineNotification
            mt="16px"
            status="error"
            notificationTitle="Error"
            notificationDescription={
              <Box>
                {this.csvObject.nameErrors > 0 && (
                  <Box>
                    <Box>This files contains invalid usernames:</Box>
                    <Box>
                      {this.state.invalidNames}
                      <br></br>
                    </Box>
                  </Box>
                )}
                <Box>{errorLog}</Box>
              </Box>
            }
          ></InlineNotification>
        </Box>
      );
    }
    return <div className="importErrors" />;
  }

  render() {
    let file = this.csvObject.getFile();
    if (this.state.loading !== 100) {
      return (
        <div className="file">
          <div className={"fileName"}> {file.name}</div>
          <div className={"fileInfo"}>
            <div className={"loadingEntries"}>Loading ({this.state.loading.toFixed(0)}%)</div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <br></br>
          <div className="file">
            <div className={"fileName"}> {file.name}</div>
            <div className={"fileInfo"}>
              <div className={"uniqueEntries"}>
                {this.csvObject.getUniqueEntries().toLocaleString()} <i className="fa fa-user-circle" aria-hidden="true" />
              </div>
              <div className={"deleteButton"} onClick={this.handle_deleteFile.bind(this)}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
          </div>
          {this.render_issues()}
        </div>
      );
    }
  }
}
