import alt from "../alt";
import CountryActions from "../actions/CountryActions";

import Api from "../utils/Api";

class CountryStore {
  constructor() {
    this.countries = [];

    this.exportPublicMethods({
      getCountries: this.getCountries,
    });

    this.bindListeners({
      handleFetch: CountryActions.FETCH,
      handleFetchUpdated: CountryActions.FETCH_UPDATED,
    });
  }

  getCountries = () => {
    return this.countries;
  };

  handleFetchUpdated(result) {
    this.countries = result;
  }

  handleFetch = () => {
    if (this.countries.length === 0) {
      Api.get("/config/device/ajax/countries", function (result) {
        const devices = result["countries"];
        CountryActions.fetchUpdated(devices);
      });
    }
  };
}

export default alt.createStore(CountryStore, "CountryStore");
