import React from "react";
class TableSearchBox extends React.Component {
  render() {
    return (
      <span className="table-search-box">
        <span className="fa fa-search" />
        <input
          placeholder="Search"
          value={this.props.searchExpression}
          onChange={this.props.searchChangeHandler}
          data-cy="flexTableSearch"
        />
      </span>
    );
  }
}

export default TableSearchBox;
