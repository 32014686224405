import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import Api from "../../utils/Api";
import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";
import LeftPanel from "../../utils/LeftPanel";
import LineGraph from "../../modules/LineGraph";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";

class EdgewizeUplinkUtlisation extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      vsize: [],
      loaded: false,
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.getUtilisationData();
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.getUtilisationData);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  getUtilisationData = () => {
    Api.get_analytics(
      "/managedevice/ajax/device/metric/interface." + this.props.device + ".download_rate",
      (download) => {
        Api.get_analytics("/managedevice/ajax/device/metric/interface." + this.props.device + ".upload_rate", (upload) => {
          let download_rate = download["data"];
          let upload_rate = upload["data"];

          let utilisation_rate = [];

          for (let i = 0; i < download_rate.length; i++) {
            utilisation_rate.push({
              time: download_rate[i].time,
              value: download_rate[i].value + upload_rate[i].value,
            });
          }

          this.setState({
            vsize: utilisation_rate,
            loaded: true,
          });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  render() {
    return (
      <div className="formgroup">
        <div className="formgroup_title">
          <legend>{this.props.device} Utilisation</legend>
          <div className="gray-desc">Total Connection Utilisation in Mbit</div>
        </div>
        <div className="formgroup_content">
          <LineGraph data={this.state.vsize} container="utilization" xAxisFormatter="formatUnixTimestamp" dataLoaded={this.state.loaded} />
        </div>
      </div>
    );
  }
}

class NoUplinks extends React.Component {
  render() {
    return (
      <div className="formgroup">
        <div className="formgroup_title">
          <div className="formgroup_title_title">No WAN Uplinks</div>
        </div>
        <div className="formgroup_content">
          <div className="formgroup-listitem">
            <div className="formgroup-listitem-topline">
              <div className="formgroup-listitem-title">
                No WAN uplinks were found. To configure a WAN uplink, click <Link to={"/config/device/network/devices"}>here</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class EdgewizeClientVpnStatus extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      configuration: undefined,
      status: undefined,
    };
  }

  componentDidMount() {
    let self = this;
    Api.get("/edgewize/ajax/clientvpn", function (result) {
      self.setState({
        configuration: result["data"],
        status: result["status"],
      });
    });
  }

  renderStatus = () => {
    if (this.state.configuration === undefined || !this.state.configuration.auto_start) {
      return (
        <div className="formgroup-listitem">
          <div className="formgroup-listitem-topline">
            <div className="formgroup-listitem-title">Not Configured</div>
          </div>
        </div>
      );
    }

    if (this.state.configuration.auto_start) {
      return [
        <div className="formgroup-listitem">
          <div className="formgroup-listitem-topline">
            <div className="formgroup-listitem-title">Client VPN Enabled</div>
          </div>
        </div>,
        <div className="formgroup-listitem">
          <div className="formgroup-listitem-topline">
            <div className="formgroup-listitem-title">{this.state.status.active ? "Online and Ready" : "Not Active"}</div>
          </div>
        </div>,
        <div className="formgroup-listitem">
          <div className="formgroup-listitem-topline">
            <div className="formgroup-listitem-title">{this.state.status.active_clients.length} Active Clients</div>
          </div>
        </div>,
      ];
    }
  };
  handle_OpenClientVPN = () => {
    this.context.router.push("/edgewize/device/clientvpn");
  };

  render() {
    return (
      <div className="formgroup">
        <div className="formgroup_title">
          <div className="formgroup_title_title">Client VPN Status</div>
          <div className="formgroup_title_rightlink" onClick={this.handle_OpenClientVPN}>
            <i className="fa fa-cog" aria-hidden="true" />
          </div>
        </div>
        {this.renderStatus()}
      </div>
    );
  }
}

EdgewizeClientVpnStatus.contextTypes = {
  router: PropTypes.object.isRequired,
};

class UplinkStatus extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      devices: [],
    };
  }

  componentDidMount() {
    InterfaceStore.listen(this.onChange);

    setTimeout(() => {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  render_UplinksIndividual = (link) => {
    if (link.state) {
      return (
        <div className="formgroup-listitem">
          <div className="formgroup-listitem-topline">
            <div className="formgroup-listitem-title">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img className="rightpadded network-status-icon" src="/static/images/online_interface.png" /> {link.interface} ONLINE
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="formgroup-listitem">
        <div className="formgroup-listitem-topline">
          <div className="formgroup-listitem-title">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className="rightpadded network-status-icon" src="/static/images/offline_interface.png" /> {link.interface} OFFLINE
          </div>
        </div>
      </div>
    );
  };

  render_Uplinks = () => {
    let ret = [];
    for (let potential_uplink of this.state.devices) {
      if (potential_uplink.wan) {
        ret.push(this.render_UplinksIndividual(potential_uplink));
      }
    }
    return ret;
  };

  handle_OpenInterfaceConfiguration = () => {
    this.context.router.push("/config/device/network/devices");
  };

  render() {
    return (
      <div className="formgroup">
        <div className="formgroup_title">
          <div className="formgroup_title_title">Uplink Status</div>
          <div className="formgroup_title_rightlink" onClick={this.handle_OpenInterfaceConfiguration}>
            <i className="fa fa-cog" aria-hidden="true" />
          </div>
        </div>
        {this.render_Uplinks()}
      </div>
    );
  }
}

UplinkStatus.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default class EdgewizeDashboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      devices: [],
    };
  }

  componentDidMount() {
    InterfaceStore.listen(this.onChange);

    setTimeout(() => {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  renderUplinks() {
    let ret = [];
    for (let device of this.state.devices) {
      if (device.wan) {
        ret.push(<EdgewizeUplinkUtlisation key={"uplink-" + device.interface} device={device.interface} />);
      }
    }

    if (ret.length === 0) {
      ret.push(<NoUplinks key={"noUplinks"} />);
    }
    return ret;
  }

  render() {
    return (
      <LeftPanel>
        <div className="padded">
          <h2>Dashboard</h2>
          <div className="row">
            <div className="col-md-8">{this.renderUplinks()}</div>
            <div className="col-md-4">
              <UplinkStatus />
              <EdgewizeClientVpnStatus />
            </div>
          </div>
        </div>
      </LeftPanel>
    );
  }
}
