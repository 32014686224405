import moment from "moment";
import { TimelineEvent } from "./EventTimelineCard";

type GroupInReduce = {
  [key: string]: TimelineEvent[];
};

export const groupByTime = (events: TimelineEvent[]): { key: string; values: TimelineEvent[] }[] => {
  const sortedEvents = events.reduce((group: GroupInReduce, event) => {
    const timeDate = moment(event.time).format("YYYY-MM-DD, H");
    group[timeDate] ??= [];
    group[timeDate].push(event);
    return group;
  }, {});
  const keysObj = Object.keys(sortedEvents).map((k) => ({ key: k, values: sortedEvents[k] }));

  return keysObj;
};

export const eventStringFormatter = (event: TimelineEvent): string => {
  const ip = event.ip ? event.ip : "Unknown IP";
  switch (event.key) {
    case "event.userdb.auth.success":
      const macAddress = event.macAddress ? event.macAddress + " " : "";
      return `Log in from ${event.chrome_id ? event.chrome_id : macAddress + ip}`;
    case "event.userdb.session.timeout":
      return `Timeout from ${event.chrome_id ? event.chrome_id : ip}`;
    case "event.firewall.webfilter.hit":
      return event.host ? `Blocked from visiting ${event.host}` : "Blocked from visiting a website (host missing).";

    default:
      return `Unknown type of event ${event.key}`;
  }
};

export const searchFilter = (events: TimelineEvent[], searchTerm: string): TimelineEvent[] => {
  return events.filter((obj) => {
    return Object.keys(obj).some((key) => {
      const value = obj[key as keyof TimelineEvent];
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
};
