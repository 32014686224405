import React from "react";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import FlexibleTable from "../../modules/FlexibleTable";
import SignatureStore from "../../stores/SignatureStore";
import SignatureActions from "../../actions/SignatureActions";
import ConnectionInfoModal from "../debug/ConnectionInfoModal";

export default class ReportingViolationUserDay extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      series: [],
      detail__visible: false,
      detail__connection: undefined,
      loaded: false,
    };
    this.handle_load();
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    SignatureStore.listen(this.onChange);
    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    this.unsubFilterStore();
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.handle_load();
  };

  handle_OpenDetails = (connection) => {
    this.setState({
      detail__connection: connection,
      detail__visible: true,
    });
  };

  handle_CloseDetails = () => {
    this.setState({
      detail__connection: undefined,
      detail__visible: false,
    });
  };

  handle_load = () => {
    let self = this;
    Api.get_analytics(
      "/surfwize/analytics/filtering/user/" +
        self.props.params.user +
        "/individual?blocked=true&filter_policy=" +
        this.props.params.policyId,
      function (result) {
        self.setState({
          data: result["data"],
          loaded: true,
        });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  prepare_columns = () => {
    return [
      {
        title: "Time",
        data: function (row) {
          if (row["string_time"]) {
            return <span>{row["string_time"]}</span>;
          }
          return <span>{row["time"]}</span>;
        },
        search: FlexibleTable.search__single_string_field("string_time"),
        sortable: FlexibleTable.sortable__single_string_field("string_time"),
        onclick: this.handle_OpenDetails,
      },
      {
        title: "Policy",
        data: function (row) {
          return <span>{row["policy_name"]}</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("policy_name"),
        search: FlexibleTable.search__single_string_field("policy_name"),
        onclick: this.handle_OpenDetails,
      },
      {
        title: "Application",
        data: function (row) {
          let name = "";
          if (SignatureStore.getSignature(row["tag"])) {
            name = SignatureStore.getSignature(row["tag"])["name"];
          }
          return <span>{name}</span>;
        },
        search: FlexibleTable.search__single_string_field("tag"),
        sortable: FlexibleTable.sortable__single_string_field("tag"),
        onclick: undefined,
      },
      {
        title: "Website",
        data: function (row) {
          if (row["httpHost"] !== "") {
            return <span>{row["httpHost"]}</span>;
          }
          return <span>{row["destIp"]}</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("httpHost"),
        search: FlexibleTable.search__multiple(["httpHost", "destIp"]),
        onclick: undefined,
      },
      {
        title: "MAC",
        data: function (row) {
          return <span>{row["hwAddress"]}</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("hwAddress"),
        search: FlexibleTable.search__single_string_field("hwAddress"),
        onclick: undefined,
      },
      {
        title: "IP",
        data: function (row) {
          return <span>{row["sourceIp"]}</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("sourceIp"),
        search: FlexibleTable.search__single_string_field("sourceIp"),
        onclick: undefined,
      },
      {
        title: "Device Type",
        data: function (row) {
          return <span>{row["fingerprint_name"]}</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("fingerprint_name"),
        search: FlexibleTable.search__single_string_field("fingerprint_name"),
        onclick: undefined,
      },
    ];
  };

  render__content = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <div className="row-fluid">
            <div className="span12">
              <FlexibleTable
                columns={this.prepare_columns()}
                data={this.state.data}
                sort_column={0}
                sort_asc={true}
                loaded={this.state.loaded}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <TableLeftPanel>
        <ConnectionInfoModal
          visible={this.state.detail__visible}
          connection={this.state.detail__connection}
          handleClose={this.handle_CloseDetails}
        />
        <ChildrenCentricPage icon="fa fa-rocket" title={"Filtering Blocks for " + this.props.params.user}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
