import Api from "../utils/Api";

import alt from "../alt";
import RiskSelectorActions from "../actions/RiskSelectorActions";

class RiskSelectorStore {
  constructor() {
    this.data = undefined;
    this.totals = undefined;
    this.latestUsers = undefined;
    this.atRiskUsers = undefined;
    this.loaded = false;
    this.userRiskSelectorScore = undefined;

    this.exportPublicMethods({
      getData: this.getData,
      getTotals: this.getTotals,
      getLatestUsers: this.getLatestUsers,
      getAtRiskUsers: this.getAtRiskUsers,
      getLoaded: this.getLoaded,
      getUserRiskSelectorScore: this.getUserRiskSelectorScore,
    });

    this.bindListeners({
      handleFetch: RiskSelectorActions.FETCH,
      handleFetchUpdated: RiskSelectorActions.FETCH_UPDATED,
      reset: RiskSelectorActions.RESET,
    });
  }

  getData = () => {
    return this.data;
  };

  getRisks = () => {
    return this.risks;
  };

  getTotals = () => {
    return this.totals;
  };

  getLatestUsers = () => {
    return this.latestUsers;
  };

  getAtRiskUsers = () => {
    return this.atRiskUsers;
  };

  getLoaded = () => {
    return this.loaded;
  };

  getUserRiskSelectorScore = () => {
    return this.userRiskSelectorScore;
  };

  handleFetchUpdated = (userDataResult) => {
    this.data = userDataResult["data"] || [];
    this.totals = userDataResult["totals"] || {};
    this.atRiskUsers = userDataResult["sorted_users_highest_score"] || [];
    this.latestUsers = userDataResult["sorted_users_latest_hit"] || [];
    this.loaded = true;
    this.userRiskSelectorScore = userDataResult["users_normalised_scores"] || {};
  };

  handleFetch = () => {
    Api.get_analytics("/surfwize/device/ajax/analytic/riskselector", function (getResult) {
      RiskSelectorActions.fetchUpdated(getResult["data"]);
    });
  };

  reset = () => {
    this.data = [];
    this.totals = {};
    this.atRiskUsers = [];
    this.latestUsers = [];
    this.loaded = false;
    this.userRiskSelectorScore = {};
  };
}

export default alt.createStore(RiskSelectorStore, "RiskSelectorStore");
