import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import SearchableSelect from "../../modules/SearchableSelect";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rule: this.props.rule,
      devices: [],
    };
  }

  componentDidMount() {
    InterfaceStore.listen(this.onChange);

    setTimeout(function () {
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: InterfaceStore.getInterfaces(),
    });
  };

  componentWillReceiveProps(nextprops) {
    this.setState({
      rule: nextprops.rule,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    const rule = this.state.rule;
    rule.name = event.target.value;
    this.setState({ rule: rule });
  };

  handle_ChangeExternalIp = (event) => {
    const rule = this.state.rule;
    rule.externalIp = event.target.value;
    this.setState({ rule: rule });
  };
  handle_ChangeInternalIp = (event) => {
    const rule = this.state.rule;
    rule.internalIp = event.target.value;
    this.setState({ rule: rule });
  };

  handle_ChangeWanInterface = (event) => {
    const rule = this.state.rule;
    rule.interface = event.value;
    this.setState({ rule: rule });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.rule);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let i of this.state.devices) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Editing Rule" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">Name</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.rule.name} onChange={this.handle_ChangeName} />
              <label></label>
            </div>
            <div className="formgroup-element-title">External IP</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.rule.externalIp} onChange={this.handle_ChangeExternalIp} />
            </div>
            <div className="formgroup-element-title">Internal IP</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.rule.internalIp} onChange={this.handle_ChangeInternalIp} />
            </div>
            <div className="formgroup-element-title">Wan Device</div>
            <div className="mui-select">
              <SearchableSelect
                clearable={false}
                value={this.state.rule.interface}
                onChange={this.handle_ChangeWanInterface}
                valueDataMap={this.get_SelectionMap()}
              ></SearchableSelect>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class OneToOneNat extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: [],
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/wan/onetoone", function (result) {
      self.setState({
        rules: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    let self = this;
    Api.put("/edgewize/ajax/wan/onetoone", {}, function (result) {
      self.handle_load();
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Rule" onClickHandler={this.handler__clickAdd} />;
  };

  handle_Delete = (row) => {
    let self = this;
    Api.delete("/edgewize/ajax/wan/onetoone", { id: row["id"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    let self = this;
    Api.post("/edgewize/ajax/wan/onetoone", rule, function (result) {
      self.handle_load();
    });
  };

  handle_toggleRule = (row) => {
    row["enabled"] = !row["enabled"];

    let self = this;
    Api.post("/edgewize/ajax/wan/onetoone", row, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__add_permission: true,
    });
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Enabled",
        data: function (row) {
          return <input type="checkbox" checked={row["enabled"]} onChange={() => self.handle_toggleRule(row)} />;
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          return row["name"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("name"),
      },
      {
        title: "External IP",
        data: function (row) {
          return row["externalIp"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("externalIp"),
      },
      {
        title: "Internal IP",
        data: function (row) {
          return row["internalIp"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("internalIp"),
      },
      {
        title: "WAN Interface",
        data: function (row) {
          return row["interface"];
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("interface"),
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.rules} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddEditRule
          visible={this.state.visible__add_permission}
          handleSave={this.handle__SaveRule}
          handleClose={this.handler__clickCloseAdd}
          rule={this.state.editing__permission}
        ></AddEditRule>

        <TableCentricPage icon="fa fa-random" title="1-1 NAT" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
