import { Box, Button, Flex, Icon, InlineNotification, Modal, Text, useToast } from "@familyzone/component-library";
import $ from "jquery";
import React, { useState } from "react";
import "../../../../../css/Classrooms.css";
import ImportClassroomFileList from "./ImportClassroomFileList";
import ImportScheduleFileList from "./ImportScheduleFileList";
import { batchCreateClassrooms } from "../../../../utils/api/Classrooms";
import { useMount, useSetState } from "react-use";
import { importCSVSchedules } from "./CSVImportHelpers";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@chakra-ui/react";

export const ClassroomsImportCSVModal = (props) => {
  const { successToast, warningToast, errorToast } = useToast();

  const [state, setState] = useSetState({
    dropHover: false,
    uniqueClassroomEntries: {},
    classroomFiles: new Set(),
    scheduleObjects: new Set(),
    scheduleFiles: new Set(),
    importing: false,
  });

  const [dragCounter, setDragCounter] = useState(0);

  useMount(() => {
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
        handleDragLeave();
      },
      false
    );
    window.addEventListener("dragleave", handleDragLeave);
  });

  const handleHide = () => {
    setState({
      uniqueClassroomEntries: {},
      classroomFiles: new Set(),
      scheduleObjects: new Set(),
      scheduleFiles: new Set(),
    });
    props.handleClose();
  };

  const handleCreateClassrooms = () => {
    let classrooms = JSON.stringify(state.uniqueClassroomEntries);
    batchCreateClassrooms(classrooms)
      .then(() => {
        handleHide();
        successToast({ title: "Success!", description: "We've received the request and it has been sent for processing." });
      })
      .catch(() => {
        errorToast({
          title: "Error",
          description:
            "We're not able to process your file(s) at this time. Please ensure your file(s) are formatted correctly. See the example format on the form.",
        });
      })
      .finally(() => {
        setState({ importing: false });
      });
  };

  const handleImportSchedules = () => {
    importCSVSchedules(state.scheduleObjects)
      .then((response) => {
        handleHide();
        if (!response.errors || response.errors.length === 0) {
          if (response.importedClassroomsCount > 0 && response.importedPeriodsCount > 0) {
            // Scenario 1: Import complete, count of imported schedules is not 0, display success message
            successToast({
              title: "Import complete",
              description: `Imported ${response.importedPeriodsCount} schedule period(s) across ${response.importedClassroomsCount} classroom(s).`,
            });
          } else {
            // Scenario 2: Import complete with no error, but count is 0, display warning message
            // This is an edge case that can happen when you upload an empty CSV
            warningToast({
              title: "No schedules imported",
              description:
                "No schedules were imported. Please ensure your file(s) are formatted correctly. See the example format on the form.",
            });
          }
        } else {
          // Scenario 3: Import complete with errors (partial failures won't block the overall import), display warning message with errors
          warningToast({
            title: "Import complete with errors",
            description: `Imported ${response.importedPeriodsCount} schedule period(s) across ${response.importedClassroomsCount} classroom(s) with ${response.errors.length} error(s).`,
            children: (
              <Accordion allowToggle>
                <AccordionItem>
                  {response.errors.slice(0, 2).map((error, index) => (
                    <Text key={index} fontStyle="italic">
                      {error}
                    </Text>
                  ))}
                  {response.errors.length > 2 && (
                    <>
                      <AccordionPanel>
                        {response.errors.slice(2).map((error, index) => (
                          <Text key={index + 2} fontStyle="italic">
                            {error}
                          </Text>
                        ))}
                      </AccordionPanel>
                      <AccordionButton justifyContent="end">
                        <Icon icon="fa-ellipsis" />
                      </AccordionButton>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            ),
          });
        }
      })
      .catch(() => {
        // Scenario 4: Import failed, display error message
        errorToast({
          title: "Error",
          description:
            "We're not able to process your file(s) at this time. Please ensure your file(s) are formatted correctly. See the example format on the form.",
        });
      })
      .finally(() => {
        setState({ importing: false });
      });
  };

  const handleSubmit = () => {
    setState({ importing: true });
    if (Object.keys(state.uniqueClassroomEntries).length > 0) {
      handleCreateClassrooms();
    } else if (state.scheduleFiles.size > 0) {
      handleImportSchedules();
    } else {
      setTimeout(() => {
        setState({ importing: false });
        handleHide();
      }, 500);
    }
  };

  const renderActions = () => {
    if (state.importing) {
      return (
        <Button onClick={handleSubmit} variant="primary" disabled={true}>
          Importing
        </Button>
      );
    } else {
      return (
        <Button onClick={handleSubmit} variant="primary" data-testid="import-button">
          Import
        </Button>
      );
    }
  };

  const handleUpdateClassroomCsvObject = (classroomCsvObjects) => {
    setState({ uniqueClassroomEntries: updateUniqueClassroomEntryArray(classroomCsvObjects) });
  };

  const handleUpdateScheduleCsvObject = (scheduleCsvObjects) => {
    setState({ scheduleObjects: scheduleCsvObjects });
  };

  const handleDeleteClassroomCsvObject = (csvObject) => {
    let updatedFiles = state.classroomFiles;
    updatedFiles.delete(csvObject.getFile());
    setState({ classroomFiles: updatedFiles });
  };

  const handleDeleteScheduleCsvObject = (csvObject) => {
    let updatedFiles = state.scheduleFiles;
    updatedFiles.delete(csvObject.getFile());
    setState({ scheduleFiles: updatedFiles });
  };

  const handleDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
  };

  const handleDragEnter = (evt) => {
    setDragCounter(dragCounter + 1);
    setState({ dropHover: true });
  };

  const handleDragLeave = (evt) => {
    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setState({ dropHover: false });
    }
  };

  const handleClassroomDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setDragCounter(0);
    setState({ dropHover: false });
    setState({
      classroomFiles: addFiles(evt.dataTransfer.files, state.classroomFiles),
    });
  };

  const handleScheduleDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setDragCounter(0);
    setState({ dropHover: false });
    setState({
      scheduleFiles: addFiles(evt.dataTransfer.files, state.scheduleFiles),
    });
  };

  const handleClassroomFileSelect = (evt) => {
    setState({
      classroomFiles: addFiles(evt.target.files, state.classroomFiles),
    });
  };

  const handleScheduleFileSelect = (evt) => {
    setState({
      scheduleFiles: addFiles(evt.target.files, state.scheduleFiles),
    });
  };

  function addFiles(files, currentFileList) {
    let validFiles = [];
    for (let file of files) {
      if (file.type) {
        if (
          file.type === "text/csv" ||
          file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          validFiles.push(file);
        }
      } else {
        // Windows 10 and Windows 7 sometimes return an empty string for the file type,
        // so we can check the extension as to whether the file is a csv or not
        if (file.name.endsWith(".csv")) {
          validFiles.push(file);
        }
      }
    }
    let updatedSet = currentFileList;
    for (let file of validFiles) {
      if (
        Array.from(updatedSet).filter(
          (innerFile) => innerFile.name === file.name && innerFile.size === file.size && innerFile.lastModified === file.lastModified
        ).length === 0
      ) {
        updatedSet.add(file);
      }
    }
    return updatedSet;
  }

  function updateUniqueClassroomEntryArray(classroomCsvObjects) {
    let ret = {};
    for (let csvObj of classroomCsvObjects) {
      let entries = csvObj.entries;
      for (let classroom in entries) {
        let users = entries[classroom];
        if (!ret[classroom]) {
          ret[classroom] = {};
        }

        for (let username in users) {
          ret[classroom][username] = users[username];
        }
      }
    }
    return ret;
  }

  if (props.visible) {
    return (
      <Modal headerText={"Import CSV"} onClose={handleHide} isOpen={props.visible} size="md">
        <form className="mui-form">
          <div style={{ display: state.scheduleFiles.size === 0 ? "block" : "none" }}>
            <div
              id="drop_zone_1"
              style={{ visibility: state.dropHover ? "visible" : "hidden" }}
              className={state.classroomFiles.size > 0 ? "full_size" : ""}
            >
              <div className={"dropBorder"}>
                <div
                  className={"dropContent"}
                  onDrop={handleClassroomDrop}
                  onDragEnter={() => {
                    $("#drop_zone_1").addClass("drag_over");
                  }}
                  onDragOver={() => {
                    $("#drop_zone_1").addClass("drag_over");
                  }}
                  onDragLeave={() => {
                    $("#drop_zone_1").removeClass("drag_over");
                  }}
                >
                  <div className={"dropText"}>Upload Classrooms</div>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
            <div className="mui-textfield" id={"file_picker_1"}>
              <input
                type="file"
                id="files_1"
                data-testid="files_1"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleClassroomFileSelect}
                multiple
              />
              <Button variant="primary" data-testid="upload-classrooms-button">
                <label className="importLabel" htmlFor="files_1">
                  Upload Classrooms
                </label>
              </Button>
              <InlineNotification
                mt="16px"
                status="info"
                notificationTitle={"Expected Classrooms CSV Format"}
                notificationDescription={
                  <Box>
                    <div>Columns: user, class, is_owner</div>
                    <div>Example:</div>
                    <div>oscar.maccay, classroom1, true</div>
                    <div>steve.johnson, classroom1, false</div>
                  </Box>
                }
              />
              <ImportClassroomFileList
                files={state.classroomFiles}
                updateCsvObjectCallback={handleUpdateClassroomCsvObject}
                deleteCsvObjectCallback={handleDeleteClassroomCsvObject}
              />
            </div>
          </div>
          <div style={{ display: state.classroomFiles.size === 0 ? "block" : "none" }}>
            <div
              id="drop_zone_2"
              style={{ visibility: state.dropHover ? "visible" : "hidden" }}
              className={state.scheduleFiles.size > 0 ? "full_size" : ""}
            >
              <div className={"dropBorder"}>
                <div
                  className={"dropContent"}
                  onDrop={handleScheduleDrop}
                  onDragEnter={() => {
                    $("#drop_zone_2").addClass("drag_over");
                  }}
                  onDragOver={() => {
                    $("#drop_zone_2").addClass("drag_over");
                  }}
                  onDragLeave={() => {
                    $("#drop_zone_2").removeClass("drag_over");
                  }}
                >
                  <div className={"dropText"}>Upload Classroom Schedules</div>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
            <div className="mui-textfield" id={"file_picker_2"}>
              <input
                type="file"
                id="files_2"
                data-testid="files_2"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleScheduleFileSelect}
                multiple
              />
              <Button variant="primary" data-testid="upload-schedules-button">
                <label className="importLabel" htmlFor="files_2">
                  Upload Classroom Schedules
                </label>
              </Button>
              <InlineNotification
                mt="16px"
                status="info"
                notificationTitle={"Expected Classroom Schedules CSV Format"}
                notificationDescription={
                  <Box>
                    <div>Schedules can not be added unless the class has been started and ended in Classwize.</div>
                    <div>Column titles: class, day, sched_start, sched_end</div>
                    <div>Valid days: mon, tue, wed, thur, fri, sat, sun.</div>
                    <div>Valid schedule times: 0000-2345 (4-digit times in 15 minute increments).</div>
                    <div>Example:</div>
                    <div>classroom1, mon, 0815, 0945</div>
                    <div>classroom1, mon, 1115, 1200</div>
                  </Box>
                }
              />

              <ImportScheduleFileList
                files={state.scheduleFiles}
                updateCsvObjectCallback={handleUpdateScheduleCsvObject}
                deleteCsvObjectCallback={handleDeleteScheduleCsvObject}
              />
            </div>
          </div>
        </form>
        {(state.classroomFiles.size > 0 || state.scheduleFiles.size > 0) && <Flex width="100%">{renderActions()}</Flex>}
      </Modal>
    );
  }
  return <div />;
};
