export function addGCParam(): boolean {
  const paramString = window.location.href.split("?");
  if (paramString.length > 1) {
    const params = new URLSearchParams(paramString[1]);
    const gcParam = params.get("gc");
    if (gcParam === "True") {
      return true;
    }
  }
  return false;
}
