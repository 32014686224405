/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import SessionStore from "../../stores/SessionStore";

type Headers = {
  Device: string;
  Token: string;
  UserId: string;
  IsSupportAdmin: string;
  "Content-Type": string;
  actorId: string;
};

export const headers = (): Headers => {
  return {
    Device: SessionStore.getSelectedDevice(),
    Token: SessionStore.getToken(),
    UserId: SessionStore.getUserId(),
    IsSupportAdmin: SessionStore.isSupportAdmin(),
    "Content-Type": "application/json",
    actorId: SessionStore.getUserId(),
  };
};
