import { Flex, Spinner } from "@familyzone/component-library";
import React from "react";

interface Props {
  margin?: string;
}

const CardSpinner = ({ margin }: Props): JSX.Element => {
  return (
    <Flex margin={margin} justifyContent="center" alignItems="center" flexGrow={1}>
      <Spinner data-testid="spinner" size="lg" />
    </Flex>
  );
};

export default CardSpinner;
