import React, { useState } from "react";
import { Link } from "react-router";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { useUserStore } from "../../storez/UserStore";
import { useEffectOnce } from "react-use";

const ReportingViolations = (props) => {
  const [data, setData] = useState([]);
  const [policy, setPolicy] = useState({});
  const [loaded, setLoaded] = useState(false);
  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);

  const handle_load = () => {
    Api.get_analytics("/surfwize/analytics/filtering/policy/" + props.params.policyId, async (result) => {
      let data = result["data"];
      const usernames = result["data"].map((item) => item["user"]);
      const users = await getUsersAsMap(usernames);

      data.forEach((item) => {
        item["name"] = users.get(item["user"]) ? users.get(item["user"]).firstName + " " + users.get(item["user"]).lastName : "";
      });
      setData(data);
      setLoaded(true);
    });

    Api.get_analytics("/surfwize/device/ajax/policy/" + props.params.policyId, (result) => {
      setPolicy(result["policy"]);
    });
  };

  const policy_name = () => {
    return policy?.name ?? "";
  };

  useEffectOnce(() => {
    const unsubFilterStore = useDateRangeFilterStore.subscribe(onChange);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
    handle_load();

    return () => {
      unsubFilterStore();
    };
  });

  const onChange = () => {
    handle_load();
  };

  const render__content = () => {
    const columns = [
      {
        title: "User",
        data: function (row) {
          return <Link to={"/cybersafety/blocks/violations/" + props.params.policyId + "/user/" + row["user"]}>{row["user"]}</Link>;
        },
        sortable: FlexibleTable.sortable__single_string_field("user"),
        search: FlexibleTable.search__single_string_field("user"),
        onclick: undefined,
      },
      {
        title: "Full Name",
        data: function (row) {
          return row["name"] ? row["name"] : "-";
        },
        sortable: FlexibleTable.sortable__single_string_field("name"),
        search: FlexibleTable.search__single_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Number of attempts",
        data: function (row) {
          return row["hits"];
        },
        sortable: FlexibleTable.sortable__single_numeric_field("hits"),
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={data} sort_column={2} sort_asc={false} loaded={loaded} />;
  };

  return (
    <TableLeftPanel>
      <TableCentricPage icon="fa fa-shield" title={"Policy Violations for " + policy_name()} content={render__content()} />
    </TableLeftPanel>
  );
};

export default ReportingViolations;
