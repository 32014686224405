import React from "react";
import ObjectPrinter from "../criteria/ObjectPrinter";

import FingerprintActions from "../actions/FingerprintActions";
import FingerprintStore from "../stores/FingerprintStore";
import SignatureActions from "../actions/SignatureActions";
import SignatureStore from "../stores/SignatureStore";
import PeriodStore from "../stores/PeriodStore";
import PeriodActions from "../actions/PeriodActions";
import CriteriaPrinterGroups from "./CriteriaPrinterGroups";

class FingerprintCriteriaPrinter extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      availableFingerprints: FingerprintStore.getFingerprints(),
    };
  }

  componentDidMount() {
    FingerprintStore.listen(this.onChange);

    setTimeout(() => {
      FingerprintActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    FingerprintStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableFingerprints: FingerprintStore.getFingerprints(),
    });
  };

  render_Fingerprints = () => {
    if (this.props.values.length === 0) {
      return <span />;
    }

    let ret = [];
    for (let fingerprint of this.props.values) {
      const resolved_fingerprint = FingerprintStore.getFingerprint(fingerprint);
      if (resolved_fingerprint) {
        ret.push(<span>{resolved_fingerprint["name"]}, </span>);
      } else {
        ret.push(<span>{fingerprint}</span>);
      }
    }

    return ret;
  };

  render() {
    return (
      <span>
        {this.render_Fingerprints()}
        {this.props.children}
      </span>
    );
  }
}

class SignatureCriteriaPrinter extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      availableSignatures: SignatureStore.getSignatures(),
    };
  }

  componentDidMount() {
    SignatureStore.listen(this.onChange);

    setTimeout(() => {
      SignatureActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SignatureStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableSignatures: SignatureStore.getSignatures(),
    });
  };

  render_Fingerprints = () => {
    if (this.props.values.length === 0) {
      return <span />;
    }

    let ret = [];
    for (let signature of this.props.values) {
      let resolved_sig = SignatureStore.getSignature(signature);
      if (resolved_sig) {
        ret.push(<span key={"signature" + ret.length}>{resolved_sig["name"]}, </span>);
      } else {
        ret.push(<span key={"signature" + ret.length}>{signature}</span>);
      }
    }

    return ret;
  };

  render() {
    return (
      <span>
        {this.render_Fingerprints()}
        {this.props.children}
      </span>
    );
  }
}

class TimeperiodPrinter extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      availableTimeperiods: PeriodStore.getPeriods(),
    };
  }

  componentDidMount() {
    PeriodStore.listen(this.onChange);

    setTimeout(() => {
      PeriodActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    PeriodStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableTimeperiods: PeriodStore.getPeriods(),
    });
  };

  render_Periods = () => {
    if (this.props.values.length === 0) {
      return <span />;
    }

    let ret = [];
    for (let val of this.props.values) {
      for (let period of this.state.availableTimeperiods) {
        if (period["id"] === val) {
          ret.push(<span>{period["name"]}</span>);
        }
      }
    }

    return ret;
  };

  render() {
    return (
      <span>
        {this.render_Periods()}
        {this.props.children}
      </span>
    );
  }
}

class ProtocolPrinter extends React.Component {
  static get availableProtocols() {
    return {
      1: "ICMP",
      2: "IGMP",
      3: "GGP",
      6: "TCP",
      8: "EGP",
      9: "IGP",
      17: "UDP",
      88: "EIGRP",
      89: "OSPF",
      47: "GRE",
      50: "ESP",
      51: "AH",
      58: "ICMPv6",
    };
  }

  render_Protocol = () => {
    if (this.props.values.length === 0) {
      return <span />;
    }
    return <span>{"Protocol " + ProtocolPrinter.availableProtocols[this.props.values.protocol]}</span>;
  };

  render() {
    return (
      <span>
        {this.render_Protocol()}
        {this.props.children}
      </span>
    );
  }
}

export default class CriteriaPrinter extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
    };
  }

  render_ListCriteria = () => {
    if (!this.props.criteria || this.props.criteria.length === 0) {
      return <span>Any *</span>;
    }

    let separator = ", ";
    let ret = [];
    for (let criteria of this.props.criteria) {
      switch (criteria["type"]) {
        case "ipv4.range": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["start_ip"]} - {criteria["conditions"]["end_ip"]}
              {separator}
            </span>
          );
          break;
        }
        case "source.mac": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["mac"]}
              {separator}
            </span>
          );
          break;
        }
        case "ipv4.address": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["ip"]}
              {separator}
            </span>
          );
          break;
        }
        case "ipv4": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["ip"]}/{criteria["conditions"]["mask"]}
              {separator}
            </span>
          );
          break;
        }
        case "device": {
          ret.push(
            <span key={"criteria" + ret.length}>
              Interface {criteria["conditions"]["name"]}
              {separator}
            </span>
          );
          break;
        }
        case "application.http.hostname.regex": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["expression"]}
              {separator}
            </span>
          );
          break;
        }
        case "fingerprint": {
          ret.push(<FingerprintCriteriaPrinter key={"criteria" + ret.length} values={criteria["conditions"]} />);
          break;
        }
        case "signature": {
          ret.push(<SignatureCriteriaPrinter key={"criteria" + ret.length} values={criteria["conditions"]} />);
          break;
        }
        case "ipv4.alias":
        case "source.mac.pool":
        case "application.http.hostname": {
          ret.push(<ObjectPrinter key={"criteria" + ret.length} values={criteria["conditions"]} />);
          break;
        }
        case "transport.port": {
          ret.push(
            <span key={"criteria" + ret.length}>
              Ports {criteria["conditions"].join(", ")}
              {separator}
            </span>
          );
          break;
        }
        case "transport.portrange": {
          ret.push(
            <span key={"criteria" + ret.length}>
              Ports {criteria["conditions"]["startPort"]}-{criteria["conditions"]["endPort"]}
              {separator}
            </span>
          );
          break;
        }
        case "geoip": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["country"]}
              {separator}
            </span>
          );
          break;
        }
        case "timeperiod": {
          ret.push(
            <TimeperiodPrinter key={"criteria" + ret.length} values={criteria["conditions"]}>
              {separator}
            </TimeperiodPrinter>
          );
          break;
        }
        case "protocol": {
          ret.push(
            <ProtocolPrinter key={"criteria" + ret.length} values={criteria["conditions"]}>
              {separator}
            </ProtocolPrinter>
          );
          break;
        }
        case "application.http.contenttype.regex": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["expression"]}
              {separator}
            </span>
          );
          break;
        }
        case "application.http.useragent.regex": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["expression"]}
              {separator}
            </span>
          );
          break;
        }
        case "application.http.request.regex": {
          ret.push(
            <span key={"criteria" + ret.length}>
              {criteria["conditions"]["expression"]}
              {separator}
            </span>
          );
          break;
        }
        case "source.user": {
          let conditions = criteria.conditions;
          for (let condition of conditions) {
            ret.push(
              <span key={"criteria" + ret.length}>
                {condition}
                {separator}
              </span>
            );
          }
          break;
        }
        case "group": {
          ret.push(<CriteriaPrinterGroups groups={criteria.conditions} />);
          break;
        }
        case "exclude.group": {
          ret.push(<CriteriaPrinterGroups groups={criteria.conditions} />);
          break;
        }
        default: {
          break;
        }
      }
    }

    return ret;
  };

  /**
   * Get rendered plain text from given a criteria array. This method is originally designed for FlexibleTable search
   * @param {Object[]} criteria array
   * @returns {string} plain text calculated by CriteriaPrinter
   */
  static getSearchText(criteria) {
    if (!criteria || criteria.length === 0) {
      return "Any *";
    }
    return criteria
      .map((criterion) => {
        const type = criterion.type;
        const conditions = criterion.conditions;
        if (type && conditions) {
          // Special rule for ProtocolPrinter mapping
          if (type === "protocol") {
            return ProtocolPrinter.availableProtocols[conditions.type];
          }
          // Special rule for FingerprintCriteriaPrinter mapping
          if (type === "fingerprint") {
            return conditions.map((condition) => FingerprintStore.getFingerprint(condition)["name"]).join(" ");
          }
          // Special rule for TimeperiodPrinter mapping
          if (type === "timeperiod") {
            const periods = PeriodStore.getPeriods();
            return conditions.map((condition) => periods.find((period) => period.id === condition)["name"]).join(" ");
          }
          // Object pool
          if (type === "ipv4.alias") {
            return ObjectPrinter.getObjectsText(conditions).join(" ");
          }
          // General case 1: concatenate object values and omit keys
          if (typeof conditions === "object") {
            return Object.values(conditions).join(" ");
          }
          // General case 2: concatenate array values
          if (Array.isArray(conditions)) {
            return conditions.join(" ");
          }
        }
        console.log(`Unknown criteria ${JSON.stringify(criterion)} - unable to search`);
        return " ";
      })
      .join(" ");
  }

  render() {
    return <span>{this.render_ListCriteria()}</span>;
  }
}
