import React from "react";
import { Link } from "react-router";
import InfoIconV2 from "../../../modules/InfoIconV2";

interface Props {
  total: string;
  title: string;
  infoText: string;
  viewLink: string;
}

const PersonaMiniCard: React.FC<Props> = ({ total, title, infoText, viewLink }) => {
  return (
    <div className="personaMiniCard">
      <h1 className="cardTotal">{total}</h1>
      <div className="cardTitle">
        {title}
        <InfoIconV2 backgroundColor="grey" text={infoText} placement="bottom" />
      </div>
      <div className="cardLink">
        <Link to={viewLink} className="personaModule-centerLink">
          View All
        </Link>
      </div>
    </div>
  );
};

export default PersonaMiniCard;
