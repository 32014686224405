import React from "react";
import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria, MacObject } from "../criteriaTypes";

export interface MacPoolCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
  macObjects: MacObject[];
}

const MacPoolCriteria: React.FC<MacPoolCriteriaProps> = ({ criteria, disabled, onChange, macObjects }) => {
  const { t } = useTranslation();

  if (!Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  // Map the list of users to a selector-usable array of label/value pairs
  const macObjectOptions: Option[] = macObjects.map((i) => ({ label: i.name, value: i.id }));

  // Take the criteria conditions and convert them to label/value pairs
  const selectedMacObjectOptions: Option[] = criteria.conditions
    .map((id) => macObjectOptions.find((o) => o.value === id))
    .filter((b): b is Option => !!b);

  const handleChangeSourceMacObjects = (options: Option[]) => {
    onChange({ ...criteria, conditions: [...options.map((o: Option) => macObjects.find((u) => u.id === o.value)?.id || "")] });
  };

  return (
    <MultiSearchSelector
      disabled={disabled}
      placeholder={t("Select MAC Objects")}
      options={macObjectOptions}
      selected={selectedMacObjectOptions}
      onChange={handleChangeSourceMacObjects}
    />
  );
};

export default MacPoolCriteria;
