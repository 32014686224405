import alt from "../alt";
import CaptivePortalActions from "../actions/CaptivePortalActions";
import SessionActions from "../actions/SessionActions";
import Api from "../utils/Api";

class CaptivePortalStore {
  constructor() {
    this.portals = [];
    this.loaded = false;

    this.exportPublicMethods({
      getPortals: this.getPortals,
      getPortal: this.getPortal,
      hasLoaded: this.hasLoaded,
    });

    this.bindListeners({
      handleFetch: CaptivePortalActions.FETCH,
      handleFetchUpdated: CaptivePortalActions.FETCH_UPDATED,

      handleDeletePortal: CaptivePortalActions.DELETE_PORTAL,
      handleAddPortal: CaptivePortalActions.ADD_PORTAL,
      handleSavePortal: CaptivePortalActions.SAVE_PORTAL,

      handleDestroy: SessionActions.DESELECT_DEVICE,
      // Unsure why two keys have the same value here, but it's what the original code has and since it's untested, I am leaving it as is
      // for now until. When Alt.js is removed, this will need to be handled.
      // eslint-disable-next-line no-dupe-keys
      handleDestroy: SessionActions.CHANGE_DEVICE,
    });
  }

  getPortals = () => {
    return this.portals;
  };

  getPortal = (id) => {
    for (let portal of this.portals) {
      if (portal["id"] === id) {
        return portal;
      }
    }
  };

  hasLoaded = () => {
    return this.loaded;
  };

  handleFetchUpdated(result) {
    this.portals = result;
    this.loaded = true;
  }

  handleFetch = () => {
    this.loaded = false;
    Api.get("/config/ajax/authentication", function (data) {
      CaptivePortalActions.fetchUpdated(data["data"]);
    });
  };

  handleDeletePortal = (portal_id) => {
    Api.delete("/config/ajax/authentication/" + portal_id, {}, function (data) {
      CaptivePortalActions.fetch();
    });
  };

  handleAddPortal = () => {
    Api.put("/config/ajax/authentication", {}, function (data) {
      CaptivePortalActions.fetch();
    });
  };

  handleSavePortal = (portal) => {
    Api.post("/config/ajax/authentication", portal, function (data) {
      CaptivePortalActions.fetch();
    });
  };

  handleDestroy = () => {
    this.portals = [];
  };
}

export default alt.createStore(CaptivePortalStore, "CaptivePortalStore");
