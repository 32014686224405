import React from "react";
import DeleteButton from "../../modules/DeleteButton";

import EditButton from "../../modules/EditButton";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import Separator from "../../modules/Separator";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import ConfigStore from "../../stores/ConfigStore";

import Api from "../../utils/Api";
import { batchResolveGroupDescriptions } from "../../utils/api/Groups";
import { cy } from "../../utils/CypressUtil";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { groupLabelFormatter } from "../GroupSearch/GroupSearchHelper";
import DevicePermissionsModal from "./ManageDevicePermissionsModal";

class PermissionEnumerator {
  static available() {
    return [
      { id: "owner", name: "Owner/Global Administrator" },
      { id: "surfwize_admin", name: "Filtering/Reporting Administrator" },
      { id: "surfwize_filtering", name: "Filtering Administrator" },
      { id: "surfwize_reporting", name: "Reporting/Pastoral Care Administrator" },
      { id: "surfwize_settings", name: "Configuration Administrator" },
      { id: "surfwize_guest_settings", name: "Guest Configuration" },
      { id: "edgewize", name: "Wan and Firewall Administrator" },
      { id: "classroom_admin", name: "Classroom Administrator" },
      { id: "classroom_ed-tech", name: "Classroom Ed-Tech Manager" },
      { id: "surfwize_cloud_filter_admin", name: "Cloud Only Filter Administrator" },
      { id: "surfwize_cloud_dns_filter_admin", name: "Cloud DNS Filter Administrator" },
      { id: "community_admin", name: "Community Administrator" },
    ];
  }

  static get_name(id) {
    for (let permission of PermissionEnumerator.available()) {
      if (permission["id"] === id) {
        return permission["name"];
      }
    }
  }
}

class EditPermission extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      permission: this.props.permission,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      permission: nextprops.permission,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Save
      </button>
    );
  };

  handle_AddPermission = (event) => {
    /* First check that we do not have duplicates */
    for (let existing_entry of this.state.permission.permissions) {
      if (existing_entry === event.value) {
        return;
      }
    }

    this.state.permission.permissions.push(event.value);
    this.setState({ permission: this.state.permission });
  };

  handle_RemovePermission = (permission) => {
    const statePermission = this.state.permission;
    statePermission.permissions = statePermission.permissions.filter(function (per) {
      return per !== permission;
    });

    this.setState({ permission: this.state.permission });
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.permission);
    this.props.handleClose();
  };

  render_Permissions = () => {
    let ret = [];
    for (let permission of this.state.permission.permissions) {
      ret.push(
        <div className="listselector-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="signature_item_remove selected-item-remove" onClick={() => this.handle_RemovePermission(permission)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
          {this.get_Name(permission)}
        </div>
      );
    }

    return ret;
  };

  get_Name = (id) => {
    return PermissionEnumerator.get_name(id);
  };

  get_SelectorItems = () => {
    const ret = [];
    for (let permission of PermissionEnumerator.available()) {
      ret.push([permission["id"], permission["name"]]);
    }
    return ret;
  };

  handle_AddUser = (username) => {
    const permission = this.state.permission;
    if (permission.users === undefined) {
      permission.users = [];
    }

    permission.users.push(username);
    this.setState({ permission: permission });
  };

  handle_RemoveUser = (username) => {
    let ret = [];
    const permission = this.state.permission;
    for (let user of permission.users) {
      if (user !== username) {
        ret.push(user);
      }
    }

    permission.users = ret;
    this.setState({ permission: permission });
  };

  handle_ChangePermission = (selected) => {
    const permission = this.state.permission;
    permission.permissions = selected.map((selected) => selected.text);
    this.setState({ permission: { ...permission, permission: permission } });
  };

  handle_ChangeGroup = (selected) => {
    const permission = this.state.permission;
    permission.groups = selected.map((selected) => selected.name);
    this.setState({ permission: { ...permission, permission: permission } });
  };

  handle_ChangeUser = (selected) => {
    const permission = this.state.permission;
    permission.users = selected;
    this.setState({ permission: { ...permission, permission: permission } });
  };

  render() {
    if (this.state.permission) {
      return (
        <DevicePermissionsModal
          permission={this.state.permission}
          email={this.state.permission.email}
          selectedPermissions={this.state.permission.permissions}
          permissionOptions={this.get_SelectorItems()}
          handleHide={this.handleHide}
          visible={this.props.visible}
          onChange={this.handle_ChangePermission}
          onChangeGroup={this.handle_ChangeGroup}
          onChangeUser={this.handle_ChangeUser}
          onSave={this.handle_Submit}
        ></DevicePermissionsModal>
      );
    }
    return <div />;
  }
}

class AddPermission extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: "",
    };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button
        data-cy={cy("Add Permission", "Submit")}
        button
        type="submit"
        className="mui-btn mui-btn--raised"
        onClick={this.handle_Submit}
      >
        Add Permission
      </button>
    );
  };

  changeEmailAddress = (event) => {
    this.setState({ email: event.target.value });
  };

  handle_Submit = (event) => {
    event.preventDefault();
    this.props.handleAdd(this.state.email);
    this.handleHide();
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add User" handleHide={this.handleHide} actions={this.renderActions()} disable_overflow={true}>
          <div className="mui-form">
            <div className="mui-textfield">
              <div className="formgroup-element">Email Address</div>
              <input type="text" data-cy={cy("Add User", "Email")} value={this.state.email} onChange={this.changeEmailAddress} />
            </div>
          </div>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class ManageDevicePermissions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissions: [],
      groups: [],
      timezone: ConfigStore.getItemInConfig("timezone"),
      visible__add_group: false,
      editing__permission: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/managedevice/ajax/device/permissions", function (result) {
      if (result.data) {
        const groupsToResolve = result.data.map((permission) => permission.groups).flat();
        batchResolveGroupDescriptions(groupsToResolve).then((groups) => {
          self.setState({
            permissions: result["data"],
            resolvedGroupDescriptions: groups,
            loaded: true,
          });
        });
      } else {
        self.setState({
          permissions: result["data"],
          resolvedGroupDescriptions: [],
          loaded: true,
        });
      }
    });
  };

  componentDidMount() {
    ConfigStore.listen(this.configStoreListener);
    this.handle_load();
  }

  configStoreListener = () => {
    this.setState({
      timezone: ConfigStore.getItemInConfig("timezone"),
    });
  };

  componentWillUnmount() {
    ConfigStore.unlisten(this.configStoreListener);
  }

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  handler__clickOpenAdd = () => {
    this.setState({
      visible__add_permission: true,
    });
  };

  handler__clickCloseEdit = () => {
    this.setState({
      visible__edit_permission: false,
    });
  };

  render__buttons = () => {
    return (
      <TableCentricPageButton
        data-cy={cy("Add Permissions", "Button")}
        title="Add Permissions"
        onClickHandler={this.handler__clickOpenAdd}
      />
    );
  };

  handle_Delete = (row) => {
    let self = this;
    Api.delete("/managedevice/ajax/device/permissions", { accountid: row["accountid"] }, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (permission) => {
    let self = this;
    Api.post("/managedevice/ajax/device/permissions", permission, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      editing__permission: row,
      visible__edit_permission: true,
    });
  };

  handle__AddPermission = (email) => {
    let self = this;

    const data = {
      email: email,
      timezone: this.state.timezone,
    };

    Api.put("/managedevice/ajax/device/permissions", data, function (result) {
      self.handle_load();
    });
  };

  displayPermissions = (row) => {
    let permissions = [];
    for (let permission of row["permissions"]) {
      permissions.push(PermissionEnumerator.get_name(permission));
    }
    return permissions;
  };

  sortablePermissions = (rowA, rowB) => {
    return FlexibleTable.sort_caseinsensitive_strings(this.displayPermissions(rowA).join(), this.displayPermissions(rowB).join());
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Account",
        data: function (row) {
          return row["email"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("email"),
        onclick: undefined,
      },
      {
        title: "Permissions",
        data: function (row) {
          let ret = [];
          const displayPermissions = self.displayPermissions(row);
          for (let permission of displayPermissions) {
            let separator = displayPermissions.length >= 2 && ret.length < displayPermissions.length - 1 ? ", " : "";
            ret.push(
              <div>
                {permission}
                {separator}
              </div>
            );
          }
          return ret;
        },
        sortable: this.sortablePermissions,
        onclick: undefined,
      },
      {
        title: "Users",
        data: function (row) {
          if (row["users"].length === 0) {
            return "*";
          }

          return row["users"].join(", ");
        },
        sortable: FlexibleTable.sortable__caseinsensitive_string_array_field("users"),
        onclick: undefined,
      },
      {
        title: "Groups",
        data: function (row) {
          let ret = [];

          if (row["groups"].length === 0) {
            ret.push(<span>*</span>);
          } else {
            for (let groupName of row["groups"]) {
              let separator = row["groups"].length >= 2 && ret.length < row["groups"].length - 1 ? ", " : "";
              const group = self.state.resolvedGroupDescriptions.find((group) => group.name === groupName);
              const label = group ? groupLabelFormatter(group.name, group.description, group.distinguishedName) : groupName;
              ret.push(
                <div>
                  {label}
                  {separator}
                </div>
              );
            }
          }

          return ret;
        },
        sortable: FlexibleTable.sortable__caseinsensitive_string_array_field("groups"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.permissions} loaded={this.state.loaded} className="cell-vertical-align-top" />;
  };

  render() {
    return (
      <TableLeftPanel>
        <EditPermission
          visible={this.state.visible__edit_permission}
          handleSave={this.handle__SaveRule}
          handleClose={this.handler__clickCloseEdit}
          permission={this.state.editing__permission}
        ></EditPermission>
        <AddPermission
          visible={this.state.visible__add_permission}
          handleAdd={this.handle__AddPermission}
          handleClose={this.handler__clickCloseAdd}
        ></AddPermission>

        <TableCentricPage icon="fa fa-user" title="Permissions" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
