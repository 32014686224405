import { Table, Td, Tr } from "@chakra-ui/table";
import { Box, Button, Flex, Modal, useTranslation, Text, TextInput, SingleSearchSelector, Option } from "@familyzone/component-library";
import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { displayTypesArray, ObjectPool } from "../../../types/Objects";

interface ObjectPoolsProps {
  open: boolean;
  editing: boolean;
  objectPool: ObjectPool | null;
  onSave: (objectPool: ObjectPool) => void;
  loaded: boolean;
  onClose: () => void;
}

const NewObjectPools: React.FC<ObjectPoolsProps> = ({
  open,
  editing,
  objectPool,
  onSave = () => "",
  loaded,
  onClose = () => "",
}: ObjectPoolsProps) => {
  const [poolName, setPoolName] = useState("");
  const [poolDescription, setPoolDescription] = useState("");
  const [poolType, setPoolType] = useState(2);
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const headerText = editing ? t("Edit Object Pool") : t("Add Object Pool");
  const createObjectPoolText = t("Save Object Pool");

  const convertedOptions: Option[] = useMemo(() => {
    return displayTypesArray.map((displayType) => ({
      label: displayType[1].toString(),
      value: displayType[0],
    }));
  }, []);

  useEffect(() => {
    if (editing) {
      if (!objectPool) {
        return;
      }
      setPoolName(objectPool.name);
      setPoolDescription(objectPool.desc);
      setPoolType(objectPool.type);
    } else {
      setPoolName("");
      setPoolDescription("");
      setPoolType(2);
    }
  }, [editing, objectPool]);

  const handlePoolNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPoolName(e.target.value);
  };

  const handlePoolDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPoolDescription(e.target.value);
  };

  const handleChangeSelected = (option: Option | null) => {
    setError("");
    if (option) {
      if (typeof option.value === "number") {
        setPoolType(option.value);
      }
    }
  };

  const handleClickSubmit = () => {
    if (!loaded) {
      return;
    }
    if (poolName.length < 3) {
      setError("The name must be more than 3 characters");
      return;
    }
    setError("");

    if (editing) {
      onSave({
        ...objectPool,
        id: objectPool?.id ? objectPool.id : undefined,
        name: poolName,
        desc: poolDescription,
        type: poolType,
        named_entries: objectPool?.type === poolType ? objectPool.named_entries : [],
      });
      setPoolDescription("");
      setPoolName("");
      setPoolType(2);
      return;
    } else {
      onSave({
        id: objectPool?.id ? objectPool.id : undefined,
        name: poolName,
        desc: poolDescription,
        type: poolType,
      });
      setPoolDescription("");
      setPoolName("");
      setPoolType(2);
    }
  };

  const handleonClose = () => {
    setPoolName("");
    setPoolDescription("");
    setPoolType(2);
    setError("");
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={handleonClose} headerText={headerText} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box my="sp24" overflowY="auto" py="sp4" overflow={"visible"}>
        <Table>
          <Tr>
            <Td>
              <Text fontSize="medium" color="text.title">
                Name:
              </Text>
            </Td>
            <Td>
              <TextInput
                aria-label="name"
                placeholder={t("Enter a Name")}
                value={poolName}
                onChange={handlePoolNameChange}
                isDisabled={!loaded}
              />
              {error && <Text color="error">The name must be more than 3 characters</Text>}
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Text fontSize="medium" color="text.title">
                Description:
              </Text>
            </Td>
            <Td>
              <TextInput
                aria-label="description"
                placeholder={t("Enter a Description")}
                value={poolDescription}
                onChange={handlePoolDescriptionChange}
                isDisabled={!loaded}
              />
            </Td>
          </Tr>
          {!editing && (
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  Type:
                </Text>
              </Td>
              <Td>
                <SingleSearchSelector
                  options={convertedOptions}
                  onChange={handleChangeSelected}
                  filterOptions={false}
                  showClearIcon={false}
                  defaultValue={2}
                  selected={convertedOptions.find((option) => option.value === poolType)}
                />
              </Td>
            </Tr>
          )}
        </Table>
      </Box>

      <Flex>
        <Button variant="primary" onClick={handleClickSubmit} isLoading={!loaded} aria-label="save" data-testid="save-policy-button">
          {createObjectPoolText}
        </Button>
      </Flex>
    </Modal>
  );
};

export default NewObjectPools;
