import React from "react";
import { Icon } from "@familyzone/component-library";
import { VariantTypes } from "@familyzone/component-library/dist/components/Icon";
import { innerColors, RedFlagSeverity } from "./RedFlagPill";

export type RedFlagIconType = "vpn" | "search" | "webpage";

export interface RedFlagPillIconProps {
  type: RedFlagIconType;
  severity: RedFlagSeverity;
  testid?: string;
}

const icons: Record<RedFlagIconType, string> = {
  vpn: "fa-gear",
  search: "fa-magnifying-glass",
  webpage: "fa-globe",
};

const variants: Record<RedFlagIconType, VariantTypes> = {
  vpn: "solid",
  search: "solid",
  webpage: "light",
};

export const RedFlagPillIcon = ({ type, severity, testid }: RedFlagPillIconProps): JSX.Element => {
  return <Icon data-testid={testid} icon={icons[type]} variant={variants[type]} color={innerColors[severity]} />;
};
