import React from "react";
import { Text, TextInput, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";

export interface IPv4AddressCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const IPv4AddressCriteria: React.FC<IPv4AddressCriteriaProps> = ({ criteria, disabled, onChange }) => {
  const { t } = useTranslation();

  if (Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  const handleChangeIpAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(criteria.conditions)) return;
    onChange({ ...criteria, conditions: { ip: e.target.value } });
  };

  return (
    <TextInput
      name="ip address"
      placeholder={t("IP Address")}
      isDisabled={disabled}
      value={String(criteria.conditions.ip)}
      onChange={handleChangeIpAddress}
    />
  );
};

export default IPv4AddressCriteria;
