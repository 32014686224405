import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalContent, ModalFooter, ModalBody, ModalOverlay, ModalCloseButton, Input, Text } from "@chakra-ui/react";
import { SISProvider } from "./SisIntegration";
import Api from "../../../utils/Api";
import { Box, InlineNotification } from "@familyzone/component-library";

interface Props {
  rowToEdit: SISProvider;
  show: boolean;
  handleHide: () => void;
  handleSubmit: (schoolIDsToExclude: string[], rowToEdit: SISProvider) => void;
}

interface GetSchoolResponse {
  schools: School[];
}

interface School {
  id: string;
  name: string;
}

export const SisSchoolExcludeForm: React.FC<Props> = ({ rowToEdit, show, handleHide, handleSubmit }) => {
  const [schools, setSchools] = useState<School[]>([]);
  const [schoolFilter, setSchoolFilter] = useState<string>("");
  const [schoolExcludeFilter, setSchoolExcludeFilter] = useState<string>("");
  const [schoolsToExclude, setSchoolsToExclude] = useState<School[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(() => {
    handleSubmit(
      schoolsToExclude.map((school) => school.id),
      rowToEdit
    );
  }, [schoolsToExclude, rowToEdit, handleSubmit]);

  const getSchools = useCallback(() => {
    setLoading(true);
    Api.get(
      "/config/ajax/sis/schools/" + rowToEdit.id,
      (result: GetSchoolResponse) => {
        setSchools(result.schools);
        setLoading(false);
        setError(null);
        setSchoolsToExclude(result.schools.filter((school) => rowToEdit.excluded_schools_list?.includes(school.id) ?? false));
      },
      () => {
        setLoading(false);
        setError("Failed to load schools, please check your configuration and try again.");
      }
    );
  }, [rowToEdit]);

  const handleAddToExclude = useCallback((school: School) => {
    setSchoolsToExclude((prevSchools) => [...prevSchools, school]);
  }, []);

  const handleRemoveFromExclude = useCallback((school: School) => {
    setSchoolsToExclude((prevSchools) => prevSchools.filter((s) => s.id !== school.id));
  }, []);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  return (
    <Modal isOpen={show} onClose={handleHide} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={"800px"} maxHeight={"80%"} height={"80%"}>
        <ModalCloseButton marginLeft={"auto"} />
        <ModalBody display={"flex"} overflow={"hidden"}>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", margin: "auto", alignItems: "center", gap: "0.5rem" }}>
              Loading <i className="fa fa-refresh fa-spin fa-fw" />
            </Box>
          )}
          {!loading && !error && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", width: "100%", height: "100%", overflow: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "0.5rem",
                  }}
                >
                  <Text fontSize={"22px"} fontWeight={"bold"} margin={"auto"}>
                    Included Schools
                  </Text>
                  <Input value={schoolFilter} onChange={(e) => setSchoolFilter(e.target.value)} placeholder="Filter schools" size="sm" />
                  <Box as={"button"} className="mui-btn mui-btn--raised modal-button" onClick={() => setSchoolsToExclude(schools)}>
                    Exclude All
                  </Box>
                  <SchoolList
                    schools={schools.filter(
                      (school) =>
                        !schoolsToExclude.some((s) => s.id === school.id) &&
                        (school.name.toLowerCase().includes(schoolFilter.toLowerCase()) ||
                          school.id.toLowerCase().includes(schoolFilter.toLowerCase()))
                    )}
                    filter={schoolFilter}
                    onSchoolAction={handleAddToExclude}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "0.5rem",
                  }}
                >
                  <Text fontSize={"22px"} fontWeight={"bold"} margin={"auto"}>
                    Excluded Schools
                  </Text>
                  <Input
                    value={schoolExcludeFilter}
                    onChange={(e) => setSchoolExcludeFilter(e.target.value)}
                    placeholder="Filter excluded schools"
                    size="sm"
                  />
                  <Box as={"button"} className="mui-btn mui-btn--raised modal-button" onClick={() => setSchoolsToExclude([])}>
                    Clear
                  </Box>
                  <SchoolList schools={schoolsToExclude} filter={schoolExcludeFilter} onSchoolAction={handleRemoveFromExclude} />
                </Box>
              </Box>
              <Text margin={"auto"}>Click on a School to move it between include and exclude.</Text>
            </Box>
          )}
          {error && (
            <Box margin={"auto"}>
              <InlineNotification status="error" notificationTitle={"Unable to load schools"} notificationDescription={error} />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="mui-btn mui-btn--raised modal-button" onClick={onSubmit} disabled={loading || !!error}>
            Submit
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface School {
  id: string;
  name: string;
}

interface SchoolListProps {
  schools: School[];
  filter: string;
  onSchoolAction: (school: School) => void;
}

const SchoolList: React.FC<SchoolListProps> = ({ schools, filter, onSchoolAction }) => {
  const filteredSchools = useMemo(
    () =>
      schools.filter(
        (school) => school.name.toLowerCase().includes(filter.toLowerCase()) || school.id.toLowerCase().includes(filter.toLowerCase())
      ),
    [schools, filter]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        border: "1px solid #ccc",
        padding: "0.5rem",
        flex: 1,
        gap: "2px",
      }}
    >
      {filteredSchools.map((school) => (
        <SchoolItem key={school.id} school={school} onActionClick={() => onSchoolAction(school)} />
      ))}
    </Box>
  );
};

interface SchoolItemProps {
  school: School;
  onActionClick: () => void;
}

const SchoolItem: React.FC<SchoolItemProps> = ({ school, onActionClick }) => (
  <Box
    as="button"
    onClick={onActionClick}
    sx={{
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "2px",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }}
  >
    <Text>{school.name}</Text>
    <Text>{school.id}</Text>
  </Box>
);
