import React from "react";

export default class FullPageLoader extends React.Component {
  render() {
    return (
      <div data-testid="loadingSpinner" className="loading_element">
        <i className="fa-solid fa-spinner-third fa-spin fa-3x fa-fw" />
      </div>
    );
  }
}
