import alt from "../alt";

class PeriodActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(periods) {
    return periods;
  }

  invalidateAndFetch() {
    return "";
  }

  addPeriod(name) {
    return name;
  }

  deletePeriod(id) {
    return id;
  }
}

export default alt.createActions(PeriodActions);
