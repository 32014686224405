import React from "react";
import { Box, Flex, useTranslation, Button, Icon, Spinner, SearchBox } from "@familyzone/component-library";

export const createRuleText = "Add Policy";
export const searchBoxText = "Search";

export interface SafeSearchLayoutProps {
  disabled?: boolean;
  loading?: boolean;
  onClickCreateRule?: (e: React.SyntheticEvent) => void;
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch?: (e: React.SyntheticEvent) => void;
  search?: string;
}

const SafeSearchLayout: React.FC<SafeSearchLayoutProps> = ({
  disabled = false,
  loading = false,
  onClickCreateRule = () => "",
  onChangeSearch = () => "",
  onClearSearch = () => "",
  search = "",
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Flex width="100%" p="sp24">
        <Box backgroundColor="neutrals.0" borderRadius="6px" width="100%" pl="sp12" pr="sp12" pt="sp12">
          <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Box pl="sp12" pr="sp12" flexGrow={1} maxWidth={536}>
              <SearchBox
                name="searchbox"
                placeholder={t(searchBoxText)}
                value={search}
                onChange={onChangeSearch}
                onClear={onClearSearch}
                width="100%"
              />
            </Box>
            <Flex justifyContent="end" alignItems="center" flexWrap="wrap">
              {disabled && (
                <Box p="sp4">
                  <Spinner data-testid="loading-spinner" />
                </Box>
              )}
              {!loading && (
                <Box p="sp12">
                  <Button
                    variant="primary"
                    leftIcon={<Icon icon="fa-plus" boxSize="18px" />}
                    onClick={onClickCreateRule}
                    disabled={disabled}
                  >
                    {t(createRuleText)}
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>
          <Box px="sp12" pb="sp12">
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default SafeSearchLayout;
