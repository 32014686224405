import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { NonSchoolRule, RecurrenceID } from "../../../types/Calendar";
import { Box, Checkbox, Flex, Icon, InlineNotification, Modal, Radio, RadioGroup, useTranslation } from "@familyzone/component-library";
import React, { useState } from "react";
import dayjs from "dayjs";
import { RuleUpdateType } from "../../../storez/SchoolCalendarStore";

interface DeleteNonSchoolDaysModalProps {
  open: boolean;
  day: NonSchoolRule;
  onClose: () => void;
  onDelete: (rule: NonSchoolRule, recurrenceId?: RecurrenceID, deleteType?: RuleUpdateType) => void;
}

const DeleteNonSchoolDaysModal: React.FC<DeleteNonSchoolDaysModalProps> = ({
  open,
  day,
  onClose,
  onDelete,
}: DeleteNonSchoolDaysModalProps) => {
  const { t } = useTranslation();

  const [deleteType, setDeleteType] = useState<RuleUpdateType>(RuleUpdateType.ONLY_THIS_INSTANCE);

  const handleChangeDeleteType = () => {
    setDeleteType((type) =>
      type === RuleUpdateType.ONLY_THIS_INSTANCE ? RuleUpdateType.THIS_AND_FUTURE_INSTANCES : RuleUpdateType.ONLY_THIS_INSTANCE
    );
  };

  const handleDeleteConfirm = () => {
    let recurrenceId = undefined;
    if (day.recurrence) {
      if (day.startDate && day.recurrence) {
        recurrenceId = dayjs(day.startDate * 1000).unix();
      }
    }
    onDelete(day, recurrenceId, deleteType);
  };

  const hasStarted = (): boolean => {
    const today = dayjs().utc(true).startOf("day").unix();
    return day.startDate < today;
  };

  return (
    <Modal
      isOpen={open}
      size="md"
      variant="danger"
      onClose={onClose}
      headerText={t("Delete Non-School Day")}
      primaryCTALabel={t("Delete")}
      onPrimaryCTAClick={handleDeleteConfirm}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={onClose}
    >
      <Flex mt="sp24" flexDirection="column">
        <FormControl mb="sp12">
          <FormLabel fontWeight="bold">{day?.name}</FormLabel>
        </FormControl>
        <FormControl mb="sp12">
          <Flex direction="row" alignItems="center">
            <Icon mr="sp8" icon="fa-calendar-days" boxSize="16px" color="text.paragraph.light" />{" "}
            <FormLabel>
              {day.startDate && day.endDate
                ? new Date(day?.endDate * 1000).getDate() !== new Date(day?.startDate).getDate()
                  ? `${dayjs(new Date(day?.startDate * 1000)).format("MMM DD, YYYY")} - ${dayjs(new Date(day?.endDate * 1000)).format(
                      "MMM DD, YYYY"
                    )}`
                  : `${dayjs(new Date(day?.startDate * 1000)).format("MMM DD, YYYY")}`
                : day?.startDate
                ? dayjs(new Date(day?.startDate * 1000)).format("MMM DD, YYYY")
                : ""}
            </FormLabel>
          </Flex>
        </FormControl>
        {day?.recurrence && (
          <>
            <FormControl mb="sp24">
              <Checkbox isChecked={day?.recurrence.frequency === "yearly"} isDisabled={true} isReadOnly={true}>
                <FormLabel>{t("Recurs Yearly")}</FormLabel>
              </Checkbox>
            </FormControl>
            <FormControl mb="sp8">
              <FormLabel fontWeight="medium">{t("Delete Recurring Non-School Day")}</FormLabel>
            </FormControl>
            <Box mb="sp8">
              <RadioGroup direction="column">
                <FormControl position="relative">
                  <Radio isChecked={deleteType === RuleUpdateType.ONLY_THIS_INSTANCE} onChange={handleChangeDeleteType}>
                    <FormLabel>{t("This day")}</FormLabel>
                  </Radio>
                </FormControl>
                <FormControl position="relative">
                  <Radio isChecked={deleteType === RuleUpdateType.THIS_AND_FUTURE_INSTANCES} onChange={handleChangeDeleteType}>
                    <FormLabel>{t("This and following days")}</FormLabel>
                  </Radio>
                </FormControl>
              </RadioGroup>
            </Box>
          </>
        )}
        {hasStarted() && (
          <Box>
            <InlineNotification status="warning" notificationDescription={t("Past non-school days will not be deleted.")} />
          </Box>
        )}
      </Flex>
    </Modal>
  );
};

export default DeleteNonSchoolDaysModal;
