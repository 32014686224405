export enum AuditScope {
  Calendar = "CALENDAR",
  Campaign = "CAMPAIGN",
  Community = "COMMUNITY",
  SafetyNet = "SAFETY_NET",
}

export enum EntityType {
  CommunityConfig = "COMMUNITY_CONFIG",
  CommunityFlags = "COMMUNITY_FLAGS",
  NonSchoolDay = "NON_SCHOOL_DAY",
  SchoolTime = "SCHOOL_TIME",
  StudentConfig = "STUDENT_CONFIG",
  SafetyNetRule = "SAFETY_NET_RULE",
}

export interface AuditRecord {
  id: string;
  entityId: string;
  entityType: string;
  entityVersion?: number;
  operation?: string;
  before?: object;
  after?: object;
  scope: string;
  actor?: string;
  timestamp: string;
  summary?: string;
}
