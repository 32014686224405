import React from "react";
import Criteria from "./Criteria";

export default class IPv4NetworkRangeCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = {};
    }

    this.state = {
      criteria: props.criteria,
    };

    /*{type: "ipv4.range", negate: false, conditions: {start_ip: "0.0.0.0", end_ip: "0.0.0.0"}}*/
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = {};
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_StartIpChange = (event) => {
    this.state.criteria.conditions.start_ip = event.target.value;
    this.bubble_Changes(this.state.criteria);
  };

  handle_FinishIpChange = (event) => {
    this.state.criteria.conditions.end_ip = event.target.value;
    this.bubble_Changes(this.state.criteria);
  };

  bubble_Changes = (criteria) => {
    /* Push changes up to the root */
    this.props.bubbleMethod(criteria);
  };

  render_ValidationError = (errorMessage) => {
    return <div className="errorMessage">{errorMessage}</div>;
  };

  render() {
    return (
      <Criteria label="IP Range" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="formgroup-element-fields-inline">
          <div className="mui-textfield">
            <input
              type="text"
              onChange={this.handle_StartIpChange}
              value={this.state.criteria.conditions["start_ip"]}
              placeholder="Start IP"
            />
          </div>
          <div className="mui-textfield">
            <input
              type="text"
              onChange={this.handle_FinishIpChange}
              value={this.state.criteria.conditions["end_ip"]}
              placeholder="End IP"
            />
          </div>
        </div>
        <div>{this.render_ValidationError(this.props.criteria.errorMessage || "")}</div>
      </Criteria>
    );
  }
}
