import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import {
  Box,
  Checkbox,
  DateTimePicker,
  Flex,
  Icon,
  InlineNotification,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Text,
  useTranslation,
} from "@familyzone/component-library";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useEffect, useState } from "react";
import { NonSchoolRule, Frequency, RuleType, RecurrenceID, Recurrence } from "../../../types/Calendar";
import { zIndices } from "../../../utils/ZIndexUtil";
import { containsForbiddenCharacters } from "./CalendarUtils";
import { RuleUpdateType } from "../../../storez/SchoolCalendarStore";
dayjs.extend(utc);
dayjs.extend(timezone);

interface AddNonSchoolDaysModalProps {
  open: boolean;
  existingRule?: NonSchoolRule;
  timezone?: string;
  onClose: () => void;
  onSave: (rule: NonSchoolRule, recurrenceId?: RecurrenceID, updateType?: RuleUpdateType) => void;
}

const AddNonSchoolDaysModal: React.FC<AddNonSchoolDaysModalProps> = ({
  open,
  existingRule,
  timezone,
  onClose,
  onSave,
}: AddNonSchoolDaysModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs().utc(true).startOf("day"));
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().utc(true).startOf("day"));
  const [recurrence, setRecurrence] = useState<Recurrence | undefined>();
  const [useUSDateFormat, setUseUSDateFormat] = useState<boolean>(false);
  const [thisAndFuture, setThisAndFuture] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const validate = () => {
    const errors: Record<string, string> = {};
    if (!name) {
      errors["name"] = t("Name must be specified");
    } else if (containsForbiddenCharacters(name)) {
      errors["name"] = t("Name must not contain special characters");
    }

    // get the current date in the appliance's local timezone
    const currentDate = dayjs()
      .tz(timezone || "UTC")
      .utc(true)
      .startOf("day");

    // user cannot change start date to a past value, but if user did not modify
    // the start date value, then it's allowed to keep the existing past value
    if (startDate.isBefore(currentDate) && existingRule?.startDate !== startDate.unix()) {
      errors["startDate"] = t("Start date must not be in the past");
    }

    if (endDate && endDate.isBefore(currentDate)) {
      errors["endDate"] = t("End date must not be in the past");
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleOnClose = () => {
    onClose();
    setName("");
    setStartDate(dayjs().utc(true).startOf("day"));
    setEndDate(dayjs().utc(true).startOf("day"));
    setRecurrence(undefined);
    setThisAndFuture(false);
    setFieldErrors({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setFieldErrors((e) => ({ ...e, name: "" }));
  };

  const handleChangeRecurrence = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setRecurrence(existingRule?.recurrence || { frequency: Frequency.YEARLY, interval: 1 });
    } else {
      setRecurrence(undefined);
      setThisAndFuture(true);
    }
  };

  const handleChangeUpdateType = () => {
    setThisAndFuture(!thisAndFuture);
  };

  const handleSaveDay = () => {
    if (!validate()) {
      return;
    }

    const start = startDate.unix(),
      end = !endDate || endDate.isSame(startDate, "day") ? undefined : endDate.unix();

    const ruleToSave: NonSchoolRule = {
      ...existingRule,
      name: name,
      type: RuleType.OTHER,
      startDate: start,
      startTime: 0,
      endDate: end,
      endTime: 2359,
      recurrence: recurrence,
    };

    if (existingRule && existingRule.recurrence) {
      if (!thisAndFuture) {
        delete ruleToSave.recurrence;
      }

      const recurrenceId = dayjs(existingRule.startDate * 1000).unix(),
        updateType = thisAndFuture ? RuleUpdateType.THIS_AND_FUTURE_INSTANCES : RuleUpdateType.ONLY_THIS_INSTANCE;

      onSave(ruleToSave, recurrenceId, updateType);
    } else {
      onSave(ruleToSave);
    }
    handleOnClose();
  };

  useEffect(() => {
    setName(existingRule?.name || "");

    setStartDate(existingRule?.startDate ? dayjs(existingRule.startDate * 1000).utc(false) : dayjs().utc(true).startOf("day"));
    setEndDate(
      existingRule?.endDate
        ? dayjs(existingRule.endDate * 1000).utc(false)
        : existingRule?.startDate
        ? dayjs(existingRule.startDate * 1000).utc(false)
        : dayjs().utc(true).startOf("day")
    );
    setRecurrence(existingRule?.recurrence);
    setThisAndFuture(false);
  }, [existingRule, open]);

  useEffect(() => {
    setUseUSDateFormat(!!timezone && (timezone.startsWith("US") || timezone.startsWith("America")));
  }, [timezone, open]);

  const renderDatePicker = () => {
    //
    if (!existingRule || existingRule.startDate >= dayjs().utc().startOf("day").unix()) {
      return (
        <FormControl mb="sp24" isRequired={true} isInvalid={!!fieldErrors.startDate || !!fieldErrors.endDate}>
          <FormLabel fontWeight="medium">{t("Date")}</FormLabel>
          <Box height="42px" position="relative">
            <Box position="absolute" zIndex={zIndices.multiSelect}>
              <DateTimePicker
                initialStart={dayjs(startDate)}
                initialEnd={dayjs(endDate)}
                useUSDateFormat={useUSDateFormat}
                customRanges={["today"]}
                onDateChange={(start, end) => {
                  setStartDate(dayjs(start).utc(true).startOf("day"));
                  setEndDate(dayjs(end).utc(true).startOf("day"));
                  setFieldErrors((e) => ({ ...e, startDate: "", endDate: "" }));
                }}
                direction="right"
                display="date"
                hideTime={true}
              />
            </Box>
          </Box>
          <FormErrorMessage fontWeight="regular" mt="sp4">
            {fieldErrors.startDate || fieldErrors.endDate}
          </FormErrorMessage>
        </FormControl>
      );
    }

    return (
      <Flex direction="row">
        <FormControl mb="sp24" mr="sp24" isRequired={true} isInvalid={!!fieldErrors.startDate}>
          <FormLabel fontWeight="medium">{t("Start Date")}</FormLabel>
          <Button disabled border="1px" borderColor={"neutrals.50"}>
            <Flex>
              <Icon icon="fa-solid fa-calendar-days" color="neutrals.200" mr="sp8" />
              <Text fontWeight="400" data-testid="displayedDate-disabled">
                {startDate.format(useUSDateFormat ? "MMM DD, YYYY" : "DD MMM, YYYY")}
              </Text>
            </Flex>
          </Button>
        </FormControl>
        <FormControl mb="sp24" isRequired={true} isInvalid={!!fieldErrors.endDate}>
          <FormLabel fontWeight="medium">{t("End Date")}</FormLabel>
          <Box height="42px" position="relative">
            <Box position="absolute" zIndex={zIndices.multiSelect}>
              <DateTimePicker
                initialStart={dayjs(endDate)}
                initialEnd={dayjs(endDate)}
                useUSDateFormat={useUSDateFormat}
                customRanges={["today"]}
                singleDate={true}
                onDateChange={(_start, end) => {
                  setEndDate(dayjs(end).utc(true).startOf("day"));
                  setFieldErrors((e) => ({ ...e, endDate: "" }));
                }}
                direction="right"
                display="date"
                hideTime={true}
              />
            </Box>
          </Box>
          <FormErrorMessage fontWeight="regular" mt="sp4">
            {fieldErrors.endDate}
          </FormErrorMessage>
        </FormControl>
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={open}
      size="md"
      onClose={handleOnClose}
      headerText={t(`${existingRule ? "Edit" : "Add"} Non-School Day`)}
      primaryCTALabel={t("Save")}
      onPrimaryCTAClick={handleSaveDay}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={handleOnClose}
      contentProps={{ style: { overflow: "visible" } }}
    >
      <Flex mt="sp24" direction="column">
        <FormControl mb="sp24" isRequired={true} isInvalid={!!fieldErrors.name}>
          <FormLabel fontWeight="medium">{t("Name")}</FormLabel>
          <Input placeholder={t("Enter non-school day name")} value={name} onChange={handleChangeName} data-testid="name-input" />
          <FormErrorMessage fontWeight="regular" mt="sp4">
            {fieldErrors.name}
          </FormErrorMessage>
        </FormControl>
        {renderDatePicker()}
        <FormControl mb="sp8">
          <Checkbox isChecked={!!recurrence} onChange={handleChangeRecurrence} data-testid="recurring-check">
            {t("Recurs Yearly")}
          </Checkbox>
          <FormHelperText ml="22px" color="neutrals.200">
            {t("Select if the non-school day repeats on the same date every year")}
          </FormHelperText>
        </FormControl>
        {existingRule && existingRule?.recurrence && (
          <>
            <FormControl mt="sp16" mb="sp8">
              <FormLabel fontWeight="medium">{t("Edit Recurring Non-School Day")}</FormLabel>
              {!recurrence && (
                <Box mt="sp8">
                  <InlineNotification
                    status={"warning"}
                    notificationDescription={t("After the end date specified above, this rule will no longer recur.")}
                  />
                </Box>
              )}
            </FormControl>
            <Box mb="sp8">
              <RadioGroup direction="column">
                <FormControl position="relative">
                  <Radio isChecked={!thisAndFuture} isDisabled={!recurrence} onChange={handleChangeUpdateType}>
                    <FormLabel>{t("This day")}</FormLabel>
                  </Radio>
                </FormControl>
                <FormControl position="relative">
                  <Radio isChecked={thisAndFuture} isDisabled={!recurrence} onChange={handleChangeUpdateType}>
                    <FormLabel>{t("This and following days")}</FormLabel>
                  </Radio>
                </FormControl>
              </RadioGroup>
            </Box>
          </>
        )}
      </Flex>
    </Modal>
  );
};
export default AddNonSchoolDaysModal;
