import React from "react";
import { Modal, Text, useTranslation, ModalBody } from "@familyzone/component-library";

const ModalWindowDeleteNetwork = ({ handleOK, handleOnClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      headerText={t("Delete Network")}
      isOpen
      onClose={() => handleOnClose()}
      size="sm"
      variant="danger"
      primaryCTALabel={t("Delete Network")}
      onPrimaryCTAClick={() => handleOK()}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={() => handleOnClose()}
    >
      <ModalBody>
        <Text mb="sp8" fontFamily="heading">
          {t("Are you sure you want to permanently delete this network?")}
        </Text>
      </ModalBody>
    </Modal>
  );
};

export default ModalWindowDeleteNetwork;
