import { TopTrafficCardAnalyticsApiCallResponse, TopTrafficTypesCardItem } from "./TopTrafficTypesCard";

type TrafficDataPartial = { upload: number; download: number; name?: string | undefined };
type CombinedPoints = Record<string, TopTrafficTypesCardItem>;

// This function has largely been copied from the UserDashboard.js file and lightly refactored.
// Additionally it has been converted to TypeScript. The original code was plain JS and untested.
export const topTrafficTypesCardSorter = (data: TopTrafficCardAnalyticsApiCallResponse): TopTrafficTypesCardItem[] => {
  const top10items: TopTrafficTypesCardItem[] = [];
  const cp: CombinedPoints = {};
  for (let i = 0; i < data.data.length; i++) {
    const trafficItem = data.data[i];
    if (trafficItem) {
      if (trafficItem.tag && trafficItem.tag !== trafficItem.categoryId) {
        trafficItem["tag.string"] && Object.assign(trafficItem, { name: trafficItem["tag.string"], signature: true });
        assignKeyAndCalculation(trafficItem, cp);
      } else if (trafficItem.domain) {
        Object.assign(trafficItem, { name: trafficItem.domain, signature: false });
        assignKeyAndCalculation(trafficItem, cp);
      }
    }
    if (Object.keys(cp).length >= 10) {
      break;
    }
  }
  for (const key in cp) {
    top10items.push(cp[key]);
  }

  return top10items;
};

const assignKeyAndCalculation = (datum: TrafficDataPartial, cp: CombinedPoints) => {
  if (datum.name) {
    if (!(datum.name in cp)) {
      Object.assign(cp, { [datum.name]: datum });
    } else {
      cp[datum["name"]]["upload"] += datum["upload"];
      cp[datum["name"]]["download"] += datum["download"];
    }
  }
};
