import Api from "../utils/Api";
import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { RuleAction, RuleCriteriaType, SafetyNetPolicy, SafetyNetRule, SafetyNetTag, Signature } from "../types/SafetyNet";
import { useSessionStore } from "./SessionStore";

export const safetyNetRulesURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/safety-net/rules`;
};

export const SIGNATURES_URL = "/config/ajax/signatures";

interface SafetyNet {
  recommended: SafetyNetPolicy[];
  additional: SafetyNetPolicy[];
}

interface SafetyNetChanges {
  add?: SafetyNetPolicy[];
  remove?: SafetyNetPolicy[];
}

export interface CommunitySafetyNetStore {
  signatures: Signature[];
  safetyNet: SafetyNet;
  fetch: () => Promise<SafetyNet>;
  save: (changes: SafetyNetChanges) => Promise<SafetyNet>;
  create: (policy: SafetyNetPolicy) => Promise<SafetyNetPolicy>;
  delete: (policy: SafetyNetPolicy) => Promise<SafetyNetPolicy>;
  reset: () => void;
  getActivePolicies: () => Promise<SafetyNetPolicy[]>;
  createRecommended: () => Promise<SafetyNet>;
}

const signatureToPolicy = (s: Signature): SafetyNetPolicy => {
  return {
    name: s.name,
    description: s.description,
    action: RuleAction.BLOCK,
    criteria: [{ signature: s.id, type: RuleCriteriaType.SIGNATURE }],
    checked: false,
  };
};

const fetchRules = async (): Promise<SafetyNetRule[]> => {
  let rules: SafetyNetRule[] = [],
    statusCode = 200;

  // eslint-disable-next-line
  await Api.getAsync(safetyNetRulesURL()).then(
    (response: { rules: SafetyNetRule[] }) => (rules = response.rules),
    (reason: jqXHR) => (statusCode = reason?.status)
  );

  if (statusCode !== 200) {
    throw new ResponseError("Failed to fetch Safety Net policies", statusCode);
  }

  return rules;
};

const fetchSignatures = async (): Promise<Signature[]> => {
  let signatures: Signature[] = [],
    statusCode = 200;

  // eslint-disable-next-line
  await Api.getAsync(SIGNATURES_URL).then(
    (response: { signatures: Signature[] }) => (signatures = response.signatures),
    (reason: jqXHR) => (statusCode = reason?.status)
  );

  if (statusCode !== 200) {
    throw new ResponseError("Failed to fetch signatures", statusCode);
  }

  return signatures;
};

export const useCommunitySafetyNetStore = create<CommunitySafetyNetStore>((set, get) => ({
  signatures: [],

  safetyNet: { recommended: [], additional: [] },

  fetch: async (): Promise<SafetyNet> => {
    const [policies, signatures] = await Promise.all([
      fetchRules(),
      get().signatures.length > 0 ? Promise.resolve(get().signatures) : fetchSignatures(),
    ]);

    const signatureMap: Record<string, Signature> = {},
      recommendedMap: Record<string, SafetyNetPolicy> = {},
      additionalMap: Record<string, SafetyNetPolicy> = {};

    // initialize default Safety Net rules based on the current Appindex signatures
    signatures
      .filter(
        (s) => s.enabled && !s.hidden && s.id !== "sphirewall.application.alwaysallow" && s.id !== "sphirewall.application.alwaysdeny"
      )
      .forEach((s) => {
        signatureMap[s.id] = s;
        if (s.tags.includes(SafetyNetTag.RECOMMENDED)) {
          recommendedMap[s.id] = signatureToPolicy(s);
        } else if (s.tags.includes(SafetyNetTag.CUSTOM)) {
          additionalMap[s.id] = signatureToPolicy(s);
        }
      });

    // merge default Safety Net rules with persisted ones from CCS
    policies.forEach((p) => {
      p.criteria
        .filter((c) => c.type === RuleCriteriaType.SIGNATURE)
        .forEach((c) => {
          const policyMap = recommendedMap[c.signature] ? recommendedMap : additionalMap;
          policyMap[c.signature] = {
            ...p,
            description: signatureMap[c.signature]?.description,
            checked: true,
          };
        });
    });

    const safetyNet = {
      recommended: Object.values(recommendedMap),
      additional: Object.values(additionalMap),
    };

    set({ safetyNet, signatures });

    return safetyNet;
  },

  /**
   * Batch update multiple Safety Net rules.
   */
  save: async ({ add, remove }: SafetyNetChanges): Promise<SafetyNet> => {
    const promises: Promise<SafetyNetPolicy>[] = [];

    if (add) {
      promises.push(...add.map((policy) => get().create(policy)));
    }

    if (remove) {
      promises.push(...remove.map((policy) => get().delete(policy)));
    }

    await Promise.all(promises);

    const safetyNet = { ...get().safetyNet };

    set({ safetyNet });

    return safetyNet;
  },

  /**
   * Create a single Safety Net rule.
   */
  create: async (policy: SafetyNetPolicy): Promise<SafetyNetPolicy> => {
    let saved: SafetyNetRule | undefined,
      statusCode = 200;

    await Api.postAsync(safetyNetRulesURL(), policy).then(
      (response: SafetyNetRule) => (saved = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200 || !saved) {
      throw new ResponseError(`Failed to create Safety Net policy ${Date.now()}`, statusCode);
    }

    Object.assign(policy, saved);

    return policy;
  },

  /**
   * Delete a signle Safety Net rule.
   */
  delete: async (policy: SafetyNetPolicy): Promise<SafetyNetPolicy> => {
    if (!policy.id) return policy;

    let saved: SafetyNetPolicy | undefined,
      statusCode = 200;

    await Api.deleteAsync(`${safetyNetRulesURL()}/${policy.id}`).then(
      () => {
        saved = policy;
        saved.id = undefined;
        saved.checked = false;
        saved.version = undefined;
        saved.modifiedBy = undefined;
        saved.modifiedDate = undefined;
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if ((statusCode !== 204 && statusCode !== 200) || !saved) {
      throw new ResponseError("Failed to delete Safety Net policy", statusCode);
    }

    return saved;
  },

  reset: () => {
    set({ safetyNet: { recommended: [], additional: [] }, signatures: [] });
  },

  /**
   * Get all persisted Safety Net rules.
   */
  getActivePolicies: async (): Promise<SafetyNetPolicy[]> => {
    let safetyNet = get().safetyNet;

    if (safetyNet.recommended.length === 0 && safetyNet.additional.length === 0) {
      safetyNet = await get().fetch();
    }

    return safetyNet.recommended.concat(safetyNet.additional).filter((policy) => policy.id);
  },

  createRecommended: async (): Promise<SafetyNet> => {
    const recommended = get().safetyNet.recommended.filter((policy) => !policy.id);
    recommended.forEach((policy) => (policy.checked = true));
    return get().save({ add: recommended });
  },
}));
