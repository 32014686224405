import React from "react";
import { Box, Modal, ModalBody, Spinner, Text, useTranslation } from "@familyzone/component-library";

interface ExportParentsModalProps {
  isOpen: boolean;
  loading: boolean;
  exported: boolean;
  onClose: () => void;
  executeDownload: () => void;
}
const ExportParentsModal: React.FC<ExportParentsModalProps> = ({
  isOpen,
  loading,
  exported,
  onClose,
  executeDownload,
}: ExportParentsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      headerText={t("Export Parent Data CSV")}
      isOpen={isOpen}
      size="md"
      onClose={onClose}
      primaryCTALabel={loading ? t("Exporting..") : !exported ? t("Export") : undefined}
      primaryCTADisabled={loading || exported}
      onPrimaryCTAClick={executeDownload}
      secondaryCTALabel={exported ? t("Close") : t("Cancel")}
      secondaryCTADisabled={loading}
      onSecondaryCTAClick={onClose}
    >
      <ModalBody>
        <>
          {!exported && !loading && (
            <Box my="sp24">
              <Text>{t("Only parents associated to at least 1 student will be exported.")}</Text>
            </Box>
          )}

          {!exported && loading && (
            <Box my="sp24" textAlign="center" data-testid="loading-animation">
              <Spinner size="md" />
              <Text pt="sp16">{t("Large dataset may take up to a minute to export. Please stand by...")}</Text>
            </Box>
          )}

          {exported && (
            <Box my="sp24">
              <Text>{t("Parent data successfully exported. You should see a download prompt.")}</Text>
            </Box>
          )}
        </>
      </ModalBody>
    </Modal>
  );
};

export default ExportParentsModal;
