import React from "react";
import HighchartsModul from "./HighchartsModul";
import ComponentLoading from "./ComponentLoading";

export default class ColumnGraph extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      options: undefined,
    };
    this.formatBytes = this.formatBytes.bind(this);
    this.update_options(this.props.data);
  }

  componentWillReceiveProps = (nextProps) => {
    this.update_options(nextProps.data);
  };

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    let k = 1000; // or 1024 for binary
    let dm = decimals + 1 || 3;
    let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  update_options = (series_data) => {
    let self = this;
    setTimeout(function () {
      self.setState({
        data: series_data,
        options: {
          colors: ["#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"],
          chart: {
            type: "column",
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            height: 250,
          },
          title: {
            text: "",
          },
          legend: {
            enabled: false,
          },

          xAxis: {
            type: "datetime",
            minTickInterval: 86400000,
            endOnTick: false,
            maxPadding: 0,
            lineWidth: 0,
            tickWidth: 0,
            tickmarkPlacement: "on",
            labels: {
              useHTML: true,
              enabled: true,
              format: "{value:%b %e}",
            },
          },
          yAxis: {
            tickAmount: 6,
            min: 0,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            showFirstLabel: false,
            showLastLabel: false,
            maxPadding: 0.5,
            title: {
              enabled: false,
            },
            labels: {
              useHTML: true,
              formatter: function () {
                switch (self.props.dataType) {
                  case "hits":
                    return '<span className="pd-chart-label-y">' + this.value + "</span>";
                  case "data":
                  default:
                    return '<span className="pd-chart-label-y">' + self.formatBytes(this.value, 1) + "</span>";
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointRange: 3600 * 1000 * 24,
              groupPadding: 0,
              borderWidth: 0.5,
              stacking: "normal",
              pointPadding: 0.2,
              lineColor: "#6FBDF8",
              states: {
                hover: {
                  lineWidth: 2,
                },
              },
              events: {
                click: function (event) {
                  if (event.point.url !== undefined) {
                    window.location = event.point.url;
                  }
                },
              },
            },
          },
          credits: {
            enabled: !1,
          },
          tooltip: {
            formatter: function () {
              let return_string = "";
              // let return_string = '<b>' + Highcharts.dateFormat('%d/%m/%Y', new Date(this.point.x)) + '</b>'
              switch (self.props.dataType) {
                case "hits":
                  return_string += "</b><br><b>" + this.point.y + " hits</b>";
                  break;
                case "data":
                default:
                  return_string += "</b><br><b>" + self.formatBytes(this.point.y, 1) + "</b>";
                  break;
              }
              for (let i in this.point.info) {
                return_string += "<br><b>" + this.point.info[i] + "</b>";
              }
              return return_string;
            },
          },
          series: series_data,
        },
      });
    });
  };

  render = () => {
    return (
      <div style={{ height: "300px" }}>
        <div className="pd-main-chart-container">
          {this.state.data ? (
            <div id="highcharts-graph-columnGraph" style={{ width: "97%" }}>
              <HighchartsModul container="columnGraph" options={this.state.options} />
            </div>
          ) : (
            <ComponentLoading />
          )}
        </div>
      </div>
    );
  };
}
