import React from "react";

export default class TableCentricPageButton extends React.Component {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a data-cy={this.props["data-cy"]} className="heading_button" onClick={this.props.onClickHandler}>
        {this.props.title}
      </a>
    );
  }
}
