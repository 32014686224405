import SecurityGroupStore from "../stores/SecurityGroupStore";
import SecurityGroupActions from "../actions/SecurityGroupActions";
import React from "react";
import Criteria from "./Criteria";
import NuMultiSelect from "../utils/NuMultiSelect";
import { get } from "shades";

export default class SecurityGroupCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      availableObjects: SecurityGroupStore.getSecurityGroups(),
    };

    this.displayTextLens = get("name");
    this.valueLens = get("id");
  }

  componentDidMount() {
    SecurityGroupStore.listen(this.onChange);

    setTimeout(() => {
      SecurityGroupActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SecurityGroupStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableObjects: SecurityGroupStore.getSecurityGroups(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Add = (securityGroup) => {
    const securityGroupValue = this.valueLens(securityGroup);
    this.state.criteria.conditions.push(securityGroupValue);

    this.props.bubbleMethod(this.state.criteria);
  };

  handle_Remove = (securityGroup) => {
    const removalIndex = this.state.criteria.conditions.findIndex((element) => element === securityGroup);
    this.state.criteria.conditions.splice(removalIndex, 1);

    this.props.bubbleMethod(this.state.criteria);
  };

  render() {
    return (
      <Criteria label="Security Group" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <NuMultiSelect
          allItems={this.state.availableObjects}
          selectedValues={this.state.criteria.conditions}
          displayTextLens={this.displayTextLens}
          valueLens={this.valueLens}
          searchPlaceholder="Security Groups"
          selectedPlaceHolder="No Security Groups Selected"
          onAdd={this.handle_Add}
          onRemove={this.handle_Remove}
        />
      </Criteria>
    );
  }
}
