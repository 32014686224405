import { Flex, Tag, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import TableBasedPage from "../../../../components/templates/TableBasedPage";
import { TableColumn } from "../../../../types/table";
import { ActiveBypassCode, getBypassActive } from "../../ApiHelpers";

export const breadcrumbsText = ["Filtering", "Bypass Codes", "Active"];
export const titleText = "Bypass Codes Active";
export const columnsText = ["Code", "Username", "IP", "MAC", "Chrome ID", "Activation", "Expiry", "Status"];
export const searchText = "Search";
export const activeText = "Active";
export const expiredText = "Expired";

const BypassCodesActive: React.FC = () => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [activeUsers, setActiveUsers] = useState<ActiveBypassCode[]>([]);

  const breadcrumbs = [
    { title: t(breadcrumbsText[0]), url: "/filtering", isActive: false },
    { title: t(breadcrumbsText[1]), url: "/filtering/bypass", isActive: false },
    { title: t(breadcrumbsText[2]), isActive: true },
  ];

  const columns: TableColumn[] = [
    {
      headerText: t(columnsText[0]),
      columnName: "code",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[1]),
      columnName: "username",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[2]),
      columnName: "ip",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[3]),
      columnName: "mac",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t(columnsText[4]),
      columnName: "chrome_id",
      sortable: false,
      searchable: false,
    },
    {
      headerText: t(columnsText[5]),
      columnName: "activation",
      sortable: false,
      searchable: false,
    },
    {
      headerText: t(columnsText[6]),
      columnName: "expiry",
      sortable: false,
      searchable: false,
    },
    {
      headerText: t(columnsText[7]),
      columnName: "status",
      sortable: true,
      searchable: false,
      alignment: "center",
    },
  ];

  const populateBypassCodesActive = useCallback(async () => {
    try {
      const codes = await getBypassActive();
      setActiveUsers(codes.length > 1 ? codes.sort((a, b) => (a.expiry_time > b.expiry_time ? -1 : 1)) : codes);
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    populateBypassCodesActive().catch(() => "");
  }, [populateBypassCodesActive]);

  const dataMap = (user: ActiveBypassCode, index: number) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{user.code}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{user.username}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{user.ip}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{user.mac}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{user.cid}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{moment.unix(user.activation_time).format("YYYY/MM/DD HH:mm")}</Text>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Text fontSize="md" mr="sp8">
            {moment.unix(user.expiry_time).format("YYYY/MM/DD HH:mm")}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Flex alignItems="center" justifyContent="center">
          {moment.unix(user.expiry_time) < moment() ? (
            <Tag variant="subtle" tagLabel={t(expiredText)} />
          ) : (
            <Tag variant="green" tagLabel={t(activeText)} />
          )}
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <>
      <TableBasedPage
        breadcrumbs={breadcrumbs}
        title={titleText}
        columns={columns}
        data={activeUsers}
        tableDataMap={dataMap}
        loaded={loaded}
      />
    </>
  );
};

export default BypassCodesActive;
