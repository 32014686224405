import React from "react";
import { Box, DraggableTr, Flex, Icon, TableIconButton, Td, Text, useTranslation } from "@familyzone/component-library";

import { DeleteButtonNew } from "../../modules/DeleteButtonNew";

import { SafeSearchRule } from "./types";
import { googleUsageRights } from "./SafeSearchTable";
import { UserFriendlyGroupsCriteriaPrinter } from "../../modules/criteria/UserFriendlyGroupsCriteriaPrinter";

export const googleEnabledText = "Safesearch Enforced";
export const googleDisabledText = "Safesearch Disabled";
export const ytEnabledText = "Strict Safesearch";
export const ytDisabledText = "Safesearch Disabled";
export const ytModerateText = "Moderate Safesearch";
export const bingDisabledText = "Safesearch Disabled";
export const bingEnabledText = "Safesearch Enforced";

interface SafeSearchTableRowProps {
  rule: SafeSearchRule;
  id: string;
  disabled?: boolean;
  onEdit: (e: React.SyntheticEvent) => void;
  onDelete: () => void;
}

const SafeSearchTableRow: React.FC<SafeSearchTableRowProps> = ({ rule, id, disabled = false, onEdit = () => "", onDelete = () => "" }) => {
  const { t } = useTranslation();

  return (
    <DraggableTr id={id} backgroundColor="neutrals.0">
      <Td width="48px">
        <Box p="sp4" display="flex" justifyContent="center">
          <svg width="4" height="13" viewBox="0 0 4 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5.01587C1.15625 5.01587 0.5 5.70337 0.5 6.51587C0.5 7.35962 1.15625 8.01587 2 8.01587C2.8125 8.01587 3.5 7.35962 3.5 6.51587C3.5 5.70337 2.8125 5.01587 2 5.01587ZM0.5 1.76587C0.5 2.60962 1.15625 3.26587 2 3.26587C2.8125 3.26587 3.5 2.60962 3.5 1.76587C3.5 0.953369 2.8125 0.265869 2 0.265869C1.15625 0.265869 0.5 0.953369 0.5 1.76587ZM0.5 11.2659C0.5 12.1096 1.15625 12.7659 2 12.7659C2.8125 12.7659 3.5 12.1096 3.5 11.2659C3.5 10.4534 2.8125 9.76587 2 9.76587C1.15625 9.76587 0.5 10.4534 0.5 11.2659Z"
              fill="#6B778C"
            />
          </svg>
        </Box>
      </Td>
      <Td>
        <Text>
          <UserFriendlyGroupsCriteriaPrinter criteria={rule.source_criteria} />
        </Text>
      </Td>
      <Td>
        <Text>{rule.enabled ? t(googleEnabledText) : t(googleDisabledText)}</Text>
        <br />
        <Text display="inline">{t(googleUsageRights[rule.googleUsageRights])}</Text>
      </Td>
      <Td>
        <Text>{rule.youtube_moderate_enabled ? t(ytModerateText) : rule.youtube_enabled ? t(ytEnabledText) : t(ytDisabledText)}</Text>
      </Td>
      <Td>
        <Text>{rule.bing_enabled ? t(bingEnabledText) : t(bingDisabledText)}</Text>
      </Td>
      <Td>
        <Flex justifyContent="end">
          <Box mr="sp8">
            <TableIconButton
              data-testid={`${rule.id}-edit`}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              aria-label="Edit"
              onClick={onEdit}
              disabled={disabled}
            />
          </Box>
          <DeleteButtonNew data-testid={`${rule.id}-delete`} disabled={disabled} onClick={onDelete} />
        </Flex>
      </Td>
    </DraggableTr>
  );
};

export default SafeSearchTableRow;
