export class ScheduleCSVObject {
  file: unknown;
  entries: Map<string, ScheduleCSVObjectEntry>;
  unique_periods: Set<unknown>;
  unique_classrooms: Set<unknown>;
  lineErrors: number;
  classErrors: number;
  dayErrors: number;
  periodErrors: number;
  linesRead: number;
  processed: boolean;

  constructor(file: unknown) {
    this.file = file;
    this.entries = new Map<string, ScheduleCSVObjectEntry>();
    this.unique_periods = new Set();
    this.unique_classrooms = new Set();
    this.lineErrors = 0;
    this.classErrors = 0;
    this.dayErrors = 0;
    this.periodErrors = 0;
    this.linesRead = 0;
    this.processed = false;
  }

  getFile = (): unknown => {
    return this.file;
  };

  addEntry = (classroom: string, day: string, sched_start: number, sched_end: number): void => {
    const key = `${classroom}${day}${sched_start}${sched_end}`;
    if (!this.entries.get(key)) {
      this.entries.set(key, {
        classroom: classroom,
        day: day,
        sched_start: sched_start,
        sched_end: sched_end,
      });
      this.unique_periods.add(key);
    }
    this.unique_classrooms.add(classroom);
  };

  addLineError = (): void => {
    this.lineErrors++;
  };

  addClassError = (): void => {
    this.classErrors++;
  };

  addDayError = (): void => {
    this.dayErrors++;
  };

  addPeriodError = (): void => {
    this.periodErrors++;
  };

  addLineRead = (): void => {
    this.linesRead++;
  };

  getUniqueEntries = (): { periods: number; classrooms: number } => {
    return {
      periods: this.unique_periods.size,
      classrooms: this.unique_classrooms.size,
    };
  };

  setProcessed = (): void => {
    this.processed = true;
  };
}

export type ScheduleCSVObjectEntry = {
  classroom: string;
  day: string;
  sched_start: number;
  sched_end: number;
};
