import React, { useEffect, useState } from "react";
import { applyErrorStyling, removeErrorStyling } from "../../../utils/DynamicStyling";
import SearchableSelect from "../../../modules/SearchableSelect";
import { InlineNotification, Link as TextLink } from "@familyzone/component-library";
import { SISProvider } from "./SisIntegration";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text, Box } from "@chakra-ui/react";
import NuMultiSelect from "../../../utils/NuMultiSelect";
import { get } from "shades";

const SisTypes = [
  ["Achieve", "Achieve"],
  ["Aeries", "Aeries"],
  ["Alma", "Alma"],
  ["Aspen", "Aspen"],
  ["Aspire", "Aspire"],
  ["Blackbaud", "Blackbaud"],
  ["Chalkable_iNOW", "Chalkable iNOW"],
  ["ClassLink", "ClassLink"],
  ["Ellucian", "Ellucian"],
  ["eSchoolPlus", "eSchoolPlus"],
  ["FACTS (RenWEB)", "FACTS (RenWEB)"],
  ["Focus", "Focus"],
  ["Genesis", "Genesis"],
  ["GG4L", "GG4L (Global Grid 4 Learning)"],
  ["Gradelink", "Gradelink"],
  ["Harmony", "Harmony"],
  ["Illuminate_Education", "Illuminate Education"],
  ["Infinite Campus", "Infinite Campus"],
  ["InResonance", "InResonance"],
  ["JCampus", "JCampus"],
  ["JMC", "JMC"],
  ["Lumen_Touch", "Lumen Touch"],
  ["OneRoster", "OneRoster"],
  ["Powerschool", "PowerSchool"],
  ["Rediker", "Rediker"],
  ["Sapphire", "Sapphire"],
  ["Schoolrunner", "Schoolrunner"],
  ["Skyward", "Skyward"],
  ["Sycamore", "Sycamore"],
  ["Synergy", "Synergy"],
  ["TxEIS", "TxEIS"],
  ["Tyler", "Tyler"],
  ["Veracross", "Veracross"],
  ["Vision", "Vision SIS"],
  ["unit4", "Unit4"],
  ["Other", "Other"],
];

interface Props {
  rowToEdit: SISProvider | null;
  isClasslink?: boolean;
  show: boolean;
  handleHide: () => void;
  handleSubmit: (fields: OneRosterOnHandle, rowToEdit: SISProvider | null, isClasslink: boolean) => void;
}

interface OnChange {
  label: string;
  value: string;
}

export interface OneRosterOnHandle {
  syncClassroomData: boolean;
  syncParentData: boolean;
  respectEnrolmentDate: boolean;
  respectSessions: boolean;
  integrationType: string;
  name: string;
  endpointUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
  syncParentTypes: string[];
}

interface ParentType {
  value: string;
}

export const SisOneRosterForm: React.FC<Props> = ({ rowToEdit, show, handleHide, handleSubmit, isClasslink }) => {
  const [state, setState] = useState<OneRosterOnHandle>({
    syncClassroomData: true,
    syncParentData: false,
    respectEnrolmentDate: false,
    respectSessions: false,
    integrationType: isClasslink ? "ClassLink" : "Other",
    name: "",
    endpointUrl: "",
    tokenUrl: "",
    clientId: "",
    clientSecret: "",
    syncParentTypes: ["Parent", "Relative", "Guardian"],
  });

  const [nameInput, setNameInput] = useState<HTMLInputElement | null>(null);
  const [endpointUrlInput, setEndpointUrlInput] = useState<HTMLInputElement | null>(null);
  const [tokenUrlInput, setTokenUrlInput] = useState<HTMLInputElement | null>(null);
  const [clientIdInput, setClientIdInput] = useState<HTMLInputElement | null>(null);
  const [clientSecretInput, setClientSecretInput] = useState<HTMLInputElement | null>(null);
  const [validateErrors, setValidateErrors] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        syncClassroomData:
          rowToEdit?.sync_classrooms !== undefined && rowToEdit?.sync_classrooms !== null ? rowToEdit.sync_classrooms : true,
        syncParentData: rowToEdit?.sync_parents || false,
        respectEnrolmentDate: rowToEdit?.respect_enrolment_date || false,
        respectSessions: rowToEdit?.respect_sessions || false,
        integrationType: isClasslink ? "ClassLink" : rowToEdit?.integration_type || "Other",
        name: rowToEdit?.name || "",
        endpointUrl: rowToEdit?.api_configuration.endpoint_url || "",
        tokenUrl: rowToEdit?.api_configuration.token_url || "",
        clientId: rowToEdit?.api_configuration.client_id || "",
        clientSecret: rowToEdit?.api_configuration.client_secret || "",
        // if 0 set default to all 3, this is so existing users don't need to set these themselves
        // we also make the first char upper to look nicer, we make this lower again on save
        syncParentTypes:
          rowToEdit?.sync_parent_types && rowToEdit.sync_parent_types.length > 0
            ? rowToEdit.sync_parent_types.map((value) => value.charAt(0).toUpperCase() + value.slice(1))
            : ["Parent", "Relative", "Guardian"],
      };
    });
  }, [rowToEdit, isClasslink]);

  const handleSubmitClick = () => {
    removeErrorStyling(nameInput, endpointUrlInput, tokenUrlInput, clientIdInput, clientSecretInput);
    const errors = [];

    const erroredInputs = [];
    if (!state.name) erroredInputs.push(nameInput);
    if (!state.endpointUrl) erroredInputs.push(endpointUrlInput);
    if (!state.clientId) erroredInputs.push(clientIdInput);
    if (!state.clientSecret) erroredInputs.push(clientSecretInput);

    let error = false;

    if (erroredInputs.length > 0) {
      applyErrorStyling(...erroredInputs);
      error = true;
    }

    if (!isClasslink && !state.integrationType) {
      errors.push("Integration Type is Required.");
      error = true;
    }

    if (state.syncParentData && state.syncParentTypes.length === 0) {
      errors.push("Required at least one guardian type");
      error = true;
    }

    if (!state.syncParentData && !state.syncClassroomData) {
      errors.push("Please select either parent data, classroom data, or both to be synced.");
      error = true;
    }

    if (error) {
      setValidateErrors(errors);
      return;
    }

    handleSubmit(state, rowToEdit, !!isClasslink);
  };

  const handleAddSyncParentType = (typeToAdd: ParentType) => {
    setState((prevConfig) => ({
      ...prevConfig,
      syncParentTypes: [...prevConfig.syncParentTypes, typeToAdd.value],
    }));
  };

  const handleRemoveSyncParentType = (typeToRemove: string) => {
    setState((prevConfig) => ({
      ...prevConfig,
      syncParentTypes: prevConfig.syncParentTypes.filter((item) => item !== typeToRemove),
    }));
  };

  return (
    <Modal isOpen={show} onClose={handleHide} isCentered scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent maxWidth={"800px"} maxHeight={"80%"}>
        <Box className="base-modal-close">
          <img
            alt="Close modal button"
            className="modal-close-icon"
            width="13"
            height="13"
            src="/static/images/icon_modal_close.svg"
            onClick={handleHide}
          />
        </Box>
        <ModalHeader>
          {!rowToEdit
            ? "Configure OneRoster or ClassLink Sync"
            : rowToEdit.type === "oneroster"
            ? "Edit OneRoster Sync"
            : "Edit ClassLink Sync"}
        </ModalHeader>
        <ModalBody
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          {rowToEdit && rowToEdit.type === "oneroster" && (
            <TextLink isExternal textAlign={"center"} href={"https://help.linewize.com/hc/en-gb/articles/5733217613596"}>
              Configuring OneRoster integration Guide
            </TextLink>
          )}
          {rowToEdit && rowToEdit.type === "classlink" && (
            <TextLink isExternal textAlign={"center"} href={"https://help.linewize.com/hc/en-gb/articles/5733219087132"}>
              Configuring ClassLink integration Guide
            </TextLink>
          )}
          {!rowToEdit && !isClasslink && (
            <TextLink isExternal textAlign={"center"} href={"https://help.linewize.com/hc/en-gb/articles/5733217613596"}>
              Configuring OneRoster integration Guide
            </TextLink>
          )}
          {!rowToEdit && isClasslink && (
            <TextLink isExternal textAlign={"center"} href={"https://help.linewize.com/hc/en-gb/articles/5733219087132"}>
              Configuring ClassLink integration Guide
            </TextLink>
          )}
          <Text textAlign={"center"}>
            {"Provide your SIS Provider account details below to integrate your school system with School Manager"}
          </Text>

          <Box
            as={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginTop: "2rem",
            }}
          >
            {!isClasslink && (
              <Box data-testid={"integrationTypeSelector"}>
                <label>Which SIS would you like to integrate?</label>
                <SearchableSelect
                  value={state.integrationType}
                  clearable={false}
                  onChange={(e: OnChange) =>
                    setState((prevState) => {
                      return { ...prevState, integrationType: e.value };
                    })
                  }
                  valueDataMap={SisTypes}
                />
              </Box>
            )}

            <Box>
              <label>Name</label>
              <input
                ref={(el) => setNameInput(el)}
                className="form-input"
                placeholder="Name"
                value={state.name}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, name: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>Endpoint URL</label>
              <input
                ref={(el) => setEndpointUrlInput(el)}
                className="form-input"
                placeholder="Endpoint URL"
                value={state.endpointUrl}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, endpointUrl: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>OAuth2 Token URL (Optional)</label>
              <input
                ref={(el) => setTokenUrlInput(el)}
                className="form-input"
                placeholder="OAuth2 Token URL"
                value={state.tokenUrl}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, tokenUrl: e.target.value };
                  })
                }
              />
            </Box>

            <Box>
              <label>Client ID</label>
              <input
                ref={(el) => setClientIdInput(el)}
                className="form-input"
                placeholder="Client ID"
                value={state.clientId}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, clientId: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <label>Client Secret</label>
              <input
                ref={(el) => setClientSecretInput(el)}
                className="form-input"
                placeholder="Client Secret"
                value={state.clientSecret}
                onChange={(e) =>
                  setState((prevState) => {
                    return { ...prevState, clientSecret: e.target.value };
                  })
                }
              />
            </Box>
            <Box>
              <Box className="mui-checkbox" title={"When this is ticked, syncs include classrooms and classroom relationships."}>
                <label>
                  <input
                    type="checkbox"
                    checked={state.syncClassroomData}
                    onChange={() =>
                      setState((prevState) => {
                        const newSyncClassroomData = !prevState.syncClassroomData;
                        return {
                          ...prevState,
                          syncClassroomData: newSyncClassroomData,
                          respectEnrolmentDate: newSyncClassroomData ? prevState.respectEnrolmentDate : false,
                          respectSessions: newSyncClassroomData ? prevState.respectSessions : false,
                        };
                      })
                    }
                  />
                  Sync Classroom Data
                </label>
              </Box>
              <Box
                className="mui-checkbox"
                title={
                  "When this is ticked, syncs will not add students to classes if a student does not have a valid enrolment date for that class."
                }
              >
                <label>
                  <input
                    type="checkbox"
                    checked={state.syncClassroomData ? state.respectEnrolmentDate : false}
                    disabled={!state.syncClassroomData}
                    onChange={() =>
                      setState((prevState) => {
                        return { ...prevState, respectEnrolmentDate: !state.respectEnrolmentDate };
                      })
                    }
                  />
                  Respect Enrolment Date
                </label>
              </Box>

              <Box className="mui-checkbox" title={"When this is ticked, syncs exclude classes lacking an active academic session."}>
                <label>
                  <input
                    type="checkbox"
                    checked={state.syncClassroomData ? state.respectSessions : false}
                    disabled={!state.syncClassroomData}
                    onChange={() =>
                      setState((prevState) => {
                        return { ...prevState, respectSessions: !state.respectSessions };
                      })
                    }
                  />
                  Respect Academic Sessions
                </label>
              </Box>

              <Box className="mui-checkbox" title={"When this is ticked, syncs include parent/guardian data."}>
                <label>
                  <input
                    type="checkbox"
                    checked={state.syncParentData}
                    onChange={() => {
                      // we default to selecting them all, this way existing users don't need to update their values.
                      // also, they cannot have this enabled with 0 results
                      if (state.syncParentTypes.length === 0) {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            syncParentData: !state.syncParentData,
                            syncParentTypes: ["Parent", "Relative", "Guardian"],
                          };
                        });
                        return;
                      }
                      setState((prevState) => {
                        return { ...prevState, syncParentData: !state.syncParentData };
                      });
                    }}
                  />
                  Sync Parent Data
                </label>
              </Box>
            </Box>

            {state.syncParentData && (
              <>
                <div className="formgroup-element">
                  <div>Select relationship type</div>
                  <div className="dialog_subheading_desc">Please select at least one relationship type to sync parent data</div>
                </div>
                <div className="formgroup-element">
                  <NuMultiSelect
                    allItems={[{ value: "Parent" }, { value: "Relative" }, { value: "Guardian" }]}
                    selectedValues={state.syncParentTypes}
                    displayTextLens={get("value")}
                    valueLens={get("value")}
                    onAdd={handleAddSyncParentType}
                    onRemove={handleRemoveSyncParentType}
                  />
                </div>
              </>
            )}
          </Box>
        </ModalBody>

        <ModalFooter
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "space-between",
            gap: "0.5rem",
          }}
        >
          <button className="mui-btn mui-btn--raised modal-button" onClick={handleSubmitClick}>
            Submit
          </button>
          {validateErrors && validateErrors.map((err) => <InlineNotification status={"warning"} notificationDescription={err} />)}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
