import React from "react";
import { Link } from "react-router";
import FlexibleTable from "../../../modules/FlexibleTable";
import ModalWindow from "../../../modules/ModalWindow";
import TableCentricPage from "../../../modules/TableCentricPage";
import TableCentricPageButton from "../../../modules/TableCentricPageButton";
import Separator from "../../../modules/Separator";
import Api from "../../../utils/Api";
import TableLeftPanel from "../../../utils/TableLeftPanel";
import EditButton from "../../../modules/EditButton";
import DeleteButton from "../../../modules/DeleteButton";
import SearchableSelect from "../../../modules/SearchableSelect";

import { ABTester } from "@familyzone/component-library";

import InterfacesNew from "./InterfacesNew";

class UpDownInterfaceButton extends React.Component {
  handle_ClickDown = () => {
    this.props.handleBringDown(this.props.id);
  };

  handle_ClickUp = () => {
    this.props.handleBringUp(this.props.id);
  };

  render() {
    if (this.props.state === true) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={this.handle_ClickDown}>
          <i className="fa fa-long-arrow-down" aria-hidden="true" />
        </a>
      );
    } else {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={this.handle_ClickUp}>
          <i className="fa fa-long-arrow-up" aria-hidden="true" />
        </a>
      );
    }
  }
}

class AddInterface extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      type: "",
      vlan_interface: "",
      vlan_id: 0,
    };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_Submit = () => {
    let new_route = {
      type: this.state.type,
      vlan_interface: this.state.vlan_interface,
      vlan_id: this.state.vlan_id,
    };

    this.props.handleAdd(new_route);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  handle_ChangeType = (event) => {
    this.setState({
      type: event.value,
    });
  };

  handle_ChangeVlanInterface = (event) => {
    this.setState({
      vlan_interface: event.value,
    });
  };

  handle_ChangeVlanId = (event) => {
    this.setState({
      vlan_id: parseInt(event.target.value),
    });
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let x of this.props.devices) {
      ret.push([x.interface, x.interface]);
    }
    return ret;
  };

  render_VlanOptions = () => {
    if (this.state.type === "vlan") {
      return (
        <div>
          <div className="formgroup-element-title">Interface</div>
          <div className="mui-select">
            <SearchableSelect
              value={this.state.vlan_interface}
              onChange={this.handle_ChangeVlanInterface}
              clearable={false}
              valueDataMap={this.get_SelectionMap()}
            ></SearchableSelect>
          </div>
          <div className="formgroup-element-title">Vlan Id</div>
          <div className="mui-textfield">
            <input type="number" value={this.state.vlan_id} onChange={this.handle_ChangeVlanId} />
          </div>
        </div>
      );
    }

    return <div />;
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow disable_overflow={true} title="Add Virtual Interface" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">Interface Type</div>
            <div className="mui-select">
              <SearchableSelect
                value={this.state.type}
                onChange={this.handle_ChangeType}
                clearable={false}
                valueDataMap={[
                  ["bridge", "Bridge"],
                  ["vlan", "Vlan"],
                  ["lacp", "Bonded Interface"],
                ]}
              ></SearchableSelect>
            </div>

            {this.render_VlanOptions()}
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class Interfaces extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible__add: false,
      devices: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/config/device/ajax/network/devices", function (result) {
      let devices = result["data"];
      self.setState({
        devices: devices,
        loaded: true,
      });
    });
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Virtual Interface" onClickHandler={this.handler__clickAdd} />;
  };

  handle_Delete = (i) => {
    let self = this;
    Api.get("/config/device/ajax/network/devices/" + i + "/delete", function (result) {
      self.handle_load();
    });
  };

  handleBounce = (i) => {
    let self = this;
    Api.get("/config/device/ajax/network/devices/" + i + "/refresh", function (result) {
      self.handle_load();
    });
  };

  handleBringDown = (i) => {
    let self = this;
    Api.get("/config/device/ajax/network/devices/" + i + "/down", function (result) {
      self.handle_load();
    });
  };

  handleBringUp = (i) => {
    let self = this;
    Api.get("/config/device/ajax/network/devices/" + i + "/up", function (result) {
      self.handle_load();
    });
  };

  handle__Add = (interface_details) => {
    let self = this;
    Api.put("/config/device/ajax/network/devices", interface_details, function (result) {
      self.handle_load();
    });
  };

  handle__ToggleInterfacePersisted = (row) => {
    let self = this;
    row["persisted"] = !row["persisted"];

    self.setState({ loaded: false });

    Api.post(
      "/config/device/ajax/network/devices/" + row["interface"],
      row,
      function (e) {
        self.handle_load();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  typeText = (row) => {
    if (row["bridge"] === true) {
      return "Bridge";
    } else if (row["vlan"] === true) {
      return "Vlan";
    } else if (row["lacp"] === true) {
      return "Bonded Link";
    } else {
      return "Physical Link";
    }
  };

  sortable__type_text = (row_a, row_b) => {
    return FlexibleTable.sort_caseinsensitive_strings(this.typeText(row_a), this.typeText(row_b));
  };

  activeIpAddressList = (row) => {
    if (row["dhcp"]) {
      if (row["dhcp_lease"] && row["dhcp_lease"]["state"] === "DHCP_TRANSACTION_FINISHED") {
        return row["ipv4"];
      } else {
        return [];
      }
    } else {
      return row["interface"] === "lo" ? row["ipv4"] : row["ipv4_configured"];
    }
  };

  activeIpAddressStrings = (row) => {
    const active_ip_addresses_list = this.activeIpAddressList(row);
    let ret = [];
    for (let ip of active_ip_addresses_list) {
      let separator = active_ip_addresses_list.length >= 2 && ret.length < active_ip_addresses_list.length - 1 ? ", " : "";
      ret.push(`${ip.ip}/${ip.mask}${separator}`);
    }
    return ret;
  };

  activeIpAddressText = (row) => {
    return this.activeIpAddressStrings(row).join("");
  };

  noActiveIpsText = () => "Attempting to get DHCP lease";

  sortable__active_addresses = (row_a, row_b) => {
    const addressesA = this.activeIpAddressList(row_a);
    const addressesB = this.activeIpAddressList(row_b);

    const lengthA = addressesA.length;
    const lengthB = addressesB.length;
    const commonLength = Math.min(lengthA, lengthB);

    for (let i = 0; i < commonLength; i++) {
      const ipA = `${addressesA[i].ip}/${addressesA[i].mask}`;
      const ipB = `${addressesB[i].ip}/${addressesB[i].mask}`;
      const ipSort = FlexibleTable.sort_ip_with_separator(ipA, ipB, "/");
      if (ipSort !== 0) {
        return ipSort;
      }
    }

    if (lengthA > lengthB) {
      return 1;
    } else if (lengthA < lengthB) {
      return -1;
    }
    return 0;
  };

  stateText = (row) => {
    let result = "";
    if (row["state"] === true) {
      result = "Admin UP";
    } else {
      result = "Admin Down";
    }

    if (row["pppoe_enabled"] === true) {
      if (row["pppoe_state"] === true) result += "/PPPOE UP";
      else result += "/PPPOE DOWN";
    }
    return result;
  };

  sortable__state = (row_a, row_b) => {
    return FlexibleTable.sort_caseinsensitive_strings(this.stateText(row_a), this.stateText(row_b));
  };

  linkText = (row) => {
    return row["has_link"] === true ? "Link UP" : "Link Down";
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Interface",
        data: function (row) {
          if (row["name"] !== "") {
            return <Link to={"/config/device/network/devices/" + row["interface"]}>{row["name"] + " " + row["interface"]}</Link>;
          }

          return <Link to={"/config/device/network/devices/" + row["interface"]}>{row["interface"]}</Link>;
        },
        sortable: FlexibleTable.sortable__multi_caseinsensitive_string_field("name", "interface"),
        search: FlexibleTable.search__multiple(["name", "interface"]),
        onclick: undefined,
      },
      {
        title: "Saved",
        data: function (row) {
          return <input type="checkbox" checked={row["persisted"]} onChange={() => self.handle__ToggleInterfacePersisted(row)} />;
        },
        sortable: FlexibleTable.sortable__single_bool_field("persisted"),
        onclick: undefined,
      },
      {
        title: "Type",
        data: function (row) {
          return <span>{self.typeText(row)}</span>;
        },
        sortable: self.sortable__type_text,
        search: FlexibleTable.search__with_function(self.typeText),
        onclick: undefined,
      },
      {
        title: "Active Addresses",
        data: function (row) {
          let active_ip_addresses_list = self.activeIpAddressStrings(row);
          if (!active_ip_addresses_list) {
            return <span>{self.noActiveIpsText()}</span>;
          }

          let ret = [];
          for (const ip of active_ip_addresses_list) {
            ret.push(<div>{ip}</div>);
          }
          return ret;
        },
        sortable: self.sortable__active_addresses,
        search: FlexibleTable.search__with_function(self.activeIpAddressText),
        onclick: undefined,
      },
      {
        title: "Mac Address",
        data: function (row) {
          return row["mac"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("mac"),
        search: FlexibleTable.search__single_string_field("mac"),
        onclick: undefined,
      },
      {
        title: "State",
        data: function (row) {
          return <span>{self.stateText(row)}</span>;
        },
        sortable: self.sortable__state,
        search: FlexibleTable.search__with_function(self.stateText),
        onclick: undefined,
      },
      {
        title: "Link",
        data: function (row) {
          return <span>{self.linkText(row)}</span>;
        },
        sortable: FlexibleTable.sortable__single_bool_field("has_link"),
        search: FlexibleTable.search__with_function(self.linkText),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton to={"/config/device/network/devices/" + row["interface"]} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row["interface"])} />
              <Separator />
              <Link onClick={() => self.handleBounce(row["interface"])}>
                <i className="fa fa-refresh" aria-hidden="true" />
              </Link>
              <Separator />
              <UpDownInterfaceButton
                id={row["interface"]}
                state={row["state"]}
                handleBringDown={self.handleBringDown}
                handleBringUp={self.handleBringUp}
              ></UpDownInterfaceButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.devices}
        hide_headers={false}
        loaded={this.state.loaded}
        className="cell-vertical-align-top"
      />
    );
  };

  render() {
    return (
      <ABTester
        optionB={
          <TableLeftPanel>
            <AddInterface
              devices={this.state.devices}
              visible={this.state.visible__add}
              handleAdd={this.handle__Add}
              handleClose={this.handler__clickCloseAdd}
            ></AddInterface>

            <TableCentricPage icon="fa fa-wifi" title="Interfaces" buttons={this.render__buttons()} content={this.render__content()} />
          </TableLeftPanel>
        }
        optionA={<InterfacesNew data={this.state.devices} loaded={this.state.loaded} />}
        isOptionB={true}
      />
    );
  }
}
