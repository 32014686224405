import { ABTester, Spinner } from "@familyzone/component-library";
import PropTypes from "prop-types";
import React from "react";

// TODO: Have this read the new UI flag from accountStore
const DumbBusyIndicator = ({ loaded }) => {
  if (!loaded) {
    return (
      <div className="dumb_busy_indicator">
        <ABTester optionB={<i className="fa fa-spinner fa-spin" />} optionA={<Spinner size="sm" />} isOptionB={true} />
      </div>
    );
  }
  return <></>;
};

DumbBusyIndicator.propTypes = {
  loaded: PropTypes.bool,
};

export default DumbBusyIndicator;
