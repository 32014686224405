import { Flex, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { Box, Button, Checkbox, InlineNotification, Input, Option, Text, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import { Link } from "react-router";
import { GroupSingle, GroupUsers } from "../../../types/Groups";
import { getGroup, updateGroup } from "../../../utils/api/Groups";
import { MultiGroupSearchSelector } from "../../GroupSearch/MultiGroupSearchSelector";
import { MultiUserSearchSelectorReturningOptions } from "../../UserSearch/MultiUserSearchSelectorReturningOptions";
import { subtract } from "../DiffSetHelper";
import { formatUser } from "../../UserSearch/UserSearchHelper";
import { mapGroupsToOptionsNoLabelFallbackWithID } from "../../GroupSearch/GroupSearchHelper";
import CardSpinner from "../../reporting/UserDashboard/CardSpinner";

interface IManageGroupProps {
  params: {
    id: string;
  };
}

const ManageGroup: React.FC<IManageGroupProps> = ({ params }: IManageGroupProps) => {
  const { id } = params;
  const [groupDetails, setGroupDetails] = React.useState<GroupSingle>();
  const [filteredUsers, setFilteredUsers] = React.useState<GroupUsers[]>([]);
  const [selectedSubGroups, setSelectedSubGroups] = React.useState<Option[]>([]);
  const [selectedOwners, setSelectedOwners] = React.useState<Option[]>([]);
  const [saving, setSaving] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  React.useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const group = await getGroup(id);
        if (group.users) setFilteredUsers(group.users.slice(0, 9));
        if (group.directSubgroups) setSelectedSubGroups(mapGroupsToOptionsNoLabelFallbackWithID(group.directSubgroups));
        if (group.owners)
          setSelectedOwners(group.owners.map((owner) => ({ value: owner.id, label: formatUser(owner), username: owner.username })));
        setGroupDetails(group); // set the group details at the end so that the other state changes don't trigger a re-render
        setErrorState(false);
      } catch (_) {
        setErrorState(true);
      }
    };
    void fetchGroupData();
  }, [id]);

  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  if (errorState)
    return (
      <Box m="sp24">
        <InlineNotification
          status="error"
          notificationTitle={t("Error loading group")}
          notificationDescription={t("There's a problem loading this group right now. Please try again later.")}
        />
      </Box>
    );

  if (!groupDetails) return <CardSpinner />;

  const filterUsers = (value = "") => {
    if (!groupDetails.users) return [];
    const searchTerm = value.toLowerCase();
    return groupDetails.users
      .filter((user) => {
        const name = `${user.firstName} ${user.lastName}`.toLowerCase();
        const username = user.username.toLowerCase();
        return name.includes(searchTerm) || username.includes(searchTerm);
      })
      .slice(0, 9); // Return the first 10 results of the filter.
  };

  const userTextToDisplay = (user: GroupUsers) => {
    if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
    else return user.username;
  };

  const handleSave = async () => {
    setSaving(true);

    // Work out the differences between the current state and the new state and which to remove and add.
    const newOwners = Array.from(
      subtract(new Set(selectedOwners.map((owner) => owner.value.toString())), new Set(groupDetails.owners.map((owner) => owner.id)))
    );
    const oldOwners = Array.from(
      subtract(new Set(groupDetails.owners.map((owner) => owner.id)), new Set(selectedOwners.map((owner) => owner.value.toString())))
    );
    const newSubgroups = Array.from(
      subtract(
        new Set(selectedSubGroups.map((group) => group.value.toString())),
        new Set(groupDetails.directSubgroups.map((group) => group.id))
      )
    );
    const oldSubgroups = Array.from(
      subtract(
        new Set(groupDetails.directSubgroups.map((group) => group.id)),
        new Set(selectedSubGroups.map((group) => group.value.toString()))
      )
    );

    try {
      await updateGroup(id, { archived: groupDetails.archived, newOwners, oldOwners, newSubgroups, oldSubgroups });
      successToast({ title: t("Group saved successfully.") });
    } catch (e) {
      errorToast({ title: t("Error saving group, please try again later.") });
    } finally {
      setSaving(false);
    }
  };

  const editingDisabled = groupDetails.sourceType !== "LOCAL";

  return (
    <Box m="sp24" width="100%">
      <Box backgroundColor="white">
        <Flex backgroundColor="white" padding="sp12" direction="column">
          <Stack spacing="sp12" width="80%">
            {editingDisabled && (
              <Box pt="sp12">
                <InlineNotification
                  status="warning"
                  notificationTitle={t("Editing disabled")}
                  notificationDescription={t("This group is managed by a provider and cannot be edited.")}
                />
              </Box>
            )}
            <Text fontSize="xl">{t("Manage Group")}</Text>
            <FormControl>
              <FormLabel>{t("Name")}</FormLabel>
              <Input value={groupDetails.name} isDisabled />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Description")}</FormLabel>
              <Input value={groupDetails.description ? groupDetails.description : "<not set>"} isDisabled />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Provider")}</FormLabel>
              <Input value={groupDetails.sourceType} isDisabled />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Provider DN")}</FormLabel>
              <Input value={groupDetails.distinguishedName ? groupDetails.distinguishedName : "<not set>"} isDisabled />
            </FormControl>
            <FormControl>
              <Checkbox
                isChecked={groupDetails.archived}
                isDisabled={editingDisabled}
                onChange={() => setGroupDetails({ ...groupDetails, archived: !groupDetails.archived })}
              >
                {t("Archived")}
              </Checkbox>
            </FormControl>
          </Stack>
          <Stack spacing="sp12" width="80%" my="sp12">
            <Text fontSize="xl">Owners and Subgroups</Text>
            <FormControl>
              <FormLabel>{t("Subgroups")}</FormLabel>
              <Box data-testid="group-selector">
                <MultiGroupSearchSelector
                  disabled={editingDisabled}
                  preselected={selectedSubGroups}
                  onChangeGroups={(selection) => setSelectedSubGroups(selection)}
                  useLegacyId={true}
                />
              </Box>
            </FormControl>
            <FormControl>
              <FormLabel>{t("Owners")}</FormLabel>
              <Box data-testid="owners-selector">
                <MultiUserSearchSelectorReturningOptions
                  disabled={editingDisabled}
                  preselected={selectedOwners}
                  onChangeUsers={(selection) => setSelectedOwners(selection)}
                  useLegacyId={true}
                />
              </Box>
            </FormControl>
          </Stack>
          <Stack spacing="sp12" width="80%">
            <Text fontSize="xl">Users</Text>
            <InlineNotification
              status="warning"
              notificationDescription={
                "Only the first 1000 users can be loaded. If a user does not appear when filtering please check their User Details by going to Configuration > Users & Groups > Users and searching for them."
              }
            />
            <FormControl>
              <FormLabel>{t("Filter users in the group")}</FormLabel>
              <Input placeholder="Type something..." onChange={(e) => setFilteredUsers(filterUsers(e.target.value))} />
            </FormControl>
            <Flex data-testid="user-filter-results" direction="column">
              {filteredUsers.map((user, index) => {
                return (
                  <Link style={{ marginTop: "1px" }} to={"/config/device/userdb/users/id/" + user.id} key={index}>
                    <Text as="span" pl="sp8" color="brand.500">
                      {userTextToDisplay(user)}
                    </Text>
                  </Link>
                );
              })}
            </Flex>
          </Stack>
        </Flex>
        <Box mx="sp12">
          <hr />
        </Box>
        <Button
          maxWidth="auto"
          ml="sp12"
          mb="sp24"
          disabled={saving || editingDisabled}
          isLoading={saving}
          variant="primary"
          onClick={() => void (async () => await handleSave())()}
        >
          {saving ? t("Saving...") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default ManageGroup;
