import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import uuid from "uuid/v4";

import CaptivePortalActions from "../../actions/CaptivePortalActions";
import CaptivePortalStore from "../../stores/CaptivePortalStore";

import CriteriaEditor from "../../modules/CriteriaEditor";
import CriteriaPrinter from "../../modules/CriteriaPrinter";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import LeftPanel from "../../utils/LeftPanel";

import { ABTester, Box, useTranslation } from "@familyzone/component-library";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import CardBasedPage from "../templates/CardBasedPage";
import { MultiGroupSearchSelector } from "../GroupSearch/MultiGroupSearchSelector";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";

const AuthenticationManagePortalNew = () => {
  const { t } = useTranslation();
  const title = t("Edit Captive Portal");

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("Captive Portal"), url: "/config/device/authentication/portals", isActive: false },
    { title: t("Edit Captive Portal"), isActive: true },
  ];

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box minHeight="500px" />
    </CardBasedPage>
  );
};

class Portal extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.configuration === undefined) {
      this.state = {
        configuration: {},
      };
    } else {
      this.state = {
        configuration: props.configuration,
      };
    }
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.configuration === undefined) {
      this.setState({
        configuration: {},
      });
    } else {
      this.setState({
        configuration: nextprops.configuration,
      });
    }
  }

  handle_BubbleChanges(configuration) {
    this.props.bubbleChanges(configuration);
  }

  /* Form setters */
  handle_ChangeLoginMessage = (event) => {
    const configuration = this.state.configuration;
    configuration.user_defined_authentication_message = event.target.value;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeGoogleChecked = (event) => {
    const configuration = this.state.configuration;
    configuration.google_authentication = event.target.checked;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeAzureChecked = (event) => {
    const configuration = this.state.configuration;
    configuration.azuread_authentication = event.target.checked;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeStandardChecked = (event) => {
    const configuration = this.state.configuration;
    configuration.standard_authentication = event.target.checked;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeGuestChecked = (event) => {
    const configuration = this.state.configuration;
    configuration.guest_authentication = event.target.checked;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeSSLOnboardingEnabled = (event) => {
    const configuration = this.state.configuration;
    configuration.ssl_onboarding_enabled = event.target.checked;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeRedirect = (event) => {
    const configuration = this.state.configuration;
    configuration.captive_portal_redirect_on_success = event.target.checked;
    if (configuration.captive_portal_redirect_on_success === false) {
      configuration.captive_portal_redirect_on_success_url = "";
    }
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangeRedirectUrl = (event) => {
    const configuration = this.state.configuration;
    configuration.captive_portal_redirect_on_success_url = event.target.value;
    this.handle_BubbleChanges(configuration);
  };

  handle_ChangePermanentAssociationsAllowed = (event) => {
    const configuration = this.state.configuration;
    configuration.enable_persist_session = event.target.checked;
    if (event.target.checked === true) {
      configuration.persisted_sessions_groups = [];
    }

    this.handle_BubbleChanges(configuration);
  };

  handle_UploadFile = (event) => {
    let self = this;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      self.state.configuration.logo_file_base64 = reader.result;
      self.handle_BubbleChanges(self.state.configuration);
    };
  };

  shouldDisplayPortalOptions = () => {
    return (
      this.state.configuration.guest_authentication ||
      this.state.configuration.standard_authentication ||
      this.state.configuration.azuread_authentication ||
      this.state.configuration.google_authentication
    );
  };

  handle_ChangeGroup = (group) => {
    const configuration = this.state.configuration;
    configuration.persisted_sessions_groups = group.map((group) => group.name);
    this.setState({
      configuration: configuration,
    });
  };

  render() {
    return (
      <div>
        <div className="formgroup">
          <div className="formgroup_title">
            <div className="formgroup_title_title">Portal Options</div>
          </div>
          <div className="formgroup-content">
            <div className="formgroup-element">
              <div className="formgroup-element-title">Message</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input
                    type="text"
                    value={this.state.configuration.user_defined_authentication_message}
                    onChange={this.handle_ChangeLoginMessage}
                    data-cy="captive_portal-message"
                  />
                </div>
              </div>
            </div>
            <div className="formgroup-element">
              <div className="formgroup-element-title">Logo</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <img className="uploaded_image_template" alt="" src={this.state.configuration.logo_file_base64} />
                  <input
                    type="file"
                    name="authLogo"
                    className="checkbox-bumpdown"
                    onChange={this.handle_UploadFile}
                    data-cy="captive_portal-logo"
                  />
                </div>
              </div>
            </div>

            <div className="formgroup-element">
              <div className="formgroup-element-title">Methods</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <div className="login_method">
                    <i className="fa-brands fa-google" aria-hidden="true" />
                    <div>Google</div>
                    <input
                      type="checkbox"
                      className="checkbox-bumpdown"
                      checked={this.state.configuration.google_authentication}
                      onChange={this.handle_ChangeGoogleChecked}
                      data-cy="captive_portal-methods_google"
                    />
                  </div>

                  <div className="login_method">
                    <i className="fa-brands fa-microsoft" aria-hidden="true" />
                    <div>Azure</div>

                    <input
                      type="checkbox"
                      className="checkbox-bumpdown"
                      checked={this.state.configuration.azuread_authentication}
                      onChange={this.handle_ChangeAzureChecked}
                      data-cy="captive_portal-methods_azure"
                    />
                  </div>

                  <div className="login_method">
                    <i className="fa-solid fa-key" aria-hidden="true" />
                    <div>Standard</div>
                    <input
                      type="checkbox"
                      className="checkbox-bumpdown"
                      checked={this.state.configuration.standard_authentication}
                      onChange={this.handle_ChangeStandardChecked}
                      data-cy="captive_portal-methods_standard"
                    />
                  </div>
                  <div className="login_method">
                    <i className="fa-solid fa-address-book" aria-hidden="true" />
                    <div>Guest</div>
                    <input
                      type="checkbox"
                      className="checkbox-bumpdown"
                      checked={this.state.configuration.guest_authentication}
                      onChange={this.handle_ChangeGuestChecked}
                      data-cy="captive_portal-methods_guest"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: this.shouldDisplayPortalOptions() ? "block" : "none" }}>
          <div className="formgroup">
            <div className="formgroup_title">
              <div className="formgroup_title_title">Permanent Associations</div>
            </div>
            <div className="formgroup-content">
              <div className="dialog_subheading_desc">
                Once users have signed in via the portal, they can be given the option to save their login permanently. This means they will
                not have to login again on this device.
              </div>
              <div className="no_form_div">
                <div className="formgroup-element">
                  <div className="formgroup-element-title">All Users</div>
                  <div className="formgroup-element-fields">
                    <div className="mui-checkbox">
                      <input
                        type="checkbox"
                        checked={this.state.configuration.enable_persist_session}
                        onChange={this.handle_ChangePermanentAssociationsAllowed}
                        data-cy="captive_portal-permanent_associations"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: !this.state.configuration.enable_persist_session ? "block" : "none" }}>
                <div className="formgroup-element">
                  <div className="formgroup-element-title">Groups</div>
                  <div className="formgroup-element-fields">
                    {this.state.configuration.persisted_sessions_groups && (
                      <MultiGroupSearchSelector
                        onChangeGroups={this.handle_ChangeGroup}
                        preselected={this.state.configuration.persisted_sessions_groups?.map((group) => ({ value: group, name: group }))}
                        useLegacyId={true}
                      ></MultiGroupSearchSelector>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="formgroup">
            <div className="formgroup_title">
              <div className="formgroup_title_title">Redirect to Website</div>
            </div>
            <div className="formgroup-content">
              <div className="formgroup-element">
                <div className="formgroup-element-title">Auto Redirect</div>
                <div className="formgroup-element-fields">
                  <div className="formgroup-element-fields-inline">
                    <div className="mui-checkbox">
                      <input
                        type="checkbox"
                        checked={this.state.configuration.captive_portal_redirect_on_success}
                        onChange={this.handle_ChangeRedirect}
                      />
                    </div>

                    <div className="mui-textfield">
                      <input
                        type="text"
                        value={this.state.configuration.captive_portal_redirect_on_success_url}
                        onChange={this.handle_ChangeRedirectUrl}
                        placeholder="Set Website"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formgroup">
            <div className="formgroup_title">
              <div className="formgroup_title_title">SSL Onboarding</div>
            </div>
            <div className="formgroup-content">
              <div className="dialog_subheading_desc">
                If your school is using SSL MITM, you can allow onboarding via the captive portal. If this option is ticked, when users are
                logging in we will check to see if they have installed the SSL certificates and prompt installation if not already
                installed.
              </div>

              <div className="formgroup-element">
                <div className="formgroup-element-title">Enable</div>
                <div className="formgroup-element-fields">
                  <div className="mui-checkbox">
                    <input
                      type="checkbox"
                      checked={this.state.configuration.ssl_onboarding_enabled}
                      onChange={this.handle_ChangeSSLOnboardingEnabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AddAuthenticationExceptionModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      exception_criteria: [],
      exception_name: "",
    };
  }

  handle_ExceptionNameChanged = (event) => {
    this.setState({
      exception_name: event.target.value,
    });
  };

  handle_ExceptionCriteriaChanged = (criteria) => {
    this.setState({ exception_criteria: criteria });
  };

  handle_AddExceptionCriteria = (event) => {
    event.preventDefault();
    this.props.handle_Add({ name: this.state.exception_name, criteria: this.state.exception_criteria });
  };

  renderActions = () => {
    return (
      <button
        type="submit"
        className="mui-btn mui-btn--raised"
        onClick={this.handle_AddExceptionCriteria}
        data-cy="captive_portal_modal-add_exception"
      >
        Add Exception
      </button>
    );
  };

  handleHide = () => {
    this.props.handleHide();
    this.setState({
      exception_criteria: [],
      exception_name: "",
    });
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow
          disable_overflow={this.state.exception_criteria.length <= 4}
          title="Add Exception"
          handleHide={this.handleHide}
          actions={this.renderActions()}
        >
          <form className="mui-form" onSubmit={this.handle_AddExceptionCriteria}>
            <div className="formgroup-element">
              <div className="formgroup-element-title">Name</div>
              <div className="formgroup-element-fields">
                <div className="mui-textfield">
                  <input
                    required
                    type="text"
                    value={this.state.exception_name}
                    onChange={this.handle_ExceptionNameChanged}
                    data-cy="captive_portal-exception_name"
                  />
                </div>
              </div>
            </div>

            <CriteriaEditor
              criteria={this.state.exception_criteria}
              handleCriteriaModified={this.handle_ExceptionCriteriaChanged}
              allowed={[
                "ipv4.range",
                "source.mac",
                "ipv4",
                "ipv4.address",
                "application.http.hostname.regex",
                "fingerprint",
                "signature",
                "ipv4.alias",
                "source.mac.pool",
                "application.http.hostname",
              ]}
              data-cy="captive_portal-exception_criteria"
            ></CriteriaEditor>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

class AddIncludedNetworkModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      criteria: this.props.criteria,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_CriteriaChanged = (criteria) => {
    this.setState({ criteria: criteria });
  };

  handle_AddExceptionCriteria = (event) => {
    event.preventDefault();
    this.props.handle_Add(this.state.criteria);
  };

  handleHide = () => {
    this.props.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_AddExceptionCriteria}>
        Save
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Edit Included Networks" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form included-networks-form">
            <CriteriaEditor
              criteria={this.state.criteria}
              handleCriteriaModified={this.handle_CriteriaChanged}
              allowed={["ipv4.range", "ipv4", "ipv4.address", "ipv4.alias"]}
            ></CriteriaEditor>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class AuthenticationManagePortal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      portal: {
        exceptions: [],
        inclusions: [],
        enabled: false,
      },
      optionsOpen: true,
      exceptionsOpen: false,

      exception_criteria: [],
      exception_name: "",
      add_exception_open: false,
      add_included_network_open: false,
      loaded: false,
      featureFlags: useFeatureFlagStore.getState().flags ?? {},
    };
  }

  componentDidMount() {
    useFeatureFlagStore
      .getState()
      .getOrFetch()
      .then((flags) => {
        this.setState((state) => ({ ...state, featureFlags: flags }));
      });

    CaptivePortalStore.listen(this.onChange);

    setTimeout(() => {
      CaptivePortalActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    CaptivePortalStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      portal: CaptivePortalStore.getPortal(this.props.params.portal),
      loaded: CaptivePortalStore.getPortal(this.props.params.portal) !== undefined,
    });
  };

  handleSave = (event) => {
    event.preventDefault();
    CaptivePortalActions.savePortal(this.state.portal);
    this.context.router.push("/config/device/authentication/portals");
  };

  handle_FormUpdateName = (e) => {
    const portal = this.state.portal;
    portal["name"] = e.target.value;
    this.setState({ portal: portal });
  };

  handle_FormUpdateEnabled = (e) => {
    const portal = this.state.portal;
    portal["enabled"] = e.target.checked;
    this.setState({ portal: portal });
  };

  handle_FormUpdateRestrictiveMode = (e) => {
    const portal = this.state.portal;
    portal["restrictive_mode"] = e.target.checked;
    this.setState({ portal: portal });
  };

  handle_CaptivePortalConfigurationChanged = (config) => {
    const portal = this.state.portal;
    portal["portal_configuration"] = config;
    this.setState({ portal: portal });
  };

  handle_AddExceptionCriteria = (new_criteria) => {
    const portal = this.state.portal;
    if (!portal.exceptions) {
      portal.exceptions = [];
    }

    new_criteria["id"] = uuid();
    portal.exceptions.unshift(new_criteria);

    this.setState({
      portal: portal,
      add_exception_open: false,
    });
  };

  handle_RemoveExceptionCriteria = (criteria_id) => {
    const portal = this.state.portal;
    let new_exceptions = [];
    for (let exception of portal.exceptions) {
      if (exception["id"] !== criteria_id) {
        new_exceptions.push(exception);
      }
    }

    portal.exceptions = new_exceptions;

    this.setState({
      portal: portal,
    });
  };

  handle_ExceptionNameChanged = (event) => {
    this.setState({
      exception_name: event.target.value,
    });
  };

  handle_ClickAddException = () => {
    this.setState({
      add_exception_open: true,
    });
  };

  handle_CloseAddException = () => {
    this.setState({
      add_exception_open: false,
    });
  };

  handle_AddIncludedNetwork = () => {
    this.setState({
      add_included_network_open: true,
    });
  };

  handle_CloseAddIncludedNetwork = () => {
    this.setState({
      add_included_network_open: false,
    });
  };

  handle_AddIncludedNetworkCriteria = (new_criteria) => {
    const portal = this.state.portal;
    portal["inclusions"] = new_criteria;
    this.setState({
      portal: portal,
      add_included_network_open: false,
    });
  };

  handle_RemoveIncludedNetworkCriteria = (new_criteria) => {
    for (let new_criteria_entry of new_criteria) {
      this.state.portal["inclusions"].push(new_criteria_entry);
    }

    this.setState({
      portal: this.state.portal,
      add_included_network_open: false,
    });
  };

  render_Exceptions = () => {
    let self = this;
    let columns = [
      {
        title: "Name",
        data: function (row) {
          return row["name"];
        },
        onclick: undefined,
      },
      {
        title: "Criteria",
        data: function (row) {
          return <CriteriaPrinter criteria={row.criteria} />;
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <Link onClick={() => self.handle_RemoveExceptionCriteria(row["id"])}>
                <i className="fa fa-trash" aria-hidden="true" />
              </Link>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return (
      <FlexibleTable
        columns={columns}
        data={this.state.portal.exceptions}
        className="formgroup-table"
        hide_headers={true}
        loaded={this.state.loaded}
      />
    );
  };

  render() {
    if (this.state.portal === undefined) {
      return <div />;
    }

    const showRestrictiveMode = this.state.featureFlags["configure_restrictive_mode"];

    return (
      <ABTester
        optionB={
          <LeftPanel>
            <LetterPanel>
              <LetterPanelHeader>
                Captive Portal
                <DumbBusyIndicator loaded={this.state.loaded} />
              </LetterPanelHeader>

              <LetterPanelBody>
                <form className="mui-form">
                  <div className="formgroup">
                    <div className="formgroup-content">
                      <div className="formgroup-element">
                        <div className="formgroup-element-title">Enabled</div>
                        <div className="formgroup-element-fields">
                          <div className="mui-checkbox">
                            <input
                              type="checkbox"
                              checked={this.state.portal.enabled}
                              onChange={this.handle_FormUpdateEnabled}
                              data-cy="captive_portal-enabled"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="formgroup-element">
                        <div className="formgroup-element-title">Name</div>
                        <div className="formgroup-element-fields">
                          <div className="mui-textfield">
                            <input
                              required
                              type="text"
                              value={this.state.portal.name}
                              onChange={this.handle_FormUpdateName}
                              data-cy="captive_portal-name"
                            />
                          </div>
                        </div>
                      </div>

                      {showRestrictiveMode && (
                        <div className="formgroup-element">
                          <div className="formgroup-element-title">Restrictive Mode</div>
                          <div className="formgroup-element-fields">
                            <div className="mui-checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.portal.restrictive_mode}
                                onChange={this.handle_FormUpdateRestrictiveMode}
                                data-cy="captive_portal-restrictive_mode"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <AddIncludedNetworkModal
                        criteria={this.state.portal.inclusions}
                        visible={this.state.add_included_network_open}
                        handle_Add={this.handle_AddIncludedNetworkCriteria}
                        handleHide={this.handle_CloseAddIncludedNetwork}
                      ></AddIncludedNetworkModal>
                      <div className="formgroup-element">
                        <div className="formgroup-element-title">Networks</div>
                        <div className="formgroup-element-fields">
                          <div className="mui-textfield">
                            <br />
                            <CriteriaPrinter criteria={this.state.portal.inclusions} />
                            <Link onClick={this.handle_AddIncludedNetwork}>Edit</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Portal
                    configuration={this.state.portal.portal_configuration}
                    bubbleChanges={this.handle_CaptivePortalConfigurationChanged}
                  />

                  <div className="formgroup">
                    <div className="formgroup_title">
                      <div className="formgroup_title_title">Exceptions</div>
                      <div className="formgroup_title_rightlink_button">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="heading_button" onClick={this.handle_ClickAddException} data-cy="captive_portal-add_exception">
                          Add Exception
                        </a>
                      </div>
                    </div>
                    <div className="formgroup-content formgroup-content-nopadding formgroup-content-fullwidth">
                      <AddAuthenticationExceptionModal
                        visible={this.state.add_exception_open}
                        handleHide={this.handle_CloseAddException}
                        handle_Add={this.handle_AddExceptionCriteria}
                      ></AddAuthenticationExceptionModal>
                      {this.render_Exceptions()}
                    </div>
                  </div>
                </form>
              </LetterPanelBody>
              <LetterPanelFooter>
                <LetterPanelSaveButton onClick={this.handleSave} changes={true} />
              </LetterPanelFooter>
            </LetterPanel>
          </LeftPanel>
        }
        optionA={<AuthenticationManagePortalNew />}
        isOptionB={true}
      />
    );
  }
}

AuthenticationManagePortal.contextTypes = {
  router: PropTypes.object.isRequired,
};
