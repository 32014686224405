import React from "react";
import { Flex, MenuItem } from "@familyzone/component-library";
import { MenuItemProps } from "@chakra-ui/react";

export const HeaderMenuItem: React.FC<MenuItemProps> = ({ children, ...props }) => (
  <MenuItem
    flexDirection="column"
    px="sp8"
    _first={{
      pt: "sp8",
    }}
    _last={{
      pb: "sp8",
    }}
    color="text.paragraph.title"
    fontFamily="paragraphs"
    width="100%"
    height="100%"
    {...props}
  >
    <Flex
      flexDirection="column"
      padding="8px"
      borderRadius="4px"
      width="100%"
      height="100%"
      _hover={{
        bg: "neutrals.20",
      }}
    >
      {children}
    </Flex>
  </MenuItem>
);
