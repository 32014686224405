import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Box, InlineNotification, useToast, useTranslation } from "@familyzone/component-library";
import React, { useCallback, useEffect, useMemo } from "react";
import { useCommunityFeatureFlagStore } from "../../../storez/CommunityFeatureFlagStore";
import { useDevicePermissionsStore } from "../../../storez/DevicePermissionsStore";
import { ResponseError } from "../../../types/Api";
import { Permission } from "../../../types/Authorization";
import CardBasedPage from "../../templates/CardBasedPage";
import ManageDeviceConfiguration from "../ManageDeviceConfiguration";
import { CommunityAudit } from "./CommunityAudit";
import { isCommunityAuditsAccessible } from "./AuditUtils";

export enum TabNameEnum {
  General = "general",
  Community = "community",
}

interface Props {
  params: {
    tabName: TabNameEnum;
  };
}

const ConfigAudit: React.FC<Props> = ({ params }) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [communityFlags, fetchCommunityFlags] = useCommunityFeatureFlagStore(
    useCallback((state) => [state.flags, state.getOrFetch] as const, [])
  );

  const [isSupportAdmin, permissions] = useDevicePermissionsStore(
    useCallback((state) => [state.supportAdmin, state.permissions] as const, [])
  );

  useEffect(() => {
    fetchCommunityFlags().catch((err: ResponseError) => {
      errorToast({
        title: t("Please try again"),
        description: t(err.message),
        isClosable: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommunityFlags]);

  const isOnlyCommunityAdmin = useMemo(() => {
    return permissions.length === 1 && permissions[0] === Permission.CommunityAdmin;
  }, [permissions]);

  const canAccessCommunity = useMemo(() => {
    return isCommunityAuditsAccessible(permissions, isSupportAdmin);
  }, [permissions, isSupportAdmin]);

  const canAccessGeneral = !isOnlyCommunityAdmin;

  const accessedTabs: TabNameEnum[] = useMemo(() => {
    const tabs: TabNameEnum[] = [];
    if (canAccessGeneral) tabs.push(TabNameEnum.General);
    if (canAccessCommunity) tabs.push(TabNameEnum.Community);
    return tabs;
  }, [canAccessCommunity, canAccessGeneral]);

  const getLandingTabIndex = () => {
    const tabIndex = accessedTabs.indexOf(params.tabName);
    return tabIndex > -1 ? tabIndex : 0;
  };

  const rewriteUrlPath = (selectedTabIndex: number) => {
    const pathname = process.env.NODE_ENV === "development" ? window.location.hash : window.location.pathname;

    // Get switched tab name
    // E.g "community" if the URL is /managedevice/settings/audit/community
    const currentTabName = pathname.match(/.*\/settings\/audit(.*)/);
    const selectedTabName = accessedTabs[selectedTabIndex];

    const newPathname =
      currentTabName === null || currentTabName[1] === ""
        ? `${pathname}/${selectedTabName}`
        : pathname.replace(currentTabName[1], `/${selectedTabName}`);

    window.history.replaceState("", "", newPathname);
  };

  const tabProps = {
    paddingX: "sp32",
    paddingY: "sp16",
    marginBottom: "-2px",
    borderBottomWidth: "2px",
    borderStyle: "solid",
    borderColor: "transparent",
    color: "text.title",
    fontFamily: "paragraphs",
    fontWeight: "regular",
    _selected: { background: "neutrals.0", borderColor: "brand.500", fontWeight: "medium" },
    _hover: { background: "neutrals.20", borderColor: "brand.500" },
  };

  return (
    <CardBasedPage
      title={t("Config Audit")}
      breadcrumbs={[
        { title: t("Debugging"), isActive: false },
        { title: t("Config Audit"), isActive: true },
      ]}
    >
      <Tabs isLazy={true} defaultIndex={getLandingTabIndex()} onChange={(index) => rewriteUrlPath(index)}>
        <TabList borderStyle="solid" borderColor="neutrals.30" borderBottomWidth="2px" mb="-2px">
          {canAccessGeneral && (
            <Tab disabled={true} borderTopLeftRadius="sm" {...tabProps}>
              {t("General")}
            </Tab>
          )}

          {canAccessCommunity && <Tab {...tabProps}>{t("Community")}</Tab>}
        </TabList>

        <TabPanels>
          {canAccessGeneral && (
            <TabPanel>
              <ManageDeviceConfiguration />
            </TabPanel>
          )}

          {canAccessCommunity && (
            <TabPanel>
              {communityFlags?.enableCommunity ? (
                <CommunityAudit />
              ) : (
                <Box m="sp8" p="sp8">
                  <InlineNotification
                    status="error"
                    notificationDescription={
                      <Box color="text.title">{t("Community Audit is unavailable. Please enable the Community feature first.")}</Box>
                    }
                  />
                </Box>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </CardBasedPage>
  );
};

export default ConfigAudit;
