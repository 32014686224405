import React from "react";
import $ from "jquery";
import HighchartsModul from "./HighchartsModul";
import ComponentLoading from "./ComponentLoading";

export default class ZoomingColumnGraph extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      options: {},
    };
    this.formatBytes = this.formatBytes.bind(this);
    this.graph_update = this.graph_update.bind(this);
    this.update_options(this.props.data, this.props.interval_multiplier);
  }

  componentWillReceiveProps = (nextProps) => {
    this.update_options(nextProps.data, nextProps.interval_multiplier);
  };

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Byte";
    let k = 1000; // or 1024 for binary
    let dm = decimals + 1 || 3;
    let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  graph_update = (event) => {
    let startDate = new Date(event.min);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(event.max);
    endDate.setHours(0, 0, 0, 0);

    if (!(endDate < startDate.addDays(1))) {
      startDate = startDate.addDays(1);
    }

    $("#reportrange").data("daterangepicker").setStartDate(startDate);
    $("#reportrange").data("daterangepicker").setEndDate(endDate);
    $("#reportrange").data("daterangepicker").notify();
  };

  update_options = (series_data, interval_multiplier) => {
    let self = this;
    this.setState({
      data: series_data,
      options: {
        colors: ["#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"],
        chart: {
          type: "column",
          marginLeft: 34,
          marginBottom: 0,
          marginRight: 20,
          spacingLeft: 0,
          spacingRight: 0,
          height: 250,
          zoomType: "x",
          resetZoomButton: {
            theme: {
              display: "none",
            },
          },
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },

        xAxis: {
          type: "datetime",
          tickInterval: 3600 * 1000 * interval_multiplier,
          minTickInterval: 3600 * 1000 * interval_multiplier,
          endOnTick: false,
          maxPadding: 0,
          lineWidth: 0,
          tickWidth: 0,
          tickmarkPlacement: "on",
          labels: {
            useHTML: true,
            enabled: true,
            y: 20,
          },
          events: {
            setExtremes: function (event) {
              self.graph_update(event);
            },
          },
          minRange: 3600 * 1000 * interval_multiplier,
        },
        yAxis: {
          min: 0,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          showFirstLabel: false,
          showLastLabel: false,
          maxPadding: 0.5,
          title: {
            enabled: false,
          },
          labels: {
            x: -2,
            useHTML: true,
            formatter: function () {
              switch (self.props.dataType) {
                case "hits":
                  return '<span className="pd-chart-label-y">' + this.value + "</span>";
                case "data":
                default:
                  return '<span className="pd-chart-label-y">' + self.formatBytes(this.value, 1) + "</span>";
              }
              // return '<span className="pd-chart-label-y">' + this.value / 1000000000 + "</span>";
            },
          },
        },
        plotOptions: {
          column: {
            pointRange: 3600 * 1000 * interval_multiplier,
            groupPadding: 0,
            borderWidth: 0.5,
            stacking: "normal",
            pointPadding: 0.2,
            minPointLength: 3,
            lineColor: "#6FBDF8",
            states: {
              hover: {
                lineWidth: 2,
              },
            },
            events: {
              click: function (event) {
                if (event.point.url !== undefined) {
                  window.location = event.point.url;
                }
              },
            },
          },
        },
        credits: {
          enabled: !1,
        },
        tooltip: {
          formatter: function () {
            let return_string = "";
            switch (self.props.dataType) {
              case "hits":
                return_string += "</b><br><b>" + this.point.y + " hits</b>";
                break;
              case "data":
              default:
                return_string += "</b><br><b>" + self.formatBytes(this.point.y, 1) + "</b>";
                break;
            }
            for (let i in this.point.info) {
              return_string += "<br><b>" + this.point.info[i] + "</b>";
            }
            return return_string;
          },
        },
        series: series_data,
      },
    });
  };

  render = () => {
    return (
      <div style={{ height: "300px" }}>
        <div className="pd-main-chart-container">
          <div id="highcharts-graph-columnGraph" style={{ width: "97%" }}>
            {this.state.data ? <HighchartsModul container="columnGraph" options={this.state.options} /> : <ComponentLoading />}
          </div>
        </div>
      </div>
    );
  };
}
