import Api from "../utils/Api";

import alt from "../alt";
import InterfaceActions from "../actions/InterfaceActions";
import SessionActions from "../actions/SessionActions";

class InterfaceStore {
  constructor() {
    this.devices = [];

    this.exportPublicMethods({
      getInterfaces: this.getInterfaces,
    });

    this.bindListeners({
      handleFetch: InterfaceActions.FETCH,
      handleFetchUpdated: InterfaceActions.FETCH_UPDATED,

      handleDeselectDevice: SessionActions.DESELECT_DEVICE,
      handleChangeDevice: SessionActions.CHANGE_DEVICE,
    });
  }

  getInterfaces = () => {
    return this.devices;
  };

  handleDeselectDevice = () => {
    this.handleDestroy();
  };

  handleChangeDevice = () => {
    this.handleDestroy();
  };

  handleDestroy = () => {
    this.devices = [];
  };

  handleFetchUpdated(result) {
    this.devices = result;
  }

  handleFetch = () => {
    Api.get("/config/device/ajax/network/devices", function (result) {
      const devices = result["data"];
      InterfaceActions.fetchUpdated(devices);
    });
  };
}

export default alt.createStore(InterfaceStore, "InterfaceStore");
