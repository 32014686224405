import { Box, Button, Flex, MultiSelect, Table, TbodyInfiniteScroll, Td, Text, Th, Thead, Tr } from "@familyzone/component-library";
import { orderBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import "../../../css/ReportingSearch.css";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import SignatureTreeActions from "../../actions/SignatureTreeActions";
import WordCloud from "../../modules/WordCloud";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import SignatureTreeStore from "../../stores/SignatureTreeStore";
import { useReportingSearchStore } from "../../storez/MultiSelectStores";
import { useUserStore } from "../../storez/UserStore";
import Api from "../../utils/Api";
import { uriSafeBase64Encode } from "../../utils/Base64Util";
import Filterhelper from "../../utils/Filterhelper";
import { zIndices } from "../../utils/ZIndexUtil";
import Link from "../Link";
import PageWithHeader from "../templates/PageWithHeader";
import { fullNameOrUsername } from "../UserSearch/UserSearchHelper";

const default_tags = [
  { value: "sphirewall.keyword.adult", text: "Adult" },
  { value: "sphirewall.keyword.bullying", text: "Bullying" },
  { value: "sphirewall.keyword.suicide", text: "Suicide" },
];

const ReportingSearch = ({ params, location }) => {
  const [data, setData] = useState([]);
  const [wordCloudData, setWordCloudData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tags, setTags] = useState(useReportingSearchStore.getState().tags || []);
  const [categoryTags, setCategoryTags] = useState([]);
  const [filters] = useState(Filterhelper.consolidateFilters(params, location));

  const [sortedData, setSortedData] = useState([]);
  const [sortColumn, setSortColumn] = useState("user");
  const [sortDirection, setSortDirection] = useState("asc");
  const [shownRowCount, setShownRowCount] = useState(30);

  const getUsersAsMap = useUserStore((state) => state.getUsersAsMap);

  const showMore = () => {
    setShownRowCount(shownRowCount + 20);
  };

  const handleSort = (columnName, sortDirection) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection);
  };

  useEffect(() => {
    let sortedData = data;
    if (sortDirection) {
      sortedData = orderBy(data, sortColumn, sortDirection);
    }
    setSortedData(sortedData);
  }, [data, sortColumn, sortDirection]);

  const currentSearchRequest = useRef(undefined);

  const onChangeSignatures = () => {
    setCategoryTags(SignatureTreeStore.getKeywords());
  };

  const splitTags = useMemo(() => {
    const categoryTagIds = categoryTags.map((tag) => tag.id);
    const categories = tags.filter((tag) => categoryTagIds.includes(tag.value));
    const keywords = tags.filter((tag) => !categoryTagIds.includes(tag.value));
    return { keywords, categories };
  }, [categoryTags, tags]);

  const handleLoad = useCallback(() => {
    /* Has everything we needed loaded ?*/
    if (categoryTags.length === 0) {
      return;
    }

    if (currentSearchRequest.current) {
      currentSearchRequest.current.abort();
    }
    setLoaded(false);

    currentSearchRequest.current = Api.post_analytics(
      "/sufwize/ajax/search/users/overview?" + Filterhelper.serialise(filters),
      {
        keywords: splitTags.keywords.map((tag) => tag.value),
        categories: splitTags.categories.map((tag) => tag.value),
      },
      async (result) => {
        const keywords = result["result"]["keywords"];
        sessionStorage.setItem("keywords", JSON.stringify(keywords));

        const userResult = await getUsersAsMap(result["result"]["table_result"].map((userRow) => userRow.user));

        const resultData = result["result"]["table_result"].map((item) => {
          const user = userResult.get(item["user"]);
          const name = user ? fullNameOrUsername(user) : item["user"];
          return {
            name: item["user"],
            fullName: name,
            weight: item["hits"],
            url: `/cybersafety/search/${uriSafeBase64Encode(item["user"])}`,
          };
        });

        setData(resultData);
        setWordCloudData(result["result"]["wordcloud_result"]);
        setLoaded(true);
      },
      () => {
        setLoaded(true);
      }
    );
  }, [categoryTags, filters, getUsersAsMap, splitTags]);

  useEffect(() => {
    const unsubFilterStore = useDateRangeFilterStore.subscribe(handleLoad);
    SignatureTreeStore.listen(onChangeSignatures);

    setTimeout(() => {
      SignatureTreeActions.fetch();
    }, 0);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    return () => {
      unsubFilterStore();
      SignatureTreeStore.unlisten(onChangeSignatures);
    };
  }, [handleLoad]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad, categoryTags, filters, tags]);

  useEffect(() => {
    useReportingSearchStore.setState({ tags });
  }, [tags]);

  const onSeriesClick = (customTag) => {
    if (customTag) {
      // Make sure the tag does not already exist in state
      for (const tag of tags) {
        if (tag["value"] === customTag) {
          return;
        }
      }
      const newTag = [{ value: customTag, text: customTag }];
      setTags(newTag);
    }
  };

  const resetTags = () => {
    setTags(default_tags);
  };

  const breadcrumbs = [
    { title: "Cyber Safety", url: "/cybersafety", isActive: false },
    { title: "Searches", url: "/cybersafety/search", isActive: true },
  ];

  const tableBody = () => (
    <TbodyInfiniteScroll fetchData={showMore} hasMore={sortedData.length >= shownRowCount} parentElemId="ComponentWrapper" loaded={loaded}>
      {sortedData.slice(0, shownRowCount).map(({ name, fullName, weight }) => {
        const barMax = data.length > 0 ? data[0]["weight"] : 1;
        const { keywords, categories } = splitTags;
        const url = `/cybersafety/search/${uriSafeBase64Encode(
          JSON.stringify({
            user: name,
            keywords,
            categories,
          })
        )}`;
        return (
          <Tr key={name}>
            <Td>
              <Link fontSize="14px" to={url}>
                {name}
              </Link>
            </Td>
            <Td>
              <Text fontSize="14px">{fullName}</Text>
            </Td>
            <Td>
              <Text fontSize="14px">{weight}</Text>
            </Td>
            <Td>
              <Box w={(weight / barMax) * 100} h="6px" bg="brand.400" borderRadius="10px" />
            </Td>
          </Tr>
        );
      })}
    </TbodyInfiniteScroll>
  );

  const getMultiSelectValues = () => {
    return categoryTags.map((tagEntry) => ({
      value: tagEntry["id"],
      text: tagEntry["name"],
    }));
  };

  return (
    <PageWithHeader title="Searches" breadcrumbs={breadcrumbs}>
      <Flex mx="sp24" my="sp16">
        <MultiSelect
          showPills={true}
          label=""
          placeholder="Select filter tag"
          selectedItems={tags}
          items={getMultiSelectValues()}
          onChange={(tags) => {
            setTags(tags);
          }}
          canClearAll={false}
        />
        <Button variant="ghost" onClick={resetTags}>
          Reset Filters
        </Button>
      </Flex>
      <Flex flexDir="column" w="100%" backgroundColor="neutrals.0" p="sp24">
        <Box mb="sp16">
          <WordCloud
            data={wordCloudData}
            y_type=""
            container="amCharts-wordcloud_searches"
            name="Searches"
            onSeriesClick={onSeriesClick}
            dataLoaded={loaded}
            backgroundColor="#F4F5F7"
            wordColorLight="#5E6C84"
            wordColorDark="#051839"
            showTermsLabel={true}
          />
        </Box>
        <Table>
          <Thead position="sticky" top="0" zIndex={zIndices.thead}>
            <Tr>
              <Th
                columnName="name"
                handleSort={handleSort}
                sortDirection={sortColumn === "name" ? sortDirection : ""}
                headerText="Username"
              />
              <Th
                columnName="fullName"
                handleSort={handleSort}
                sortDirection={sortColumn === "fullName" ? sortDirection : ""}
                headerText="Name"
              />
              <Th
                columnName="weight"
                handleSort={handleSort}
                sortDirection={sortColumn === "weight" ? sortDirection : ""}
                headerText="Queries"
              />
              <Th />
            </Tr>
          </Thead>
          {tableBody()}
        </Table>
      </Flex>
    </PageWithHeader>
  );
};

export default ReportingSearch;
