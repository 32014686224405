import React, { Fragment } from "react";

import SessionStore from "../stores/SessionStore";

export default class AuthorizationWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devicePermissions: SessionStore.getDevicePermissions(),
      supportAdmin: SessionStore.isSupportAdmin(),
    };
  }

  componentWillMount() {
    SessionStore.listen(this.onChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devicePermissions: SessionStore.getDevicePermissions(),
      supportAdmin: SessionStore.isSupportAdmin(),
    });
  };

  render() {
    if (this.state.supportAdmin || !this.props.allowedRoles) {
      return <Fragment>{this.props.children}</Fragment>;
    }
    for (let permission of this.state.devicePermissions) {
      if (this.props.allowedRoles.indexOf(permission) !== -1) {
        return <Fragment>{this.props.children}</Fragment>;
      }
    }
    return <Fragment />;
  }
}
