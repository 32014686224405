import { Box, Button, Checkbox, Icon, InlineNotification, Spinner, useToast, useTranslation } from "@familyzone/component-library";
import React, { FunctionComponent, ChangeEvent, useEffect, useState } from "react";
import CardBasedPage from "../templates/CardBasedPage";
import { CLASSROOM_API, LicensedFeature, LicensedFeaturesResonseShape } from "../../utils/api/ClasswizeLicencing";
import { SectionHeading } from "../shared/SectionHeading";

const ClasswizeLicencing: FunctionComponent = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const title = t("Licenced Features");

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Licenced Features"), isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const [classroomLicenseActivated, setClassroomLicenseActivated] = useState<boolean>(false);
  const [coursesLicenseActivated, setCoursesLicenseActivated] = useState<boolean>(false);

  async function fetchLicensedFeatures(): Promise<void> {
    const response: Response = await CLASSROOM_API.getLicencedFeatures();

    const responseData: LicensedFeaturesResonseShape = (await response.json()) as LicensedFeaturesResonseShape;

    if (responseData.features.includes(LicensedFeature.Classroom)) {
      setClassroomLicenseActivated(true);
    } else {
      setClassroomLicenseActivated(false);
    }

    if (responseData.features.includes(LicensedFeature.Courses)) {
      setCoursesLicenseActivated(true);
    } else {
      setCoursesLicenseActivated(false);
    }
  }

  const handleToggleClassroomLicense = (): void => {
    setClassroomLicenseActivated((state) => !state);
  };

  const handleToggleCoursesLicense = (): void => {
    setCoursesLicenseActivated((state) => !state);
  };

  const handleSave = (_e: ChangeEvent<HTMLInputElement>): void => {
    setSaving(true);

    const features: LicensedFeature[] = [];

    if (classroomLicenseActivated) {
      features.push(LicensedFeature.Classroom);
    }

    if (coursesLicenseActivated) {
      features.push(LicensedFeature.Courses);
    }

    CLASSROOM_API.updateLicencedFeatures(features)
      .then(() => {
        setSaving(false);
        successToast({
          title: t("Update successful"),
          description: t("Configuration has been successfully updated"),
          position: "top",
          isClosable: true,
        });
      })
      .catch(() => {
        setSaving(false);
        errorToast({
          title: t("Failed to update Licensed Features"),
          description: t("Please ensure you hold the Support Admin role and try again"),
          position: "top",
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchLicensedFeatures()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box pt={"sp24"} px={"sp24"}>
        <InlineNotification
          status="info"
          notificationDescription={t(
            "This page is only visible to Support Admins so that schools can't grant themselves a license to use these products."
          )}
        />
      </Box>
      <Box minHeight="200px" p="sp24">
        <SectionHeading title={t("Classroom Management")} />
        <Box px="sp12" py="sp16">
          <Checkbox isChecked={classroomLicenseActivated} isDisabled={loading || saving} onChange={handleToggleClassroomLicense}>
            {t("School has purchased a license for Classroom Management")}
          </Checkbox>
        </Box>

        <SectionHeading title={t("Linewize Learning (Courses)")} />
        <Box px="sp12" py="sp16">
          <Checkbox isChecked={coursesLicenseActivated} isDisabled={loading || saving} onChange={handleToggleCoursesLicense}>
            {t("School has purchased a license for Linewize Learning")}
          </Checkbox>
        </Box>
      </Box>
      <Box bg={"#F5FCFF"} p="sp24">
        <Button
          variant="primary"
          disabled={loading || saving}
          onClick={handleSave}
          leftIcon={saving || loading ? <Spinner color="white" /> : <Icon icon="fa-floppy-disk" variant="solid" />}
        >
          {saving ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default ClasswizeLicencing;
