import React from "react";
import { ImportScheduleFile } from "./ImportScheduleFile";
import { ScheduleCSVObject } from "./ScheduleCSVObject";

export default class ImportScheduleFileList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      updateCsvObjectCallback: this.props.updateCsvObjectCallback,
      deleteCsvObjectCallback: this.props.deleteCsvObjectCallback,
      files: this.props.files,
      csvObjects: new Set(),
    };
  }

  componentWillReceiveProps(newProps) {
    let updatedCsvSet = this.state.csvObjects;
    for (let file of newProps.files) {
      if (Array.from(this.state.csvObjects).filter((csvObject) => csvObject.getFile() === file).length === 0) {
        updatedCsvSet.add(new ScheduleCSVObject(file));
      }
    }
    this.setState({ csvObjects: updatedCsvSet });
  }

  handle_deleteFile = (csvObject) => {
    let updatedCsvSet = this.state.csvObjects;
    updatedCsvSet.delete(csvObject);
    this.setState({ csvObjects: updatedCsvSet });
    this.state.deleteCsvObjectCallback(csvObject);
    this.state.updateCsvObjectCallback(this.state.csvObjects);
  };

  readerCallback = () => {
    this.state.updateCsvObjectCallback(this.state.csvObjects);
  };

  renderFiles() {
    let ret = [];
    for (let csvObj of this.state.csvObjects) {
      let file = csvObj.getFile();
      ret.push(
        <ImportScheduleFile
          key={file.name + file.size + file.lastModified}
          csvObject={csvObj}
          readerCallback={this.readerCallback}
          deleteFile={this.handle_deleteFile}
        />
      );
    }
    return ret;
  }

  render() {
    return <div id="list">{this.renderFiles()}</div>;
  }
}
