import React from "react";
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const AdvanceConfigurationConfirmModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth={"500px"}>
        <ModalHeader display={"flex"} alignItems={"center"} gap={"1rem"}>
          <Box h={"16px"} w={"16px"}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
            </svg>
          </Box>
          Confirm Advance Configuration Change
        </ModalHeader>
        <ModalBody display={"flex"} flexDirection={"column"} gap={"1rem"} overflow={"hidden"}>
          <Text>
            {
              "You have made changes to advanced sync settings. Please be warned, these changes are for complex deployments only, and can affect your sync."
            }
          </Text>
          <Text>{"Confirm you want to make these changes before proceeding.."}</Text>
        </ModalBody>
        <ModalFooter
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button data-testid={"modal-submit-btn"} onClick={onSubmit} backgroundColor={"#FFB240"}>
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
