export const branches = [
  ["beta-au", "Beta - AU"],
  ["beta-nz", "Beta - NZ"],
  ["beta-uk", "Beta - UK"],
  ["beta-us", "Beta - US"],
  ["ea-au", "Early Access - AU"],
  ["ea-nz", "Early Access - NZ"],
  ["ea-uk", "Early Access - UK"],
  ["ea-us", "Early Access - US"],
  ["ga-au", "General Availability - AU"],
  ["ga-nz", "General Availability - NZ"],
  ["ga-uk", "General Availability - UK"],
  ["ga-us", "General Availability One - US"],
  ["ga-us2", "General Availability Two - US"],
  ["feature-branch", "Feature Branch"],
];
