export interface CalendarRule extends RuleDefinition {
  id?: string;
  calendarId?: string;
  priority?: number;
  version?: number;
}

export interface NonSchoolRule extends CalendarRule {
  startDate: number;
  type: RuleType.HOLIDAY | RuleType.OTHER;
}

export interface RuleDefinition {
  name: string;
  type: RuleType;
  startDate?: number;
  startTime: number;
  endDate?: number;
  endTime: number;
  groups?: string[];
  excludeGroups?: string[];
  recurrence?: Recurrence;
}

export enum RuleType {
  SCHOOL_TIME = "school_time",
  HOLIDAY = "holiday",
  OTHER = "other",
}

export interface Recurrence {
  frequency: Frequency;
  interval: number;
  until?: number;
  days?: Weekday[];
  exceptions?: RecurrenceException[];
}

export enum Frequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum Weekday {
  MONDAY = "mon",
  TUESDAY = "tue",
  WEDNESDAY = "wed",
  THURSDAY = "thu",
  FRIDAY = "fri",
  SATURDAY = "sat",
  SUNDAY = "sun",
}

export interface RecurrenceException {
  recurrenceId: RecurrenceID;
  type: RecurrenceExceptionType;
}

export type RecurrenceID = number;

export enum RecurrenceExceptionType {
  SKIPPED = "skipped",
}

export const SchoolTimePlaceholder: Readonly<CalendarRule> = Object.freeze({
  priority: 1,
  name: "School Hours",
  type: RuleType.SCHOOL_TIME,
  startTime: -1,
  endTime: -1,
  recurrence: Object.freeze({
    frequency: Frequency.WEEKLY,
    interval: 1,
    days: [],
  }),
});

export const CalendarRuleEquals = (c1: CalendarRule, c2: CalendarRule): boolean => {
  let equals =
    c1.priority === c2.priority &&
    c1.name === c2.name &&
    c1.type === c2.type &&
    c1.startDate === c2.startDate &&
    c1.startTime === c2.startTime &&
    c1.endDate === c2.endDate &&
    c1.endTime === c2.endTime &&
    c1.recurrence?.frequency === c2.recurrence?.frequency &&
    c1.recurrence?.interval === c2.recurrence?.interval &&
    c1.recurrence?.until === c2.recurrence?.until;

  if (equals) {
    const days1 = c1.recurrence?.days;
    const days2 = c2.recurrence?.days;
    equals = days1?.length === days2?.length && !!days1?.every((v) => days2?.includes(v));
  }

  return equals;
};

export const CalendarRuleIsNonSchoolRule = (r: CalendarRule): r is NonSchoolRule => {
  return r.type !== RuleType.SCHOOL_TIME && !!r.startDate;
};

export const CloneCalendarRule = <T extends CalendarRule>(r: T): T => {
  const clone = { ...r };
  if (r.recurrence) r.recurrence = { ...r.recurrence };
  return clone;
};
