const isValidHostnameOrIP = (hostname: string): boolean => {
  const hostnameRegex =
    // eslint-disable-next-line
    /^[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z]{2,})$/;
  const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv6Regex = /^([A-Fa-f0-9]{0,4}:){7}[A-Fa-f0-9]{0,4}$/;

  // Check for hostname first
  if (hostnameRegex.test(hostname)) {
    return true;
  }

  // Then check for IPv4
  if (ipv4Regex.test(hostname)) {
    return true;
  }

  // Finally check for IPv6
  return ipv6Regex.test(hostname);
};

export default isValidHostnameOrIP;
