import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Button, Input, Modal, useTranslation } from "@familyzone/component-library";
import React, { FC, useEffect, useState } from "react";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria, Fingerprint, IpObject, MacObject } from "../../modules/criteria/criteriaTypes";
import { getFingerprints, getObjects } from "../../pages/filtering/ApiHelpers";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

interface NatExceptionsRule {
  comment: string;
  name?: string;
  enabled: boolean;
  id: string;
  source_criteria: FilteringSourceCriteria[];
  destination_criteria: FilteringSourceCriteria[];
}

interface INatExceptionsModalProps {
  handleHide: () => void;
  isOpen: boolean;
  saveSelection: (rule: unknown) => void;
  selectedSourceCriteria: FilteringSourceCriteria[];
  selectedDestinationCriteria: FilteringSourceCriteria[];
  updateParentSourceState: (criteria: FilteringSourceCriteria[]) => void;
  updateParentDestinationState: (criteria: FilteringSourceCriteria[]) => void;
  updateParentNameState: (name: string) => void;
  rule: NatExceptionsRule;
}

const NatExceptionsModal: FC<INatExceptionsModalProps> = ({
  handleHide,
  isOpen,
  saveSelection,
  selectedSourceCriteria,
  selectedDestinationCriteria,
  updateParentDestinationState,
  updateParentSourceState,
  updateParentNameState,
  rule,
}) => {
  const { t } = useTranslation();
  const [sourceSelection, setSourceSelection] = useState<FilteringSourceCriteria[]>(selectedSourceCriteria);
  const [destinationSelection, setDestinationSelection] = useState<FilteringSourceCriteria[]>(selectedDestinationCriteria);
  const [ipObjects, setIpObjects] = useState<IpObject[]>([]);
  const [macObjects, setMacObjects] = useState<MacObject[]>([]);
  const [fingerprints, setFingerprints] = useState<Fingerprint[]>([]);
  const [loading, setLoading] = useState(true);

  const sourceOptions: Partial<CriteriaTypes> = {
    "source.user": "User",
    protocol: "Protocol",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "source.mac.pool": "Mac Address Object",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
  };

  const destinationOptions: Partial<CriteriaTypes> = {
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "source.mac": "Mac Address",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
  };

  const handleChangeSource = (criteria: FilteringSourceCriteria[]) => {
    updateParentSourceState(criteria);
    setSourceSelection(criteria);
  };

  const handleChangeDestination = (criteria: FilteringSourceCriteria[]) => {
    updateParentDestinationState(criteria);
    setDestinationSelection(criteria);
  };

  const handleSave = () => {
    saveSelection(rule);
  };

  useEffect(() => {
    const populateObjects = async () => {
      const result = await getObjects();
      setFingerprints(await getFingerprints());
      const ipObjects = result.filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0);
      const macObjects = result.filter((obj): obj is MacObject => obj.type === 4);
      setIpObjects(ipObjects);
      setMacObjects(macObjects);
      setLoading(false);
    };
    void populateObjects();
  }, []);

  return (
    <Modal isOpen={isOpen} size="md" onClose={handleHide} headerText={t("Edit Rule")} contentProps={{ style: { overflow: "scroll" } }}>
      <>
        {loading && <CardSpinner />}
        {!loading && (
          <Stack spacing="sp12" mt="sp8">
            <FormControl>
              <FormLabel>{t("Name")}</FormLabel>
              <Input value={rule.name} onChange={(e) => updateParentNameState(e.target.value)} placeholder="Rule Name" />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Source")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                macObjects={macObjects}
                fingerprints={fingerprints}
                customOptions={sourceOptions}
                selected={sourceSelection}
                onChange={handleChangeSource}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Destination")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                customOptions={destinationOptions}
                selected={destinationSelection}
                onChange={handleChangeDestination}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>
            </FormControl>
          </Stack>
        )}
      </>
    </Modal>
  );
};

export default NatExceptionsModal;
