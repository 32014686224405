import * as React from "react";

function SvgIconSearch(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.718 10.122L8.953 7.357A5.321 5.321 0 017.36 8.952l2.764 2.764a1.13 1.13 0 001.595-1.594zM9.037 4.519a4.519 4.519 0 10-9.037 0 4.519 4.519 0 009.037 0zM4.52 7.908a3.389 3.389 0 110-6.778 3.389 3.389 0 010 6.778z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgIconSearch;
