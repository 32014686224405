import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import SearchableSelect from "../../modules/SearchableSelect";

import DeleteButton from "../../modules/DeleteButton";

class AddRoute extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      destination: "",
      destination_cidr: 32,
      route_nexthop: "",
      route_device: "",
    };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_Submit = () => {
    let new_route = {
      destination: this.state.destination,
      destination_cidr: this.state.destination_cidr,
      route_nexthop: this.state.route_nexthop,
      route_device: this.state.route_device,
    };

    this.props.handleAdd(new_route);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  handle_ChangeDestination = (event) => {
    this.setState({
      destination: event.target.value,
    });
  };

  handle_ChangeDestinationCidr = (event) => {
    this.setState({
      destination_cidr: parseInt(event.target.value),
    });
  };

  handle_ChangeNextHop = (event) => {
    this.setState({
      route_nexthop: event.target.value,
    });
  };

  handle_ChangeInterface = (event) => {
    this.setState({
      route_device: event.value,
    });
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let x of this.props.devices) {
      ret.push([x.interface, x.interface]);
    }

    return ret;
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Route" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">Destination IP</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.destination} onChange={this.handle_ChangeDestination} />
            </div>
            <div className="formgroup-element-title">Destination CIDR</div>
            <div className="mui-textfield">
              <input type="number" value={this.state.destination_cidr} onChange={this.handle_ChangeDestinationCidr} />
            </div>
            <div className="formgroup-element">Destination Interface</div>
            <div className="mui-select">
              <SearchableSelect
                value={this.state.route_device}
                onChange={this.handle_ChangeInterface}
                valueDataMap={this.get_SelectionMap()}
              ></SearchableSelect>
            </div>
            <div className="formgroup-element-title">Next Hop</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.route_nexthop} onChange={this.handle_ChangeNextHop} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class Routes extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      routes: [],
      visible__add: false,
      devices: [],
      loaded: false,
    };

    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/config/device/ajax/network/routes", function (result) {
      let routes = result["data"];
      self.setState({
        routes: routes,
        loaded: true,
      });
    });

    Api.get("/config/device/ajax/network/devices", function (result) {
      let devices = result["data"];
      self.setState({
        devices: devices,
      });
    });
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Route" onClickHandler={this.handler__clickAdd} />;
  };

  handle_Delete = (id) => {
    let self = this;
    Api.delete("/config/device/ajax/network/routes", { route_id: id }, function (result) {
      self.handle_load();
    });
  };

  handle__Add = (route) => {
    let self = this;
    Api.put("/config/device/ajax/network/routes", route, function (result) {
      self.handle_load();
    });
  };

  typeText = (row) => {
    if (row["id"] === "" || row["id"] === undefined) {
      return "Dynamic";
    }
    return "Static";
  };

  sourceText = (row) => row["source"] + "/" + row["source_cidr"];
  destinationText = (row) => row["destination"] + "/" + row["destination_cidr"];

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Source",
        data: this.sourceText,
        search: FlexibleTable.search__with_function(this.sourceText),
        onclick: undefined,
      },
      {
        title: "Destination",
        data: this.destinationText,
        search: FlexibleTable.search__with_function(this.destinationText),
        onclick: undefined,
      },
      {
        title: "Next Hop",
        data: function (row) {
          return row["route_nexthop"];
        },
        search: FlexibleTable.search__single_string_field("route_nexthop"),
        onclick: undefined,
      },
      {
        title: "Interface",
        data: function (row) {
          return row["route_device"];
        },
        search: FlexibleTable.search__single_string_field("route_device"),
        onclick: undefined,
      },
      {
        title: "Type",
        data: this.typeText,
        search: FlexibleTable.search__with_function(this.typeText),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          if (row["id"] !== "") {
            return (
              <div>
                <DeleteButton onClick={() => self.handle_Delete(row["id"])} />
              </div>
            );
          }
          return <div />;
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.routes} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddRoute
          devices={this.state.devices}
          visible={this.state.visible__add}
          handleAdd={this.handle__Add}
          handleClose={this.handler__clickCloseAdd}
        />

        <TableCentricPage icon="fa fa-exchange" title="Routing" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
