import React, { useLayoutEffect, useRef, useState, useCallback, useMemo } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Link } from "react-router";
import PersonaDashboardStore from "../../../stores/PersonaDashboardStore";
import PersonaDashboardActions from "../../../actions/PersonaDashboardActions";
import { ToggleGroup } from "../../../modules/ToggleGroup";
import { indexOptions } from "../../../components/reporting/ReportingBlocked";
import { useQuery } from "react-query";
import Api from "../../../utils/Api";
import { findTitles } from "../../../components/reporting/ReportingBlocked";
import topBlockedCircle from "../../../../images/icons/topBlockedCircle.svg";
import NoData from "../../../modules/NoData";
import ComponentLoading from "../../../modules/ComponentLoading";
import clsx from "clsx";
import InfoIconV2 from "../../../modules/InfoIconV2";
import { useUserStore } from "../../../storez/UserStore";

const topBlockedPoliciesKeys = {
  id: "top-blocked-policies",
  dates: (apiDateOverride) => [topBlockedPoliciesKeys.id, Object.values(apiDateOverride).join("-")],
};
const topBlockedUsersKeys = {
  id: "top-blocked-users",
  dates: (apiDateOverride) => [topBlockedUsersKeys.id, Object.values(apiDateOverride).join("-")],
};
const topBlockedAppsKeys = {
  id: "top-blocked-apps",
  dates: (apiDateOverride) => [topBlockedAppsKeys.id, Object.values(apiDateOverride).join("-")],
};

export const TopBlocked = (props) => {
  const [selectedToggle, setSelectedToggle] = useState(PersonaDashboardStore.getToggle("topBlocked") || "Users");
  const chartRef = useRef(null);
  const [showNoData, setShowNoData] = useState(false);

  const topBlockedPoliciesQuery = useQuery(
    topBlockedPoliciesKeys.dates(props.apiDateOverride),
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/filtering/policies", { dateOverride: props.apiDateOverride }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Policies",
      select: useCallback((data) => {
        if (!data) return;
        return data.sort((a, b) => b.hits - a.hits).slice(0, 8);
      }, []),
    }
  );
  const topBlockedUsersQuery = useQuery(
    topBlockedUsersKeys.dates(props.apiDateOverride),
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/filtering/users?blocked=true", { dateOverride: props.apiDateOverride }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Users",
      select: useCallback((data) => {
        if (!data) return;
        return data.sort((a, b) => b.hits - a.hits).slice(0, 8);
      }, []),
    }
  );
  const topBlockedAppsQuery = useQuery(
    topBlockedAppsKeys.dates(props.apiDateOverride),
    () =>
      Api.get_analyticsAsync("/surfwize/analytics/traffic/typesandwebsites?blocked=true", { dateOverride: props.apiDateOverride }).then(
        (result) => result.data
      ),
    {
      enabled: selectedToggle === "Apps",
      select: useCallback((data) => {
        if (!data) return;
        const result = data.sort((a, b) => b.hits - a.hits).slice(0, 8);
        return (data = findTitles(result));
      }, []),
    }
  );

  const initChart = () => {
    const chart = am4core.create("topBlockedPieChartContainer", am4charts.XYChart);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.autoGridCount = false;
    categoryAxis.renderer.gridCount = 8;
    categoryAxis.renderer.minGridDistance = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.align = "left";
    categoryAxis.paddingLeft = -15;
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (user) => user);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.paddingRight = 20;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.labels.template.adapter.add("text", function (text, axisLabel) {
      if (text === "0") {
        return "0 hits";
      } else {
        return text;
      }
    });

    var label = categoryAxis.renderer.labels.template;
    label.minWidth = 210;
    label.maxWidth = 210;
    label.truncate = true;
    label.padding(3, 3, 3, 5);
    label.marginRight = 5;
    label.background = new am4core.RoundedRectangle();
    label.background.cornerRadius(5, 5, 5, 5);
    label.fill = am4core.color("#094C99");

    label.events.on("over", function (event) {
      event.target.fill = am4core.color("#23527C");
      event.target.textDecoration = "underline";
    });
    label.events.on("out", function (event) {
      event.target.fill = am4core.color("#094C99");
      event.target.textDecoration = "none";
    });

    if (selectedToggle === "Policies") {
      label.url = "/cybersafety/blocks?startingIndex=policies";
    } else if (selectedToggle === "Users") {
      label.url = "/surfwize/reporting/users/{username}";
    } else if (selectedToggle === "Apps") {
      label.events.on("over", function (event) {
        const labelText = event.target.currentText.toLowerCase();
        // determines if the link is a Website or a Type
        if (labelText && labelText.includes(".")) {
          label.url = `/surfwize/reporting/website/${labelText}/users?&blocked=true&hits=true`;
        } else {
          label.url = `/surfwize/reporting/type/sphirewall.application.${labelText}/users?&blocked=true&hits=true`;
        }
      });
    }

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "category";

    series.columns.template.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "left";
    series.tooltip.label.width = 130;
    series.tooltip.label.height = 48;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#3E3E3C");
    series.tooltip.background.stroke = am4core.color("#3E3E3C");
    series.tooltip.strokeWidth = 0;

    series.tooltipHTML = `<div style='text-align: center; margin-top: 5px; margin-right: 5px; margin-left: 5px;'>
                <div style='display: inline-block; vertical-align: middle; line-height: normal; font-size: 14px;'>
                    <img src=${topBlockedCircle} style='margin-right: 5px; width:26px; height:26px; padding-bottom: 5px;'>
                    {valueX} hits
                </div>
            </div>`;

    chart.colors.list = [
      am4core.color("#054196"),
      am4core.color("#0055AE"),
      am4core.color("#0075DB"),
      am4core.color("#038DBB"),
      am4core.color("#047A89"),
      am4core.color("#06A59A"),
      am4core.color("#06A59A"),
      am4core.color("#06A59A"),
    ];
    chart.cursor.lineY.strokeOpacity = 0;
    chart.cursor.lineX.strokeOpacity = 0;

    series.columns.template.events.once("inited", function (event) {
      event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
    });

    chartRef.current = chart;
  };

  const showLoading = useCallback(
    () => topBlockedAppsQuery.isLoading || topBlockedPoliciesQuery.isLoading || topBlockedUsersQuery.isLoading,
    [topBlockedAppsQuery.isLoading, topBlockedPoliciesQuery.isLoading, topBlockedUsersQuery.isLoading]
  );

  // chart init
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => initChart(), [selectedToggle, showLoading]);

  // Batch get the user's names from the SM API by username and set these into state.
  // This will re-run if the topBlockedUsersQuery.data changes.
  const [userStoreUsers, setUserStoreUsers] = useState(new Map());
  const getUsers = useUserStore((state) => state.getUsersAsMap);

  useMemo(() => {
    void (async () => {
      const results = topBlockedUsersQuery.data?.map((e) => e.user);
      if (results && results.length > 0) {
        const users = await getUsers(results);
        setUserStoreUsers(users);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBlockedUsersQuery.data]);

  // chart updates
  useLayoutEffect(() => {
    const chart = chartRef.current;
    if (selectedToggle === "Users") {
      chart.data = topBlockedUsersQuery.data?.map((e) => {
        // Find the user based on the username of the data from DQS and the response from the UMS fullname lookup.
        const user = userStoreUsers.get(e.user);
        // Check for the user's first and last name, if they don't exist, use the username as a fallback
        const displayName = user?.firstName.length || user?.lastName.length ? `${user.firstName} ${user.lastName}` : e.user;
        return { category: displayName, value: e.hits, username: e.user };
      });
    } else if (selectedToggle === "Apps") {
      chart.data = topBlockedAppsQuery.data?.map((e) => {
        return { category: e.type_name, value: e.hits };
      });
    } else {
      chart.data = topBlockedPoliciesQuery.data?.map((e) => {
        return { category: e.policy_name, value: e.hits };
      });
    }
    chartRef.current = chart;
    setShowNoData(!showLoading() && chartRef.current && chartRef.current.data && chartRef.current.data.length === 0);
  }, [selectedToggle, topBlockedAppsQuery.data, topBlockedPoliciesQuery.data, topBlockedUsersQuery.data, showLoading, userStoreUsers]);

  const updateToggle = (toggle) => {
    setSelectedToggle(toggle);
    PersonaDashboardActions.setToggle("topBlocked", toggle);
  };

  const togglesRef = useRef();
  if (!togglesRef.current) {
    togglesRef.current = [
      {
        label: "Users",
        onClick: () => {
          updateToggle("Users");
        },
      },
      {
        label: "Apps",
        onClick: () => {
          updateToggle("Apps");
        },
      },
      {
        label: "Policies",
        onClick: () => {
          updateToggle("Policies");
        },
      },
    ];
    const initiallySelectedToggle = togglesRef.current.find((element) => element.label === selectedToggle);
    initiallySelectedToggle.initiallySelected = true;
  }

  function getLink() {
    let param;
    switch (selectedToggle) {
      case "Policies":
        param = indexOptions.policies;
        break;
      case "Users":
        param = indexOptions.users;
        break;
      case "Apps":
        param = indexOptions.apps;
        break;
      default:
        param = indexOptions.users;
        break; // shouldn't happen, but default to Users
    }

    return `/cybersafety/blocks?startingIndex=${param}`;
  }

  return (
    <div className="personaModule">
      <div className="personaModule-header">
        <h2>Top Blocked</h2>
        <InfoIconV2 backgroundColor="grey" text="Frequently blocked users, apps, policies. Rollover for count" />
        <ToggleGroup className="personaTopBlockedToggles" toggles={togglesRef.current} />
        <Link to={getLink()} className="personaModule-rightLink">
          View All
        </Link>
      </div>
      {showLoading() && (
        <div className="centered">
          <ComponentLoading />
        </div>
      )}
      {showNoData && <NoData />}
      <div
        id="topBlockedPieChartContainer"
        className={clsx("personaTopBlockedChart", (showLoading() || showNoData) && "hidePersonaChart")}
      />
    </div>
  );
};
