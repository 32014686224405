import React, { useCallback, useEffect, useState } from "react";
import AmountField from "../../modules/AmountField";
import {
  Box,
  ButtonTd,
  DateTimePicker,
  Flex,
  Icon,
  InlineNotification,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Td,
  Text,
  Tr,
  useTranslation,
} from "@familyzone/component-library";
import TableBasedPage from "../templates/TableBasedPage";
import { TableColumn } from "../../types/table";
import {
  Data,
  exportVerdict,
  extractVerdictStatus,
  isData,
  isQueryResult,
  FormattedData,
  VerdictStatus,
} from "./ReportingUserTimelineHelpers";
import { CSVExportButton } from "../../modules/ExportReport";
import ConfigStore from "../../stores/ConfigStore";
import dayjs from "dayjs";
import SignatureActions from "../../actions/SignatureActions";
import ConfigActions from "../../actions/ConfigActions";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import qs from "querystring";
import Api from "../../utils/Api";
import ModalWindow from "../../modules/ModalWindow";
import { zIndices } from "../../utils/ZIndexUtil";
import StudentNamePill from "../../modules/StudentNamePill";
import { ConnectionInfoModalNew } from "../debug/ConnectionInfoModalNew";
import { getDateTime, shouldUseUSDateFormat } from "../../utils/DateTimeUtil";
import SignatureStore from "../../stores/SignatureStore";
import { Signature } from "../../types/Classrooms";

/**
 * Date ranges for the ReportingUserTimelineNew has two behaviours:
 *    1. When `date` parameter is passed, the timeline is loaded from start of the day to the end of the day
 *    2. When `end_date` and `end_time` are passed, the timeline would load the 48H of data from the end time.
 *         This behaviour is only present if you open navigate to the user timeline from user dashboard
 */
interface Props {
  user?: string;
  date?: string;
  filter_type?: string;
  end_date?: string;
  end_time?: string;
}

export const ReportingUserTimelineNew: React.FC<Props> = ({ user, date, filter_type, end_date, end_time }) => {
  const [data, setData] = useState<FormattedData[]>([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailConnection, setDetailConnection] = useState<unknown>();
  const [endDate, setEndDate] = useState(
    date ? dayjs(date).endOf("day") : end_date && end_time ? getDateTime(end_date, end_time) : dayjs()
  );
  const [startDate, setStartDate] = useState(date ? dayjs(date).startOf("day") : endDate.subtract(48, "hour"));
  const [verdict, setVerdict] = useState<"Allowed" | "Blocked" | "All">("All");
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [useUSDateFormat, setUseUSDateFormat] = useState(false);

  /* Disabling this rule(s) because Alt store getters/setter are type unsafe, until these stores fully migrated to another state management library */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  const [displayMachineNameColumn, setDisplayMachineNameColumn] = useState(ConfigStore.getItemInConfig("display_machine_name"));

  useEffect(() => {
    ConfigStore.listen(handleConfigChange);

    setTimeout(() => {
      GlobalDatePickerVisibilityActions.hideGlobalDatePicker();
      SignatureActions.fetch();
      ConfigActions.fetch();
    }, 0);

    const checkDateFormat = async () => {
      const should = await shouldUseUSDateFormat();
      setUseUSDateFormat(should);
    };
    void checkDateFormat();

    return () => {
      ConfigStore.unlisten(handleConfigChange);
    };
  }, []);

  const handleConfigChange = () => {
    setDisplayMachineNameColumn(ConfigStore.getItemInConfig("display_machine_name"));
  };
  /* eslint-enable */

  const handleOpenDetails = (connection: Data) => {
    setDetailConnection(connection);
    setDetailVisible(true);
  };

  const handleCloseDetails = () => {
    setDetailConnection(undefined);
    setDetailVisible(false);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleLoad = () => {
    if (!user) return;

    setData([]);
    setLoaded(false);

    let params = qs.stringify({
      overrideDates: "t",
      startDate: startDate.format("YYYY-MM-DD"),
      startTime: startDate.format("HH:mm:ss"),
      endDate: endDate.format("YYYY-MM-DD"),
      endTime: endDate.format("HH:mm:59"),
      filter_user: user,
    });

    if (filter_type) {
      params += `&filter_type=${filter_type}`;
    }

    switch (verdict) {
      case "Blocked":
        params += "&blocked=true";
        break;
      case "Allowed":
        params += "&blocked=false";
        break;
    }

    const url = `/surfwize/analytics/user/${user}/timeline?${params}`;
    Api.get_analytics(
      url,
      (result: unknown) => {
        if (isQueryResult(result) && (result.data.length === 0 || result.data.some(isData))) {
          const formatted = result.data.map(formatData);
          setData(formatted);
          setLoaded(true);
        }
      },
      (err: { message: string }) => {
        setError(err.message || "Error when retrieving user's timeline.");
      }
    );
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, startDate, endDate, verdict]);

  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Users"), url: "/surfwize/reporting/users", isActive: false },
    { title: t("User Timeline"), isActive: true },
  ];

  const initialColumns: TableColumn[] = [
    {
      headerText: t("Date/Time"),
      columnName: "time",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Verdict"),
      columnName: "verdict",
      sortable: true,
      searchable: true,
      exportable: true,
      exportData: exportVerdict,
    },
    {
      headerText: t("Website"),
      columnName: "website",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Type"),
      columnName: "tag",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Upload"),
      columnName: "upload",
      sortable: true,
      searchable: true,
      exportable: true,
    },
    {
      headerText: t("Download"),
      columnName: "download",
      sortable: true,
      searchable: true,
      exportable: true,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const updateColumns = () => {
    if (displayMachineNameColumn) {
      const machineNameColumn = {
        headerText: t("Machine Name"),
        columnName: "hostname",
        sortable: true,
        searchable: true,
        exportable: true,
      };

      const columns = [...initialColumns];

      columns.splice(1, 0, machineNameColumn);

      setColumns(columns);
    }
  };

  useEffect(() => {
    updateColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayMachineNameColumn]);

  const formatData = (unformatted: Data): FormattedData => ({
    ...unformatted,
    time: unformatted["string_time"] ?? unformatted["time"],
    verdict: extractVerdictStatus(unformatted),
    website: unformatted["httpHost"] !== "" ? unformatted["httpHost"] : unformatted["destIp"],
  });

  const dataMap = (data: FormattedData, index: number) => {
    return (
      <Tr key={index}>
        <Td>
          <Text fontSize="md">{data.time}</Text>
        </Td>
        <>
          {displayMachineNameColumn && (
            <Td>
              <Text fontSize="md">{data.hostname}</Text>
            </Td>
          )}
        </>
        <Td>{verdictTag(data)}</Td>
        <Td>
          <Text fontSize="md">{data.website}</Text>
        </Td>
        <Td>
          <Text fontSize="md">{data.tag}</Text>
        </Td>
        <Td>
          <Text fontSize="md">
            <AmountField amount={data.upload} />
          </Text>
        </Td>
        <Td>
          <Text fontSize="md">
            <AmountField amount={data.download} />
          </Text>
        </Td>
        <ButtonTd buttonIconName="fa-arrow-right" onClick={() => handleOpenDetails(data)} />
      </Tr>
    );
  };

  const verdictTag = (row: Data) => {
    const verdict = extractVerdictStatus(row);
    const variant = verdict === VerdictStatus.BlockedRed ? "red" : "subtle";
    const label = verdict === VerdictStatus.Allowed ? "Allowed" : "Blocked";
    return <Tag tagLabel={label} variant={variant} />;
  };

  const removeTypeFilter = () => {
    filter_type = "";
    window.location.href = `/surfwize/reporting/users/${user ?? ""}/timeline?date=${date ?? ""}&filter_type=`;
    handleLoad();
  };

  const typeFilterLabel = useCallback(() => {
    if (!filter_type || filter_type === "") return "";
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const stored_signature: Signature = SignatureStore.getSignature(filter_type);
    if (stored_signature) {
      return stored_signature.name;
    } else return "";
  }, [filter_type]);

  const typeFilterTag = () => {
    if (!filter_type || filter_type === "") return <></>;
    return (
      <Box mt="5px" ml="10px">
        <Tag isRemovable onRemoveClick={() => removeTypeFilter()} tagLabel={typeFilterLabel()} variant="red" />
      </Box>
    );
  };

  return (
    <TableBasedPage
      title={t("User Timeline")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={data}
      tableDataMap={dataMap}
      loaded={loaded}
      childrenInTableHeader={<CSVExportButton columns={columns} formattedData={data} />}
      defaultSort={{ column: "time", direction: "desc" }}
    >
      <>
        {user && (
          <Box marginTop="24" marginLeft="24">
            <StudentNamePill username={user} />
          </Box>
        )}
        <Box marginTop="24" marginLeft="24" maxWidth="400px">
          <InlineNotification
            notificationDescription="The User Timeline enables you to view the detailed history of a user in up to 48 hour blocks."
            status="info"
          />
        </Box>
        <Flex zIndex={zIndices.multiSelect} mt="sp24" ml="sp24">
          <DateTimePicker
            initialStart={dayjs(startDate)}
            initialEnd={dayjs(endDate)}
            useUSDateFormat={useUSDateFormat}
            onDateChange={(start, end) => {
              setStartDate(dayjs(start));
              setEndDate(dayjs(end));
            }}
            direction="right"
            customRanges={["last1hour", "last2Hours", "last6Hours", "last12Hours", "last24Hours"]}
            display="time"
            maxSpan={{ value: 2, unit: "days" }}
          />
          <Menu>
            <MenuButton ml="sp8">
              <Flex fontWeight="regular">
                Filter by: {verdict}
                <Icon icon="fa-chevron-down" ml="sp8" />
              </Flex>
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem data-testid="blockedBtn" onClick={() => setVerdict("Blocked")} fontSize="md">
                Blocked
              </MenuItem>
              <MenuItem data-testid="allowedBtn" onClick={() => setVerdict("Allowed")} fontSize="md">
                Allowed
              </MenuItem>
              <MenuItem data-testid="allBtn" onClick={() => setVerdict("All")} fontSize="md">
                All
              </MenuItem>
            </MenuList>
          </Menu>
          {typeFilterTag()}
        </Flex>
        <ConnectionInfoModalNew connection={detailConnection} visible={detailVisible} handleClose={handleCloseDetails} />
        {error && (
          <ModalWindow
            title="Error"
            handleHide={handleCloseErrorDialog}
            actions={
              <button type="submit" className="mui-btn mui-btn--raised" onClick={handleCloseErrorDialog}>
                OK
              </button>
            }
          >
            {error}
          </ModalWindow>
        )}
      </>
    </TableBasedPage>
  );
};
