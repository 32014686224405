import React from "react";
import SessionStore from "../stores/SessionStore";

export default class ProtectedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devicePermissions: SessionStore.getDevicePermissions(),
      supportAdmin: SessionStore.isSupportAdmin(),
      available_devices_loaded: SessionStore.hasDevicePermissionsLoaded(),
    };
  }

  componentWillMount() {
    SessionStore.listen(this.onChange);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devicePermissions: SessionStore.getDevicePermissions(),
      supportAdmin: SessionStore.isSupportAdmin(),
      available_devices_loaded: SessionStore.hasDevicePermissionsLoaded(),
    });
  };

  render() {
    if (this.state.supportAdmin) {
      return <div>{this.props.children}</div>;
    }

    if (!this.state.available_devices_loaded) {
      return <div></div>;
    }

    for (let permission of this.state.devicePermissions) {
      if (this.props.allowedRoles.indexOf(permission) !== -1) {
        return <div>{this.props.children}</div>;
      }
    }

    return <div></div>;
  }
}
