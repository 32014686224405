import { ClassroomPolicy } from "../../../types/Classrooms";
import { Schedule, ClassroomSchedule } from "../../../types/Classrooms";

function formatTime(time: string): string {
  if (time.length === 3) {
    time = `0${time}`;
  }
  time = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
  return time;
}

export default function formatSchedule(schedule: ClassroomPolicy["schedule"]): ClassroomSchedule {
  const daySchedule: Schedule = {
    mon: [],
    tue: [],
    wed: [],
    thur: [],
    fri: [],
    sat: [],
    sun: [],
  };
  const nightSchedule: Schedule = {
    mon: [],
    tue: [],
    wed: [],
    thur: [],
    fri: [],
    sat: [],
    sun: [],
  };
  const classroomSchedule: ClassroomSchedule = [daySchedule, nightSchedule];
  if (schedule?.periods) {
    schedule.periods.sort(function (a, b) {
      const sortResult: number = a.startTime - b.startTime;
      if (sortResult === 0) {
        return a.endTime - b.endTime;
      }
      return sortResult;
    });
    for (const period of schedule?.periods) {
      const dayRef = period.day as keyof Schedule;
      if (period.startTime >= 1200) {
        const nightPeriod: string = formatTime(period.startTime.toString()) + "-" + formatTime(period.endTime.toString());
        nightSchedule[dayRef].push(nightPeriod);
      } else {
        const dayPeriod: string = formatTime(period.startTime.toString()) + "-" + formatTime(period.endTime.toString());
        daySchedule[dayRef].push(dayPeriod);
      }
    }
    return classroomSchedule;
  }
  return classroomSchedule;
}
