import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { CommunityFlags } from "../types/Community";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";

export const communityFlagsURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/community-flags`;
};

export interface SaveOptions {
  overrideVersionConflict: boolean;
}

export interface CommunityFeatureFlagStore {
  flags?: CommunityFlags;
  getOrFetch: () => Promise<CommunityFlags>;
  fetch: () => Promise<CommunityFlags>;
  save: (flags: CommunityFlags, options?: SaveOptions) => Promise<CommunityFlags>;
  reset: () => void;
}

const synchronized: { fetchResult?: Promise<CommunityFlags> } = {};

export const useCommunityFeatureFlagStore = create<CommunityFeatureFlagStore>((set, get) => ({
  flags: undefined,

  /**
   * Gets Community feature flags from the store, or if the store is empty, then fetches
   * the flags from Community Config API first.
   *
   * NOTE: The Community flags are used in `AppRoot` to decide access to Community routes,
   * conditionally render menu items and content on some pages. To avoid fetching the flags
   * two-three times when UI loads, this method limits the number of in-flight requests to
   * just 1 when invoked multiple times in quick succession and returns the same promise to
   * all callers.
   *
   * @returns promise that completes with Community feature flags.
   */
  getOrFetch: async (): Promise<CommunityFlags> => {
    let flags = get().flags;
    if (flags && Object.keys(flags).length > 0) {
      return flags;
    }

    if (synchronized.fetchResult) {
      return await synchronized.fetchResult;
    }

    synchronized.fetchResult = get().fetch();
    flags = await synchronized.fetchResult;
    synchronized.fetchResult = undefined;

    return flags;
  },

  fetch: async (): Promise<CommunityFlags> => {
    let flags,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.getAsync(communityFlagsURL()).then(
      (response: CommunityFlags) => (flags = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!flags || statusCode !== 200) {
      throw new ResponseError("Failed to fetch community feature flags", statusCode);
    }

    set({ flags });

    return flags;
  },

  save: async (flags: CommunityFlags, _options?: SaveOptions): Promise<CommunityFlags> => {
    let saved: CommunityFlags | undefined,
      statusCode = 200;

    // eslint-disable-next-line
    await Api.putAsync(communityFlagsURL(), flags).then(
      (response: CommunityFlags) => (saved = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save Community feature flags", statusCode);
    }

    set({ flags: saved });

    return saved;
  },

  reset: () => {
    synchronized.fetchResult = undefined;
    set({ flags: undefined });
  },
}));
