import React from "react";

import ObjectStore from "../stores/ObjectStore";
import ObjectActions from "../actions/ObjectActions";

export default class ObjectPrinter extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      availableObjects: ObjectStore.getObjects(),
    };
  }

  componentDidMount() {
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    ObjectStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableObjects: ObjectStore.getObjects(),
    });
  };

  static getObjectsText(values) {
    let ret = [];
    for (let val of values) {
      const resolved_object = ObjectStore.getObject(val);
      if (resolved_object) {
        ret.push(`${resolved_object["name"]}, `);
      } else {
        ret.push(val);
      }
    }
    return ret;
  }

  render_Objects = () => {
    if (this.props.values.length === 0) {
      return <span></span>;
    }

    const objectsText = ObjectPrinter.getObjectsText(this.props.values);

    let ret = [];
    for (let val of objectsText) {
      ret.push(<span key={"object" + ret.length}>{val}</span>);
    }
    return ret;
  };

  render() {
    return <span>{this.render_Objects()}</span>;
  }
}
