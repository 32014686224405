import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "@familyzone/component-library";
import { useMount } from "react-use";
import { fetchAggregates } from "../../../utils/api/Aggregates";
import { BasePill } from "./BasePill";
import PropTypes from "prop-types";
import dayjs from "dayjs";

export const AggregatePills: FC = (_, context) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState(0);
  const [classrooms, setClassrooms] = useState(0);
  const [users, setUsers] = useState(0);
  const [timestamp, setTimestamp] = useState("");

  useMount(() => {
    void (async () => {
      const { aggregate } = await fetchAggregates();
      setGroups(aggregate.groups);
      setClassrooms(aggregate.classrooms);
      setUsers(aggregate.users);
      setTimestamp(aggregate.modifiedDate);
      setLoading(false);
    })();
  });

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  const handleOpenUsers = () => {
    context.router.push("/config/device/userdb/users");
  };

  const handleOpenGroups = () => {
    context.router.push("/config/device/userdb/groups");
  };

  const handleOpenClassrooms = () => {
    context.router.push("/config/device/classwize/classrooms");
  };
  /* eslint-enable */

  const subtitle = useMemo(() => (timestamp ? `${t("Updated")} ${dayjs(timestamp).fromNow()}` : ""), [t, timestamp]);

  return (
    <>
      <BasePill isLoading={loading} title={`${users}`} subtitle={subtitle} body={t("Users")} onClick={handleOpenUsers} />
      <BasePill isLoading={loading} title={`${groups}`} subtitle={subtitle} body={t("Groups")} onClick={handleOpenGroups} />
      <BasePill isLoading={loading} title={`${classrooms}`} subtitle={subtitle} body={t("Classrooms")} onClick={handleOpenClassrooms} />
    </>
  );
};

AggregatePills.contextTypes = {
  router: PropTypes.object.isRequired,
};
