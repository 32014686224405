import React from "react";
import { Flex, useTranslation, Thead, Tr, Th, DraggableTable, DraggableTbody, arrayMove } from "@familyzone/component-library";

import { SafeSearchRule } from "./types";

import NoContentDisplay from "./NoContentDisplay";
import SafeSearchTableRow from "./SafeSearchTableRow";

export const columns: string[] = ["", "Groups and Devices", "Google", "Youtube", "Bing", "Operations"];
export const noContentTitle = "Create your first rule";
export const noContentText = "Create your first safe search rule by clicking on the “Add Rule“ button";
export const lockedWarningText = "Locked filter policies have greater priority and must remain at the top";

export const googleUsageRights = {
  nfbl: "Not filtered by license",
  rwm: "Reuse with modification",
  r: "Reuse",
  ncrwm: "Noncommercial reuse with modification",
  ncr: "Noncommercial reuse",
};

export const safeSearchRuleMatchesSearch = (rule: SafeSearchRule, search: string): boolean => {
  if (!search) return true;
  const lcaseSearch = search.toLowerCase();
  const usageRightsText = googleUsageRights[rule.googleUsageRights].toLowerCase();
  if (usageRightsText.includes(lcaseSearch)) {
    return true;
  }
  if (rule.enabled && "google enabled strict enforced safesearch".includes(search)) {
    return true;
  }
  if (rule.bing_enabled && "bing enabled strict enforced safesearch".includes(search)) {
    return true;
  }
  if (!rule.bing_enabled && "bing disabled safesearch".includes(search)) {
    return true;
  }
  if (!rule.enabled && "google disabled safesearch".includes(search)) {
    return true;
  }
  if (rule.youtube_enabled && "youtube enabled strict enforced safesearch".includes(search)) {
    return true;
  }
  if (rule.youtube_moderate_enabled && "youtube moderate safesearch".includes(search)) {
    return true;
  }

  return false;
};

export interface SafeSearchTableProps {
  loading?: boolean;
  disabled?: boolean;
  search?: string;
  rules: SafeSearchRule[];
  onSortRules: (rules: SafeSearchRule[]) => void;
  onEditRule: (rule: SafeSearchRule) => void;
  onDeleteRule: (rule: SafeSearchRule) => void;
}

const SafeSearchTable: React.FC<SafeSearchTableProps> = ({
  loading = false,
  disabled = false,
  search = "",
  rules,
  onSortRules,
  onEditRule,
  onDeleteRule,
}) => {
  const { t } = useTranslation();

  if (rules.length === 0 && !loading) {
    return <NoContentDisplay title={t(noContentTitle)} text={t(noContentText)} />;
  }

  const handleMoveRule = (active: string | number, over: string | number) => {
    if (loading) return;
    if (active === over) return;
    const activeIndex = rules.findIndex((r) => r.id === active);
    const overIndex = rules.findIndex((r) => r.id === over);
    if (activeIndex === -1 || overIndex === -1) return;

    const newRules = arrayMove(rules, activeIndex, overIndex);

    onSortRules(newRules);
  };

  return (
    <Flex mt="sp24">
      <DraggableTable onMove={handleMoveRule}>
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th
                key={`${index}-${column}`}
                headerText={t(column)}
                columnName={column}
                alignment={index === columns.length - 1 ? "right" : "left"}
              />
            ))}
          </Tr>
        </Thead>
        <DraggableTbody items={rules.map((r) => r.id)} loaded={!loading}>
          {rules
            .filter((r) => safeSearchRuleMatchesSearch(r, search))
            .map((rule) => (
              <SafeSearchTableRow
                key={rule.id}
                rule={rule}
                id={rule.id}
                onEdit={() => onEditRule(rule)}
                onDelete={() => onDeleteRule(rule)}
                disabled={disabled}
              />
            ))}
        </DraggableTbody>
      </DraggableTable>
    </Flex>
  );
};

export default SafeSearchTable;
