import React from "react";
import TableBasedPage from "../../components/templates/TableBasedPage";
import { Text, Td, Tr, useTranslation, Box, Flex, Icon, TableIconButton } from "@familyzone/component-library";

interface Association {
  username: string;
  macAddress: string;
}

interface AssociationTableRenderProps {
  data: Association[];
  loaded: boolean;
}

const AssociationTableRenderer = ({ data, loaded }: AssociationTableRenderProps): JSX.Element => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config/device/dashboard", isActive: false },
    { title: t("Users and Groups"), url: "/config/device/userdb/users", isActive: false },
    { title: t("Associations"), isActive: true },
  ];

  const columns = [
    {
      headerText: t("User Name"),
      columnName: "username",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t("Mac/IP Address"),
      columnName: "macAddress",
      sortable: true,
      searchable: false,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
      sortable: true,
      searchable: false,
    },
  ];

  const dataMap = ({ username, macAddress }: Association, index: number) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{username}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{macAddress}</Text>
      </Td>
      <Td>
        <Flex>
          <Box mr="sp8">
            <TableIconButton
              icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
              aria-label={"Delete association"}
              onClick={() => console.log("not implemented")}
              data-testId="deleteAssociationBtn"
            />
          </Box>
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <TableBasedPage
      title={t("Associations")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={data}
      tableDataMap={dataMap}
      loaded={loaded}
      showSearch={false}
    />
  );
};

export default AssociationTableRenderer;
