import React from "react";
import LeftPanel from "../utils/LeftPanel";
import SessionStore from "../stores/SessionStore";
import AccessDenied from "./AccessDenied";

export default function Authorization(allowedRoles) {
  return (WrappedComponent) => {
    return class WithAuthorization extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          devicePermissions: SessionStore.getDevicePermissions(),
          supportAdmin: SessionStore.isSupportAdmin(),
          available_devices_loaded: SessionStore.hasDevicePermissionsLoaded(),
        };
      }

      componentWillMount() {
        SessionStore.listen(this.onChange);
      }

      componentWillUnmount() {
        SessionStore.unlisten(this.onChange);
      }

      onChange = () => {
        this.setState({
          devicePermissions: SessionStore.getDevicePermissions(),
          supportAdmin: SessionStore.isSupportAdmin(),
          available_devices_loaded: SessionStore.hasDevicePermissionsLoaded(),
        });
      };

      render() {
        if (this.state.supportAdmin) {
          return <WrappedComponent {...this.props} />;
        }

        if (!this.state.available_devices_loaded) {
          return (
            <LeftPanel>
              <div className="padded">
                <div className="row">
                  <div className="col-md-12">Loading....</div>
                </div>
              </div>
            </LeftPanel>
          );
        }

        for (let permission of this.state.devicePermissions) {
          if (allowedRoles.indexOf(permission) !== -1) {
            return <WrappedComponent {...this.props} />;
          }
        }

        return <AccessDenied />;
      }
    };
  };
}
