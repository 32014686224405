import React from "react";
import { withTranslation } from "@familyzone/component-library";
import useFetchReportingTableData from "../../utils/hooks/useFetchReportingTableData";
import ReportingSearchableTable from "./ReportingSearchableTable";
import PageWithHeader from "../templates/PageWithHeader";

const ReportingTopDestinationPorts = (props) => {
  const { data, loaded } = useFetchReportingTableData("/surfwize/analytics/destPorts");

  const searchFilter = (row, searchTerm) => {
    return !searchTerm || row.ip.includes(searchTerm) || row.mac.includes(searchTerm);
  };

  // Set the total sum
  data.forEach((d) => (d.total = d.upload + d.download));

  const { t } = props;
  const tableProps = {
    data,
    loaded,
    columns: [
      { name: "destPort", header: "Destination Port", sortable: true },
      { name: "upload", header: "Upload", isAmount: true, sortable: true },
      { name: "download", header: "Download", isAmount: true, sortable: true },
      { name: "total", header: "Total", isAmount: true },
    ],
    searchFilter,
  };

  return (
    <PageWithHeader
      breadcrumbs={[
        { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
        { title: t("Network"), url: "/surfwize/device/network", isActive: false },
        { title: t("Destination Ports"), url: "/surfwize/device/network/destports", isActive: true },
      ]}
      title={t("Destination Ports")}
    >
      <ReportingSearchableTable isInfiniteScroll {...tableProps} />
    </PageWithHeader>
  );
};
export default withTranslation()(ReportingTopDestinationPorts);
