import React from "react";
import { ButtonTd, Flex, Tag, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import TableBasedPage from "../../templates/TableBasedPage";
import { getCellContent, getExceptionsText, getTypeText, IRow } from "./WmiConfigurationHelpers";

interface WmiConfigurationProps {
  data: IRow[];
  loaded: boolean;
}

const WmiConfigurationNew = ({ data, loaded }: WmiConfigurationProps): JSX.Element => {
  const { t } = useTranslation();
  const title = t("WMI");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("WMI"), isActive: true },
  ];

  const columnHeaders = ["Status", "Server", "Domain", "Type", "Username", "Exceptions"];

  const columns = [];
  columnHeaders.forEach((item) => {
    columns.push({
      headerText: t(item),
      columnName: item.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
      exportable: true,
    });
  });
  columns.push({ headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false });

  const handleEditRow = () => {
    return;
  };
  const handleDeleteRow = () => {
    return;
  };

  const statusTag = (row: IRow): JSX.Element => {
    const variant = row.enabled ? "subtle" : "red";
    const label = row.enabled ? "Allowed" : "Blocked";
    return <Tag tagLabel={label} variant={variant} />;
  };

  const dataMap = (row: IRow, index: number) => (
    <Tr key={index}>
      <Td>{statusTag(row)}</Td>

      <Td>
        <Text fontSize="md">
          {getCellContent(row, "hostname")}
          {row.hostname}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">{getCellContent(row, "domain")}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{getTypeText(row)}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{getCellContent(row, "username")}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{getExceptionsText(row)}</Text>
      </Td>
      <Td>
        <Flex>
          <ButtonTd buttonIconName="fa-pencil" onClick={() => handleEditRow()} />
          <ButtonTd buttonIconName="fa-trash" onClick={() => handleDeleteRow()} />
        </Flex>
      </Td>
    </Tr>
  );
  return <TableBasedPage title={title} breadcrumbs={breadcrumbs} columns={columns} data={data} tableDataMap={dataMap} loaded={loaded} />;
};

export default WmiConfigurationNew;
