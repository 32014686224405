import React, { useMemo, useState, useRef, useEffect } from "react";
import TableBasedPage from "../../templates/TableBasedPage";
import NetworkIcon from "../../../modules/NetworkIcon";
import { Box, Button, Flex, InlineNotification, Link, Tag, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import { TableColumn } from "../../../types/table";
import { capitalise } from "../../../helpers/stringHelpers";
import { allResults, allTypes, formatFilterForExport, getCategories, UserJourneyData } from "./UserJourneyHelper";
import UserJourneyFilters from "./UserJourneyFilters";
import { capitalize } from "lodash";
import StudentNamePill from "../../../modules/StudentNamePill";
import { ExportReportModal } from "../../../modules/ExportReport";
import { SortType } from "../../templates/SortSearchTable";
import moment from "moment";
import { useUserJourneyFiltersStore } from "../../../storez/MultiSelectStores";

interface TableRowProps {
  tableData: UserJourneyData;
  isHighlighted: boolean;
  scrollTo: boolean;
}

export const TableRow: React.FC<TableRowProps> = ({ tableData, isHighlighted, scrollTo }) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const resultVariants: Record<string, "subtle" | "orange" | "red"> = {
    ALLOWED: "subtle",
    FLAGGED: "orange",
    BLOCKED: "red",
  };

  const resultTag = (result: string) => {
    const variant = resultVariants[result];
    const label = capitalize(result);
    return <Tag tagLabel={label} variant={variant} />;
  };

  useEffect(() => {
    if (scrollTo) {
      const divElement = ref.current;
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [scrollTo]);

  const toggleShowMore = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <Tr ref={ref}>
      <Td background={isHighlighted ? "brand.200" : undefined}>
        <Text fontSize="md">{tableData.string_datetime ?? tableData.time}</Text>
      </Td>
      <Td background={isHighlighted ? "brand.200" : undefined}>{resultTag(tableData.result)}</Td>
      <Td background={isHighlighted ? "brand.200" : undefined}>
        <Text fontSize="md">
          {tableData.signature_name?.map((data, i) => (
            <Text>
              {data}
              {i + 1 < tableData.signature_name.length ? "," : ""}
            </Text>
          ))}
        </Text>
      </Td>
      <Td width="35%" background={isHighlighted ? "brand.200" : undefined}>
        <Flex>
          <Box mr="sp8" width="16px" minWidth="16px" height="16px">
            <NetworkIcon iconURL={tableData.signature_favicon_url} fallBackIcon="fa-arrow-pointer" />
          </Box>
          <Box wordBreak="break-word">
            <Text fontSize="md">
              {tableData.activity.length > 240 && !isExpanded ? tableData.activity.substring(0, 240) + "..." : tableData.activity}

              {tableData.activity.length > 240 &&
                (isExpanded ? (
                  <Link ml="sp8" whiteSpace="nowrap" className="clickable" onClick={toggleShowMore}>
                    Show Less
                  </Link>
                ) : (
                  <Link ml="sp8" whiteSpace="nowrap" className="clickable" onClick={toggleShowMore}>
                    Show More
                  </Link>
                ))}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td background={isHighlighted ? "brand.200" : undefined}>
        <Text fontSize="md">{capitalise(tableData.type)}</Text>
      </Td>
    </Tr>
  );
};

interface IUserJourneyProps {
  data: UserJourneyData[];
  loaded: boolean;
  dateTimeParams: string;
  initialResultFilter?: string;
}

export const UserJourneyNew: React.FC<IUserJourneyProps> = ({ data, loaded, dateTimeParams, initialResultFilter }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Users"), url: "/surfwize/reporting/users", isActive: false },
    { title: t("User Journey"), isActive: true },
  ];

  const items = ["Timestamp", "Result", "Category", "Activity", "Type"];
  const columnNames = ["string_datetime", "result", "signature_name", "activity", "type"];

  const queryString = window.location.search || window.location.hash.split("?")[1] || "";
  const urlParams = new URLSearchParams(queryString);
  const highlight = urlParams.get("highlight");
  const startDate = parseInt(urlParams.get("startDate") || "-1");
  const endDate = parseInt(urlParams.get("endDate") || "-1");

  const [isInContextMode, setIsInContextMode] = useState(Boolean(highlight && startDate && endDate));

  const columns: TableColumn[] = [];
  items.forEach((item, index) =>
    columns.push({
      headerText: item,
      columnName: columnNames[index],
      sortable: true,
      searchable: true,
      exportable: true,
    })
  );

  const contextData = data.filter((d) => {
    const mo = moment(d.string_datetime ?? d.time, "YYYY-MM-DD HH:mm:ss");
    const time = mo.toDate().getTime();
    return time <= endDate && time >= startDate;
  });

  const firstOccurrence = contextData.findIndex((d) => d.activity.split(" - ")[0] === highlight);

  const tableRows = (tableData: UserJourneyData, index: number) => {
    const isHighlighted = isInContextMode && tableData.activity.split(" - ")[0] === highlight;
    return <TableRow tableData={tableData} isHighlighted={isHighlighted} key={index} scrollTo={firstOccurrence === index} />;
  };

  const UserJourneyPill = () => {
    if (data.length > 0) {
      return (
        <Box marginTop="24" marginLeft="24">
          <StudentNamePill username={data[0].user} />
        </Box>
      );
    } else return <></>;
  };

  const initialResult = initialResultFilter && allResults.includes(initialResultFilter) ? [{ value: initialResultFilter }] : [];
  useUserJourneyFiltersStore.setState({ resultsSelected: initialResult });

  const [exportTypeFilter, setExportTypeFilter] = useState<string[] | undefined>();
  const [exportResultFilter, setExportResultFilter] = useState<string[] | undefined>();
  const [exportCategoryFilter, setExportCategoryFilter] = useState<string[] | undefined>();

  const [filteredData, setFilteredData] = useState<UserJourneyData[]>(data);
  const updateFilteredData = (filtered: UserJourneyData[]) => {
    setFilteredData(filtered);
  };

  const numberOfCategories = useMemo(() => {
    return getCategories(data).length;
  }, [data]);

  const handleExitViewInContext = () => {
    setIsInContextMode(false);
  };

  const UserJourneyChildren = () => {
    return (
      <>
        <UserJourneyPill />
        <UserJourneyFilters
          disabled={isInContextMode}
          data={data}
          onDataFiltered={updateFilteredData}
          onFiltersChanged={(types, results, categories) => {
            setExportTypeFilter(formatFilterForExport(types, allTypes.length));
            setExportResultFilter(formatFilterForExport(results, allResults.length));
            setExportCategoryFilter(formatFilterForExport(categories, numberOfCategories));
          }}
        />
        {isInContextMode && (
          <Box mt="sp24" mx="sp24">
            <InlineNotification
              status="info"
              notificationDescription={
                <>
                  <Flex>
                    <Text display="inline">
                      Viewing context for search{" "}
                      <Text display="inline" fontWeight="bold">
                        "{highlight}"
                      </Text>{" "}
                      within a{" "}
                      <Text display="inline" fontWeight="bold">
                        {(endDate - startDate) / 1000 / 60 / 60}
                      </Text>{" "}
                      hour window
                    </Text>
                  </Flex>
                  <Flex>
                    <Button size="compact" variant="ghost" onClick={handleExitViewInContext}>
                      Exit view in context mode
                    </Button>
                  </Flex>
                </>
              }
            />
          </Box>
        )}
      </>
    );
  };

  const [exportSortColumn, setExportSortColumn] = useState<string>("string_datetime");
  const [exportIsSortAscending, setExportIsSortAscending] = useState<boolean>(false);

  const onSort = (sortColumn: string, sortDirection: SortType) => {
    if (sortColumn.trim() !== "") {
      setExportSortColumn(sortColumn);
    }
    setExportIsSortAscending(sortDirection === "asc");
  };

  return (
    <TableBasedPage
      title={t("User Journey")}
      breadcrumbs={breadcrumbs}
      children={UserJourneyChildren()}
      columns={columns}
      data={isInContextMode ? contextData : filteredData}
      tableDataMap={tableRows}
      onSort={onSort}
      disableInfiniteScroll={isInContextMode}
      childrenInTableHeader={
        <ExportReportModal
          headerText="Export User Journey Report"
          userId={data[0]?.user || ""}
          dateTimeParams={dateTimeParams}
          disabled={!data.length}
          typeFilter={exportTypeFilter}
          resultFilter={exportResultFilter}
          categoryFilter={exportCategoryFilter}
          sortColumn={exportSortColumn}
          isSortAscending={exportIsSortAscending}
        />
      }
      loaded={loaded}
    />
  );
};

export default UserJourneyNew;
