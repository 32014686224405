import React, { useEffect, useState } from "react";
import Api from "../../utils/Api";
import "../../../css/LookerReportPage.css";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";

export default function LookerPageWrapper(props) {
  const [allow, setAllow] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");

  useEffect(() => {
    useFeatureFlagStore
      .getState()
      .getOrFetch()
      .then((flags) => {
        // TODO: Use `requireFeatureFlags` check in `AppRoot.js` instead for better UX!
        setAllow(!!flags[props.route.featureFlag]);
      });
  });

  useEffect(() => {
    Api.get(
      props.route.apiEndpoint,
      function (result) {
        setEmbedUrl(result);
      },
      function (result) {
        console.log("Unable to generate Looker Embed URL: " + result);
        setEmbedUrl("");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!allow) return <div />;

  return <iframe width="100%" height="100%" src={embedUrl} className="LookerACS" title="Looker Dashboard"></iframe>;
}
