import React from "react";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";

export default class Netconsole extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      settings: {},
      saving: false,
      changes: true,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    Api.get("/managedevice/ajax/device/netconsole", function (result) {
      self.setState({
        settings: result["result"],
      });
    });
  };

  handle_Submit = () => {
    this.setState({
      saving: true,
      changes: false,
    });
    Api.post(
      "/managedevice/ajax/device/netconsole",
      this.state.settings,
      (e) => {
        this.handle_load();
        this.setState({ saving: false });
      },
      function (error) {}
    );
  };

  handle_change_enabled = () => {
    const settings = this.state.settings;
    settings.enabled = !this.state.settings.enabled;

    this.setState({ changes: true, settings: settings });
  };

  handle_change_src_port = (event) => {
    const settings = this.state.settings;
    settings.src_port = parseInt(event.target.value, 10);
    this.setState({ changes: true, settings: settings });
  };

  handle_change_src_ip = (event) => {
    const settings = this.state.settings;
    settings.src_ip = event.target.value;
    this.setState({ changes: true, settings: settings });
  };

  handle_change_interface = (event) => {
    const settings = this.state.settings;
    settings.interface = event.target.value;
    this.setState({ changes: true, settings: settings });
  };

  handle_change_dst_port = (event) => {
    const settings = this.state.settings;
    settings.dst_port = parseInt(event.target.value, 10);
    this.setState({ changes: true, settings: settings });
  };

  handle_change_dst_ip = (event) => {
    const settings = this.state.settings;
    settings.dst_ip = event.target.value;
    this.setState({ changes: true, settings: settings });
  };

  handle_change_nexthop_mac = (event) => {
    const settings = this.state.settings;
    settings.nexthop_mac = event.target.value;
    this.setState({ changes: true, settings: settings });
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Netconsole</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Enabled</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-checkbox">
                        <input type="checkbox" checked={this.state.settings.enabled} onChange={this.handle_change_enabled} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Source Port</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="number" value={this.state.settings.src_port} onChange={this.handle_change_src_port} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Source IP</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.settings.src_ip} onChange={this.handle_change_src_ip} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Interface</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.settings.interface} onChange={this.handle_change_interface} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Destination Port</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="number" value={this.state.settings.dst_port} onChange={this.handle_change_dst_port} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Destination IP</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.settings.dst_ip} onChange={this.handle_change_dst_ip} />
                      </div>
                    </div>
                  </div>
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Nexthop MAC</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.settings.nexthop_mac} onChange={this.handle_change_nexthop_mac} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton saving={this.state.saving} changes={this.state.changes} onClick={this.handle_Submit} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
