import alt from "../alt";

class ConfigActions {
  fetch() {
    return "nothing";
  }

  setConfig(config) {
    return config;
  }

  saveConfig(config) {
    return config;
  }
}

/**
 * @deprecated Use {@link useDeviceOptionsStore} instead.
 *
 * This will be removed by https://familyzone.atlassian.net/browse/COMMUNITY-1214
 */
export default alt.createActions(ConfigActions);
