import React from "react";
import PropTypes from "prop-types";
import Api from "../../utils/Api";

export default class GoogleCustomerConfigurationLink extends React.Component {
  componentDidMount() {
    const self = this;
    Api.post(
      "/config/ajax/authentication/google/customer/activate?code=" + this.props.location.query.code,
      {},
      function (res) {
        self.context.router.push("/config/device/authentication/google");
      },
      function (error) {
        self.context.router.push("/config/device/authentication/google");
      }
    );
  }

  render() {
    return <div></div>;
  }
}

GoogleCustomerConfigurationLink.contextTypes = {
  router: PropTypes.object.isRequired,
};
