import React from "react";
import { withTranslation } from "@familyzone/component-library";
import ReportingSearchableTable from "./ReportingSearchableTable";
import useFetchReportingTableData from "../../utils/hooks/useFetchReportingTableData";
import PageWithHeader from "../templates/PageWithHeader";

const ReportingTopDestinationIps = (props) => {
  const { data, loaded } = useFetchReportingTableData("/surfwize/analytics/destIps");

  const searchFilter = (row, searchTerm) => {
    return !searchTerm || row.ip.includes(searchTerm) || row.mac.includes(searchTerm);
  };

  // Set the total sum
  data.forEach((d) => (d.total = d.upload + d.download));

  const { t } = props;
  const tableProps = {
    data: data,
    loaded,
    columns: [
      { name: "destIp", header: "Destination IP", sortable: true },
      { name: "upload", header: "Upload", isAmount: true, sortable: true },
      { name: "download", header: "Download", isAmount: true, sortable: true },
      { name: "total", header: "Total", isAmount: true },
    ],
    searchFilter,
  };

  return (
    <PageWithHeader
      breadcrumbs={[
        { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
        { title: t("Network"), url: "/surfwize/device/network", isActive: false },
        { title: t("Destination IPs"), url: "/surfwize/device/network/destip", isActive: true },
      ]}
      title={t("Destination IPs")}
    >
      <ReportingSearchableTable isInfiniteScroll {...tableProps} />
    </PageWithHeader>
  );
};
export default withTranslation()(ReportingTopDestinationIps);
