import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onReload: () => void;
}

const SaveConflictErrorModal: React.FC<Props> = ({ open, onClose, onSave, onReload }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleConfirm = (): void => {
    setSaving(true);
    onSave();
  };

  const handleReload = (): void => {
    setLoading(true);
    onReload();
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSaving(false);
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      variant="warning"
      size="md"
      primaryCTALabel={saving ? t("Saving..") : t("Confirm Save")}
      primaryCTADisabled={loading || saving}
      onPrimaryCTAClick={handleConfirm}
      secondaryCTALabel={loading ? t("Loading..") : t("Reload Page")}
      secondaryCTADisabled={loading || saving}
      onSecondaryCTAClick={handleReload}
    >
      <ModalBody>
        <Text mb="sp8" fontFamily="heading">
          {t("Conflict while saving changes")}
        </Text>
        <Text mb="sp8" fontFamily="paragraphs">
          {t("Another user has modified the configuration while you were on this page.")}
        </Text>
        <Text mb="sp8" fontFamily="paragraphs">
          {t("Please choose how to proceed.")}
        </Text>
      </ModalBody>
    </Modal>
  );
};

export default SaveConflictErrorModal;
