import React from "react";
import { Link } from "react-router";

import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Separator from "../../modules/Separator";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";

class AddVPN extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { name: "" };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd(this.state.name);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add VPN" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup-element-title">Name</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.name} onChange={this.handle_ChangeName} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div></div>;
  }
}

export default class GatewayToGatewayVpns extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      vpns: [],
      visible__add_group: false,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/edgewize/ajax/gatewayvpns", function (result) {
      self.setState({
        vpns: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    this.setState({
      visible__add_permission: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add VPN" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (name) => {
    const self = this;
    Api.delete("/edgewize/ajax/gatewayvpns", { name: name }, function (result) {
      self.handle_load();
    });
  };

  handle__AddGroup = (name) => {
    const self = this;
    Api.put("/edgewize/ajax/gatewayvpns", { name: name }, function (result) {
      self.handle_load();
    });
  };

  render__content = () => {
    const self = this;
    const columns = [
      {
        title: "Name",
        data: function (row) {
          return <Link to={"/edgewize/device/vpn/" + row["name"]}>{row["name"]}</Link>;
        },
        onclick: undefined,
        search: FlexibleTable.search__single_string_field("name"),
      },
      {
        title: "Status",
        data: function (row) {
          return "-";
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton to={"/edgewize/device/vpn/" + row["name"]}></EditButton>
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row["name"])}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.vpns} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddVPN
          visible={this.state.visible__add_permission}
          handleAdd={this.handle__AddGroup}
          handleClose={this.handler__clickCloseAdd}
        ></AddVPN>

        <TableCentricPage icon="fa fa-cloud" title="Gateway VPN" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
