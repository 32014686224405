import React from "react";
import SearchableSelect from "../modules/SearchableSelect";
import "../../css/ListSelector.css";

class GroupSelectedOption extends React.Component {
  handleRemove = () => {
    this.props.handleRemove(this.props.item);
  };

  render() {
    return (
      <div className="listselector-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="signature_item_remove selected-item-remove" onClick={this.handleRemove}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </a>

        {this.props.item}
      </div>
    );
  }
}

export default class ListSelector extends React.Component {
  handle_AddSelection = (event) => {
    this.props.addElement(event.value);
  };

  handle_RemoveSelection = (item) => {
    this.props.removeElement(item);
  };

  render_SelectedOptions = () => {
    let ret = [];
    for (let x of this.props.selected) {
      ret.push(<GroupSelectedOption key={x} item={x} handleRemove={this.handle_RemoveSelection} />);
    }

    return ret;
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let option of this.props.available) {
      ret.push([option, option]);
    }

    return ret;
  };

  render() {
    const valueDataMap = this.props.valueDataMap ? this.props.valueDataMap : this.get_SelectionMap();

    return (
      <div>
        <div className="mui-select">
          <SearchableSelect clearable={false} value={""} onChange={this.handle_AddSelection} valueDataMap={valueDataMap}></SearchableSelect>
          <label>{this.props.label}</label>
        </div>
        <div>{this.render_SelectedOptions()}</div>
      </div>
    );
  }
}
