import React, { useCallback, useEffect, useState } from "react";
import {
  Flex,
  useTranslation,
  Modal,
  Table,
  Td,
  Tr,
  Tbody,
  Text,
  Button,
  Box,
  useToast,
  RadioGroup,
  Radio,
  Option,
  SingleSearchSelector,
} from "@familyzone/component-library";

import { SafeSearchRule, SourceCriteriaError, TimePeriod } from "./types";
import CriteriaSelector from "../../modules/criteria/CriteriaSelector";
import validateCriteria from "../../modules/criteria/validateCriteria";
import { SecurityGroup, IpObject, FilteringSourceCriteria, Fingerprint, MacObject } from "../../modules/criteria/criteriaTypes";
import { getFingerprints, getObjects, getSecurityGroups, getTimePeriods } from "./ApiHelpers";
import { googleUsageRights as googleUsageRightsMap } from "./SafeSearchTable";

export const headerTextEditing = "Edit Policy";
export const headerTextCreating = "Create Policy";
export const createRuleText = "Save Policy";
export const populateObjectsErrorText = "Failed to load necessary items. Please refresh the page.";
export const criteriaText = "Criteria";
export const enabledText = "Enforced";
export const disabledText = "Disabled";
export const googleText = "Google";
export const bingText = "Bing";
export const youtubeText = "Youtube";
export const safeSearchText = "Safesearch";
export const strictText = "Strict";
export const moderateText = "Moderate";
export const usageRightsText = "Usage Rights";

interface GoogleUsageRightsOption extends Option {
  value: keyof typeof googleUsageRightsMap;
  label: string;
}

interface SafeSearchModalProps {
  open: boolean;
  editing: boolean;
  loading: boolean;
  rule: SafeSearchRule | null;
  onClose: () => void;
  onSubmit: (rule: SafeSearchRule) => void;
}

const SafeSearchModal: React.FC<SafeSearchModalProps> = ({
  open = false,
  editing = false,
  loading = false,
  rule,
  onClose = () => "",
  onSubmit = () => "",
}) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [sourceCriteria, setSourceCriteria] = useState<FilteringSourceCriteria[]>([]);
  const [sourceCriteriaErrors, setSourceCriteriaErrors] = useState<SourceCriteriaError[]>([]);
  const [googleSafeSearch, setGoogleSafeSearch] = useState(false);
  const [googleUsageRights, setGoogleUsageRights] = useState<keyof typeof googleUsageRightsMap>("nfbl");
  const [youtubeSafeSearch, setYoutubeSafeSearch] = useState<"disabled" | "moderate" | "strict">("disabled");
  const [bingEnabled, setBingEnabled] = useState(false);

  const [ipObjects, setIpObjects] = useState<IpObject[]>([]);
  const [fingerprints, setFingerprints] = useState<Fingerprint[]>([]);
  const [macObjects, setMacObjects] = useState<MacObject[]>([]);
  const [timePeriods, setTimePeriods] = useState<TimePeriod[]>([]);
  const [securityGroups, setSecurityGroups] = useState<SecurityGroup[]>([]);

  const headerText = editing ? t(headerTextEditing) : t(headerTextCreating);

  const googleUsageRightsOptions: GoogleUsageRightsOption[] = Object.entries(googleUsageRightsMap)
    .map((entry) => ({
      value: entry[0],
      label: t(entry[1]),
    }))
    .filter((entry): entry is GoogleUsageRightsOption => entry.value in googleUsageRightsMap);

  const handleChangeGoogleUsageRights = (selected: GoogleUsageRightsOption | null) => {
    if (!selected) return;
    setGoogleUsageRights(selected.value);
  };

  const handleChangeSourceCriteria = (sourceCriteria: FilteringSourceCriteria[]) => {
    setSourceCriteria(sourceCriteria);
  };

  const handleEnableSafeSearch = () => {
    setGoogleSafeSearch(true);
  };

  const handleDisableSafeSearch = () => {
    setGoogleSafeSearch(false);
  };

  const handleSetYTSafeSearch = (setting: "disabled" | "moderate" | "strict") => {
    setYoutubeSafeSearch(setting);
  };

  const handleEnableBing = () => {
    setBingEnabled(true);
  };

  const handleDisableBing = () => {
    setBingEnabled(false);
  };

  const handleClickSubmit = () => {
    if (!rule) return;

    const criteriaValidationResult = validateCriteria(sourceCriteria);

    const [hasErrors, sourceCriterias] = criteriaValidationResult;

    if (hasErrors) {
      setSourceCriteriaErrors(
        sourceCriterias
          .map((sourceCriteria) => {
            if (sourceCriteria.errorMessage) {
              return {
                id: sourceCriteria.id,
                msg: sourceCriteria?.errorMessage,
              };
            }
            return null;
          })
          .filter((s): s is SourceCriteriaError => !!s)
      );
      return;
    }

    setSourceCriteriaErrors([]);

    const finalRule: SafeSearchRule = {
      id: rule.id,
      source_criteria: sourceCriteria,
      googleUsageRights,
      enabled: googleSafeSearch,
      youtube_enabled: youtubeSafeSearch === "moderate" || youtubeSafeSearch === "disabled" ? false : true,
      youtube_moderate_enabled: youtubeSafeSearch === "moderate" ? true : false,
      bing_enabled: bingEnabled,
    };

    onSubmit(finalRule);
  };

  useEffect(() => {
    if (!rule) return;
    setGoogleSafeSearch(rule.enabled);
    setSourceCriteria(rule.source_criteria);
    setGoogleUsageRights(rule.googleUsageRights);
    setYoutubeSafeSearch(rule.youtube_moderate_enabled ? "moderate" : rule.youtube_enabled ? "strict" : "disabled");
    setBingEnabled(rule.bing_enabled);
  }, [rule, open]);

  const populateObjects = useCallback(async () => {
    try {
      // Asynchronously await all the necessary network requests at once
      const data = await Promise.all([getObjects(), getFingerprints(), getTimePeriods(), getSecurityGroups()]);
      // Set IP objects
      setIpObjects(data[0].filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0));
      // Set device fingerprints
      setFingerprints(data[1]);
      // Set MAC objects
      setMacObjects(data[0].filter((obj): obj is MacObject => obj.type === 4));
      // Set timeperiods
      setTimePeriods(data[2]);
      // Set security groups
      setSecurityGroups(data[3]);
    } catch (err) {
      console.error(err);
      errorToast({ title: t(populateObjectsErrorText), isClosable: true });
    }
  }, [errorToast, t]);

  useEffect(() => {
    populateObjects().catch(() => "");
  }, [populateObjects]);

  return (
    <Modal isOpen={open} onClose={onClose} headerText={headerText} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box my="sp24" overflowY="auto" overflow="visible">
        <Table>
          <Tbody>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t(criteriaText)}
                </Text>
              </Td>
              <Td>
                <CriteriaSelector
                  ipObjects={ipObjects}
                  fingerprints={fingerprints}
                  macObjects={macObjects}
                  timePeriods={timePeriods}
                  securityGroups={securityGroups}
                  disabled={loading}
                  selected={sourceCriteria}
                  errors={sourceCriteriaErrors}
                  onChange={handleChangeSourceCriteria}
                  customOptions={{
                    group: "Group",
                    ipv4: "Network",
                    "ipv4.address": "IP Address",
                    "ipv4.alias": "IP Address Object",
                    "ipv4.range": "Network Range",
                    "source.mac": "Mac Address",
                    "source.mac.pool": "Mac Address Object",
                    "source.user": "User",
                  }}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" fontWeight="bold" color="text.title">
                  {t(googleText)}
                </Text>
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t(safeSearchText)}
                </Text>
              </Td>
              <Td>
                <Flex>
                  <RadioGroup>
                    <Radio name="enabled" isChecked={googleSafeSearch} onChange={handleEnableSafeSearch} isDisabled={loading}>
                      {t(enabledText)}
                    </Radio>
                    <Radio name="disabled" isChecked={!googleSafeSearch} onChange={handleDisableSafeSearch} isDisabled={loading}>
                      {t(disabledText)}
                    </Radio>
                  </RadioGroup>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t(usageRightsText)}
                </Text>
              </Td>
              <Td>
                <SingleSearchSelector
                  options={googleUsageRightsOptions}
                  selected={googleUsageRightsOptions.find((o) => o.value === googleUsageRights)}
                  onChange={handleChangeGoogleUsageRights}
                  filterOptions={false}
                  showClearIcon={false}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" fontWeight="bold" color="text.title">
                  {t(youtubeText)}
                </Text>
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t(safeSearchText)}
                </Text>
              </Td>
              <Td>
                <Flex>
                  <RadioGroup>
                    <Radio
                      name="enabled"
                      isChecked={youtubeSafeSearch === "strict"}
                      onChange={() => handleSetYTSafeSearch("strict")}
                      isDisabled={loading}
                    >
                      {t(strictText)}
                    </Radio>
                    <Radio
                      name="enabled"
                      isChecked={youtubeSafeSearch === "moderate"}
                      onChange={() => handleSetYTSafeSearch("moderate")}
                      isDisabled={loading}
                    >
                      {t(moderateText)}
                    </Radio>
                    <Radio
                      name="disabled"
                      isChecked={youtubeSafeSearch === "disabled"}
                      onChange={() => handleSetYTSafeSearch("disabled")}
                      isDisabled={loading}
                    >
                      {t(disabledText)}
                    </Radio>
                  </RadioGroup>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" fontWeight="bold" color="text.title">
                  {t(bingText)}
                </Text>
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontSize="medium" color="text.title">
                  {t(safeSearchText)}
                </Text>
              </Td>
              <Td>
                <RadioGroup>
                  <Radio name="enabled" isChecked={bingEnabled} onChange={handleEnableBing} isDisabled={loading}>
                    {t(enabledText)}
                  </Radio>
                  <Radio name="disabled" isChecked={!bingEnabled} onChange={handleDisableBing} isDisabled={loading}>
                    {t(disabledText)}
                  </Radio>
                </RadioGroup>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Flex>
        <Button variant="primary" onClick={handleClickSubmit} isLoading={loading} aria-label="save">
          {t(createRuleText)}
        </Button>
      </Flex>
    </Modal>
  );
};

export default SafeSearchModal;
