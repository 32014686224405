import React, { useEffect, useState } from "react";

import LeftPanel from "../../utils/LeftPanel";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";

import { useAgentAuthStore } from "../../stores/AgentAuthStore";
import { Box, Checkbox, Flex, Icon, Text, TextInput } from "@familyzone/component-library";
import { FormControl, FormLabel } from "@chakra-ui/form-control";

const AgentAuthentication: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [hasChanges, setChanges] = useState(true);
  const agentAuth = useAgentAuthStore((state) => state.agentAuth);
  const getAgentAuth = useAgentAuthStore((state) => state.getAgentAuth);
  const setAgentAuth = useAgentAuthStore((state) => state.setAgentAuth);
  const saveAgentAuth = useAgentAuthStore((state) => state.saveAgentAuth);

  useEffect(() => {
    setAgentAuth({});
    getAgentAuth()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [getAgentAuth, setAgentAuth]);

  const handle_ChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgentAuth({
      ...agentAuth,
      enabled: event.target.checked,
    });
    setChanges(true);
  };

  const handle_ChangeRequireKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgentAuth({
      ...agentAuth,
      require_hash_key: event.target.checked,
    });
    setChanges(true);
  };

  const handle_ChangeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgentAuth({
      ...agentAuth,
      key: event.target.value,
    });
    setChanges(true);
  };

  const handle_Submit = async () => {
    setSaving(true);
    if (hasChanges) {
      const saved = await saveAgentAuth(agentAuth);
      setSaving(false);
      setChanges(!saved);
    }
  };

  return (
    <LeftPanel>
      <LetterPanel>
        <LetterPanelHeader>
          NAC API & Auth Agent
          <DumbBusyIndicator loaded={!loading} />
        </LetterPanelHeader>
        <LetterPanelBody>
          <Box display="flex" flexDirection="column" px="20" py="20" rowGap="20">
            <Box width="70%">
              <Flex alignItems="center" bgColor="#e3faf8" width="100" mb="5" mt="5" p="5">
                <Icon icon="fa-info-circle" color="teal" mr="sp2" />
                <Text>This page is not visible to customers, support admins can only view this page.</Text>
              </Flex>
            </Box>

            <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
              <FormLabel width="40" htmlFor="enabled">
                Enabled
              </FormLabel>
              <Checkbox isChecked={agentAuth?.enabled ?? false} onChange={handle_ChangeEnabled} />
            </FormControl>

            <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
              <FormLabel width="40" htmlFor="requireKey">
                Require Key
              </FormLabel>
              <Checkbox isChecked={agentAuth?.require_hash_key ?? false} onChange={handle_ChangeRequireKey} />
            </FormControl>

            <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
              <FormLabel htmlFor="key" width="40">
                Key
              </FormLabel>
              <Box flexGrow={2}>
                <TextInput value={agentAuth?.key ?? ""} onChange={handle_ChangeKey} />
              </Box>
            </FormControl>
          </Box>
        </LetterPanelBody>
        <LetterPanelFooter>
          <LetterPanelSaveButton onClick={handle_Submit} changes={hasChanges} saving={saving}></LetterPanelSaveButton>
        </LetterPanelFooter>
      </LetterPanel>
    </LeftPanel>
  );
};

export default AgentAuthentication;
