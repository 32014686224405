import React from "react";
import Criteria from "./Criteria";
import SearchableSelect from "../modules/SearchableSelect";

export default class Protocol extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = { type: 0 };
    }

    this.state = {
      criteria: props.criteria,
    };

    /* {type: "protocol", negate: false, conditions: []} */
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = { type: 0 };
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_ChangeProtocol = (event) => {
    this.state.criteria.conditions = { type: event.value };
    this.bubble_Changes(this.state.criteria);
  };

  bubble_Changes = (criteria) => {
    /* Push changes up to the root */
    this.props.bubbleMethod(criteria);
  };

  render() {
    return (
      <Criteria label="Protocol" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-select">
          <SearchableSelect
            value={this.state.criteria.conditions.type}
            onChange={this.handle_ChangeProtocol}
            clearable={false}
            valueDataMap={[
              [1, "ICMP"],
              [2, "IGMP"],
              [3, "GGP"],
              [6, "TCP"],
              [8, "EGP"],
              [9, "IGP"],
              [17, "UDP"],
              [88, "EIGRP"],
              [89, "OSPF"],
              [47, "GRE"],
              [50, "ESP"],
              [51, "AH"],
              [58, "ICMPv6"],
            ]}
          ></SearchableSelect>
        </div>
      </Criteria>
    );
  }
}
