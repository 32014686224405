import clsx from "clsx";
import { useState } from "react";

export const ToggleGroup = (props) => {
  const [selectedId, setSelectedId] = useState(() => {
    if (props.toggles.length === 0) {
      return null;
    }

    const initiallySelectedToggle = props.toggles.find((t) => t.initiallySelected);
    return initiallySelectedToggle ? initiallySelectedToggle.label : props.toggles[0].label;
  });

  const onClickWrap = (t) => (event) => {
    // if the clicked toggle is already selected then noop
    if (selectedId === t.label) {
      return;
    }

    setSelectedId(t.label);
    t.onClick();
  };

  return (
    <div className={clsx("ToggleGroup", props.className)}>
      {props.toggles.map((t) => (
        <Toggle key={t.label} selected={t.label === selectedId} onClick={onClickWrap(t)}>
          {t.label}
        </Toggle>
      ))}
    </div>
  );
};

const Toggle = (props) => {
  return (
    <button className={clsx("Toggle", props.className, { "Toggle--selected": props.selected })} onClick={props.onClick}>
      {props.children}
    </button>
  );
};
