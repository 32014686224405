import { RedFlagPillProps, RedFlagSeverity } from "../RedFlagPill/RedFlagPill";

export interface RedFlagRawApiData {
  data: RedFlagRawData;
}

// This is the data that comes back from the /riskselector API endpoint.
export interface RedFlagRawData extends Record<string, number | string | Record<string, number> | unknown[] | undefined> {
  name: string;
  most_recent_hit_datetime: string;
  adult_content_hit: number;
  offensive_hit: number;
  vpn_hit: number;
  suicidal_search_term?: number;
  search_indicators?: Record<string, number>;
  devices?: unknown[];
}

export interface SignatureKeyword {
  id: string;
  name: string;
  deprecated: boolean;
}

export const FormatRedFlagDataForPills = (data: RedFlagRawData, keywords: SignatureKeyword[], user: string): RedFlagPillProps[] => {
  const result: RedFlagPillProps[] = [];

  const defaultKeys: string[] = ["adult_content_hit", "offensive_hit", "vpn_hit", "suicidal_search_term"];

  for (const key of defaultKeys) {
    const value = data[key];
    if (value && typeof value === "number" && value > 0) {
      result.push({
        severity: getSeverity(value),
        text: `${value} ${getLabel(key, keywords)}`,
        link: getLink(key, user, keywords),
      });
    }
  }

  const devicesThreshold = 5;
  if (data.devices && data.devices.length > devicesThreshold) {
    const value = data.devices.length;
    result.push({
      severity: getSeverity(value),
      text: `${value} Devices`,
    });
  }

  if (data.search_indicators) {
    const indicators = data.search_indicators;
    Object.keys(indicators).forEach((key) => {
      const value = indicators[key];
      if (value && value > 0) {
        result.push({
          severity: getSeverity(value),
          text: `${value} ${getLabel(key, keywords)}`,
          link: getLink(key, user, keywords),
        });
      }
    });
  }

  return result;
};

export const getLabel = (id: string, keywords: SignatureKeyword[]): string => {
  const labelMap: Record<string, string> = {
    suicidal_search_term: "Suicide",
    adult_content_hit: "Adult Content",
    offensive_hit: "Offensive Content",
    vpn_hit: "VPN",
    devices: "Devices",
  };

  if (labelMap[id]) {
    return labelMap[id];
  }

  for (const keyword of keywords) {
    if (keyword.id === id) {
      return keyword.name + " Search";
    }
  }
  return id;
};

export const getLink = (id: string, user: string, keywords: SignatureKeyword[]): string | undefined => {
  switch (id) {
    case "adult_content_hit":
      return `/surfwize/reporting/type/sphirewall.application.porn/user/${user}?hits=true`;
    case "offensive_hit":
      return `/surfwize/reporting/type/sphirewall.application.offensive/user/${user}?hits=true`;
    case "vpn_hit":
      return `/surfwize/reporting/type/sphirewall.application.blocklist.proxies/user/${user}?hits=true`;
    case "suicidal_search_term":
      return `/cybersafety/search/user/${user}/suicide`;
    case "devices":
      return undefined;
    default:
      for (const keyword of keywords) {
        if (keyword.id === id) {
          return `/cybersafety/search/user/${user}/${keyword.name}`;
        }
      }
  }
  return undefined;
};

const getSeverity = (count: number): RedFlagSeverity => {
  const highSeverityThreshold = 5;
  const mediumSeverityThreshold = 2;
  if (count > highSeverityThreshold) {
    return "high";
  }
  if (count > mediumSeverityThreshold) {
    return "medium";
  }
  return "low";
};
