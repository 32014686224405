import { ReportingTopTypesData } from "../components/reporting/ReportingTopTypesHelper";
import Api from "../utils/Api";

export interface InfoBoxProps {
  type: infoBoxType;
  category: string;
  target?: string;
  signatureId?: string;
}

export const getInfoBoxProps = (data: ReportingTopTypesData, user?: string): InfoBoxProps => {
  const type = getInfoBoxType(data, user);
  return {
    type: type,
    category: getInfoBoxCategory(type, data),
    target: getInfoBoxTarget(type, data.domain, data["tag.string"] ?? ""),
    signatureId: getInfoBoxSignatureId(type, data.tag),
  };
};

export type infoBoxType = "category" | "website" | "signature" | "none";

export const getInfoBoxType = (data: ReportingTopTypesData, user?: string): infoBoxType => {
  if (user || !data.tag) {
    return "none";
  }

  const tagEqualsCategoryId = data.tag === data.categoryId;
  const tagEqualsSubCategoryId = data.tag === data.subCategoryId;
  const domainOrWebsiteExists = data.domain || data.website;

  if (tagEqualsCategoryId || tagEqualsSubCategoryId) {
    if (domainOrWebsiteExists && data["categoryId.string"]) {
      if ((tagEqualsCategoryId && data.categoryId) || (tagEqualsSubCategoryId && data.subCategoryId)) {
        return "category";
      }
      return "website";
    }
    return "none";
  }

  return "signature";
};

export const getInfoBoxCategory = (type: infoBoxType, data: ReportingTopTypesData): string => {
  if (type === "category") {
    if (data.tag === data.categoryId) {
      return data["categoryId.string"] ?? "";
    }
    if (data.tag === data.subCategoryId) {
      return data["subCategoryId.string"] ?? "";
    }
    return "";
  }
  if (type === "website") {
    return "Website";
  }
  return "";
};

export const getInfoBoxTarget = (type: infoBoxType, domain: string | undefined, tagString: string): string | undefined => {
  if (type === "category" || type === "website") {
    return domain;
  }
  return tagString;
};

export const getInfoBoxSignatureId = (type: infoBoxType, tag: string | undefined): string | undefined => {
  if (type === "signature") {
    return tag;
  }
  return undefined;
};

export const getHeaderString = (type: infoBoxType, category: string, target: string): string => {
  if (type === "signature") {
    return target;
  }
  return `${category} - ${target}`;
};

export interface InfoBoxSignatureData {
  description?: string;
  favicon_url?: string;
  url?: string;
}

interface signatureApiData {
  signature: InfoBoxSignatureData;
}
const isSignatureApiData = (data: unknown): data is signatureApiData => typeof data === "object" && data !== null && "signature" in data;

export const getSignatureData = async (signatureId: string): Promise<InfoBoxSignatureData> => {
  try {
    const response = (await Api.get_analyticsAsync(`/surfwize/signature/${signatureId}/details`)) as unknown;
    if (!isSignatureApiData(response)) {
      return {};
    }
    return response.signature;
  } catch (error) {
    return {};
  }
};
