import React from "react";
import { Box, Button, InlineNotification, Modal, Option, useToast, useTranslation } from "@familyzone/component-library";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";
import LayoutConfig from "../../utils/LayoutConfig";
import SessionStore from "../../stores/SessionStore";
import { isUserTeacher } from "../../utils/api/Users";

interface MasqueradeClasswizeSignInAsTeacherModalProps {
  teacherGroups: string[];
}

const MasqueradeClasswizeSignInAsTeacherModal: React.FC<MasqueradeClasswizeSignInAsTeacherModalProps> = ({ teacherGroups }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isTeacher, setIsTeacher] = React.useState(false);
  const [hasChosenUser, setHasChosenUser] = React.useState(false);

  const { errorToast } = useToast();

  const handleClickSubmit = (selected: Option | null) => {
    if (!selected) return;
    else window.open(masqueradeUrl(selected.value.toString()), "_blank");
  };

  const masqueradeUrl = (username: string) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return `${LayoutConfig.classwize_link()}/login/masquerade/callback?user=${username}&deviceid=${SessionStore.getSelectedDevice()}&token=${SessionStore.getToken()}`;
  };

  const validateIsTeacher = async (selected: Option | null) => {
    if (!selected) return;
    try {
      const userIsTeacher = await isUserTeacher(selected.value.toString(), teacherGroups);
      setIsTeacher(userIsTeacher);
      setHasChosenUser(true);
    } catch {
      errorToast({
        title: t("There was an error checking this user is a teacher."),
        description: t("Please try again later. If this keeps happening, please contact support."),
      });
      setIsTeacher(false);
      setHasChosenUser(false);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={() => setOpen(true)}>
        {t("Sign in as Teacher")}
      </Button>
      <Modal
        headerText={t("Sign in as Teacher")}
        size="md"
        isOpen={open}
        onClose={() => setOpen(false)}
        contentProps={{ style: { overflow: "visible" } }}
      >
        <Box mt="sp24">
          <UserSearchSelector
            placeholderText={t("Search for a user")}
            buttonText={t("Sign in")}
            handleChange={(selected) => void (async () => await validateIsTeacher(selected))()}
            handleSubmit={handleClickSubmit}
            submitDisabled={!isTeacher}
          />
          {hasChosenUser && !isTeacher && (
            <Box mt="sp16">
              <InlineNotification
                notificationTitle={t("Cannot sign in as this user.")}
                notificationDescription={t("The selected user is not a teacher of the selected teacher groups.")}
                status="error"
                isRemovable
              />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default MasqueradeClasswizeSignInAsTeacherModal;
