import React from "react";

import PropTypes from "prop-types";
import { ReportingUserTimelineNew } from "./ReportingUserTimelineNew";

/**
 * Date ranges for the ReportingUserTimelineNew has two behaviours:
 *    1. When `date` parameter is passed, the timeline is loaded from start of the day to the end of the day
 *    2. When `end_date` and `end_time` are passed, the timeline would load the 48H of data from the end time.
 *         This behaviour is only present if you open navigate to the user timeline from user dashboard
 */

export default class ReportingUserTimeline extends React.Component {
  render() {
    return (
      <ReportingUserTimelineNew
        user={this.props.params.user}
        date={this.props.location.query.date}
        end_date={this.props.location.query.end_date}
        end_time={this.props.location.query.end_time}
        filter_type={this.props.location.query.filter_type}
      />
    );
  }
}

ReportingUserTimeline.contextTypes = {
  router: PropTypes.object.isRequired,
};
