import React, { FunctionComponent, useState } from "react";
import { Icon, Modal, ModalBody, TableIconButton, Text, useTranslation } from "@familyzone/component-library";
import { Props as ButtonProps } from "@familyzone/component-library/src/components/Button";
import PropTypes from "prop-types";

interface Props extends Omit<ButtonProps, "variant" | "children"> {
  onClick: (e: React.SyntheticEvent) => void;
  isDeleting?: boolean;
  to?: string;
  disabled?: boolean;
  titleToDelete?: string; // The title will be Delete [titleToDelete]
  bodyToDelete?: string; // The modal body will ask if the user wants to permanently delete this [bodyToDelete]
  actionVerb?: string; // Manually set the action verb. Eg, Archive, Delete
}

export const DeleteButtonNew: FunctionComponent<Props> = (
  {
    onClick,
    isDeleting = false,
    to = undefined,
    disabled = false,
    titleToDelete = "Policy",
    bodyToDelete = "configuration",
    actionVerb = "Delete",
    ...props
  },
  context
) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOnOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handlePrimaryCTAClick = (e: React.SyntheticEvent) => {
    if (to !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      context?.router?.push(to);
    }
    handleOnClose();
    onClick(e);
  };

  const titleText = t(`${actionVerb} ${titleToDelete}`);
  const bodyText = t(`Are you sure you want to ${actionVerb.toLowerCase()} this ${bodyToDelete}?`);

  return (
    <>
      <TableIconButton
        icon={
          <Icon
            icon={`fa-trash-can${isDeleting ? " fa-spin" : ""}`}
            data-testid={`fa-trash-can${isDeleting ? " fa-spin" : ""}`}
            variant="solid"
            color="text.paragraph.light"
          />
        }
        onClick={handleOnOpen}
        aria-label="Delete"
        disabled={disabled}
        {...props}
      />
      <Modal
        isOpen={open}
        size="sm"
        onClose={handleOnClose}
        headerText={titleText}
        variant="danger"
        primaryCTALabel={titleText}
        onPrimaryCTAClick={handlePrimaryCTAClick}
        secondaryCTALabel="Cancel"
        onSecondaryCTAClick={handleOnClose}
      >
        <ModalBody>
          <Text mb="48px">{bodyText}</Text>
        </ModalBody>
      </Modal>
    </>
  );
};

DeleteButtonNew.contextTypes = {
  router: PropTypes.object,
};
