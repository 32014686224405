import React from "react";

interface Props {
  onChange: (ldappassword: string) => void;
  password?: string;
}

const LDAPServerPasswordInput: React.FC<Props> = ({ onChange, password }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="mui-textfield" style={{ display: "flex" }}>
      <input
        type={showPassword ? "text" : "password"}
        name="password"
        value={password}
        onChange={(e) => onChange(e.target.value)}
        data-cy="password"
        data-testid="password-input-ldap"
      />
      <button
        type="button"
        className="mui-btn"
        onClick={() => setShowPassword(!showPassword)}
        style={{ marginLeft: "10px", minWidth: "80px" }}
      >
        {showPassword ? "Hide" : "Show"}
      </button>
    </div>
  );
};

export default LDAPServerPasswordInput;
