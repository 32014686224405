import { FieldErrors, FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";
import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { Box } from "@familyzone/component-library";
import React from "react";
import { CheckBoxControl } from "./CheckboxControl";

type Props<T extends FieldValues> = {
  label: string;
  propertyName: keyof T;
  register: UseFormRegister<T>;

  inputPropertyName: keyof T;
  inputRegisterOptions: RegisterOptions<T>;
  checkboxRegisterOptions?: RegisterOptions<T>;

  errors: FieldErrors<T>;
  tooltip?: string;
  enabled: boolean;

  inputTestId?: string;
};

export const CheckBoxWithInputControl = <T extends FieldValues>({
  label,
  propertyName,
  register,
  errors,
  tooltip,
  enabled,
  inputPropertyName,
  inputRegisterOptions,
  inputTestId,
  checkboxRegisterOptions,
}: Props<T>): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: " column",
        gap: "8px",
      }}
    >
      <CheckBoxControl
        registerOptions={checkboxRegisterOptions}
        label={label}
        register={register}
        errors={errors}
        propertyName={propertyName}
        tooltip={tooltip}
      />
      {enabled && (
        <FormControl
          isInvalid={!!errors[inputPropertyName]}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            minHeight: "40px",
            fontSize: "14px",
            paddingLeft: "32px",
          }}
        >
          <Input data-testid={inputTestId} {...register(inputPropertyName as Path<T>, inputRegisterOptions)} />
          <FormErrorMessage>{errors[inputPropertyName] && errors[inputPropertyName]?.message}</FormErrorMessage>
        </FormControl>
      )}
    </Box>
  );
};
