import {
  Td,
  Tr,
  useTranslation,
  Text,
  Flex,
  Box,
  TableIconButton,
  Icon,
  Link,
  Button,
  useToast,
  Input,
  InlineNotification,
  Modal,
} from "@familyzone/component-library";
import isValidDomain from "is-valid-domain";
import { forEach } from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import ObjectActions from "../../../actions/ObjectActions";
import ObjectStore from "../../../stores/ObjectStore";
import { displayTypesArray, NamedEntry, ObjectPool } from "../../../types/Objects";
import { TableColumn } from "../../../types/table";
import { isValidIPAddress, isValidIPRange, isValidIPv4Subnet, isValidSingleMacAddress, isValidWebsite } from "../../../utils/Validation";
import { downcaseDomain, stripSchema } from "../../../utils/WebsiteUtil";
import TableBasedPage from "../../templates/TableBasedPage";
import AddNewObjectEntry from "./AddNewObjectEntry";
import DeleteObjectPoolsModal from "./DeleteObjectPoolsModal";

interface NamedEntryProps extends NamedEntry {
  startName?: string;
  startValue?: string;
}

interface CSVErrorProps {
  line: number;
  error: string;
  entry: string;
}

const EditObjectPools: React.FC = () => {
  const { errorToast } = useToast();
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [object, setObject] = useState<ObjectPool>();
  const [openNewEntry, setOpenNewEntry] = useState(false);
  const [editingEntry, setEditingEntry] = useState<NamedEntryProps | null>(null);
  const [openDeleteEntry, setOpenDeleteEntry] = useState(false);
  const [listEntries, setListEntries] = useState<NamedEntryProps[]>([]);
  const [CSVErrors, setCSVErrors] = useState<CSVErrorProps[]>([]);
  const [examples, setExamples] = useState<string[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const WEBSITE_TYPE = 2;

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config/device/dashboard", isActive: false },
    { title: t("Objects"), url: "/config/device/objects/pools", isActive: false },
    { title: t("Pools"), url: "/config/device/objects/pools", isActive: false },
  ];

  const params = window.location.href.split("/config/device/objects/pools/")?.[1]?.split("/");

  // https://tools.ietf.org/id/draft-liman-tld-names-00.html -- 2. Technical specification for Top Level Domain Labels
  const topLevelDomainRegex = /^[a-z][a-z0-9-]*[a-z0-9]$/i;

  /* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument */
  const onChange = async () => {
    setLoaded(false);
    if (ObjectStore.getLoaded()) {
      const result = await ObjectStore.getObject(params?.[0]);
      setObject({ ...result, displayType: displayTypesArray.find((displayType) => displayType[0] === result?.type)?.[1].toString() });

      const new_list_entries: NamedEntryProps[] = [];
      if (result !== undefined) {
        if (result.named_entries === undefined) {
          forEach(object?.named_entries, (value: NamedEntry, index: number) => {
            new_list_entries.push({
              id: index,
              name: value?.name ? value.name : "",
              desc: value?.desc ? value.desc : "",
              value: value?.value ? value.value : "",
              click_action: "edit",
              invalidValueMsg: null,
            });
          });
        } else {
          forEach(result.named_entries, (value: NamedEntry, index: number) => {
            new_list_entries.push({
              id: index,
              name: value?.name ? value.name : "",
              desc: value?.desc ? value.desc : "",
              value: value?.value ? value.value : "",
              click_action: "edit",
              invalidValueMsg: null,
            });
          });
        }
        setListEntries(new_list_entries);
      }
    }
    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    ObjectStore.listen(onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
    setLoaded(true);
    return () => {
      ObjectStore.unlisten(onChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* eslint-enable */

  const handleOpenEditEntry = (entry: NamedEntryProps) => {
    setOpenNewEntry(true);
    if (!entry) {
      return;
    }
    setEditingEntry({ ...entry, click_action: "edit", startName: entry.name, startValue: entry.value });
  };

  const handleCloseEditEntry = () => {
    setOpenNewEntry(false);
    setEditingEntry(null);
  };

  const handleOpenAddEntry = () => {
    setEditingEntry(null);
    setOpenNewEntry(true);
  };

  const handleOpenDeleteEntry = (entry: NamedEntryProps) => {
    setEditingEntry(entry);
    setOpenDeleteEntry(true);
  };

  const handleCloseDeleteEntry = () => {
    setOpenDeleteEntry(false);
    setEditingEntry(null);
  };

  const validateIPv4Subnet = (value: string) => {
    let errorMsg = null;

    if (value.includes(":")) {
      errorMsg = "The IPv4 subnet entry is invalid. Please do not use ports in the IP subnet.";
      return errorMsg;
    }
    if (value.match(/[^0-9./]/g)) {
      errorMsg = "The IPv4 subnet entry is invalid. Please use only numbers periods or forward slashes.";
      return errorMsg;
    }
    if (!isValidIPv4Subnet(value)) {
      errorMsg = "The IPv4 subnet entry is invalid. Please use the following format 192.168.1.1/32 or 192.168.0.1/255.255.255.255.";
    }
    return errorMsg;
  };

  const validateIpRange = (value: string) => {
    let errorMsg = null;
    // if value outside standard range
    if (value.includes(":")) {
      errorMsg = "The IP range entry is invalid. Please do not use ports in the IP range.";
      return errorMsg;
    }
    if (value.match(/[^0-9.-]/g)) {
      errorMsg = "The IP range entry is invalid. Please use only numbers periods or dashes.";
      return errorMsg;
    }
    if (!isValidIPRange(value) && !isValidIPAddress(value)) {
      errorMsg = "The IP range entry is invalid. Use the following range format 192.168.0.1-192.168.0.255 or a single IP address.";
    }
    return errorMsg;
  };

  const validateMacAddress = (value: string) => {
    let errorMsg = null;
    if (value.match(/[^0-9a-f:]/gi)) {
      errorMsg = "The MAC address entry is invalid. Please use only numbers a b c d e f and colons.";
      return errorMsg;
    }

    if (value.match(/.{1,2}(?=:)/g)?.some((value) => value.length !== 2)) {
      errorMsg =
        "The MAC address entry is invalid. You cannot have more the two characters between colons - for example: a1:b2:c3:d4:e5:f6.";
      return errorMsg;
    }

    if (!isValidSingleMacAddress(value)) {
      errorMsg = "The MAC address entry is invalid. Use the following format a1:b2:c3:d4:e5:f6.";
    }
    return errorMsg;
  };

  const validateWebsite = (value: string) => {
    let errorMsg = null;

    if (isValidIPAddress(value)) {
      errorMsg = "This entry is invalid. Please do not use IP addresses.";
      return errorMsg;
    }

    if (!topLevelDomainRegex.test(value) && !isValidWebsite(value)) {
      errorMsg = "This entry does not meet the requirements for a valid website - for example: notpurple.com example.com.";
    }
    return errorMsg;
  };

  const validateDomain = (value: string) => {
    let errorMsg = null;

    if (value.includes("/")) {
      errorMsg = "The domain entry is invalid. The domain cannot include a path (/).";
      return errorMsg;
    }

    if (value.includes("?")) {
      errorMsg = "The domain entry is invalid. The domain cannot include a query string (?).";
      return errorMsg;
    }

    if (!isValidDomain(value)) {
      errorMsg = "The domain entry is invalid. Only include the main part of the URL - for example: notpurple.com example.com.";
    }
    return errorMsg;
  };

  const noValidation = () => null; // null means no error message

  const validate = (value: string) => {
    switch (object?.type) {
      case displayTypesArray[0][0]:
        return validateIpRange(value);
      case displayTypesArray[1][0]:
        return validateIPv4Subnet(value);
      case displayTypesArray[2][0]:
        return validateWebsite(value);
      case displayTypesArray[3][0]:
        return validateMacAddress(value);
      case displayTypesArray[4][0]:
        return noValidation();
      case displayTypesArray[5][0]:
        return validateDomain(value);
      default:
        return noValidation();
    }
  };

  const handleSubmitEntry = (entry: NamedEntry) => {
    if (!object) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const strippedValue: string = stripSchema(entry.value);
    setCSVErrors([]);
    const error = validate(strippedValue);
    if (error) {
      errorToast({ title: error, position: "top", duration: 6000 });
      return;
    }
    // ignore and throw error if entry already exists
    if (listEntries.find((value) => value.value === strippedValue && entry.id !== value.id)) {
      errorToast({ title: "Entry already exists", position: "top", duration: 6000 });
      return;
    }
    if (!entry.name || shouldAutofillName(entry)) {
      autoFillName(entry);
    }
    if (!object?.named_entries) {
      object.named_entries = [];
    }
    if (entry.click_action === "edit") {
      listEntries.forEach((named_entry) => {
        if (named_entry.id === entry.id) {
          named_entry.name = entry.name;
          named_entry.desc = entry.desc;
          named_entry.value = strippedValue;
        }
      });
    } else {
      const newEntry = { ...entry, value: strippedValue };
      listEntries.push(newEntry);
    }
    setEditingEntry(null);
    handleCloseEditEntry();
    updatePersistedObject(object);
    void onChange();
  };

  const shouldAutofillName = (entry: NamedEntryProps) => {
    return entry.startName === entry.startValue && entry.name === entry.startName;
  };

  const autoFillName = (entry: NamedEntry) => {
    entry.name = entry.value;
  };

  const handleDelete = () => {
    setCSVErrors([]);
    if (!object) {
      return;
    }
    object.entries = [];
    object.named_entries = listEntries.filter((value: NamedEntry) => value.id !== editingEntry?.id);

    if (object.type === WEBSITE_TYPE) {
      object.named_entries = object.named_entries.map((value) => ({
        ...value,
        value: downcaseDomain(value.value),
      }));
    }
    setLoaded(false);
    object.named_entries.forEach((entry: NamedEntry) => {
      delete entry.id;
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ObjectActions.saveObject(object);
    handleCloseDeleteEntry();
    void onChange();
  };

  const updatePersistedObject = (object: ObjectPool) => {
    object.entries = []; // convert to new method
    object.named_entries = listEntries;
    if (object.type === WEBSITE_TYPE) {
      object.named_entries = object.named_entries.map((value) => ({
        ...value,
        value: downcaseDomain(value.value),
      }));
    }
    setLoaded(false);
    object.named_entries.forEach((entry: NamedEntry) => {
      delete entry.id;
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ObjectActions.saveObject(object);
  };

  const columns: TableColumn[] = [
    {
      headerText: t("Name"),
      columnName: "name",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Description"),
      columnName: "desc",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Entry"),
      columnName: "value",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
      alignment: "right",
    },
  ];

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 0) {
      return;
    }

    if (!e.target.files) {
      return;
    }

    setLoaded(false);

    const valid = e.target.files?.[0].type === "text/csv";
    if (!valid) {
      errorToast({ title: "Invalid file type", position: "top", duration: 6000 });
      setLoaded(true);
      return;
    }

    setCSVErrors([]);

    const new_list_entries: NamedEntryProps[] = [];
    const lineErrors: CSVErrorProps[] = [];
    const reader = new FileReader();

    reader.readAsText(e.target.files[0]);
    reader.onload = () => {
      const csv = reader.result?.toString();
      const lines = csv?.split("\n");
      if (!lines) {
        setLoaded(true);
        return;
      }
      if (lines.length > 10000) {
        errorToast({ title: "CSV file must not be more than 10000 lines", position: "top", duration: 6000 });
        setLoaded(true);
        return;
      }

      lines?.forEach((line, index) => {
        line = line.trim();
        const values = line.split(",");
        if (values.length === 1) {
          if (values[0] === "") {
            return;
          } else {
            lineErrors.push({
              line: index + 1,
              error: "CSV Format does not match. Please follow the format in the example template",
              entry: values[0],
            });
          }
        }
        if (values.length === 3) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const preparedLine: string = stripSchema(values[2]);

          if (values[2] === "") {
            return;
          }

          if (values[0] === "Name" && values[1] === "Description" && values[2] === "Entry") {
            return;
          }

          const error = validate(preparedLine);
          if (error) {
            lineErrors.push({
              line: index + 1,
              error: error,
              entry: values[2],
            });
            return;
          }
          if (new_list_entries.find((value) => value.value === preparedLine)) {
            lineErrors.push({
              line: index + 1,
              error: "Entry already exists",
              entry: values[2],
            });
            return;
          }
          if (values[0] === "" || values[1] === "") {
            new_list_entries.push({
              name: values[0] === "" ? preparedLine : values[0],
              desc: values[1] === "" ? (values[1] !== "" ? values[1] : "") : values[1],
              value: preparedLine,
              click_action: "edit",
              invalidValueMsg: null,
              id: index,
            });
          } else {
            new_list_entries.push({
              name: values[0],
              desc: values[1],
              value: preparedLine,
              click_action: "edit",
              invalidValueMsg: null,
              id: index,
            });
          }
        } else {
          lineErrors.push({
            line: index + 1,
            error: "CSV Format does not match. Please follow the format in the example template",
            entry: values[0],
          });
        }
      });
      if (lineErrors.length > 0) {
        setCSVErrors(lineErrors);
        errorToast({ title: "Errors found in CSV", position: "top", duration: 6000 });
        setOpenErrorDialog(true);
        setLoaded(true);
        return;
      }
      setListEntries(new_list_entries);
      if (!object) {
        setLoaded(true);
        return;
      }
      if (new_list_entries.length === 0) {
        setLoaded(true);
        return;
      }

      const newObject = object;
      newObject.named_entries = new_list_entries;

      if (newObject.type === WEBSITE_TYPE) {
        newObject.named_entries = newObject.named_entries.map((value) => ({
          ...value,
          value: downcaseDomain(value.value),
        }));
      }

      setLoaded(false);
      newObject.named_entries.forEach((entry: NamedEntry) => {
        delete entry.id;
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ObjectActions.saveObject(newObject);
      void onChange();
      setLoaded(true);
    };
  };

  const handleOpenErrorDialog = () => {
    setOpenErrorDialog(true);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleCloseNotice = () => {
    setCSVErrors([]);
  };

  useEffect(() => {
    let examples: string[] = [];
    switch (object?.type) {
      case displayTypesArray[0][0]:
        examples = ["192.168.0.1-192.168.0.255", "127.0.0.0-127.255.255.255", "172.16.0.0-172.31.255.255"];
        break;
      case displayTypesArray[1][0]:
        examples = ["192.168.1.0/255.255.255.0", "10.1.0.0/255.255.0.0", "93.184.216.34/32"];
        break;
      case WEBSITE_TYPE:
        examples = ["notpurple.com", "example.com"];
        break;
      case displayTypesArray[3][0]:
        examples = ["CE:B2:A4:C8:F0:EA", "6B:91:38:EB:8A:BF", "01:DB:91:49:09:C1"];
        break;
      case displayTypesArray[4][0]:
        examples = ["web games", "inappropriate content", "free movies online"];
        break;
      case displayTypesArray[5][0]:
        examples = ["notpurple.com", "example.com"];
        break;
      default:
        break;
    }
    setExamples(examples);
  }, [object?.type]);

  const exampleCSV = () => {
    const headers = ["Name", "Description", "Entry"];
    if (object?.type === undefined) {
      return;
    }
    // convert examples into csv outputs
    const notices = `optional column - leave blank if not required,optional column - leave blank if not required,required (delete this line before submitting)`;
    const exampleEntries = examples.map((example) => `,,${example}`).join("\n");
    const csvData = [headers, notices, exampleEntries].join("\n");
    const element = document.createElement("a");
    const file = new Blob([csvData], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "example.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const tableDataMap = (row: NamedEntry, index: number): JSX.Element => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{row.name}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{row.desc}</Text>
      </Td>
      <Td>
        {object?.type === WEBSITE_TYPE || object?.displayType === displayTypesArray[5][1] ? (
          <Link
            href={`http://${row.value}`}
            target="_blank"
            rel="noopener noreferrer"
            title={`Open ${row.value} in new tab`}
            data-testid="websiteLink"
          >
            <Text color="#094C99" fontSize="md" minWidth="200px">
              {row.value}
            </Text>
          </Link>
        ) : (
          <Text fontSize="md" minWidth="200px">
            {row.value}
          </Text>
        )}
      </Td>
      <Td>
        <Flex justifyContent="end">
          <Box mr="sp8">
            <TableIconButton
              onClick={() => handleOpenEditEntry(row)}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              aria-label={"Edit association"}
              data-testId={row.id ? `${row.id}-edit` : "edit"}
            />
          </Box>
          <TableIconButton
            icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
            aria-label={"Delete association"}
            onClick={() => handleOpenDeleteEntry(row)}
            data-testId={row.id ? `${row.id}-delete` : "delete"}
          />
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <>
      <TableBasedPage
        breadcrumbs={breadcrumbs}
        title={t(object?.name ? (object?.displayType ? `${object.name} - ${object.displayType}` : "Pools") : "Pools")}
        loaded={loaded}
        columns={columns}
        data={listEntries}
        tableDataMap={tableDataMap}
        children={
          <>
            {CSVErrors.length > 0 ? (
              <Flex pt="sp24" pr="sp24" pl="sp24">
                <InlineNotification
                  notificationTitle="CSV Import Error"
                  isRemovable={true}
                  onCloseButtonClick={handleCloseNotice}
                  status="error"
                  notificationDescription={
                    <Flex direction="column">
                      <Text>
                        There was a problem(s) with your CSV import, {CSVErrors.length} identified. Please resolve these errors and try
                        again. Click <Link onClick={handleOpenErrorDialog}>here</Link> to view the errors.
                      </Text>
                    </Flex>
                  }
                />
              </Flex>
            ) : undefined}

            <Flex pt="sp24" pr="sp24" pl="sp24">
              <InlineNotification
                status="warning"
                notificationDescription="Warning - Upload CSV will overwrite all existing entries. This cannot be undone."
              />
            </Flex>
          </>
        }
        childrenInTableHeader={
          <Flex justifyContent="end">
            {examples.length > 0 && (
              <Box mr="sp8">
                <Button variant="outline" onClick={exampleCSV}>
                  {t("Download CSV Template")}
                </Button>
              </Box>
            )}

            <Box mr="sp8">
              <Button variant="primary" disabled={!loaded}>
                {t("Upload CSV")}
                <Input
                  type="file"
                  id="file"
                  name="file"
                  accept=".csv, text"
                  onChange={handleFileUpload}
                  aria-label="Upload CSV"
                  data-testid="uploadCSV"
                  aria-hidden={true}
                  opacity="0"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  padding="0"
                  border="0"
                />
              </Button>
            </Box>

            <Box>
              <Button onClick={handleOpenAddEntry} variant="primary" data-testId="Add-new-entry">
                {t("Add New Entry")}
              </Button>
            </Box>
          </Flex>
        }
      />
      <AddNewObjectEntry
        open={openNewEntry}
        onClose={handleCloseEditEntry}
        loaded={loaded}
        existingEntry={editingEntry}
        onSave={handleSubmitEntry}
      />
      <DeleteObjectPoolsModal
        open={openDeleteEntry}
        onClose={handleCloseDeleteEntry}
        loaded={loaded}
        type="entry"
        onDelete={handleDelete}
        id={editingEntry?.id?.toString()}
      />
      <ErrorModal open={openErrorDialog} onClose={handleCloseErrorDialog} errors={CSVErrors} />
    </>
  );
};

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  errors: CSVErrorProps[];
}

const ErrorModal = ({ open, onClose, errors }: ErrorModalProps) => {
  const { t } = useTranslation();

  const handleDownloadErrors = () => {
    const headers = ["Line", "Error Message", "Entry"];
    const csv = errors.map((error) => `${error.line},${error.error},${error.entry}`).join("\n");
    const csvData = [headers, csv].join("\n");
    const element = document.createElement("a");
    const file = new Blob([csvData], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "errors.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Modal isOpen={open} onClose={onClose} size="md" headerText={t("CSV Import Errors")} contentProps={{ style: { maxHeight: "700px" } }}>
      <Box>
        <Flex direction="column">
          <Flex pt="sp16">
            <Text>{t(`There was a problem(s) with your CSV import, ${errors.length} identified:`)}</Text>
          </Flex>
          <Text sx={errors.length > 8 ? { overflowY: "scroll", maxHeight: "500px" } : { maxHeight: "500px" }}>
            {errors.map((error) => (
              <Flex pt="6" pb="6">
                <Text>{t(`Line ${error.line}: ${error.entry} - ${error.error}`)}</Text>
              </Flex>
            ))}
          </Text>
        </Flex>
        <Flex direction="row" justifyContent="space-between" pt="sp16">
          <Box mr="sp8">
            <Button variant="danger" onClick={handleDownloadErrors}>
              {t("Export Errors")}
            </Button>
          </Box>
          <Flex>
            <Button onClick={onClose} variant="primary" aria-label="close">
              {t("Close")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};

export default EditObjectPools;
