import React from "react";

import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import SearchableSelect from "../../modules/SearchableSelect";
import ListSelector from "../../modules/ListSelector";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";

import Api from "../../utils/Api";

import SessionStore from "../../stores/SessionStore";
import SessionActions from "../../actions/SessionActions";

import InterfaceStore from "../../stores/InterfaceStore";
import InterfaceActions from "../../actions/InterfaceActions";

export default class HighAvailability extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      enabled: false,
      changes: false,
      saving: false,
      loaded: false,
      devices: [],
      interfaces: [],
      this_device_id: "",
      peer_id: "",
      peer_ip: "",
      failover_duration: "",
      monitoring_interface: "",
      failover_interfaces: [],
      is_primary: false,
    };
  }

  componentDidMount() {
    this.handle_load();
    SessionStore.listen(this.onChange);
    InterfaceStore.listen(this.onChange);
    setTimeout(() => {
      SessionActions.fetchAvailableDevices();
      InterfaceActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
    InterfaceStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      devices: SessionStore.getAvailableDevices(),
      interfaces: InterfaceStore.getInterfaces(),
    });
    if (this.state.interfaces.length) {
      this.handle_HaStatus();
    }
  };

  handle_Submit = (event) => {
    event.preventDefault();

    let settings = {
      enabled: this.state.enabled,
      peer_id: this.state.peer_id,
      peer_ip: this.state.peer_ip,
      failover_duration: parseInt(this.state.failover_duration, 10),
      monitoring_interface: this.state.monitoring_interface,
      failover_interfaces: this.state.failover_interfaces,
    };

    this.handle__SaveObject(settings);
  };

  handle__SaveObject = (settings) => {
    let self = this;
    self.setState({ changes: true, saving: true });

    Api.post(
      "/config/ajax/highavailability",
      settings,
      function (e) {
        self.handle_load();
        self.setState({
          changes: false,
          saving: false,
        });
      },
      function (error) {
        console.log(error);
        self.setState({ saving: false });
      }
    );
  };

  handle_changePeerDevice = (event) => {
    this.notifyChanged();
    this.setState({ peer_id: event.value });
  };

  handle_changePeerIP = (event) => {
    this.notifyChanged();
    this.setState({ peer_ip: event.target.value });
  };

  handle_failoverDuration = (event) => {
    this.notifyChanged();
    this.setState({ failover_duration: event.target.value });
  };

  handle_changeMonitoringInterface = (event) => {
    this.notifyChanged();
    this.setState({ monitoring_interface: event.value });
  };

  handle_ChangeEnabled = (event) => {
    this.notifyChanged();
    this.setState({ enabled: event.target.checked });
  };

  handle_HaStatus = () => {
    let self = this;
    if (self.state.enabled) {
      let primary = true;
      for (let i of self.state.interfaces) {
        if (self.state.failover_interfaces.includes(i["interface"]) && i["state"] === false) {
          primary = false;
          break;
        }
      }
      self.setState({ is_primary: primary });
    }
  };

  handle_load = () => {
    let self = this;
    Api.get(
      "/config/ajax/highavailability",
      function (result) {
        let data = result["data"];

        if ("enabled" in data) {
          self.setState({ enabled: data["enabled"] });
        }
        if ("peer_id" in data) {
          self.setState({ peer_id: data["peer_id"] });
        }
        if ("peer_ip" in data) {
          self.setState({ peer_ip: data["peer_ip"] });
        }
        if ("failover_duration" in data) {
          self.setState({ failover_duration: data["failover_duration"] });
        }
        if ("monitoring_interface" in data) {
          self.setState({ monitoring_interface: data["monitoring_interface"] });
        }
        if ("failover_interfaces" in data) {
          self.setState({ failover_interfaces: data["failover_interfaces"] });
        }

        self.setState({ loaded: true });
      },
      function (exception) {
        self.setState({ loaded: true });
      }
    );

    Api.get("/managedevice/ajax/device", function (result) {
      let data = result["data"];
      self.setState({ this_device_id: data["deviceid"] });
    });
  };

  addFailoverInterface = (i) => {
    let failover_interfaces = this.state.failover_interfaces;
    failover_interfaces.push(i);
    this.setState({ failover_interfaces: failover_interfaces });
  };

  removeFailoverInterface = (i) => {
    let failover_interfaces = this.state.failover_interfaces;
    let ret = [];

    for (let x of failover_interfaces) {
      if (x === i) {
        continue;
      }

      ret.push(x);
    }

    failover_interfaces = ret;
    this.setState({ failover_interfaces: failover_interfaces });
  };

  notifyChanged = () => {
    this.setState({ changes: true });
  };

  get_DeviceSelectionMap() {
    let ret = [];
    for (let device of this.state.devices) {
      if (this.state.this_device_id !== device.deviceid) {
        ret.push([device.deviceid, device.device.user_defined_name + " (" + device.deviceid + ")"]);
      }
    }
    return ret;
  }

  get_InterfaceSelectionMap() {
    let ret = [];
    for (let i of this.state.interfaces) {
      ret.push([i.interface, i.interface]);
    }
    return ret;
  }

  get_InterfaceSelectionList() {
    let ret = [];
    for (let i of this.state.interfaces) {
      ret.push(i.interface);
    }
    return ret;
  }

  render__HaStatus = () => {
    if (this.state.is_primary) {
      return <span className="filtering-pill sticky-pill"> Primary </span>;
    }
  };

  render__RequiredOptions = () => {
    return (
      <div className="formgroup-element">
        <div className="formgroup-element-title">Enabled</div>
        <div className="formgroup-element-fields">
          <div className="mui-checkbox">
            <input type="checkbox" checked={this.state.enabled} onChange={this.handle_ChangeEnabled} />
          </div>
        </div>
      </div>
    );
  };

  render__contents = () => {
    if (this.state.enabled) {
      return (
        <div>
          <div className="formgroup">
            <div className="formgroup_title">
              <div className="formgroup_title_title">Peer Configuration</div>
            </div>
            <div className="formgroup-content">
              <div className="formgroup-element">
                <div className="formgroup-element-title">Peer</div>
                <div className="formgroup-element-fields">
                  <SearchableSelect
                    clearable={false}
                    value={this.state.peer_id}
                    onChange={this.handle_changePeerDevice}
                    valueDataMap={this.get_DeviceSelectionMap()}
                  />
                </div>
              </div>

              <div className="formgroup-element">
                <div className="formgroup-element-title">Peer Monitoring IP Address</div>
                <div className="formgroup-element-fields">
                  <div className="mui-textfield">
                    <input type="text" value={this.state.peer_ip} onChange={this.handle_changePeerIP} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="formgroup">
            <div className="formgroup_title">
              <div className="formgroup_title_title">Failover Configuration</div>
            </div>
            <div className="formgroup-content">
              <div className="formgroup-element">
                <div className="formgroup-element-title">Failover Duration (seconds)</div>
                <div className="formgroup-element-fields">
                  <div className="mui-textfield">
                    <input type="text" value={this.state.failover_duration} onChange={this.handle_failoverDuration} />
                  </div>
                </div>
              </div>

              <div className="formgroup-element">
                <div className="formgroup-element-title">Monitoring Interface</div>
                <div className="formgroup-element-fields">
                  <SearchableSelect
                    clearable={false}
                    value={this.state.monitoring_interface}
                    onChange={this.handle_changeMonitoringInterface}
                    valueDataMap={this.get_InterfaceSelectionMap()}
                  />
                </div>
              </div>

              <div className="formgroup-element">
                <div className="formgroup-element-title">Failover Interfaces</div>
                <div className="formgroup-element-fields">
                  <ListSelector
                    available={this.get_InterfaceSelectionList()}
                    selected={this.state.failover_interfaces}
                    addElement={this.addFailoverInterface}
                    removeElement={this.removeFailoverInterface}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            High Availability
            <DumbBusyIndicator loaded={this.state.loaded} />
            {this.render__HaStatus()}
          </LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">{this.render__RequiredOptions()}</div>
              </div>
              {this.render__contents()}
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton onClick={this.handle_Submit} changes={this.state.changes} saving={this.state.saving} />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
} // Class HighAvailability
