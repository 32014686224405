export function parseEnvVarToStringArray(envVar: string | undefined): string[] {
  if (typeof envVar !== "string") {
    return [];
  }

  try {
    const parsedArray = JSON.parse(envVar) as Array<string | number | boolean | null>;
    if (!Array.isArray(parsedArray)) {
      return [];
    }
    return parsedArray.map((item) => {
      if (item === null) return "null";
      return String(item);
    });
  } catch (error) {
    return [];
  }
}
