import { Box } from "@familyzone/component-library";

import Link from "./Link";

const PrivacyPolicy = () => {
  return (
    <Box fontFamily="links">
      <Link color="text.link.hover" fontSize="sm" fontWeight="400" to="https://qoria.com/privacy" target="_blank" cursor="pointer">
        Privacy Policy
      </Link>
    </Box>
  );
};

export default PrivacyPolicy;
