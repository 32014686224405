import React from "react";
import moment from "moment";

import SessionStore from "../../../stores/SessionStore";

import FlexibleTable from "../../../modules/FlexibleTable";
import ModalWindow from "../../../modules/ModalWindow";
import TableCentricPageButton from "../../../modules/TableCentricPageButton";
import Separator from "../../../modules/Separator";

import TableLeftPanel from "../../../utils/TableLeftPanel";
import TableCentricPage from "../../../modules/TableCentricPage";

import EditButton from "../../../modules/EditButton";
import DeleteButton from "../../../modules/DeleteButton";
import SearchableSelect from "../../../modules/SearchableSelect";

import "../../../../css/filtering.css";
import Api from "../../../utils/Api";
import { ABTester, Box, Link, Text } from "@familyzone/component-library";
import LdapServersNew from "./LdapServersNew";
import LDAPServerPasswordInput from "./../LDAPServerPasswordInput";
import { getEndpoint } from "../interfaces/GetSyncExecutions";
import { formatString } from "./SyncStatus";

class AddEditRule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ldap: this.props.ldap,
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      ldap: nextprops.ldap,
    });
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeSync = (event) => {
    const ldap = this.state.ldap;
    ldap.sync_enabled = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeSyncOUs = (event) => {
    const ldap = this.state.ldap;
    ldap.sync_ous = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeSyncByDN = (event) => {
    const ldap = this.state.ldap;
    ldap.sync_by_dn = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeEnabled = (event) => {
    const ldap = this.state.ldap;
    ldap.enabled = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeAuthenticateUserUsingDn = (event) => {
    const ldap = this.state.ldap;
    ldap.authenticateuserusingsupplieddn = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeMode = (event) => {
    const ldap = this.state.ldap;
    ldap.type = event.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeProtocol = (event) => {
    const ldap = this.state.ldap;
    ldap.protocol = event.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUserOwnedGroupMembership = (event) => {
    const ldap = this.state.ldap;
    ldap.userownedgroupmembership = event.target.checked;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUserNameAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.usernameattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeManagerAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.managerattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeEmailAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.emailattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUserBase = (event) => {
    const ldap = this.state.ldap;
    ldap.userbase = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUserGroupAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.usergroupattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUserDnAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.userdnattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeGroupDnAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.groupdnattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeOuDnAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.oudnattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeOuNameAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.ounameattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeGroupBase = (event) => {
    const ldap = this.state.ldap;
    ldap.groupbase = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeGroupNameAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.groupnameattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeGroupMemberNameAttribute = (event) => {
    const ldap = this.state.ldap;
    ldap.groupmembernameattribute = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeHostname = (event) => {
    const ldap = this.state.ldap;
    ldap.hostname = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangePort = (event) => {
    const ldap = this.state.ldap;
    ldap.port = parseInt(event.target.value);
    this.setState({ ldap: ldap });
  };

  handle_ChangeBaseDN = (event) => {
    const ldap = this.state.ldap;
    ldap.basedn = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangeUsername = (event) => {
    const ldap = this.state.ldap;
    ldap.ldapusername = event.target.value;
    this.setState({ ldap: ldap });
  };

  handle_ChangePassword = (password) => {
    const ldap = this.state.ldap;
    ldap.ldappassword = password;
    this.setState({ ldap: ldap });
  };

  handle_ChangeSearchQuery = (event) => {
    let value = event.target.value ? event.target.value : "(objectClass=*)";
    // eslint-disable-next-line no-sequences
    this.setState((state) => ((state.ldap.search_query = value), state));
  };

  handle_Submit = () => {
    this.props.handleSave(this.state.ldap);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button
        type="submit"
        className="mui-btn mui-btn--raised"
        disabled={
          !this.state.ldap.hostname ||
          !this.state.ldap.port ||
          !this.state.ldap.ldapusername ||
          !this.state.ldap.ldappassword ||
          !this.state.ldap.basedn
        }
        onClick={this.handle_Submit}
      >
        Save
      </button>
    );
  };

  render__RequiredOptions = () => {
    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Enabled</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.ldap.enabled} onChange={this.handle_ChangeEnabled} data-cy="enabled" />
            </div>
          </div>
        </div>

        {this.render_GeneralOptions()}
      </div>
    );
  };

  render_GeneralOptions = () => {
    if (!this.state.ldap.enabled) {
      return <div />;
    }

    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title">Sync Enabled</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.ldap.sync_enabled} onChange={this.handle_ChangeSync} data-cy="syncEnabled" />
            </div>
          </div>
        </div>

        <div className="formgroup-element">
          <div className="formgroup-element-title">Sync Organization Units</div>
          <div className="formgroup-element-fields">
            <div className="mui-checkbox">
              <input type="checkbox" checked={this.state.ldap.sync_ous} onChange={this.handle_ChangeSyncOUs} data-cy="syncOrgUnits" />
            </div>
          </div>
        </div>

        <div className="formgroup-element-title">Server Type</div>
        <div className="mui-select">
          <SearchableSelect
            value={this.state.ldap.type}
            clearable={false}
            className={"server-type-search"}
            onChange={this.handle_ChangeMode}
            valueDataMap={[
              ["ad", "Active Directory"],
              ["ol", "OpenLdap"],
              ["ed", "Novell eDirectory"],
              ["custom", "Manual"],
            ]}
            data-cy="serverType"
          ></SearchableSelect>
        </div>

        <div className="formgroup-element-title">Protocol</div>
        <div className="mui-select">
          <SearchableSelect
            value={this.state.ldap.protocol}
            clearable={false}
            className={"server-type-search"}
            onChange={this.handle_ChangeProtocol}
            valueDataMap={[
              ["ldap", "ldap"],
              ["ldaps", "ldaps"],
            ]}
            data-cy="protocol"
          ></SearchableSelect>
        </div>

        <div className="formgroup-element-title">Server Hostname</div>
        <div className="mui-textfield">
          <input type="text" value={this.state.ldap.hostname} onChange={this.handle_ChangeHostname} data-cy="serverHostname" />
        </div>

        <div className="formgroup-element-title">Server Port</div>
        <div className="mui-textfield">
          <input type="number" value={this.state.ldap.port} onChange={this.handle_ChangePort} data-cy="serverPort" />
        </div>

        <div className="formgroup-element-title">Base DN</div>
        <div className="mui-textfield">
          <input type="text" value={this.state.ldap.basedn} onChange={this.handle_ChangeBaseDN} data-cy="baseDN" />
        </div>

        <div className="formgroup-element-title">Username</div>
        <div className="mui-textfield">
          <input type="text" value={this.state.ldap.ldapusername} onChange={this.handle_ChangeUsername} data-cy="username" />
        </div>

        <div className="formgroup-element-title">Password</div>
        <LDAPServerPasswordInput password={this.state.ldap.ldappassword} onChange={this.handle_ChangePassword} data-cy="password" />

        <div className="formgroup-element-title">Search Query</div>
        <div className="mui-textfield">
          <input
            type="text"
            value={this.state.ldap.search_query || "(objectClass=*)"}
            onChange={this.handle_ChangeSearchQuery}
            data-cy="searchQuery"
          />
        </div>

        <div className="mui-checkbox">
          <em>
            <div>Syncing by DN allows you to sync multiple groups with the same name.</div>
            <div>Changing this can invalidate existing filtering rules.</div>
          </em>
          <input type="checkbox" checked={this.state.ldap.sync_by_dn} onChange={this.handle_ChangeSyncByDN} data-cy="syncByDN" />
          <label>Sync By DN</label>
        </div>
      </div>
    );
  };

  render__ManualOptions = () => {
    if (this.state.ldap.type === "custom") {
      return (
        <div>
          <div className="mui-textfield">
            <input
              type="checkbox"
              checked={this.state.ldap.userownedgroupmembership}
              onChange={this.handle_ChangeUserOwnedGroupMembership}
            />
            <label>User Owned Group Membership</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.usernameattribute} onChange={this.handle_ChangeUserNameAttribute} />
            <label>Username Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.managerattribute} onChange={this.handle_ChangeManagerAttribute} />
            <label>Manager Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.emailattribute} onChange={this.handle_ChangeEmailAttribute} />
            <label>Email Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.userbase} onChange={this.handle_ChangeUserBase} />
            <label>User Base</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.usergroupattribute} onChange={this.handle_ChangeUserGroupAttribute} />
            <label>User Group Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.userdnattribute} onChange={this.handle_ChangeUserDnAttribute} />
            <label>User DN Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.groupdnattribute} onChange={this.handle_ChangeGroupDnAttribute} />
            <label>Group DN Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.oudnattribute} onChange={this.handle_ChangeOuDnAttribute} />
            <label>OU DN Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.ounameattribute} onChange={this.handle_ChangeOuNameAttribute} />
            <label>OU Name Attribute</label>
          </div>

          <div className="formgroup-element">
            <div className="formgroup-element-title"> Authenticate with User DN</div>
            <div className="formgroup-element-fields">
              <div className="mui-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.ldap.authenticateuserusingsupplieddn}
                  onChange={this.handle_ChangeAuthenticateUserUsingDn}
                />
              </div>
            </div>
          </div>

          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.groupbase} onChange={this.handle_ChangeGroupBase} />
            <label>Group Base</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.groupnameattribute} onChange={this.handle_ChangeGroupNameAttribute} />
            <label>Group Name Attribute</label>
          </div>
          <div className="mui-textfield">
            <input type="text" value={this.state.ldap.groupmembernameattribute} onChange={this.handle_ChangeGroupMemberNameAttribute} />
            <label>Group Member Name Attribute</label>
          </div>
        </div>
      );
    }

    return <div />;
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Edit LDAP Server" handleHide={this.handleHide} actions={this.renderActions()} customclassName="editModal">
          <form className="mui-form">
            {this.render__RequiredOptions()}
            {this.render__ManualOptions()}
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class LdapServers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ldap: [],
      visible__add_group: false,
      edit__ldap_server: undefined,
      loaded: false,
    };
  }

  handle_load = () => {
    const self = this;
    self.setState({
      loaded: false,
      latestResultStatus: undefined,
    });
    Api.get("/surfwize/device/ajax/ldap2", function (result) {
      let ldapResult = result["ldap"];

      // here we fetch the statuses and then map each child execution to each ldap server,
      // so we can then display the status returned from sync-api instead of cms statuses
      Api.get(getEndpoint("LDAPS"), (statusResult) => {
        const flattenedResults = statusResult.result.edges.map((edge) => edge.node);
        // if we don't get any results from sync-api, assume we haven't synced yet
        if (flattenedResults.length <= 0) {
          self.setState({
            loaded: true,
            ldap: ldapResult,
          });
          return;
        }

        if (flattenedResults[0].childExecutions) {
          // for each ldap object, find the latest sync execution and map it to the ldap object
          ldapResult.forEach((ldap) => {
            // here we map the legacySyncID to the ldap server id
            let matchedSyncExecution = flattenedResults[0].childExecutions.find(
              (childExecution) => childExecution.legacySyncID === ldap.id
            );
            if (matchedSyncExecution) {
              ldap.latestSyncExecution = matchedSyncExecution;
            }
          });
        }

        self.setState({
          loaded: true,
          ldap: ldapResult,
          latestResultStatus: flattenedResults[0]?.syncStatuses[0] || undefined,
        });
      });
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  handler__clickAdd = () => {
    this.setState({
      current_add_new: true,
      edit__ldap_server: { enabled: true },
      visible__add_permission: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_permission: false,
      current_add_new: false,
    });
  };

  handle_SyncAll = (event) => {
    event.preventDefault();

    let self = this;
    Api.post(
      "/config/ajax/authentication/ldap/sync",
      {},
      function (e) {
        self.handle_load();
        const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
        const now = new Date();
        const device = SessionStore.getSelectedDevice();
        manuallySetRunningSyncs[device.concat("_LDAPServersLockout")] = now.getTime() + 60000; // 60 second 'ttl' to lock out this sync in this browser
        localStorage.setItem("manuallySetRunningSyncs", JSON.stringify(manuallySetRunningSyncs));
      },
      function (error) {}
    );
  };

  render__buttons = () => {
    return [
      <TableCentricPageButton title="Sync All" onClickHandler={this.handle_SyncAll} data-cy="syncAll" />,
      <TableCentricPageButton title="Refresh" onClickHandler={this.handle_load} data-cy="refresh" />,
      <TableCentricPageButton title="Add LDAP Server" onClickHandler={this.handler__clickAdd} data-cy="addLDAPServer" />,
    ];
  };

  handle_Delete = (row) => {
    let self = this;
    self.setState({
      loaded: false,
    });
    Api.delete("/surfwize/device/ajax/ldap2/" + row["id"], {}, function (result) {
      self.handle_load();
    });
  };

  handle__SaveRule = (rule) => {
    let self = this;
    self.setState({
      loaded: false,
    });
    Api.post("/surfwize/device/ajax/ldap2/" + rule["id"], rule, function (result) {
      self.handle_load();
    });
  };

  handle__SaveNew = (rule) => {
    let self = this;
    self.setState({
      loaded: false,
    });
    Api.put("/surfwize/device/ajax/ldap2", rule, function (result) {
      self.handle_load();
    });
  };

  handle_Edit = (row) => {
    this.setState({
      current_add_new: false,
      edit__ldap_server: row,
      visible__add_permission: true,
    });
  };

  statusText = (row) => {
    const now = new Date();
    const device = SessionStore.getSelectedDevice();
    const manuallySetRunningSyncs = JSON.parse(localStorage.getItem("manuallySetRunningSyncs") || "{}");
    const manualStatusExpiry = manuallySetRunningSyncs[device.concat("_LDAPServersLockout")];

    if (manualStatusExpiry > now.getTime()) {
      return "Sync running now";
    } else if (!row.latestSyncExecution) {
      return "Not Synced";
    } else if (!row.latestSyncExecution.syncStatuses || row.latestSyncExecution.syncStatuses.length <= 0) {
      return "Not Synced";
    } else if (row.latestSyncExecution.syncStatuses[0].status === "Failed") {
      return "Failed to sync, reason was " + row.latestSyncExecution.syncStatuses[0].message;
    } else if (row.latestSyncExecution.syncStatuses[0].status === "Fetching Data") {
      return "Sync running now";
    } else if (row.latestSyncExecution.syncStatuses[0].status === "Data Fetched") {
      return "Data was successfully fetched.";
    }

    return "";
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Server",
        data: function (row) {
          return row["hostname"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("hostname"),
        search: FlexibleTable.search__single_string_field("hostname"),
        onclick: undefined,
      },
      {
        title: "Base DN",
        data: function (row) {
          return row["basedn"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("basedn"),
        search: FlexibleTable.search__single_string_field("basedn"),
        onclick: undefined,
      },
      {
        title: "Status",
        data: (row) => this.statusText(row),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton onClick={() => self.handle_Edit(row)} />
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row)} />
            </div>
          );
        },
        class: "operationsColumn",
        onclick: undefined,
      },
    ];

    return (
      <>
        {this.state.latestResultStatus && (
          <Box backgroundColor={"white"} margin={"1rem"} padding={"1rem"}>
            <Text fontWeight={"500"}>Last Sync</Text>
            {this.state.latestResultStatus.status === "Completed" && (
              <Box gap={"0.5rem"}>
                <Text>
                  Completed at {moment(this.state.latestResultStatus.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")} (
                  {moment.duration(moment(this.state.latestResultStatus.createdAt, formatString).diff(moment())).humanize(true)})
                </Text>
                {this.state.latestResultStatus.metrics && (
                  <Box alignItems={"end"} display={"flex"} flexDir={"column"}>
                    {this.state.latestResultStatus.metrics.usersAdded !== null && (
                      <Text>Users Added: {this.state.latestResultStatus.metrics.usersAdded}</Text>
                    )}
                    {this.state.latestResultStatus.metrics.usersUnchanged !== null && (
                      <Text>Users Unchanged: {this.state.latestResultStatus.metrics.usersUnchanged}</Text>
                    )}
                    {this.state.latestResultStatus.metrics.groupsAdded !== null && (
                      <Text>Groups Added: {this.state.latestResultStatus.metrics.groupsAdded}</Text>
                    )}
                    {this.state.latestResultStatus.metrics.groupsUnchanged !== null && (
                      <Text>Groups Unchanged: {this.state.latestResultStatus.metrics.groupsUnchanged}</Text>
                    )}
                  </Box>
                )}
              </Box>
            )}
            {this.state.latestResultStatus.status === "Failed" && (
              <Box>
                <Text>
                  Failed at {moment(this.state.latestResultStatus.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")} (
                  {moment.duration(moment(this.state.latestResultStatus.createdAt, formatString).diff(moment())).humanize(true)})
                </Text>
                <Box>Reason: {this.state.latestResultStatus.message}</Box>
              </Box>
            )}
            {this.state.latestResultStatus.status === "Started" && (
              <Box>
                <Text>In Progress</Text>
                <Text>
                  Started at {moment(this.state.latestResultStatus.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")} (
                  {moment.duration(moment(this.state.latestResultStatus.createdAt, formatString).diff(moment())).humanize(true)})
                </Text>
              </Box>
            )}
            {this.state.latestResultStatus.status === "Pending" && (
              <Box>
                <Text>The Sync is pending and will be started shortly</Text>
                <Text>
                  Requested at {moment(this.state.latestResultStatus.createdAt, formatString).format("HH:mm:ss - ddd Do MMM")} (
                  {moment.duration(moment(this.state.latestResultStatus.createdAt, formatString).diff(moment())).humanize(true)})
                </Text>
              </Box>
            )}
          </Box>
        )}
        <FlexibleTable columns={columns} data={this.state.ldap} loaded={this.state.loaded} />
      </>
    );
  };

  render() {
    return (
      <ABTester
        optionB={
          <TableLeftPanel>
            <AddEditRule
              visible={this.state.visible__add_permission}
              handleSave={this.state.current_add_new ? this.handle__SaveNew : this.handle__SaveRule}
              handleClose={this.handler__clickCloseAdd}
              ldap={this.state.edit__ldap_server}
            />
            <TableCentricPage
              title="LDAP Servers"
              buttons={this.render__buttons()}
              content={this.render__content()}
              subTitle={
                <Link isExternal href={"https://help.linewize.com/hc/en-gb/articles/5732871324444"}>
                  Configuring LDAP Guide
                </Link>
              }
            />
          </TableLeftPanel>
        }
        optionA={<LdapServersNew data={this.state.ldap} loaded={this.state.loaded} statusText={this.statusText} />}
        isOptionB={true}
      />
    );
  }
}
