import { create } from "zustand";
import { Permission } from "../types/Authorization";

interface ResetOptions {
  supportAdmin?: boolean;
}

export interface SessionState {
  permissions: Permission[];
  supportAdmin: boolean;
  reset: (options?: ResetOptions) => void;
}

// Currently, the values are set by `SessionStore.js` to keep the two stores in sync,
// so for now, this merely serves as a strongly typed wrapper, but eventually, all
// permissions-related bits should be fully extracted here
export const useDevicePermissionsStore = create<SessionState>((set) => ({
  permissions: [],

  supportAdmin: false,

  reset: ({ supportAdmin }: ResetOptions = {}) => {
    // under normal conditions, the `supportAdmin` property is not reset
    // as it does not change when switching between appliances
    if (typeof supportAdmin === "boolean") {
      set({ permissions: [], supportAdmin });
    } else {
      set({ permissions: [] });
    }
  },
}));
