import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import Api from "../../../utils/Api";
import {
  Box,
  Checkbox,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import TableBasedPage from "../../templates/TableBasedPage";
import { zIndices } from "../../../utils/ZIndexUtil";
import HostDetailsModal from "../HostDetailsModal";
import AdminSessionByIPModal from "../../../modules/AdminSessionByIPModal";
import { DeleteSessionModal } from "./DeleteSessionModal";
import { TableColumn } from "../../../types/table";
import { CSVExportButton } from "../../../modules/ExportReport";
import { exportAuthenticationProvider, exportLoginTime, exportSaved, Host, isQueryResult, Session } from "./ActiveHostsHelpers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

interface Props {
  params?: {
    user?: string;
  };
}

dayjs.extend(relativeTime);

export const ActiveHosts: React.FC<Props> = (props, context) => {
  const { t } = useTranslation();
  const { successToast } = useToast();

  const [hosts, setHosts] = useState<Host[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showDetailsHost, setShowDetailsHost] = useState<Host | false>(false);
  const [loaded, setLoaded] = useState(false);
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
  const [currentIPForAdminSession, setCurrentIPForAdminSession] = useState("");
  const [showRemoveSessionModal, setShowRemoveSessionModal] = useState(false);
  const [sessionToRemove, setSessionToRemove] = useState<Session>();

  useEffect(() => {
    handleLoad();
  }, []);

  const handleLoad = () => {
    setLoaded(false);

    Api.get("/edgewize/device/analytics/clients/hosts", (result: unknown) => {
      if (isQueryResult(result)) {
        setHosts(result["data"]);
        setLoaded(true);
      }
    });
  };

  const breadcrumbData = [
    { title: "Statistics", url: "/surfwize/dashboard", isActive: false },
    { title: "Realtime", url: "/surfwize/device/status/hosts", isActive: false },
    { title: "Users and Hosts", url: "/surfwize/device/status/hosts", isActive: true },
  ];

  const columns: TableColumn[] = [
    {
      columnName: "host",
      headerText: "IP Address",
      searchable: true,
      sortable: true,
      exportable: true,
    },
    {
      columnName: "hw",
      headerText: "Hardware",
      searchable: true,
      sortable: true,
      exportable: true,
    },
    {
      columnName: "user",
      headerText: "User",
      searchable: true,
      sortable: true,
      exportable: true,
    },
    {
      columnName: "micro_lock",
      headerText: "Quarantined",
      sortable: true,
    },
    {
      columnName: "formattedLoginTime",
      headerText: "Last Login",
      sortable: true,
      exportable: true,
      exportData: exportLoginTime,
    },
    {
      columnName: "formattedLoginMethod",
      headerText: "Login Method",
      sortable: true,
      exportable: true,
      exportData: exportAuthenticationProvider,
    },
    {
      columnName: "is_saved",
      headerText: "Save",
      sortable: true,
      exportable: true,
      exportData: exportSaved,
    },
    {
      columnName: "type",
      headerText: "Device Type",
      searchable: true,
      sortable: true,
      exportable: true,
    },
    {
      columnName: "total_active_connections",
      headerText: "Connections",
      sortable: true,
    },
  ];

  const handleShowSessionCreationModal = (IPAddress: string) => {
    setShowCreateSessionModal(true);
    setCurrentIPForAdminSession(IPAddress);
  };

  const handleHideSessionCreationModal = () => {
    setShowCreateSessionModal(false);
    setCurrentIPForAdminSession("");
  };

  const handleShowRemoveSessionModal = (session: Session) => {
    setShowRemoveSessionModal(true);
    setSessionToRemove(session);
  };

  const handleRemoveSession = (session: Session) => {
    setLoaded(false);
    Api.delete("/surfwize/device/analytics/clients/users", session, function () {
      handleLoad();
      successToast({
        title: "Session Removed",
        description: `${session.user ? `A session for ${session.user}` : `A session`} has been successfully removed`,
        isClosable: true,
      });
    });
    setShowRemoveSessionModal(false);
  };

  const handleAddPermanentSession = (session: Host) => {
    if (session["session"])
      Api.put(
        "/config/ajax/sessions",
        {
          username: session["session"]["user"],
          macAddress: session["session"]["mac_address"],
        },
        function () {
          handleLoad();
        }
      );
  };

  const handleRemovePermanentSession = (session: Host) => {
    if (session["session"])
      Api.delete(
        "/config/ajax/sessions",
        {
          username: session["session"]["user"],
          macAddress: session["session"]["mac_address"],
        },
        function () {
          handleLoad();
        }
      );
  };

  const handleShowDetails = (host: Host) => {
    setShowDetails(true);
    setShowDetailsHost(host);
  };

  const handleHideDetails = () => {
    setShowDetails(false);
  };

  /* Disabled eslint for react router v3 */
  /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
  const handleTraceHost = (host: Host) => {
    context.router.push("/surfwize/device/status/connections?ip=" + host["host"]);
  };

  const handleTraceUser = (host: Host) => {
    if (host["user"]) context.router.push("/surfwize/device/status/connections?user=" + host["user"]);
  };
  /* eslint-enable */

  const dataMap = (r: Host, index: number) => (
    <Tr key={index} p="12">
      <Td>
        <Text fontSize="md">{r.host}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{r.hw}</Text>
      </Td>
      <Td>
        <Text color="brand.500" fontSize="md">
          {r.user ? <Link to={"/surfwize/reporting/users/" + r.user}>{r.user}</Link> : "-"}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">
          {r.micro_lock && r.micro_lock * 1000 > new Date().getTime() ? (
            <span className="quarentined">Quarantined for {dayjs.unix(r.micro_lock).toNow(true)}</span>
          ) : (
            "-"
          )}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">{r.formattedLoginTime}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{r.formattedLoginMethod}</Text>
      </Td>
      <Td>
        <Text fontSize="md">
          {r.session ? (
            r.session?.is_saved ? (
              <Checkbox isChecked={true} onChange={() => handleRemovePermanentSession(r)} />
            ) : (
              <Checkbox isChecked={false} onChange={() => handleAddPermanentSession(r)} />
            )
          ) : (
            "-"
          )}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md">{r.type}</Text>
      </Td>
      <Td>
        <Text fontSize="md">{r.total_active_connections}</Text>
      </Td>
      <Td>
        <Box key={r.host}>
          <Menu>
            <MenuButton p="7px">
              <Icon icon="fa-ellipsis-vertical" data-testid="fa-ellipsis-vertical" boxSize="14px" />
            </MenuButton>
            <MenuList zIndex={zIndices.menu}>
              <MenuItem icon={<Icon icon="fa-eye" boxSize="14px" />} onClick={() => handleShowDetails(r)} fontSize="md">
                {t("View Details")}
              </MenuItem>
              <MenuItem
                icon={<Icon icon="fa-plus" data-testid="fa-plus" boxSize="14px" />}
                onClick={() => handleShowSessionCreationModal(r["host"])}
                fontSize="md"
              >
                {t("Create Session")}
              </MenuItem>
              {r.user && (
                <MenuItem
                  icon={<Icon icon="fa-times" boxSize="14px" />}
                  onClick={() => {
                    if (r["session"]) handleShowRemoveSessionModal(r["session"]);
                  }}
                  fontSize="md"
                >
                  {t("Remove Session")}
                </MenuItem>
              )}
              <MenuItem icon={<Icon icon="fa-solid fa-play" boxSize="14px" />} onClick={() => handleTraceHost(r)} fontSize="md">
                {t("Trace Host")}
              </MenuItem>
              {r.user && (
                <MenuItem icon={<Icon icon="fa-user" boxSize="14px" />} onClick={() => handleTraceUser(r)} fontSize="md">
                  {t("Trace User")}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Td>
    </Tr>
  );

  const data = useMemo(() => {
    const filteredHosts = props.params?.user ? hosts.filter(({ user }) => user === props.params?.user) : hosts;
    const formattedHosts = filteredHosts.map((host) => {
      return {
        ...host,
        formattedLoginTime: host.session?.loginTime ? dayjs.unix(host.session.loginTime).fromNow() : "-",
        formattedLoginMethod: host.session?.authentication_provider || "-",
      };
    });
    return formattedHosts;
  }, [props.params?.user, hosts]);

  return (
    <TableBasedPage
      columns={columns}
      breadcrumbs={breadcrumbData}
      loaded={loaded}
      data={data}
      title={t("Users and Hosts")}
      tableDataMap={dataMap}
      childrenInTableHeader={<CSVExportButton columns={columns} formattedData={data} />}
    >
      <>
        <HostDetailsModal handleClose={handleHideDetails} visible={showDetails} host={showDetailsHost} />
        <AdminSessionByIPModal
          onSaveSuccess={handleLoad}
          ip={currentIPForAdminSession}
          visible={showCreateSessionModal}
          handleClose={handleHideSessionCreationModal}
        />
        {showRemoveSessionModal && sessionToRemove && (
          <DeleteSessionModal
            sessionToRemove={sessionToRemove}
            handleOK={handleRemoveSession}
            handleOnClose={() => setShowRemoveSessionModal(false)}
          />
        )}
      </>
    </TableBasedPage>
  );
};

ActiveHosts.contextTypes = {
  router: PropTypes.object.isRequired,
};
