import "jsondiffpatch/public/formatters-styles/annotated.css";
import "jsondiffpatch/public/formatters-styles/html.css";
import jsondiffpatch from "jsondiffpatch/src/main";
import formatters from "jsondiffpatch/src/main-formatters";
import moment from "moment";
import React from "react";

import ObjectActions from "../../actions/ObjectActions";
import PeriodActions from "../../actions/PeriodActions";
import SecurityGroupActions from "../../actions/SecurityGroupActions";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";

import SessionStore from "../../stores/SessionStore";

import Api from "../../utils/Api";
import { Box } from "@familyzone/component-library";

class DiffView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      new_config: {},
      old_config: {},
      show_raw: true,
    };

    this.renderNavigation = this.renderNavigation.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible && nextProps.visible) {
      this.setState({
        new_config: {},
        old_config: {},
      });
      this.handle_load(nextProps.new_id, nextProps.new_id_device, nextProps.old_id, nextProps.old_id_device);
    }
  }

  handle_load = (new_id, new_device, old_id, old_device) => {
    let self = this;
    Api.get(
      "/managedevice/ajax/device/" + new_device + "/configuration/changes/id/" + new_id + "/full",
      function (current) {
        self.setState({ new_config: JSON.parse(current["result"]["body"]) });
        Api.get(
          "/managedevice/ajax/device/" + old_device + "/configuration/changes/id/" + old_id + "/full",
          function (before) {
            self.setState({ old_config: JSON.parse(before["result"]["body"]) });
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handleHide}>
        Close
      </button>
    );
  };

  showRaw = (event) => {
    event.preventDefault();
    this.setState({ show_raw: true });
  };

  showDiff = (event) => {
    event.preventDefault();
    this.setState({ show_raw: false });
  };

  renderNavigation = () => {
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="nav-link" onClick={this.showRaw}>
          Raw
        </a>{" "}
        | {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="nav-link" onClick={this.showDiff}>
          Diff
        </a>
      </div>
    );
  };

  render_raw = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <div className="row">
            <div className="col-md-6">
              <span>
                <b>Before:</b>
              </span>
              <pre>{JSON.stringify(this.state.old_config, null, 2)}</pre>
            </div>
            <div className="col-md-6">
              <span>
                <b>After:</b>
              </span>
              <pre>{JSON.stringify(this.state.new_config, null, 2)}</pre>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render_diff = () => {
    let delta = jsondiffpatch
      .create({
        objectHash: function (obj) {
          let hash = [],
            prop;
          for (prop in obj) {
            if (obj.hasOwnProperty(prop)) {
              hash.push(prop);
            }
          }
          return hash.sort().join("");
        },
      })
      .diff(this.state.old_config, this.state.new_config);
    let content = { __html: formatters.html.format(delta, this.state.old_config) };

    return <div dangerouslySetInnerHTML={content} />;
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow
          title="Configuration Changes"
          handleHide={this.handleHide}
          navigation={this.renderNavigation()}
          actions={this.renderActions()}
          customClass="wide-modal"
        >
          {this.state.show_raw ? this.render_raw() : this.render_diff()}
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class ManageDeviceConfiguration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      changes: [],
      showDiffModal: false,
      old_id: 0,
      old_id_device: SessionStore.getSelectedDevice(),
      new_id: 1,
      new_id_device: SessionStore.getSelectedDevice(),
      activeVersion: 0,
      loaded: false,
    };
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get(
      "/info",
      function (result) {
        self.setState({
          activeVersion: result["configuration_tracker"] ? result["configuration_tracker"]["device.version"] : "0",
        });
      },
      (err) => {
        console.log(err);
      }
    );

    Api.get("/managedevice/ajax/device/configuration/changes/all", function (result) {
      self.setState({
        changes: result["data"],
        loaded: true,
      });
    });
  };

  handle_Rollback = (conf) => {
    let self = this;
    Api.post("/managedevice/ajax/device/configuration/changes/revert", { revert_id: conf.id }, function (result) {
      self.handle_load();
      ObjectActions.invalidateAndFetch();
      PeriodActions.invalidateAndFetch();
      SecurityGroupActions.invalidateAndFetch();
    });
  };

  handler_closeDiff = () => {
    this.setState({
      showDiffModal: false,
    });
  };

  handle_Diff = (new_id, new_device_id, scoop) => {
    let old_id = 0;
    let old_id_device = SessionStore.getSelectedDevice();
    for (let change of this.state.changes) {
      if (scoop === change["scope"] && new_id > change["id"] > old_id) {
        old_id = change["id"];
        old_id_device = change["deviceid"];
      }
    }
    this.setState({
      showDiffModal: true,
      new_id: new_id,
      new_id_device: new_device_id,
      old_id: old_id,
      old_id_device: old_id_device,
    });
  };

  componentDidMount() {
    this.handle_load();
  }

  render__buttons = () => {
    return <div />;
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "State",
        data: function (row) {
          if (self.state.activeVersion >= row["id"]) {
            return <i className="fa fa-check-square" aria-hidden="true" />;
          } else {
            return <i className="fa fa-exclamation-circle" aria-hidden="true" />;
          }
        },
        onclick: undefined,
      },
      {
        title: "Time",
        data: function (row) {
          return moment.unix(row["change_timestamp"]).format("DD/MM/YYYY, h:mm:ss a");
        },
        sortable: FlexibleTable.sortable__single_numeric_field("change_timestamp"),
        onclick: undefined,
      },
      {
        title: "User",
        data: function (row) {
          return row["change_username"];
        },
        search: FlexibleTable.search__single_string_field("change_username"),
        sortable: FlexibleTable.sortable__single_string_field("change_username"),
        onclick: undefined,
      },
      {
        title: "Scope",
        data: function (row) {
          return row["scope"];
        },
        search: FlexibleTable.search__single_string_field("scope"),
        sortable: FlexibleTable.sortable__single_string_field("scope"),
        onclick: undefined,
      },
      {
        title: "Version",
        data: function (row) {
          return (
            <div>
              {row["id"]}
              {SessionStore.getSelectedDevice() !== row["deviceid"] ? (
                <div
                  style={{
                    fontSize: "9px",
                    color: "red",
                    border: "1px solid",
                    display: "inline",
                    padding: "2px",
                    marginLeft: "5px",
                    borderRadius: "3px",
                  }}
                >
                  {"parent"}
                </div>
              ) : (
                <div />
              )}
            </div>
          );
        },
        search: FlexibleTable.search__single_string_field("id"),
        sortable: FlexibleTable.sortable__single_string_field("id"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => self.handle_Diff(row["id"], row["deviceid"], row["scope"])}>Show Changes</a>
              <span> | </span>
              {SessionStore.getSelectedDevice() !== row["deviceid"] ? (
                <div style={{ color: "grey", display: "inline" }}>Rollback</div>
              ) : (
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => self.handle_Rollback(row)}>Rollback</a>
                </>
              )}
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.changes} loaded={this.state.loaded} sort_asc={false} sort_column="4" />;
  };

  render() {
    return (
      <Box>
        <DiffView
          new_id={this.state.new_id}
          new_id_device={this.state.new_id_device}
          old_id={this.state.old_id}
          old_id_device={this.state.old_id_device}
          visible={this.state.showDiffModal}
          handleClose={this.handler_closeDiff}
        />
        <Box id="fz_table_header" pt="sp24" pl="sp12">
          {/* Search box is injected here by FlexibleTable */}
        </Box>
        {this.render__content()}
      </Box>
    );
  }
}
