import React, { useLayoutEffect, useRef, useMemo, FC } from "react";
import ReactDOM from "react-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import NoData from "../modules/NoData";
import ComponentLoading from "../modules/ComponentLoading";
import clsx from "clsx";
import { useForceUpdate } from "../utils/hooks/useForceUpdate";
import { getAmount } from "../modules/AmountField";
import { createPieChart } from "./PieChartHelper";

export interface InputChartDataItem {
  name: string;
  y: number;
}

interface ChartDataItem {
  app: string;
  transfer: number;
  formatTransfer: string;
}

interface Props {
  data: InputChartDataItem[];
  dataLoaded: boolean;
}

export const TopSixAppsPieChart: FC<Props> = ({ data, dataLoaded }): JSX.Element => {
  const chartRef = useRef<am4charts.PieChart | null>(null);
  const forceUpdate = useForceUpdate();

  const chartColorList = useMemo(
    () => [
      am4core.color("#2F2CB7"),
      am4core.color("#A56EFF"),
      am4core.color("#DD7A01"),
      am4core.color("#06A59A"),
      am4core.color("#0D9DDA"),
      am4core.color("#E3066A"),
    ],
    []
  );

  const useSingleColumnLegend = useRef(true);
  const legend = (): JSX.Element[] => {
    const hoverSlice = (item: number, hover: boolean) => {
      return () => {
        const slice = chartRef?.current?.series.values[0].slices.getIndex(item);
        if (slice) {
          slice.isHover = hover;
        }
      };
    };

    if (chartRef.current) {
      let total = 0;
      chartRef.current?.data?.forEach((item: ChartDataItem) => (total += item.transfer));
      return chartRef.current?.data.map((item: ChartDataItem, i: number) => {
        const itemId = `legend-item-${i}`;
        const itemStyle = { background: chartColorList[i].toString() };
        const percent = Math.round((item.transfer / total) * 1000) / 10; // Round to one decimal place to match the graph
        return (
          <div
            key={i}
            className={clsx("legend-item", useSingleColumnLegend.current && "legend-item-single-column")}
            id={itemId}
            onMouseOver={hoverSlice(i, true)}
            onMouseOut={hoverSlice(i, false)}
          >
            <div
              className={clsx("legend-marker", !useSingleColumnLegend.current && i % 2 === 1 && "legend-marker-inner")}
              style={itemStyle}
            ></div>
            <div className="legend-label">{item.app}</div>
            <div className={clsx("legend-value", !useSingleColumnLegend.current && i % 2 === 0 && "legend-value-inner")}>({percent}%)</div>
          </div>
        );
      });
    } else {
      return [];
    }
  };

  const switchLegendLayoutThreshold = 2017;
  useLayoutEffect(() => {
    function handleResize() {
      if (useSingleColumnLegend.current !== window.innerWidth < switchLegendLayoutThreshold) {
        useSingleColumnLegend.current = window.innerWidth < switchLegendLayoutThreshold;
        ReactDOM.render(legend(), document.getElementById("personaTopSixAppsChartLegend"));
      }
    }

    window.addEventListener("resize", handleResize);
    return (_: void) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useLayoutEffect(() => {
    chartRef.current = createPieChart({ valueField: "transfer", categoryField: "app", chartColorList: chartColorList });
  }, [chartColorList]);

  useLayoutEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.data = data;
      chart.data = data?.map((x) => {
        return {
          app: x["name"],
          transfer: x["y"],
          formatTransfer: getAmount(x["y"]), //In future will use byte-size package. See edu-2247
        };
      });
      chart.data.sort(function (a: ChartDataItem, b: ChartDataItem) {
        return b["transfer"] - a["transfer"];
      });
      forceUpdate();
    }
  }, [forceUpdate, data]);

  const showNoData = dataLoaded && chartRef.current?.data?.length === 0;

  return (
    <div id="topSixAppsModule" className="personaModule">
      {!dataLoaded && (
        <div className="centered">
          <ComponentLoading />
        </div>
      )}
      {showNoData && <NoData />}
      <div id="personaTopSixAppsChart" className={clsx("", (!dataLoaded || showNoData) && "hidePersonaChart")}></div>
      <div id="personaTopSixAppsChartLegend">{legend()}</div>
    </div>
  );
};
