import { headers } from "./ApiHelpers";

// Enum to map feature flags to valid strings the API accepts to represent them
export enum ClassroomFeatureFlag {
  ScreenShare = "SCREEN_SHARE",
}

/**
 * Get all apps that can be restricted.
 *
 * Used to populate the restricted applications dropdown on the Config > Classwize > Screenshots config page.
 */
export function getClassroomFeatureFlags(): Promise<Response> {
  return fetch(`/api/classrooms/enabled-features`, {
    method: "GET",
    headers: headers(),
  });
}

export interface ClassroomFeatureFlagsResponseShape {
  applianceId: string;
  enabledFeatures: ClassroomFeatureFlag[];
}

/**
 * Saves the screenshot configuration. This will overwrite the full configuration each time, no deltas.
 *
 * 200 - Created or updated successfully
 *
 * 400 - Request validation failed
 */
export function updateClassroomFeatureFlags(features: ClassroomFeatureFlag[]): Promise<Response> {
  return fetch(`/api/classrooms/enabled-features`, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify({ enabledFeatures: features } satisfies UpdateClassroomFeatureFlagsPayloadShape),
  });
}

export interface UpdateClassroomFeatureFlagsPayloadShape {
  enabledFeatures: ClassroomFeatureFlag[];
}
