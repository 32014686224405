import React from "react";
import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import { Guardian } from "../../types/Community";

export const DeleteParentModal: React.FC<{
  guardian: Guardian;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ guardian, open, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      headerText={t("Delete Parent")}
      isOpen={open}
      size="md"
      variant="danger"
      primaryCTALabel={t("Delete")}
      onPrimaryCTAClick={onConfirm}
      secondaryCTALabel={t("Cancel")}
      onSecondaryCTAClick={onCancel}
      onClose={onCancel}
    >
      <ModalBody>
        <>
          <Text mb="sp24">{t("This will delete the parent record permanently.")}</Text>
          {(guardian.linkCount || guardian.students?.length) && (
            <Text mb="sp24">{t("The parent will not be able to connect their students from Qustodio.")}</Text>
          )}
          <Text data-testid="confirmation-question">
            {t("Are you sure you want to delete parent")}{" "}
            <b>
              {guardian.firstName} {guardian.lastName}
            </b>
            ?
          </Text>
        </>
      </ModalBody>
    </Modal>
  );
};
