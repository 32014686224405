import React, { useCallback, useEffect, useState } from "react";
import {
  Flex,
  useTranslation,
  Modal,
  Text,
  Button,
  Box,
  useToast,
  Option,
  SingleSearchSelector,
  Spinner,
} from "@familyzone/component-library";
import { BypassCodeWithoutId, createBypassCode, generateBypassCode, getUserInfo } from "../../ApiHelpers";
import { BypassCode } from "../../types";
import moment from "moment";

export const headerText = "Add Code";
export const buttonText = "Add";
export const timeOptionLabels = [
  "For 10 Minutes",
  "For 15 Minutes",
  "For 20 Minutes",
  "For 30 Minutes",
  "For 45 Minutes",
  "For 1 Hour",
  "For 2 Hours",
  "For 3 Hours",
];
export const selectorPlaceholderText = "Select duration...";

export type ExpireTimeOption = Option & {
  value: number;
};

interface BypassCodeModalProps {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSubmit: (code: BypassCode) => void;
}

const BypassCodeModal: React.FC<BypassCodeModalProps> = ({ open, disabled, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [code, setCode] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [loaded, setLoaded] = useState(false);

  const timeOptions: ExpireTimeOption[] = [
    {
      label: t(timeOptionLabels[0]),
      value: 10,
    },
    {
      label: t(timeOptionLabels[1]),
      value: 15,
    },
    {
      label: t(timeOptionLabels[2]),
      value: 20,
    },
    {
      label: t(timeOptionLabels[3]),
      value: 30,
    },
    {
      label: t(timeOptionLabels[4]),
      value: 45,
    },
    {
      label: t(timeOptionLabels[5]),
      value: 60,
    },
    {
      label: t(timeOptionLabels[6]),
      value: 2 * 60,
    },
    {
      label: t(timeOptionLabels[7]),
      value: 3 * 60,
    },
  ];

  const [expiryTime, setExpiryTime] = useState<ExpireTimeOption | null>(timeOptions[0]);

  const handleClickSubmit = async () => {
    try {
      if (!expiryTime) return;
      if (!code) return;
      if (!createdBy) return;

      const bypass: BypassCodeWithoutId = {
        code: code,
        expiry_time: moment().unix() + expiryTime.value * 60,
        creation_time: moment().unix(),
        created_by: createdBy,
      };
      setLoaded(false);

      await createBypassCode(bypass);

      onSubmit({ ...bypass, id: Math.random().toString(16) });
      setCode("");
      setLoaded(true);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const populateCode = useCallback(async () => {
    try {
      if (!open) return;
      const generatedCode = await generateBypassCode();
      setCode(generatedCode);
      setLoaded(true);
    } catch (err) {
      console.error(err);
      errorToast({
        title: "Error loading data",
        description: "Encountered an error while loading bypass code data",
      });
    }
  }, [open, errorToast]);

  useEffect(() => {
    populateCode().catch(() => "");
  }, [populateCode]);

  useEffect(() => {
    if (open) return;
    setLoaded(false);
  }, [open]);

  const handleChangeTime = (selected: ExpireTimeOption | null) => {
    setExpiryTime(selected);
  };

  const populateCreatedBy = useCallback(async () => {
    try {
      const userInfo = await getUserInfo();
      setCreatedBy(userInfo.email);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    populateCreatedBy().catch(() => "");
  }, [populateCreatedBy]);

  return (
    <Modal isOpen={open} onClose={onClose} headerText={t(headerText)} size="md" contentProps={{ style: { overflow: "visible" } }}>
      <Box my="sp24" overflowY="auto" py="sp4" overflow="visible">
        <Box mb="sp16">
          {!loaded ? (
            <Spinner />
          ) : (
            <Text>
              Bypass Code:{" "}
              <Text display="inline" fontWeight="bold">
                {code}
              </Text>
            </Text>
          )}
        </Box>

        <SingleSearchSelector
          placeholder={t(selectorPlaceholderText)}
          options={timeOptions}
          onChange={handleChangeTime}
          selected={expiryTime}
          showClearIcon={false}
          filterOptions={false}
          disabled={!loaded || disabled}
        />
      </Box>
      <Flex>
        <Button
          variant="primary"
          onClick={() => void handleClickSubmit()}
          disabled={!loaded || disabled}
          aria-label="save"
          data-testid="save-policy-button"
        >
          {t(buttonText)}
        </Button>
      </Flex>
    </Modal>
  );
};

export default BypassCodeModal;
