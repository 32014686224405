import { Flex, Stack } from "@chakra-ui/react";
import { Button, Input, Modal, useTranslation, Text, Option } from "@familyzone/component-library";
import React from "react";
import { UserSearchSelector } from "../UserSearch/UserSearchSelector";

interface AssociationModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  handleSave: (user: string, val: string) => void;
  onChangeMac: (e: React.ChangeEvent<HTMLInputElement>) => void;
  macError: string;
}

const AssociationModal: React.FC<AssociationModalProps> = ({
  open = true,
  onClose,
  handleSave,
  onChangeMac,
  macError,
}: AssociationModalProps) => {
  const titleText = "Add User";
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState<string>();
  const [val, setVal] = React.useState("");

  const handleClick = () => {
    if (selectedUser === undefined) {
      return;
    }
    handleSave(selectedUser, val);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeMac(e);
    setVal(e.target.value);
  };

  const userSelected = (user: Option | null) => {
    if (user && typeof user.value === "string") {
      setSelectedUser(user.value);
    }
  };

  function isValidRule(): boolean {
    if (!macError && selectedUser && val) {
      return true;
    }
    return false;
  }

  return (
    <Modal headerText={t(titleText)} size="md" isOpen={open} onClose={onClose} contentProps={{ style: { overflow: "visible" } }}>
      <Stack spacing="sp12" width="100%" my="sp12">
        <Text>User</Text>
        <UserSearchSelector placeholderText="Enter username, email or name" handleChange={userSelected}></UserSearchSelector>
      </Stack>
      <Stack spacing="sp12" width="100%" my="sp12">
        <Text>Mac/IP Address</Text>
        <Input data-testid="macAddress-input" onChange={(e) => onChange(e)}></Input>
        {macError && <Text>{macError}</Text>}
      </Stack>
      <Flex>
        <Button
          data-testid="addButton"
          disabled={!isValidRule()}
          maxWidth="100%"
          mt="sp12"
          variant="primary"
          onClick={() => {
            handleClick();
          }}
        >
          Add
        </Button>
      </Flex>
    </Modal>
  );
};

export default AssociationModal;
