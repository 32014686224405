import React from "react";
import { Box, Flex, Text, TextInput, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria } from "../criteriaTypes";

export interface IPv4CriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
}

const IPv4Criteria: React.FC<IPv4CriteriaProps> = ({ criteria, disabled, onChange }) => {
  const { t } = useTranslation();

  if (Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  const handleChangeIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(criteria.conditions)) return;
    onChange({ ...criteria, conditions: { ip: e.target.value, mask: criteria.conditions.mask } });
  };

  const handleChangeMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(criteria.conditions)) return;
    onChange({
      ...criteria,
      conditions: {
        ip: criteria.conditions.ip,
        mask: e.target.value,
      },
    });
  };

  return (
    <Flex>
      <TextInput name="ip" placeholder={t("IP")} isDisabled={disabled} value={String(criteria.conditions.ip)} onChange={handleChangeIp} />
      <Box mx="sp4" />
      <TextInput
        name="subnet mask"
        placeholder={t("Subnet Mask")}
        isDisabled={disabled}
        value={String(criteria.conditions.mask)}
        onChange={handleChangeMask}
      />
    </Flex>
  );
};

export default IPv4Criteria;
