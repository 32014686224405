import React, { useLayoutEffect, useRef, useState, useCallback, useMemo } from "react";
import { Link } from "react-router";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { formatBitsPerSecond } from "../../../utils/StringUtil";
import ComponentLoading from "../../../modules/ComponentLoading";
import InfoIconV2 from "../../../modules/InfoIconV2";
import { useQuery } from "react-query";
import Api from "../../../utils/Api";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { momentDateToApiDate } from "../../../utils/DateTimeUtil";
import moment from "moment";

// Apply chart themes
am4core.useTheme(am4themes_animated);

const transferRateKeys = {
  id: "transfer-rate",
  time: (currentTime) => [transferRateKeys.id, currentTime.format("YYYY-MM-DD HH:mm:ss")],
};

const formatAxis = (axis, axisFormatter) => {
  if (axisFormatter) {
    axis.renderer.labels.template.adapter.add("textOutput", (text, target) => {
      const value = target.dataItem.value;
      return value ? axisFormatter(value) : "";
    });
  }
};

const formatBulletTooltip = (bullet) => {
  bullet.adapter.add("tooltipHTML", (html, target) => {
    const time = moment(target.dataItem.dataContext.time).format("h:mm A");
    const value = formatBitsPerSecond(target.dataItem.dataContext.value);
    return `<table style="margin-bottom: 4px">
    <tr>
        <th align="left">
            <img src="/static/images/Time-Clock.svg" alt="time" style="margin: 0 8px 3px 0;"/>
        </th>
        <td>${time}</td>
    </tr>
    <tr>
        <th align="left">
            <img src="/static/images/up-down-arrow.svg" alt="up" style="margin: 0 8px 3px 0;"/>
        </th>
        <td>Rate: ${value}</td>
    </tr>
</table>`;
  });
};

export const TransferRate = () => {
  const [graphLoaded, setGraphLoaded] = useState(false);
  const chartRef = useRef(null);

  const [currentTime] = useState(new Date());
  const currentMoment = useMemo(() => moment(currentTime), [currentTime]);
  const fourHoursAgo = useMemo(() => moment(currentTime).minutes(0).seconds(0).subtract(4, "hours"), [currentTime]);
  const dateTimeOverride = momentDateToApiDate(fourHoursAgo, currentMoment);
  const transferRateQuery = useQuery(
    transferRateKeys.time(currentMoment),
    () =>
      Api.get_analyticsAsync("/managedevice/ajax/device/metric/firewall.transfer.persec", { dateOverride: dateTimeOverride }).then(
        (result) => result.data
      ),
    {
      select: useCallback(
        (data) => {
          // filter out the data
          let result = [];
          for (let i = data.length - 1; i >= 0; i--) {
            const e = data[i];
            if (e.time <= currentMoment.unix() && e.time >= fourHoursAgo.unix()) {
              result.push({ value: e.value, time: new Date(e.time * 1000) });
            }
          }
          return result;
        },
        [currentMoment, fourHoursAgo]
      ),
    }
  );

  // chart init
  useLayoutEffect(() => {
    const chart = am4core.create("transferRateLineGraphContainer", am4charts.XYChart);
    chartRef.current = chart;
    chart.paddingLeft = 0;
    chart.fontFamily = "Lato";
    chart.fontSize = "12";

    chart.data = transferRateQuery.data;
    // chart.data = data;
    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: "minute",
      count: 2,
    };
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.gridIntervals.setAll([{ timeUnit: "minute", count: 60 }]);
    dateAxis.dateFormats.setKey("minute", "ha");
    dateAxis.periodChangeDateFormats.setKey("minute", "ha");

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    formatAxis(yAxis, formatBitsPerSecond);
    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "time";
    series.fontSize = 10;
    series.stroke = am4core.color("#5867E8");
    series.fill = am4core.color("#E0E5F8");
    series.fillOpacity = 0.5;
    series.strokeWidth = 2;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#514F4D");

    // Make bullets transparent but still keep tooltips
    const circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.strokeWidth = 0;
    circleBullet.fillOpacity = 0;
    formatBulletTooltip(circleBullet);

    chart.events.once("ready", (event) => {
      setGraphLoaded(true);
    });

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, [transferRateQuery.data]);

  const showNoData =
    transferRateQuery.status === "error" || (transferRateQuery.status === "success" && transferRateQuery.data.length === 0);
  const showGraph = transferRateQuery.status === "success" && transferRateQuery.data.length > 0 && graphLoaded;
  const showLoading = transferRateQuery.status === "loading" || (transferRateQuery.length > 0 && !graphLoaded);

  return (
    <div className="personaModule">
      <div className="personaModule-header">
        <h2>Transfer Rate</h2>
        <InfoIconV2 backgroundColor="grey" text="Network speed and performance" />
        <Link
          to="/managedevice/settings/diagnostics/advancedmetrics?startingMetric=firewall.transfer.persec"
          className="personaModule-rightLink"
        >
          View All
        </Link>
      </div>

      {showGraph && (
        <span>
          <label style={{ margin: "0 4px 3px 0", fontWeight: "bold" }}>Currently:</label>
          {/*{formatBitsPerSecond(Math.max(...transferRateQuery.data.map(x => x.value)))}*/}
          {formatBitsPerSecond(transferRateQuery.data[transferRateQuery.data.length - 1].value)}
        </span>
      )}

      <div className="chart-container has-centered-elements">
        {showLoading && (
          <div className="is-centered">
            <ComponentLoading />
          </div>
        )}
        {showNoData && <div className="is-centered">No data to display</div>}

        <div id={"transferRateLineGraphContainer"} className={`chart ${!showGraph ? "loading" : ""}`} />
      </div>
    </div>
  );
};
