import React from "react";

export default class PrivacyPolicyOld extends React.Component {
  render() {
    return (
      <div className="flex-row-center privacy">
        <a href="https://www.familyzone.com/legal" rel="noreferrer" target="_blank">
          View Privacy Policy
        </a>
        <span>
          {" | "}
          <a href="https://www.linewize.com/" rel="noreferrer" target="_blank">
            Powered by Family Zone
          </a>
        </span>
      </div>
    );
  }
}
