export function stripSchema(website) {
  const schemeSeparator = "://";

  const schemeStartIndex = website.indexOf(schemeSeparator);

  if (schemeStartIndex !== -1) {
    return website.substring(schemeStartIndex + schemeSeparator.length);
  } else {
    return website;
  }
}

export function downcaseDomain(url) {
  /**
   * Given a valid url, this function will transform the domain to lower case
   * @param  {String} url valid URL
   * @return {String}     transformed URL
   */
  let parsedUrl = url.match(/([^/]+)(\S*)/);

  return `${parsedUrl[1].toLowerCase()}${parsedUrl[2]}`;
}

/**
 * Automatically tries to assume the website string that is entered.
 * Adds a ".com" if no ending extension is present.
 * @param {string} website website string to fix
 * @returns string
 */
export function fixWebsiteString(website) {
  if (website.length === 0) {
    return website;
  }
  const spacelessString = website.replace(/\s/g, "_");
  const normalizedString = downcaseDomain(spacelessString);
  if (normalizedString[normalizedString.length - 1] === ".") {
    return `${normalizedString.substr(0, normalizedString.length - 1)}.com`;
  }
  if (normalizedString.indexOf(".") !== -1 && normalizedString[normalizedString.length - 1] !== ".") {
    return normalizedString;
  }
  return `${normalizedString}.com`;
}
