import React from "react";

export default class RightPanel extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible: false,
    };

    if ("visible" in props) {
      this.state = {
        visible: props.visible,
      };
    }
  }

  componentWillReceiveProps(newProps) {
    if ("visible" in newProps) {
      this.setState({
        visible: newProps.visible,
      });
    }
  }

  render() {
    if (this.state.visible) {
      return <div className="main-body-right-panel">{this.props.children}</div>;
    }
    return <div />;
  }
}
