import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";

import Separator from "../../modules/Separator";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";

import PeriodStore from "../../stores/PeriodStore";
import PeriodActions from "../../actions/PeriodActions";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import InfoIcon from "../../modules/InfoIcon";
import { ABTester, Box, Flex, Icon, TableIconButton, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import TableBasedPage from "../templates/TableBasedPage";

class AddPeriod extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { name: "" };
  }

  handleHide = () => {
    this.props.handleClose();
  };

  handle_ChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd(this.state.name);
    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Time Period" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form" onSubmit={this.handle_Submit}>
            <div className="mui-textfield">
              <div className="formgroup-element">Name</div>
              <input type="text" value={this.state.name} onChange={this.handle_ChangeName} />
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div></div>;
  }
}

export default class Periods extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      periods: [],
      visible__add: false,
      loaded: false,
      tzString: "",
    };
  }

  componentDidMount() {
    PeriodStore.listen(this.onChange);

    setTimeout(function () {
      PeriodActions.fetch();
    }, 0);

    Api.get("/managedevice/ajax/device", (result) => {
      let data = result["data"];
      this.setState({ tzString: data["timezone"] });
    });
  }

  componentWillUnmount() {
    PeriodStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      periods: PeriodStore.getPeriods(),
      loaded: PeriodStore.getLoaded(),
    });
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add: false,
    });
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Time Period" onClickHandler={this.handler__clickAdd}></TableCentricPageButton>;
  };

  handle_Delete = (id) => {
    PeriodActions.deletePeriod(id);
  };

  handle__AddPeriod = (name) => {
    const self = this;
    Api.put("/config/device/ajax/periods", { name: name }, function (result) {
      self.context.router.push("/config/device/objects/timeperiods/" + result["result"]["id"]);
      PeriodActions.invalidateAndFetch();
    });
  };

  render__content = () => {
    const timeTitle = (
      <div className="tableHeaderWrapper" title={`Timezone: ${this.state.tzString}`}>
        Name
        <InfoIcon />
      </div>
    );

    const self = this;
    const columns = [
      {
        title: timeTitle,
        data: function (row) {
          return <Link to={"/config/device/objects/timeperiods/" + row["id"]}>{row["name"]}</Link>;
        },
        search: FlexibleTable.search__single_string_field("name"),
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton to={"/config/device/objects/timeperiods/" + row["id"]}></EditButton>
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row["id"])}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.periods} loaded={this.state.loaded} />;
  };

  oldUi = () => {
    return (
      <TableLeftPanel>
        <AddPeriod
          visible={this.state.visible__add}
          handleAdd={this.handle__AddPeriod}
          handleClose={this.handler__clickCloseAdd}
        ></AddPeriod>

        <TableCentricPage icon="fa-solid fa-clock" title="Time Periods" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  };

  render() {
    return <ABTester optionB={this.oldUi()} optionA={<PeriodsNew />} isOptionB={true} />;
  }
}

const PeriodsNew = () => {
  const { t } = useTranslation();
  const title = t("Time Periods");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Objects"), url: "/config/device/objects", isActive: false },
    { title: t("Time Periods"), url: "/config/device/objects/timeperiods", isActive: true },
  ];

  const columns = [
    {
      headerText: t("Name"),
      columnName: "name",
      sortable: true,
      searchable: true,
    },
    {
      headerText: t("Operations"),
      columnName: "operations",
    },
  ];

  const data = [{ name: "Test" }];
  const dataMap = ({ name }, index) => (
    <Tr key={index}>
      <Td>
        <Text>{name}</Text>
      </Td>
      <Td>
        <Flex>
          <Box mr="sp8">
            <TableIconButton
              disabled={true}
              onClick={() => {}}
              icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
            />
          </Box>
          <TableIconButton
            disabled={true}
            onClick={() => {}}
            icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
          />
        </Flex>
      </Td>
    </Tr>
  );

  return <TableBasedPage title={title} breadcrumbs={breadcrumbs} columns={columns} data={data} tableDataMap={dataMap} />;
};

Periods.contextTypes = {
  router: PropTypes.object.isRequired,
};
