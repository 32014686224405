import React from "react";
import Separator from "../../../modules/Separator";
import Api from "../../../utils/Api";

import FlexibleTable from "../../../modules/FlexibleTable";
import ModalWindow from "../../../modules/ModalWindow";
import TableCentricPage from "../../../modules/TableCentricPage";
import TableCentricPageButton from "../../../modules/TableCentricPageButton";
import TableLeftPanel from "../../../utils/TableLeftPanel";

import EditButton from "../../../modules/EditButton";
import DeleteButton from "../../../modules/DeleteButton";
import SearchableSelect from "../../../modules/SearchableSelect";
import { ABTester } from "@familyzone/component-library";
import WmiConfigurationNew from "./WmiConfigurationNew";

class WmiEntry extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      entity: {},
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      entity: nextprops.entity,
    });
  }

  handle_ChangeEnabled = (event) => {
    let copy = this.state.entity;
    copy.enabled = event.target.checked;
    this.setState({
      entity: copy,
    });
  };

  handle_ChangeHostname = (event) => {
    let copy = this.state.entity;
    copy.hostname = event.target.value;
    this.setState({
      entity: copy,
    });
  };

  handle_ChangeDomain = (event) => {
    let copy = this.state.entity;
    copy.domain = event.target.value;
    this.setState({
      entity: copy,
    });
  };

  handle_ChangeUsername = (event) => {
    let copy = this.state.entity;
    copy.username = event.target.value;
    this.setState({
      entity: copy,
    });
  };

  handle_ChangePassword = (event) => {
    let copy = this.state.entity;
    copy.password = event.target.value;
    this.setState({
      entity: copy,
    });
  };

  handle_ChangeExceptions = (event) => {
    let copy = this.state.entity;
    copy.exceptions = event.target.value.split(",");
    this.setState({
      entity: copy,
    });
  };

  handle_ChangeMode = (event) => {
    let copy = this.state.entity;
    copy.mode = event.value;
    this.setState({
      entity: copy,
    });
  };

  handle_Delete = () => {
    this.props.handle_Delete(this.props.entry.id);
  };

  handle_Submit = () => {
    this.props.handle_Save(this.state.entity);
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Save
      </button>
    );
  };

  handleHide = () => {
    this.props.handleHide();
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Edit Domain Controller" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="formgroup">
              <div className="mui-checkbox">
                <input type="checkbox" checked={this.state.entity.enabled} onChange={this.handle_ChangeEnabled} data-cy="enabled" />
                <label>Enabled</label>
              </div>

              <div className="mui-select">
                <div className="formgroup-element">Type</div>
                <SearchableSelect
                  value={this.state.entity.mode}
                  onChange={this.handle_ChangeMode}
                  valueDataMap={[
                    [1, "RADIUS NPS Logins"],
                    [2, "Domain Logins"],
                  ]}
                  data-cy="typeSelect"
                ></SearchableSelect>
              </div>

              <div className="mui-textfield">
                <div className="formgroup-element">Server</div>
                <input type="text" value={this.state.entity.hostname} onChange={this.handle_ChangeHostname} data-cy="server" />
              </div>
              <div className="mui-textfield">
                <div className="formgroup-element">Domain</div>
                <input type="text" value={this.state.entity.domain} onChange={this.handle_ChangeDomain} data-cy="domain" />
              </div>
              <div className="mui-textfield">
                <div className="formgroup-element">Username</div>
                <input type="text" value={this.state.entity.username} onChange={this.handle_ChangeUsername} data-cy="username" />
              </div>
              <div className="mui-textfield">
                <div className="formgroup-element">Password</div>
                <input type="password" value={this.state.entity.password} onChange={this.handle_ChangePassword} data-cy="password" />
              </div>

              <div className="mui-textfield">
                <div className="formgroup-element">User Exceptions</div>
                <input
                  type="text"
                  value={(this.state.entity.exceptions || []).join(",")}
                  onChange={this.handle_ChangeExceptions}
                  data-cy="userExceptions"
                />
              </div>
            </div>
          </form>
        </ModalWindow>
      );
    }
    return <div />;
  }
}

export default class WmiConfiguration extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      configuration: [],
      status: {},
      loaded: false,
      edit_open: false,
      edit_entry: undefined,
    };

    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/config/ajax/authentication/wmic", function (result) {
      if (result["configuration"]) {
        self.setState({
          configuration: result["configuration"],
          status: result["status"],
          loaded: true,
        });
      }
    });
  };

  handle_Add = () => {
    let self = this;
    console.log("handle_Add");
    Api.put(
      "/config/ajax/authentication/wmic",
      {},
      function (e) {
        self.handle_load();
      },
      function (error) {
        self.handle_load();
      }
    );
  };

  handle_Delete = (id) => {
    let self = this;

    let ret = [];
    for (let x of this.state.configuration) {
      if (x.id !== id) {
        ret.push(x);
      }
    }

    Api.post(
      "/config/ajax/authentication/wmic",
      { configuration: ret },
      function (e) {
        self.handle_load();
      },
      function (error) {}
    );
  };

  handle_Edit = (row) => {
    this.setState({
      edit_open: true,
      edit_entry: row,
    });
  };

  handle_HideEdit = (row) => {
    this.setState({
      edit_open: false,
    });
  };

  handle_Save = (entry_to_save) => {
    this.setState({ edit_open: false });

    let self = this;
    let entries = [];
    for (let entry of this.state.configuration) {
      if (entry.id === entry_to_save.id) {
        entries.push(entry_to_save);
      } else {
        entries.push(entry);
      }
    }

    Api.post(
      "/config/ajax/authentication/wmic",
      { configuration: entries },
      function (e) {
        self.handle_load();
      },
      function (error) {}
    );
  };

  typeText = (row) => {
    if (row["mode"] === 2) {
      return "Domain Login Events";
    }
    if (row["mode"] === 1) {
      return "NPS RADIUS Events";
    }

    return "";
  };

  sortable__type = (rowA, rowB) => {
    return FlexibleTable.sort_caseinsensitive_strings(this.typeText(rowA), this.typeText(rowB));
  };

  exceptionsText = (row) => {
    return (row.exceptions || []).join(",");
  };

  sortable__exceptions = (rowA, rowB) => {
    return FlexibleTable.sort_caseinsensitive_strings(this.exceptionsText(rowA), this.exceptionsText(rowB));
  };

  statusText = (row) => (row["enabled"] ? "Enabled" : "Disabled");

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Status",
        data: self.statusText,
        sortable: FlexibleTable.sortable__single_bool_field("enabled"),
        search: FlexibleTable.search__with_function(self.statusText),
        onclick: undefined,
      },
      {
        title: "Server",
        data: function (row) {
          return row["hostname"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("hostname"),
        search: FlexibleTable.search__single_string_field("hostname"),
        onclick: undefined,
      },
      {
        title: "Domain",
        data: function (row) {
          return row["domain"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("domain"),
        search: FlexibleTable.search__single_string_field("domain"),
        onclick: undefined,
      },
      {
        title: "Type",
        data: self.typeText,
        sortable: self.sortable__type,
        search: FlexibleTable.search__with_function(self.typeText),
        onclick: undefined,
      },
      {
        title: "Username",
        data: function (row) {
          return row["username"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("username"),
        search: FlexibleTable.search__single_string_field("username"),
        onclick: undefined,
      },
      {
        title: "Exceptions",
        data: self.exceptionsText,
        sortable: self.sortable__exceptions,
        search: FlexibleTable.search__with_function(self.exceptionsText),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          if (row["id"] !== "") {
            return (
              <div>
                <EditButton onClick={() => self.handle_Edit(row)}>Edit</EditButton>
                <Separator />
                <DeleteButton onClick={() => self.handle_Delete(row["id"])} />
              </div>
            );
          }
          return <div />;
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.configuration} loaded={this.state.loaded} />;
  };

  render__buttons = () => {
    return <TableCentricPageButton title="Add Domain Controller" onClickHandler={this.handle_Add} />;
  };

  render() {
    return (
      <ABTester
        optionB={
          <TableLeftPanel>
            <WmiEntry
              visible={this.state.edit_open}
              entity={this.state.edit_entry}
              handle_Save={this.handle_Save}
              handleHide={this.handle_HideEdit}
            />
            <TableCentricPage icon="fa fa-user" title="WMI" buttons={this.render__buttons()} content={this.render__content()} />
          </TableLeftPanel>
        }
        optionA={<WmiConfigurationNew data={this.state.configuration} loaded={this.state.loaded} />}
        isOptionB={true}
      />
    );
  }
}
