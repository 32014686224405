import { FormControl } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Button, Modal, useTranslation } from "@familyzone/component-library";
import React from "react";

import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria, IpObject } from "../../modules/criteria/criteriaTypes";
import { getObjects } from "../../pages/filtering/ApiHelpers";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

interface IWalledGardenSelectionModalProps {
  handleHide: () => void;
  isOpen: boolean;
  saveSelection: (criteria: FilteringSourceCriteria[]) => void;
  selectedCriteria: FilteringSourceCriteria[];
  updateParentState: (criteria: FilteringSourceCriteria[]) => void;
  isExclusion: boolean;
}

const WalledGardenSelectionModal: React.FC<IWalledGardenSelectionModalProps> = ({
  handleHide,
  isOpen,
  saveSelection,
  selectedCriteria,
  updateParentState,
  isExclusion,
}) => {
  const { t } = useTranslation();
  const [selections, setSelections] = React.useState<FilteringSourceCriteria[]>(selectedCriteria);
  const [ipObjects, setIpObjects] = React.useState<IpObject[]>([]);
  const [loading, setLoading] = React.useState(true);

  const inclusionOptions: Partial<CriteriaTypes> = {
    group: "Group",
    "source.user": "User",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "ipv4.range": "Network Range",
    ipv4: "Network",
  };

  const exclusionOptions: Partial<CriteriaTypes> = {
    signature: "Signature",
    "application.http.hostname.regex": "Website",
    "application.http.hostname": "Website Objects",
    group: "Group",
    "source.user": "User",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
  };

  React.useEffect(() => {
    const populateIPObjects = async () => {
      const result = await getObjects();
      const ipObjects = result.filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0);
      setIpObjects(ipObjects);
      setLoading(false);
    };
    void populateIPObjects();
  }, []);

  const handleSave = () => {
    saveSelection(selections);
  };

  const handleChange = (criteria: FilteringSourceCriteria[]) => {
    updateParentState(criteria);
    setSelections(criteria);
  };

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={() => handleHide()}
      headerText={t(`Edit ${isExclusion ? "Exclusion" : "Inclusion"}`)}
      contentProps={{ style: { overflow: "scroll" } }}
    >
      <>
        {/* Show the loader so that criteria is correctly populated */}
        {loading && <CardSpinner />}
        {!loading && (
          <Stack spacing="sp12" mt="sp8">
            <FormControl>
              <CriteriaSelector
                ipObjects={ipObjects}
                customOptions={isExclusion ? exclusionOptions : inclusionOptions}
                selected={selections}
                onChange={(criteria) => handleChange(criteria)}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <Button variant="primary" onClick={handleSave}>
                Save {isExclusion ? "Exclusion" : "Inclusion"}
              </Button>
            </FormControl>
          </Stack>
        )}
      </>
    </Modal>
  );
};

export default WalledGardenSelectionModal;
