export class CSVObject {
  constructor(file) {
    this.file = file;
    this.entries = new Map();
    this.lineErrors = 0;
    this.nameErrors = 0;
    this.macErrors = 0;
    this.linesRead = 0;
    this.uniqueEntries = 0;
    this.processed = false;
  }

  getFile() {
    return this.file;
  }

  getEntries() {
    return this.entries;
  }

  setEntries(entries) {
    this.entries = entries;
  }

  setFile(file) {
    this.file = file;
  }

  addEntry(name, macAddress) {
    if (!this.entries[name]) {
      this.entries[name] = new Set();
    }
    this.entries[name].add(macAddress);
  }

  addLineError() {
    this.lineErrors++;
  }

  addNameError() {
    this.nameErrors++;
  }

  addMacError() {
    this.macErrors++;
  }

  addLineRead() {
    this.linesRead++;
  }

  getUniqueEntries() {
    if (this.processed) {
      if (this.uniqueEntries > 0) {
        return this.uniqueEntries;
      }
      this.uniqueEntries = this.calculateUniqueEntries();
    }
    return this.calculateUniqueEntries();
  }

  calculateUniqueEntries() {
    let uniqueEntries = 0;
    let map = this.entries;
    Object.keys(map).forEach(function (key) {
      let value = map[key];
      // eslint-disable-next-line no-unused-vars
      for (let macEntry of value) {
        uniqueEntries++;
      }
    });
    return uniqueEntries;
  }

  getProcessed() {
    return this.processed;
  }

  setProcessed() {
    this.processed = true;
  }
}
