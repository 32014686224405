import React, { useLayoutEffect, useRef, useMemo } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useForceUpdate } from "../../js/utils/hooks/useForceUpdate";
import ComponentLoading from "../../js/modules/ComponentLoading";
import clsx from "clsx";
import NoData from "../../js/modules/NoData";
import { Box } from "@familyzone/component-library";

export const PieChart = ({ data, dataLoaded, legendOrientation, showPercentage, chartName }) => {
  const chartRef = useRef(null);
  const forceUpdate = useForceUpdate();

  const chartColorList = useMemo(
    () => [
      new am4core.color("#2F2CB7"),
      new am4core.color("#A56EFF"),
      new am4core.color("#DD7A01"),
      new am4core.color("#06A59A"),
      new am4core.color("#0D9DDA"),
      new am4core.color("#E3066A"),
    ],
    []
  );

  const useSingleColumnLegend = useRef(true);
  const legend = () => {
    const hoverSlice = (item, hover) => {
      return () => {
        const slice = chartRef?.current?.series.values[0].slices.getIndex(item);
        if (slice) {
          slice.isHover = hover;
        }
      };
    };

    const showLegendPercentage = (i, percent) => {
      if (showPercentage) {
        return (
          <div className={clsx("legend-value", !useSingleColumnLegend.current && i % 2 === 0 && "legend-value-inner")}>({percent}%)</div>
        );
      }
    };
    let total = 0;
    chartRef.current?.data?.forEach((item) => (total += item.transfer));
    return chartRef.current?.data.map((item, i) => {
      const itemId = `legend-item-${i}`;
      const itemStyle = chartColorList[i];
      const rgb = `rgb(${itemStyle._value.r},${itemStyle._value.g},${itemStyle._value.b})`;
      const percent = Math.round((item.transfer / total) * 1000) / 10; // Round to one decimal place to match the graph
      return (
        <Box
          onMouseOver={hoverSlice(i, true)}
          onMouseOut={hoverSlice(i, false)}
          cursor="default"
          width="100%"
          float="left"
          margin="4px 0"
          fontSize="14px"
          fontWeight="normal"
          id={`${itemId}`}
        >
          <Box width="10px" height="10px" borderRadius="50%" float="left" margin="4px 6px 0 0" margin-left="6px" bg={rgb} />
          <Box maxW="80%" whiteSpace="nowrap" overflow="hidden" float="left" textOverflow="ellipsis">
            {item.app}
          </Box>
          {showLegendPercentage(i, percent)}
        </Box>
      );
    });
  };

  useLayoutEffect(() => {
    const chart = am4core.create(`personaTopSixAppsChart_${chartName}`, am4charts.PieChart);
    chart.numberFormatter.numberFormat = "#.0";

    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "transfer";
    pieSeries.dataFields.category = "app";
    pieSeries.slices.template.propertyFields.dummyData = "formatTransfer";
    pieSeries.slices.template.tooltipText = "{category}: {value.percent}% ({dummyData})";

    chart.innerRadius = am4core.percent(60);
    chart.radius = am4core.percent(80);
    pieSeries.slices.template.stroke = am4core.color("#ffffff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.tooltip.zIndex = Infinity;

    const colorSet = new am4core.ColorSet();
    colorSet.list = chartColorList;

    pieSeries.colors = colorSet;

    chartRef.current = chart;
  }, [chartName, chartColorList]);

  useLayoutEffect(() => {
    const chart = chartRef.current;
    chart.data = data?.map((x) => {
      if (x["name"] === "") {
        x["name"] = "Other";
      }
      return {
        app: x["name"],
        transfer: x.y,
      };
    });
    chart.data.sort(function (a, b) {
      return b["transfer"] - a["transfer"];
    });

    forceUpdate();
  }, [forceUpdate, data]);

  const showNoData = dataLoaded && chartRef.current?.data?.length === 0;
  return (
    <Box margin="1px" height="100%" display="flex" flexDir="row" position="relative">
      {!dataLoaded && (
        <Box top="50%" margin="auto">
          <ComponentLoading />
        </Box>
      )}
      {showNoData && <NoData />}
      <Box
        width="70%"
        padding="1px"
        id={`personaTopSixAppsChart_${chartName}`}
        className={clsx("", (!dataLoaded || showNoData) && "hidePersonaChart")}
      ></Box>
      <Box width="50%" display="flex" alignItems="center">
        <Box width="100%" color="#51595d" marginRight="5px">
          {legend()}
        </Box>
      </Box>
    </Box>
  );
};
