import React from "react";
import ReactDOM from "react-dom";

class PortalHoc extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      domNode: null,
    };
  }

  componentDidMount() {
    this.setState({ domNode: document.getElementById(this.props.elementId) });
  }

  render() {
    return this.state.domNode ? ReactDOM.createPortal(this.props.children, this.state.domNode) : null;
  }
}

export default PortalHoc;
