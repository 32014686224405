import { Box } from "@familyzone/component-library";
import React, { ReactNode } from "react";
import BaseModal from "./BaseModal";

interface SleekModalProps {
  show: boolean;
  handleHide: () => void;
  buttons?: ReactNode;
  centerButtons?: boolean;
  noBasePadding?: boolean;
  className?: string;
  title?: string;
  subTitle?: React.ReactNode;
  description?: string;
  children?: ReactNode;
}

const SleekModal: React.FC<SleekModalProps> = ({
  show,
  handleHide,
  buttons,
  centerButtons,
  noBasePadding,
  className,
  title,
  subTitle,
  description,
  children,
}) => {
  return (
    <BaseModal show={show} handleHide={handleHide} buttons={buttons} centerButtons={centerButtons} noBasePadding={noBasePadding}>
      <Box className={className}>
        {title && <h3 className="sleek-modal-title">{title}</h3>}
        {subTitle && (
          <Box textAlign={"center"} marginBottom={"5px"}>
            {subTitle}
          </Box>
        )}
        {description && (
          <Box textAlign={"center"} marginBottom={"24px"}>
            {description}
          </Box>
        )}
        {children}
      </Box>
    </BaseModal>
  );
};

export default SleekModal;
