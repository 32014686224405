import { Box, Button, Flex, Option, SingleSearchSelector, Text, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import { useDebounce } from "react-use";
import { UserUMS } from "../../types/Users";
import { mapPrefillToOption, mapUserToOptions, search } from "./UserSearchHelper";

interface Props {
  id?: string;
  buttonText?: string;
  handleSubmit?: (selected: Option | null) => void;
  handleChange?: (selected: Option | null) => void;
  placeholderText: string;
  prefill?: UserUMS | string;
  disabled?: boolean;
  clear?: number;
  showClearIcon?: boolean;
  showHelpText?: boolean;
  clearOnSelect?: boolean;
  submitDisabled?: boolean;
}

export const UserSearchSelector: React.FC<Props> = ({
  id,
  buttonText,
  handleSubmit,
  handleChange,
  placeholderText,
  prefill,
  disabled,
  clear,
  showClearIcon = false,
  showHelpText = true,
  clearOnSelect,
  submitDisabled = false,
}) => {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const valueIsPresent = (e: unknown): e is React.ChangeEvent<HTMLInputElement> => typeof e === "object" && e !== null && "target" in e;
  const [selected, setSelected] = React.useState<Option | null>(mapPrefillToOption(prefill));
  const [users, setUsers] = React.useState<UserUMS[]>([]);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

  const [val, setVal] = React.useState("");
  const [debouncedValue, setDebouncedValue] = React.useState("");

  const onChange = (option: Option | null, e: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    if (option) setSelected(option);
    if (valueIsPresent(e)) {
      setVal(e.target.value);
      if (handleChange) handleChange(option);
    }

    if (option && clearOnSelect) {
      setSelected(null);
      setUsers([]);
    }
  };

  useDebounce(
    () => {
      setDebouncedValue(val?.trim());
    },
    500,
    [val]
  );

  React.useEffect(() => {
    setSelected(mapPrefillToOption(prefill));
  }, [clear, prefill]);

  React.useEffect(() => {
    if (!val || val.length < 3) setShowDropdown(false);
  }, [val]);

  React.useEffect(() => {
    if (debouncedValue && debouncedValue.length >= 3) {
      void search(debouncedValue)
        .then((result) => setUsers(result))
        .catch(() =>
          errorToast({ title: t("Error searching"), description: t("There was an error searching for users, please try again later.") })
        );
      setShowDropdown(true);
    } else {
      setUsers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Box overflow="visible" position="relative">
      <SingleSearchSelector
        id={id}
        name="user-selector"
        placeholder={placeholderText}
        options={mapUserToOptions(users)}
        disabled={disabled}
        selected={selected}
        onChange={onChange}
        showDropdownIcon={false}
        showDropdown={showDropdown}
        searchDelay={0}
        showClearIcon={showClearIcon}
        filterOptions={false}
      />
      {showHelpText && (
        <Text color="neutrals.200" mt="sp4" fontSize="sm">
          {t("Please enter 3 or more characters.")}
        </Text>
      )}
      {handleSubmit && buttonText && (
        <Flex mt="sp24">
          <Box>
            <Button variant="primary" disabled={!selected || submitDisabled} onClick={() => handleSubmit(selected)}>
              {t(buttonText)}
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
};
