import { GridItem, Box } from "@familyzone/component-library";
import React from "react";
import ColumnGraph from "../../../modules/ColumnGraph";
import Api from "../../../utils/Api";
import CardSpinner from "./CardSpinner";
import { dataSorter } from "./GraphCardHelpers";
import { ApiDates } from "../../../utils/DateTimeUtil";

interface IGraphCardProps {
  user: string;
  dateFilters: ApiDates;
}

export interface UserTransferSummaryData {
  today_transfer: number;
  yesterday_transfer: number;
  week_transfer: number;
  month_transfer: number;
  transfer: {
    deviceid: string;
    upload: number;
    download: number;
    transfer: number;
    day: string;
  }[];
}

const GraphCard = ({ user, dateFilters }: IGraphCardProps): JSX.Element => {
  const [series, setSeries] = React.useState<{ name: string; gapSize: number; data: number[][] }[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    prepareSeries(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dateFilters]);

  const prepareSeries = (user: string) => {
    setLoading(true);
    Api.get_analytics(
      "/surfwize/analytics/transfer/summary/user/" + user,
      (data: UserTransferSummaryData) => {
        setSeries([{ name: "Check", gapSize: 0, data: dataSorter(data) }]);
        setLoading(false);
      },
      (_: unknown) => {
        setLoading(false);
      },
      { dateOverride: dateFilters }
    );
  };

  return (
    <GridItem colSpan={8} background="neutrals.0" borderRadius="sm">
      <Box borderRadius="md" background="neutrals.10">
        {loading ? <CardSpinner /> : <ColumnGraph data={series} />}
      </Box>
    </GridItem>
  );
};

export default GraphCard;
