import React from "react";
import PropTypes from "prop-types";
import Api from "../utils/Api";
import closeIcon from "../../images/IconAnnouncementExit.svg";

import SessionStore from "../stores/SessionStore";
import moment from "moment";

export default class AnnouncementBar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      announcements: [],
    };
  }

  componentDidMount() {
    let self = this;
    let statusio_interval = setInterval(() => {
      // Fetch the status of incidents and maintenances for the announcement banners, this does not need to be frequent, currently set to 10 minutes
      self.fetchAnnouncements();
    }, 600000);

    this.setState({ statusio_interval: statusio_interval });
    setTimeout(() => {
      // Initial call
      self.fetchAnnouncements();
    }, 0);
  }

  fetchAnnouncements = () => {
    let self = this;
    if (SessionStore.getSelectedDevice() !== undefined && SessionStore.getSelectedDevice() != null) {
      Api.get(
        "/announcements",
        function (result) {
          self.statusChange(result);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.statusio_interval);
  }

  checkIfAnnouncementExists = (announcement_id) => {
    let announcements = this.state.announcements;
    for (let i = 0; i < announcements.length; i++) {
      if (announcements[i].id === announcement_id) {
        return true;
      }
    }
    return false;
  };

  statusChange = (statusio) => {
    let announcements = this.state.announcements;
    let self = this;
    if (statusio["announcements"] !== undefined) {
      statusio["announcements"].forEach(function (announcement) {
        if (!self.checkIfAnnouncementExists(announcement.id)) {
          if (announcement.type !== "System Incident") {
            announcements = announcements.concat(self.formatTime(announcement));
          } else {
            announcements = announcements.concat(announcement);
          }
        }
      });
    }
    this.setState({ announcements: announcements });
  };

  formatTime = (announcement) => {
    if (announcement.type === "SYSTEM OUTAGE") {
      return announcement;
    }
    announcement.title = announcement.title.concat(
      moment(announcement.start).format("DD/MM/YY HH:mm"),
      " until ",
      moment(announcement.end).format("DD/MM/YY HH:mm")
    );
    return announcement;
  };

  closeAnnouncement = (announcement_id) => {
    let announcements = this.state.announcements;
    for (let i = 0; i < announcements.length; i++) {
      if (announcements[i].id === announcement_id) {
        announcements[i].hideme = true;
        break;
      }
    }
    this.setState({ announcements: announcements });
  };

  handleLink = () => {
    window.open("http://status.linewize.net/", "_blank");
  };

  render() {
    let self = this;
    return (
      <div className="announcement-bar-container">
        {this.state.announcements
          ? this.state.announcements.map(function (announcement) {
              if (announcement.hideme) {
                return null;
              }
              return (
                <div className="status-bar-open" key={announcement["id"]}>
                  <div className="announcement-type">{announcement.type}</div>
                  <div className="announcement-spacer">-</div>
                  <div className="announcement-title">{announcement.title}</div>
                  <div className="announcement-name">{announcement.name}</div>
                  <button type="button" className="announcement-link" onClick={self.handleLink}>
                    VIEW DETAILS
                  </button>
                  <button onClick={() => self.closeAnnouncement(announcement.id)} className="ab-close-button">
                    <img src={closeIcon} />
                  </button>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

AnnouncementBar.contextTypes = {
  router: PropTypes.object.isRequired,
};
