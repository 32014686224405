import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useTranslation,
  Spinner,
  useToast,
  InlineNotification,
  Flex,
  MultiSearchSelector,
  Option,
} from "@familyzone/component-library";
import CardBasedPage from "../../templates/CardBasedPage";
import {
  getRestrictableApps,
  getScreenshotConfig,
  RestrictableAppsResponseShape,
  saveScreenshotConfig,
  SaveScreenshotConfigPayloadShape,
  ScreenshotConfigResponseShape,
} from "../../../utils/api/ScreenVisibilityRestrictions";
import { optionArrayToStringArray, parseDomain, stringArrayToOptionArray } from "../../shared/DomainsMultiSelectInput/utils";

const ClassroomScreenVisibilityRestrictions: FunctionComponent = () => {
  const { t } = useTranslation();
  const title = t("Screen Visibility Restrictions");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Screen Visibility Restrictions"), isActive: true },
  ];

  const { successToast, errorToast } = useToast();

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [errorLoadingConfig] = useState<boolean>(false);
  const [savingConfig, setSavingConfig] = useState<boolean>(false);

  const [restrictedDomainsOptions, setRestrictedDomainsOptions] = useState<string[]>([]);
  const [restrictedDomainsSelected, setRestrictedDomainsSelected] = useState<string[]>([]);

  const [restrictedAppsOptions, setRestrictedAppsOptions] = useState<Option[]>([]);
  const [restrictedAppsSelected, setRestrictedAppsSelected] = useState<Option[]>([]);

  const restrictedDomainsInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedDomain = parseDomain(e.target.value);
    if (parsedDomain === null) {
      setRestrictedDomainsOptions([]);
      return;
    }
    setRestrictedDomainsOptions([parsedDomain]);
  };

  const restrictedDomainsChanged = (domainOptions: Option[]) => {
    setRestrictedDomainsSelected(optionArrayToStringArray(domainOptions));
    setRestrictedDomainsOptions([]);
  };

  const restrictedAppsChanged = (appOptions: Option[]) => {
    setRestrictedAppsSelected(appOptions);
  };

  async function fetchScreenshotConfig(): Promise<void> {
    setLoadingConfig(true);

    const restrictableApps = await getRestrictableApps();
    const restrictableAppsJson = (await restrictableApps.json()) as RestrictableAppsResponseShape;
    setRestrictedAppsOptions(restrictableAppsJson.applications.map((x) => ({ value: x.id, label: x.name })));

    const config = await getScreenshotConfig();
    const configJson = (await config.json()) as ScreenshotConfigResponseShape;
    const restrictedDomains = configJson.restrictedDomains;
    const restrictedApps = configJson.restrictedApps.map((x) => ({ value: x.id, label: x.label }));

    setRestrictedDomainsSelected(restrictedDomains);
    setRestrictedAppsSelected(restrictedApps);

    setLoadingConfig(false);
  }

  useEffect(() => {
    fetchScreenshotConfig().then(
      () => {
        setLoadingConfig(false);
      },
      () => {
        setLoadingConfig(false);
      }
    );
  }, []);

  function saveConfiguration(): void {
    setSavingConfig(true);

    // form correct payload based on configuration
    const screenshotConfigPayload: SaveScreenshotConfigPayloadShape = {
      restrictedDomains: restrictedDomainsSelected,
      restrictedAppIds: restrictedAppsSelected.map((x) => String(x.value)),
    };

    saveScreenshotConfig(screenshotConfigPayload)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save screenshot config");
        }
        successToast({
          title: t("Saved successfully"),
          description: t("Screenshot configuration has been saved successfully."),
          position: "top",
          isClosable: true,
        });
      })
      .catch(() => {
        errorToast({
          title: t("Error saving screenshot configuration"),
          description: t("Please contact support if the issue persists."),
          position: "top",
          isClosable: true,
        });
        setSavingConfig(false);
      })
      .finally(() => {
        setSavingConfig(false);
      });

    return;
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        <Box borderRadius="sm" p="sp12" backgroundColor="neutrals.20" mb={30}>
          <Text fontFamily="Poppins" fontSize="sm" color="text.paragraph.regular" role="heading">
            Stop teachers from seeing student screens and screenshots in Live View, Screen View and the Screenshot History Report for these
            domains and apps.
          </Text>
        </Box>

        {loadingConfig ? (
          <Spinner />
        ) : (
          <>
            <Flex my={30} data-testid="restricted-domains-selector">
              <Box width={240}>
                <Text color="text.paragraph.regular">Restricted Domains / Subdomains</Text>
              </Box>
              <Box flexGrow={1}>
                <MultiSearchSelector
                  placeholder={t("Paste domain/subdomain URLs")}
                  options={stringArrayToOptionArray(restrictedDomainsOptions)}
                  selected={stringArrayToOptionArray(restrictedDomainsSelected)}
                  onInputChange={restrictedDomainsInputChanged}
                  onChange={restrictedDomainsChanged}
                  clearInputOnSelect
                  filterOptions={false}
                />
                <Text mt="6" ml="4" color="text.paragraph.regular" fontSize={"sm"}>
                  You can block individual domains (example.com) and subdomains (test.example.com), or use the * wildcard to block a domain
                  and all its subdomains (*.example.com)
                </Text>
              </Box>
            </Flex>

            <Flex my={30} data-testid="restricted-app-selector">
              <Box width={240}>
                <Text color="text.paragraph.regular">Restricted Windows Apps</Text>
              </Box>
              <Box flexGrow={1}>
                <MultiSearchSelector
                  placeholder={"Select Windows applications"}
                  options={restrictedAppsOptions}
                  onChange={restrictedAppsChanged}
                  selected={restrictedAppsSelected}
                  clearInputOnSelect
                />
                <Text mt="6" ml="4" color="text.paragraph.regular" fontSize="sm">
                  Can&apos;t see your testing app? Contact Linewize Support.
                </Text>
              </Box>
            </Flex>
          </>
        )}
        {errorLoadingConfig && (
          <Box mb={10}>
            <InlineNotification
              notificationTitle={t("Error fetching ClassroomScreenVisibilityRestrictions configuration")}
              notificationDescription={t("Please contact support if the issue persists.")}
              status="error"
            />
          </Box>
        )}
      </Box>
      <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
        <Button
          variant="primary"
          disabled={savingConfig || loadingConfig}
          onClick={saveConfiguration}
          data-testid="save-screenshot-config-button"
        >
          {savingConfig ? t("Saving") : t("Save")}
        </Button>
      </Box>
    </CardBasedPage>
  );
};

export default ClassroomScreenVisibilityRestrictions;
