import React from "react";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";

import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default class DiagnosticsCoredumps extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      coredumps: [],
      loaded: false,
      tzString: "",
    };
  }

  handle_load = () => {
    let self = this;
    Api.get("/managedevice/ajax/device/coredumps", function (result) {
      self.setState({
        coredumps: result["data"],
        loaded: true,
      });
    });
  };

  componentDidMount() {
    Api.get("/managedevice/ajax/device", (result) => {
      let data = result["data"];
      this.setState({ tzString: data["timezone"] });
    });
    this.handle_load();
  }

  render__buttons = () => {
    return <div />;
  };

  handle_Download = (file_entity) => {
    window.location = "/managedevice/ajax/device/coredump/" + file_entity["file"];
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Time",
        data: function (row) {
          let tz = self.state.tzString || "UTC";
          let day = dayjs.unix(row["timestamp"]).tz(tz);
          return day.format("YYYY-MM-DD HH:mm:ss ZZ");
        },
        sortable: FlexibleTable.sortable__single_numeric_field("timestamp"),
        search: FlexibleTable.search__single_date_field("timestamp"),
        onclick: undefined,
      },
      {
        title: "Filename",
        data: function (row) {
          return row["file"];
        },
        sortable: FlexibleTable.sortable__single_caseinsensitive_string_field("file"),
        search: FlexibleTable.search__single_string_field("file"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a onClick={() => self.handle_Download(row)}>Download</a>;
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.coredumps} loaded={this.state.loaded} sort_asc={false} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <TableCentricPage icon="fa fa-ban" title="Coredumps" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
