/* Adapted from validate.js URL regex, which can be found here https://gist.github.com/dperini/729294
 * validate.js links to that gist from here http://validatejs.org/#validators-url
 * Adaptions:
 *   Remove protocol, as we don't allow entering a protocol
 *   Remove url based authentication, as it doesn't make sense for our usage
 *   Allow plain TLDs https://tools.ietf.org/html/rfc1034
 */
const websiteRegex =
  /^(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:\d{1,2}|1\d\d|2[0-4]\d|25[0-5]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?|(?:\.[a-z]{2,63})$/i;

export function isValidWebsite(website: string): boolean {
  return websiteRegex.test(website);
}

/**
 * Defines a regexp that will match any
 * valid IPv4 CIDR notation from 0.0.0.0/0
 * to 255.255.255.255/32
 *
 * @type {RegExp}
 */
const ipv4CIDRSubnetRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(?:3[0-2]|[12]?[0-9])$/;

/**
 * Defines a regexp that will match any
 * valid Ipv4 Subnet Mask notation from
 * 0.0.0.0/0.0.0.0 to 255.255.255.255/255.255.255.255
 *
 * @type {RegExp}
 */
const ipv4MaskSubnetRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export function isValidIPv4Subnet(subnet: string): boolean {
  return ipv4CIDRSubnetRegex.test(subnet) || ipv4MaskSubnetRegex.test(subnet);
}

/**
 * Regex to check for an IP address, disallowing leading zeros
 * 1.2.3.4 -> pass
 * 1.2.3.04 -> fail
 * @type {RegExp}
 */
const IPAddressRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;
const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidIPAddress(IPAddressCandidate: string | number): boolean {
  if (typeof IPAddressCandidate === "number") return false;
  return IPAddressRegex.test(IPAddressCandidate);
}

const IPRangeRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])-(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;

export function isValidIPRange(IPRangeCandidate: string): boolean {
  return IPRangeRegex.test(IPRangeCandidate);
}

const MacAddressRegex = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/;

export function isValidMacAddress(macAddress: string): boolean {
  return MacAddressRegex.test(macAddress);
}

const SingleMacAddressRegex = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2})+$/;

export function isValidSingleMacAddress(macAddress: string): boolean {
  return SingleMacAddressRegex.test(macAddress);
}

export function isNotEmpty(field: unknown[] | string | number): boolean {
  if (typeof field === "number") return true;
  return !!field && field.length > 0;
}

export function isValidEmail(email: string): boolean {
  return EmailRegex.test(email);
}

export function areUniqueArrays(twoDimensionalArray: unknown[][]): boolean {
  if (twoDimensionalArray[0] === undefined) return false;
  if (twoDimensionalArray[1] === undefined) return false;
  return twoDimensionalArray[0].filter((item) => twoDimensionalArray[1].find((item2) => item === item2)).length === 0;
}

export const MINIMUM_PASSWORD_LENGTH = 16;

export function isValidPassword(password: string): boolean {
  return !!password && password.length >= MINIMUM_PASSWORD_LENGTH;
}

/**
 * Compares a search string to an array of strings. Returns either true or false
 * depending on if the search string is found inside any of the strings inside.
 * the array.
 */
export function fastSearch(search: string, keywords: string[]): boolean {
  if (!search || typeof search !== "string") return true;
  const trimmedSearch = search.toLowerCase();
  return keywords.some((keyword) => trimmedSearch.includes(keyword));
}

/**
 * Compares a keyword to another string. Returns either true or false
 * depending on if the keyword is found inside the string.
 */
export function searchString(keyword: string, string: string): boolean {
  if (!keyword || typeof keyword !== "string" || !string || typeof string !== "string") return true;
  return string.toLowerCase().includes(keyword.toLowerCase());
}

// This function is the re-introduction of old code that was removed and refactored above, but caused errors.
// Rather than spend time figuring out what is broken in the new code, we're just adding the old code back and keeping it until the old UI
// is removed.
/**
 * Compares a search string to an array of strings. Returns either true or false
 * depending on if the search string is found inside any of the strings inside.
 * the array.
 */
export function fastSearchOld(search?: string | number, keywords?: string[]): boolean {
  if (search === "") return true;
  if (!search || !keywords) return false;
  // Turn search data into an easily searchable string.
  const trimmedSearch = search.toString().trim().toLowerCase();

  // Return true if any of the keywords contain the search string.
  const found = keywords.some((keyword) => {
    const trimmedKeyword = keyword.toString().toLowerCase();

    if (trimmedKeyword.includes(trimmedSearch)) {
      return true;
    }
    return false;
  });
  // Return the found value
  return found;
}

// This function is the re-introduction of old code that was removed and refactored above, but caused errors.
// Rather than spend time figuring out what is broken in the new code, we're just adding the old code back and keeping it until the old UI
// is removed.
/**
 * Compares a keyword to another string. Returns either true or false
 * depending on if the keyword is found inside the string.
 */
export function searchStringOld(keyword?: string, string?: string): boolean {
  if (keyword === "") return true;
  if (!keyword || !string) return false;

  // Turn keyword and string into easily searchable strings.
  const trimmedKeyword = keyword.toString().trim().toLowerCase();
  const trimmedString = string.toString().toLowerCase();
  return trimmedString.includes(trimmedKeyword);
}
