import { headers } from "./ApiHelpers";

/**
 * Get all apps that can be restricted.
 *
 * Used to populate the restricted applications dropdown on the Config > Classwize > Screenshots config page.
 */
export function getRestrictableApps(): Promise<Response> {
  return fetch(`/api/classrooms/screenshots/restrictable-applications`, {
    method: "GET",
    headers: headers(),
  });
}

export interface RestrictableAppsResponseShape {
  applications: {
    id: string;
    name: string;
  }[];
}

/**
 * Fetches the customer's screenshot configuration
 */
export function getScreenshotConfig(): Promise<Response> {
  return fetch(`/api/classrooms/screenshots/configuration`, {
    method: "GET",
    headers: headers(),
  });
}

export interface ScreenshotConfigResponseShape {
  restrictedDomains: string[];
  restrictedApps: {
    id: string;
    label: string;
  }[];
}

/**
 * Saves the screenshot configuration. This will overwrite the full configuration each time, no deltas.
 *
 * 200 - Created or updated successfully
 *
 * 400 - Request validation failed
 */
export function saveScreenshotConfig(config: SaveScreenshotConfigPayloadShape): Promise<Response> {
  return fetch(`/api/classrooms/screenshots/configuration`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(config),
  });
}

export interface SaveScreenshotConfigPayloadShape {
  restrictedDomains: string[];
  restrictedAppIds: string[];
}
