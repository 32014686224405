import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Button, Input, Modal, Select, useTranslation } from "@familyzone/component-library";
import { Item } from "@familyzone/component-library/dist/components/Select";
import React from "react";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria, Fingerprint, IpObject, MacObject } from "../../modules/criteria/criteriaTypes";
import { getFingerprints, getObjects } from "../../pages/filtering/ApiHelpers";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

interface IPriorityQosModalProps {
  handleHide: () => void;
  isOpen: boolean;
  saveSelection: (rule: unknown) => void;
  selectedSourceCriteria: FilteringSourceCriteria[];
  selectedDestinationCriteria: FilteringSourceCriteria[];
  updateParentSourceState: (criteria: FilteringSourceCriteria[]) => void;
  updateParentPriorityState: (value: number) => void;
  updateParentDestinationState: (criteria: FilteringSourceCriteria[]) => void;
  updateParentNameState: (name: string) => void;
  rule: {
    comment: string;
    dscp_value: number;
    enabled?: boolean;
    id?: string;
    source_criteria?: FilteringSourceCriteria[];
    destination_criteria?: FilteringSourceCriteria[];
  };
}

const PriorityQosModal: React.FC<IPriorityQosModalProps> = ({
  handleHide,
  isOpen,
  saveSelection,
  selectedSourceCriteria,
  selectedDestinationCriteria,
  updateParentDestinationState,
  updateParentPriorityState,
  updateParentSourceState,
  updateParentNameState,
  rule,
}) => {
  const { t } = useTranslation();
  const [sourceSelection, setSourceSelection] = React.useState<FilteringSourceCriteria[]>(selectedSourceCriteria);
  const [destinationSelection, setDestinationSelection] = React.useState<FilteringSourceCriteria[]>(selectedDestinationCriteria);
  const [ipObjects, setIpObjects] = React.useState<IpObject[]>([]);
  const [macObjects, setMacObjects] = React.useState<MacObject[]>([]);
  const [fingerprints, setFingerprints] = React.useState<Fingerprint[]>([]);
  const [loading, setLoading] = React.useState(true);

  const sourceOptions: Partial<CriteriaTypes> = {
    "source.user": "User",
    protocol: "Protocol",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "source.mac": "Mac Address",
    fingerprint: "Device Type",
    "source.mac.pool": "Mac Address Object",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
    timeperiod: "Time Periods",
    group: "Group",
  };

  const destinationOptions: Partial<CriteriaTypes> = {
    "application.http.contenttype.regex": "Content Type",
    "application.http.useragent.regex": "User Agent",
    "application.http.request.regex": "HTTP Request Path",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "application.http.hostname.regex": "Website",
    signature: "Signature",
    "application.http.hostname": "Website Objects",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
    geoip: "Country",
  };

  const handleChangeSource = (criteria: FilteringSourceCriteria[]) => {
    updateParentSourceState(criteria);
    setSourceSelection(criteria);
  };

  const handleChangeDestination = (criteria: FilteringSourceCriteria[]) => {
    updateParentDestinationState(criteria);
    setDestinationSelection(criteria);
  };

  const handleChangePriority = (item: Item | null) => {
    if (!item) return;
    updateParentPriorityState(parseInt(item.value, 10));
  };

  const handleSave = () => {
    saveSelection(rule);
  };

  React.useEffect(() => {
    const populateObjects = async () => {
      const result = await getObjects();
      setFingerprints(await getFingerprints());
      const ipObjects = result.filter((obj): obj is IpObject => obj.type === 1 || obj.type === 0);
      const macObjects = result.filter((obj): obj is MacObject => obj.type === 4);
      setIpObjects(ipObjects);
      setMacObjects(macObjects);
      setLoading(false);
    };
    void populateObjects();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={() => handleHide()}
      headerText={t("Edit Rule")}
      contentProps={{ style: { overflow: "scroll" } }}
    >
      <>
        {loading && <CardSpinner />}
        {!loading && (
          <Stack spacing="sp12" mt="sp8">
            <FormControl>
              <FormLabel>{t("Name")}</FormLabel>
              <Input value={rule.comment} onChange={(e) => updateParentNameState(e.target.value)} placeholder="Rule Name" />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Source")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                macObjects={macObjects}
                fingerprints={fingerprints}
                customOptions={sourceOptions}
                selected={sourceSelection}
                onChange={(criteria) => handleChangeSource(criteria)}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Destination")}</FormLabel>
              <CriteriaSelector
                ipObjects={ipObjects}
                customOptions={destinationOptions}
                selected={destinationSelection}
                onChange={(criteria) => handleChangeDestination(criteria)}
                errors={[]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Priority")}</FormLabel>
              <Select
                items={QosValueDataMap.map((item) => ({ value: item.value.toString(), text: item.text }))}
                placeholder={rule.dscp_value.toString()}
                isFiltered
                onChange={handleChangePriority}
              />
            </FormControl>
            <FormControl>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>
            </FormControl>
          </Stack>
        )}
      </>
    </Modal>
  );
};

export default PriorityQosModal;

const QosValueDataMap = [
  { value: 0, text: "0 - Best Effort" },
  { value: 10, text: "10 - AF11" },
  { value: 12, text: "12 - AF12" },
  { value: 14, text: "14 - AF13" },
  { value: 18, text: "18 - AF21" },
  { value: 20, text: "20 - AF22" },
  { value: 26, text: "26 - AF31" },
  { value: 28, text: "28 - AF32" },
  { value: 30, text: "30 - AF33" },
  { value: 34, text: "34 - AF34" },
  { value: 36, text: "36 - AF42" },
  { value: 38, text: "38 - AF43" },
  { value: 8, text: "8 - CS1" },
  { value: 16, text: "16 - CS2" },
  { value: 24, text: "24 - CS3" },
  { value: 32, text: "32 - CS4" },
  { value: 40, text: "40 - CS5" },
  { value: 48, text: "48 - CS6" },
  { value: 56, text: "56 - CS7" },
  { value: 46, text: "46 - EF" },
];
