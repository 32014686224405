import { Box, Text } from "@familyzone/component-library";
import React from "react";

interface TableCentricPageProps {
  title: string;
  subTitle?: React.ReactNode;
  buttons: React.ReactNode;
  content: React.ReactNode;
}

const TableCentricPage: React.FC<TableCentricPageProps> = ({ title, subTitle, buttons, content, children }) => {
  return (
    <Box padding={"20px 40px"} height={"100%"} backgroundColor={"#f0f0f0"}>
      <Box>
        <Box margin={"10px 0 20px 0"}>
          <Box id="fz_table_header" display={"flex"} alignItems={"center"} backgroundColor={"unset"}>
            <Text lineHeight={1} order={1} color={"#333"} fontSize={"24px"}>
              {title}
            </Text>
            <Box id="fz_table_header_buttons" order={4} marginLeft={"auto"} display={"flex"} alignItems={"center"} gap={"16px"}>
              {buttons}
            </Box>
          </Box>
          {subTitle && subTitle}
        </Box>
        <Box>
          {content}
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default TableCentricPage;
