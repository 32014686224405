import React from "react";
import PropTypes from "prop-types";

import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import TimeRangeSelector from "../../modules/TimeRangeSelector";
import { convertToPrintableTime, isValidTime } from "../../utils/DateTimeUtil";

import PeriodActions from "../../actions/PeriodActions";
import { ABTester, Box, useTranslation } from "@familyzone/component-library";
import CardBasedPage from "../templates/CardBasedPage";

export default class Period extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      period: {
        name: "",
        startTime: 0,
        endTime: 0,
      },
      saving: false,
      onLoadStartTime: "",
      onLoadEndTime: "",
      processedStartTime: "",
      processedEndTime: "",
      validStartEndTime: true,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  __convertToSphirewallTime = (time) => {
    const hours = parseInt(time.slice(0, 2)) * 100;
    const minutes = parseInt(time.slice(3, 5));
    return hours + minutes;
  };

  handle_load = () => {
    const self = this;
    Api.get("/config/device/ajax/periods/" + this.props.params.timeperiod, function (result) {
      const period = result["period"];

      const start = convertToPrintableTime(period["startTime"]);
      const end = convertToPrintableTime(period["endTime"]);
      const validTimes = self.areStartEndValid(start, end);

      self.setState({
        period: period,
        processedStartTime: start,
        processedEndTime: end,
        onLoadStartTime: start,
        onLoadEndTime: end,
        validStartEndTime: validTimes,
      });
    });
  };

  handle_Submit = () => {
    const self = this;
    self.setState({ saving: true });
    const startTime = self.__convertToSphirewallTime(self.state.processedStartTime);
    const endTime = self.__convertToSphirewallTime(self.state.processedEndTime);
    self.state.period["startTime"] = startTime;
    self.state.period["endTime"] = endTime;

    Api.post(
      "/config/device/ajax/periods/" + this.props.params.timeperiod,
      this.state.period,
      function (e) {
        self.setState({ saving: false });
        self.context.router.push("/config/device/objects/timeperiods");

        PeriodActions.invalidateAndFetch();
      },
      function (error) {}
    );
  };

  handle_ChangeName = (event) => {
    const period = this.state.period;
    period["name"] = event.target.value;
    this.setState({
      period: period,
    });
  };

  handle_ChangeStartDate = (event) => {
    const period = this.state.period;
    period["startDateString"] = event.target.value;
    this.setState({
      period: period,
    });
  };

  handle_ChangeEndDate = (event) => {
    const period = this.state.period;
    period["endDateString"] = event.target.value;
    this.setState({
      period: period,
    });
  };

  handle_ChangeAny = (event) => {
    const period = this.state.period;
    period["any"] = event.target.checked;

    if (event.target.checked) {
      period["mon"] = false;
      period["tue"] = false;
      period["wed"] = false;
      period["thu"] = false;
      period["fri"] = false;
      period["sat"] = false;
      period["sun"] = false;
    }

    this.setState({
      period: period,
    });
  };

  handle_ChangeMon = (event) => this.handle_ChangeDay(event, "mon");
  handle_ChangeTue = (event) => this.handle_ChangeDay(event, "tue");
  handle_ChangeWed = (event) => this.handle_ChangeDay(event, "wed");
  handle_ChangeThu = (event) => this.handle_ChangeDay(event, "thu");
  handle_ChangeFri = (event) => this.handle_ChangeDay(event, "fri");
  handle_ChangeSat = (event) => this.handle_ChangeDay(event, "sat");
  handle_ChangeSun = (event) => this.handle_ChangeDay(event, "sun");

  handle_ChangeDay = (event, day) => {
    const period = this.state.period;
    if (event.target.checked) {
      period["any"] = false;
    }

    period[day] = event.target.checked;
    this.setState({
      period: period,
    });
  };

  getPrintableStartDate = () => {
    let value = "" + this.state.period.startTime;
    if (this.state.period.startTime < 60) {
      value += "00" + this.state.period.startTime;
    }

    if (this.state.period.startTime < 600) {
      value += "0" + this.state.period.startTime;
    }

    const hours = value.slice(0, 2);
    const minutes = value.slice(2, 4);
    return hours + ":" + minutes;
  };

  timeUpdatedCallback = (startTime, endTime) => {
    const valid = this.areStartEndValid(startTime, endTime);

    this.setState({
      processedStartTime: startTime,
      processedEndTime: endTime,
      validStartEndTime: valid,
    });
  };

  areStartEndValid = (start, end) => {
    let valid = false;

    if (isValidTime(start) && isValidTime(end) && this.__convertToSphirewallTime(end) > this.__convertToSphirewallTime(start)) {
      valid = true;
    }

    return valid;
  };

  areDaysValid = (period) => {
    const isDayOfWeekSelected = this.isDayOfWeekSelected(period);
    return (isDayOfWeekSelected && period.any !== true) || (!isDayOfWeekSelected && period.any === true); // This is an XOR operation.
  };

  isDayOfWeekSelected = (period) => {
    if (period.mon) {
      return true;
    }
    if (period.tue) {
      return true;
    }
    if (period.wed) {
      return true;
    }
    if (period.thu) {
      return true;
    }
    if (period.fri) {
      return true;
    }
    if (period.sat) {
      return true;
    }
    if (period.sun) {
      return true;
    }

    return false;
  };

  oldUI = () => {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Manage Time Period</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Name</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <input type="text" value={this.state.period.name} onChange={this.handle_ChangeName} />
                      </div>
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Time (24hours)</div>
                    <div className="formgroup-element-fields">
                      <TimeRangeSelector
                        startTime={this.state.onLoadStartTime}
                        endTime={this.state.onLoadEndTime}
                        updateCallback={this.timeUpdatedCallback}
                      ></TimeRangeSelector>
                      {!this.state.validStartEndTime && (
                        <p className="AlertBanner--error">
                          Start time should be less than end time, and entered in HH:MM format. For example, 08:30 to 22:00.{" "}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="formgroup-element">
                    <div className="formgroup-element-title">Days</div>
                    <div className="formgroup-element-fields">
                      <div className="mui-textfield">
                        <table id="tp-days">
                          <thead>
                            <tr>
                              <th className="all-days">All</th>
                              <th>Mon</th>
                              <th>Tue</th>
                              <th>Wed</th>
                              <th>Thu</th>
                              <th>Fri</th>
                              <th>Sat</th>
                              <th>Sun</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="all-days">
                                <input type="checkbox" name="any" checked={this.state.period.any} onChange={this.handle_ChangeAny} />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="mon"
                                  id="mon"
                                  checked={this.state.period.mon}
                                  onChange={this.handle_ChangeMon}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="tue"
                                  id="tue"
                                  checked={this.state.period.tue}
                                  onChange={this.handle_ChangeTue}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="wed"
                                  id="wed"
                                  checked={this.state.period.wed}
                                  onChange={this.handle_ChangeWed}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="thu"
                                  id="thu"
                                  checked={this.state.period.thu}
                                  onChange={this.handle_ChangeThu}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="fri"
                                  id="fri"
                                  checked={this.state.period.fri}
                                  onChange={this.handle_ChangeFri}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="sat"
                                  id="sat"
                                  checked={this.state.period.sat}
                                  onChange={this.handle_ChangeSat}
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="sun"
                                  id="sun"
                                  checked={this.state.period.sun}
                                  onChange={this.handle_ChangeSun}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {!this.areDaysValid(this.state.period) && <p className="AlertBanner--error">Please select one or more days.</p>}
                    </div>
                  </div>

                  {!this.isDayOfWeekSelected(this.state.period) && (
                    <div className="formgroup-element">
                      <div className="formgroup-element-title">
                        Date
                        <br />
                        (yyyy-mm-dd)
                      </div>
                      <div className="formgroup-element-fields">
                        <div className="formgroup-element-fields-inline">
                          <div className="mui-textfield">
                            <input
                              type="text"
                              value={this.state.period.startDateString}
                              onChange={this.handle_ChangeStartDate}
                              disabled={this.isDayOfWeekSelected(this.state.period)}
                              placeholder="2021-04-23"
                            />
                          </div>
                          <div className="inline-block">to</div>
                          <div className="mui-textfield">
                            <input
                              type="text"
                              value={this.state.period.endDateString}
                              disabled={this.isDayOfWeekSelected(this.state.period)}
                              onChange={this.handle_ChangeEndDate}
                              placeholder="2021-04-25"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              disabled={!this.state.validStartEndTime || !this.areDaysValid(this.state.period)}
              onClick={this.handle_Submit}
              saving={this.state.saving}
              changes={true}
            ></LetterPanelSaveButton>
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  };

  render() {
    return <ABTester optionB={this.oldUI()} optionA={<PeriodNew />} isOptionB={true} />;
  }
}

const PeriodNew = () => {
  const { t } = useTranslation();

  const title = t("Manage Time Period");

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Objects"), url: "/config/device/objects", isActive: false },
    { title: t("Time Periods"), url: "/config/device/timeperiods", isActive: false },
    { title: t("Manage Time Period"), isActive: true },
  ];

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box minHeight="200px" />
    </CardBasedPage>
  );
};

Period.contextTypes = {
  router: PropTypes.object.isRequired,
};
