import React from "react";
import get from "lodash.get";

import LeftPanel from "../utils/LeftPanel";
import Api from "../utils/Api";
import LetterPanel from "../modules/LetterPanel";
import LetterPanelHeader from "../modules/LetterPanelHeader";
import LetterPanelBody from "../modules/LetterPanelBody";
import LetterPanelFooter from "../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../modules/LetterPanelSaveButton";
import SessionStore from "../stores/SessionStore";
import exclamationIcon from "../../images/error.svg";
import { PasswordChanger, MessageHint, PASSWORD_ERROR_MESSAGE } from "./PasswordChanger";

export default class Account extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      password1: "",
      password2: "",
      phoneNumber: "",
      saving: false,
      changes: false,
      isSupportAdmin: SessionStore.isSupportAdmin(),
      username: SessionStore.getUsername(),
      status: "info",
      msg: "",
      showPasswordMessageHint: true,
    };
    this.unsubAccountStore = null;
  }

  componentDidMount = () => {
    Api.get("/settings/account", (result) => {
      const user = result["result"];
      this.setState({
        phoneNumber: user["phone_number"],
      });
    });
    SessionStore.listen(this.sessionStoreListener);
  };

  componentWillUnmount = () => {
    SessionStore.unlisten(this.sessionStoreListener);
  };

  sessionStoreListener = () => {
    this.setState({
      isSupportAdmin: SessionStore.isSupportAdmin(),
      username: SessionStore.getUsername(),
    });
  };

  handleSave = () => {
    const data = {
      password: this.state.password1,
      repassword: this.state.password2,
      phone_number: this.state.phoneNumber,
    };

    if (this.state.saving || !this.state.changes) {
      return;
    }

    this.setState({ saving: true });

    Api.post(
      "/settings/account",
      data,
      (_r) => {
        this.setState({
          saving: false,
          changes: false,
        });
      },
      (r, e) => {
        const error_message = get(r, "responseJSON.error");

        if (!error_message) {
          console.error("Error saving:", r, e);
        }

        this.setState({
          showPasswordMessageHint: false,
          saving: false,
          changes: true,
          status: "error",
          msg: PASSWORD_ERROR_MESSAGE,
        });
      }
    );
  };

  handleChangePassword = (e) => {
    this.setState({
      showPasswordMessageHint: true,
    });
    this.updatePasswords(e);
  };

  updatePasswords = (e) => {
    this.setState({
      changes: true,
      [e.target.id]: e.target.value,
    });
  };

  handleChangePhoneNumber = (event) => {
    this.setState({
      phoneNumber: event.target.value,
      changes: true,
    });
  };

  render = () => {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>Account Settings</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form" id="accountSettings">
              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Change Password</div>
                </div>
                {this.state.isSupportAdmin ? (
                  <div className="message-content">
                    <div className="warning">
                      <img src={exclamationIcon} alt="" />
                      Admin users can only sign-in through Google
                    </div>
                    <div className="well">
                      <div className="googleLogo" />
                      <div className="stack">
                        <div className="title">Authenticated with Google</div>
                        <div className="username">{this.state.username}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="formgroup-content">
                    <PasswordChanger
                      showConfirmation
                      showWhyUrl
                      onChangePassword={this.handleChangePassword}
                      password1={this.state.password1}
                      password2={this.state.password2}
                      showMessageHint={this.state.showPasswordMessageHint}
                    />
                    {
                      /* Toggle between PasswordChanger's MessageHint and ours. */
                      !this.state.showPasswordMessageHint && <MessageHint status={this.state.status} msg={this.state.msg} />
                    }
                  </div>
                )}
              </div>

              <div className="formgroup">
                <div className="formgroup_title">
                  <div className="formgroup_title_title">Phone Number</div>
                </div>
                <div className="formgroup-content">
                  <div className="mui-textfield account-phone-number">
                    <input type="tel" value={this.state.phoneNumber} onChange={this.handleChangePhoneNumber} />
                    <label>Phone Number</label>
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              onClick={this.handleSave}
              saving={this.state.saving}
              changes={this.state.changes}
            ></LetterPanelSaveButton>
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  };
}
