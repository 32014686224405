import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import Api from "../../utils/Api";
import CaptivePortalStore from "../../stores/CaptivePortalStore";
import CaptivePortalActions from "../../actions/CaptivePortalActions";

import CriteriaPrinter from "../../modules/CriteriaPrinter";
import FlexibleTable from "../../modules/FlexibleTable";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";

import TableLeftPanel from "../../utils/TableLeftPanel";
import Separator from "../../modules/Separator";

import EditButton from "../../modules/EditButton";
import DeleteButton from "../../modules/DeleteButton";
import { ABTester, ButtonTd, Flex, Td, Text, Tr, useTranslation } from "@familyzone/component-library";
import TableBasedPage from "../templates/TableBasedPage";

const AuthenticationNew = ({ data, loaded, onModalButtonClick, onDelete }) => {
  const { t } = useTranslation();
  const [formattedData, setFormattedData] = useState([]);
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Authentication"), url: "/config/device/authentication", isActive: false },
    { title: t("Captive Portal"), isActive: true },
  ];

  const formatData = (unformatted) => {
    return {
      id: unformatted["id"],
      name: unformatted["name"],
      network: unformatted["inclusions"],
    };
  };
  useEffect(() => {
    setFormattedData(data.map(formatData));
  }, [data]);

  const items = ["Name", "Network"];

  const columns = [];
  items.forEach((item) => {
    columns.push({
      headerText: t(item),
      columnName: item.toLowerCase().replace(/\s/g, ""),
      sortable: true,
      searchable: true,
      exportable: true,
    });
  });

  columns.push({ headerText: "Operations", columnName: "operations" });

  const dataMap = ({ id, name, network }, index) => (
    <Tr key={index}>
      <Td>
        <Text fontSize="md">{name ? name : "-"}</Text>
      </Td>
      <Td>
        <Text fontSize="md">
          <CriteriaPrinter criteria={network}></CriteriaPrinter>
        </Text>
      </Td>
      <Td>
        <Flex>
          <Link to={`/config/device/authentication/portals/${id}`}>
            <ButtonTd buttonIconName="fa-pencil" />
          </Link>
          <ButtonTd buttonIconName="fa-trash" onClick={() => onDelete(id)} />
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <TableBasedPage
      title={t("Captive Portal")}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={formattedData}
      tableDataMap={dataMap}
      loaded={loaded}
    />
  );
};

export default class Authentication extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      portals: [],
      loaded: false,
    };
  }

  componentDidMount() {
    CaptivePortalStore.listen(this.onChange);

    setTimeout(() => {
      CaptivePortalActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    CaptivePortalStore.unlisten(this.onChange);
  }

  onChange = () => {
    if (CaptivePortalStore.hasLoaded()) {
      this.setState({
        portals: CaptivePortalStore.getPortals(),
        loaded: true,
      });
    }
  };

  handler__clickAddAuthenticationConfig = () => {
    let self = this;
    Api.put(
      "/config/ajax/authentication",
      {},
      function (data) {
        CaptivePortalActions.fetch();
        self.context.router.push("/config/device/authentication/portals/" + data["result"]["id"]);
      },
      function (err) {}
    );
  };

  render__buttons = () => {
    return (
      <TableCentricPageButton
        title="Add Captive Portal"
        onClickHandler={this.handler__clickAddAuthenticationConfig}
      ></TableCentricPageButton>
    );
  };

  render__content = () => {
    const columns = [
      {
        title: "Name",
        data: function (row) {
          return <Link to={"/config/device/authentication/portals/" + row["id"]}>{row["name"]}</Link>;
        },
        search: FlexibleTable.search__single_string_field("name"),
        onclick: undefined,
      },
      {
        title: "Network",
        data: function (row) {
          return <CriteriaPrinter criteria={row.inclusions}></CriteriaPrinter>;
        },
        search: FlexibleTable.search__criteria_printer("inclusions"),
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          return (
            <div>
              <EditButton to={"/config/device/authentication/portals/" + row["id"]}></EditButton>
              <Separator />
              <DeleteButton onClick={() => CaptivePortalActions.deletePortal(row["id"])}></DeleteButton>
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.portals} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <ABTester
        optionB={
          <TableLeftPanel>
            <TableCentricPage icon="fa fa-user" title="Captive Portal" buttons={this.render__buttons()} content={this.render__content()} />
          </TableLeftPanel>
        }
        optionA={
          <AuthenticationNew
            data={this.state.portals}
            loaded={this.state.loaded}
            onDelete={(id) => CaptivePortalActions.deletePortal(id)}
          />
        }
        isOptionB={true}
      />
    );
  }
}

Authentication.contextTypes = {
  router: PropTypes.object.isRequired,
};
