import React from "react";
import { MultiSearchSelector, Option, Text, useTranslation } from "@familyzone/component-library";
import { FilteringSourceCriteria, SecurityGroup } from "../criteriaTypes";

export interface SecurityGroupCriteriaProps {
  criteria: FilteringSourceCriteria;
  disabled: boolean;
  onChange: (criteria: FilteringSourceCriteria) => void;
  securityGroups: SecurityGroup[];
}

const SecurityGroupCriteria: React.FC<SecurityGroupCriteriaProps> = ({ criteria, disabled, onChange, securityGroups }) => {
  const { t } = useTranslation();

  if (!Array.isArray(criteria.conditions)) {
    return <Text>{t("Invalid Criteria")}</Text>;
  }

  // Map the list of users to a selector-usable array of label/value pairs
  const securityGroupOptions: Option[] = securityGroups.map((i) => ({ label: i.name, value: i.id }));

  // Take the criteria conditions and convert them to label/value pairs
  const selectedSecurityGroupOptions: Option[] = criteria.conditions
    .map((id) => securityGroupOptions.find((o) => o.value === id))
    .filter((b): b is Option => !!b);

  const handleChangeSecurityGroups = (options: Option[]) => {
    onChange({ ...criteria, conditions: [...options.map((o: Option) => securityGroups.find((u) => u.id === o.value)?.id || "")] });
  };

  return (
    <MultiSearchSelector
      disabled={disabled}
      placeholder={t("Select Security Groups")}
      name="security-group-selector"
      options={securityGroupOptions}
      selected={selectedSecurityGroupOptions}
      onChange={handleChangeSecurityGroups}
    />
  );
};

export default SecurityGroupCriteria;
