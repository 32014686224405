import React from "react";
import LineReader from "../../../../utils/LineReader";

export class ImportScheduleFile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: 0,
      readerCallback: this.props.readerCallback,
    };
    this.csvObject = this.props.csvObject;
    this.VALID_COLUMN_COUNT = 4;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.csvObject) {
      this.csvObject = this.props.csvObject;
    }
  }

  componentDidMount() {
    if (!this.csvObject.processed) {
      let lr = new LineReader();
      lr.on("line", this.readLine);
      lr.on("progress", this.updateProgress);
      lr.on("end", this.loadDone);
      lr.read(this.csvObject.getFile());
    }
  }

  updateProgress = (evt) => {
    if (evt < 100) {
      this.setState({ loading: evt });
    }
  };

  readLine = (line, callback) => {
    this.csvObject.addLineRead();
    let entries = line.split(",");
    if (entries.length !== this.VALID_COLUMN_COUNT) {
      console.log("Bad Line: [" + line + "]");
      this.csvObject.addLineError();
      callback();
      return;
    }
    let classroom = entries[0].trim().replace(/['"]+/g, "");
    let day = entries[1].trim().replace(/['"]+/g, "");
    let sched_start = parseInt(entries[2].trim().replace(/['"]+/g, ""));
    let sched_end = parseInt(entries[3].trim().replace(/['"]+/g, ""));
    if (classroom === "" || day === "" || !sched_start || !sched_end) {
      console.log("Bad Line: [" + line + "]");
      this.csvObject.addLineError();
      callback();
      return;
    }
    if (["mon", "tue", "wed", "thur", "fri", "sat", "sun"].indexOf(day.toLowerCase()) >= 0) {
      if (sched_start < sched_end && sched_start > 0 && sched_start < 2345 && sched_end > 0 && sched_end < 2345) {
        this.csvObject.addEntry(classroom, day, sched_start, sched_end);
      } else {
        console.log("Invalid Period: " + sched_start + "-" + sched_end + " [" + line + "]");
        this.csvObject.addPeriodError();
      }
    } else {
      console.log("Invalid Day: " + day + " [" + line + "]");
      this.csvObject.addDayError();
    }
    callback();
  };

  loadDone = () => {
    this.csvObject.setProcessed(true);
    this.setState({ loading: 100 });
    if (this.state.readerCallback) {
      this.state.readerCallback(this.csvObject);
    }
  };

  handle_deleteFile = () => {
    this.props.deleteFile(this.csvObject);
  };

  render_issues() {
    let errorLog = [];

    if (this.csvObject.lineErrors > 0) {
      errorLog.push(
        <div key={"lineErrors"} className={"errorItem"}>
          {this.csvObject.lineErrors} Invalid Lines
        </div>
      );
    }
    if (this.csvObject.classErrors > 0) {
      errorLog.push(
        <div key={"classErrors"} className={"errorItem"}>
          {this.csvObject.classErrors} Unknown Classrooms
        </div>
      );
    }
    if (this.csvObject.dayErrors > 0) {
      errorLog.push(
        <div key={"dayErrors"} className={"errorItem"}>
          {this.csvObject.dayErrors} Invalid Days
        </div>
      );
    }
    if (this.csvObject.periodErrors > 0) {
      errorLog.push(
        <div key={"periodErrors"} className={"errorItem"}>
          {this.csvObject.periodErrors} Invalid Periods
        </div>
      );
    }

    if (errorLog.length > 0) {
      return (
        <div className="importErrors">
          {this.csvObject.lineErrors + this.csvObject.classErrors + this.csvObject.dayErrors + this.csvObject.periodErrors}
          <i className="fa fa-exclamation-triangle" aria-hidden="true">
            <div className={"errorTooltip"}>
              <i className={"fa fa-caret-left"} aria-hidden="true" />
              {errorLog}
            </div>
          </i>
        </div>
      );
    }
    return <div className="importErrors" />;
  }

  render() {
    let file = this.csvObject.getFile();
    if (this.state.loading !== 100) {
      return (
        <div className="file">
          <div className={"fileName"}> {file.name} </div>
          <div className={"fileInfo"}>
            <div className={"loadingEntries"}>Loading ({this.state.loading.toFixed(0)}%)</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="file">
          <div className={"fileName"}> {file.name} </div>
          <div className={"fileInfo"}>
            <div className={"uniqueEntries"}>
              {this.csvObject.getUniqueEntries().classrooms} <i className="fa fa-users" title={"Classrooms"} aria-hidden="true" />
            </div>
            <div className={"uniqueEntries"}>
              {this.csvObject.getUniqueEntries().periods} <i className="fa-solid fa-clock" title={"Periods"} aria-hidden="true" />
            </div>
            {this.render_issues()}
            <div className={"deleteButton"}>
              <i className="fa fa-times" aria-hidden="true" onClick={this.handle_deleteFile} />
            </div>
          </div>
        </div>
      );
    }
  }
}
