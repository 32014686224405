import {
  Box,
  Button,
  Flex,
  Icon,
  InlineNotification,
  TableIconButton,
  Td,
  Text,
  Tr,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import React, { FC, useEffect, useState } from "react";
import { DeleteButtonNew } from "../../../modules/DeleteButtonNew";
import TableBasedPage from "../../templates/TableBasedPage";
import Link from "../../Link";
import { ClassroomsImportCSVModal } from "./CSVImport/ClassroomsImportCSVModal";
import SessionStore from "../../../stores/SessionStore";
import ClassroomsPurgeModal from "./ClassroomsPurgeModal";
import AddClassroom from "./AddClassroom";
import { Classroom } from "../../../types/Classrooms";
import { deleteClassroom, getClassrooms } from "../../../utils/api/Classrooms";
import ClassroomSearchBox from "./ClassroomsSearchBox";

export const ClassroomsNew: FC = () => {
  const { t } = useTranslation();

  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [visibleImportCsv, setVisibleImportCsv] = useState(false);
  const [isSupportAdmin, setIsSupportAdmin] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const fetchClassrooms = async () => {
    setLoaded(false);
    try {
      setClassrooms(await getClassrooms());
      setLoaded(true);
    } catch (e) {
      errorToast({ title: "Error", description: "Failed to load. Please refresh the page", isClosable: true });
    }
  };

  useEffect(() => {
    void fetchClassrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call*/
  useEffect(() => {
    onChange();

    SessionStore.listen(onChange);

    return () => {
      SessionStore.unlisten(onChange);
    };
  }, []);

  /* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
  const onChange = () => {
    setIsSupportAdmin(SessionStore.isSupportAdmin());
  };
  /* eslint-enable */

  const handleClickImport = () => {
    setVisibleImportCsv(true);
  };

  const handleClickCloseImport = () => {
    setVisibleImportCsv(false);
  };

  const { successToast, errorToast } = useToast();
  const handleDelete = async (id: string) => {
    try {
      await deleteClassroom(id);
      successToast({ title: "Success!", description: "The classroom has been deleted." });
      setLoaded(false);
      const index = classrooms.findIndex((classoom: Classroom) => classoom.id === id);
      classrooms.splice(index, 1);
      setClassrooms(classrooms);
      setLoaded(true);
    } catch (error) {
      errorToast({
        title: "Error",
        description: "We're not able to process the request at this time. Please try again later.",
      });
    }
  };

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Classrooms"), isActive: true },
  ];

  const columns = [
    { headerText: t("Name"), columnName: "name", sortable: true, searchable: true },
    { headerText: t("Provider"), columnName: "sourceType", sortable: true, searchable: true },
    { headerText: t("Operations"), columnName: "operations", sortable: false, searchable: false },
  ];

  const operations = (row: Classroom): JSX.Element => {
    const { sourceType, id } = row;

    if (sourceType !== undefined && (sourceType.toLowerCase() === "local" || sourceType.toLowerCase() === "csv")) {
      return (
        <Flex>
          <Box mr="sp8">
            <Link to={`/config/device/classwize/classrooms/${encodeURIComponent(id)}`}>
              <TableIconButton
                data-testid="classrooms-edit-button"
                aria-label="Edit"
                icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
              />
            </Link>
          </Box>
          <Box mr="sp8">
            <DeleteButtonNew
              onClick={() =>
                void (async () => {
                  await handleDelete(id);
                })()
              }
              titleToDelete="Classroom"
              bodyToDelete="classroom"
            />
          </Box>
        </Flex>
      );
    }

    return (
      <Link to={`/config/device/classwize/classrooms/${encodeURIComponent(id)}`}>
        <TableIconButton
          data-testid="classrooms-view-button"
          aria-label="View"
          icon={<Icon icon="fa-eye" variant="solid" color="text.paragraph.light" />}
        />
      </Link>
    );
  };

  const tableDataMap = (row: Classroom, index: number): JSX.Element => {
    const { name, sourceType, id } = row;

    return (
      <Tr key={index}>
        <Td data-testid="classroom-name">
          <Link to={`/config/device/classwize/classrooms/${encodeURIComponent(id)}`}>
            <Text fontSize={"sm"}>{name}</Text>
          </Link>
        </Td>
        <Td data-testid="classroom-provider">
          <Text>{sourceType}</Text>
        </Td>
        <Td>{operations(row)}</Td>
      </Tr>
    );
  };

  const aboveTableChildren = (): JSX.Element => {
    return (
      <Flex mt="sp24" mr="sp24" justifyContent="end">
        <AddClassroom />
        <Box ml="sp8">
          <Button variant="primary" onClick={handleClickImport}>
            {t("Import CSV")}
          </Button>
        </Box>
        {isSupportAdmin && (
          <Box ml="sp8">
            <ClassroomsPurgeModal />
          </Box>
        )}
      </Flex>
    );
  };

  const withinTableChildren = (): JSX.Element => {
    return (
      <>
        <ClassroomsImportCSVModal visible={visibleImportCsv} handleClose={handleClickCloseImport} />
        <Flex>
          <ClassroomSearchBox
            searchEnabled={loaded}
            onSearchStart={() => {
              setLoaded(false);
              setIsSearching(true);
            }}
            onSearchEnd={(classrooms) => {
              setClassrooms(classrooms);
              setLoaded(true);
            }}
          />
          {loaded && !isSearching && (
            <Box ml="sp12" minW="300px" maxH="sp12">
              <InlineNotification
                notificationDescription="Please use search to display more classrooms."
                notificationTitle={`Showing you ${classrooms.length} classroom(s)`}
                status="info"
              />
            </Box>
          )}
        </Flex>
      </>
    );
  };

  return (
    <TableBasedPage
      title="Classrooms"
      loaded={loaded}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={classrooms}
      tableDataMap={tableDataMap}
      children={aboveTableChildren()}
      childrenInTableHeader={withinTableChildren()}
      showSearch={false}
    />
  );
};
