import { Tag } from "@familyzone/component-library";
import React from "react";

interface UserStatusPillProps {
  archived?: boolean;
}

const ArchivedStatusPill: React.FC<UserStatusPillProps> = ({ archived = false }) => {
  const label = archived ? "Archived" : "Active";
  const variant = archived ? "subtle" : "green";
  return <Tag variant={variant} tagLabel={label} />;
};

export default ArchivedStatusPill;
