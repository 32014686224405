import LoginBackground from "../../images/loginBackground.svg";
import PrivacyPolicy from "./PrivacyPolicy";
import { Box, Flex, Image, LinewizeLogoWithText, Text, ThemeProvider } from "@familyzone/component-library";

const LoginWrapper = ({ header, children, footer = <PrivacyPolicy /> }) => {
  return (
    <ThemeProvider>
      <Flex h="100%">
        <Flex h="100%" w="500px" bg="neutrals.0" p="sp64" direction="column" justifyContent="space-between">
          <Box>
            <LinewizeLogoWithText color="brand.400" maxW="176px" mb="sp64" />
            <Text fontFamily="heading" fontSize="xxl" color="text.title">
              {header}
            </Text>
            {children}
          </Box>
          {footer}
        </Flex>
        <Image src={LoginBackground} alt="Background image showing dashboard" w="auto" h="80%" m="auto" />
      </Flex>
    </ThemeProvider>
  );
};

export default LoginWrapper;
