import Api from "../utils/Api";

import alt from "../alt";
import PeriodActions from "../actions/PeriodActions";
import SessionActions from "../actions/SessionActions";

class PeriodStore {
  constructor() {
    this.periods = [];
    this.loaded = false;

    this.exportPublicMethods({
      getPeriods: this.getPeriods,
      getLoaded: this.getLoaded,
    });

    this.bindListeners({
      handleFetch: PeriodActions.FETCH,
      handleFetchUpdated: PeriodActions.FETCH_UPDATED,
      handleInvalidateAndFetch: PeriodActions.INVALIDATE_AND_FETCH,

      handleAddPeriod: PeriodActions.ADD_PERIOD,
      handleDeletePeriod: PeriodActions.DELETE_PERIOD,
      handleDeselectDevice: SessionActions.DESELECT_DEVICE,
      handleChangeDevice: SessionActions.CHANGE_DEVICE,
    });
  }

  getPeriods = () => {
    return this.periods;
  };

  getLoaded = () => {
    return this.loaded;
  };

  handleDeselectDevice = () => {
    this.handleDestroy();
  };

  handleChangeDevice = () => {
    this.handleDestroy();
  };

  handleDestroy = () => {
    this.periods = [];
    this.loaded = false;
  };

  handleFetchUpdated(result) {
    this.periods = result;
    this.loaded = true;
  }

  handleFetch = () => {
    if (this.periods.length === 0) {
      Api.get("/config/device/ajax/periods", function (result) {
        const devices = result["data"];
        PeriodActions.fetchUpdated(devices);
      });
    }
  };

  handleInvalidateAndFetch = () => {
    this.periods = [];
    this.handleFetch();
  };

  handleAddPeriod = (name) => {
    Api.put("/config/device/ajax/periods", { name: name }, function () {
      PeriodActions.invalidateAndFetch();
    });
  };

  handleDeletePeriod = (id) => {
    Api.delete("/config/device/ajax/periods", { id: id }, function () {
      PeriodActions.invalidateAndFetch();
    });
  };
}

export default alt.createStore(PeriodStore, "PeriodStore");
