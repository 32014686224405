import { Flex, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { Box, Button, Checkbox, Input, Tag, Text, useToast, useTranslation } from "@familyzone/component-library";
import React from "react";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";
import { FilteringSourceCriteria } from "../../modules/criteria/criteriaTypes";
import Api from "../../utils/Api";
import CardSpinner from "../reporting/UserDashboard/CardSpinner";

interface SyslogConfig {
  regex: string;
  client: string;
  enabled: boolean;
  status: boolean;
  exclude: FilteringSourceCriteria[];
}

interface SyslogStatus {
  failures: number;
  success: number;
}

const SyslogConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [syslogStatus, setSyslogStatus] = React.useState<SyslogStatus>({
    failures: 0,
    success: 0,
  });
  const [syslogConfig, setSyslogConfig] = React.useState<SyslogConfig>({
    enabled: false,
    status: false,
    regex: "",
    client: "",
    exclude: [],
  });

  React.useEffect(() => {
    setLoading(true);
    Api.get("/config/ajax/authentication/syslog", function (result: { configuration: SyslogConfig; status: SyslogStatus }) {
      if (result.configuration) setSyslogConfig(result.configuration);
      if (result.status) setSyslogStatus(result.status);
    });
    setLoading(false);
  }, []);

  const handleSave = () => {
    setSaving(true);
    Api.post(
      "/config/ajax/authentication/syslog",
      syslogConfig,
      function () {
        successToast({ title: t("Success"), description: t("Syslog configuration saved") });
        setSaving(false);
      },
      function () {
        errorToast({ title: t("Error"), description: t("Error saving syslog configuration") });
        setSaving(false);
      }
    );
  };

  const excludeOptions: Partial<CriteriaTypes> = {
    "source.user": "User",
    group: "Group",
  };

  if (loading) return <CardSpinner />;

  return (
    <Box m="sp24" width="100%">
      <Box backgroundColor="white">
        <Flex backgroundColor="white" padding="sp12" direction="column">
          <Stack spacing="sp12" width="80%">
            <Text fontSize="xl">{t("Syslog")}</Text>
            <FormControl>
              <Checkbox
                isChecked={syslogConfig.enabled}
                onChange={() => setSyslogConfig({ ...syslogConfig, enabled: !syslogConfig.enabled })}
              >
                {t("Enabled")}
              </Checkbox>
            </FormControl>
            {syslogConfig.enabled && (
              <>
                <Box>
                  <Text fontSize="md" mb="sp8">
                    {t("Status")}
                  </Text>
                  <Stack direction="row" spacing="8">
                    <Tag variant="green" tagLabel={`Logins: ${syslogStatus.success}`} />
                    <Tag variant="red" tagLabel={`Failures: ${syslogStatus.failures}`} />
                  </Stack>
                </Box>
                <FormControl>
                  <FormLabel>{t("Regex Filter")}</FormLabel>
                  <Input value={syslogConfig.regex} onChange={(e) => setSyslogConfig({ ...syslogConfig, regex: e.target.value })} />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Client")}</FormLabel>
                  <Input value={syslogConfig.client} onChange={(e) => setSyslogConfig({ ...syslogConfig, client: e.target.value })} />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Exclude")}</FormLabel>
                  <CriteriaSelector
                    customOptions={excludeOptions}
                    selected={syslogConfig.exclude}
                    errors={[]}
                    onChange={(selected) => {
                      setSyslogConfig({ ...syslogConfig, exclude: selected });
                    }}
                  />
                </FormControl>
              </>
            )}
          </Stack>
        </Flex>
        <Button maxWidth="auto" ml="sp12" mb="sp24" disabled={saving} isLoading={saving} variant="primary" onClick={() => handleSave()}>
          {saving ? t("Saving...") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default SyslogConfiguration;
