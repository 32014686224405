import React from "react";
import { Tag } from "@familyzone/component-library";

interface PolicyPillProps {
  type: "block" | "allow" | "lock";
}

const PolicyPill: React.FC<PolicyPillProps> = ({ type }) => {
  switch (type) {
    case "block":
      return <Tag variant="orange" tagLeftIcon="fa fa-ban" tagLabel="Block" />;
    case "allow":
      return <Tag variant="green" tagLeftIcon="fa fa-check" tagLabel="Allow" />;
    default:
      return <Tag variant="subtle" tagLeftIcon="fa fa-lock" tagLabel="Locked" />;
  }
};

export default PolicyPill;
