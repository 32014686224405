import React, { FC, ReactChild, useContext } from "react";
import { Box, Breadcrumb, Flex, Text, ThemeProvider } from "@familyzone/component-library";
import { ReactRouterCompatContext } from "../ContextProvider";
import { InjectedRouter } from "react-router";

export interface PageWithHeaderProps {
  title?: string | React.ReactNode;
  tools?: JSX.Element;
  breadcrumbs?: {
    title: string;
    isActive: boolean;
  }[];
  children?: ReactChild;
}

const PageWithHeader: FC<PageWithHeaderProps> = ({ breadcrumbs, children = <></>, title, tools }) => {
  const { router } = useContext(ReactRouterCompatContext) as { router: InjectedRouter };

  const handlePush = (url: string) => {
    router.push(url);
  };

  return (
    <ThemeProvider>
      <Flex direction="column" flex="1">
        {(breadcrumbs || title) && (
          <Box py="sp16" px="sp24" background="neutrals.10">
            {breadcrumbs && (
              <Flex direction="row">
                <Breadcrumb breadcrumbData={breadcrumbs} push={handlePush} />
              </Flex>
            )}
            {typeof title === "string" ? (
              <Flex direction="row" alignItems="center">
                <Text fontFamily="heading" fontSize="xxl" color="text.title" mb="xl" pb="md" role="heading" flexGrow={1}>
                  {title}
                </Text>
                {tools ?? ""}
              </Flex>
            ) : (
              title
            )}
          </Box>
        )}
        {children}
      </Flex>
    </ThemeProvider>
  );
};

export default PageWithHeader;
