export enum Permission {
  ClassroomAdmin = "classroom_admin",
  ClassroomTechManager = "classroom_ed-tech",
  CloudOnlyFilterAdmin = "surfwize_cloud_filter_admin",
  CloudDNSFilterAdmin = "surfwize_cloud_dns_filter_admin",
  CommunityAdmin = "community_admin",
  ConfigurationAdmin = "surfwize_settings",
  FilteringReportingAdmin = "surfwize_admin",
  FilteringAdmin = "surfwize_filtering",
  GuestConfiguration = "surfwize_guest_settings",
  NetworkAdmin = "edgewize",
  Owner = "owner",
  ReportingAdmin = "surfwize_reporting",
}

/**
 * For detailed descriptions, refer to https://help.linewize.com/hc/en-gb/articles/5765505949852-User-Roles-in-School-Manager
 */
export const PermissionLabels: { [key in Permission]: string } = {
  [Permission.ClassroomAdmin]: "Classroom Administrator",
  [Permission.ClassroomTechManager]: "Classroom Ed-Tech Manager",
  [Permission.CloudOnlyFilterAdmin]: "Cloud Only Filter Administrator",
  [Permission.CloudDNSFilterAdmin]: "Cloud DNS Filter Administrator",
  [Permission.CommunityAdmin]: "Community Administrator",
  [Permission.ConfigurationAdmin]: "Configuration Administrator",
  [Permission.FilteringReportingAdmin]: "Filtering/Reporting Administrator",
  [Permission.FilteringAdmin]: "Filtering Administrator",
  [Permission.GuestConfiguration]: "Guest Configuration",
  [Permission.NetworkAdmin]: "Wan and Firewall Administrator",
  [Permission.Owner]: "Owner/Global Administrator",
  [Permission.ReportingAdmin]: "Reporting/Pastoral Care Administrator",
};
