import React, { createContext } from "react";
import { RouterContext } from "react-router";
import PropTypes from "prop-types";

export const ReactRouterCompatContext = createContext({});

class ReactRouterCompatProvider extends React.PureComponent {
  getChildContext() {
    return {
      router: this.props.router,
    };
  }

  render() {
    return (
      <ReactRouterCompatContext.Provider value={this.getChildContext()}>
        <RouterContext {...this.props} />
      </ReactRouterCompatContext.Provider>
    );
  }
}

ReactRouterCompatProvider.childContextTypes = {
  router: PropTypes.object.isRequired,
};

export default ReactRouterCompatProvider;
