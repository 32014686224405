import Api from "../utils/Api";

import alt from "../alt";
import ConfigActions from "../actions/ConfigActions";

/**
 * @deprecated Use {@link useDeviceOptionsStore} instead.
 *
 * This will be removed by https://familyzone.atlassian.net/browse/COMMUNITY-1214
 */
class ConfigStore {
  constructor() {
    this.config = {};
    this.fetched = false;

    this.exportPublicMethods({
      getConfig: this.getConfig,
      getItemInConfig: this.getItemInConfig,
      isLoaded: this.isLoaded,
    });

    this.bindListeners({
      handleFetch: ConfigActions.FETCH,
      handleSaveConfig: ConfigActions.SAVE_CONFIG,
      handleSetConfig: ConfigActions.SET_CONFIG,
    });
  }

  getConfig = () => {
    return this.config;
  };

  getItemInConfig = (key) => {
    if (this.config[key] !== undefined) {
      return this.config[key];
    }
    return undefined;
  };

  handleSetConfig(result) {
    this.fetched = true;
    this.config = result;
  }

  handleFetch = () => {
    Api.get("/managedevice/ajax/device", function (result) {
      setTimeout(() => {
        ConfigActions.setConfig(result["data"]);
      }, 0);
    });
  };

  handleSaveConfig = (config) => {
    Api.post("/managedevice/ajax/device", config, function () {
      setTimeout(() => {
        ConfigActions.setConfig(config);
      }, 0);
    });
  };

  isLoaded = () => {
    return this.fetched;
  };
}

export default alt.createStore(ConfigStore, "ConfigStore");
