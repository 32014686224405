export const splitAtIndex = (str: string, index: number): [string, string] => {
  return [str.slice(0, index), str.slice(index)];
};

// Split by the first slash which is not prefixed by : or /
export const splitUrlToDomainAndRest = (urlParam: string): [string, string] => {
  let slashIndex = urlParam.indexOf("/");
  while (slashIndex >= 0) {
    if (slashIndex > 0) {
      const charPreceeding = urlParam[slashIndex - 1];
      if (charPreceeding !== ":" && charPreceeding !== "/") {
        return splitAtIndex(urlParam, slashIndex);
      } else slashIndex = urlParam.indexOf("/", slashIndex + 1);
    } else return [urlParam, ""];
  }
  return [urlParam, ""];
};

export const lowercaseDomain = (url: string): string => {
  const split = splitUrlToDomainAndRest(url);
  return split[0].toLowerCase() + split[1];
};

export const getWebsiteValue = (site: string): string => {
  let website = lowercaseDomain(site).split(" ").join("_");
  if (!website.includes(".")) {
    website += ".com";
  }
  return website;
};
