import React from "react";

import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";

export default class Dns extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nameserver: "",
      saving: false,
      changes: true,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = () => {
    const self = this;
    Api.get("/config/device/ajax/network/dns", function (result) {
      const data = result["data"];
      self.setState({
        nameserver: data["nameserver"],
      });
    });
  };

  handle_Submit = () => {
    const self = this;
    self.setState({ changes: false, saving: true });
    Api.post(
      "/config/device/ajax/network/dns",
      { nameserver: this.state.nameserver },
      function (e) {
        self.setState({ saving: false });
        self.handle_load();
      },
      function (error) {}
    );
  };

  handle_ChangeNameserver = (event) => {
    this.setState({ nameserver: event.target.value, changes: true });
  };

  render() {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>DNS</LetterPanelHeader>
          <LetterPanelBody>
            <form className="mui-form">
              <div className="formgroup">
                <div className="formgroup-content">
                  <div className="formgroup-element-title">Nameserver</div>
                  <div className="mui-textfield">
                    <input type="text" value={this.state.nameserver} onChange={this.handle_ChangeNameserver} />
                  </div>
                </div>
              </div>
            </form>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              saving={this.state.saving}
              changes={this.state.changes}
              onClick={this.handle_Submit}
            ></LetterPanelSaveButton>
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
