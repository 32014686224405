import React from "react";
import Api from "../../utils/Api";
import LeftPanel from "../../utils/LeftPanel";

import Header from "../../utils/Header";
import CriteriaSelector from "../../modules/criteria/CriteriaSelector";

class SecurityGroupElement extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      element: this.props.element,
    };
  }

  handle_CriteriaChanged = (criteria) => {
    const element = this.state.element;
    element.criteria = criteria;
    this.setState({ element: element });
    this.props.bubbleUp(element);
  };

  handle_NameChanged = (event) => {
    const element = this.state.element;
    element.name = event.target.value;
    this.setState({ element: element });
    this.props.bubbleUp(element);
  };

  handle_Remove = () => {
    this.props.removeElement(this.state.element.id);
  };

  criteriaOptions = {
    "source.user": "User",
    "application.http.contenttype.regex": "Content Type",
    "application.http.useragent.regex": "User Agent",
    "application.http.request.regex": "HTTP Request Path",
    protocol: "Protocol",
    "ipv4.range": "Network Range",
    ipv4: "Network",
    "ipv4.address": "IP Address",
    "ipv4.alias": "IP Address Object",
    "source.mac": "Mac Address",
    "application.http.hostname.regex": "Website",
    fingerprint: "Device Type",
    signature: "Signature",
    "source.mac.pool": "Mac Address Object",
    "application.http.hostname": "Website Object",
    "transport.port": "Port",
    "transport.portrange": "Port Range",
    device: "Interface",
    geoip: "Country",
    timeperiod: "Time Periods",
  };

  render() {
    return (
      <div className="formgroup">
        <div className="formgroup_title">
          <div className="formgroup_title_title">Options</div>
        </div>
        <div className="formgroup-content">
          <div className="formgroup-element">
            <div className="formgroup-element-title">Name</div>
            <div className="formgroup-element-fields">
              <div className="mui-textfield">
                <input type="text" value={this.state.element.name} onChange={this.handle_NameChanged} />
              </div>
            </div>
            <CriteriaSelector
              selected={this.state.element.criteria}
              onChange={this.handle_CriteriaChanged}
              customOptions={this.criteriaOptions}
            />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={this.handle_Remove}>Remove</a>
          </div>
        </div>
      </div>
    );
  }
}

export default class SecurityGroup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      securitygroup: {
        entries: [],
      },
    };

    this.handle_load();
  }

  handle_load = () => {
    const self = this;
    Api.get("/config/device/ajax/securitygroups/" + this.props.params.securitygroup, function (result) {
      const securitygroup = result["data"];
      self.setState({
        securitygroup: securitygroup,
      });
    });
  };

  handle_Submit = (event) => {
    event.preventDefault();

    const self = this;
    Api.post(
      "/config/device/ajax/securitygroups/" + this.props.params.securitygroup,
      this.state.securitygroup,
      function (e) {
        self.handle_load();
      },
      function (error) {}
    );
  };

  handle_AddEntry = (event) => {
    event.preventDefault();

    const self = this;
    Api.put(
      "/config/device/ajax/securitygroups/" + this.props.params.securitygroup,
      {},
      function (e) {
        self.handle_load();
      },
      function (error) {}
    );
  };

  handle_ElementChanged = (element) => {
    const elements = [];
    const securitygroup = this.state.securitygroup;
    for (let existing_element of securitygroup.entries) {
      if (existing_element.id === element.id) {
        elements.push(element);
      } else {
        elements.push(existing_element);
      }
    }

    securitygroup.entries = elements;
    this.setState({ securitygroup: securitygroup });
  };

  handle_ElementRemove = (element_id) => {
    const elements = [];
    const securitygroup = this.state.securitygroup;
    for (let existing_element of securitygroup.entries) {
      if (existing_element.id === element_id) {
        continue;
      } else {
        elements.push(existing_element);
      }
    }

    securitygroup.entries = elements;
    this.setState({ securitygroup: securitygroup });
  };

  render_Elements = () => {
    const ret = [];
    for (let element of this.state.securitygroup.entries) {
      ret.push(
        <SecurityGroupElement
          element={element}
          removeElement={this.handle_ElementRemove}
          bubbleUp={this.handle_ElementChanged}
        ></SecurityGroupElement>
      );
    }
    return ret;
  };

  render() {
    return (
      <LeftPanel>
        <div className="padded">
          <Header>Manage Security Group</Header>
          <form className="mui-form">
            {this.render_Elements()}
            <button className="letter-panel-footer-save-button letter-panel-footer-save-changes" onClick={this.handle_AddEntry}>
              Add Entry
            </button>
            <button type="submit" className="letter-panel-footer-save-button letter-panel-footer-save-changes" onClick={this.handle_Submit}>
              Save
            </button>
          </form>
        </div>
      </LeftPanel>
    );
  }
}
