import alt from "../alt";

class DeviceStatusActions {
  fetch() {
    return "nothing";
  }

  fetchUpdated(activity) {
    return activity;
  }

  disableStack() {
    return "";
  }

  enableStack() {
    return "";
  }
}

export default alt.createActions(DeviceStatusActions);
