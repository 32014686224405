import Api from "../../utils/Api";
import React, { useState } from "react";
import { useMount } from "react-use";
import TableBasedPage from "../templates/TableBasedPage";
import { Box, Button, Flex, Icon, TableIconButton, Td, Text, Toggle, Tr, useToast, useTranslation } from "@familyzone/component-library";
import { formatUser } from "../UserSearch/UserSearchHelper";
import { isPolicyBindings, isResponse, isUnknownArray, PolicyBinding, tryGetUsersByUsername } from "./DnsFilteringHelper";
import { AddEditBindingModal } from "./AddEditBindingModal";
import { TableColumn } from "../../types/table";
import { UserUMS } from "../../types/Users";

export const DnsFiltering: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast } = useToast();

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editTarget, setEditTarget] = useState<PolicyBinding>();
  const [bindings, setBindings] = useState<PolicyBinding[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<UserUMS[]>([]);

  useMount(() => loadPolicyBindings());

  const loadPolicyBindings = () => {
    setLoaded(false);

    Api.get("/surfwize/device/ajax/dnsfiltering", async (result: unknown) => {
      if (isResponse(result) && isUnknownArray(result.data) && isPolicyBindings(result.data)) {
        setBindings(result.data);

        const users = await tryGetUsersByUsername(
          result.data.map((d) => d.user),
          () => errorToast({ title: "There was an error fetching usernames", isClosable: true })
        );

        setUsers(users);
        setLoaded(true);
      }
    });
  };

  const saveBinding = (binding: PolicyBinding, callback?: (err?: string) => void) => {
    for (const existingBinding of bindings) {
      if (existingBinding.id === binding.id) continue;

      if (existingBinding.objectLists.find((id) => binding.objectLists.includes(id))) {
        if (callback) callback("Policy binding contains subnet group used in an existing binding");
        return;
      }

      if (binding.user === existingBinding.user) {
        if (callback) callback("Policy binding uses a user that already has a binding");
        return;
      }
    }

    if (binding.enabled === undefined) binding.enabled = true;

    if (callback) callback();
    setLoaded(false);

    Api.post("/surfwize/device/ajax/dnsfiltering", binding, () => {
      loadPolicyBindings();
    });
  };

  const deleteBinding = (binding: PolicyBinding) => {
    setLoaded(false);

    Api.delete("/surfwize/device/ajax/dnsfiltering", { id: binding.id }, () => {
      loadPolicyBindings();
    });
  };

  const toggleBindingEnable = (binding: PolicyBinding) => {
    binding.enabled = !binding.enabled;
    saveBinding(binding);
  };

  const showModal = (editTarget?: PolicyBinding) => {
    setShowAddEditModal(true);
    setEditTarget(editTarget);
  };

  const closeModal = () => {
    setShowAddEditModal(false);
    setEditTarget(undefined);
  };

  const tableHeaderButtons = (
    <Button variant="primary" onClick={() => showModal()} aria-label="add">
      {t("Add Policy Binding")}
    </Button>
  );

  const title = "Cloud DNS Filtering";

  const breadcrumbs = [
    { title: t("Filtering"), url: "/filtering", isActive: false },
    { title: t(title), isActive: true },
  ];

  const columns: TableColumn[] = [
    { headerText: "Enable", columnName: "enabled" },
    { headerText: "User", columnName: "user" },
    { headerText: "Subnet Group(s)", columnName: "objectListNames" },
    { headerText: "Operations", columnName: "operations" },
  ];

  const getUserByPolicyBinding = (username?: string) => {
    if (username) {
      return users.find((u) => u.username === username);
    }
    return undefined;
  };

  const dataMap = (binding: PolicyBinding, index: number) => {
    const user = getUserByPolicyBinding(binding.user);
    return (
      <Tr key={index}>
        <Td>
          <Toggle
            data-testid={`${binding.id}-toggle`}
            isChecked={binding.enabled}
            onChange={() => toggleBindingEnable(binding)}
            aria-label="Enabled"
          />
        </Td>
        <Td>
          <Text>{binding && user ? formatUser(user) : binding.user}</Text>
        </Td>
        <Td>
          <Text>{binding.objectListNames.join(", ")}</Text>
        </Td>
        <Td>
          <Flex>
            <Box mr="sp8">
              <TableIconButton
                data-testid="edit-button"
                aria-label="Edit"
                icon={<Icon icon="fa-pencil" variant="solid" color="text.paragraph.light" />}
                onClick={() => showModal(binding)}
              />
            </Box>
            <Box mr="sp8">
              <TableIconButton
                data-testid="delete-button"
                aria-label="Delete"
                icon={<Icon icon="fa-trash-can" variant="solid" color="text.paragraph.light" />}
                onClick={() => deleteBinding(binding)}
              />
            </Box>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <TableBasedPage
      title={t(title)}
      breadcrumbs={breadcrumbs}
      loaded={loaded}
      columns={columns}
      data={bindings}
      tableDataMap={dataMap}
      childrenInTableHeader={tableHeaderButtons}
    >
      <AddEditBindingModal
        isOpen={showAddEditModal}
        policyBinding={editTarget}
        handleClose={closeModal}
        handleSave={saveBinding}
        user={getUserByPolicyBinding(editTarget?.user)}
      />
    </TableBasedPage>
  );
};
