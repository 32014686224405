import React from "react";
import clsx from "clsx";

export default class LetterPanelFooter extends React.Component {
  render() {
    const classes = clsx("letter-panel-footer", this.props.className);

    return <div className={classes}>{this.props.children}</div>;
  }
}
