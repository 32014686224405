import { UserStoreUser } from "../../storez/UserStore";
import { PatchUserBody, PatchUserResponse, UserUMS } from "../../types/Users";
import { headers } from "./ApiHelpers";
import { PurgeType } from "./Helpers";

type ApiSearchResult = {
  users: unknown;
};

const baseUrl = "/api/users";

export const createUser = async (username: string): Promise<string> => {
  const raw = await fetch(`${baseUrl}`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ username }),
  });
  if (raw.status !== 201) throw new Error("Invalid response from server");

  const response: unknown = await raw.json();
  if (!(response !== null && typeof response === "object" && "id" in response && typeof response.id === "string")) {
    throw new Error("Invalid response from server");
  }
  return response.id;
};

export const getUsers = async (): Promise<UserUMS[]> => {
  return getUsersApiCall(baseUrl);
};

export const searchUsers = async (query: string, excludeArchived = true): Promise<UserUMS[]> => {
  const url = `${baseUrl}/search?query=${encodeURIComponent(query)}&excludeArchived=${String(excludeArchived)}`;
  return getUsersApiCall(url);
};

export const getUserByUsername = async (username: string): Promise<UserUMS> => {
  const url = `${baseUrl}/username/${username}`;
  return getUserApiCall(url);
};

export const isUserTeacher = async (username: string, groups: string[]): Promise<boolean> => {
  const raw = await fetch(`${baseUrl}/is-teacher`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ username: username, groups: groups }),
  });
  const response: unknown = await raw.json();
  if (response !== null && typeof response === "object" && "isTeacher" in response && typeof response.isTeacher === "boolean") {
    return response.isTeacher;
  } else throw new Error("Invalid response from server");
};

export const getUsersByUsername = async (usernames: string[]): Promise<UserStoreUser[]> => {
  const raw = await fetch(`${baseUrl}/username`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ usernames: usernames }),
  });
  const response: unknown = await raw.json();
  if (responseHasUsersArray(response) && isUserStoreUsersArray(response.users)) return response.users;
  else throw new Error("Invalid response from server");
};

export const responseHasUsersArray = (response: unknown): response is { users: unknown[] } => {
  return response !== null && typeof response === "object" && "users" in response && Array.isArray(response.users);
};

export const isUserStoreUsersArray = (users: unknown[]): users is UserStoreUser[] => {
  return users.every(
    (user) =>
      user !== null &&
      typeof user === "object" &&
      "username" in user &&
      "id" in user &&
      typeof user.username === "string" &&
      typeof user.id === "string"
  );
};

export const getUserById = async (id: string): Promise<UserUMS> => {
  const url = `${baseUrl}/id/${id}`;
  return getUserApiCall(url);
};

export const isUserObject = (user: unknown): user is UserUMS =>
  user !== null &&
  typeof user === "object" &&
  "email" in user &&
  "id" in user &&
  "stableId" in user &&
  "username" in user &&
  "firstName" in user &&
  "lastName" in user;

export const getUsersApiCall = async (url: string): Promise<UserUMS[]> => {
  const raw = await fetch(url, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (isUserSearchResult(response) && isUnknownArray(response.users) && isUserObjects(response.users)) {
    return response.users;
  } else {
    throw new Error("Invalid response from server");
  }
};

export const getUserApiCall = async (url: string): Promise<UserUMS> => {
  const raw = await fetch(url, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (isUserObject(response)) {
    return response;
  } else {
    throw new Error("Invalid response from server");
  }
};

const isUserSearchResult = (response: unknown): response is ApiSearchResult =>
  typeof response === "object" && response !== null && "users" in response;

const isUnknownArray = (users: unknown): users is unknown[] => Array.isArray(users);

const isUserObjects = (users: unknown[]): users is UserUMS[] => {
  if (users.length === 0) return true;
  return users.some(
    (user) =>
      user !== null &&
      typeof user === "object" &&
      "email" in user &&
      "id" in user &&
      "stableId" in user &&
      "username" in user &&
      "firstName" in user &&
      "lastName" in user &&
      "archived" in user &&
      "sourceType" in user &&
      "distinguishedName" in user
  );
};

export const isPatchUserResponse = (response: unknown): response is PatchUserResponse =>
  typeof response === "object" && response !== null && "firstName" in response && "lastName" in response && "groups" in response;

export const patchUsersApiCall = async (id: string, body: PatchUserBody): Promise<PatchUserResponse> => {
  const url = `${baseUrl}/${id}`;
  const raw = await fetch(url, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify(body),
  });
  const response: unknown = await raw.json();

  if (isPatchUserResponse(response)) {
    return response;
  } else {
    throw new Error("Invalid response from server");
  }
};

export const deleteUser = async (id: string): Promise<void> => {
  const raw = await fetch(`${baseUrl}`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ users: [id] }),
  });
  if (raw.status !== 200) throw new Error("Invalid response from server");
};

export const purgeUsers = async (type: PurgeType): Promise<void> => {
  const raw = await fetch(`${baseUrl}/purge`, {
    method: "DELETE",
    headers: headers(),
    body: JSON.stringify({ type }),
  });
  if (raw.status !== 204) throw new Error("Invalid response from server");
};
