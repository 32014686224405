import React from "react";
import { CheckboxTree, CategoryNode, Flex, Text, Image } from "@familyzone/component-library";
import StatusChip, { ACTION_ALLOWED, ACTION_BLOCKED } from "./StatusChip";

export const blockedText = "Blocked";
export const allowedText = "Allowed";

const DefaultIcon = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.875 2.4375V8.0625C7.875 8.58984 7.44531 9 6.9375 9H1.3125C0.785156 9 0.375 8.58984 0.375 8.0625V2.4375C0.375 1.92969 0.785156 1.5 1.3125 1.5H6.9375C7.44531 1.5 7.875 1.92969 7.875 2.4375ZM11.625 2.75V7.76953C11.625 8.25781 11.0391 8.55078 10.6289 8.27734L8.5 6.79297V3.72656L10.6289 2.24219C11.0391 1.96875 11.625 2.26172 11.625 2.75Z"
        fill="#6B778C"
      />
    </svg>
  );
};

export type SignatureSummary = {
  id: string;
  name: string;
  icon: string;
  included: boolean;
  important: boolean;
  action: number;
  category: string;
};

export type SignatureTree = Omit<SignatureSummary, "category"> & {
  children: SignatureTree[];
};

export interface SignatureTreeProps {
  signatureTree: SignatureTree[];
  includedContent?: React.ReactNode;
}

const SignatureHierarchy: React.FC<SignatureTreeProps> = ({ signatureTree, includedContent }) => {
  const renderTree = (node: SignatureTree) => (
    <CheckboxTree
      key={`item-${node.id}`}
      isChecked={node.included}
      baseProps={{
        cursor: "default",
      }}
      title={
        <CategoryNode icon={node.icon ? <Image src={node.icon} width="16px" /> : <DefaultIcon />}>
          <Flex alignItems="center">
            <Text mr="sp4">{node.name}</Text>
            {(node.action === ACTION_ALLOWED || node.action === ACTION_BLOCKED) && <StatusChip action={node.action} />}
            {node.included && includedContent}
          </Flex>
        </CategoryNode>
      }
    >
      {node.children.map(renderTree)}
    </CheckboxTree>
  );

  return <>{signatureTree.map(renderTree)}</>;
};

export default SignatureHierarchy;
