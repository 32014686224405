import { Box, Flex, SearchBox, Table, Tbody, TbodyInfiniteScroll, Th, Thead, Tr } from "@familyzone/component-library";
import React, { ReactChild, useEffect, useState } from "react";
import { searchStringOld as searchString } from "../../utils/Validation";
import { TableColumn } from "../../types/table";
import { zIndices } from "../../utils/ZIndexUtil";
import { sortTable } from "./SortTableHelper";

export type TableData<Data> = Partial<Record<keyof Data, unknown>>;

export type SortType = "asc" | "desc" | "";

const numberOfRowsToInitiallyShow = 30;
const increaseRowCountBy = 20;

export interface SortSearchTableProps<Data extends TableData<Data>> {
  loaded: boolean;
  columns: TableColumn[];
  data: Data[];
  // tableDataMap is a function which accepts a Data object and returns a Table Row <Tr> element with nested <Td> elements
  tableDataMap: (value: Data, index: number) => ReactChild;
  showSearch?: boolean;
  tableTopMargin?: string;
  tableMargin?: string;
  tablePadding?: string;
  childrenInTableHeader?: ReactChild;
  overflowAutoToAllowDropdownSelectToShow?: boolean;
  searchInput?: string;
  onSort?: (sortColumn: string, sortDirection: SortType) => void;
  defaultSort?: { column: string; direction: SortType };
  disableInfiniteScroll?: boolean;
}

const SortSearchTable = <Data extends TableData<Data>>({
  loaded,
  columns,
  data,
  tableDataMap,
  showSearch = true,
  tableTopMargin = "sp24",
  tableMargin = "sp24",
  tablePadding = "sp24",
  childrenInTableHeader = <></>,
  overflowAutoToAllowDropdownSelectToShow = false,
  searchInput,
  onSort,
  defaultSort = { column: "", direction: "" },
  disableInfiniteScroll = false,
}: SortSearchTableProps<Data>): JSX.Element => {
  const [sortedData, setSortedData] = useState<Data[]>([]);
  const [searchValue, setSearchValue] = useState(searchInput || "");
  const [sortColumn, setSortColumn] = useState(defaultSort.column);
  const [sortDirection, setSortDirection] = useState<SortType>(defaultSort.direction);
  const [shownRowCount, setShownRowCount] = useState(numberOfRowsToInitiallyShow);

  useEffect(() => {
    sortSearchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortDirection, sortColumn, searchValue]);

  useEffect(() => {
    if (onSort) {
      onSort(sortColumn, sortDirection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, sortDirection]);

  const sortSearchData = () => {
    if (!data) {
      setSortedData([]);
      return;
    }
    let sortedData = data;
    if (sortDirection) {
      sortedData = sortTable(data, sortColumn, sortDirection);
    }
    if (searchValue) {
      const searchableColumns = columns.filter((c) => c.searchable);
      sortedData = sortedData.filter((d: Record<string, unknown>) =>
        searchableColumns.some(({ columnName }) => searchString(searchValue, String(d[columnName])))
      );
    }
    setSortedData(sortedData);
  };

  const showMore = () => {
    setShownRowCount(shownRowCount + increaseRowCountBy);
  };

  const overflowAuto = () => {
    return overflowAutoToAllowDropdownSelectToShow ? { overflow: "auto" } : {};
  };

  return (
    <Box m={tableMargin} mt={tableTopMargin} p={tablePadding} background="neutrals.0" borderRadius="6px">
      <Flex direction="row">
        {showSearch && (
          <SearchBox
            data-testid="searchBar"
            onClear={() => setSearchValue("")}
            onChange={(event) => setSearchValue(event.target.value)}
            value={searchValue}
            placeholder="Search"
            width="536px"
          />
        )}
        {childrenInTableHeader && showSearch && <Flex flex="1" />}
        {childrenInTableHeader}
      </Flex>
      <Flex direction="row" my="sp16">
        <Table style={overflowAuto()}>
          <Thead position="sticky" top="0" zIndex={zIndices.thead}>
            <Tr>
              {columns.map(({ headerText, columnName, sortable, alignment }) => (
                <Th
                  key={columnName}
                  alignment={alignment}
                  headerText={headerText}
                  columnName={columnName}
                  sortDirection={sortable && sortColumn === columnName ? sortDirection : ""}
                  handleSort={
                    sortable
                      ? (columnName, sortDirection) => {
                          setSortColumn(columnName);
                          setSortDirection(sortDirection);
                        }
                      : undefined
                  }
                />
              ))}
            </Tr>
          </Thead>
          <>
            {disableInfiniteScroll ? (
              <Tbody loaded={loaded}>{sortedData.map(tableDataMap)}</Tbody>
            ) : (
              <TbodyInfiniteScroll
                fetchData={showMore}
                hasMore={sortedData.length > shownRowCount}
                parentElemId="ComponentWrapper"
                loaded={loaded}
                searched={!!searchValue}
              >
                {sortedData.slice(0, shownRowCount).map(tableDataMap)}
              </TbodyInfiniteScroll>
            )}
          </>
        </Table>
      </Flex>
    </Box>
  );
};

export default SortSearchTable;
