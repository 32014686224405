import { Button, Icon, MenuButton } from "@familyzone/component-library";
import React from "react";
import { MenuButtonProps } from "@chakra-ui/react";

interface Props extends MenuButtonProps {
  children?: JSX.Element | JSX.Element[];
  icon?: string;
  iconVariant?: "solid" | "regular" | "light" | "duotone";
}

export const HeaderStyledButton: React.FC<Props> = React.forwardRef(
  ({ icon, iconVariant = "solid", children, ...props }, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button
        ref={ref}
        variant="ghost"
        color="text.title"
        fontWeight="regular"
        border="1px solid"
        borderColor="neutrals.0"
        _hover={{ bg: "brand.200" }}
        _focus={{ bg: "brand.200", boxShadow: "outline", borderColor: "brand.400" }}
        leftIcon={
          icon ? (
            <Icon
              bg="neutrals.80"
              borderRadius="round"
              p="sp4"
              color="neutrals.0"
              height="18px"
              width="18px"
              variant={iconVariant}
              icon={icon}
              data-testid={icon}
            />
          ) : undefined
        }
        {...props}
      >
        {children}
      </Button>
    );
  }
);

export const HeaderMenuButton: React.FC<Props> = (props) => <MenuButton as={HeaderStyledButton} {...props} />;
