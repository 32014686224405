import Api from "../utils/Api";

import alt from "../alt";
import ObjectActions from "../actions/ObjectActions";
import SessionActions from "../actions/SessionActions";

class ObjectStore {
  constructor() {
    this.objects = [];
    this.loaded = false;

    this.exportPublicMethods({
      getObjects: this.getObjects,
      getObject: this.getObject,
      getNetworkObjects: this.getNetworkObjects,
      getNetworkSubnetObjects: this.getNetworkSubnetObjects,
      getWebsiteObjects: this.getWebsiteObjects,
      getKeywordObjects: this.getKeywordObjects,
      getMacObjects: this.getMacObjects,
      getDomainsObjects: this.getDomainsObjects,
      getLoaded: this.getLoaded,
    });

    this.bindListeners({
      handleFetch: ObjectActions.FETCH,
      handleFetchUpdated: ObjectActions.FETCH_UPDATED,
      handleInvalidateAndFetch: ObjectActions.INVALIDATE_AND_FETCH,

      handleAddObject: ObjectActions.ADD_OBJECT,
      handleSaveObject: ObjectActions.SAVE_OBJECT,
      handleDeleteObject: ObjectActions.DELETE_OBJECT,
      handleDeselectDevice: SessionActions.DESELECT_DEVICE,
      handleChangeDevice: SessionActions.CHANGE_DEVICE,
    });
  }

  getObjects = () => {
    return this.objects;
  };

  getLoaded = () => {
    return this.loaded;
  };

  getNetworkObjects = () => {
    const ret = [];
    for (let object of this.objects) {
      if (object["type"] === 0 || object["type"] === 1) {
        ret.push(object);
      }
    }

    return ret;
  };

  getNetworkSubnetObjects = () => this.objects.filter((object) => object.type === 1);

  getWebsiteObjects = () => {
    const ret = [];
    for (let object of this.objects) {
      if (object["type"] === 2) {
        ret.push(object);
      }
    }

    return ret;
  };

  getKeywordObjects = () => {
    const ret = [];
    for (let object of this.objects) {
      if (object["type"] === 6) {
        ret.push(object);
      }
    }

    return ret;
  };

  getMacObjects = () => {
    const ret = [];
    for (let object of this.objects) {
      if (object["type"] === 4) {
        ret.push(object);
      }
    }

    return ret;
  };

  getDomainsObjects = () => this.objects.filter((object) => object.type === 7);

  getObject = (key) => {
    for (let object of this.objects) {
      if (object["id"] === key) {
        return object;
      }
    }
  };

  handleDeselectDevice = () => {
    this.destroyDevice();
  };

  handleChangeDevice = () => {
    this.destroyDevice();
  };

  destroyDevice = () => {
    this.objects = [];
    this.loaded = false;
  };

  handleFetchUpdated(result) {
    this.objects = result || [];
    this.loaded = true;
  }

  handleFetch = () => {
    if (this.objects.length === 0) {
      Api.get(
        "/config/device/ajax/aliases",
        function (data) {
          setTimeout(() => {
            ObjectActions.fetchUpdated(data["data"]);
          }, 0);
        },
        function (err) {
          console.log("error fetching objects");
        }
      );
    }
  };

  handleInvalidateAndFetch = () => {
    this.objects = [];
    this.handleFetch();
  };

  handleDeleteObject = (object_id) => {
    Api.delete("/config/device/ajax/aliases/" + object_id, {}, function () {
      ObjectActions.invalidateAndFetch();
    });
  };

  handleAddObject = (entity) => {
    Api.put("/config/device/ajax/aliases", entity, function () {
      ObjectActions.invalidateAndFetch();
    });
  };

  handleSaveObject = (entity) => {
    Api.post("/config/device/ajax/aliases", entity, function () {
      ObjectActions.invalidateAndFetch();
    });
  };
}

export default alt.createStore(ObjectStore, "ObjectStore");
