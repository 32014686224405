import React from "react";
import Criteria from "./Criteria";

export default class WebsiteCriteria extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = {};
    }

    this.state = {
      criteria: props.criteria,
    };

    /* {type: "application.http.hostname.regex", negate: false, conditions: {expression: ""}} */
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = {};
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Change = (event) => {
    this.state.criteria.conditions.expression = event.target.value;
    this.bubble_Changes(this.state.criteria);
  };

  bubble_Changes = (criteria) => {
    /* Push changes up to the root */
    this.props.bubbleMethod(criteria);
  };

  render() {
    return (
      <Criteria label="Website" onRemove={() => this.props.handle_RemoveCriteria(this.state.criteria)}>
        <div className="mui-textfield">
          <input type="text" value={this.state.criteria.conditions["expression"]} onChange={this.handle_Change} placeholder="Website" />
        </div>
      </Criteria>
    );
  }
}
