import {
  ActiveConnectionsApiResponse,
  ActiveConnectionsFormattedData as FormattedData,
  ActiveConnectionsProps,
} from "./ActiveConnectionsTypes";

export const getConnectionsUri = ({ user, mac, ip }: ActiveConnectionsProps, startTime?: number): string => {
  let uri = "/edgewize/device/analytics/connections?";
  if (startTime && startTime > 0) {
    uri += `&time=${startTime}`;
  }

  if (user) {
    uri += `&user=${user}`;
  }
  if (mac) {
    uri += `&mac=${mac}`;
  }
  if (ip) {
    uri += `&ip=${ip}`;
  }

  return uri;
};

export const getProtocol = (protocol?: number): "TCP" | "UDP" | "ICMP" | "" => {
  switch (protocol) {
    case 6:
      return "TCP";
    case 17:
      return "UDP";
    case 1:
      return "ICMP";
    default:
      return "";
  }
};

export const isAllowed = (item: ActiveConnectionsApiResponse): boolean => {
  if (item["applayer.rule.id"]) {
    return item["applayer.rule.action"];
  }
  if (item["zoom.rule.verdict"]) {
    return item["zoom.rule.verdict"] !== 0;
  }
  if (item["captiveportal.rule.id"]) {
    return false;
  }
  return true;
};

export const formatData = (unformatted: ActiveConnectionsApiResponse[]): FormattedData[] => {
  const formatted: FormattedData[] = [];
  unformatted.forEach((item: ActiveConnectionsApiResponse) => {
    formatted.push(formatSingleItem(item));
  });
  return formatted;
};

export const formatSingleItem = (unformatted: ActiveConnectionsApiResponse): FormattedData => {
  let source = unformatted.sourceIp;
  if (unformatted.sourcePort) {
    source += `:${unformatted.sourcePort}`;
  }
  let destination = unformatted.destIp;
  if (unformatted.destPort) {
    destination += `:${unformatted.destPort}`;
  }

  const result: FormattedData = {
    time: unformatted.time,
    allowed: isAllowed(unformatted),
    source: source,
    destination: destination,
    protocol: getProtocol(unformatted.protocol),
    user: unformatted.user,
    applicationTagName: unformatted["tracking.signature.application_tag_name"] ?? "",
    currentState: unformatted.state,
    rawData: unformatted,
  };
  return result;
};
