import React from "react";
import { Link } from "react-router";

import Separator from "../../modules/Separator";
import FlexibleTable from "../../modules/FlexibleTable";
import ModalWindow from "../../modules/ModalWindow";
import TableCentricPage from "../../modules/TableCentricPage";
import TableCentricPageButton from "../../modules/TableCentricPageButton";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import SearchableSelect from "../../modules/SearchableSelect";

import DeleteButton from "../../modules/DeleteButton";

class PrintButton extends React.Component {
  render() {
    return (
      <Link
        to={
          "/config/device/userdb/guestsprinter/login/" +
          encodeURI(this.props.username) +
          "/name/" +
          encodeURI(this.props.fullname) +
          "/expiry/" +
          encodeURI(this.props.expiry)
        }
        target="_blank"
        className="clickable"
      >
        <i className="fa fa-print" aria-hidden="true" />
      </Link>
    );
  }
}

class AddBulkUsers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      numGuests: "",
      expire_after_first_login: true,
      expire_after_account_creation: false,
      lifetime: "1day",
    };
  }

  handleHide = () => {
    this.setState({
      numGuests: "",
      expire_after_first_login: true,
      expire_after_account_creation: false,
      lifetime: "1day",
    });
    this.props.handleClose();
  };

  handle_Submit = () => {
    if (this.state.numGuests >= 1 && this.state.numGuests <= 100) {
      this.props.handleAdd({
        numGuests: this.state.numGuests,
        expire_after_first_login: this.state.expire_after_first_login,
        expire_after_account_creation: this.state.expire_after_account_creation,
        lifetime: this.state.lifetime,
      });
    }

    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  handle_ChangeExpireAfterFirstLogin = (event) => {
    this.setState({
      expire_after_first_login: event.target.checked,
      expire_after_account_creation: !event.target.checked,
    });
  };

  handle_ChangeSelectedAccountCreation = (event) => {
    this.setState({
      expire_after_first_login: !event.target.checked,
      expire_after_account_creation: event.target.checked,
    });
  };

  handle_ChangeLifetime = (event) => {
    this.setState({
      lifetime: event.value,
    });
  };

  handle_ChangeNumGuests = (event) => {
    let number = parseInt(event.target.value);
    if (number && number >= 0 && number <= 100) {
      this.setState({ numGuests: number });
    }
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add Guests" handleHide={this.handleHide} actions={this.renderActions()}>
          <form className="mui-form">
            <div className="mui-textfield">
              <div className="formgroup-element">Number of guest accounts</div>
              <input type="text" value={this.state.numGuests} onChange={this.handle_ChangeNumGuests} placeholder="1-100" />
            </div>
            <div className="mui-checkbox">
              <label>
                <input type="checkbox" checked={this.state.expire_after_first_login} onChange={this.handle_ChangeExpireAfterFirstLogin} />
                Expire after First Login
              </label>
            </div>
            <div className="mui-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this.state.expire_after_account_creation}
                  onChange={this.handle_ChangeSelectedAccountCreation}
                />
                Expire after Account Creation
              </label>
            </div>

            <div className="mui-select">
              <div className="formgroup-element">Lifetime</div>
              <SearchableSelect
                value={this.state.lifetime}
                onChange={this.handle_ChangeLifetime}
                valueDataMap={[
                  ["1hour", "1 Hour"],
                  ["1day", "1 Day"],
                  ["2day", "2 Days"],
                  ["1week", "1 Week"],
                  ["1month", "1 Month"],
                  ["3month", "3 Months"],
                  ["6month", "6 Months"],
                ]}
              ></SearchableSelect>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

class AddUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      firstname: "",
      lastname: "",
      expire_after_first_login: true,
      expire_after_account_creation: false,
      lifetime: "1day",
    };
  }

  handleHide = () => {
    this.setState({
      firstname: "",
      lastname: "",
      expire_after_first_login: true,
      expire_after_account_creation: false,
      lifetime: "1day",
    });
    this.props.handleClose();
  };

  handle_ChangeFirstName = (event) => {
    this.setState({ firstname: event.target.value });
  };

  handle_ChangeLastName = (event) => {
    this.setState({ lastname: event.target.value });
  };

  handle_Submit = () => {
    this.props.handleAdd({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      expire_after_first_login: this.state.expire_after_first_login,
      expire_after_account_creation: this.state.expire_after_account_creation,
      lifetime: this.state.lifetime,
    });

    this.handleHide();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised" onClick={this.handle_Submit}>
        Add
      </button>
    );
  };

  handle_ChangeExpireAfterFirstLogin = (event) => {
    this.setState({
      expire_after_first_login: event.target.checked,
      expire_after_account_creation: !event.target.checked,
    });
  };

  handle_ChangeSelectedAccountCreation = (event) => {
    this.setState({
      expire_after_first_login: !event.target.checked,
      expire_after_account_creation: event.target.checked,
    });
  };

  handle_ChangeLifetime = (event) => {
    this.setState({
      lifetime: event.value,
    });
  };

  render() {
    if (this.props.visible) {
      return (
        <ModalWindow title="Add a Guest" handleHide={this.handleHide} actions={this.renderActions()} disable_overflow={true}>
          <form className="mui-form">
            <div className="mui-textfield">
              <div className="formgroup-element">First Name</div>
              <input type="text" value={this.state.firstname} onChange={this.handle_ChangeFirstName} />
            </div>
            <div className="mui-textfield">
              <div className="formgroup-element">Last Name</div>
              <input type="text" value={this.state.lastname} onChange={this.handle_ChangeLastName} />
            </div>

            <div className="mui-checkbox">
              <label>
                <input type="checkbox" checked={this.state.expire_after_first_login} onChange={this.handle_ChangeExpireAfterFirstLogin} />
                Expire after First Login
              </label>
            </div>
            <div className="mui-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this.state.expire_after_account_creation}
                  onChange={this.handle_ChangeSelectedAccountCreation}
                />
                Expire after Account Creation
              </label>
            </div>

            <div className="mui-select">
              <div className="formgroup-element">Lifetime</div>
              <SearchableSelect
                value={this.state.lifetime}
                onChange={this.handle_ChangeLifetime}
                valueDataMap={[
                  ["1hour", "1 Hour"],
                  ["1day", "1 Day"],
                  ["2day", "2 Days"],
                  ["1week", "1 Week"],
                  ["1month", "1 Month"],
                  ["3month", "3 Months"],
                  ["6month", "6 Months"],
                ]}
              ></SearchableSelect>
            </div>
          </form>
        </ModalWindow>
      );
    }

    return <div />;
  }
}

export default class Guests extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      guests: [],
      visible__add_singleGuest: false,
      visible__add_multipleGuests: false,
      loaded: false,
    };
  }

  componentDidMount() {
    this.handle_load();
  }

  handle_load = () => {
    let self = this;
    self.setState({
      loaded: false,
    });

    Api.get("/config/ajax/guests", function (result) {
      let guests = result["data"];
      self.setState({
        guests: guests,
        loaded: true,
      });
    });
  };

  handler__clickAdd = () => {
    this.setState({
      visible__add_singleGuest: true,
    });
  };

  handler__clickCloseAdd = () => {
    this.setState({
      visible__add_singleGuest: false,
    });
  };

  handler__clickAddMultiple = () => {
    this.setState({
      visible__add_multipleGuests: true,
    });
  };

  handler__clickCloseAddMultiple = () => {
    this.setState({
      visible__add_multipleGuests: false,
    });
  };

  render__buttons = () => {
    return [
      <TableCentricPageButton title="Add Guest" onClickHandler={this.handler__clickAdd} />,
      <TableCentricPageButton title="Add Multiple Guests" onClickHandler={this.handler__clickAddMultiple} />,
    ];
  };

  handle_Delete = (username) => {
    let self = this;
    Api.delete("/config/ajax/deleteguest/" + username, {}, function (result) {
      self.handle_load();
    });
  };

  handle__AddUser = (object) => {
    let self = this;
    Api.post("/config/ajax/createguest", object, function () {
      self.handle_load();
    });
  };

  handle__AddBulkUsers = (object) => {
    let self = this;
    Api.post("/config/ajax/createmultipleguests", object, function () {
      self.handle_load();
    });
  };

  render__content = () => {
    let self = this;
    let columns = [
      {
        title: "Token",
        data: function (row) {
          return row["username"];
        },
        search: FlexibleTable.search__single_string_field("username"),
        sortable: FlexibleTable.sortable__single_string_field("username"),
        export: function (row) {
          return row["username"];
        },
        onclick: undefined,
      },
      {
        title: "Name",
        data: function (row) {
          let fname = row["fname"] ? row["fname"] : "";
          let lname = row["lname"] ? row["lname"] : "";
          return fname + " " + lname;
        },
        search: FlexibleTable.search__multiple(["fname", "lname"]),
        sortable: FlexibleTable.sortable__single_string_field("fname"),
        export: function (row) {
          let fname = row["fname"] ? row["fname"] : "";
          let lname = row["lname"] ? row["lname"] : "";
          return fname + " " + lname;
        },
        onclick: undefined,
      },
      {
        title: "Expiry",
        data: function (row) {
          return row["expiry"];
        },
        search: FlexibleTable.search__single_string_field("expiry"),
        sortable: FlexibleTable.sortable__single_string_field("expiry"),
        export: function (row) {
          return row["expiry"];
        },
        onclick: undefined,
      },
      {
        title: "State",
        data: function (row) {
          if (row["archived"]) {
            return <span className="archived-yes">Archived</span>;
          }
          return <span className="archived-no">Active</span>;
        },
        sortable: FlexibleTable.sortable__single_string_field("archived"),
        export: function (row) {
          if (row["archived"]) {
            return "Archived";
          }
          return "Active";
        },
        onclick: undefined,
      },
      {
        title: "Operations",
        data: function (row) {
          let fname = row["fname"] ? row["fname"] : "";
          let lname = row["lname"] ? row["lname"] : "";
          return (
            <div>
              <PrintButton username={row["username"]} fullname={fname + " " + lname} expiry={row["expiry"]}>
                Print
              </PrintButton>
              <Separator />
              <DeleteButton onClick={() => self.handle_Delete(row["username"])} />
            </div>
          );
        },
        onclick: undefined,
      },
    ];

    return <FlexibleTable columns={columns} data={this.state.guests} loaded={this.state.loaded} />;
  };

  render() {
    return (
      <TableLeftPanel>
        <AddUser visible={this.state.visible__add_singleGuest} handleAdd={this.handle__AddUser} handleClose={this.handler__clickCloseAdd} />
        <AddBulkUsers
          visible={this.state.visible__add_multipleGuests}
          handleAdd={this.handle__AddBulkUsers}
          handleClose={this.handler__clickCloseAddMultiple}
        />

        <TableCentricPage icon="fa fa-user" title="Guests" buttons={this.render__buttons()} content={this.render__content()} />
      </TableLeftPanel>
    );
  }
}
