import React, { useMemo } from "react";
import { Button, Icon, useTranslation } from "@familyzone/component-library";
import { Checkbox, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Tag } from "@chakra-ui/react";
import { AuditScope, EntityType } from "../../../types/Audit";
import { formatScope } from "./AuditUtils";

export interface ScopeOption {
  scope: AuditScope;
  entityTypes?: EntityType[];
}

export const ScopeSelector: React.FC<{
  options: ScopeOption[];
  selectedOptions: ScopeOption[];
  disabled?: boolean;
  onChange: (selected: ScopeOption[]) => void;
}> = ({ options, selectedOptions, disabled, onChange }) => {
  const { t } = useTranslation();

  const selectScope = (scope: AuditScope) => {
    const withoutScope = selectedOptions.filter((s) => s.scope !== scope);
    if (withoutScope.length === selectedOptions.length) {
      return onChange([...selectedOptions, { scope, entityTypes: [] }]);
    }
    return onChange(withoutScope);
  };

  const selectType = (scope: AuditScope, type: EntityType) => {
    const selected = selectedOptions.find((s) => s.scope === scope);
    if (!selected) {
      return onChange([...selectedOptions, { scope, entityTypes: [type] }]);
    }

    if (!selected.entityTypes?.length) {
      selected.entityTypes = [type];
      return onChange([...selectedOptions]);
    }

    const withoutType = selected.entityTypes.filter((t) => t !== type);
    if (withoutType.length === selected.entityTypes.length) {
      selected.entityTypes = [...selected.entityTypes, type];
    } else {
      selected.entityTypes = withoutType;
    }

    return onChange([...selectedOptions]);
  };

  const clearAll = () => onChange([]);

  const isSelected = (scope: AuditScope, type?: EntityType) => {
    const selected = selectedOptions.find((s) => s.scope === scope);
    if (selected && type) {
      return selected.entityTypes?.includes(type);
    }
    return !!selected;
  };

  const isIndeterminate = (option: ScopeOption) => {
    if (!option.entityTypes?.length) {
      return false;
    }

    const selected = selectedOptions.find((o) => o.scope === option.scope);
    return selected && !!selected.entityTypes?.length && selected?.entityTypes?.length !== option?.entityTypes?.length;
  };

  const selectedCount = useMemo(() => {
    return selectedOptions.reduce((count, o) => {
      if (!o.entityTypes?.length) {
        return count + 1;
      }
      return count + o.entityTypes?.length;
    }, 0);
  }, [selectedOptions]);

  const menuItemCSS = {
    color: "text.title",
    py: "sp8",
    borderRadius: "0 !important",
  };

  const checkboxCSS = {
    flexGrow: 1,
    "& [data-checked][data-disabled]": {
      color: "text.paragraph.regular",
      borderColor: "neutrals.40",
      svg: {
        backgroundColor: "neutrals.40",
      },
    },
    "& svg": {
      margin: 0,
      height: "100%",
      width: "100%",
      color: "white",
      transform: "scale(1) !important",
    },
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<Icon icon="fa-chevron-down" />}
        border="1px"
        borderColor="neutrals.50"
        bgColor="neutrals.0"
        fontWeight="regular"
        _hover={{ bgColor: "neutrals.20" }}
        _focus={{
          border: "1px solid brand.500",
        }}
        disabled={disabled}
        aria-label="Filter by scope"
      >
        {t("Filter by scope")}{" "}
        {selectedCount > 0 && (
          <Tag variant="dark" size="compact" my="-sp4">
            {String(selectedCount)}
          </Tag>
        )}
      </MenuButton>
      <MenuList py="sp8">
        {options.flatMap((o) => (
          <MenuGroup key={`group-${o.scope}`}>
            <MenuItem key={o.scope} sx={menuItemCSS}>
              <Checkbox
                isChecked={isSelected(o.scope)}
                isIndeterminate={isIndeterminate(o)}
                isDisabled={disabled}
                onChange={() => selectScope(o.scope)}
                sx={checkboxCSS}
              >
                {formatScope(o.scope)}
              </Checkbox>
            </MenuItem>
            {o.entityTypes?.map((t) => (
              <MenuItem key={o.scope + "~" + t} sx={menuItemCSS}>
                <Checkbox
                  ml="sp24"
                  isChecked={isSelected(o.scope, t)}
                  isDisabled={disabled}
                  onChange={() => selectType(o.scope, t)}
                  sx={checkboxCSS}
                >
                  {formatScope(t)}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuGroup>
        ))}
        <MenuDivider my="8px !important" />
        <MenuItem
          as={Button}
          variant="ghost"
          borderRadius="0 !important"
          outline="none"
          _hover={{}}
          _focus={{ boxShadow: "none" }}
          onClick={clearAll}
          isDisabled={disabled}
        >
          {t("Clear All")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
