import React from "react";
import { Icon, Image } from "@familyzone/component-library";

interface Props {
  iconURL?: string | null; // Some of the icons coming from the api are not "undefined" but "null"
  fallBackIcon: string;
}

const NetworkIcon: React.FunctionComponent<Props> = ({ iconURL, fallBackIcon }: Props) => {
  return <>{iconURL ? <Image src={iconURL} fit="cover" /> : <Icon icon={fallBackIcon} />}</>;
};
export default NetworkIcon;
