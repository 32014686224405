import { useState, useEffect } from "react";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import Api from "../Api";

/**
 * Simple data fetching hook to be used by the reporting Tables components
 * that all share similar logic.
 *
 * @typedef {Object} useFetchReportingTableData
 * @param {string} endpoint - api endpoint to fetch
 * @return {Object} The feched data and loaded flag indicator
 */
const useFetchReportingTableData = (endpoint) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const unsubFilterStore = useDateRangeFilterStore.subscribe(fetchData);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    async function fetchData() {
      try {
        const response = await Api.get_analyticsAsync(endpoint);

        if (response?.data) {
          setLoaded(true);
          setData(response.data);
        }
      } catch (error) {
        setLoaded(false);
      }
    }

    fetchData();

    return () => {
      unsubFilterStore();
    };
  }, [endpoint]);

  return { data, loaded };
};

export default useFetchReportingTableData;
