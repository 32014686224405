import alt from "../alt";
import SignatureTreeActions from "../actions/SignatureTreeActions";
import Api from "../utils/Api";

class SignatureTreeStore {
  constructor() {
    this.signatures = [];
    this.keywords = [];

    this.exportPublicMethods({
      getSignatures: this.getSignatures,
      getKeywords: this.getKeywords,
    });

    this.bindListeners({
      handleFetch: SignatureTreeActions.FETCH,
      handleFetchUpdated: SignatureTreeActions.FETCH_UPDATED,
    });
  }

  getSignatures = () => {
    return this.signatures;
  };

  getKeywords = () => {
    return this.keywords;
  };

  handleFetchUpdated(result) {
    this.signatures = result["signatures"];
    this.keywords = result["keywords"].filter((tag) => !tag.deprecated);
  }

  handleFetch = () => {
    if (this.signatures.length === 0) {
      Api.get(
        "/surfwize/ajax/signatures/tree",
        function (data) {
          setTimeout(() => {
            SignatureTreeActions.fetchUpdated(data);
          }, 0);
        },
        function (err) {
          console.log("error fetching signatures");
        }
      );
    }
  };
}

export default alt.createStore(SignatureTreeStore, "SignatureTreeStore");
