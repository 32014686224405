export interface Data {
  deviceid: string;
  agent: string;
  agent_inside_network: boolean;
  sourceIp: string;
  destIp: string;
  bytes: number;
  upload: number;
  download: number;
  protocol: number;
  user: string;
  sourcePort: number;
  destPort: number;
  packets: number;
  hw: string;
  applayer_rule_action: boolean;
  firewall_rule_action: boolean;
  agent_bypass: boolean;
  qos_dscp_applied: boolean;
  qos_dscp_value: number;
  qos_ratelimiter_applied: boolean;
  useragent: string;
  contenttype: string;
  hwAddress: string;
  referer: string;
  httpHost: string;
  tag: string;
  noise: boolean;
  categoryId: string;
  subCategoryId: string;
  http_request_uris: string[];
  app_filtering_denied: boolean;
  verdict_filter_rule: string;
  verdict_application_rule: string;
  verdict_application_rule_fire_event: boolean;
  mitm: boolean;
  micro_lock_locked: boolean;
  bypass_expiry_time: number;
  bypass_code: string;
  reportingType: string;
  extensionConnection: boolean;
  string_time: string;
  time?: string;
  hostname?: string;
  applayer_rule_id?: number;
  zoom_rule_verdict?: number;
  captiveportal_rule_id?: number;
}

export interface FormattedData extends Data {
  website?: string;
  verdict?: number;
}

type QueryResult = { data: Data[] };

export const isQueryResult = (data: unknown): data is QueryResult => typeof data === "object" && data !== null && "data" in data;

export const isData = (data: unknown): data is Data => typeof data === "object" && data !== null && "deviceid" in data;

export const VerdictStatus = {
  Red: 10,
  BlockedRed: 10,
  Grey: 20,
  BlockedGrey: 20,
  Green: 30,
  Allowed: 30,
};

export const extractVerdictStatus = (row: Data): number => {
  if (row["applayer_rule_id"] !== undefined) {
    if (!row["applayer_rule_action"]) {
      return VerdictStatus.Red;
    } else {
      return VerdictStatus.Green;
    }
  } else if (row["zoom_rule_verdict"] !== undefined) {
    if (row["zoom_rule_verdict"] === 0) {
      return VerdictStatus.Red;
    } else {
      return VerdictStatus.Green;
    }
  } else if (row["captiveportal_rule_id"] !== undefined) {
    return VerdictStatus.Red;
  } else if (row["app_filtering_denied"]) {
    return VerdictStatus.Red;
  } else if (row["qos_ratelimiter_applied"]) {
    return VerdictStatus.Grey;
  } else if (row["qos_dscp_applied"]) {
    return VerdictStatus.Grey;
  } else {
    return VerdictStatus.Green;
  }
};

export const exportVerdict = (row: unknown): string => {
  if (isData(row)) {
    if (row["applayer_rule_id"] !== undefined) {
      if (!row["applayer_rule_action"]) {
        return "Blocked by Webfiltering";
      } else {
        return "Allowed by Webfiltering";
      }
    } else if (row["zoom_rule_verdict"] !== undefined) {
      if (row["zoom_rule_verdict"] === 0) {
        return "Blocked by Classroom";
      } else {
        return "Allowed by Classroom";
      }
    } else if (row["captiveportal_rule_id"] !== undefined) {
      return "Blocked";
    } else if (row["app_filtering_denied"]) {
      return "Blocked";
    }
    return "Allowed";
  }
  return "-";
};
