import { Flex, Icon, Text } from "@familyzone/component-library";
import React from "react";

export interface RedFlagPillTooltipProps {
  hits: number;
  time: string;
}

export const RedFlagPillTooltip = ({ hits, time }: RedFlagPillTooltipProps): JSX.Element => {
  return (
    <Flex
      _after={{
        content: `""`,
        position: "absolute",
        top: "100%",
        left: "50%",
        marginLeft: "-10px",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: "#3e3e3c transparent transparent transparent",
      }}
    >
      <Icon pt="sp4" pr="sp4" icon="fa-flag" />
      <Text pr="sp8">{hits}</Text>
      <Icon pt="sp4" pr="sp4" icon="fa-clock" variant="light" />
      <Text>{time}</Text>
    </Flex>
  );
};
