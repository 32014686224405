/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback, useEffect, useState } from "react";
import SessionStore from "../stores/SessionStore";
import SnapEngage from "./SnapEngage";
import Zendesk from "./Zendesk";
import { useDeviceOptionsStore } from "../storez/DeviceOptionsStore";
import ForethoughtAI from "./ForethoughtAI";
import { parseEnvVarToStringArray } from "../utils/envUtils";

const snapEngage = <SnapEngage />;
const zenDesk = <Zendesk />;

const ChatWidget = (): JSX.Element => {
  // the device config is loaded in `AppRoot` to avoid multiple fetch calls as that data is required
  // by several components; once the data is available, this view will automatically re-render
  const [deviceOptions] = useDeviceOptionsStore(useCallback((state) => [state.options] as const, []));
  const [chatbotEnabled, setChatbotEnabled] = useState(false);
  const enabledAppliances = parseEnvVarToStringArray(process.env.REACT_APP_FORETHOUGHT_AI_CHATBOT_ENABLED_APPLIANCE_IDS);

  useEffect(() => {
    // Set the chatbot enabled state to load forethoughtAI only when appliance is enabled.
    const enabled = (deviceOptions?.deviceid && enabledAppliances.includes(deviceOptions?.deviceid)) as boolean;
    setChatbotEnabled(enabled);
  }, [chatbotEnabled, deviceOptions?.deviceid, enabledAppliances]);

  if (!SessionStore.isAuthenticated() || !SessionStore.getSelectedDevice()) {
    return <></>;
  }

  if (deviceOptions?.allow_support_zendesk) {
    if (chatbotEnabled) {
      // Only load when needed and device enabled.
      return <ForethoughtAI />;
    }
    return zenDesk;
  }

  if (deviceOptions?.allow_support_school_manager) {
    if (chatbotEnabled) {
      // Only load when needed and device enabled.
      return <ForethoughtAI />;
    }
    return snapEngage;
  }

  return <></>;
};

export default ChatWidget;
