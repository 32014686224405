import ObjectStore from "../stores/ObjectStore";
import ObjectActions from "../actions/ObjectActions";
import React from "react";
import Criteria from "./Criteria";
import SmartListSelector from "../modules/SmartListSelector";

export default class Ipv4Object extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.criteria.conditions === undefined) {
      props.criteria.conditions = [];
    }

    this.state = {
      criteria: props.criteria,
      availableObjects: ObjectStore.getNetworkObjects(),
    };

    /* {type: "ipv4.alias", negate: false, conditions: []} */
  }

  componentDidMount() {
    ObjectStore.listen(this.onChange);

    setTimeout(() => {
      ObjectActions.fetch();
    }, 0);
  }

  componentWillUnmount() {
    ObjectStore.unlisten(this.onChange);
  }

  onChange = () => {
    this.setState({
      availableObjects: this.props.subnetsOnly ? ObjectStore.getNetworkSubnetObjects() : ObjectStore.getNetworkObjects(),
    });
  };

  componentWillReceiveProps(nextprops) {
    if (nextprops.criteria.conditions === undefined) {
      nextprops.criteria.conditions = [];
    }

    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_Add = (item) => {
    this.state.criteria.conditions.push(item);
    this.props.bubbleMethod(this.state.criteria);
  };

  handle_Remove = (item) => {
    let new_conditions = [];
    for (let x of this.state.criteria.conditions) {
      if (x !== item) {
        new_conditions.push(x);
      }
    }

    this.state.criteria.conditions = new_conditions;
    this.props.bubbleMethod(this.state.criteria);
  };

  getObjectId = (item) => {
    return item["id"];
  };

  getObjectName = (item) => {
    return item["name"];
  };

  render() {
    return (
      <Criteria
        label={this.props.label || "Ip Objects"}
        onRemove={this.props.handle_RemoveCriteria !== undefined ? () => this.props.handle_RemoveCriteria(this.state.criteria) : undefined}
      >
        <SmartListSelector
          get__value={this.getObjectId}
          get__label={this.getObjectName}
          selected={this.state.criteria.conditions}
          available={this.state.availableObjects}
          removeSelectedFromOptions
          addElement={this.handle_Add}
          removeElement={this.handle_Remove}
        />
      </Criteria>
    );
  }
}
