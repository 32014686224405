import React from "react";
import moment from "moment";
import qs from "querystring";
import GlobalDatePickerVisibilityActions from "../../../actions/GlobalDatePickerVisibilityActions";
import { useDateRangeFilterStore } from "../../../storez/DateRangeFilterStore";
import Api from "../../../utils/Api";
import UserJourneyNew from "./UserJourneyNew";

export default class UserJourney extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      journeyData: [],
      loaded: false,
      initialResultFilter: props.location.query.verdict,
    };
    this.tableRef = React.createRef();
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.getJourneyData();
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.getJourneyData);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  getJourneyData = () => {
    let self = this;
    if (this.state.loaded) {
      this.setState({
        loaded: false,
      });
    }

    const startDate = moment(`${useDateRangeFilterStore.getState().getStartDate()} ${useDateRangeFilterStore.getState().getStartTime()}`);
    const endDate = moment(`${useDateRangeFilterStore.getState().getEndDate()} ${useDateRangeFilterStore.getState().getEndTime()}`);
    this.setState({ startDate, endDate, selectedStart: startDate, selectedEnd: endDate });

    Api.get_analytics(
      `/surfwize/analytics/users/${this.props.params.user}/journey`,
      function (result) {
        const categories = [];
        result.data.forEach((item) => {
          if (item.signature_name !== null && item.signature_name.length > 0) {
            item.signature_name.forEach((signature) => {
              if (!categories.includes(signature)) {
                categories.push(signature);
              }
            });
          }
        });

        self.setState({
          journeyData: result.data,
          loaded: true,
        });
      },
      function (error) {
        self.setState({
          journeyData: [],
          loaded: true,
        });
      }
    );
  };

  render() {
    const dateTimeParams =
      this.state.selectedStart &&
      this.state.selectedEnd &&
      qs.stringify({
        overrideDates: "t",
        startDate: this.state.selectedStart.format("YYYY-MM-DD"),
        startTime: this.state.selectedStart.format("HH:mm:ss"),
        endDate: this.state.selectedEnd.format("YYYY-MM-DD"),
        endTime: this.state.selectedEnd.format("HH:mm:ss"),
      });

    return (
      <UserJourneyNew
        data={this.state.journeyData}
        loaded={this.state.loaded}
        dateTimeParams={dateTimeParams}
        initialResultFilter={this.state.initialResultFilter}
      />
    );
  }
}
