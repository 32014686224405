import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface Item {
  text?: string;
  value: string;
}

interface ReportingWelfareStore {
  tags: Item[];
}

interface ReportingSearchStore {
  tags: Item[];
}

interface UserJourneyFiltersStore {
  categoriesSelected: Item[];
  typesSelected: Item[];
  resultsSelected: Item[];
}

export const useReportingWelfareStore = create(
  persist<ReportingWelfareStore>(
    () => ({
      tags: [],
    }),
    {
      name: "ReportingWelfareStore",
      getStorage: () => sessionStorage,
    }
  )
);

export const useReportingSearchStore = create(
  persist<ReportingSearchStore>(
    () => ({
      tags: [],
    }),
    {
      name: "ReportingSearchStore",
      getStorage: () => sessionStorage,
    }
  )
);

export const useUserJourneyFiltersStore = create(
  persist<UserJourneyFiltersStore>(
    () => ({
      categoriesSelected: [],
      typesSelected: [],
      resultsSelected: [],
    }),
    {
      name: "UserJourneyFiltersStore",
      getStorage: () => sessionStorage,
    }
  )
);
