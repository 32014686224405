import React from "react";
import uuid from "uuid/v4";

import SearchableSelect from "../modules/SearchableSelect";

import HttpRequestPath from "../criteria/HttpRequestPath";
import IPv4NetworkRangeCriteria from "../criteria/IPv4NetworkRangeCriteria";
import MacAddressCriteria from "../criteria/MacAddressCriteria";
import ContentType from "../criteria/ContentType";
import UserAgent from "../criteria/UserAgent";
import IPv4Criteria from "../criteria/IPv4Criteria";
import Protocol from "../criteria/Protocol";
import Port from "../criteria/Port";
import PortRange from "../criteria/PortRange";
import Interface from "../criteria/Interface";
import Country from "../criteria/Country";
import IPv4NetworkSubnetCriteria from "../criteria/IPv4NetworkSubnetCriteria";
import WebsiteCriteria from "../criteria/WebsiteCriteria";
import FingerprintCriteria from "../criteria/FingerprintCriteria";
import SignatureCriteria from "../criteria/SignatureCriteria";
import TimePeriods from "../criteria/TimePeriods";
import Ipv4Object from "../criteria/Ipv4Object";
import MacAddressObject from "../criteria/MacAddressObject";
import WebsiteObject from "../criteria/WebsiteObject";
import SecurityGroupCriteria from "../criteria/SecurityGroupCriteria";

export default class CriteriaEditor extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      criteria: props.criteria,
      typeValue: "--",
    };

    this.available_criteria = {
      "ipv4.range": { name: "Network Range" },
      "source.mac": { name: "Mac Address" },
      ipv4: { name: "Network" },
      "ipv4.address": { name: "Ip Address" },
      "application.http.hostname.regex": { name: "Website" },
      fingerprint: { name: "Device Type" },
      signature: { name: "Application" },
      "ipv4.alias": { name: "IP Address Object" },
      "source.mac.pool": { name: "Mac Address Object" },
      "application.http.hostname": { name: "Website Object" },
      "transport.port": { name: "Port" },
      "transport.portrange": { name: "Port Range" },
      device: { name: "Interface" },
      geoip: { name: "Country" },
      timeperiod: { name: "Time Periods" },
      protocol: { name: "Protocol" },
      "application.http.contenttype.regex": { name: "Content Type" },
      "application.http.useragent.regex": { name: "User Agent" },
      "application.http.request.regex": { name: "Http Request Path" },
      "source.user": { name: "User" },
      securitygroup: { name: "Security Group" },
    };
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      criteria: nextprops.criteria,
    });
  }

  handle_CriteriaChanged = (criteria) => {
    /* This method bubbles the criteria changes up */
    this.props.handleCriteriaModified(criteria);
  };

  handle_IndividualCriteriaChanged = (criteria) => {
    /* Find the criteria that we are dealing with */
    let new_criteria = [];
    for (let potential_target of this.state.criteria) {
      if (criteria["id"] === potential_target["id"]) {
        new_criteria.push(criteria);
      } else {
        new_criteria.push(potential_target);
      }
    }

    this.handle_CriteriaChanged(new_criteria);
  };

  handle_AddCriteria = (event) => {
    let self = this;
    let obj = event.value;
    /* Search through existing criteria and make sure that it does not already exist */
    for (let criteria of this.state.criteria) {
      if (criteria["type"] === obj) {
        console.log("Cannot add duplicate criteria");
        return;
      }
    }

    if (obj && obj !== "") {
      this.state.criteria.push({
        name: self.available_criteria[obj]["name"],
        type: obj,
        id: uuid(),
      });
    }

    this.setState({
      typeValue: "--",
    });

    this.handle_CriteriaChanged(this.state.criteria);
  };

  handle_RemoveCriteria = (criteria) => {
    let new_criteria = [];
    for (let potential_target of this.state.criteria) {
      if (criteria["id"] !== potential_target["id"]) {
        new_criteria.push(potential_target);
      }
    }

    this.handle_CriteriaChanged(new_criteria);
  };

  render_SelectedCriteriaElement = (criteria) => {
    let criterion = criteria["type"];
    switch (criterion) {
      case "ipv4.range":
        return (
          <IPv4NetworkRangeCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "source.mac":
        return (
          <MacAddressCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "ipv4.address":
        return (
          <IPv4Criteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "ipv4":
        return (
          <IPv4NetworkSubnetCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "application.http.hostname.regex":
        return (
          <WebsiteCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "fingerprint":
        return (
          <FingerprintCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "signature":
        return (
          <SignatureCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "ipv4.alias":
        return (
          <Ipv4Object
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "source.mac.pool":
        return (
          <MacAddressObject
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "application.http.hostname":
        return (
          <WebsiteObject
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "transport.port":
        return (
          <Port
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "transport.portrange":
        return (
          <PortRange
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "device":
        return (
          <Interface
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "geoip":
        return (
          <Country
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "timeperiod":
        return (
          <TimePeriods
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "protocol":
        return (
          <Protocol
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "application.http.contenttype.regex":
        return (
          <ContentType
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "application.http.useragent.regex":
        return (
          <UserAgent
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "application.http.request.regex":
        return (
          <HttpRequestPath
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      case "securitygroup":
        return (
          <SecurityGroupCriteria
            criteria={criteria}
            bubbleMethod={this.handle_IndividualCriteriaChanged}
            handle_RemoveCriteria={this.handle_RemoveCriteria}
          />
        );
      default:
        return;
    }
  };

  /* Render Methods */
  render_SelectedCriteria = () => {
    let ret = [];
    for (let criteriaIndex in this.state.criteria) {
      let criteria = this.state.criteria[criteriaIndex];
      ret.push(<div key={criteria["type"] + criteriaIndex}>{this.render_SelectedCriteriaElement(criteria)}</div>);
    }
    return ret;
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let criteria of this.props.allowed) {
      ret.push([criteria, this.available_criteria[criteria]["name"]]);
    }

    return ret;
  };

  render_AddCriteria = () => {
    return (
      <SearchableSelect
        clearable={false}
        placeholder={"Select Criteria..."}
        onChange={this.handle_AddCriteria}
        valueDataMap={this.get_SelectionMap()}
      ></SearchableSelect>
    );
  };

  render() {
    return (
      <div>
        <div className="formgroup-element">
          <div className="formgroup-element-title" />
          <div className="formgroup-element-fields">
            <div className="mui-form--inline" data-cy="criteria">
              {this.render_AddCriteria()}
            </div>
          </div>
        </div>
        {this.render_SelectedCriteria()}
      </div>
    );
  }
}
