import React from "react";
import { Link } from "react-router";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import FlexibleTable from "../../modules/FlexibleTable";
import AmountField from "../../modules/AmountField";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import Filterhelper from "../../utils/Filterhelper";
import QueryFilter from "../../modules/QueryFilter";
import { getUsersByUsername } from "../../utils/api/Users";
import { fullNameOrUsername } from "../UserSearch/UserSearchHelper";

export default class ReportingTopWebsiteUsers extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      series: undefined,
      loaded: false,
      filters: Filterhelper.operate(this),
    };
    this.unsubFilterStore = null;
  }

  componentDidMount() {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onFilterChange);

    setTimeout(() => {
      GlobalDatePickerVisibilityActions.showGlobalDatePicker();
    }, 0);

    this.onFilterChange();
  }

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onFilterChange = () => {
    this.setState({
      data: [],
      loaded: false,
    });

    let self = this;
    Api.get_analytics(
      "/surfwize/analytics/website/" + this.props.params.website + "/users?" + Filterhelper.serialise(this.state.filters),
      function (result) {
        let data = result["data"];
        self.addName(data);
      },
      function (err) {
        console.log(err);
      }
    );
  };

  addName = (data) => {
    const userMap = new Map();
    const usersToFetch = [];
    for (const user of data) {
      if (!userMap.has(user["user"])) {
        usersToFetch.push(user["user"]);
        userMap.set(user["user"], undefined);
      }
    }
    getUsersByUsername(usersToFetch).then((users) => {
      for (const user of users) {
        userMap.set(user.username, user);
      }
      const r = data.map((item) => {
        const user = userMap.get(item.user);
        const fullName = user ? fullNameOrUsername(user) : item.user;
        return { ...item, name: fullName };
      });
      this.setState({
        data: r,
        loaded: true,
      });
    });
  };

  prepare_columns = () => {
    let self = this;
    let columns = [
      {
        title: "Username",
        data: function (row) {
          return (
            <Link
              to={
                "/surfwize/reporting/website/" +
                self.props.params.website +
                "/user/" +
                row["user"] +
                "?" +
                Filterhelper.serialise(self.state.filters)
              }
            >
              {row["user"]}
            </Link>
          );
        },
        search: FlexibleTable.search__single_string_field("user"),
        sortable: FlexibleTable.sortable__single_string_field("user"),
        onclick: undefined,
        export: function (row) {
          return row["user"];
        },
      },
      {
        title: "Name",
        data: function (row) {
          let name = row["name"] ? row["name"] : "-";
          return <span>{name}</span>;
        },
        search: FlexibleTable.search__single_string_field("name"),
        sortable: FlexibleTable.sortable__single_string_field("name"),
        onclick: undefined,
      },
    ];

    if (self.state.filters.hits) {
      columns.push({
        title: "Hits",
        data: function (row) {
          return row["hits"];
        },
        sortable: FlexibleTable.sortable__single_numeric_field("hits"),
        onclick: undefined,
        export: function (row) {
          return row["hits"];
        },
      });
    } else {
      columns.push(
        {
          title: "Upload",
          data: function (row) {
            return <AmountField amount={row["upload"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("upload"),
          onclick: undefined,
          export: function (row) {
            return row["upload"];
          },
        },
        {
          title: "Download",
          data: function (row) {
            return <AmountField amount={row["download"]} />;
          },
          sortable: FlexibleTable.sortable__single_numeric_field("download"),
          onclick: undefined,
          export: function (row) {
            return row["download"];
          },
        },
        {
          title: "Transfer",
          data: function (row) {
            return <AmountField amount={row["upload"] + row["download"]} />;
          },
          sortable: FlexibleTable.sortable__multi_int_field("upload", "download"),
          onclick: undefined,
          export: function (row) {
            return row["upload"] + row["download"];
          },
        }
      );
    }

    return columns;
  };

  render__content = () => {
    let self = this;
    return (
      <div>
        <div className="main-content-sub-element">
          <FlexibleTable
            columns={this.prepare_columns()}
            data={this.state.data}
            sort_column={self.state.filters.hits ? 2 : 4}
            sort_asc={false}
            loaded={this.state.loaded}
          />
        </div>
      </div>
    );
  };

  render_TopRight = () => {
    return (
      <div>
        <QueryFilter filters={this.state.filters} />
      </div>
    );
  };

  render_title = () => {
    return <span>{this.props.params.website} Users</span>;
  };

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-rocket" buttons={this.render_TopRight()} title={this.render_title()}>
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}
