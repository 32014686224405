import React from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

const bodyElement = document.getElementsByClassName("surfwize-body")[0];

class ScrollingModal extends React.Component {
  handleClose = (e) => {
    e.stopPropagation();
    this.props.handleHide();
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const backdropClass = clsx("scrolling-modal-backdrop", !this.props.show && "scrolling-modal-hide");
    const buttonsClass = clsx("scrolling-modal-buttons", this.props.centerButtons && "scrolling-modal-buttons-centered");

    return ReactDOM.createPortal(
      <div className={backdropClass} onMouseDown={this.handleClose}>
        <div onClick={this.stopPropagation} onMouseDown={this.stopPropagation} className="scrolling-modal">
          <div className="scrolling-modal-close">
            <img
              className="modal-close-icon"
              width="13"
              height="13"
              src="/static/images/icon_modal_close.svg"
              onClick={this.handleClose}
              alt="Close Modal"
            />
          </div>
          <h3 className="scrolling-modal-title">{this.props.title}</h3>
          <div className="scrolling-modal-content">{this.props.children}</div>
          {this.props.buttons && <div className={buttonsClass}>{this.props.buttons}</div>}
        </div>
      </div>,
      bodyElement
    );
  }
}

export default ScrollingModal;
