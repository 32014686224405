import { Box, Flex, Text } from "@familyzone/component-library";
import React, { ReactChild } from "react";

const SectionHeading: React.FC<{ title: string; icon?: ReactChild }> = ({ title, icon }) => {
  return (
    <Box borderRadius="sm" p="sp12" backgroundColor="neutrals.20">
      <Flex gap="sp8">
        <Text fontFamily="Poppins" fontSize="sm" fontWeight="medium" color="text.paragraph.regular" role="heading">
          {title}
        </Text>
        {icon}
      </Flex>
    </Box>
  );
};

export default SectionHeading;
