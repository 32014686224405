import React from "react";
import ScrollingModal from "../modules/ScrollingModal";
import Api from "../utils/Api";
import SessionStore from "../stores/SessionStore";
import SearchableSelect from "../modules/SearchableSelect";
import isValidHostnameOrIP from "../utils/isValidHostnameOrIp";

class PingTool extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hostname: "",
      ping_count: 0,
      running: false,
      last_results: [],
    };
  }

  startPing = () => {
    let self = this;
    Api.get("/ajax/ping/" + this.state.hostname, function (result) {
      /* Update the resultset */
      self.state.last_results.push(result["online"]);
      self.setState({
        ping_count: (self.state.ping_count += 1),
        last_results: self.state.last_results,
      });

      if (self.state.ping_count < 6) {
        self.startPing();
      } else {
        self.setState({
          running: false,
        });
      }
    });
  };

  handlePing = (event) => {
    event.preventDefault();
    this.setState({
      ping_count: 0,
      running: true,
      last_results: [],
    });

    this.startPing();
  };

  renderResults = () => {
    let ret = [];
    for (let result of this.state.last_results) {
      if (result > 0) {
        ret.push(
          <div>
            64 bytes from {this.state.hostname}: time={result} ms
          </div>
        );
      } else {
        ret.push(<div>Failed to ping {this.state.hostname}</div>);
      }
    }

    return ret;
  };

  handle_ChangeHostname = (event) => {
    this.setState({
      hostname: event.target.value,
    });
  };

  render() {
    return (
      <div className="toolitem">
        <div>Ping Tool</div>
        <div>
          <form className="mui-form">
            <div className="formgroup-element-title">Hostname or IP Address</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.hostname} onChange={this.handle_ChangeHostname} />
            </div>

            {this.renderResults()}
            <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handlePing}>
              Ping
            </button>
          </form>
        </div>
      </div>
    );
  }
}

class TracerouteTool extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hostname: "",
      result: [],
    };
  }

  renderResults = () => {
    let ret = [];
    for (let result of this.state.result) {
      ret.push(<div>{result}</div>);
    }

    return ret;
  };

  handleTraceroute = (event) => {
    event.preventDefault();
    /* reset the results */
    this.setState({
      result: [],
      traceRouteError: "",
    });

    if (!isValidHostnameOrIP(this.state.hostname)) {
      this.setState({ traceRouteError: "Please ensure that the format is an IPv4 address or a hostname" });
      return;
    }

    let self = this;
    Api.get("/ajax/traceroute/" + this.state.hostname, function (result) {
      self.setState({
        result: result["result"],
      });
    });
  };

  handle_ChangeHostname = (event) => {
    this.setState({
      hostname: event.target.value,
    });
  };

  render() {
    return (
      <div className="toolitem">
        <div>Traceroute Tool</div>
        <div>
          <form className="mui-form">
            <div className="formgroup-element-title">Hostname or IP Address</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.hostname} onChange={this.handle_ChangeHostname} />
            </div>

            {this.renderResults()}
            {this.state.traceRouteError && <p className="errorMessage">{this.state.traceRouteError}</p>}
            <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handleTraceroute}>
              Trace
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const packetCaptureStatus = {
  INACTIVE: "inactive",
  CAPTURING: "capturing",
  STOPPING: "stopping",
  DOWNLOADING: "downloading",
};

export class CaptureTool extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filter: "",
      interface: "",
      devices: [],

      status: packetCaptureStatus.INACTIVE,
      status__no_packets: 0,
      interval: undefined,
    };
  }

  componentDidMount() {
    let self = this;

    this.handleLoad();
    this.setState({
      interval: setInterval(function () {
        self.handleLoad();
      }, 2000),
    });
  }

  componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  handleLoad = () => {
    if (this.state.devices.length === 0) {
      Api.get("/config/device/ajax/network/devices", (result) => {
        this.setState({
          devices: result["data"],
        });
      });
    }

    if (this.state.status !== packetCaptureStatus.CAPTURING && this.state.status !== packetCaptureStatus.STOPPING) return;

    Api.get("/ajax/capture", (result) => {
      this.setState({
        status__no_packets: result.result.no_packets_captured,
      });

      if (result.result.running) return;

      if (this.state.status === packetCaptureStatus.CAPTURING) {
        Api.post("/ajax/capture/stop", {}, (result) => {});
      }

      if (this.state.status === packetCaptureStatus.DOWNLOADING || this.state.status === packetCaptureStatus.INACTIVE) return;

      this.setState({
        status: packetCaptureStatus.DOWNLOADING,
      });

      this.downloadCapture("/ajax/capture/download", this.state.interface);
    });
  };

  downloadCapture = (url, capture_interface) => {
    let req = new XMLHttpRequest();
    req.open("GET", url + "?t=" + Date.now(), true);
    req.setRequestHeader("Content-type", "application/zip");
    req.setRequestHeader("Device", SessionStore.getSelectedDevice());
    req.setRequestHeader("Token", SessionStore.getToken());
    req.setRequestHeader("UserId", SessionStore.getUserId());
    req.responseType = "blob";
    req.onload = (event) => {
      if (event.currentTarget.status === 200) {
        let blob = req.response;
        let link = document.createElement("a");
        link.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = "capture_".concat(capture_interface, ".pcap");
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      }

      this.setState({
        status: packetCaptureStatus.INACTIVE,
      });
    };
    req.send();
  };

  handleButton = (event) => {
    event.preventDefault();

    if (this.state.status === packetCaptureStatus.CAPTURING) {
      this.setState({
        status: packetCaptureStatus.STOPPING,
      });

      Api.post("/ajax/capture/stop", {}, (result) => {});
    } else {
      Api.post(
        "/ajax/capture/start",
        {
          interface: this.state.interface,
          target: this.state.filter,
        },
        (result) => {
          this.setState({
            status: packetCaptureStatus.CAPTURING,
            status__no_packets: 0,
          });
          this.handleLoad();
        }
      );
    }
  };

  handle_ChangeFilter = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  handle_ChangeInterface = (event) => {
    this.setState({
      interface: event.value,
    });
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let x of this.state.devices) {
      ret.push([x.interface, x.interface]);
    }

    return ret;
  };

  renderResults = () => {
    switch (this.state.status) {
      case packetCaptureStatus.CAPTURING:
        return <div data-testid="result-message">Capture running, collected {this.state.status__no_packets} packets</div>;
      case packetCaptureStatus.DOWNLOADING:
        return <div data-testid="result-message">Downloading capture...</div>;
      default:
        return <div data-testid="result-message"></div>;
    }
  };

  renderButton = () => {
    switch (this.state.status) {
      case packetCaptureStatus.CAPTURING:
        return (
          <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handleButton}>
            Stop
          </button>
        );

      case packetCaptureStatus.STOPPING:
      case packetCaptureStatus.DOWNLOADING:
        return <div />;

      default:
        return (
          <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handleButton}>
            Start
          </button>
        );
    }
  };

  render() {
    return (
      <div className="toolitem">
        <div>Capture Tool</div>
        <div>
          <form className="mui-form">
            <div className="formgroup-element-title">Filter</div>
            <div className="mui-textfield">
              <input type="text" value={this.state.filter} onChange={this.handle_ChangeFilter} />
            </div>

            <div className="formgroup-element-title">Interface</div>
            <div className="mui-select">
              <SearchableSelect
                value={this.state.interface}
                onChange={this.handle_ChangeInterface}
                valueDataMap={this.get_SelectionMap()}
              ></SearchableSelect>
            </div>

            {this.renderResults()}
            {this.renderButton()}
          </form>
        </div>
      </div>
    );
  }
}

export default class Tools extends React.Component {
  handleHide = () => {
    this.props.handleClose();
  };

  renderActions = () => {
    return (
      <button type="submit" className="mui-btn mui-btn--raised modal-button" onClick={this.handleHide}>
        Close
      </button>
    );
  };

  render() {
    return (
      <ScrollingModal
        show={this.props.visible}
        title="Network Diagnostic Tools"
        handleHide={this.handleHide}
        buttons={this.renderActions()}
      >
        {/* Don't load <CaptureTool/> until we need too -- because it pings /devices frequently */}
        {this.props.visible && (
          <React.Fragment>
            <PingTool />
            <TracerouteTool />
            <CaptureTool />
          </React.Fragment>
        )}
      </ScrollingModal>
    );
  }
}
