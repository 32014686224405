import {
  Box,
  Button,
  Text,
  useTranslation,
  Checkbox,
  Flex,
  useToast,
  SingleSearchSelector,
  Option,
  InlineNotification,
  Spinner,
} from "@familyzone/component-library";
import React, { FunctionComponent, ChangeEvent, useEffect, useState } from "react";
import CardBasedPage from "../../templates/CardBasedPage";
import { SDL_API, CoursesConfigurationResponse, EfrontCourse, UpdateCoursesPayload } from "../../../utils/api/CoursesConfig";

import { sortCoursesAlphabeticallyByTitle } from "./utils";

const EfrontConfig: FunctionComponent = () => {
  const { t } = useTranslation();

  const title = t("Configure eFront");

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: t("Configure eFront"), isActive: true },
  ];

  const { successToast, errorToast } = useToast();

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [errorLoadingConfig, setErrorLoadingConfig] = useState<boolean>(false);

  const [creatingBranch, setCreatingBranch] = useState<boolean>(false);
  const [savingCourses, setSavingCourses] = useState<boolean>(false);

  const [configuration, setConfiguration] = useState<CoursesConfigurationResponse>();

  const [availableParentBranches, setAvailableParentBranches] = useState<Option[]>([]);
  const [selectedParentBranch, setSelectedParentBranch] = useState<Option | null>();

  const [availableCourses, setAvailableCourses] = useState<EfrontCourse[]>([]);

  function displayConfigFailedToast(message: string): void {
    errorToast({
      title: t("Error creating eFront configuration"),
      description: message,
      position: "top",
      isClosable: true,
    });
  }

  function fetchCoursesConfig(): void {
    SDL_API.getConfiguration()
      .then((response) => {
        if (!response.ok) {
          setLoadingConfig(false);
          setErrorLoadingConfig(true);
          return;
        }

        response
          .json()
          .then((responseData: CoursesConfigurationResponse) => {
            setConfiguration(responseData);

            // map the selected parent branch to item
            const rawSelectedParentBranch = responseData.parentBranches.find((parentBranch) => parentBranch.parent === true);
            if (rawSelectedParentBranch) {
              setSelectedParentBranch({
                label: rawSelectedParentBranch.name,
                value: rawSelectedParentBranch.id.toString(),
              });
            }

            // map the parent branches to items
            const arr: Option[] = [];
            for (const parentBranch of responseData.parentBranches || []) {
              arr.push({
                label: parentBranch.name,
                value: parentBranch.id.toString(),
              });
            }
            setAvailableParentBranches(arr);

            setAvailableCourses(sortCoursesAlphabeticallyByTitle(responseData.courses || []));
            setLoadingConfig(false);
          })
          .catch(() => {
            setLoadingConfig(false);
            setErrorLoadingConfig(true);
          });
      })
      .catch(() => {
        setLoadingConfig(false);
        setErrorLoadingConfig(true);
      });
  }

  useEffect(() => {
    setLoadingConfig(true);
    fetchCoursesConfig();
  }, []);

  const handleCheckboxChange = (courseId: number, isChecked: boolean) => {
    const x = availableCourses.map((course) => {
      if (course.id === courseId) {
        return { ...course, assigned: isChecked };
      }
      return course;
    });
    setAvailableCourses(x);
  };

  function handleCreateBranch(_e: ChangeEvent<HTMLInputElement>): void {
    setCreatingBranch(true);

    if (selectedParentBranch == null) {
      errorToast({
        title: t("No parent branch selected"),
        description: t("Please select a parent branch."),
        position: "top",
        isClosable: true,
      });
      setCreatingBranch(false);
      return;
    }

    const payload = {
      parentBranchId: Number(selectedParentBranch.value),
    };

    SDL_API.createConfiguration(payload)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            displayConfigFailedToast(t("Please ensure you have Support Admin privileges."));
            return;
          } else if (response.status === 409) {
            displayConfigFailedToast(t("A branch already exists for this appliance."));
            return;
          } else if (response.status === 412) {
            response
              .json()
              .then((responseData: { error: string }) => {
                displayConfigFailedToast(responseData.error);
                return;
              })
              .catch(() => {
                displayConfigFailedToast(t("Precondition Failed. Please inspect network request."));
                return;
              });
          } else {
            displayConfigFailedToast(t("An unexpected error occurred. Please raise a dev escalation."));
            return;
          }
          setCreatingBranch(false);
          return;
        }
        successToast({
          title: t("Appliance successfully configured for Courses"),
          description: t("An eFront branch has been created and configured for this appliance."),
          position: "top",
          isClosable: true,
        });

        setLoadingConfig(true);
        // We must first send a dummy request because the API returns a 500 the first time we try to fetch it after creating the branch
        SDL_API.getConfiguration().finally(() => {
          // Now fetch the configuration again
          fetchCoursesConfig();
        });
      })
      .catch(() => {
        displayConfigFailedToast(t("Please ensure you have Support Admin privileges."));
        setCreatingBranch(false);
      })
      .finally(() => {
        setCreatingBranch(false);
      });
  }

  const handleChangeBranch = (selectedOption: Option | null) => {
    if (!selectedOption) {
      setSelectedParentBranch(null);
      return;
    }
    selectedOption.label = selectedOption.label ?? `eFront branch ID ${selectedOption.value}`;
    setSelectedParentBranch(selectedOption);
  };

  function handleSaveCourses(): void {
    setSavingCourses(true);

    const updateCoursesPayload: UpdateCoursesPayload = {
      courses: availableCourses.filter((course) => course.assigned).map((course) => ({ id: course.id })),
    };

    if (updateCoursesPayload.courses.length === 0) {
      errorToast({
        title: t("No courses selected"),
        description: t("Please select at least one course."),
        position: "top",
        isClosable: true,
      });
      setSavingCourses(false);
      return;
    }

    SDL_API.updateCourses(updateCoursesPayload)
      .then((response) => {
        if (!response.ok) {
          displayConfigFailedToast(t("Please ensure you have Support Admin privileges."));
          setSavingCourses(false);
          return;
        }
        successToast({
          title: t("Configured eFront"),
          description: t("eFront configuration has been set for this device."),
          position: "top",
          isClosable: true,
        });
      })
      .catch(() => {
        displayConfigFailedToast(t("Please ensure you have Support Admin privileges."));
        setSavingCourses(false);
        return;
      })
      .finally(() => {
        setSavingCourses(false);
        return;
      });

    return;
  }

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box minHeight="200px" p="sp24">
        <Box mb={20}>
          <InlineNotification
            status="info"
            notificationDescription={t(
              "This page is only visible to Support Admins and only necessary if the school has a license to use Courses."
            )}
          />
        </Box>
        {errorLoadingConfig ? (
          <InlineNotification
            notificationTitle={t("Error fetching Courses configuration")}
            notificationDescription={t("Please contact support if the issue persists.")}
            status="error"
          />
        ) : (
          <>
            <Box my={20}>
              <Box my={20}>
                <Text fontWeight={"medium"} mb={3}>
                  1. Select parent branch
                </Text>
                <Text color={"#42526E"} fontSize={"sm"}>
                  The operation cannot be undone. It will create a new branch in eFront and associate it with this appliance.
                </Text>
              </Box>

              <Flex alignItems={"center"}>
                <SingleSearchSelector
                  options={availableParentBranches}
                  selected={selectedParentBranch}
                  disabled={loadingConfig || creatingBranch || configuration?.configured}
                  placeholder={t("Select parent branch")}
                  onChange={handleChangeBranch}
                  filterOptions={true}
                  showClearIcon={false}
                />
                <Button
                  variant="primary"
                  mx={10}
                  onClick={handleCreateBranch}
                  disabled={loadingConfig || creatingBranch || configuration?.configured}
                >
                  {creatingBranch ? t("Creating branch") : configuration?.configured ? t("Branch already created") : t("Create branch")}
                </Button>
                {loadingConfig && <Spinner />}
                {configuration?.branchId && (
                  <Box>
                    <Text color={"#42526E"} fontSize={"sm"}>
                      Branch ID in eFront: {configuration?.branchId.toString() || ""}
                    </Text>
                    <Text color={"#42526E"} fontSize={"sm"}>
                      Parent branch: {selectedParentBranch?.label}
                    </Text>
                  </Box>
                )}
              </Flex>
            </Box>

            {configuration?.configured && (
              <Box my={20}>
                <Box my={20}>
                  <Text fontWeight={"medium"} mb={3}>
                    2. Select courses
                  </Text>
                  <Text color={"#42526E"} fontSize={"sm"}>
                    Select at least one course
                  </Text>
                </Box>

                {availableCourses.map((course: EfrontCourse) => {
                  return (
                    <Flex key={course.id} alignItems={"center"} my={20}>
                      <Box mt={5} mr={5}>
                        <Checkbox
                          isChecked={course.assigned}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleCheckboxChange(course.id, e.target.checked);
                          }}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight={"medium"} mb={3}>
                          {course.title}
                        </Text>
                        <Text color={"#42526E"} fontSize={"sm"}>
                          {course.courseCode}
                        </Text>
                      </Box>
                    </Flex>
                  );
                })}
              </Box>
            )}
          </>
        )}
      </Box>
      <>
        {configuration?.configured && (
          <Box bg={"#F5FCFF"} p="sp24" overflow={"hidden"}>
            <Button variant="primary" disabled={savingCourses} onClick={handleSaveCourses}>
              {savingCourses ? t("Saving") : t("Save")}
            </Button>
          </Box>
        )}
      </>
    </CardBasedPage>
  );
};

export default EfrontConfig;
