import React from "react";
import { Box } from "@familyzone/component-library";

export default class TableLeftPanel extends React.Component {
  render() {
    return (
      <div className="main-body-body-table">
        {this.props.children}
        {/* Spacing box for preventing zendesk overlap */}
        <Box height="100px" width="100%" />
      </div>
    );
  }
}
