import { create } from "zustand";
import { COMMUNITY_TIMEOUT_MILLIS } from "../constants/CommunityConstants";
import { jqXHR, ResponseError } from "../types/Api";
import { CommunityDashboardData } from "../types/Community";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";

export const communityDashboardURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/community-dashboard`;
};

export interface SearchOptions {
  groupId?: string;
}

export interface CommunityDashboardStore {
  data: CommunityDashboardData;
  fetch: (options: SearchOptions) => Promise<CommunityDashboardData>;
  reset: () => void;
}

const DEFAULT_DATA: CommunityDashboardData = Object.freeze({
  users: Object.freeze({ linkedCount: 0, claimedCount: 0 }),
  guardians: Object.freeze({ linkedCount: 0, claimedCount: 0 }),
});

export const useCommunityDashboardStore = create<CommunityDashboardStore>((set) => ({
  data: { ...DEFAULT_DATA },

  fetch: async (options: SearchOptions): Promise<CommunityDashboardData> => {
    let data,
      statusCode = 200;

    const params = new URLSearchParams();
    if (!!options.groupId) {
      params.append("groupId", options.groupId);
    }

    let url = communityDashboardURL();
    if (Array.from(params.keys()).length > 0) {
      url += `?${params.toString()}`;
    }

    // eslint-disable-next-line
    await Api.getAsyncWithTimeout(url, COMMUNITY_TIMEOUT_MILLIS).then(
      (response: CommunityDashboardData) => (data = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!data || statusCode !== 200) {
      throw new ResponseError("Failed to fetch dashboard data", statusCode);
    }

    set({ data });

    return data;
  },

  reset: () => {
    set({ data: { ...DEFAULT_DATA } });
  },
}));
