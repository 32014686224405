import { Box, Button, InlineNotification, Modal } from "@familyzone/component-library";
import React from "react";
import "../../../css/Associations.css";
import { ImportFileList } from "./AssociationsImportFileList";

export class ImportCSV extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      file: "",
      dropHover: false,
      uniqueEntries: [],
      files: new Set(),
      errorFiles: [],
      importDisabled: true,
    };
    this.dragCounter = 0;
    this.csvObjects = new Set();
  }

  componentDidMount() {
    document.body.addEventListener("dragover", this.handleDragOver.bind(this));
    document.body.addEventListener("dragenter", this.handleDragEnter.bind(this));
    document.body.addEventListener("drop", this.handleDrop.bind(this));
    document.body.addEventListener("dragleave", this.handleDragLeave.bind(this));
  }

  setImportDisabled = () => {
    this.setState({ importDisabled: false });
  };

  handleHide = () => {
    this.setState({
      file: "",
      files: new Set(),
      uniqueEntries: [],
      importDisabled: true,
    });
    this.props.handleClose();
  };

  handle_Submit = () => {
    for (let entry of this.state.uniqueEntries) {
      this.props.handleAdd({ username: entry[0], macAddress: entry[1] });
    }
    this.handleHide();
  };

  handle_updateCsvObject(csvObjects, errors) {
    this.csvObjects = csvObjects;
    // Results is an array [uniqueEntries, duplicationError]
    let results = this.updateUniqueEntryArray(csvObjects);
    this.setState({ uniqueEntries: results[0] });
    return results[1];
  }

  handle_deleteCsvObject(csvObject) {
    let updatedFiles = this.state.files;
    updatedFiles.delete(csvObject.getFile());
    this.removeFileFromErrorArray(csvObject);
    this.setState({
      files: updatedFiles,
      importDisabled: true,
    });
  }

  removeFileFromErrorArray(csvObject) {
    let currentErrors = this.state.errorFiles;
    let indexOfCurrentFile = currentErrors.indexOf(csvObject.file.name);
    if (indexOfCurrentFile > -1) {
      currentErrors.splice(indexOfCurrentFile, 1);
    }
    this.setState({ errorFiles: currentErrors });
  }

  handleDragOver(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
  }

  handleDragEnter(evt) {
    this.dragCounter++;
    this.setState({ dropHover: true });
  }

  handleDragLeave(evt) {
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dropHover: false });
    }
  }

  handleDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.dragCounter = 0;
    this.setState({ dropHover: false });
    this.addFiles(evt.dataTransfer.files);
  }

  handleFileSelect(evt) {
    this.addFiles(evt.target.files);
  }

  addFiles(files) {
    let validFiles = [];
    for (let file of files) {
      if (file.type) {
        if (
          file.type === "text/csv" ||
          file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          validFiles.push(file);
        }
      } else {
        // Windows 10 and Windows 7 sometimes return an empty string for the file type,
        // so we can check the extension as to whether the file is a csv or not
        if (file.name.endsWith(".csv")) {
          validFiles.push(file);
        }
      }
    }
    let updatedSet = this.state.files;
    for (let file of validFiles) {
      if (
        Array.from(updatedSet).filter(
          (innerFile) => innerFile.name === file.name && innerFile.size === file.size && innerFile.lastModified === file.lastModified
        ).length === 0
      ) {
        updatedSet.add(file);
      }
    }
    this.setState({ files: updatedSet });
  }

  addDuplicationError(fileName, duplicationErrors, errorMsg) {
    let currentFilesWithErrors = this.state.errorFiles;
    if (fileName) {
      if (!currentFilesWithErrors.includes(fileName)) {
        currentFilesWithErrors.push(fileName);
      }
    }
    if (!duplicationErrors.includes(errorMsg)) {
      duplicationErrors.push(errorMsg);
    }
    this.setState({ errorFiles: currentFilesWithErrors });
    return duplicationErrors;
  }

  handleDuplicationErrors(csvObj, macEntry, fileEntries, duplicationErrors) {
    this.removeFileFromErrorArray(csvObj);
    let self = this;
    let errorAdded = false;

    let errorMsg = self.props.macIpValueValidator(macEntry);
    if (errorMsg) {
      // Pass null as the file name so the user can still upload the file, and instead these ip's already in use will be skipped during the import
      duplicationErrors = self.addDuplicationError(
        null,
        duplicationErrors,
        "This file contains a Mac/Ip address (" + macEntry + ") that is already in use"
      );
      errorAdded = true;
    }
    let count = 0;
    Object.keys(fileEntries).forEach(function (name) {
      let macSet = fileEntries[name];
      for (let existingMacEntry of macSet) {
        if (macEntry === existingMacEntry) {
          count += 1;
          if (count > 1) {
            duplicationErrors = self.addDuplicationError(
              csvObj.file.name,
              duplicationErrors,
              "This file contains duplicate Mac/Ip addresses (" + macEntry + ")"
            );
            errorAdded = true;
          }
        }
      }
    });
    return [duplicationErrors, errorAdded];
  }

  updateUniqueEntryArray(csvObjects) {
    let fullMap = new Map();
    let ret = [];
    let self = this;
    let duplicationError = new Map();
    for (let csvObj of csvObjects) {
      let entries = csvObj.getEntries();
      duplicationError[csvObj.file.name] = [];
      Object.keys(entries).forEach(function (name) {
        let macSet = entries[name];
        for (let macEntry of macSet) {
          if (!fullMap[name]) {
            fullMap[name] = new Set();
          }
          let duplicationErrorResults = self.handleDuplicationErrors(csvObj, macEntry, entries, duplicationError[csvObj.file.name]);
          duplicationError[csvObj.file.name] = duplicationErrorResults[0];
          if (!duplicationErrorResults[1]) {
            // if an error was detected with this line, it will be skipped during the import (if there is an import)
            fullMap[name].add(macEntry);
          }
        }
      });
    }
    Object.keys(fullMap).forEach(function (name) {
      let macSet = fullMap[name];
      for (let macEntry of macSet) {
        ret.push([name, macEntry]);
      }
    });
    return [ret, duplicationError];
  }

  render() {
    if (this.props.visible) {
      return (
        <Modal headerText={"Import CSV"} onClose={this.handleHide} isOpen={this.props.visible} size="md">
          <form className="mui-form">
            <div id="drop_zone" style={{ visibility: this.state.dropHover ? "visible" : "hidden" }}>
              <div className={"dropBorder"}>
                <div className={"dropContent"}>
                  <div className={"dropText"}>Drop Files Here</div>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
            <div style={{ visibility: this.state.dropHover ? "hidden" : "visible" }}>
              <div className="mui-textfield" id={"file_picker_2"}>
                <input
                  type="file"
                  id="files"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={this.handleFileSelect.bind(this)}
                  multiple
                />
                <Button variant="primary" data-testid="browse-button">
                  <label className="importLabel" htmlFor="files">
                    Browse
                  </label>
                </Button>
                <InlineNotification
                  mt="16px"
                  status="info"
                  notificationTitle={"Expected Associations CSV Format"}
                  notificationDescription={
                    <Box>
                      <div>Columns: username, mac/ip address. Do not include the column titles.</div>
                      <div>All usernames must be valid and addresses must not already be in use.</div>
                      <div>Example:</div>
                      <div>oscar.maccay,1.1.1.1</div>
                      <div>steve.johnson,1.1.1.2</div>
                    </Box>
                  }
                />
                <ImportFileList
                  files={this.state.files}
                  updateCsvObjectCallback={this.handle_updateCsvObject.bind(this)}
                  deleteCsvObjectCallback={this.handle_deleteCsvObject.bind(this)}
                  errorMsg={this.state.errorMsg}
                  setImportDisabled={this.setImportDisabled}
                />
              </div>
            </div>
            <Button
              variant="primary"
              onClick={this.handle_Submit}
              disabled={(this.state.errorFiles.length > 0) | this.state.importDisabled}
              data-testid="import-button"
            >
              Import
            </Button>
          </form>
        </Modal>
      );
    }
    return <div />;
  }
}
