import { Td, Tr, Text, useToast, useTranslation, Flex, Box } from "@familyzone/component-library";
import React, { useCallback, useEffect, useState } from "react";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import SignatureStore from "../../stores/SignatureStore";
import Api from "../../utils/Api";
import Filterhelper from "../../utils/Filterhelper";
import { Link } from "react-router";
import TableBasedPage from "../templates/TableBasedPage";
import { getReportingFilter, ReportingFilter, ReportingTopTypeUserData, ReportingTopTypeUserDataResponse } from "./ReportingTopTypesHelper";
import { Signature } from "../../pages/filtering/types";
import StudentNamePill from "../../modules/StudentNamePill";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import SignatureActions from "../../actions/SignatureActions";
import { ApiDates } from "../../utils/DateTimeUtil";

interface Props {
  location?: {
    query?: ApiDates;
  };
  params: {
    type: string;
    user: string;
  };
}

const ReportingTypeUserNew: React.FC<Props> = ({ location, params }) => {
  const filterStore = useDateRangeFilterStore();
  const { t } = useTranslation();
  const breadcrumbs = [
    { title: t("Statistics"), url: "/surfwize/dashboard", isActive: false },
    { title: t("Users"), url: "/surfwize/reporting/users", isActive: false },
  ];

  const { errorToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ReportingTopTypeUserData[]>([]);
  const [filter, setFilter] = useState<ReportingFilter>(getReportingFilter(new URLSearchParams(window.location.search)));

  /* eslint-disable @typescript-eslint/no-unsafe-call, react-hooks/exhaustive-deps, @typescript-eslint/no-implied-eval, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */
  useEffect(() => {
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);
    setTimeout(SignatureActions.fetch, 0);
    const unsubFilterStore = useDateRangeFilterStore.subscribe(onDateChanged);

    return () => {
      unsubFilterStore();
    };
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (location?.query && location.query.startDate && location.query.endDate)
      filterStore.updateDateFilter({
        startDate: location.query.startDate,
        endDate: location.query.endDate,
        startTime: location.query.startTime ? location.query.startTime : "00:00:00",
        endTime: location.query.endTime ? location.query.endTime : "23:59:59",
        relativeKey: "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.query]);

  const onDateChanged = () => {
    setFilter({
      ...filter,
    });
  };

  const onLoad = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const stored_signature: Signature = SignatureStore.getSignature(params.type);
    if (stored_signature) {
      return `Application ${stored_signature.name} Usage`;
    } else return "Application Usage";
  }, [params.type]);

  useEffect(() => {
    setLoading(true);

    let noiseOverride = undefined;
    if (params.type === "sphirewall.application.blocklist.proxies") {
      noiseOverride = false;
    }
    Api.get_analytics(
      "/surfwize/analytics/type/" + params.type + "/user/" + params.user + "/by/day?" + Filterhelper.serialise(filter),
      function (result: ReportingTopTypeUserDataResponse) {
        setData(result.data.map((date) => ({ ...date, transfer: date.download + date.upload })));
        setLoading(false);
      },
      function (_: unknown) {
        setLoading(false);
        errorToast({ title: "There was an error fetching reporting data", isClosable: true });
      },

      { noiseOverride: noiseOverride }
    );
    onLoad();
  }, [errorToast, filter, params, onLoad]);

  const columns = [
    {
      headerText: "Date",
      columnName: "day",
      sortable: true,
      searchable: false,
      exportable: true,
    },
  ];

  if (filter.hits) {
    columns.push({
      headerText: "Hits",
      columnName: "hits",
      sortable: true,
      searchable: false,
      exportable: true,
    });
  } else {
    const columnTitles = ["Upload", "Download", "Transfer"];
    const columnNames = ["upload", "download", "transfer"];
    columnTitles.forEach((title, index) => {
      columns.push({
        headerText: title,
        columnName: columnNames[index],
        sortable: true,
        searchable: false,
        exportable: true,
      });
    });
  }

  const datamap = ({ day, hits, download, upload, transfer }: ReportingTopTypeUserData, index: number): JSX.Element => {
    return (
      <Tr key={index}>
        <Td data-testid="date-cell">
          <Text fontSize="md">
            <Link
              to={
                "/surfwize/reporting/users/" +
                params.user +
                "/timeline?date=" +
                day +
                "&filter_type=" +
                params.type +
                "&" +
                Filterhelper.serialise(filter)
              }
            >
              {day}
            </Link>
          </Text>
        </Td>
        {filter.hits ? (
          <Td data-testid="hits-cell">
            <Text fontSize="md">{hits}</Text>
          </Td>
        ) : (
          <>
            <Td data-testid="upload-cell">
              <Text fontSize="md">{upload}</Text>
            </Td>
            <Td data-testid="download-cell">
              <Text fontSize="md">{download}</Text>
            </Td>
            <Td data-testid="transfer-cell">
              <Text fontSize="md">{transfer}</Text>
            </Td>
          </>
        )}
      </Tr>
    );
  };

  const Children: React.FC = () => {
    if (params.user) {
      return (
        <Flex>
          {params.user && (
            <Box marginTop="24" marginLeft="24">
              <StudentNamePill username={params.user} />
            </Box>
          )}
        </Flex>
      );
    } else return <></>;
  };

  return (
    <TableBasedPage
      loaded={!loading}
      title={onLoad()}
      breadcrumbs={breadcrumbs}
      columns={columns}
      data={data}
      showSearch={false}
      tableDataMap={datamap}
      children={<Children />}
    />
  );
};

export default ReportingTypeUserNew;
