import React from "react";
import { FormLabel, Box, Tooltip } from "@chakra-ui/react";
import { Icon } from "@familyzone/component-library";

export const ConfigFormLabel = ({ label, tooltip }: { label: string; tooltip?: string }): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      gap: "4px",
    }}
  >
    <FormLabel>{label}</FormLabel>
    {tooltip && (
      <Tooltip hasArrow placement={"right"} variant={"dark"} label={tooltip}>
        <Box display={"flex"} alignItems={"center"}>
          <Icon boxSize={"3"} color={"grey"} aria-label={"circle-question-icon"} icon="fa-circle-question" />
        </Box>
      </Tooltip>
    )}
  </Box>
);
