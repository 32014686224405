import React, { useState } from "react";
import Api from "../utils/Api";
import { Box, Button, Flex, Modal, Text, useToast, withTranslation } from "@familyzone/component-library";
import { ModalBody } from "@chakra-ui/modal";
import { UserSearchSelector } from "../components/UserSearch/UserSearchSelector";

const AdminSessionByIPModal = (props) => {
  const [currentUsername, setCurrentUsername] = useState(null);
  const [userValidationError, setUserValidationError] = useState("");
  const [temporarySessionError, setTemporarySessionError] = useState("");
  const { successToast } = useToast();

  const handleHide = () => {
    setCurrentUsername("");
    setUserValidationError("");
    setTemporarySessionError("");

    props.handleClose();
  };

  const handle_CreateSession = () => {
    if ((currentUsername !== null) & (currentUsername !== "")) {
      const postBody = {
        username: currentUsername,
        ip_address: props.ip,
      };
      setUserValidationError("");
      setTemporarySessionError("");
      Api.post(
        "/config/ajax/admin-login-session",
        postBody,
        () => {
          props.onSaveSuccess();
          handleHide();
          successToast({
            title: "Session Created",
            description: `A session has been created for ${currentUsername}`,
            isClosable: true,
          });
        },
        () => {
          setTemporarySessionError("Unable to create session. Please try again.");
        }
      );
    } else {
      setUserValidationError("Please select a user");
    }
  };

  const handle_ChangeUserName = (user) => {
    if (user && typeof user.value === "string") {
      setCurrentUsername(user.value);
    }
  };

  return (
    <Modal headerText="Create Temporary Session" isOpen={props.visible} onClose={handleHide} size="sm">
      <ModalBody color="neutrals.900">
        <Text mb="sp12" color="neutrals.500">
          Allow a user to access your network
        </Text>

        <Flex mt="sp32">
          <Text fontWeight="500" mr="sp8">
            IP address:
          </Text>
          <Text fontWeight="400">{props.ip}</Text>
        </Flex>

        <Flex flexDirection={"column"} mt="sp16" mb="sp24">
          <Text fontWeight="500">User</Text>
          <UserSearchSelector
            prefill={currentUsername}
            handleChange={handle_ChangeUserName}
            placeholderText="Enter username, email or name"
          />
        </Flex>

        <Box className="errorMessage">{userValidationError}</Box>
        <Box className="errorMessage">{temporarySessionError}</Box>
        <Box mt="sp8">
          <Button disabled={userValidationError || !currentUsername} variant={"primary"} onClick={handle_CreateSession}>
            Create session
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(AdminSessionByIPModal);
