import { create } from "zustand";
import { getAgentAuth, saveAgentAuth } from "../utils/api/AgentAuth";

export interface AgentAuth {
  enabled?: boolean;
  require_hash_key?: boolean;
  key?: string;
}

export interface AgentAuthStore {
  agentAuth: AgentAuth;
  getAgentAuth: () => Promise<AgentAuth | undefined>;
  setAgentAuth: (agentAuth: AgentAuth) => void;
  saveAgentAuth: (agentAuth: AgentAuth) => Promise<boolean>;
}

export const useAgentAuthStore = create<AgentAuthStore>((set) => ({
  agentAuth: {} as AgentAuth,
  getAgentAuth: async () => {
    let fetchedAgentAuth: AgentAuth | undefined;
    try {
      fetchedAgentAuth = await getAgentAuth();
    } catch (error) {
      fetchedAgentAuth = undefined;
    }
    set({ agentAuth: fetchedAgentAuth });
    return fetchedAgentAuth;
  },
  saveAgentAuth: async (agentAuth: AgentAuth) => {
    const saved = await saveAgentAuth(agentAuth);
    if (saved) {
      set(() => ({ agentAuth: agentAuth }));
    }
    return saved;
  },
  setAgentAuth: (agentAuth: AgentAuth) => set(() => ({ agentAuth: agentAuth })),
}));
