import { Menu } from "@familyzone/component-library";
import React from "react";
import { MenuProps } from "@chakra-ui/react";

const menu = {
  parts: ["button", "list", "item", "groupTitle", "command", "divider"],
  baseStyle: {
    list: {
      bg: "neutrals.0",
      borderRadius: "sm",
      zIndex: "999",
      boxShadow: "0px 0px 1px rgba(5, 24, 57, 0.2), 0px 18px 28px rgba(5, 24, 57, 0.1)",
    },
    groupTitle: {
      mx: "sp8",
      px: "sp8",
      pt: "sp16",
      pb: "sp12",
      borderTop: "1px solid",
      borderColor: "neutrals.30",
      color: "text.paragraph.light",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "xs",
    },
  },
};

export const HeaderMenu: React.FC<MenuProps> = ({ children, ...props }) => {
  return (
    <Menu styleConfig={menu} {...props}>
      {children}
    </Menu>
  );
};
