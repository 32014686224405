import { Option } from "@familyzone/component-library";

export function stringArrayToOptionArray(arr: string[]): Option[] {
  return arr.map((x) => ({ value: x, label: x }));
}

export function optionArrayToStringArray(arr: Option[]): string[] {
  return arr.map((x) => String(x.value));
}

/**
 * Parses the given URL string and returns the domain.
 * @param urlString The URL string to parse.
 * @returns The domain if the URL is valid, otherwise null.
 */
export function parseDomain(urlString: string): string | null {
  try {
    // If URL doesn't have a protocol, prepend one to make it a valid URL before instantiating URL object.
    if (!urlString.includes("://")) {
      urlString = `https://${urlString}`;
    }

    const url = new URL(urlString); // This will throw a TypeError if the URL is invalid.

    // Decode it so wildcards are not "%2A"
    const parts = decodeURIComponent(url.hostname).split(".");

    if (parts[0] === "www") {
      parts.shift();
    }

    if (parts.length < 2) {
      throw new Error("A domain cannot be a single word");
    }

    for (let i = 0; i < parts.length; i++) {
      if (!parts[i].includes("*")) {
        continue;
      }
      if (parts[i].length > 1 || i !== 0) {
        throw new Error("An asterisk can only be used in the first part of the domain and must be the only character in the subdomain");
      }
    }

    const tld = parts[parts.length - 1];
    if (tld.length < 2 && tld !== "*") {
      throw new Error("A domain cannot have a TLD that is less than 2 characters unless it's a wildcard");
    }

    return parts.join(".");
  } catch (error) {
    return null;
  }
}
